/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Text/utils
 * @desc Utils for menu text
 */

import NodeText from 'labstep-web/prosemirror/nodes/text';
import { getSelectedText } from 'labstep-web/prosemirror/utils';
import { getSelectionNodeType } from 'labstep-web/prosemirror/utils/selection';
import { getSelectionAttributes } from 'labstep-web/prosemirror/marks/utils';
import { EditorState } from 'prosemirror-state';

/**
 *
 * @param state
 * @description Calculates if text menu should be hidden
 * @returns boolean
 */
export const getShouldHide = (state: EditorState) => {
  const textNode = getSelectionNodeType(state, NodeText.key);
  const linkMarks = getSelectionAttributes(
    state,
    state.schema.marks.link,
  );
  const selectedText = getSelectedText(state);
  return (
    !textNode ||
    state.selection.from === state.selection.to ||
    linkMarks.length > 0 ||
    !selectedText ||
    selectedText.length === 0
  );
};
