/**
 * Labstep
 *
 * @module screens/ResourceLocation/Show/Right
 * @desc ResourceLocation Show Right
 */

import RightPane from 'labstep-web/components/Layout/RightPane';
import LogList from 'labstep-web/components/Log/List';
import ResourceItemEmptyState from 'labstep-web/components/ResourceItem/Card/EmptyState';
import { ResourceItemEntitySearch } from 'labstep-web/components/ResourceItem/Search';
import ResourceLocationManager from 'labstep-web/components/ResourceLocation/Manager';
import ThreadForm from 'labstep-web/components/Thread/Form';
import { ICONS } from 'labstep-web/constants/icons';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React from 'react';
import { IScreensResourceLocationShowRightProps } from './types';

const ScreensResourceLocationShowRight: React.FC<
  IScreensResourceLocationShowRightProps
> = ({ resourceLocation }) => (
  <RightPane
    route={{
      to: 'resource_location_show',
      params: { id: resourceLocation.guid },
    }}
    subPages={[
      {
        name: 'Sub-Locations',
        icon: ICONS.resource_location.primary,
        route: {
          to: 'resource_location_show_sublocations',
          params: { id: resourceLocation.guid },
        },
        component: (
          <ResourceLocationManager
            resourceLocation={resourceLocation}
          />
        ),
      },
      {
        name: 'Items',
        icon: ICONS.resource_item.primary,
        route: {
          to: 'resource_location_show_resource_items',
          params: { id: resourceLocation.guid },
        },
        component: (
          <ResourceItemEntitySearch
            additionalFilters={[
              (resourceItem: ResourceItem) =>
                resourceItem.resource_location &&
                resourceLocation &&
                resourceItem.resource_location.guid ===
                  resourceLocation.guid,
            ]}
            noResultsMessage={
              <ResourceItemEmptyState explanation="When you select this location for your resource items they will appear here." />
            }
            params={{ resource_location_guid: resourceLocation.guid }}
            tableFormat={false}
            initialSearchParams={{
              status: 'available',
            }}
          />
        ),
      },
      {
        name: 'Notes',
        icon: ICONS.thread.primary,
        count: resourceLocation.thread.comment_count,
        route: {
          to: 'resource_location_show_thread',
          params: { id: resourceLocation.guid },
        },
        component: (
          <ThreadForm
            createThreadId={resourceLocation.thread.id}
            readThreadIds={resourceLocation.thread.id}
            entityNameLabel="notes"
          />
        ),
      },
      {
        name: 'Activity',
        icon: 'history',
        route: {
          to: 'resource_location_show_activity',
          params: { id: resourceLocation.guid },
        },
        component: <LogList entity={resourceLocation} />,
      },
    ]}
  />
);

export default ScreensResourceLocationShowRight;
