/**
 * Labstep
 *
 * @module components/Filter/Deleted
 * @desc Filter to toggle between deleted items
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import { PostFilterDeleted } from 'labstep-web/services/postFilter/filters/deleted';
import React, { useCallback } from 'react';
import { IDeletedFilterProps } from './types';

const FilterDeleted: React.FC<IDeletedFilterProps> = ({
  setParams,
  searchParams,
  paramKey = 'is_deleted',
  addPostFilter,
}) => {
  const checked = searchParams[paramKey] === '1';
  const onClick = useCallback(() => {
    if (addPostFilter) {
      addPostFilter(PostFilterDeleted.getNode());
    } else {
      setParams({
        [paramKey]: checked ? undefined : '1',
      });
    }
  }, [checked, addPostFilter, setParams, paramKey]);
  return (
    <ActionComponent
      type="option"
      icon="trash"
      text="Deleted"
      onClick={onClick}
    />
  );
};

export default FilterDeleted;
