/**
 * Labstep
 *
 * @module components/Modal/Edit
 * @desc Edit modal for experiment and protocol elements
 */

import MoleculeActionEdit from 'labstep-web/components/Molecule/Action/Edit';
import TableModal from 'labstep-web/components/Table/Modal';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import ProtocolChildRouter from 'labstep-web/containers/Router/ProtocolChild';
import { Metadata } from 'labstep-web/models/metadata';
import { Molecule } from 'labstep-web/models/molecule.model';
import { ProtocolTable } from 'labstep-web/models/protocol-table.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { IModalEditProps } from './types';

export const ModalEdit: React.FC<IModalEditProps> = ({
  activeEditModal,
  setActiveEditModal,
  parent,
}) => {
  if (!activeEditModal) {
    return null;
  }

  let modal = null;
  switch (activeEditModal.entityName) {
    case ProtocolValue.entityName: {
      setActiveEditModal(null);
      return (
        <ProtocolChildRouter
          route="value_show"
          id={activeEditModal.id}
        >
          {({ to }) => (
            <Redirect
              to={{ pathname: to, state: { expanded: true } }}
            />
          )}
        </ProtocolChildRouter>
      );
    }
    case Metadata.entityName: {
      setActiveEditModal(null);
      return (
        <ProtocolChildRouter
          route="metadata_show"
          id={activeEditModal.id}
        >
          {({ to }) => (
            <Redirect
              to={{ pathname: to, state: { expanded: true } }}
            />
          )}
        </ProtocolChildRouter>
      );
    }
    case ProtocolTable.entityName:
      modal = (entity: ProtocolTable) => (
        <TableModal
          allowLocking={
            parent.entityName === Protocol.entityName ||
            parent.isTemplate
          }
          table={entity}
          viewComponent={null}
          onDone={() => setActiveEditModal(null)}
          open
        />
      );
      break;
    case Molecule.entityName:
      modal = (entity: Molecule) => (
        <MoleculeActionEdit
          molecule={entity}
          canEdit
          viewComponent={null}
          onDone={() => setActiveEditModal(null)}
          open
          parent={parent}
        />
      );
      break;
    default:
      break;
  }

  return modal ? (
    <EntityReadEntityContainer
      entityName={activeEditModal.entityName}
      id={activeEditModal.id}
    >
      {({ entity }) => modal(entity)}
    </EntityReadEntityContainer>
  ) : null;
};

export default withActiveEditModal(ModalEdit);
