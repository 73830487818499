/**
 * Labstep
 *
 * @desc Protocol Version label
 */

import React from 'react';
import Label from 'labstep-web/core/Label';
import Icon from 'labstep-web/core/Icon';
import { Protocol } from 'labstep-web/models/protocol.model';
import styles from './styles.module.scss';
import { IProtocolVersionProps } from './types';

export const getProtocolVersion = (protocol: Protocol) =>
  `${protocol.printVersion} - ${
    protocol.is_last_version ? 'Latest' : 'Legacy'
  }`;

const ProtocolVersion: React.FC<IProtocolVersionProps> = ({
  protocol,
  onClick,
}) => (
  <Label className={styles.label} onClick={onClick} circular>
    <span>{getProtocolVersion(protocol)}</span>
    <Icon name="angle down" />
  </Label>
);

export default ProtocolVersion;
