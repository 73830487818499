/**
 * Labstep
 *
 * @module components/Experiment/CompletionChecklist
 * @desc Component showing a checklist of steps not completed for experiment
 */

import SubHeader from 'labstep-web/core/SubHeader';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Metadata } from 'labstep-web/models/metadata';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React from 'react';
import Message from './Message';
import styles from './styles.module.scss';
import {
  IExperimentCompletionChecklistProps,
  ISections,
} from './types';

const ExperimentCompletionChecklist: React.FC<
  IExperimentCompletionChecklistProps
> = ({
  experiment,
  experimentWorkflow,
  onLinkNavigation,
  protocolValuesWithoutResourceItemCount,
}) => {
  const params = {
    id: experimentWorkflow ? experimentWorkflow.id : experiment.id,
  };
  const inventoryTo = experimentWorkflow
    ? 'experiment_workflow_show_values'
    : 'experiment_show_values';
  const dataTo = experimentWorkflow
    ? 'experiment_workflow_show_metadata'
    : 'experiment_show_metadata';

  let sections: ISections = [
    {
      label: 'Inventory',
      route: {
        to: inventoryTo,
        params,
      },
      humanReadableEntityName:
        ProtocolValue.getHumanReadableEntityName(),
      messages: [
        {
          count: protocolValuesWithoutResourceItemCount,
          text: `with no ${ResourceItem.getHumanReadableEntityName()} specified`,
          prepend: ProtocolValue.getHumanReadableEntityName(),
        },
        {
          count: experiment.protocolValuesWithoutAmountCount,
          text: 'with no amount specified',
          prepend: ProtocolValue.getHumanReadableEntityName(),
        },
        {
          count: experiment.protocolValuesWithAmountNotDeductedCount,
          text: 'where amount was not deducted',
          prepend: `input ${ProtocolValue.getHumanReadableEntityName()}`,
        },
        {
          count:
            experiment.protocolValuesWithoutResourceItemOutputCount,
          text: 'where the item has not been added to inventory',
          prepend: `output ${ProtocolValue.getHumanReadableEntityName()}`,
        },
      ],
    },
    {
      label: 'Data',
      route: {
        to: dataTo,
        params,
      },
      humanReadableEntityName: Metadata.getHumanReadableEntityName(
        false,
        false,
        experiment,
      ),
      messages: [
        {
          level: 'red',
          count: experiment.metadataRequiredWithValueMissingCount,
          text: 'missing',
          prepend: `required ${Metadata.getHumanReadableEntityName(
            false,
            false,
            experiment,
          )}`,
        },
        {
          count: experiment.metadataNotRequiredWithValueMissingCount,
          text: 'incomplete',
          prepend: Metadata.getHumanReadableEntityName(
            false,
            false,
            experiment,
          ),
        },
      ],
    },
  ];

  if (experimentWorkflow) {
    sections = [
      {
        label: 'Protocols',
        route: {
          to: 'experiment_workflow_show_workflow',
          params,
        },
        humanReadableEntityName:
          Experiment.getHumanReadableEntityName(),
        messages: [
          {
            count: experimentWorkflow.incompleteExperimentsCount,
            text: 'incomplete',
            prepend: 'protocol',
          },
        ],
      },
      ...sections,
    ];
  } else {
    sections = [
      {
        label: 'Steps',
        route: {
          to: 'experiment_show_steps',
          params,
        },
        humanReadableEntityName: 'step',
        messages: [
          {
            count: experiment.incompleteStepsCount,
            text: 'incomplete',
            prepend: 'step',
          },
        ],
      },
      ...sections,
    ];
  }

  return (
    <div>
      {sections.map((section) => (
        <div key={section.label} className={styles.section}>
          <SubHeader>{section.label}</SubHeader>
          {section.messages.reduce(
            (count, message) => count + message.count,
            0,
          ) === 0 ? (
            <Message
              level="green"
              route={section.route}
              onClick={onLinkNavigation}
            >
              {`All ${section.humanReadableEntityName}s complete`}
            </Message>
          ) : (
            section.messages.map(
              (message) =>
                message.count > 0 && (
                  <Message
                    onClick={onLinkNavigation}
                    key={message.text}
                    level={message.level}
                    route={section.route}
                  >
                    {`${message.count} ${message.prepend}${
                      message.count === 1 ? '' : 's'
                    } ${message.text}`}
                  </Message>
                ),
            )
          )}
        </div>
      ))}
    </div>
  );
};

export default ExperimentCompletionChecklist;
