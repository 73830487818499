/**
 * Labstep
 *
 * @module core/Print/utils
 * @desc Convert progress number to message for the user
 */

import React from 'react';

/**
 * Get progress message.
 */
export const getProgressMessage = (
  progress: number,
): React.ReactNode => {
  switch (progress) {
    case 0:
      return <p>Requested print from server... please wait</p>;
    case 1:
      return <p>The server is preparing your print... please wait</p>;
    case 2:
      return (
        <p>The server is still preparing your print... please wait</p>
      );
    case 3:
      return (
        <p>
          The server is taking longer than expected to prepare your
          print... please wait
        </p>
      );
    case 4:
      return (
        <p>
          It has been 2 minutes since you requested your print.
          <br />
          The Labstep team was notified and will look into it.
          <br />
          You can wait a bit longer or refresh this window and try
          again later.
        </p>
      );
    case 5:
      return (
        <p>
          It has been 3 minutes since you requested your print.
          <br />
          The Labstep team was notified and will look into it.
          <br />
          You can wait a bit longer or refresh this window and try
          again later.
        </p>
      );
    case 6:
      return (
        <p>
          It has been 4 minutes since you requested your print.
          <br />
          The Labstep team was notified and will look into it.
          <br />
          You can wait a bit longer or refresh this window and try
          again later.
        </p>
      );
    case 7:
      return (
        <p>
          It has been 5 minutes since you requested your print.
          <br />
          The Labstep team was notified and will look into it.
          <br />
          We suggest you contact us via the help button.
          <br />
          You can wait a bit longer or refresh this window and try
          again later.
        </p>
      );
    default:
      return <p />;
  }
};
