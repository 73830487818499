/**
 * Labstep
 *
 * @desc Generic ShowEdit component for PubChemMetadata
 */

import React from 'react';
import { PubChemMetadata } from 'labstep-web/containers/PubChem/types';
import { NAME_MAPPPINGS } from 'labstep-web/components/PubChemMetadata/Form/fields';
import { Molecule } from 'labstep-web/models/molecule.model';
import ShowEdit from './ShowEdit';
import { IPubChemMetadataFormShowEditGenericProps } from './types';

const PubChemMetadataFormShowEditGeneric: React.FC<
  IPubChemMetadataFormShowEditGenericProps
> = ({ parent, pubChemMetadata, field }) => {
  const fieldName = field.name as string;
  const value = pubChemMetadata[NAME_MAPPPINGS[fieldName]];

  return (
    <ShowEdit
      entityName={parent.entityName}
      value={value}
      defaultValues={{ [fieldName]: value }}
      preSubmit={(
        values,
      ):
        | { properties: PubChemMetadata }
        | {
            pubchem: {
              reactants: PubChemMetadata[];
              products: PubChemMetadata[];
            };
          } => {
        const { [fieldName]: fieldValue } = values;

        if (parent instanceof Molecule) {
          const reactants =
            parent.pubchem?.reactants.map((r) =>
              r === pubChemMetadata
                ? {
                    ...pubChemMetadata,
                    [NAME_MAPPPINGS[fieldName]]: `${fieldValue}`,
                  }
                : r,
            ) || [];
          const products =
            parent.pubchem?.products.map((p) =>
              p === pubChemMetadata
                ? {
                    ...pubChemMetadata,
                    [NAME_MAPPPINGS[fieldName]]: `${fieldValue}`,
                  }
                : p,
            ) || [];
          return {
            pubchem: {
              products,
              reactants,
            },
          };
        }
        return {
          properties: {
            ...pubChemMetadata,
            [NAME_MAPPPINGS[fieldName]]: `${fieldValue}`,
          },
        };
      }}
      entity={parent}
      field={field}
    >
      {value}
    </ShowEdit>
  );
};

export default PubChemMetadataFormShowEditGeneric;
