/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/Metadata
 * @desc Renders a Metadata node
 */

import { Metadata as MetadataModel } from 'labstep-web/models/metadata';
import Read from 'labstep-web/prosemirror/components/NodeView/Read';
import { useReactNodeView } from 'labstep-web/prosemirror/components/ReactNodeView';
import React from 'react';
import MetadataBlockComponent from './Block';
import { IMetadataNodeProps, IMetadataNodeViewProps } from './types';

export class MetadataNode extends React.Component<IMetadataNodeProps> {
  public shouldComponentUpdate(): boolean {
    return Boolean(!this);
  }

  public render(): React.ReactElement {
    const { id, entity, timestamp } = this.props;
    return (
      <div contentEditable={false}>
        <Read
          entityName={entity.entityName}
          id={entity.id}
          timestamp={timestamp}
        >
          {({ entity: protocol }): React.ReactElement => (
            <Read
              entityName={MetadataModel.entityName}
              id={id}
              timestamp={timestamp}
            >
              {({ entity: metadata }): React.ReactElement => (
                <MetadataBlockComponent
                  entity={protocol}
                  metadata={metadata}
                  readOnly={!!timestamp}
                />
              )}
            </Read>
          )}
        </Read>
      </div>
    );
  }
}

const MetadataNodeView: React.FC<IMetadataNodeViewProps> = ({
  entity,
  timestamp,
}) => {
  const context = useReactNodeView();
  const { id } = context.node.attrs;

  return (
    <MetadataNode entity={entity} id={id} timestamp={timestamp} />
  );
};

export default MetadataNodeView;
