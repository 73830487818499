/**
 * Labstep
 *
 * @module core/List/Collapsible
 * @desc List with collapsible sections
 */

import React from 'react';
import TextWithCount from 'labstep-web/core/Text/WithCount';
import Collapsible from 'labstep-web/core/Collapsible';
import List from '..';
import {
  IListCollapsibleProps,
  IListCollapsibleSectionProps,
} from './types';

export const ListCollapsibleSection: React.FC<
  IListCollapsibleSectionProps
> = ({ icon, text, count, component }) =>
  count ? (
    <Collapsible
      label={<TextWithCount icon={icon} text={text} count={count} />}
      component={component}
    />
  ) : null;

export const ListCollapsible: React.FC<IListCollapsibleProps> = ({
  sections,
}) => (
  <List>
    {sections.map(({ icon, text, count, component }, i) => (
      <ListCollapsibleSection
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        icon={icon}
        text={text}
        count={count}
        component={component}
      />
    ))}
  </List>
);

export default ListCollapsible;
