/**
 * Labstep
 *
 * @module services/token
 * @desc Verify JWT Token
 */

import * as jose from 'jose';
import { APP_VERSION } from 'labstep-web/constants/version';
import { Action } from 'labstep-web/models/action.model';
import { Observable, from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { configService } from './config.service';
import fosRouter from './fos-router';
import HttpClientService from './http-client.service';

const JWT_ALGORITHM = 'RS256';

export class JWTService {
  protected expiredTokens: Record<string, Observable<Action>>;

  public publicKey?: jose.KeyLike;

  public constructor() {
    this.expiredTokens = {};
  }

  public async loadPublicKey() {
    this.publicKey = await jose.importSPKI(
      configService.jwtPublicKey,
      JWT_ALGORITHM,
    );
  }

  public refreshToken(
    token: string,
    refreshToken: string,
  ): Observable<Action> {
    if (this.expiredTokens[token]) {
      return this.expiredTokens[token];
    }

    const url = fosRouter.generate('app_publicapi_user_tokenrefresh');
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      'labstep-web-app-version': APP_VERSION,
    };
    const body = {
      refresh_token: refreshToken,
    };

    const obs = from(
      HttpClientService.send('POST', url, headers, body),
    ).pipe(
      map((payload) => {
        return {
          type: 'SUCCESS_REFRESH_TOKEN',
          payload,
        };
      }),
      catchError((error) =>
        of({
          type: 'FAIL_REFRESH_TOKEN',
          error,
        }),
      ),
    );
    this.expiredTokens[token] = obs;

    return obs;
  }

  public async isJwtValid(token: string): Promise<boolean> {
    if (!this.publicKey) {
      await this.loadPublicKey();
    }

    if (
      !this.publicKey ||
      !configService.jwtPublicKey ||
      configService.jwtPublicKey.length === 0
    ) {
      return false;
    }

    try {
      await jose.jwtVerify(token, this.publicKey);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async isJwtExpired(token: string): Promise<boolean> {
    if (!this.publicKey) {
      await this.loadPublicKey();
    }

    if (
      !this.publicKey ||
      !configService.jwtPublicKey ||
      configService.jwtPublicKey.length === 0
    ) {
      return false;
    }

    try {
      await jose.jwtVerify(token, this.publicKey);
      return false;
    } catch (err) {
      return true;
    }
  }
}

export const jwtService = new JWTService();
