/**
 * Labstep
 *
 * @module ProtocolTimer/Action/Create/Form
 * @desc ProtocolTimer create action with form
 */

import React from 'react';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import ActionComponent from 'labstep-web/core/Action/Component';
import EntityActionCreateModal from 'labstep-web/components/Entity/Action/Create/Modal';
import ProtocolTimerFormCreate from 'labstep-web/components/ProtocolTimer/Form/Create';
import { actionText } from 'labstep-web/components/ProtocolTimer/Action/Create';
import { IProtocolTimerActionCreateFormProps } from './types';

export const ProtocolTimerActionCreateForm: React.FC<
  IProtocolTimerActionCreateFormProps
> = ({
  protocol,
  viewComponent,
  actionComponentProps,
  defaultValues,
  options,
  modalProps,
}) => (
  <EntityActionCreateModal
    header={actionText}
    parent={protocol}
    entityName="protocol_timer"
    viewComponent={
      viewComponent ||
      (({ toggleModal }) => (
        <ActionComponent
          type="button"
          text={actionText}
          {...actionComponentProps}
          onClick={toggleModal}
        />
      ))
    }
    content={({ toggleModal }) => (
      <ProtocolTimerFormCreate
        defaultValues={defaultValues}
        protocol={protocol}
        options={enhanceOptions({
          options,
          onSuccess: toggleModal,
        })}
      />
    )}
    {...modalProps}
  />
);

export default ProtocolTimerActionCreateForm;
