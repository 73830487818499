/**
 * Labstep
 *
 * @module components/ProtocolDevice/Manager
 * @desc Protocol Device manager
 */

import Container from 'labstep-web/components/Layout/Container';
import ProtocolDeviceActionCreate from 'labstep-web/components/ProtocolDevice/Action/Create';
import ProtocolDeviceCardEmptyState from 'labstep-web/components/ProtocolDevice/Card/EmptyState';
import ProtocolDeviceList from 'labstep-web/components/ProtocolDevice/List';
import PremiumFeatureScreenGuard from 'labstep-web/core/PremiumFeature/ScreenGuard';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ProtocolDevice } from 'labstep-web/models/protocol-device.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import {
  IProtocolDeviceManagerContainerProps,
  IProtocolDeviceManagerProps,
} from './types';

export const ProtocolDeviceManager: React.FC<
  IProtocolDeviceManagerProps
> = ({
  parent,
  protocolDevices,
  withEmptyState = true,
  showCreateAction = true,
}) => {
  const action = showCreateAction && (
    <ProtocolDeviceActionCreate parent={parent} />
  );

  if (protocolDevices.length) {
    return (
      <div>
        {action && <Container>{action}</Container>}
        <ProtocolDeviceList protocolDevices={protocolDevices} />
      </div>
    );
  }

  if (withEmptyState) {
    return (
      <ProtocolDeviceCardEmptyState
        action={action}
        parentEntityName={parent.entityName}
      />
    );
  }

  return <Container>{action}</Container>;
};

export const ProtocolDeviceManagerContainer: React.FC<
  IProtocolDeviceManagerContainerProps
> = ({ parent, ...props }) => (
  <PremiumFeatureScreenGuard unstyled premiumFeature="devices">
    <ReadOnMountHOC
      type="cursor"
      entityName={ProtocolDevice.entityName}
      params={{
        [`${
          parent instanceof Protocol ? 'protocol' : 'experiment'
        }_id`]: parent.id,
      }}
      children={({ entities: protocolDevices }) => (
        <ProtocolDeviceManager
          parent={parent}
          protocolDevices={protocolDevices}
          {...props}
        />
      )}
    />
  </PremiumFeatureScreenGuard>
);

export default ProtocolDeviceManagerContainer;
