/**
 * Labstep
 *
 * @module components/File/ListOrTable
 * @desc List or table of files
 */

import React from 'react';
import FileTable from 'labstep-web/components/File/Table';
import FileList from 'labstep-web/components/File/List';
import MasterIndexCenterListOrTable from 'labstep-web/screens/Master/Index/Center/ListOrTable';
import { IFileListOrTableProps } from './types';
import { actions } from './constants';

export const FileListOrTable: React.FC<IFileListOrTableProps> = ({
  files,
  ...rest
}) => (
  <MasterIndexCenterListOrTable
    tableComponent={
      <FileTable files={files} actions={actions} {...rest} />
    }
    listComponent={<FileList files={files} actions={actions} />}
  />
);

export default FileListOrTable;
