/**
 * Labstep
 *
 * @module components/EntityExport/Action/Create
 * @desc Create Action for EntityExport
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { IEntityActionCreateContainerProps } from 'labstep-web/components/Entity/Action/Create/types';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityExport } from 'labstep-web/models/entity-export.model';
import React from 'react';
import { IEntityExportActionCreateProps } from './types';

export const EntityExportActionCreate: React.FC<
  IEntityExportActionCreateProps
> = ({
  actionComponentProps,
  activeGroup,
  entity,
  createdAtFrom,
  entityName,
  params,
  fields,
  type,
  options,
}) => {
  let overwriteCanCreateProps: IEntityActionCreateContainerProps['overwriteCanCreateProps'];
  const body: any = { group_id: activeGroup?.id };
  if (entity) {
    body.query_entity_name = entity.entityName;
    body.query_parameters = { id: entity.id };
    overwriteCanCreateProps = {
      parentId: entity.id,
      parentName: entity.entityName,
    };
  } else if (entityName) {
    body.query_entity_name = entityName;
    body.query_parameters = params;
    body.fields = fields;
    body.type = type;
  }

  if (createdAtFrom) {
    body.query_parameters = {
      ...body.query_parameters,
      created_at_from: createdAtFrom,
    };
  }

  return (
    <EntityActionCreate
      overwriteCanCreateProps={overwriteCanCreateProps}
      entityName={EntityExport.entityName}
      body={body}
      options={{ ...options, toast: true }}
      actionComponentProps={{
        type: 'option',
        icon: 'file archive',
        text: 'Export',
        ...actionComponentProps,
      }}
    />
  );
};

export default withActiveGroup(EntityExportActionCreate);
