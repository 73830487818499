/**
 * Labstep
 *
 * @module prosemirror/extensions/referencing/plugin
 * @desc Referencing plugin definition to store the referencing menu state
 */

import { showAction } from 'labstep-web/prosemirror/components/Menu/Referencing/Menu/utils';
import { getToken } from 'labstep-web/prosemirror/utils/selection';
import { Plugin, PluginKey } from 'prosemirror-state';
import {
  SELECT_CATEGORY,
  SELECT_ITEM,
  SELECT_NEXT,
  SELECT_PREVIOUS,
  SET_CATEGORIES,
  SET_CREATABLE_ITEMS,
  SET_EXTERNAL_ITEMS,
  SET_ITEMS,
  SUBMIT_CATEGORY,
  SUBMIT_ITEM,
  SELECT_CREATABLE_ITEM,
  SET_IS_OPEN,
} from '../commands/actions';
import { IProseMirrorReferencingToken } from '../types';
import { PluginState } from './types';

const KEY = 'referencing';

export const TRIGGER = '@';

export const referencingPluginKey = new PluginKey(KEY);

export const initialState: PluginState = {
  isOpen: false,
  categories: [],
  availableCategories: [],
  index: 0,
  selectedCategory: null,
  items: [],
  externalItems: [],
  availableItems: [],
  creatableItems: [],
  token: null,
};

export const updateState = (
  value: PluginState,
  action: any,
  token: IProseMirrorReferencingToken | null,
) => {
  if (action.type === SET_CATEGORIES) {
    return {
      ...value,
      categories: action.categories,
      availableCategories: action.categories,
    };
  }
  if (action.type === SELECT_PREVIOUS) {
    return {
      ...value,
      index: value.index > 0 ? value.index - 1 : value.index,
    };
  }
  if (action.type === SELECT_NEXT) {
    const itemsTotal =
      value.availableItems.length + value.creatableItems.length;
    let limit: number;
    if (value.selectedCategory) {
      if (showAction(value.selectedCategory)) {
        limit = itemsTotal;
      } else {
        limit = itemsTotal - 1;
      }
    } else {
      limit = value.categories.length - 1;
    }
    return {
      ...value,
      index: value.index < limit ? value.index + 1 : value.index,
    };
  }
  if (action.type === SUBMIT_CATEGORY) {
    return {
      ...value,
      selectedCategory: value.availableCategories[value.index],
      items: [],
      externalItems: [],
      availableItems: [],
      creatableItems: [],
      index: 0,
      token,
    };
  }
  if (action.type === SELECT_CATEGORY) {
    return {
      ...value,
      selectedCategory: value.availableCategories[action.index],
      items: [],
      externalItems: [],
      availableItems: [],
      creatableItems: [],
      index: 0,
      token,
    };
  }
  if (action.type === SET_ITEMS) {
    return {
      ...value,
      items: action.items,
      availableItems: action.items,
    };
  }
  if (action.type === SET_EXTERNAL_ITEMS) {
    return {
      ...value,
      externalItems: action.items,
    };
  }
  if (action.type === SET_CREATABLE_ITEMS) {
    const creatableItems = action.items;
    return {
      ...value,
      creatableItems,
      index:
        !!value.selectedCategory &&
        value.index >
          value.availableItems.length + creatableItems.length
          ? 0
          : value.index,
    };
  }
  if (action.type === SET_IS_OPEN && action.isOpen === false) {
    return initialState;
  }
  if (action.type === SET_IS_OPEN && action.isOpen === true) {
    return { ...value, isOpen: true };
  }
  if (
    action.type === SUBMIT_ITEM ||
    action.type === SELECT_ITEM ||
    action.type === SELECT_CREATABLE_ITEM
  ) {
    return initialState;
  }
  return value;
};

const getAvailableCategories = (
  value: PluginState,
  text: string,
): PluginState['availableCategories'] =>
  value.categories.filter((c) =>
    c.label.toLowerCase().includes(text.toLowerCase()),
  );

const getAvailableItems = (
  value: PluginState,
  stepIds: number[],
  text: string,
): PluginState['availableItems'] => {
  const { selectedCategory } = value;
  if (!selectedCategory) {
    return [];
  }
  return [
    ...value.items.filter((item: any) => {
      if (
        ['metadatas', 'protocol_values'].indexOf(
          selectedCategory.value,
        ) > -1
      ) {
        const label = item.label || 'Untitled';
        return label.toLowerCase().includes(text.toLowerCase());
      }
      if (selectedCategory.value.endsWith('steps')) {
        return `Step ${
          stepIds.findIndex(
            (stepId) => Number(stepId) === Number(item.id),
          ) + 1
        }`.includes(text.toLowerCase());
      }
      return item.name.toLowerCase().includes(text.toLowerCase());
    }),
    ...value.externalItems,
  ];
};

const referencingPlugin = new Plugin({
  key: referencingPluginKey,
  state: {
    init: () => {
      return initialState;
    },
    apply: (tr, value, oldState, newState) => {
      const action = tr.getMeta(referencingPlugin);

      const token = getToken(newState, '@');

      if (action) {
        return updateState(value, action, token);
      }

      if (!token) {
        return initialState;
      }

      const stepIds = (newState.doc.content.toJSON() as any[])
        .filter((b) => b.type.endsWith('step'))
        .map((b) => b.attrs.id);

      const availableItems = getAvailableItems(
        value,
        stepIds,
        token.text,
      );

      return {
        ...value,
        availableCategories: getAvailableCategories(
          value,
          token.text,
        ),
        availableItems,
        token,
        index:
          !!value.selectedCategory &&
          value.index >
            availableItems.length + value.creatableItems.length
            ? 0
            : value.index,
      };
    },
  },
});

export default referencingPlugin;
