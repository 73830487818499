/**
 * Labstep
 *
 * @module ProtocolTimer/Form/Create
 * @desc Protocol Timer Create Form
 */

import { protocolTimerFormFields } from 'labstep-web/components/ProtocolTimer/Form/fields';
import { preSubmit } from 'labstep-web/components/ProtocolTimer/Form/utils';
import FormCreate from 'labstep-web/core/Form/Create';
import TextHelper from 'labstep-web/core/Text/Helper';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { ProtocolTimer } from 'labstep-web/models/protocol-timer.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IProtocolTimerFormCreateProps } from './types';

export const ProtocolTimerFormCreate: React.FC<
  IProtocolTimerFormCreateProps
> = ({ protocol, toggleModal, defaultValues, options }) => (
  <>
    <TextHelper>{`Interactive timers can be used in ${getHumanReadableEntityName(
      ExperimentWorkflow.entityName,
      true,
    )} for time sensitive steps.`}</TextHelper>
    <FormCreate
      autoFocus
      entityName={ProtocolTimer.entityName}
      parentName={protocol.entityName}
      parentId={protocol.id}
      options={options}
      onCancel={toggleModal}
      fields={protocolTimerFormFields}
      defaultValues={defaultValues}
      preSubmit={preSubmit}
    />
  </>
);

export default ProtocolTimerFormCreate;
