/**
 * Labstep
 *
 * @module core/Label/Toggle
 * @desc This large toggle is used in journeys such as selecting tier pricing options,
 * when a pill with extra information may be required and an icon isn’t enough to
 * describe the context change.
 */

import classNames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { ILabelToggleItemProps } from './types';

export const LabelToggleItem: React.FC<ILabelToggleItemProps> = ({
  text,
  action,
  onClick,
  isActive,
  icon,
  iconColor,
}) => (
  <div
    className={classNames(styles.item, {
      [styles.active]: isActive,
    })}
    onClick={onClick}
  >
    {icon && <Icon name={icon} size="large" color={iconColor} />}
    {text}
    {action}
  </div>
);

export default LabelToggleItem;
