/* eslint-disable dot-notation */
/**
 * Labstep
 *
 * @module state/store
 * @desc Set up redux store
 */

import { configService } from 'labstep-web/services/config.service';
import { SensitiveService } from 'labstep-web/services/sensitive.service';
import epics from 'labstep-web/state/epics';
import reducers from 'labstep-web/state/reducers';
import LogRocket from 'logrocket';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import { autoRehydrate, persistStore } from 'redux-persist';
import reduxThunk from 'redux-thunk';
import { fileDownloadGroupId } from './middleware/file-download-group-id';
import { optimistic } from './middleware/optimistic';
import { timeoutLogout } from './middleware/timeout-logout';

const { debug } = configService;

const epicMiddleware = createEpicMiddleware();

const middlewares = [
  reduxThunk,
  // Keep timeoutLogout above epicMiddleware
  fileDownloadGroupId,
  timeoutLogout,
  optimistic,
  epicMiddleware,
];

// Append redux logger if in debug mode
if (
  debug &&
  configService.reactReduxLogger &&
  configService.nodeEnv !== 'test'
) {
  const reduxLogger = createLogger({
    collapsed: true,
  });
  middlewares.push(reduxLogger);
}

middlewares.push(
  LogRocket.reduxMiddleware({
    actionSanitizer(action) {
      return SensitiveService.purifyReduxAction(action);
    },
    stateSanitizer(state) {
      return SensitiveService.purifyReduxAction(state);
    },
  }),
);

let enhancers = compose(
  applyMiddleware(...middlewares),
  autoRehydrate(),
);

// // Chrome Dev Tools
if (debug) {
  enhancers = composeWithDevTools(enhancers);
}

const store = createStore(
  combineReducers(reducers),
  undefined,
  enhancers,
);

epicMiddleware.run(epics);

// if (module?.hot) {
//   module.hot.accept('./reducers', () => {
//     store.replaceReducer(reducers as any);
//   });
// }

// Not using persist for testing otherwise it breaks
if (configService.nodeEnv !== 'test') {
  persistStore(store, {
    whitelist: [
      'authenticatedUser',
      'activeGroupId',
      'ui_persistent',
      'thumbnail',
      'client',
      'last_action_timestamp',
    ],
  });
}

if ((window as any)['Cypress']) {
  (window as any)['store'] = store;
}

export default store;
