/**
 * Labstep
 *
 * @module core/Modal
 * @desc Wrapper around semantic-ui-react Modal
 */

import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal';

import './styles.scss';

export default Modal;
