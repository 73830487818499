/**
 * Labstep
 */

import { EntityState } from 'labstep-web/models/entity-state.model';
import { WorkflowStatusIndicatorSize } from './types';

export const handleResize = (
  numberOfElements: number,
  setSizeAction: React.Dispatch<
    React.SetStateAction<WorkflowStatusIndicatorSize>
  >,
  newSize: number,
) => {
  if (newSize < 300 + (numberOfElements - 1) * 50) {
    setSizeAction(WorkflowStatusIndicatorSize.small);
  } else if (newSize < numberOfElements * 150) {
    setSizeAction(WorkflowStatusIndicatorSize.medium);
  } else {
    setSizeAction(WorkflowStatusIndicatorSize.large);
  }
};

export const sortEntityStatesByPosition = (
  entityStates: EntityState[],
) => {
  return entityStates.sort((a, b) => {
    return a.position - b.position;
  });
};
