/* eslint-disable no-underscore-dangle */
/**
 * Labstep
 *
 * @module state/schema/helpers
 * @desc Helper functions for normalizr/schema
 */

import camelCase from 'lodash/camelCase';
import schema from 'labstep-web/services/schema';

/**
 * Checks if schema entity is of type Entity (Not array)
 *
 * @function
 * @param  {EntitySchema} schemaEntity Entity defined using schema from normalizr
 * @return {bool}
 */
const isEntityType = (schemaEntity: any): boolean =>
  '_key' in schemaEntity;

/**
 * Computes children of a schema Entity with the keys
 * as defined by the entity itself (Not the backend)
 * e.g. (for Experiment children of  { ..., steps: experimentSteps })
 * and experimentStep key 'protocol_step'
 * we will return 'protocol_step'
 * Check if child entity is of type EntitySchema
 * else type of ArraySchema
 *
 * @function
 * @param  {object} schemaEntity Entity defined using schema from normalizr
 * @return {array} - Array of children keys
 */
const getEntityChildren = (schemaEntity: any): string[] =>
  Object.keys(schemaEntity.schema).map((childKey: string) => {
    const child = schemaEntity.schema[childKey];

    if (child._key) {
      return child._key;
    }

    return child.schema._key;
  });

/**
 * This helper function returns the name of the key of an entity's child
 * E.g. if we define in our schema protocolStep.define({ tables: protocolTables })
 * this function will return 'tables'
 *
 * @function
 * @param  {object} parentSchema - Schema of Parent Entity
 * @param  {string} childEntityName - Child entityName (e.g. 'protocol_table')
 * @return {string} - Name of child key
 */
export const nameOfChildKey = (
  parentEntityName: string,
  childEntityName: string,
): string => {
  const parentSchema =
    schema[camelCase(parentEntityName) as keyof typeof schema].schema;
  return Object.keys(parentSchema).find(
    (key: string) =>
      parentSchema[key].schema._key === childEntityName,
  )!;
};

/**
 * Computes the parents of an entity
 *
 * @function
 * @param  {string} entityName - Name of entity e.g. 'protocol_step'
 * @return {array} - Array of all parents e.g. ['protocol', 'experiment']
 */
export const parentOf = (entityName: string): string[] => {
  const parent: any[] = [];

  (Object.keys(schema) as (keyof typeof schema)[]).forEach(
    (entity) => {
      const schemaEntity = schema[entity];

      if (isEntityType(schemaEntity)) {
        const children = getEntityChildren(schemaEntity);

        if (
          children.includes(entityName) ||
          children.includes(`${entityName}s`)
        ) {
          parent.push(entity);
        }
      }
    },
  );

  return parent;
};

/**
 * Recursively gets all child entities of a SchemaEntity or ArrayEntity
 *
 * FIXME Attemps smells like bad code
 *
 * @function
 * @param  {array|object} entity
 * @return {array} - Array of all the keys required for denormalization
 */
// export const getNestedEntities = (entity: Entity): string[] => {
//   let keys: string[] = [];
//   let entities: any[] = [entity];
//   let currentEntity: any;
//   let entitiesToAppend: any[];
//   let attempts = 0;

//   function mapEntitySchema(currentValue: string): any {
//     return currentEntity.schema[currentValue];
//   }

//   function filterByKey(item: any): any {
//     const key = item._key || item.schema._key;
//     return keys.indexOf(key) === -1;
//   }

//   do {
//     currentEntity = entities.pop();

//     // Handle entity of Array type
//     if (!('_key' in currentEntity)) {
//       currentEntity = currentEntity.schema;
//     }

//     keys = union(keys, [currentEntity._key]);
//     entitiesToAppend = Object.keys(currentEntity.schema)
//       .map(mapEntitySchema)
//       .filter(filterByKey);

//     entities = union(entities, entitiesToAppend);
//     attempts += 1;
//   } while (entities.length && attempts < 10000); // Preventing infinite loop

//   return keys;
// };

/**
 * Returns id attribute for entity
 *
 * @function
 * @param  {string} entityName - Entity Name
 * @return {string} - ID attribute
 */
export const getIdAttribute = (entityName: string): string => {
  const entity = schema[camelCase(entityName) as keyof typeof schema];
  return (entity && entity._idAttribute) || 'id';
};

/**
 * Pluralizes entityName as found in schema
 *
 * @function
 * @param  {string} entityName - Entity name
 * @return {string}
 */
export const getPluralInSchema = (entityName: string): string => {
  return `${entityName}s`;
};
