/**
 * Labstep
 *
 * @module models/access-key
 * @desc Typescript export class for AccessKey
 */

import { Entity } from 'labstep-web/models/entity.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

export class AccessKey extends Entity {
  static readonly entityName = 'access_key';

  get entityName(): string {
    return AccessKey.entityName;
  }

  constructor(data: Partial<AccessKey> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  uuid!: string;

  name!: string;

  expires_at!: string;

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
