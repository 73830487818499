/**
 * Labstep
 *
 * @module components/Layout/Menu/Right
 * @desc Right side bar. Shows profile and notifications. Shown only on mobile, tablet.
 */

import React from 'react';
import NotificationsPreviewList from 'labstep-web/components/Notification/List/Preview';
import SideMenu from 'labstep-web/core/SideMenu';
import styles from './styles.module.scss';
import { INotificationSideMenuProps } from './types';

export const NotificationSideMenu: React.FC<
  INotificationSideMenuProps
> = ({ viewComponent }) => (
  <SideMenu viewComponent={viewComponent} name="notification">
    <div className={styles.container}>
      <div className={styles.notificationsContainer}>
        <NotificationsPreviewList />
      </div>
    </div>
  </SideMenu>
);

export default NotificationSideMenu;
