/**
 * Labstep
 *
 * @module prosemirror/nodes/text
 * @desc Text node
 */

import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';

const spec: LabstepNode = {
  key: 'text',
  spec: {
    group: 'inline',
  },
};

export default spec;
