/**
 * Labstep
 */

import { routing } from 'labstep-web/services/navigation';
import { matchPath } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ENTITY_NAME_OPTIONS } from './EntityNameSelector/constants';

/**
 *
 * @description Checks if pathname matches one of the index routes
 * and returns the relevant entityName
 */
export const getInitialEntityName = (
  location: RouteComponentProps['location'],
): string => {
  const matchedEntityName = ENTITY_NAME_OPTIONS.find((e) =>
    matchPath(
      location.pathname,
      routing[`group_${e}s` as keyof typeof routing]({} as any),
    ),
  );
  return matchedEntityName || ENTITY_NAME_OPTIONS[0];
};
