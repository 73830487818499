/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/ProtocolTable
 * @desc Renders an ProtocolTable node
 */

import TableItem from 'labstep-web/components/Table/Item';
import { ProtocolTable } from 'labstep-web/models/protocol-table.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import Read from 'labstep-web/prosemirror/components/NodeView/Read';
import { useReactNodeView } from 'labstep-web/prosemirror/components/ReactNodeView';
import React from 'react';
import { IComponentProps } from './types';

export class ProtocolTableNode extends React.Component<IComponentProps> {
  // eslint-disable-next-line class-methods-use-this
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { id, timestamp, entity } = this.props;
    const { entityName } = ProtocolTable;
    const readProps = {
      entityName,
      id,
      timestamp,
    };

    return (
      <div contentEditable={false}>
        <Read {...readProps}>
          {({ entity: protocolTable }) => (
            <TableItem
              readOnly={!!timestamp}
              table={protocolTable}
              allowLocking={
                entity.entityName === Protocol.entityName ||
                entity.isTemplate
              }
            />
          )}
        </Read>
      </div>
    );
  }
}

const ProtocolTableNodeView = ({ entity, timestamp }) => {
  const context = useReactNodeView();
  const { guid } = context.node.attrs;

  return (
    <ProtocolTableNode
      id={guid}
      entity={entity}
      // readOnly={!context.view.editable}
      timestamp={timestamp}
    />
  );
};

export default ProtocolTableNodeView;
