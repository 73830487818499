/**
 * Labstep
 *
 * @module models/integration
 * @desc Typescript export class for Integration
 */

import React from 'react';
import { SemanticICONS } from 'semantic-ui-react';

export class Integration {
  static entityName = 'integration';

  get entityName(): string {
    return Integration.entityName;
  }

  constructor(data: Partial<Integration> = {}) {
    Object.assign(this, data);
  }

  id: number;

  name: string;

  url: string;

  author: string;

  image?: string;

  icon?: SemanticICONS;

  description: string;

  longDescription?: React.ReactNode;

  beta?: boolean;
}
