/**
 * Labstep
 *
 * @module screens/Authentication/Shared
 * @desc Shared elements
 */

import React from 'react';
import Link from 'labstep-web/core/Link';
import OutboundLink from 'labstep-web/core/OutboundLink';
import Icon from 'labstep-web/core/Icon';
import styles from './styles.module.scss';
import { IBenefitsProps } from './types';

export const SharedBody: React.FC = ({ children }) => (
  <div className={styles.sharedBody}>{children}</div>
);

const defaultBenefits = [
  'Effortless collaboration across teams, departments and countries.',
  'Modern, intuitive user interface for streamlined data entry.',
  'Customisable templates to save time and standardise record keeping.',
  'Connected modules for seemless workflows.',
  'Powerful API for automation and integration.',
];

export const Benefits: React.FC<IBenefitsProps> = ({
  header,
  benefits = defaultBenefits,
}) => (
  <div className={styles.benefits}>
    <h3>{header}</h3>
    {benefits.map((benefit) => (
      <div key={benefit}>
        <Icon name="check" /> {benefit}
      </div>
    ))}
  </div>
);

export const SharedFooter: React.FC = ({ children }) => (
  <div className={styles.sharedFooter}>{children}</div>
);

export const AuthenticationSharedForgotPassword = () => (
  <div>
    {'Forgotten your password? '}
    <Link to="forgot_password">Reset password</Link>
  </div>
);

export const SignupLinks = () => (
  <div>
    {'Don’t have an account yet? '}
    <Link to="signup">Create an account</Link>
  </div>
);

export const AlreadyHaveAnAccount = () => (
  <div>
    {'Already have an account? '}
    <Link to="login">Sign in</Link>
  </div>
);

export const TermsAndConditions = () => (
  <div>
    {'By signing up, you agree to our '}
    <OutboundLink
      target="_blank"
      to="https://www.labstep.com/terms-conditions"
    >
      Terms & Conditions
    </OutboundLink>
    {' & '}
    <OutboundLink
      target="_blank"
      to="https://www.labstep.com/privacy-policy"
    >
      Privacy Policy
    </OutboundLink>
  </div>
);
