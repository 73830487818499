/**
 * Labstep
 *
 * @module prosemirror/extensions/keymap
 * @desc Any other keymap that don't belong elsewhere
 */

import {
  joinDown,
  joinUp,
  lift,
  selectParentNode,
} from 'prosemirror-commands';
import { undoInputRule } from 'prosemirror-inputrules';
import { LabstepExtension } from '../types';

const ExtensionKeymap: LabstepExtension = {
  keymap: {
    Backspace: undoInputRule,
    'Alt-ArrowUp': joinUp,
    'Alt-ArrowDown': joinDown,
    'Mod-BracketLeft': lift,
    Escape: selectParentNode,
  },
};

export default ExtensionKeymap;
