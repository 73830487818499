/**
 * Labstep
 */

import { preSubmitType } from 'labstep-web/core/Form/Reusable/types';

/** Filter empty field values  */
export const preSubmit: preSubmitType = (values) =>
  Object.keys(values).reduce((result, key) => {
    if (values[key] === null) {
      return result;
    }
    return { ...result, [key]: values[key] };
  }, {});
