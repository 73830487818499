/**
 * Labstep
 *
 * @module screens/Device/Show/Center
 * @desc Device Show Center
 */

import React from 'react';
import DeviceActionMenu from 'labstep-web/components/Device/Action/Menu';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import EntityPrimaryInfoImage from 'labstep-web/components/Entity/PrimaryInfo/Image';
import EntityUserCard from 'labstep-web/components/EntityUser/Card';
import InformationCard from 'labstep-web/core/Card/Information';
import DeviceBookingManager from 'labstep-web/components/DeviceBooking/Manager';
import DeviceBreadcrumb from 'labstep-web/components/Device/Breadcrumb';
import { Action } from 'labstep-web/components/Device/Action/Menu/types';
import { IDeviceShowCenterProps } from './types';
import styles from './styles.module.scss';

export const DeviceShowCenter: React.FC<IDeviceShowCenterProps> = ({
  device,
}) => {
  return (
    <div id="device-show-screen" className={styles.container}>
      <InformationCard
        additionalActions={
          !device.is_template && <EntityUserCard entity={device} />
        }
        actionMenu={
          <DeviceActionMenu
            device={device}
            actions={[
              Action.set_template,
              Action.tag,
              Action.share,
              Action.duplicate,
              Action.delete,
              Action.transfer_ownership,
            ]}
          />
        }
        breadcrumb={<DeviceBreadcrumb device={device} />}
        primaryInfo={<EntityPrimaryInfoImage entity={device} />}
        secondaryInfo={<MetadataManager entity={device} />}
        bottom={
          !device.is_template && (
            <DeviceBookingManager device={device} />
          )
        }
      />
    </div>
  );
};

export default DeviceShowCenter;
