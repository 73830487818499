/**
 * Labstep
 *
 * @module prosemirror/nodes/blockquote
 * @desc Blockquote node
 */

import { wrapIn } from 'prosemirror-commands';
import { wrappingInputRule } from 'prosemirror-inputrules';
import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';
import { NodeType } from 'prosemirror-model';

// : (NodeType) → InputRule
// Given a blockquote node type, returns an input rule that turns `"> "`
// at the start of a textblock into a blockquote.
export function blockQuoteRule(nodeType: NodeType) {
  return wrappingInputRule(/^\s*>\s$/, nodeType);
}

const NodeBlockquote: LabstepNode = {
  key: 'blockquote',
  spec: {
    content: 'block+',
    group: 'block',
    defining: true,
    parseDOM: [{ tag: 'blockquote' }],
    toDOM() {
      return ['blockquote', 0];
    },
  },
  keymap: {
    'Ctrl->': (state, dispatch) =>
      wrapIn(state.schema.nodes[NodeBlockquote.key])(state, dispatch),
  },
  inputRules({ schema }) {
    return [blockQuoteRule(schema.nodes[NodeBlockquote.key])];
  },
};

export default NodeBlockquote;
