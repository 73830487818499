/**
 * Labstep
 *
 * @module core/Hoverable
 * @desc A component that renders a react element based on hovering another element
 */

import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { IHoverableProps } from './types';

const Hoverable: React.FC<IHoverableProps> = ({
  component,
  hoverable,
  className,
  show,
  dataTestId = '',
}) => (
  <div
    data-testid={dataTestId}
    className={classnames(styles.container, className)}
  >
    {hoverable}
    <div className={show ? undefined : 'to-appear'}>{component}</div>
  </div>
);

export default Hoverable;
