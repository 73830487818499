/**
 * Labstep
 *
 * @module components/ProtocolValue/Detail/Amount
 * @desc ProtocolValue Detail Amount
 */

import React from 'react';
import EntityDetailTable from 'labstep-web/components/Entity/Detail/Table';
import { IProtocolValueDetailAmountProps } from './types';
import { getAmountRows } from './utils';

export const ProtocolValueDetailAmount: React.FC<
  IProtocolValueDetailAmountProps
> = ({ protocolValue }) => (
  <EntityDetailTable
    columns={[protocolValue]}
    rows={getAmountRows(protocolValue)}
  />
);

export default ProtocolValueDetailAmount;
