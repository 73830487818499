/**
 * Labstep
 *
 * @module core/GoogleDoc
 */

import React from 'react';
import { IGoogleDocProps } from './types';

const GoogleDoc: React.FC<IGoogleDocProps> = ({ file }) => (
  <iframe
    title={file.name}
    src={file.path}
    style={{
      width: '100%',
      height: '100%',
      border: 'none',
    }}
  />
);

export default GoogleDoc;
