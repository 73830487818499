/**
 * Labstep
 *
 * @module components/File/Filter/Pane
 * @desc Filter pane for file
 */

import FileFilterExtensionList from 'labstep-web/components/File/Filter/Extension/List';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import FilterPane from 'labstep-web/components/Filter/Pane';
import { ICONS } from 'labstep-web/constants/icons';
import { STRINGS } from 'labstep-web/strings';
import React from 'react';

const FileFilterPane: React.FC = () => (
  <FilterPane
    filters={[Filter.created_by]}
    entityName="file"
    extraFilters={<FileFilterExtensionList />}
    icon={ICONS.file.primary}
    text={STRINGS.file.plural.capitalized}
    filterCreatedByText="Uploaded by"
  />
);

export default FileFilterPane;
