/**
 * Labstep
 *
 * @module screens/Authentication/LogoutJupyter
 * @desc LogoutJupyter
 */

import { UserLogoutContainer } from 'labstep-web/containers/User/Logout';
import React, { useEffect } from 'react';
import {
  IAuthenticationLogoutJupyterContainerProps,
  IAuthenticationLogoutJupyterProps,
} from './types';

export const AuthenticationLogoutJupyter: React.FC<
  IAuthenticationLogoutJupyterProps
> = ({ logout }) => {
  useEffect(() => {
    logout();
  }, []);

  return null;
};

export const AuthenticationLogoutJupyterContainer: React.FC<
  IAuthenticationLogoutJupyterContainerProps
> = () => (
  <UserLogoutContainer>
    {({ logout }): React.ReactElement => {
      return <AuthenticationLogoutJupyter logout={logout} />;
    }}
  </UserLogoutContainer>
);

export default AuthenticationLogoutJupyterContainer;
