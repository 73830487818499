/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/Show/Right/Devices
 * @desc Experiment Workflow Devices
 */

import ProtocolDeviceManager from 'labstep-web/components/ProtocolDevice/Manager';
import { ICONS } from 'labstep-web/constants/icons';
import ListCollapsible from 'labstep-web/core/List/Collapsible';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Experiment } from 'labstep-web/models/experiment.model';
import React from 'react';
import { IExperimentWorkflowShowRightDevicesProps } from './types';

export const ExperimentWorkflowShowRightDevices: React.FC<
  IExperimentWorkflowShowRightDevicesProps
> = ({ experimentWorkflow }) => (
  <>
    <ProtocolDeviceManager
      parent={experimentWorkflow.root_experiment}
      withEmptyState={!experimentWorkflow.protocol_device_count}
    />
    <ReadOnMountHOC
      entityName={Experiment.entityName}
      type="cursor"
      params={{
        is_root: false,
        experiment_workflow_id: experimentWorkflow.id,
      }}
      children={({ entities: experiments }) => (
        <ListCollapsible
          sections={experiments.map((experiment: Experiment) => ({
            icon: ICONS.experiment.primary,
            text: experiment.name,
            count: experiment.protocol_device_count,
            component: (
              <ProtocolDeviceManager
                parent={experiment}
                showCreateAction={false}
              />
            ),
          }))}
        />
      )}
    />
  </>
);
export default ExperimentWorkflowShowRightDevices;
