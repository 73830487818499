/**
 * Labstep
 *
 * @module components/Entity/Entity
 * @desc Table listing all entities
 */

import React from 'react';
import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import styles from './styles.module.scss';
import { ISortableHeaderProps } from './types';

const SortableHeader: React.FC<ISortableHeaderProps> = ({
  title,
  sortKey,
  searchParams,
  setParams,
}) => {
  const isOrderAsc = searchParams.sort === sortKey;
  const isOrderDesc = searchParams.sort === `-${sortKey}`;

  return (
    <span
      className={classnames({ [styles.sortable]: sortKey })}
      onClick={() => {
        if (searchParams.sort === sortKey) {
          setParams({ sort: `-${sortKey}` });
        } else {
          setParams({ sort: sortKey });
        }
      }}
    >
      {title}
      <Icon
        name="long arrow alternate up"
        color={isOrderAsc ? 'blue' : undefined}
        disabled={!isOrderAsc}
      />
      <Icon
        name="long arrow alternate down"
        color={isOrderDesc ? 'blue' : undefined}
        disabled={!isOrderDesc}
        className={styles.arrowDown}
      />
    </span>
  );
};

export default SortableHeader;
