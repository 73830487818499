/**
 * Labstep
 *
 * @module containers/ProseMirror/ReferencingSearch
 * @desc Container for reading entities from referencing search
 */

import { readEntitiesPage } from 'labstep-web/state/actions/entity';
import { setProseMirrorReferencingSearchAction } from 'labstep-web/state/actions/prosemirror-referencing-search';
import {
  selectReadPageEntities,
  selectReadPageStatus,
  selectReadPageTotal,
} from 'labstep-web/state/selectors/entity';
import { selectProseMirrorReferencingSearch } from 'labstep-web/state/selectors/prosemirror-referencing-search';
import { connect } from 'react-redux';
import { IWithProseMirrorReferencingSearchInjectedProps } from './types';

export const SEARCH_COUNT = 3;

export const mapStateToProps = (state: any) => {
  let entities = [];
  let status = false;
  let total = false;
  let creatableEntities = [];

  const proseMirrorReferencingSearch =
    selectProseMirrorReferencingSearch(state);

  if (proseMirrorReferencingSearch) {
    const {
      searchQuery,
      experimentWorkflowId,
      experimentNotId,
      entityName,
      groupId,
      creatableEntityName,
    } = proseMirrorReferencingSearch;

    const params = {
      experiment_workflow_id: experimentWorkflowId,
      experiment_not_id: experimentNotId,
      ...(searchQuery && {
        search_query: searchQuery,
      }),
      count: SEARCH_COUNT,
    };

    entities = selectReadPageEntities(state, entityName, params, 1);
    status = selectReadPageStatus(state, entityName, params, 1);
    total = selectReadPageTotal(state, entityName, params);

    if (creatableEntityName) {
      creatableEntities = selectReadPageEntities(
        state,
        creatableEntityName,
        {
          group_id: groupId,
          ...(searchQuery && {
            search_query: searchQuery,
          }),
          count: SEARCH_COUNT,
        },
        1,
      );
    }
  }

  return {
    entities,
    status,
    total,
    creatableEntities,
  };
};

export const mapDispatchToProps = (
  dispatch: (...args: any[]) => void,
) => ({
  setSearchParams({
    experimentWorkflowId,
    experimentNotId,
    entityName,
    searchQuery,
    groupId,
    creatableEntityName,
  }) {
    dispatch(
      setProseMirrorReferencingSearchAction({
        experimentWorkflowId,
        experimentNotId,
        entityName,
        searchQuery,
        groupId,
        creatableEntityName,
      }),
    );
  },
  searchSubExperiments({
    experimentWorkflowId,
    experimentNotId,
    entityName,
    searchQuery,
  }) {
    dispatch(
      readEntitiesPage(
        entityName,
        {
          experiment_workflow_id: experimentWorkflowId,
          experiment_not_id: experimentNotId,
          ...(searchQuery && {
            search_query: searchQuery,
          }),
          count: SEARCH_COUNT,
        },
        1,
      ),
    );
  },
  searchCreatable({ searchQuery, groupId, creatableEntityName }) {
    dispatch(
      readEntitiesPage(
        creatableEntityName,
        {
          group_id: groupId,
          ...(searchQuery && {
            search_query: searchQuery,
          }),
          count: SEARCH_COUNT,
        },
        1,
      ),
    );
  },
});

export const withProseMirrorReferencingSearch = <
  P extends IWithProseMirrorReferencingSearchInjectedProps,
  S,
>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<
    P,
    Exclude<
      keyof P,
      keyof IWithProseMirrorReferencingSearchInjectedProps
    >
  >
> => connect(mapStateToProps, mapDispatchToProps)(Component as any);
