/**
 * Labstep
 */

import hotToast from 'react-hot-toast';
import bugsnagService from 'labstep-web/services/bugsnag.service';
import React, { useCallback } from 'react';
import ReactDropzone from 'react-dropzone';
import CoreToast from 'labstep-web/core/Toast';
import DropzoneChildren from './Children';
import styles from './styles.module.scss';
import { IDropzoneProps } from './types';

export const Dropzone: React.FC<IDropzoneProps> = ({
  children,
  showDragEnter,
  ...rest
}) => {
  const [dragEntered, setDragEntered] = React.useState(false);
  const onDropRejected = useCallback<
    NonNullable<IDropzoneProps['onDropRejected']>
  >((files) => {
    bugsnagService.notify('File upload error', { files }, 'info');
    const firstMessage = files[0].errors[0].message;
    const id = 'file-upload-error';
    hotToast.error(
      () => (
        <CoreToast
          message={firstMessage}
          options={{ header: 'Error' }}
          close={(): void => hotToast.dismiss(id)}
        />
      ),
      {
        duration: 5000,
        position: 'top-right',
        id,
      },
    );
  }, []);
  return (
    <ReactDropzone
      onDragEnter={(): void => setDragEntered(true)}
      onDragLeave={(): void => setDragEntered(false)}
      onDrop={(): void => setDragEntered(false)}
      onDropRejected={onDropRejected}
      noDragEventsBubbling
      {...rest}
    >
      {({ getRootProps, getInputProps }): React.ReactElement => (
        <div {...getRootProps()} className={styles.container}>
          {dragEntered && showDragEnter && (
            <div id="dropzone" className={styles.dragAndDropGraphic}>
              <div>Drag Files here...</div>
            </div>
          )}
          <input {...getInputProps()} />
          {children || <DropzoneChildren />}
        </div>
      )}
    </ReactDropzone>
  );
};

Dropzone.defaultProps = {
  maxSize: 100 * 1e6, // 100mb
  minSize: 1,
};

export default Dropzone;
