import {
  TypeResetActionCreator,
  TypeSetEntityViewParamsActionCreator,
  TypeSetUiCountPerPageActionCreator,
  TypeSetUiExperimentWorkflowViewActionCreator,
  TypeSetUiSearchParamsActionCreator,
  TypeToggleUiFilterActionCreator,
} from './ui-persistent.types';

export const toggleUiFilter: TypeToggleUiFilterActionCreator = (
  entityName,
  filter,
) => ({
  type: 'SET_UI_FILTER',
  entityName,
  filter,
});

export const setUiSearchParams: TypeSetUiSearchParamsActionCreator = (
  pathname,
  params,
) => ({
  type: 'SET_UI_SEARCH_PARAMS',
  pathname,
  params,
});

export const setUiExperimentWorkflowView: TypeSetUiExperimentWorkflowViewActionCreator =
  (view) => ({
    type: 'SET_UI_EXPERIMENT_WORKFLOW_VIEW',
    view,
  });

export const setUiCountPerPage: TypeSetUiCountPerPageActionCreator = (
  count,
) => ({
  type: 'SET_UI_COUNT_PER_PAGE',
  count,
});

export const reset: TypeResetActionCreator = () => ({
  type: 'RESET_UI_PERISTENT',
});

export const setEntityViewParams: TypeSetEntityViewParamsActionCreator =
  (groupId, entityName, params) => ({
    type: 'SET_ENTITY_VIEW_PARAMS',
    meta: {
      body: {
        entityName,
        params,
        groupId,
      },
    },
  });
