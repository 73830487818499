/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Delete
 * @desc Action to delete experiment workflow
 */

import React from 'react';
import DeleteAction from 'labstep-web/components/Entity/Action/Delete';
import { IExperimentWorkflowDeleteActionProps } from './types';

export const ExperimentWorkflowDeleteAction: React.FC<
  IExperimentWorkflowDeleteActionProps
> = ({ experimentWorkflow }) => (
  <DeleteAction entity={experimentWorkflow} />
);

export default ExperimentWorkflowDeleteAction;
