/**
 * Labstep
 *
 * @module prosemirror/marks/link
 * @desc Link mark
 */

import { DOMOutputSpec } from 'prosemirror-model';
import { LabstepMark } from 'labstep-web/prosemirror/marks/types';
import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { string } from 'yup';

interface Link {
  attrs: {
    href: string;
    title: string;
  };
}

export const fields: IFieldWithoutActionProps[] = [
  {
    fieldType: FieldType.Text,
    name: 'url',
    validation: string().required().url(),
    placeholder: 'Paste or type your link',
    fieldLabel: 'Address',
  },
  {
    fieldType: FieldType.Text,
    name: 'text',
    validation: string(),
    placeholder: 'e.g. My link to a different page',
    fieldLabel: 'Text to display',
  },
];

const MarkLink: LabstepMark = {
  key: 'link',
  spec: {
    attrs: {
      href: {},
      title: { default: null },
    },
    inclusive: false,
    parseDOM: [
      {
        tag: 'a[href]',
        getAttrs(dom) {
          if (dom instanceof HTMLElement) {
            return {
              href: dom.getAttribute('href'),
              title: dom.getAttribute('title'),
            };
          }
          return null;
        },
      },
    ],
    toDOM(node): DOMOutputSpec {
      const { href, title } = node.attrs as Link['attrs'];
      return ['a', { href, title, target: '_blank' }, 0];
    },
  },
};

export default MarkLink;
