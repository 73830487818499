/**
 * Labstep
 */

import { IActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { OrderRequest } from 'labstep-web/models/order-request.model';

// eslint-disable-next-line no-shadow
export enum Action {
  view = 'view',
  share = 'share',
  duplicate = 'duplicate',
  tag = 'tag',
  assign = 'assign',
  delete = 'delete',
  remove_purchase_order = 'remove_purchase_order',
  set_purchase_order = 'set_purchase_order',
  revoke = 'revoke',
  transfer_ownership = 'transfer_ownership',
}

export interface IOrderRequestActionMenuProps
  extends Pick<
    IActionMenuProps,
    Exclude<keyof IActionMenuProps, 'children'>
  > {
  orderRequest: OrderRequest;
  actions: Action[];
}
