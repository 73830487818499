/**
 * Labstep
 *
 * @module components/DeviceBooking/Detail
 * @desc Detail for Device Booking
 */

import DeviceBookingDeleteAction from 'labstep-web/components/DeviceBooking/Action/Delete';
import DeviceBookingFormShowEditDescription from 'labstep-web/components/DeviceBooking/Form/ShowEdit/Description';
import DeviceBookingFormShowEditEndedAt from 'labstep-web/components/DeviceBooking/Form/ShowEdit/EndedAt';
import DeviceBookingFormShowEditStartedAt from 'labstep-web/components/DeviceBooking/Form/ShowEdit/StartedAt';
import EntityLink from 'labstep-web/components/Entity/Link';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import TextWithHelp from 'labstep-web/core/Text/WithHelp';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { DeviceBooking } from 'labstep-web/models/device-booking.model';
import React from 'react';
import { IDeviceBookingDetailProps } from './types';

export const rows = [
  {
    header: (
      <TextWithHelp
        text="Device"
        icon={ICONS.device.primary}
        header
      />
    ),
    content: (deviceBooking: DeviceBooking) => (
      <EntityLink entity={deviceBooking.device} />
    ),
    dataTestId: 'device-booking-device',
  },
  {
    header: (
      <TextWithHelp text="Start Time" icon="calendar check" header />
    ),
    content: (deviceBooking: DeviceBooking) => (
      <DeviceBookingFormShowEditStartedAt
        deviceBooking={deviceBooking}
      />
    ),
    dataTestId: 'device-booking-start',
  },
  {
    header: (
      <TextWithHelp text="End Time" icon="calendar times" header />
    ),
    content: (deviceBooking: DeviceBooking) => (
      <DeviceBookingFormShowEditEndedAt
        deviceBooking={deviceBooking}
      />
    ),
    dataTestId: 'device-booking-end',
  },
  {
    header: (
      <TextWithHelp text="Description" icon="align left" header />
    ),
    content: (deviceBooking: DeviceBooking) => (
      <DeviceBookingFormShowEditDescription
        deviceBooking={deviceBooking}
      />
    ),
    dataTestId: 'device-booking-description',
  },
];

export const DeviceBookingDetail: React.FC<
  IDeviceBookingDetailProps
> = ({ deviceBookingId, setActiveEditModal }) => (
  <ReadOnMountHOC
    type="entity"
    entityName={DeviceBooking.entityName}
    id={deviceBookingId}
    loading={{ loader: 'placeholder' }}
    children={({ entity: deviceBooking }) => (
      <>
        <HorizontalTable
          padded
          borderless
          headerProps={{ width: 6 }}
          rows={rows}
          columns={[deviceBooking]}
        />
        <DeviceBookingDeleteAction
          deviceBooking={deviceBooking}
          actionComponentProps={{
            type: 'button',
            text: 'Cancel Booking',
            elementProps: {
              color: 'red',
              basic: true,
              fluid: true,
            },
          }}
          skipConfirmation
          options={{ onSuccess: () => setActiveEditModal(null) }}
        />
      </>
    )}
  />
);

export default withActiveEditModal(DeviceBookingDetail);
