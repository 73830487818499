/**
 * Labstep
 *
 * @module state/constants/ui
 * @desc Constants for UI
 */

export const SET_ACTIVE_ENTITY = 'SET_ACTIVE_ENTITY';
export const CLEAR_ACTIVE_ENTITY = 'CLEAR_ACTIVE_ENTITY';
export const SET_CONNECTIVITY = 'SET_CONNECTIVITY';
export const TOGGLE_NEW_CONTENT = 'TOGGLE_NEW_CONTENT';
