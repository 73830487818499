/**
 * Labstep
 *
 * @module components/PurchaseOrder/Card/EmptyState
 * @desc Empty state for group
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import PurchaseOrderCreateAction from 'labstep-web/components/PurchaseOrder/Action/Create';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import { IPurchaseOrderEmptyStateProps } from './types';

export const PurchaseOrderEmptyState: React.FC<
  IPurchaseOrderEmptyStateProps
> = (props) => (
  <EmptyState
    title={`No ${PurchaseOrder.getHumanReadableEntityName(true)}`}
    explanation={
      <div>
        Group your order requests into orders to make them easier to
        track and manage. If you are interested in having your orders
        fulfilled by Labstep contact{' '}
        <a href="mailto:ordering@labstep.com">ordering@labstep.com</a>
      </div>
    }
    src="/img/empty-state/orders.svg"
    action={<PurchaseOrderCreateAction />}
    {...props}
  />
);

export default PurchaseOrderEmptyState;
