/**
 * Labstep
 *
 * @desc Create Form for templatable entity
 */

import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import FormCreate from 'labstep-web/core/Form/Create';
import React from 'react';
import { fieldEntityTemplate } from '../../fields';
import { IEntityFormCreateProps } from './types';

export const EntityFormCreateTemplatable: React.FC<
  IEntityFormCreateProps
> = ({
  entityName,
  templateId,
  activeGroup,
  defaultValues,
  showLoaderWhileCreating,
  options,
}) => {
  const fields = [];
  const body: any = {};
  if (!templateId) {
    const fieldTemplate = fieldEntityTemplate(entityName);
    fields.push({
      ...fieldTemplate,
      elementProps: {
        ...fieldTemplate.elementProps,
        params: { group_id: activeGroup?.id },
      },
    });
  } else {
    body.template_id = templateId;
    body.group_id = activeGroup?.id;
  }

  return (
    <FormCreate
      body={body}
      autoFocus
      entityName={entityName}
      fields={fields}
      defaultValues={defaultValues}
      options={options}
      showLoaderWhileCreating={showLoaderWhileCreating}
    />
  );
};

export default withActiveGroup(EntityFormCreateTemplatable);
