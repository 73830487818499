/**
 * Labstep
 *
 * @module components/JupyterNotebook/Form/ShowEdit/Automation
 * @desc Form to schedule automation for JupyterNotebook
 */

import { getFieldAutomation } from 'labstep-web/components/JupyterNotebook/Form/fields';
import { FieldTypeReactSelect } from 'labstep-web/core/Form/Reusable/types';
import FormShowEditSelect from 'labstep-web/core/Form/ShowEdit/Select';
import { JupyterNotebook } from 'labstep-web/models/jupyter-notebook.model';
import { isMatch } from 'lodash';
import React from 'react';
import {
  AutomationOption,
  IJupyterNotebookFormShowEditAutomationProps,
} from './types';

const getSelectedAutomation = (
  field: FieldTypeReactSelect,
  jupyterNotebook: JupyterNotebook,
) => {
  const { options } = field.elementProps;

  const matchingOption: AutomationOption | undefined = (
    options as AutomationOption[]
  ).find((option) => {
    if (option.value === null) {
      return jupyterNotebook.automation === null;
    }
    return isMatch(
      jupyterNotebook.automation,
      (option as AutomationOption).value,
    );
  });

  if (!matchingOption) {
    return 'Custom Rule';
  }

  return matchingOption.label;
};

const JupyterNotebookFormShowEditAutomation: React.FC<
  IJupyterNotebookFormShowEditAutomationProps
> = ({ jupyterNotebook }) => {
  const field = getFieldAutomation(jupyterNotebook.id);
  return (
    <FormShowEditSelect
      entity={jupyterNotebook}
      field={field}
      isClearable={false}
      hasValue
    >
      {getSelectedAutomation(
        field as FieldTypeReactSelect,
        jupyterNotebook,
      )}
    </FormShowEditSelect>
  );
};

export default JupyterNotebookFormShowEditAutomation;
