/**
 * Labstep
 *
 * @module components/ExperimentWorkflowLink/List
 * @desc List of ExperimentWorkflowLinks
 */

import React from 'react';
import List from 'labstep-web/core/List';
import EntityDraggable from 'labstep-web/components/Entity/Draggable';
import ExperimentWorkflowLinkItem from '../Item';
import { IExperimentWorkflowLinkListProps } from './types';

export const ExperimentWorkflowLinkList: React.FC<
  IExperimentWorkflowLinkListProps
> = ({ experimentWorkflowLinks, showBackLinks }) => (
  <List id="experiment-workflow-link-list">
    {experimentWorkflowLinks.map((experimentWorkflowLink) => {
      const item = (
        <ExperimentWorkflowLinkItem
          key={experimentWorkflowLink.guid}
          experimentWorkflowLink={experimentWorkflowLink}
          showBackLinks={showBackLinks}
        />
      );
      return showBackLinks ? (
        item
      ) : (
        <EntityDraggable
          key={experimentWorkflowLink.guid}
          entityGuid={experimentWorkflowLink.guid}
          entityName={experimentWorkflowLink.entityName}
          children={item}
        />
      );
    })}
  </List>
);

export default ExperimentWorkflowLinkList;
