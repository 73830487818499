/**
 * Labstep
 *
 * @module models/entity-state
 * @desc Typescript export class for EntityState
 */

import { Type } from 'class-transformer';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { PermissionActions } from 'labstep-web/typings';
import { isEqual } from 'lodash';
import { EntityStateWorkflow } from './entity-state-workflow.model';
import { EntityUserRoleRequirement } from './entity-user-role-requirement.model';
import { Entity } from './entity.model';
import { SignatureRequirement } from './signature-requirement.model';
import { Signature } from './signature.model';

// eslint-disable-next-line no-shadow
export enum EntityStateType {
  unstarted = 'Unstarted',
  started = 'Started',
  completed = 'Completed',
}

export const TARGET_ALLOWED_ACTIONS_LOCK_OPTIONS = {
  Unlocked: {
    permissions: ['*'],
    target_allowed_actions_lock: null,
    is_lock: false,
  },
  'Comments Only': {
    permissions: [
      'comment:*',
      'file:*',
      'entity_user:*',
      'experiment_workflow:edit:entity_state',
      'signature:create',
      'signature_request:create',
    ],
    target_allowed_actions_lock: [
      'comment:*',
      'file:*',
      'entity_user:*',
      'experiment_workflow:edit:entity_state',
      'signature:create',
      'signature_request:create',
    ],
    is_lock: true,
  },
  'Full Lock': {
    permissions: [],
    target_allowed_actions_lock: [],
    is_lock: true,
  },
};

export class EntityState extends Entity {
  public static readonly entityName = 'entity_state';

  public static readonly entityClass = 'entity_state' as const;

  public get entityName(): string {
    return EntityState.entityName;
  }

  public constructor(data: Partial<EntityState> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: string;

  public name!: string;

  public workflow_id!: number;

  public position!: number;

  public type!: EntityStateType;

  public description!: string;

  public icon!: string;

  public color!: string;

  public allowed_actions!: PermissionActions[];

  public _target_allowed_actions_lock!: string[];

  public is_lock!: boolean;

  @Type(() => EntityStateWorkflow)
  public entity_state_workflow!: EntityStateWorkflow;

  @Type(() => EntityUserRoleRequirement)
  public _entity_user_role_requirements!: EntityUserRoleRequirement[];

  @Type(() => SignatureRequirement)
  public _signature_requirements!: SignatureRequirement[];

  public get signature_requirements(): SignatureRequirement[] {
    return this._signature_requirements.filter((e) => !e.deleted_at);
  }

  public set signature_requirements(
    signatureRequirements: SignatureRequirement[],
  ) {
    this._signature_requirements = signatureRequirements;
  }

  public get entity_user_role_requirements(): EntityUserRoleRequirement[] {
    return this._entity_user_role_requirements.filter(
      (e) => !e.deleted_at,
    );
  }

  public set entity_user_role_requirements(
    entityUserRoleRequirements: EntityUserRoleRequirement[],
  ) {
    this._entity_user_role_requirements = entityUserRoleRequirements;
  }

  public get target_allowed_actions_lock(): string[] {
    if (this._target_allowed_actions_lock === null) {
      return TARGET_ALLOWED_ACTIONS_LOCK_OPTIONS.Unlocked.permissions;
    }
    return this._target_allowed_actions_lock;
  }

  public set target_allowed_actions_lock(value: string[]) {
    this._target_allowed_actions_lock = value;
  }

  public get rolesToBeAssigned(): EntityUserRoleRequirement[] {
    return this._entity_user_role_requirements.filter(
      (e) => !e.deleted_at && !e.automation,
    );
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }

  get numberRequiredSignatures() {
    return this.entity_user_role_requirements.reduce(
      (acc, requirement) => acc + requirement.number_required,
      0,
    );
  }

  get printPermissions(): string {
    return (
      Object.keys(TARGET_ALLOWED_ACTIONS_LOCK_OPTIONS).find((key) =>
        isEqual(
          TARGET_ALLOWED_ACTIONS_LOCK_OPTIONS[
            key as keyof typeof TARGET_ALLOWED_ACTIONS_LOCK_OPTIONS
          ].permissions,
          this.target_allowed_actions_lock,
        ),
      ) || ''
    );
  }

  public getSignaturesAtRound(
    signatures: Signature[],
    round: number,
  ) {
    return signatures
      .filter((signature) => signature.entity_state_round === round)
      .filter((signature) =>
        this.entity_user_role_requirements.some(
          (entityUserRoleRequirement) =>
            entityUserRoleRequirement.id ===
            signature.entity_user_role_requirement_id,
        ),
      );
  }
}
