/**
 * Labstep
 */

import { sortEntityStatesByPosition } from 'labstep-web/components/EntityStateWorkflow/StatusIndicator/utils';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';

export const getStatesBeforeCurrentState = (
  experimentWorkflow: ExperimentWorkflow,
  inclusive = false,
) => {
  const listOfSortedState = sortEntityStatesByPosition(
    experimentWorkflow.entity_state_workflow?.entity_states || [],
  );

  const indexOfCurrentState = listOfSortedState.findIndex(
    (entityState) =>
      experimentWorkflow.entity_state.id === entityState.id,
  );

  return listOfSortedState.slice(
    0,
    inclusive ? indexOfCurrentState + 1 : indexOfCurrentState,
  );
};
