/**
 * Labstep
 *
 * @module components/DeviceData/EmptyState
 * @desc Empty state for group
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Button from 'labstep-web/core/Button';
import { DeviceData } from 'labstep-web/models/device-data.model';

export const DeviceDataEmptyState: React.FC = () => (
  <EmptyState
    title={`No ${DeviceData.getHumanReadableEntityName(true)}`}
    explanation="To start sending data from this device see our guide on how to get set up with Devices on Labstep."
    src="/img/empty-state/data.svg"
    action={
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://help.labstep.com/en/articles/5694792-setting-up-a-device-a-complete-guide"
      >
        <Button primary>See Documentation</Button>
      </a>
    }
  />
);

export default DeviceDataEmptyState;
