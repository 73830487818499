/**
 * Labstep
 *
 * @module components/Entity/Search
 * @desc Generic component for rendering a list of entities with search bar + filters
 */

import React from 'react';
import EntityEmptyState from 'labstep-web/components/Entity/Card/EmptyState';
import Placeholder from 'labstep-web/core/Placeholder';
import { useParamsContext } from 'labstep-web/hoc/Params/context';
import { getStatusIsFetching } from './utils';
import { IEntitySearchChildrenProps } from './types';

export const EntitySearchChildren: React.FC<
  IEntitySearchChildrenProps
> = ({
  showEmptyStateOnSearchOnly,
  tableFormat,
  status,
  entities,
  entityName,
  noResultsMessage,
  noResultsMessageOnSearch,
  initialSearchParams,
  children,
  read,
  useAllPages,
  statusAllPages,
  readNextPage,
  errorComponent,
}) => {
  const { searchParams, setParams, addPostFilter } =
    useParamsContext();

  if ([400, 500].includes(status?.error?.status) && errorComponent) {
    return errorComponent;
  }

  const notFetching =
    tableFormat || !(status && status.isFetching && !status.cached);
  if (notFetching) {
    const noEmptyState =
      showEmptyStateOnSearchOnly && !('search_query' in searchParams);
    const isEmptyState =
      entities.length === 0 && !tableFormat && !noEmptyState;
    const emptyState = (
      <EntityEmptyState
        entityName={entityName}
        noResultsMessage={noResultsMessage}
        noResultsMessageOnSearch={noResultsMessageOnSearch}
        searchParams={searchParams}
        initialSearchParams={initialSearchParams}
      />
    );
    if (isEmptyState) {
      return emptyState;
    }
    return children({
      read,
      entities,
      status: useAllPages
        ? getStatusIsFetching(statusAllPages)
        : status,
      searchParams,
      setParams,
      addPostFilter,
      readNextPage,
      emptyState,
    });
  }

  return <Placeholder />;
};

export default EntitySearchChildren;
