/**
 * Labstep
 *
 * @module services/ag-grid-reporting
 * @desc AGGrid Service Reporting
 */

import { ColDef } from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import Link from 'labstep-web/core/Link';
import { Metadata } from 'labstep-web/models/metadata';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { get } from 'lodash';
import React from 'react';

export class AGGridEntityReportingService {
  public static metadataCellRenderer = (
    metadatas: Metadata[],
    metadata: Metadata,
  ) => {
    const foundMetadata = metadatas.find(
      (dataMetadata: Metadata) =>
        dataMetadata.label === metadata.label,
    );
    if (!foundMetadata) {
      return '';
    }
    return foundMetadata.display_value || '';
  };

  public static protocolValueCellRenderer = (
    protocolValues: ProtocolValue[],
    protocolValue: ProtocolValue,
    path: string,
  ) => {
    const foundProtocolValue =
      AGGridEntityReportingService.getProtocolValue(
        protocolValues,
        protocolValue,
      );
    if (!foundProtocolValue) {
      return '';
    }
    return get(foundProtocolValue, path) || '';
  };

  public static getProtocolValue = (
    protocolValues: ProtocolValue[],
    protocolValue: ProtocolValue,
  ) =>
    protocolValues.find(
      (dataProtocolValue: ProtocolValue) =>
        dataProtocolValue.name === protocolValue.name,
    );

  public static getColDef = (protocol: Protocol): ColDef[] => {
    const columnDefs: ColDef[] = [
      {
        colId: `experiment_name`,
        headerName: 'Experiment',
        headerComponentParams: {
          icon: ICONS.experiment_workflow.primary,
        },
        cellRenderer: (
          params: CellRendererParams<ProtocolCondition>,
        ) => (
          <Link
            to="experiment_workflow_show"
            params={{
              id: params.node.data.experiment.experiment_workflow
                .guid,
            }}
            label={
              params.node.data.experiment.experiment_workflow.name
            }
          >
            {params.node.data.experiment.experiment_workflow.name}
          </Link>
        ),
      },
    ];

    let metadataKey = 0;
    protocol.metadatas_without_variable_template.forEach(
      (metadata) => {
        columnDefs.push({
          colId: `metadata_${metadataKey}`,
          headerName: metadata.label || '',
          headerComponentParams: {
            icon: ICONS.metadata.primary,
          },
          cellRenderer: (
            params: CellRendererParams<ProtocolCondition>,
          ) =>
            AGGridEntityReportingService.metadataCellRenderer(
              [
                ...params.node.data.metadatas_constant,
                ...params.node.data.metadatas_variable,
              ],
              metadata,
            ),
        });
        metadataKey += 1;
      },
    );

    const protocolValueKey = 0;
    protocol.protocol_values_without_variable_template.forEach(
      (protocolValue) => {
        columnDefs.push({
          colId: `protocol_value_${protocolValueKey}`,
          headerName: `Amount`,
          headerComponentParams: {
            icon: ICONS.protocol_value.info.amount_used,
            secondaryHeader: protocolValue.name,
          },
          cellRenderer: (
            params: CellRendererParams<ProtocolCondition>,
          ) =>
            AGGridEntityReportingService.protocolValueCellRenderer(
              [
                ...params.node.data.protocol_values_constant,
                ...params.node.data.protocol_values_variable,
              ],
              protocolValue,
              'amount',
            ),
        });
        columnDefs.push({
          colId: `protocol_value_resource_item_${protocolValueKey}`,
          headerName: protocolValue.name || '',
          headerComponentParams: {
            icon: ICONS.resource_item.primary,
          },
          cellRenderer: (
            params: CellRendererParams<ProtocolCondition>,
          ) => {
            const foundProtocolValue =
              AGGridEntityReportingService.getProtocolValue(
                [
                  ...params.node.data.protocol_values_constant,
                  ...params.node.data.protocol_values_variable,
                ],
                protocolValue,
              );
            if (
              !foundProtocolValue ||
              !foundProtocolValue.resourceItem
            ) {
              return '';
            }
            return (
              <Link
                to="resource_item_show"
                params={{
                  id: foundProtocolValue.resourceItem.guid,
                }}
                label={foundProtocolValue.resourceItem.name || ''}
              >
                {foundProtocolValue.resourceItem.name}
              </Link>
            );
          },
        });

        let resourceItemMetadataKey = 0;
        protocolValue.resource_template?.resource_item_template?.metadata_thread.metadatas.forEach(
          (metadata) => {
            columnDefs.push({
              colId: `protocol_value_resource_template_resource_item_template_metadata_${resourceItemMetadataKey}`,
              headerName: `${metadata.label}`,
              headerComponentParams: {
                icon: ICONS.metadata.secondary,
                cornerIcon: ICONS.resource_item.primary,
                secondaryHeader: protocolValue.name,
              },
              cellRenderer: (
                params: CellRendererParams<ProtocolCondition>,
              ) => {
                const foundProtocolValue =
                  AGGridEntityReportingService.getProtocolValue(
                    [
                      ...params.node.data.protocol_values_constant,
                      ...params.node.data.protocol_values_variable,
                    ],
                    protocolValue,
                  );
                if (
                  !foundProtocolValue ||
                  !foundProtocolValue.resourceItem
                ) {
                  return '';
                }
                return AGGridEntityReportingService.metadataCellRenderer(
                  foundProtocolValue.resourceItem!.metadata_thread
                    .metadatas,
                  metadata,
                );
              },
            });
            resourceItemMetadataKey += 1;
          },
        );

        let resourceMetadataKey = 0;
        if (protocolValue.resource_template) {
          protocolValue.resource_template?.metadata_thread.metadatas.forEach(
            (metadata) => {
              columnDefs.push({
                colId: `protocol_value_resource_template_metadata_${resourceMetadataKey}`,
                headerName: `${metadata.label}`,
                headerComponentParams: {
                  icon: ICONS.metadata.secondary,
                  cornerIcon: ICONS.resource.primary,
                  secondaryHeader: protocolValue.name,
                },
                cellRenderer: (
                  params: CellRendererParams<ProtocolCondition>,
                ) => {
                  const foundProtocolValue =
                    AGGridEntityReportingService.getProtocolValue(
                      [
                        ...params.node.data.protocol_values_constant,
                        ...params.node.data.protocol_values_variable,
                      ],
                      protocolValue,
                    );
                  if (
                    !foundProtocolValue ||
                    !foundProtocolValue.resourceItem
                  ) {
                    return '';
                  }
                  return AGGridEntityReportingService.metadataCellRenderer(
                    foundProtocolValue.resourceItem!.resource
                      .metadata_thread.metadatas,
                    metadata,
                  );
                },
              });
              resourceMetadataKey += 1;
            },
          );
        }

        resourceItemMetadataKey += 1;
      },
    );

    return columnDefs;
  };
}
