/**
 * Labstep
 *
 * @module components/Device/Action/Delete
 * @desc Action to delete
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { IDeviceDeleteActionProps } from './types';

export const DeviceDeleteAction: React.FC<
  IDeviceDeleteActionProps
> = ({ device }) => <EntityActionDelete entity={device} />;

export default DeviceDeleteAction;
