/**
 * Labstep
 *
 * @module components/JupyterNotebook/Form
 * @desc Form to schedule automation for JupyterNotebook
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import {
  AUTOMATION_TRIGGER_INTERVAL_LABELS,
  AutomationTriggerInterval,
} from 'labstep-web/models/automation.model';
import rules from 'labstep-web/services/validation/rules';

export const automationOff = {
  label: 'Off',
  value: null,
};

export const getFixedDate = (
  jupyterNotebookId: string,
  fixedDate?: Date,
) => ({
  label: 'Run at a fixed date',
  value: {
    type: 'jupyter_notebook',
    trigger_type: 'fixed_date',
    trigger_at: fixedDate,
    filter: [],
    action: 'jupyter_instance_run',
    payload: { jupyter_notebook_id: jupyterNotebookId },
  },
});

export const getSchedule = (
  jupyterNotebookId: string,
  triggerInterval: string,
  label: string,
) => ({
  label,
  value: {
    type: 'jupyter_notebook',
    trigger_type: 'schedule',
    trigger_interval: triggerInterval,
    filter: [],
    action: 'jupyter_instance_run',
    payload: { jupyter_notebook_id: jupyterNotebookId },
  },
});

export const getFieldAutomation = (
  jupyterNotebookId: string,
  fixedDate?: Date,
): IFieldWithoutActionProps => ({
  name: 'automation',
  validation: rules.jupyter_notebook.automation,
  fieldType: FieldType.ReactSelect,
  elementProps: {
    options: [
      automationOff,
      getFixedDate(jupyterNotebookId, fixedDate),
      getSchedule(
        jupyterNotebookId,
        AutomationTriggerInterval.fiveMinutes,
        AUTOMATION_TRIGGER_INTERVAL_LABELS[
          AutomationTriggerInterval.fiveMinutes
        ],
      ),
      getSchedule(
        jupyterNotebookId,
        AutomationTriggerInterval.halfHour,
        AUTOMATION_TRIGGER_INTERVAL_LABELS[
          AutomationTriggerInterval.halfHour
        ],
      ),
      getSchedule(
        jupyterNotebookId,
        AutomationTriggerInterval.hour,
        AUTOMATION_TRIGGER_INTERVAL_LABELS[
          AutomationTriggerInterval.hour
        ],
      ),
      getSchedule(
        jupyterNotebookId,
        AutomationTriggerInterval.day,
        AUTOMATION_TRIGGER_INTERVAL_LABELS[
          AutomationTriggerInterval.day
        ],
      ),
      getSchedule(
        jupyterNotebookId,
        AutomationTriggerInterval.week,
        AUTOMATION_TRIGGER_INTERVAL_LABELS[
          AutomationTriggerInterval.week
        ],
      ),
    ],
  },
});

export const fieldJupyterNotebookName: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'name',
  placeholder: 'Add name',
  validation: rules.jupyter_notebook.name,
};
