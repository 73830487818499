/**
 * Labstep
 *
 * @module core/Form/Edit
 * @desc Core Edit Form. Reusable for entities.
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import Button from 'labstep-web/core/Button';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import ModalConfirmation from 'labstep-web/core/Modal/Confirmation';
import { Entity } from 'labstep-web/models/entity.model';
import { getFieldsWithValidationRules } from 'labstep-web/services/validation';
import React from 'react';
import {
  FieldType,
  IFieldProps,
  IReusableFormProps,
} from '../Reusable/types';
import {
  ICoreFormEditProps,
  IEditFormUpdateContainerProps,
} from './types';

export const getDefaultValue = (value: unknown) => {
  if (value === null || typeof value === 'undefined') {
    return '';
  }
  if (typeof value === 'number') {
    return String(value);
  }
  return value;
};

/**
 * If field is present in entity then it returns that
 * value else it returns empty string
 *
 * @param  {array} fields - Array of fields
 * @param  {object} entity - Entity
 * @param  {function} formatValue - Format value function
 * @return {array} - array of updated fields
 */
export const getDefaultValues = (
  fields: Array<IFieldProps>,
  entity: Entity,
): Array<IFieldProps> =>
  fields.reduce((result, field) => {
    if (field.fieldType === FieldType.action) {
      return result;
    }
    const fieldName = entity[field.name as keyof typeof entity];
    return {
      ...result,
      [field.name]:
        field.fieldType === 'File'
          ? fieldName
          : getDefaultValue(fieldName),
    };
  }, [] as Array<IFieldProps>);

export const SubmitButton: React.FC<any> = ({
  status,

  submitButtonText,
}) => {
  const disabled = status && status.isFetching;

  return (
    <Button loader type="submit" disabled={disabled} primary>
      {submitButtonText}
    </Button>
  );
};

export const EditForm: React.FC<ICoreFormEditProps> = ({
  update,
  toggleEdit,
  status,
  options,
  fields,
  entityName,
  entity,
  submitButtonText = 'Save',
  loadDefaultValues,
  confirmationModalMessage,
  ...props
}) => {
  let defaultValues = getDefaultValues(fields, entity);
  if (loadDefaultValues) {
    defaultValues = loadDefaultValues(defaultValues, entity);
  }
  const onSubmit: IReusableFormProps['onSubmit'] = (values) =>
    update(values, options);
  const submitButton: IReusableFormProps['submitButton'] = ({
    values,
    submitForm,
  }) => {
    const message =
      confirmationModalMessage && confirmationModalMessage(values);
    if (message) {
      return (
        <ModalConfirmation
          header="Warning"
          positive
          message={message}
          onConfirm={submitForm}
          confirmButtonContent={submitButtonText}
        >
          {({ toggleModal }) => (
            <Button type="button" primary onClick={toggleModal}>
              {submitButtonText}
            </Button>
          )}
        </ModalConfirmation>
      );
    }
    return (
      <SubmitButton
        submitButtonText={submitButtonText}
        status={status}
        toggle={toggleEdit}
      />
    );
  };

  const passedFields = getFieldsWithValidationRules(
    fields,
    entityName,
  );

  return (
    <ReusableForm
      onCancel={toggleEdit}
      submitButton={submitButton}
      status={status}
      {...props}
      onSubmit={onSubmit}
      fields={passedFields}
      defaultValues={defaultValues}
    />
  );
};

const EditFormUpdateContainer: React.FC<
  IEditFormUpdateContainerProps
> = ({ optimistic = false, ...props }) => (
  <EntityUpdateContainer
    entityName={props.entityName}
    id={props.id || props.entity.id || props.entity.guid}
    optimistic={optimistic}
  >
    {(containerProps) => <EditForm {...containerProps} {...props} />}
  </EntityUpdateContainer>
);

export default EditFormUpdateContainer;
