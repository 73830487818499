/**
 * Labstep
 *
 * @module prosemirror/extensions/alignment
 * @desc Alignment management
 */

import { LabstepExtension } from '../types';
import { setTextAlign } from './commands';

const ExtensionAlignment: LabstepExtension = {
  keymap: {
    'Mod-Shift-l': setTextAlign('left'),
    'Mod-Shift-e': setTextAlign('center'),
    'Mod-Shift-r': setTextAlign('right'),
    'Mod-Shift-j': setTextAlign('justify'),
  },
};

export default ExtensionAlignment;
