/**
 * Labstep
 *
 * @module components/Metadata/Action/Create
 * @desc Create action for metadata
 */

import React from 'react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import Message from 'labstep-web/core/Message';
import { IMetadataActionCreateMessageProps } from './types';

export const MetadataActionCreateModalMessage: React.FC<
  IMetadataActionCreateMessageProps
> = ({ entityName }) => {
  const entities = getHumanReadableEntityName(entityName, true);
  return (
    <Message color="yellow">
      {`You are adding a template field. This field will
  appear on all ${entities} using this template.`}
    </Message>
  );
};

export default MetadataActionCreateModalMessage;
