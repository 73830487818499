/**
 * Labstep
 *
 * @module screens/ProtocolCollection/Index/Breadcrumb
 * @desc ProtocolCollection Index Breadcrumb
 */

import FolderBreadcrumb from 'labstep-web/components/Folder/Breadcrumb';
import { FolderType } from 'labstep-web/models/folder.model';
import React from 'react';

export const ProtocolCollectionIndexBreadcrumb: React.FC = () => (
  <FolderBreadcrumb type={FolderType.protocol_collection} />
);

export default ProtocolCollectionIndexBreadcrumb;
