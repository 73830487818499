/**
 * Labstep
 *
 * @module Core/SideMenu
 * @desc Core Side Menu with default styles
 */

import cx from 'classnames';
import { withSidebar } from 'labstep-web/containers/Ui/Sidebar';
import Portal from 'labstep-web/core/Portal';
import Sidebar from 'labstep-web/core/Sidebar';
import { objectOrFunction } from 'labstep-web/services/react.service';
import React from 'react';
import styles from './styles.module.scss';
import { ICoreSideMenuProps } from './types';

export class SideMenuContainer extends React.Component<ICoreSideMenuProps> {
  public static defaultProps: Partial<ICoreSideMenuProps> = {
    closeOnNavigation: true,
    name: 'default',
  };

  public constructor(props: ICoreSideMenuProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.close = this.close.bind(this);
    this.renderViewComponent = this.renderViewComponent.bind(this);
  }

  public componentDidMount(): void {
    const { open, name, setSidebar } = this.props;

    if (open) {
      setSidebar(true, name as string);
    }
  }

  public componentWillUnmount(): void {
    const { setSidebar, name } = this.props;
    const open = this.props.sidebar[name as string];
    if (open) {
      setSidebar(false, name as string);
    }
  }

  public onClick(): void {
    const { setSidebar, name } = this.props;
    setSidebar(true, name as string);
  }

  public close(): void {
    this.props.setSidebar(false, this.props.name as string);
  }

  public renderViewComponent(
    viewComponent: ICoreSideMenuProps['viewComponent'],
  ): React.ReactElement {
    if (typeof viewComponent === 'function') {
      return viewComponent({ toggleSideMenu: this.onClick });
    }

    return (
      <div onClick={this.onClick} style={{ cursor: 'pointer' }}>
        {viewComponent}
      </div>
    );
  }

  public render(): React.ReactElement {
    const {
      children,
      viewComponent,
      className,
      name,

      sidebar,
      portal,
    } = this.props;
    const open = sidebar[name as string];

    const menuContent = (
      <div
        id={`right-menu-${name}`}
        className={cx(styles.sidebarStyles, className)}
        onClick={(evt) => evt.stopPropagation()}
        onDoubleClick={(evt) => evt.stopPropagation()}
      >
        <Sidebar
          animation="overlay"
          direction="right"
          visible={open}
          onHide={this.close}
        >
          {open &&
            objectOrFunction(children, {
              setSidebar: this.onClick,
            })}
        </Sidebar>
      </div>
    );

    let content: React.ReactChild = menuContent;
    if (portal) {
      content = <Portal open>{menuContent}</Portal>;
    }

    return (
      <>
        {this.renderViewComponent(viewComponent)}
        {content}
      </>
    );
  }
}

export default withSidebar(SideMenuContainer);
