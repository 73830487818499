/**
 * Labstep
 *
 * @module components/PurchaseOrder/Action/UpdateStatusDropdown
 * @desc Purchase Order status + option to change
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import DropdownPill from 'labstep-web/core/Dropdown/Pill';
import ModalConfirmation from 'labstep-web/core/Modal/Confirmation';
import {
  PurchaseOrderStatus,
  PurchaseOrderStatusValues,
} from 'labstep-web/models/purchase-order.model';
import { generateNewDateString } from 'labstep-web/services/date.service';
import React, { useState } from 'react';
import styles from '../../Status/styles.module.scss';
import { IPurchaseOrderActionUpdateStatusDropdownProps } from './types';

export const PurchaseOrderActionUpdateStatusDropdown: React.FC<
  IPurchaseOrderActionUpdateStatusDropdownProps
> = ({ purchaseOrder }) => {
  const [
    selectedPurchaseOrderStatus,
    setSelectedPurchaseOrderStatus,
  ] = useState<PurchaseOrderStatus>(purchaseOrder.status);
  const canEdit = useHasAccess(
    purchaseOrder.entityName,
    purchaseOrder.id,
    Action.edit,
  );
  let body;
  let message;
  switch (selectedPurchaseOrderStatus) {
    case PurchaseOrderStatus.open:
      body = { status: PurchaseOrderStatus.open };
      message =
        'Once an order is open, you can modify or add / remove order requests from it.';
      break;
    case PurchaseOrderStatus.pending:
      body = { status: PurchaseOrderStatus.pending };
      message =
        'Once an order is pending, you will no longer be able to add / remove order requests from it.';
      break;
    case PurchaseOrderStatus.completed:
      body = {
        status: PurchaseOrderStatus.completed,
        locked_at: generateNewDateString(),
      };
      message =
        'Once marking the order as complete, the order requests are no longer editable and can only be unlocked by the workspace owner.';
      break;
    default:
      break;
  }
  return (
    <EntityUpdateContainer
      entityName={purchaseOrder.entityName}
      id={purchaseOrder.id}
    >
      {({ status, update }) => (
        <ModalConfirmation
          onConfirm={({ toggleModal }) => {
            update(body, {
              onSuccess: toggleModal,
            });
          }}
          status={status}
          header="Warning"
          message={
            <div>
              <p>{message}</p>
              <p>Are you sure you wish to proceed?</p>
            </div>
          }
        >
          {({ toggleModal }) => (
            <DropdownPill
              activeLabel={
                PurchaseOrderStatusValues[purchaseOrder.status]
              }
              disabled={!canEdit}
              triggerClassName={styles[purchaseOrder.status]}
              items={Object.keys(PurchaseOrderStatusValues).map(
                (purchaseOrderStatus) => {
                  return {
                    label:
                      PurchaseOrderStatusValues[purchaseOrderStatus],
                    onClick: () => {
                      setSelectedPurchaseOrderStatus(
                        PurchaseOrderStatus[purchaseOrderStatus],
                      );
                      if (
                        purchaseOrderStatus !== purchaseOrder.status
                      ) {
                        toggleModal();
                      }
                    },
                  };
                },
              )}
            />
          )}
        </ModalConfirmation>
      )}
    </EntityUpdateContainer>
  );
};

export default PurchaseOrderActionUpdateStatusDropdown;
