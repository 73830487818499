/**
 * Labstep
 *
 * @module components/OrderRequest/DataGrid
 * @desc OrderRequest Item Data Grid
 */

import React from 'react';
import EntityDataGrid from 'labstep-web/components/Entity/DataGrid';
import { IOrderRequestDataGridProps } from './types';

export const OrderRequestDataGrid: React.FC<
  IOrderRequestDataGridProps
> = ({ orderRequests, ...rest }) => (
  <EntityDataGrid entities={orderRequests} {...rest} />
);

export default OrderRequestDataGrid;
