/**
 * Labstep
 *
 * @module screens/Error/UnhandledError
 * @desc Simulate errors
 */

import { PingContainer } from 'labstep-web/containers/Ping';
import Button from 'labstep-web/core/Button';
import store from 'labstep-web/state/store';
import React, { useState } from 'react';
import { Container } from 'semantic-ui-react';

/** Method to return a FAIL_REFRESH_TOKEN redux action */
const getRefreshTokenFailAction = (status: number) => ({
  type: 'FAIL_REFRESH_TOKEN',
  meta: {
    method: 'POST',
    url: 'https://localhost/public-api/user/token/refresh',
    headers: {
      'Content-Type': 'application/json',
      'labstep-web-app-version': '8.3.3',
      apikey: 'test@labstep.com',
    },
    body: {
      refresh_token: 'test@labstep.com',
    },
  },
  error: {
    data: {
      message: 'The request was made but no response was received',
    },
    status,
  },
});

export const UnhandledError: React.FC = () => {
  const [breakPage, setBreakPage] = useState(false);

  if (breakPage) {
    throw new Error('Simulate error from react component');
  }

  return (
    <PingContainer>
      {({ ping }) => (
        <Container>
          <h2>On this page you can simulate errors</h2>
          <div>
            <Button
              primary
              onClick={() => {
                setBreakPage(true);
              }}
            >
              Break page
            </Button>
            <Button
              primary
              onClick={() => {
                throw new Error(
                  'Simulated error from UnhandledError page',
                );
              }}
            >
              Throw exception
            </Button>
            <Button
              primary
              onClick={() => {
                ping('Simulated error 400', 400);
              }}
            >
              API call 400
            </Button>
            <Button
              primary
              onClick={() => {
                ping('Simulated error 500', 500);
              }}
            >
              API call 500
            </Button>
            <Button
              primary
              onClick={() => {
                store.dispatch(getRefreshTokenFailAction(400));
              }}
            >
              REFRESH_TOKEN_FAIL Redux Action 400 - Logout
            </Button>
            <Button
              primary
              onClick={() => {
                store.dispatch(getRefreshTokenFailAction(500));
              }}
            >
              REFRESH_TOKEN_FAIL Redux Action 500
            </Button>
            <Button
              primary
              onClick={() => {
                ping('Simulate timeout', 200, {
                  timeout: 1,
                });
              }}
            >
              Simulate timeout
            </Button>
          </div>
        </Container>
      )}
    </PingContainer>
  );
};

export default UnhandledError;
