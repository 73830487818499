/**
 * Labstep
 *
 * @module components/File/Snapgene
 * @desc Component to show snapgene PNG/SVG
 */

import cx from 'classnames';
import FileIFrame from 'labstep-web/components/File/IFrame';
import { ThumbnailContainer } from 'labstep-web/containers/File/Thumbnail';
import { ToggleContainer } from 'labstep-web/containers/Toggle';
import Image from 'labstep-web/core/Image';
import Modal from 'labstep-web/core/Modal/Default';
import { File as FileModel } from 'labstep-web/models/file.model';
import React from 'react';
import Lightbox from 'react-image-lightbox';
import styles from './styles.module.scss';
import {
  IFileSnapgeneProps,
  IFileSnapgeneToggleContainerProps,
  IMessageProps,
  ISnapgenePreviewProps,
} from './types';

export const Message: React.FC<IMessageProps> = ({ message }) => (
  <div className={styles.container}>{message}</div>
);

export const canRenderSnapgene = (file: FileModel) =>
  Boolean(file.snapgene_output_png);

export const SnapgenePreview: React.FC<ISnapgenePreviewProps> = ({
  file,
  toggle,
}) => {
  if (!canRenderSnapgene(file)) {
    return null;
  }
  return (
    <>
      {!file.snapgene_output_svg ? (
        <ThumbnailContainer file={file.snapgene_output_png}>
          {({ src }) => (
            <Lightbox mainSrc={src} onCloseRequest={toggle} />
          )}
        </ThumbnailContainer>
      ) : (
        <Modal
          viewComponent={null}
          size="fullscreen"
          header="Snapgene"
          initialModalState
          content={() => (
            <FileIFrame file={file.snapgene_output_svg} />
          )}
        />
      )}
    </>
  );
};

export const FileSnapgene: React.FC<IFileSnapgeneProps> = ({
  file,
  toggle,
  toggled,
  expandable,
  ...rest
}) => {
  if (file.is_snapgene_processing) {
    return (
      <Message
        message={
          <p>
            Processing {file.name}.<br />
            Please wait...
            <br />
            It should take about 2 minutes.
          </p>
        }
      />
    );
  }

  if (!file.snapgene_output_png) {
    return (
      <Message
        message={
          <p>
            SnapGene server could not output any PNG or SVG from this
            data.
          </p>
        }
      />
    );
  }

  return (
    <>
      <ThumbnailContainer file={file.snapgene_output_png}>
        {({ src }) => (
          <Image
            fluid
            src={src}
            {...rest}
            className={cx(styles.image)}
            onClick={expandable ? toggle : undefined}
          />
        )}
      </ThumbnailContainer>
      {toggled && <SnapgenePreview file={file} toggle={toggle} />}
    </>
  );
};

FileSnapgene.defaultProps = {
  expandable: true,
};

export const FileSnapgeneToggleContainer: React.FC<
  IFileSnapgeneToggleContainerProps
> = (props) => (
  <ToggleContainer>
    {(containerProps) => (
      <FileSnapgene {...props} {...containerProps} />
    )}
  </ToggleContainer>
);

export default FileSnapgeneToggleContainer;
