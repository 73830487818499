/**
 * Labstep
 *
 * @module components/NotificationAlert/Action/Delete
 * @desc Action to delete
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { INotificationAlertActionDeleteProps } from './types';

export const NotificationAlertActionDelete: React.FC<
  INotificationAlertActionDeleteProps
> = ({ notificationAlert, actionComponentProps }) => (
  <EntityActionDelete
    entity={notificationAlert}
    actionComponentProps={actionComponentProps}
    skipConfirmation
  />
);

export default NotificationAlertActionDelete;
