/**
 * Labstep
 *
 * @module components/Folder/Action/UpdateOuter
 * @desc Action to update outer folder of a folder
 */

import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import EntityActionUpdateSearchSelect from 'labstep-web/components/Entity/Action/Update/SearchSelect';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { Folder } from 'labstep-web/models/folder.model';
import React from 'react';
import { IFolderActionUpdateOuterProps } from './types';

export const FolderActionUpdateOuter: React.FC<
  IFolderActionUpdateOuterProps
> = ({ folder, folderType, activeGroup, actionComponentProps }) => (
  <ModalDefault
    header={`Move to ${Folder.getHumanReadableEntityName(
      false,
      true,
    )}`}
    content={({ toggleModal }): JSX.Element => (
      <EntityActionUpdateSearchSelect
        entity={folder}
        optimistic
        autoFocus
        openMenuOnFocus
        entityName={Folder.entityName}
        params={{
          type: folderType,
          group_id: activeGroup.id,
          not_id: folder.inner_folder_ids,
        }}
        getBody={(value: Folder | null): Record<string, unknown> => ({
          outer_folder_id: value ? value.id : null,
        })}
        options={{ onSuccess: toggleModal }}
        labelKey="folder_path"
      />
    )}
    viewComponent={({ toggleModal }): JSX.Element => (
      <EntityActionEdit
        entity={folder}
        actionComponentProps={{
          type: 'option',
          icon: 'move',
          text: 'Move',
          onClick: toggleModal,
          ...actionComponentProps,
        }}
      />
    )}
  />
);

export default withActiveGroup(FolderActionUpdateOuter);
