/**
 * Labstep
 *
 * @module models/protocol-table
 * @desc Typescript export class for ProtocolTable
 */

import { Type } from 'class-transformer';
import { ProtocolStep } from 'labstep-web/models/protocol-step.model';
import { Entity } from 'labstep-web/models/entity.model';

export class ProtocolTable extends Entity {
  public static readonly idAttr = 'guid' as const;

  static readonly entityName = 'protocol_table';

  get entityName(): string {
    return ProtocolTable.entityName;
  }

  constructor(data: Partial<ProtocolTable> = {}) {
    super();
    Object.assign(this, data);
  }

  name: string;

  data: any;

  @Type(() => ProtocolStep)
  protocol_step: ProtocolStep;
}
