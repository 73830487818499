/**
 * Labstep
 *
 * @module screens/Landing/Login/ContinueButton
 * @desc Continue Button for login modal
 */

import React from 'react';
import Popup from 'labstep-web/core/Popup';
import Button from 'labstep-web/core/Button';
import { IButtonCircularProps } from './types';
import styles from './styles.module.scss';

const ButtonCircular: React.FC<IButtonCircularProps> = ({
  icon,
  text,
  onClick,
  dataTestId,
}) => (
  <Popup
    trigger={
      <Button
        data-testid={dataTestId}
        className={styles.button}
        icon={icon}
        circular
        borderless
        onClick={onClick}
      />
    }
    content={text}
  />
);

export default ButtonCircular;
