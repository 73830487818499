/**
 * Labstep
 *
 * @module core/Alert
 * @desc Wrapper around semantic-ui-react Message
 */

import React from 'react';
import cx from 'classnames';
import Message from 'labstep-web/core/Message';
import { StrictMessageProps } from 'labstep-web/core/Message/types';
import styles from './styles.module.scss';
import { IAlertProps } from './types';

const classes = ({
  primary = false,
  centered = false,
  noMargin = false,
}) =>
  cx({
    [styles.primary]: primary,
    [styles.centered]: centered,
    [styles.noMargin]: noMargin,
  });

/**
 * Core Alert (Message) component. Used to show alerts for Loading, Errors e.t.c
 * Based on semantic ui Message component
 */
const Alert: React.FC<IAlertProps & StrictMessageProps> = ({
  children,
  color,
  type,
  centered,
  noMargin,
  ...rest
}) => {
  switch (type) {
    case 'loading':
      return (
        <Message
          className={classes({
            primary: true,
            centered,
            noMargin: false,
          })}
          {...rest}
        >
          Loading...
        </Message>
      );
    default:
      return (
        <Message
          className={classes({ primary: !color, centered, noMargin })}
          color={color}
          {...rest}
        >
          {children}
        </Message>
      );
  }
};

Alert.defaultProps = {
  centered: false,
  noMargin: false,
};

export { Message };

export default Alert;
