/**
 * Labstep
 *
 * @module screens/ResourceLocation/Show/Center
 * @desc ResourceLocation Show Center
 */

import React from 'react';
import ResourceLocationActionMenu from 'labstep-web/components/ResourceLocation/Action/Menu';
import { Action } from 'labstep-web/components/ResourceLocation/Action/Menu/types';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import ResourceLocationBreadcrumb from 'labstep-web/components/ResourceLocation/Breadcrumb';
import ResourceLocationCardEmptyStateMap from 'labstep-web/components/ResourceLocation/Card/EmptyState/Map';
import EntityPrimaryInfoImage from 'labstep-web/components/Entity/PrimaryInfo/Image';
import ResourceLocationMapModalCreate from 'labstep-web/components/ResourceLocation/MapModalCreate';
import EntityUserCard from 'labstep-web/components/EntityUser/Card';
import InformationCard from 'labstep-web/core/Card/Information';
import { IResourceLocationShowCenterProps } from './types';
import styles from './styles.module.scss';

export const ResourceLocationShowCenter: React.FC<
  IResourceLocationShowCenterProps
> = ({ resourceLocation }) => {
  return (
    <div
      id="resource-location-show-screen"
      className={styles.container}
    >
      <InformationCard
        additionalActions={
          <EntityUserCard entity={resourceLocation} />
        }
        actionMenu={
          <ResourceLocationActionMenu
            resourceLocation={resourceLocation}
            actions={[
              Action.delete,
              Action.update_outer,
              Action.stock_check,
            ]}
          />
        }
        breadcrumb={
          <ResourceLocationBreadcrumb
            resourceLocation={resourceLocation}
          />
        }
        primaryInfo={
          <EntityPrimaryInfoImage entity={resourceLocation} />
        }
        secondaryInfo={<MetadataManager entity={resourceLocation} />}
        bottom={
          <ResourceLocationMapModalCreate
            resourceLocation={resourceLocation}
            createAction={
              <ResourceLocationCardEmptyStateMap
                resourceLocation={resourceLocation}
              />
            }
          />
        }
      />
    </div>
  );
};

export default ResourceLocationShowCenter;
