/**
 * Labstep
 *
 * @module screens/Master/Index
 * @desc Index screen
 */

import React from 'react';
import DocumentHead from 'labstep-web/core/DocumentHead';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import LayoutMainContentCenter from 'labstep-web/components/Layout/MainContent/Center';
import styles from './styles.module.scss';
import { IMasterIndexProps } from './types';
import Center from './Center';
import LeftPane from './LeftPane';

export const MasterIndex: React.FC<IMasterIndexProps> = ({
  leftPane,
  isTemplate,
  ...rest
}) => (
  <>
    <DocumentHead
      title={getHumanReadableEntityName(
        rest.entityName,
        true,
        true,
        isTemplate,
      )}
    />
    <LayoutMainContentCenter>
      <div className={styles.container}>
        {leftPane && <LeftPane leftPane={leftPane} />}
        <Center {...rest} />
      </div>
    </LayoutMainContentCenter>
  </>
);

export default MasterIndex;
