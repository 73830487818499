/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/ProtocolStep
 * @desc Renders an ProtocolStep node
 */

import React from 'react';
import isEqual from 'lodash/isEqual';
import ProtocolStepCardDetail from 'labstep-web/components/ProtocolStep/Card/Detail';
import { useReactNodeView } from 'labstep-web/prosemirror/components/ReactNodeView';
import { useStepsOrderContext } from 'labstep-web/prosemirror/context';
import { getIdTimestamp } from 'labstep-web/state/epics/normalize/utils';
import { ProtocolStepNodeProps } from './types';

export class ProtocolStepNode extends React.Component<ProtocolStepNodeProps> {
  public shouldComponentUpdate(prevProps) {
    return (
      !isEqual(this.props.stepsOrder, prevProps.stepsOrder) ||
      !isEqual(this.props.readOnly, prevProps.readOnly)
    );
  }

  public render() {
    const {
      id,
      readOnly,
      stepsOrder,
      onDelete,
      timestamp,
      protocol,
    } = this.props;

    const stepNumber =
      stepsOrder.findIndex(
        (s) => String(s) === String(getIdTimestamp(id, timestamp)),
      ) + 1;

    return (
      <ProtocolStepCardDetail
        protocol={protocol}
        protocolStepId={id}
        stepNumber={stepNumber}
        onDelete={readOnly ? null : onDelete}
        timestamp={timestamp}
      />
    );
  }
}

const ProtocolStepNodeView = ({ entity, onDelete, timestamp }) => {
  const context = useReactNodeView();
  const { guid } = context.node.attrs;

  const { stepsOrder, editable } = useStepsOrderContext();

  return (
    <ProtocolStepNode
      id={guid}
      protocol={entity}
      readOnly={!editable}
      stepsOrder={stepsOrder}
      onDelete={onDelete}
      timestamp={timestamp}
    />
  );
};

export default ProtocolStepNodeView;
