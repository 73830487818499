/**
 * Labstep
 *
 * @module components/Tag/Filter/Status/Active
 * @desc Active filter component for status
 */

import React from 'react';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { tagTypes } from 'labstep-web/components/Tag/Form/fields';
import { ITagFilterStatusActiveProps } from './types';

const TagFilterStatusActive: React.FC<
  ITagFilterStatusActiveProps
> = ({ clearParams, searchParams }) =>
  'type' in searchParams ? (
    <RemoveLabel
      name={`${
        tagTypes[searchParams.type as keyof typeof tagTypes]
      } Tags`}
      onRemoveClick={clearParams && (() => clearParams(['type']))}
    />
  ) : null;

export default TagFilterStatusActive;
