import { IProseMirrorCommandsElement } from 'labstep-web/prosemirror/extensions/slash/types';

export const SELECT_PREVIOUS = 'SELECT_PREVIOUS';

export const selectPrevious = () => ({
  type: SELECT_PREVIOUS,
});

export const SELECT_NEXT = 'SELECT_NEXT';

export const selectNext = () => ({
  type: SELECT_NEXT,
});

export const SUBMIT_ELEMENT = 'SUBMIT_ELEMENT';

export const submitElement = (
  element: IProseMirrorCommandsElement,
) => ({
  type: SUBMIT_ELEMENT,
  element,
});

export const SET_ELEMENTS = 'SET_ELEMENTS';

export const setElements = (
  elements: IProseMirrorCommandsElement[],
) => ({
  type: SET_ELEMENTS,
  elements,
});

export const SET_IS_OPEN = 'SET_IS_OPEN';

export const setIsOpen = (isOpen: boolean) => ({
  type: SET_IS_OPEN,
  isOpen,
});
