/**
 * Labstep
 *
 * @module containers/Websocket
 */

import { websocketCheckConnectedAction } from 'labstep-web/state/actions/websocket';
import {
  selectWebsocketConnected,
  selectWebsocketUsers,
} from 'labstep-web/state/selectors/websocket';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  connected: selectWebsocketConnected(state),
  users: selectWebsocketUsers(state),
});

const mapDispatchToProps = (dispatch) => ({
  checkConnected() {
    dispatch(websocketCheckConnectedAction());
  },
});

export const withWebsocket = (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);
