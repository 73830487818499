/**
 * Labstep
 *
 * @module components/ResourceItem/Action/UpdateAmount/Bulk
 * @desc Bulk UpdateAmount Action for order request
 */

import React from 'react';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import ModalDefault from 'labstep-web/core/Modal/Default';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import ResourceItemFormEditBulk from 'labstep-web/components/ResourceItem/Form/Edit/Bulk';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { IResourceItemActionUpdateAmountBulkProps } from './types';

const ResourceItemActionUpdateAmountBulk: React.FC<
  IResourceItemActionUpdateAmountBulkProps
> = ({ entities, options }) => {
  const entityIdsAccessObject = useHasAccessMultiple(
    ResourceItem.entityName,
    entities ? entities.map((e) => e.id) : [],
    Action.edit,
  );

  const entitiesWithPermission = entities
    ? entities.filter((e) => entityIdsAccessObject[e.id])
    : [];

  if (
    !entities ||
    entities.length === 0 ||
    entitiesWithPermission.length === 0
  ) {
    return null;
  }

  return (
    <ModalDefault
      header="Set Amount"
      content={({ toggleModal }) => (
        <ResourceItemFormEditBulk
          resourceItems={entitiesWithPermission}
          options={enhanceOptions({
            options,
            onSuccess: toggleModal,
          })}
        />
      )}
      viewComponent={({ toggleModal }) => (
        <BulkActionComponent
          entitiesWithPermissionCount={entitiesWithPermission.length}
          entitiesSelectedCount={entities.length}
          onClick={toggleModal}
          content="Set Amount"
          icon="pencil"
        />
      )}
    />
  );
};

export default ResourceItemActionUpdateAmountBulk;
