/**
 * Labstep
 *
 * @module components/Protocol/Action/Restore
 * @desc Restore a previous protocol version
 */

import React from 'react';
import { navigation } from 'labstep-web/services/navigation';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { withRouter } from 'react-router';
import { ProtocolActionRestoreProps } from './types';

export const ProtocolActionRestore: React.FC<
  ProtocolActionRestoreProps
> = ({ history, protocol, actionComponentProps }) => {
  const body: any = {
    protocol_collection_id: protocol.collection.id,
    source_id: protocol.id,
  };
  const options: any = {
    onSuccess: () => {
      history.push(
        navigation.get('protocol_collection_show', {
          id: protocol.collection.id,
        }),
      );
    },
  };

  return (
    <EntityActionCreate
      customActionName="Restore"
      entityName="protocol"
      body={body}
      options={options}
      actionComponentProps={{
        type: 'option',
        icon: 'redo',
        ...actionComponentProps,
      }}
    />
  );
};

export default withRouter(ProtocolActionRestore);
