/**
 * Labstep
 */

import { CSSObjectWithLabel } from 'react-select';

export const floatingStyles = {
  control: (base: CSSObjectWithLabel) => ({
    ...base,
    boxShadow:
      '2px 0px 2px hsla(0, 0%, 0%, 0.1), -2px 0px 2px hsla(0, 0%, 0%, 0.1), 0 -2px 2px hsla(0, 0%, 0%, 0.1)',
    border: 0,
    borderBottom: '1px solid #d8d8d8',
    padding: '6px 4px',
    borderRadius: '4px 4px 0 0',
    marginTop: '2px',
  }),
  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    boxShadow:
      '2px 2px 2px hsla(0, 0%, 0%, 0.1), -2px 2px 2px hsla(0, 0%, 0%, 0.1), 0 2px 2px hsla(0, 0%, 0%, 0.1)',
    margin: 0,
    borderRadius: '0 0 4px 4px',
    padding: 0,
  }),
};
