/**
 * Labstep
 *
 * @module components/User/Action/Signout
 * @desc Signout Action
 */

import { UserLogoutContainer } from 'labstep-web/containers/User/Logout';
import ActionComponent from 'labstep-web/core/Action/Component';
import { IActionComponentProps } from 'labstep-web/core/Action/Component/types';
import { addActionComponentPropsToDefault } from 'labstep-web/core/Action/Component/utils';
import { windowService } from 'labstep-web/services/window.service';
import React from 'react';
import {
  IUserActionSignoutContainerProps,
  IUserActionSignoutProps,
} from './types';

export const UserActionSignout: React.FC<IUserActionSignoutProps> = ({
  signout,
  actionComponentProps: extraActionComponentProps,
}) => {
  let actionComponentProps = {
    type: 'button',
    text: 'Sign Out',
    onClick: () => {
      signout();
    },
    elementProps: { color: 'red' },
  } as IActionComponentProps;

  actionComponentProps = addActionComponentPropsToDefault(
    actionComponentProps,
    extraActionComponentProps,
  );

  return <ActionComponent {...actionComponentProps} />;
};

export const UserActionSignoutContainer: React.FC<
  IUserActionSignoutContainerProps
> = ({ skipApi, ...props }) => (
  <UserLogoutContainer>
    {({ logout, logoutWithoutApi }): React.ReactElement => {
      let signout = logout;
      if (skipApi) {
        signout = () => {
          logoutWithoutApi();
          windowService.reload();
        };
      }
      return <UserActionSignout signout={signout} {...props} />;
    }}
  </UserLogoutContainer>
);

export default UserActionSignoutContainer;
