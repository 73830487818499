/**
 * Labstep
 */

import React, { useContext } from 'react';
import { IEntitySearchContainerProps } from './types';

export const defaultEntitySearchContext = {
  sortOptions: undefined,
  usePostFilter: false,
};

export type EntitySearchContextType = {
  sortOptions?: IEntitySearchContainerProps['sortOptions'];
  usePostFilter?: IEntitySearchContainerProps['usePostFilter'];
};
const EntitySearchContext =
  React.createContext<EntitySearchContextType>(
    defaultEntitySearchContext,
  );

export const useEntitySearchContext = (): EntitySearchContextType => {
  const context = useContext(EntitySearchContext);
  if (!context) {
    throw new Error(
      'useEntitySearchContext must be used within a EntitySearchContextProvider',
    );
  }
  return context;
};

export default EntitySearchContext;
