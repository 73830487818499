/**
 * Labstep
 *
 * @module components/Filter/User/List
 * @desc Filter List for Users
 */

import FilterListHeader from 'labstep-web/components/Filter/List/Header';
import FilterListSearch from 'labstep-web/components/Filter/List/Search';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import { Size } from 'labstep-web/core/Initials/types';
import { User } from 'labstep-web/models/user.model';
import { PostFilterAssignedTo } from 'labstep-web/services/postFilter/filters/assignedTo';
import { PostFilterAuthor } from 'labstep-web/services/postFilter/filters/author';
import React from 'react';
import { IFilterUserListProps } from './types';

export const FilterUserList: React.FC<IFilterUserListProps> = ({
  header,
  entityName,
  name,
  field,
  usePostFilter,
}) => (
  <FilterListSearch
    filter={{
      entityName,
      name,
    }}
    entityName={User.entityName}
    params={{
      count: 5,
      is_deleted: 'both',
      sort: '-deleted_at,last_name,first_name',
    }}
    mapping={(entity: User) => {
      let fieldPostFilter;
      if (usePostFilter) {
        if (field === 'author_id') {
          fieldPostFilter = [PostFilterAuthor, [entity.guid]];
        } else {
          fieldPostFilter = [PostFilterAssignedTo, [entity.guid]];
        }
      }
      return {
        component: (
          <ProfilePicture
            linkable={false}
            size={Size.mini}
            entity={entity}
          />
        ),
        text: entity.name,
        params: { [field]: String(entity.id) },
        postFilterValues: fieldPostFilter,
      };
    }}
    searchBarProps={{
      viewComponent: (
        <FilterListHeader
          header={header}
          filter={{
            entityName,
            name,
          }}
        />
      ),
    }}
  />
);

export default FilterUserList;
