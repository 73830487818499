/**
 * Labstep
 *
 * @module components/User/Form/fields
 * @desc Fields for user
 */

import rules from 'labstep-web/services/validation/rules';
import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';

export const fieldUserFirstName: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'first_name',
  placeholder: 'First Name',
  validation: rules.user.first_name,
};

export const fieldUserLastName: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'last_name',
  placeholder: 'Last Name',
  validation: rules.user.last_name,
};

export const fieldUserEmail: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'email',
  placeholder: 'Email address',
  validation: rules.email,
};

export const fieldUserUsername: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'username',
  placeholder: 'Email address',
  validation: rules.username,
};

export const fieldUserPassword: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'password',
  placeholder: 'Password',
  elementProps: {
    type: 'password',
  },
  validation: rules.password,
};

export const fieldUserCurrentPassword: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'current_password',
  placeholder: 'Current Password',
  elementProps: {
    type: 'password',
  },
  validation: rules.password,
};

export const fieldUserNewSignupPassword: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'new_password',
  placeholder: 'New password',
  elementProps: {
    type: 'password',
  },
  validation: rules.signup_password,
};

export const fieldUserNewSignupPasswordConfirmation: IFieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    name: 'new_password_confirm',
    placeholder: 'Confirm new Password',
    elementProps: {
      type: 'password',
    },
    validation: rules.signup_password_confirmation,
  };

export const fieldUserSignupPassword: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'password',
  placeholder: 'New password',
  elementProps: {
    type: 'password',
  },
  validation: rules.signup_password,
};

export const fieldUserSignupPasswordConfirmation: IFieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    name: 'password_confirm',
    placeholder: 'Confirm new Password',
    elementProps: {
      type: 'password',
    },
    validation: rules.signup_password_confirmation,
  };

export const fieldUserCode: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'code',
  validation: rules.six_digit_code,
  fieldLabel: '6-digit code from your authenticator',
  placeholder: 'Enter 6 digit code',
};

export const fieldUserBiography: IFieldWithoutActionProps = {
  fieldLabel: 'Biography',
  name: 'biography',
  fieldType: FieldType.TextArea,
  validation: rules.user.biography,
};
