/**
 * Labstep
 *
 * @module models/rich-text
 * @desc Typescript export class for RichText
 */

import { Entity } from 'labstep-web/models/entity.model';

// eslint-disable-next-line no-shadow
export enum Type {
  html = 'html',
  r = 'r',
  clike = 'clike',
  mathematica = 'mathematica',
  python = 'python',
}

export class RichText extends Entity {
  static readonly entityName = 'rich_text';

  get entityName(): string {
    return RichText.entityName;
  }

  constructor(data: Partial<RichText> = {}) {
    super();
    Object.assign(this, data);
  }

  type: Type;

  id: number;

  description: string;
}
