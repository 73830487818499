/**
 * Labstep
 *
 * @module components/Filter/List/Item/Content
 * @desc Content for list of filter items
 */

import classNames from 'classnames';
import { withUiPersistent } from 'labstep-web/containers/UiPersistent';
import { IWithUiPersistentInjectedProps } from 'labstep-web/containers/UiPersistent/types';
import React from 'react';
import { FilterList } from '..';
import styles from './styles.module.scss';
import { IFilterListContentProps } from './types';

export const isFilterCollapsed = (
  uiPersistent: IWithUiPersistentInjectedProps['uiPersistent'],
  filter: IFilterListContentProps['filter'],
) =>
  uiPersistent.filters[filter.entityName][filter.name]!.state ===
  'collapsed';

export const FilterListContent: React.FC<IFilterListContentProps> = ({
  uiPersistent,
  filters,
  filter,
  extraFilters,
  children,
  action,
}) => (
  <div
    className={classNames({
      [styles.hidden]: isFilterCollapsed(uiPersistent, filter),
    })}
  >
    {extraFilters && (
      <div className={styles.extraFiltersContainer}>
        {extraFilters}
      </div>
    )}
    {children || <FilterList filters={filters} action={action} />}
  </div>
);

export default withUiPersistent(FilterListContent);
