/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/List/Link
 * @desc List of experiment workflow links
 */

import ExperimentWorkflowLinkList from 'labstep-web/components/ExperimentWorkflowLink/List';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ExperimentWorkflowLink } from 'labstep-web/models/experiment-workflow-link.model';
import React from 'react';
import { IExperimentWorkflowListLinkProps } from './types';

const ExperimentWorkflowListLink: React.FC<
  IExperimentWorkflowListLinkProps
> = ({ experimentWorkflowId, showBackLinks, ...rest }) => (
  <ReadOnMountHOC
    type="cursor"
    entityName={ExperimentWorkflowLink.entityName}
    params={{
      [showBackLinks ? 'dest_id' : 'src_id']: experimentWorkflowId,
    }}
    children={({ entities }) =>
      entities ? (
        <ExperimentWorkflowLinkList
          experimentWorkflowLinks={entities}
          showBackLinks={showBackLinks}
          {...rest}
        />
      ) : null
    }
  />
);

export default ExperimentWorkflowListLink;
