/**
 * Labstep
 *
 * @module prosemirror/state/input-rules
 * @desc ProseMirror build input rules based on schema
 */

import {
  ellipsis,
  emDash,
  inputRules,
  smartQuotes,
} from 'prosemirror-inputrules';
import { nodes } from 'labstep-web/prosemirror/nodes';

// : (Schema) → Plugin
// A set of input rules for creating the basic block quotes, lists,
// code blocks, and heading.
export function buildInputRules(schema) {
  const rules = smartQuotes.concat(ellipsis, emDash);

  nodes
    .filter((node) => !!node.inputRules)
    .forEach((node) => {
      rules.push(...node.inputRules({ schema }));
    });

  return inputRules({ rules });
}
