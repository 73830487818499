/**
 * Labstep
 *
 * @module components/OrganizationPlan/Show/FreeTrial
 * @desc Display organization free trial
 */

import React from 'react';
import OrganizationPlanCardUsers from '../../Card/Users';
import OrganizationPlanCardWorkspaces from '../../Card/Workspaces';
import OrganizationPlanCardFreeTrial from '../../Card/FreeTrial';
import { OrganizationPlanShowFreeTrialProps } from './types';
import styles from './styles.module.scss';

export const OrganizationPlanShowFreeTrial: React.FC<
  OrganizationPlanShowFreeTrialProps
> = ({ organization }) => (
  <div className={styles.container}>
    <OrganizationPlanCardFreeTrial organization={organization} />
    <OrganizationPlanCardUsers organization={organization} />
    <OrganizationPlanCardWorkspaces organization={organization} />
  </div>
);

export default OrganizationPlanShowFreeTrial;
