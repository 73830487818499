/**
 * Labstep
 *
 * @module components/ProtocolValue/Check
 * @desc Experiment Value item
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import { IProtocolValueCheckResourceItemOutputProps } from './types';

export const ProtocolValueCheckResourceItemOutput: React.FC<
  IProtocolValueCheckResourceItemOutputProps
> = ({ protocolValue }) => {
  if (!protocolValue.is_output || protocolValue.is_variable) {
    return null;
  }
  if (protocolValue.resource_item_output) {
    return (
      <Icon
        name="check circle"
        color="green"
        popup={{ content: 'Item specified' }}
      />
    );
  }

  return (
    <Icon
      name="warning sign"
      color="yellow"
      popup={{ content: 'Item not specified' }}
    />
  );
};

export default ProtocolValueCheckResourceItemOutput;
