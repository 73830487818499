/**
 * Labstep
 *
 * @module containers/Responsive
 * @desc Drops one size on mobile
 */

import React from 'react';
import Mobile from 'labstep-web/core/MediaQuery/Mobile';

const getSmallerSize = (
  availableSizes,
  size,
  downScale = 1,
): string => {
  const index = availableSizes.indexOf(size);

  if (index > 0) {
    return availableSizes[index - downScale];
  }
  return size;
};

const defaultAvailableSizes: string[] = [
  'mini',
  'tiny',
  'small',
  'medium',
  'large',
  'big',
  'huge',
  'massive',
];

export const withResponsive = (
  PassedComponent: React.ComponentType<any>,
  defaultSize = 'medium',
  availableSizes = defaultAvailableSizes,
) =>
  /* eslint-disable */
  class WithResponsive extends React.Component<any, any> {
    render() {
      const { size, responsive, downScale, ...rest } = this.props;
      const selectedSize = size || defaultSize;
      return (
        <Mobile>
          {({ match }) => {
            const finalSize =
              match && responsive !== false
                ? getSmallerSize(
                    availableSizes,
                    selectedSize,
                    downScale,
                  )
                : selectedSize;
            return <PassedComponent {...rest} size={finalSize} />;
          }}
        </Mobile>
      );
    }
  };
