/**
 * Labstep
 *
 * @module models/metadata
 * @desc Typescript export class for Metadata
 */

import { Type } from 'class-transformer';
import { ICONS } from 'labstep-web/constants/icons';
import { DeviceData } from 'labstep-web/models/device-data.model';
import { Entity } from 'labstep-web/models/entity.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { File } from 'labstep-web/models/file.model';
import { Molecule } from 'labstep-web/models/molecule.model';
import { NotificationAlert } from 'labstep-web/models/notification-alert.model';
import { ProtocolDevice } from 'labstep-web/models/protocol-device.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import {
  Sequence,
  SequenceCreateBodyType,
} from 'labstep-web/models/sequence.model';
import { Thread } from 'labstep-web/models/thread.model';
import { User } from 'labstep-web/models/user.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { SemanticICONS } from 'semantic-ui-react';
import { MetadataThread } from '../metadata-thread.model';
import { Option } from '../protocol-value.model';
import { METADATA_DEFAULT_LABEL_DATA } from './constants';
import {
  IEntityWithMetadata,
  IMetadataCreateProps,
  IMetadataOptions,
  MetadataEntityNameType,
  MetadataType,
} from './types';
import {
  getAlertTriggerTime,
  getCanBeLinkedToDevice,
  getCreateProps,
  getEmptyOptions,
  getHasAlertFired,
  getHasValue,
  getInputOutputTypeOption,
  getIsServingAsDefaultTemplate,
  getOptionsSelectedValues,
  getPrintValue,
  getSequenceCreateBody,
} from './utils';

const METADATA_ICONS = {
  [MetadataType.default]: ICONS.metadata.type.default,
  [MetadataType.numeric]: ICONS.metadata.type.numeric,
  [MetadataType.date]: ICONS.metadata.type.date,
  [MetadataType.datetime]: ICONS.metadata.type.datetime,
  [MetadataType.options]: ICONS.metadata.type.options,
  [MetadataType.file]: ICONS.metadata.type.file,
  [MetadataType.molecule]: ICONS.metadata.type.molecule,
  [MetadataType.sequence]: ICONS.metadata.type.sequence,
};

export class Metadata extends Entity {
  public static readonly entityName: MetadataEntityNameType =
    'metadata';

  public get entityName(): string {
    return Metadata.entityName;
  }

  public constructor(data: Partial<Metadata> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public display_value!: string | null;

  public value!: string | null;

  public label!: string | null;

  public position!: number;

  public number!: number | null;

  private _unit!: string | null;

  public date!: string | null;

  private _type!: MetadataType;

  private _is_required!: boolean | null;

  private _options!: IMetadataOptions;

  public experiment_id!: Experiment['id'] | null;

  public experiment_name!: Experiment['name'] | null;

  public is_input!: boolean;

  public is_output!: boolean;

  public is_template!: boolean;

  public template_id!: number | null;

  public variable_template_guid!: string | null;

  public is_variable!: boolean;

  public protocol_condition_guid!: string | null;

  @Type(() => File)
  public files!: File[];

  @Type(() => MetadataThread)
  public metadata_thread?: MetadataThread;

  @Type(() => Metadata)
  public template?: Metadata | null;

  @Type(() => Metadata)
  public variable_template?: Metadata | null;

  @Type(() => Thread)
  public thread?: Thread;

  @Type(() => Molecule)
  public molecule!: Molecule | null;

  @Type(() => Sequence)
  public sequence!: Sequence | null;

  @Type(() => DeviceData)
  public device_data!: DeviceData | null;

  @Type(() => ProtocolDevice)
  private _protocol_device!: ProtocolDevice | null;

  @Type(() => NotificationAlert)
  public notification_alert!: NotificationAlert | null;

  public get type(): MetadataType {
    if (this.variable_template) {
      return this.variable_template.type;
    }
    return this._type;
  }

  public set type(type: MetadataType) {
    this._type = type;
  }

  public get options(): IMetadataOptions {
    if (this._options?.values === null) {
      return { ...this._options, values: {} };
    }
    return this._options;
  }

  public set options(options: IMetadataOptions) {
    this._options = options;
  }

  public get unit(): string | null {
    if (this.variable_template) {
      return this.variable_template.unit;
    }
    return this._unit;
  }

  public set unit(unit: string | null) {
    this._unit = unit;
  }

  public get is_required(): boolean | null {
    if (this.variable_template) {
      return this.variable_template.is_required;
    }
    return this._is_required;
  }

  public set is_required(is_required: boolean | null) {
    this._is_required = is_required;
  }

  public get protocol_device(): ProtocolDevice | null {
    // Need to use own protocol device due to denormalization issue
    // https://github.com/Labstep/labstep/issues/10013
    if (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this._protocol_device?.guid as any) ===
      this.variable_template?.protocol_device
    ) {
      return this._protocol_device;
    }
    if (this.variable_template) {
      return this.variable_template.protocol_device;
    }
    return this._protocol_device;
  }

  public set protocol_device(protocolDevice: ProtocolDevice | null) {
    this._protocol_device = protocolDevice;
  }

  // To harmonize with table/timer/value
  public get displayName(): string {
    return this.label || METADATA_DEFAULT_LABEL_DATA;
  }

  public get labelWithUnit(): string | null {
    return `${this.displayName}${this.unit ? ` (${this.unit})` : ''}`;
  }

  public get printValue(): string | null {
    return getPrintValue(this);
  }

  public get hasValue(): boolean {
    return getHasValue(this);
  }

  public get optionsSelectedValues(): string[] {
    return getOptionsSelectedValues(this);
  }

  public get canBeLinkedToDevice(): boolean {
    return getCanBeLinkedToDevice(this);
  }

  public get inputOutputTypeOption(): Option {
    return getInputOutputTypeOption(this);
  }

  public get inputOutputTypeLabel(): string {
    return this.inputOutputTypeOption.label;
  }

  public get optionsTemplate(): Metadata | null | undefined {
    return this.template || this.variable_template;
  }

  public get hasActiveAlert(): boolean {
    return !!(
      this.notification_alert && !this.notification_alert.deleted_at
    );
  }

  public get hasAlertFired(): boolean {
    return getHasAlertFired(this);
  }

  public get alertTriggerTime(): string | null {
    return getAlertTriggerTime(this);
  }

  /**
   * Returns fields required to create sequence
   */
  public get sequenceCreateBody():
    | SequenceCreateBodyType
    | undefined {
    return getSequenceCreateBody(this);
  }

  public get emptyOptions(): IMetadataOptions {
    return getEmptyOptions(this);
  }

  /**
   *
   * @param entity
   * @returns true if this is a template metadata for a metadata that
   * has not yet been created for this entity
   */
  public getIsServingAsDefaultTemplate(
    entity: IEntityWithMetadata,
  ): boolean {
    return getIsServingAsDefaultTemplate(this, entity);
  }

  public getCreateProps(
    entity: IEntityWithMetadata,
  ): IMetadataCreateProps | undefined {
    return getCreateProps(this, entity);
  }

  public static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
    parent?: IEntityWithMetadata,
    isData?: boolean,
  ): string {
    let name = 'metadata field';
    const dataName = 'data field';
    if (parent) {
      if (parent.entityName === User.entityName) {
        name = 'info';
      }
      if (
        [
          Experiment.entityName,
          ExperimentWorkflow.entityName,
          Protocol.entityName,
        ].includes(parent.entityName)
      ) {
        name = dataName;
      }
    }
    if (isData) {
      name = dataName;
    }
    return getHumanReadableEntityName(name, plural, capitalized);
  }

  public get icon(): SemanticICONS {
    return (
      METADATA_ICONS[this.type] ||
      METADATA_ICONS[MetadataType.default]
    );
  }
}
