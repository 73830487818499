/**
 * Labstep
 */

import { Category } from 'labstep-web/prosemirror/extensions/referencing/plugin/types';

export const showAction = (selectedCategory: Category) =>
  ['protocol_values', 'experiments', 'metadatas'].includes(
    selectedCategory.value,
  );
