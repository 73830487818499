/**
 * Labstep
 *
 * @module containers/Stripe
 * @desc Container to call Stripe endpoints
 */

import {
  createStripeBillingPortalSessionAction,
  createStripeBillingPortalSessionActionType,
  createStripeCheckoutSessionAction,
  createStripeCheckoutSessionActionType,
  ICreateStripeBillingPortalSessionBody,
  ICreateStripeCheckoutSessionBody,
} from 'labstep-web/state/actions/stripe';
import { selectApiStatus } from 'labstep-web/state/selectors/ui';
import { LabstepReduxState } from 'labstep-web/state/types';
import { IOptions } from 'labstep-web/typings';
import { connect } from 'react-redux';
import { StripeContainerProps } from './types';

const Container = ({ children, ...rest }) => children(rest);

const mapStateToProps = (state: LabstepReduxState) => ({
  createStripeCheckoutSessionStatus: selectApiStatus(
    state,
    createStripeCheckoutSessionActionType,
  ),
  createBillingPortalSessionStatus: selectApiStatus(
    state,
    createStripeBillingPortalSessionActionType,
  ),
});

const mapDispatchToProps = (dispatch) => ({
  createStripeCheckoutSession(
    body: ICreateStripeCheckoutSessionBody,
    options?: IOptions,
  ) {
    dispatch(createStripeCheckoutSessionAction(body, options));
  },
  createStripeBillingPortalSession(
    body: ICreateStripeBillingPortalSessionBody,
    options?: IOptions,
  ) {
    dispatch(createStripeBillingPortalSessionAction(body, options));
  },
});

export const StripeContainer: React.FC<StripeContainerProps> =
  connect(mapStateToProps, mapDispatchToProps)(Container);
