/**
 * Labstep
 *
 * @module components/JupyterNotebook/Modal
 * @desc JupyterNotebook Modal
 */

import JupyterNotebookFormShowEditAutomation from 'labstep-web/components/JupyterNotebook/Form/ShowEdit/Automation';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import { IJupyterNotebookModalProps } from './types';

export const JupyterNotebookModalAutomation: React.FC<
  IJupyterNotebookModalProps
> = ({ jupyterNotebook }) => (
  <ModalDefault
    header="Automation"
    content={() => (
      <JupyterNotebookFormShowEditAutomation
        jupyterNotebook={jupyterNotebook}
      />
    )}
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        type="icon"
        icon="time"
        elementProps={{
          color: jupyterNotebook.automation ? 'orange' : 'grey',
          popup: {
            content: `${jupyterNotebook.automationDisplay} ${
              jupyterNotebook.automation?.trigger_at
                ? ` - Next run scheduled: ${humanReadableDate(
                    jupyterNotebook.automation.trigger_at,
                  )}`
                : ''
            }`,
          },
        }}
        onClick={toggleModal}
      />
    )}
  />
);

export default JupyterNotebookModalAutomation;
