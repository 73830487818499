/**
 * Labstep
 *
 * @module components/ProtocolValue/Form/ShowEdit/ResourceItem
 * @desc ProtocolValue Info
 */

import React from 'react';
import ProtocolValueShowEditResourceItemInput from './Input';
import ProtocolValueShowEditResourceItemOutput from './Output';
import { IProtocolValueShowEditResourceItemProps } from './types';

export const ProtocolValueShowEditResourceItem: React.FC<
  IProtocolValueShowEditResourceItemProps
> = (props) =>
  props.protocolValue.is_output ? (
    <ProtocolValueShowEditResourceItemOutput {...props} />
  ) : (
    <ProtocolValueShowEditResourceItemInput {...props} />
  );

export default ProtocolValueShowEditResourceItem;
