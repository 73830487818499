/**
 * Labstep
 */

import type { Active, Over } from '@dnd-kit/core';
import { ISortableItemProps } from './types';

export const getOldAndNewIndex = (
  items: ISortableItemProps[],
  active: Active,
  over: Over,
): { oldIndex: number; newIndex: number } => ({
  oldIndex: items.findIndex(({ id }) => id === active.id),
  newIndex: items.findIndex(({ id }) => id === over.id),
});
