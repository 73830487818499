/**
 * Labstep
 *
 * @module models/chemical
 * @desc Typescript export class for Chemical
 */

import { Type } from 'class-transformer';
import { PubChemMetadata } from 'labstep-web/containers/PubChem/types';
import {
  calcYield,
  calcTheoreticalMass,
} from 'labstep-web/services/chemical.service';
import { Entity } from 'labstep-web/models/entity.model';
import { Molecule } from 'labstep-web/models//molecule.model';
import { ProtocolValue } from 'labstep-web/models//protocol-value.model';

export type ChemicalType = 'product' | 'reactant' | 'solvent';

export const ChemicalSearchProperty = 'InChI';

export const canCalcStoichiometry = (chemical: Chemical): boolean =>
  !!chemical.properties?.MolecularWeight;

export const getDensity = (chemical: Chemical): string | null => {
  if (chemical.properties?.Density) {
    return chemical.properties.Density.split(' ')[0];
  }
  return null;
};

export class Chemical extends Entity {
  public static readonly idAttr = 'guid' as const;

  public static readonly entityName = 'chemical';

  public get entityName(): string {
    return Chemical.entityName;
  }

  public constructor(data = {}) {
    super();
    Object.assign(this, data);
  }

  public type!: ChemicalType;

  public properties!: PubChemMetadata;

  public purity!: number;

  public equivalence!: number | string;

  public molar_amount!: number;

  public is_limiting!: boolean;

  @Type(() => Molecule)
  public molecule!: Molecule;

  @Type(() => ProtocolValue)
  public protocol_value!: ProtocolValue;

  /**
   * Get Yield as a decimal (string)
   */
  public get yield(): string | null {
    return calcYield(this);
  }

  public get yieldAsPercentage(): string | null {
    if (this.yield) {
      return `${parseFloat(this.yield) * 100}%`;
    }
    return null;
  }

  public get theoreticalMass(): string | null {
    return calcTheoreticalMass(this);
  }

  public get density(): string | null {
    return getDensity(this);
  }

  public get canChangeAmount(): boolean {
    return this.type === 'reactant' ? this.is_limiting : true;
  }
}
