/**
 * Labstep
 */

import { EntityState } from 'labstep-web/models/entity-state.model';
import { EntityUser } from 'labstep-web/models/entity-user.model';

export const getHaveAllEntityUserRolesForNextEntityBeenAssigned = (
  entityUsers: EntityUser[],
  nextEntityState?: EntityState,
): boolean => {
  if (!nextEntityState) {
    return true;
  }

  return nextEntityState.entity_user_role_requirements.reduce(
    (result, entityUserRoleRequirement) => {
      return (
        result &&
        entityUserRoleRequirement.number_required <=
          entityUsers.filter(
            (e) =>
              e.entity_user_role?.id ===
              entityUserRoleRequirement.entity_user_role.id,
          ).length
      );
    },
    true,
  );
};
