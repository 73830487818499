/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/Options
 * @desc ShowEdit compoennts for Metadata field type options
 */

import React from 'react';
import EntityActionUpdateSelectMulti from 'labstep-web/components/Entity/Action/Update/SelectMulti';
import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { mapArrayOfStringToOptionValues } from 'labstep-web/components/Metadata/Form/utils';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import styles from './styles.module.scss';
import { IMetadataShowEditTypeProps } from './types';

export const MetadataShowEditOptions: React.FC<
  IMetadataShowEditTypeProps
> = ({ metadata }) => {
  const currentValues = {
    values: (!!metadata.options && metadata.options.values) || {},
    is_allow_multiple:
      !!metadata.options && metadata.options.is_allow_multiple,
    is_allow_add: !!metadata.options && metadata.options.is_allow_add,
  };
  return (
    <Can
      entityName={metadata.entityName}
      id={metadata.id}
      action={Action.edit}
    >
      <div className={styles.container}>
        <EntityActionUpdateSelectMulti
          entity={metadata}
          optimistic
          defaultValues={currentValues.values}
          createBody={(keys) => {
            return {
              options: {
                ...currentValues,
                values: mapArrayOfStringToOptionValues(
                  metadata.optionsSelectedValues,
                  keys,
                ),
              },
            };
          }}
        />
        <EntityActionUpdate
          entityName={metadata.entityName}
          id={metadata.id}
          body={{
            options: {
              ...currentValues,
              is_allow_multiple: !currentValues.is_allow_multiple,
            },
          }}
          optimistic
          actionComponentProps={({ canEdit }) => ({
            type: 'checkbox',
            elementProps: {
              checked: currentValues.is_allow_multiple,
              disabled: !canEdit,
              toggle: false,
              label: 'Allow multiple selections',
            },
          })}
        />
        <EntityActionUpdate
          entityName={metadata.entityName}
          id={metadata.id}
          body={{
            options: {
              ...currentValues,
              is_allow_add: !currentValues.is_allow_add,
            },
          }}
          optimistic
          actionComponentProps={({ canEdit }) => ({
            type: 'checkbox',
            elementProps: {
              checked: currentValues.is_allow_add,
              disabled: !canEdit,
              toggle: false,
              label: 'Allow adding new options',
            },
          })}
        />
      </div>
    </Can>
  );
};

export default MetadataShowEditOptions;
