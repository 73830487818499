/**
 * Labstep
 *
 * @module core/Checkbox
 * @desc Core checkbox component. Wrapper around semantic.
 */

import React from 'react';
import SemanticCheckbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox';
import { ICheckboxProps } from './types';

export const Checkbox: React.FC<ICheckboxProps> = ({
  onClick,
  disabled,
  ...rest
}) => (
  <SemanticCheckbox
    onClick={disabled ? undefined : onClick}
    disabled={disabled}
    {...rest}
  />
);

export default Checkbox;
