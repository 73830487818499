/**
 * Labstep
 *
 * @module components/SortOptions/Active
 * @desc Active sort opions
 */

import React from 'react';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import SortOptionsOrder from '../Order';
import { ISortOptions } from '../types';
import { defaultSortOptionsCreatedAt } from '..';
import styles from './styles.module.scss';
import { ISortOptionsActiveProps } from './types';

const SortOptionsActive: React.FC<ISortOptionsActiveProps> = ({
  searchParams,
  clearParams,
  setParams,
  sortOptions,
  defaultSortOptions = defaultSortOptionsCreatedAt,
}) => {
  if (!searchParams.sort) {
    return null;
  }

  const options: ISortOptions = {
    ...defaultSortOptions,
    ...sortOptions,
  } as const;
  const activeSortKey = searchParams.sort as
    | `-${keyof ISortOptions}`
    | keyof ISortOptions;
  const activeSortOption =
    options[
      activeSortKey[0] === '-'
        ? (activeSortKey.slice(1) as keyof ISortOptions)
        : (activeSortKey as keyof ISortOptions)
    ];

  if (!activeSortOption) {
    return null;
  }

  return (
    <div data-testid="sort-active" className={styles.container}>
      <span>Sorting By:</span>
      <RemoveLabel
        name={activeSortOption.label}
        onRemoveClick={(): void => clearParams(['sort'])}
        action={
          <SortOptionsOrder
            activeSortKey={activeSortKey}
            setParams={setParams}
          />
        }
      />
    </div>
  );
};

export default SortOptionsActive;
