/**
 * Labstep
 *
 * @module components/Notification/Action/Disable
 * @desc Action to stop receiving notifications of this type
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import Dropdown from 'labstep-web/core/Dropdown';
import Loader from 'labstep-web/core/Loader';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { UserGroupNotification } from 'labstep-web/models/user-group-notification.model';
import React from 'react';
import { INotificationActionDisableProps } from './types';

export const NotificationActionDisable: React.FC<
  INotificationActionDisableProps
> = ({ notification, activeGroup }) => (
  <ReadOnMountHOC
    type="entities"
    loading={false}
    entityName={UserGroupNotification.entityName}
    params={{
      get_single: 1,
      notification_type: notification.type,
      user_group_id: activeGroup.logged_user_user_group.id,
    }}
    children={({ entity, status }) => {
      if (!(status && !status.isFetching && entity)) {
        return (
          <Dropdown.Item>
            <Loader active inline size="mini" />
          </Dropdown.Item>
        );
      }
      if (status && status.error) {
        return (
          <Dropdown.Item>Oops something went wrong..</Dropdown.Item>
        );
      }
      return (
        <EntityActionUpdate
          id={entity.id}
          entityName={entity.entityName}
          body={{ is_push: false }}
          actionComponentProps={{
            type: 'option',
            text: 'Turn off notifications like this',
          }}
          options={{ toast: true }}
        />
      );
    }}
  />
);

export default withActiveGroup(NotificationActionDisable);
