/**
 * Labstep
 *
 * @module
 * @desc Right part of Signin screen
 */

import SigninForm from 'labstep-web/components/User/Form/Signin';
import UserSigninSocialMedia from 'labstep-web/components/User/Signin/SocialMedia';
import Divider from 'labstep-web/core/Divider';
import AuthenticationComponent from 'labstep-web/screens/Authentication/Component';
import {
  AuthenticationSharedForgotPassword,
  SharedBody,
  SharedFooter,
  SignupLinks,
} from 'labstep-web/screens/Authentication/Shared';
import React, { useState } from 'react';
import { IFormDataProps, IRightProps } from './types';

const Right: React.FC<IRightProps> = ({ isMfaScreen }) => {
  const [formData, setFormData] = useState<IFormDataProps>({});
  return (
    <AuthenticationComponent>
      <div id="signin-form">
        {!isMfaScreen && !formData.username && (
          <>
            <UserSigninSocialMedia mode="signin" />

            <Divider horizontal>Or</Divider>
          </>
        )}
        <SharedBody>
          <SigninForm setFormData={setFormData} formData={formData} />
        </SharedBody>
        {!isMfaScreen && (
          <SharedFooter>
            <AuthenticationSharedForgotPassword />
            <SignupLinks />
          </SharedFooter>
        )}
      </div>
    </AuthenticationComponent>
  );
};
export default Right;
