/**
 * Labstep
 *
 * @module components/PurchaseOrder/SideMenu
 * @desc Side menu showing purchase order requests and finalise button
 */

import Container from 'labstep-web/components/Layout/Container';
import SideMenu from 'labstep-web/components/Layout/Menu/Side';
import OrderRequestList from 'labstep-web/components/OrderRequest/List';
import PurchaseOrderActionUpdateStatus from 'labstep-web/components/PurchaseOrder/Action/UpdateStatus';
import Button from 'labstep-web/core/Button';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import styles from './styles.module.scss';
import { IPurchaseOrderSideMenuProps } from './types';

export const PurchaseOrderSideMenu: React.FC<
  IPurchaseOrderSideMenuProps
> = ({ purchaseOrderId, header, history, onClose }) => (
  <SideMenu
    name="Order"
    open
    onClose={onClose}
    header={header}
    defaultChildrenContainer
    portal
    viewComponent={() => null}
  >
    <ReadOnMountHOC
      type="entities"
      entityName={PurchaseOrder.entityName}
      loading={{ loader: 'spinner' }}
      params={{
        get_single: 1,
        is_deleted: 'both',
        id: purchaseOrderId,
      }}
      children={({ entity: purchaseOrder }) => (
        <div className={styles.container}>
          <div className={styles.list}>
            <OrderRequestList
              actions={[]}
              orderRequests={purchaseOrder.order_requests}
            />
          </div>
          <div>
            <Container>
              <Button
                primary
                basic
                fluid
                route={{
                  to: 'purchase_order_show',
                  params: { id: purchaseOrder.id },
                }}
              >
                Go to Order
              </Button>
            </Container>
            <PurchaseOrderActionUpdateStatus
              purchaseOrder={purchaseOrder}
              options={{
                onSuccess: () => {
                  history.push(
                    navigation.get('purchase_order_show', {
                      id: purchaseOrder.id,
                    }),
                  );
                },
              }}
              actionComponentProps={{
                type: 'button',
                elementProps: { fluid: true },
              }}
            />
          </div>
        </div>
      )}
    />
  </SideMenu>
);

export default withRouter(PurchaseOrderSideMenu);
