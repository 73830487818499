/**
 * Labstep
 *
 * @module components/Experiment/StartEnd
 * @desc Start or finish depending on status
 */

import React from 'react';
import { withRouter } from 'react-router';
import { navigation } from 'labstep-web/services/navigation';
import ExperimentActionStart from 'labstep-web/components/Experiment/Action/Start';
import ExperimentActionEndReverse from 'labstep-web/components/Experiment/Action/End/Reverse';
import ExperimentActionEndWithCheck from 'labstep-web/components/Experiment/Action/End/WithCheck';
import { IExperimentActionStartEndProps } from './types';

export const ExperimentActionStartEnd: React.FC<
  IExperimentActionStartEndProps
> = ({
  experiment,
  history,
  actionType,
  redirect = true,
  showReverse = true,
}) => {
  if (experiment.started_at) {
    if (experiment.ended_at) {
      return showReverse ? (
        <ExperimentActionEndReverse experiment={experiment} />
      ) : null;
    }
    return (
      <ExperimentActionEndWithCheck
        actionType={actionType}
        options={{
          onSuccess: ({ response }) => {
            history.push(
              navigation.get('experiment_workflow_show_workflow', {
                id: response.entities.experiment[response.result]
                  .experiment_workflow,
              }),
            );
          },
        }}
        experiment={experiment}
      />
    );
  }

  return (
    <ExperimentActionStart
      options={
        redirect
          ? {
              onSuccess: ({ response }) =>
                history.push(
                  navigation.get('experiment_show', {
                    id: response.result,
                  }),
                ),
            }
          : {}
      }
      experiment={experiment}
    />
  );
};

export default withRouter(ExperimentActionStartEnd);
