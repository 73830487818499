/**
 * Labstep
 *
 * @module prosemirror/components/Menu/File/Action/Delete
 * @desc Button to delete a file node
 *
 * MenuFile - Component - Action - Delete
 */

import { ICONS } from 'labstep-web/constants/icons';
import Button from 'labstep-web/core/Button';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { IMenuFileActionDeleteProps } from './types';

const MenuFileActionDelete: React.FC<IMenuFileActionDeleteProps> = ({
  state,
  dispatch,
}) => (
  <Button
    onMouseDown={() => {
      dispatch?.(state.tr.deleteSelection());
    }}
  >
    <Icon name={ICONS.file.actions.delete} />
    <span>Delete</span>
  </Button>
);

export default MenuFileActionDelete;
