/**
 * Labstep
 *
 * @module core/DocumentHead
 * @desc Manages document head. Wrapper around react-helmet
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { IDocumentHeadProps } from './types';

const DocumentHead: React.FC<IDocumentHeadProps> = ({ title }) => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
);

export default DocumentHead;
