/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Labstep
 */

import { PermissionEntity } from 'labstep-web/components/Entity/Can/types';
import { Group } from 'labstep-web/models/group.model';
import { arrayify } from 'labstep-web/state/reducers/helpers';
import { PermissionActions } from 'labstep-web/typings';

export class AuthorizationService {
  public static hasAccess(
    entityName: string,
    permissionEntity: PermissionEntity,
    action: string,
    field?: string | string[],
  ): boolean {
    const allowedActions = permissionEntity.allowed_actions || [];

    if (allowedActions.length === 0) {
      return false;
    }

    if (
      AuthorizationService.checkAction(
        action,
        allowedActions,
        entityName,
        field,
      )
    ) {
      return true;
    }

    return false;
  }

  public static isActionAllowedForLockedEntity(
    permissionEntityEntityName: string,
    permissionEntity: PermissionEntity,
    action: string,
    field?: string | string[],
  ) {
    if (!('allowed_actions_lock' in permissionEntity)) {
      return false;
    }

    const allowedActionsLock: PermissionActions[] =
      permissionEntity.allowed_actions_lock as PermissionActions[];

    if (allowedActionsLock.length === 0) {
      return false;
    }

    if (
      AuthorizationService.checkAction(
        action,
        allowedActionsLock,
        permissionEntityEntityName,
        field,
      )
    ) {
      return true;
    }

    return false;
  }

  public static hasGroupAccess(action: string, group: Group) {
    if (!group || !group.logged_user_user_group) {
      return false;
    }

    if (action === 'view') {
      return true;
    }

    const userGroupType = group.logged_user_user_group.type;
    if (action === 'edit') {
      return userGroupType === 'edit' || userGroupType === 'owner';
    }
    if (action === 'owner') {
      return userGroupType === 'owner';
    }

    return false;
  }

  public static checkAction(
    action: string,
    allowedActions: string[],
    permissionEntityEntityName: string,
    fields?: string | string[],
  ): boolean {
    const getIsAllowed = (field?: string) => {
      for (let i = 0; i < allowedActions.length; i += 1) {
        if (
          AuthorizationService.checkActionCompareActionAndAllowedAction(
            action,
            allowedActions[i],
          )
        ) {
          return true;
        }
        if (
          AuthorizationService.checkActionCompareActionAndAllowedAction(
            action,
            `${permissionEntityEntityName}:${allowedActions[i]}`,
          )
        ) {
          return true;
        }
        if (
          AuthorizationService.checkActionCompareActionAndAllowedAction(
            action,
            allowedActions[i].replace(
              `${permissionEntityEntityName}:`,
              '',
            ),
          )
        ) {
          return true;
        }
        if (
          AuthorizationService.checkActionCompareActionAndAllowedAction(
            action,
            allowedActions[i].replace('*', ''),
          )
        ) {
          return true;
        }
        if (
          field &&
          AuthorizationService.checkActionCompareActionAndAllowedAction(
            `${action}:${field}`,
            allowedActions[i],
          )
        ) {
          return true;
        }
        if (
          field &&
          AuthorizationService.checkActionCompareActionAndAllowedAction(
            `${permissionEntityEntityName}:${action}:${field}`,
            allowedActions[i],
          )
        ) {
          return true;
        }
      }
      return false;
    };
    if (fields) {
      return arrayify(fields).every(getIsAllowed);
      // eslint-disable-next-line no-else-return
    } else if (getIsAllowed()) {
      return true;
    } else {
      return false;
    }
  }

  public static checkActionCompareActionAndAllowedAction(
    action: string,
    allowedAction: string,
  ): boolean {
    if (action === allowedAction) {
      return true;
    }
    if (allowedAction === '*') {
      return true;
    }
    const actionParts = action.split(':');
    const allowedActionParts = allowedAction.split(':');
    return allowedActionParts.every((p, i) => {
      if (p === '*') {
        return true;
      }
      return p === actionParts[i];
    });
  }
}
