/**
 * Labstep
 *
 * @module state/services/schema
 * @desc Combine all redux actions using normalizr
 */

import * as schema from './schema';

export default schema;
