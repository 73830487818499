/**
 * Labstep
 *
 * @module components/UserOrganization/Action/ManagePermissions
 * @desc Update workspace permissions per user
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import Button from 'labstep-web/core/Button';
import Popup from 'labstep-web/core/Popup';
import { IGroupActionManageMembersProps } from './types';
import { GroupActionManageMembersTable } from './Table';

export const GroupActionManageMembers: React.FC<
  IGroupActionManageMembersProps
> = ({ group, iconOnly, actionComponentProps }) => {
  return (
    <ModalDefault
      header={`Manage Members: ${group.name}`}
      size="large"
      content={() => <GroupActionManageMembersTable group={group} />}
      viewComponent={({ toggleModal }) => {
        return iconOnly ? (
          <Popup
            trigger={
              <Button icon="cog" circular onClick={toggleModal} />
            }
            content="Manage"
            size="mini"
          />
        ) : (
          <ActionComponent
            type="option"
            icon="users"
            text="Manage Members"
            {...actionComponentProps}
            onClick={toggleModal}
          />
        );
      }}
    />
  );
};
