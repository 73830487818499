/**
 * Labstep
 *
 * @module components/ResourceLocation/Action/StockCheck
 * @desc Action for resource location stock check
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import ResourceLocationStockCheck from 'labstep-web/components/ResourceLocation/StockCheck';
import { ICONS } from 'labstep-web/constants/icons';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { generateNewDateString } from 'labstep-web/services/date.service';
import React from 'react';
import { IResourceLocationActionStockCheckProps } from './types';

const ResourceLocationActionStockCheck: React.FC<
  IResourceLocationActionStockCheckProps
> = ({ resourceLocation, actionComponentProps }) => (
  <ModalDefault
    header="Stock Check"
    content={({ toggleModal }) => (
      <ResourceLocationStockCheck
        resourceLocation={resourceLocation}
        onDone={toggleModal}
      />
    )}
    viewComponent={({ toggleModal }) => (
      <EntityActionUpdate
        entityName={ResourceLocation.entityName}
        id={resourceLocation.guid}
        actionComponentProps={{
          type: 'option',
          ...actionComponentProps,
          icon: ICONS.resource_location.stock_check,
          text: 'Stock Check',
        }}
        body={{ stock_checked_at: generateNewDateString() }}
        options={{ onSuccess: toggleModal }}
      />
    )}
  />
);

export default ResourceLocationActionStockCheck;
