/**
 * Labstep
 *
 * @module components/PermissionRole/Action/Delete
 * @desc Delete resource location
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { IPermissionRoleActionDeleteProps } from './types';

const PermissionRoleActionDelete: React.FC<
  IPermissionRoleActionDeleteProps
> = ({ permissionRole }) => (
  <EntityActionDelete entity={permissionRole} />
);

export default PermissionRoleActionDelete;
