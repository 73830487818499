/**
 * Labstep
 *
 * @module containers/JupyterInstance
 * @desc Calls serverless endpoint to get jupyterlab url
 */

import {
  getJupyterInstance,
  postJupyterInstance,
  selectJupyterInstance,
} from 'labstep-web/state/slices/jupyter/jupyterInstance';
import React from 'react';
import { connect } from 'react-redux';
import {
  WithJupyterInstanceInjectedProps,
  WithJupyterInstanceMapDispatchToProps,
  WithJupyterInstanceMapStateToProps,
} from './types';

const mapStateToProps: any = (
  state,
): WithJupyterInstanceMapStateToProps => ({
  jupyterInstance: selectJupyterInstance(state),
});

const mapDispatchToProps = (
  dispatch,
): WithJupyterInstanceMapDispatchToProps => ({
  getJupyterInstance(
    type: string,
    jupyterNotebookId: string,
    isEnded?: boolean,
  ) {
    dispatch(
      getJupyterInstance({
        type,
        jupyter_notebook_id: jupyterNotebookId,
        is_ended: isEnded,
      }),
    );
  },
  postJupyterInstance(type: string, jupyterNotebookId: string) {
    dispatch(
      postJupyterInstance({
        type,
        jupyter_notebook_id: jupyterNotebookId,
      }),
    );
  },
});

export const withJupyterInstance = <P, S>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<P, Exclude<keyof P, keyof WithJupyterInstanceInjectedProps>>
> => connect(mapStateToProps, mapDispatchToProps)(Component as any);
