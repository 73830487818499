/**
 * Labstep
 *
 * @module
 * @desc Tracks and saves scroll so that it can scroll to the point
 * when navigating to that page again
 */

import debounce from 'lodash/debounce';
import React from 'react';
import { withRouter } from 'react-router';
import { IScrollTrackerProps, IScrollTrackerState } from './types';
import {
  getHasRouteSwitchedBetweenDesiredRoutes,
  getIsPathnameInDesiredRoutes,
  getKeyFromPathname,
} from './utils';

const ELEMENT_ID_TO_TRACK = 'screens-master-show-center';

/** Needs to be longer than the
 * transition timeout otherwise scrolling won't work */
const TIMEOUT_MS = 800;
export class ScrollTracker extends React.Component<
  IScrollTrackerProps,
  IScrollTrackerState
> {
  public element: HTMLElement | null | undefined;

  public onScroll!: VoidFunction;

  public constructor(props: IScrollTrackerProps) {
    super(props);
    this.state = {};
  }

  public componentDidMount(): void {
    this.setUpScrolling();
  }

  public componentDidUpdate(
    prevProps: Readonly<IScrollTrackerProps>,
  ): void {
    const { location } = this.props;

    if (prevProps.location.key !== location.key) {
      if (!getIsPathnameInDesiredRoutes(location.pathname)) {
        this.setState({});
        if (this.onScroll) {
          this.element?.removeEventListener('scroll', this.onScroll);
        }
      } else {
        this.setUpScrolling();

        if (
          getHasRouteSwitchedBetweenDesiredRoutes(
            prevProps.location.pathname,
            location.pathname,
          )
        ) {
          this.scrollToPosition();
        }
      }
    }
  }

  public setUpScrolling(): void {
    const { location } = this.props;

    if (getIsPathnameInDesiredRoutes(location.pathname)) {
      this.onScroll = debounce((): void => {
        const key = getKeyFromPathname(location.pathname);

        this.setState((prevState) => {
          return { ...prevState, [key]: this.element?.scrollTop };
        });
      }, 200);

      this.element?.removeEventListener('scroll', this.onScroll);
      setTimeout(() => {
        this.element = document.getElementById(ELEMENT_ID_TO_TRACK);

        if (this.element) {
          this.element.addEventListener('scroll', this.onScroll);
        }
      }, TIMEOUT_MS);
    }
  }

  public scrollToPosition(): void {
    const { location } = this.props;
    setTimeout(() => {
      const key = getKeyFromPathname(location.pathname);
      const scroll = this.state[key];

      if (scroll && this.element) {
        this.element.scrollTop = scroll;
      }
    }, TIMEOUT_MS);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, class-methods-use-this
  public render() {
    return null;
  }
}

export default withRouter(ScrollTracker);
