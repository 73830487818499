/**
 * Labstep
 *
 * @module core/Input
 * @desc Wrapper around semantic-ui-react Input
 */

import Input from 'semantic-ui-react/dist/commonjs/elements/Input';

export default Input;
