/**
 * Labstep
 *
 * @module core/Image
 * @desc Wrapper around semantic-ui-react Image
 */

import Image from 'semantic-ui-react/dist/commonjs/elements/Image';

export default Image;
