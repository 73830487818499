/**
 * Labstep
 *
 * @module state/epics/user
 * @desc Redux epic for user actions
 */

import { Action } from 'labstep-web/models/action.model';
import { configService } from 'labstep-web/services/config.service';
import { googleService } from 'labstep-web/services/google.service';
import * as sfApi from 'labstep-web/services/sf-api.service';
import { windowService } from 'labstep-web/services/window.service';
import { logoutHardAction } from 'labstep-web/state/actions/user';
import {
  INTERNAL_LOGIN,
  INTERNAL_REGISTER,
  LOGOUT,
  REGISTER_USER_IS_NEW,
  SUCCESS_INTERNAL_LOGIN,
} from 'labstep-web/state/constants/user';
import {
  selectAuthenticatedUser,
  selectAuthenticatedUserId,
  selectTokenSamlLoginUuid,
} from 'labstep-web/state/selectors/authenticated-user';
import { StateObservable } from 'redux-observable';
import { EMPTY, Observable, concat, of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { LabstepReduxState } from '../types';

interface IError {
  status: number;
  data: {
    message: string;
  };
}

const isDisabledError = (error: IError): boolean =>
  error &&
  error.status === 403 &&
  error.data &&
  error.data.message === 'Invalid credentials.';

const isUserEndpointReturningUnauthenticated = (
  action: Action,
): boolean =>
  action.meta?.url?.includes('generic/user/info') &&
  [401, 403].indexOf(action?.error?.status) > -1;

/**
 * Sends an action to disable authenticated user if account is locked
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onDisabledAccountActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type.includes('FAIL') &&
        (isDisabledError(action.error) ||
          isUserEndpointReturningUnauthenticated(action)),
    ),
    map(() => logoutHardAction()),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_LOGOUT_ON_USER_DISABLED_ACCOUNT',
          payload: err,
        }),
        source$,
      ),
    ),
  );

interface IBody {
  access_token: string;
  id_token: string;
  share_link_token?: string;
  is_signup_enterprise?: boolean;
}

/**
 * Sends an action to disable authenticated user if account is locked
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onSocialMediaLoginActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type === 'SOCIAL_MEDIA_LOGIN'),
    map((action: Action) => {
      const {
        share_link_token: shareLinkToken,
        provider,
        error,
        access_token,
        id_token,
        is_signup_enterprise,
      } = action.meta.sdkResponse;

      if (error) {
        return {
          type: 'FAIL_INTERNAL_LOGIN',
          error,
        };
      }

      const body: IBody = { access_token, id_token };
      if (shareLinkToken) {
        body.share_link_token = shareLinkToken;
      }
      if (is_signup_enterprise) {
        body.is_signup_enterprise = is_signup_enterprise;
      }

      return sfApi.post({
        type: INTERNAL_LOGIN,
        route: {
          custom: `app_publicapi_user_login${provider}`,
        },
        body,
        meta: { authProvider: provider },
        onFail: action.meta.onFail,
        onSuccess: action.meta.onSuccess,
      });
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_USER_SOCIAL_MEDIA_LOGIN',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Start logout
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onLogoutStartActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type === 'START_LOGOUT'),
    map(() => {
      if (
        state$.value.jupyter?.jupyter_instance?.edit &&
        Object.keys(state$.value.jupyter?.jupyter_instance?.edit)
          .length > 0
      ) {
        windowService.setLocation(
          `${configService.jupyterUrl}/hub/logout`,
        );
        return {
          type: 'LOGOUT_JUPYTER',
        };
      }

      return {
        type: 'CALL_LABSTEP_LOGOUT_ENDPOINT',
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_USER_LOGOUT_GOOGLE', payload: err }),
        source$,
      ),
    ),
  );

/**
 * Logout Google
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onLogoutGoogleActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type === 'LOGOUT_SUCCESS'),
    map(() => {
      const { authProvider } = selectAuthenticatedUser(state$.value);

      if (authProvider === 'google') {
        // TODO Need to put the call to GAPI logout into the async pipe
        googleService.logout();
      }

      return {
        type: 'GOOGLE_LOGOUT_SKIP',
        meta: { authProvider },
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_USER_LOGOUT_GOOGLE', payload: err }),
        source$,
      ),
    ),
  );

/**
 * Logout Success > wipe local storage
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onLogoutSuccessActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'LOGOUT_SUCCESS' ||
        action.type === 'SUCCESS_LOGOUT',
    ),
    map(() => {
      windowService.clearLocalStorage();
      windowService.setLocation('/login');

      return {
        type: 'LOGOUT_SUCCESS_WIPED_LOCAL_STORAGE',
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_USER_LOGOUT_SUCCESS', payload: err }),
        source$,
      ),
    ),
  );

/**
 * Internal register
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onInternalRegisterActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type === 'INTERNAL_REGISTER'),
    map((action: Action) => {
      return sfApi.post({
        type: INTERNAL_REGISTER,
        route: {
          custom: 'app_publicapi_user_postuser',
        },
        body: { ...action.meta.userData },
        onFail: action.meta.onFail,
        onSuccess: action.meta.onSuccess,
      });
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_USER_INTERNAL_REGISTER',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Internal register success
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onInternalRegisterSuccessActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) => action.type === 'SUCCESS_INTERNAL_REGISTER',
    ),
    map((action: Action) => {
      if (action.meta.onSuccess) {
        action.meta.onSuccess();
      }
      return {
        type: 'SUCCESS_INTERNAL_LOGIN',
        meta: {
          authProvider: 'internal',
        },
        payload: action.payload,
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_USER_INTERNAL_REGISTER_SUCCESS',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * SAML Login Success
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onSAMLLoginSuccessActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type === 'SUCCESS_SAML_LOGIN'),
    map((action: Action) => {
      if (action.meta.onSuccess) {
        action.meta.onSuccess();
      }
      return {
        type: 'SUCCESS_INTERNAL_LOGIN',
        meta: {
          authProvider: 'saml',
        },
        payload: action.payload,
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_USER_SAML_LOGIN_SUCCESS',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Read authenticated user
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onReadAuthenticatedUserActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) => action.type === 'READ_AUTHENTICATED_USER',
    ),
    map((action: Action) =>
      sfApi.get({
        type: 'READ_USER',
        route: {
          custom: 'app_api_user_getinfo',
        },
        meta: {
          identifier: selectAuthenticatedUserId(state$.value),
          normalize: 'user',
        },
        ...action.meta.options,
      }),
    ),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_USER_READ_AUTHENTICATED_USER',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Trigger custom action for new user
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onNewUserActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) => action.type === SUCCESS_INTERNAL_LOGIN,
    ),
    mergeMap((action: Action) => {
      if (action.payload && action.payload.is_new) {
        return of({
          type: REGISTER_USER_IS_NEW,
          payload: action.payload,
        });
      }
      return EMPTY;
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_USER_NEW_USER', payload: err }),
        source$,
      ),
    ),
  );

/**
 * When we get a SAML login url, redirect user to it.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onSuccessGetSAMLLoginUrlActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'SUCCESS_GET_SAML_LOGIN_URL',
    ),
    tap((action: Action) => {
      if (action.payload && action.payload.url) {
        windowService.setLocation(action.payload.url);
      }
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_USER_SUCCESS_GET_SAML_LOGIN_URL',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * On fail internal login with error_code 'login_saml_user',
 * trigger a call to get SAML login url.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onLoginSamlUserActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'FAIL_INTERNAL_LOGIN_CHECK' ||
        action.type === 'FAIL_INTERNAL_REGISTER',
    ),
    tap((action: Action) => {
      if (
        action.error &&
        action.error.data &&
        action.error.data.error_code &&
        action.error.data.error_code === 'login_saml_user' &&
        action.error.data.metadata &&
        action.error.data.metadata.saml_login_url
      ) {
        windowService.setLocation(
          action.error.data.metadata.saml_login_url,
        );
      }
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_LOGIN_SAML_USER',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Logout will call API logout endpoint and send SAML token uuid if any.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onStartLogoutEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'CALL_LABSTEP_LOGOUT_ENDPOINT',
    ),
    map(() => {
      const tokenSamlLoginUuid = selectTokenSamlLoginUuid(
        state$.value,
      );
      return sfApi.post({
        type: LOGOUT,
        route: {
          custom: 'app_api_user_logout',
        },
        body: {
          tokenSamlLoginUuid,
        },
      });
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_USER_LOGOUT',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * When user creates an organization, reload user from info endpoint.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onSuccessCreateOrganizationEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'SUCCESS_CREATE_ORGANIZATION',
    ),
    map((action: Action) =>
      sfApi.get({
        type: 'READ_USER',
        route: {
          custom: 'app_api_user_getinfo',
        },
        meta: {
          identifier: selectAuthenticatedUserId(state$.value),
          normalize: 'user',
        },
        ...action.meta.options,
      }),
    ),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_SUCCESS_CREATE_ORGANIZATION',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * When user leaves a workspace and it is the logged user, redirect the user to the list of workspaces.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onLeaveWorkspaceEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) => action.type === 'SUCCESS_UPDATE_USER_GROUP',
    ),
    tap((action: Action) => {
      const authenticatedUser = selectAuthenticatedUser(state$.value);
      if (
        action.meta?.body?.deleted_at &&
        action.meta?.denormalized_payload?.user?.id &&
        authenticatedUser?.id &&
        action.meta?.denormalized_payload?.user?.id ===
          authenticatedUser.id
      ) {
        windowService.setLocation('/workspaces');
      }
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_LEAVE_WORKSPACE',
          payload: err,
        }),
        source$,
      ),
    ),
  );
