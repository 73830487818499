/**
 * Labstep
 */

import { PremiumFeature } from 'labstep-web/models/organization.model';
import { selectAuthenticatedUserEntity } from 'labstep-web/state/selectors/authenticated-user';
import { useSelector } from 'react-redux';

export const useHasFeatureFlagEnabled = (
  feature: PremiumFeature,
): boolean => {
  const hasFeatureEnabled = useSelector((state) => {
    const authenticatedUserEntity =
      selectAuthenticatedUserEntity(state);
    if (!authenticatedUserEntity) {
      return false;
    }
    return authenticatedUserEntity.hasFeatureEnabled(feature);
  });
  return hasFeatureEnabled;
};

export const useHasFeatureFlagsEnabled = (
  features: PremiumFeature[],
): Record<PremiumFeature, boolean> => {
  const featuresEnabled = useSelector((state) => {
    const authenticatedUserEntity =
      selectAuthenticatedUserEntity(state);

    if (!authenticatedUserEntity) {
      return features.reduce(
        (acc, feature) => {
          acc[feature] = false;
          return acc;
        },
        {} as Record<PremiumFeature, boolean>,
      );
    }

    return features.reduce(
      (acc, feature) => {
        acc[feature] =
          authenticatedUserEntity.hasFeatureEnabled(feature);
        return acc;
      },
      {} as Record<PremiumFeature, boolean>,
    );
  });

  return featuresEnabled;
};
