/**
 * Labstep
 *
 * @desc A service to deal with amount and units
 */

import * as ExcelIO from '@mescius/spread-excelio';
import { configService } from '../config.service';

const localExcelIO: {
  IO: typeof ExcelIO.IO;
  LicenseKey?: string;
} = ExcelIO; // Create a local variable

export class ExcelIOService {
  /**
   * Initialise SpreadJS LicenseKey and return a new IO instance.
   */
  public static get IO(): ExcelIO.IO {
    if (configService && configService.spreadJSLicenseKey) {
      localExcelIO.LicenseKey = configService.spreadJSLicenseKey; // Assign the LicenseKey
    }
    return new ExcelIO.IO();
  }
}
