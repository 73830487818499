/**
 * Labstep
 *
 * @module components/UserGroup/Action/Create/Bulk
 * @desc Bulk Share
 */

import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ActionComponentBulk from 'labstep-web/core/Action/Component/Bulk';
import FormReusable from 'labstep-web/core/Form/Reusable';
import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import { mixed } from 'yup';
import { UserGroup } from 'labstep-web/models/user-group.model';
import { IUserGroupActionCreateBulkProps } from './types';

const groupField: IFieldWithoutActionProps = {
  name: 'group',
  elementProps: {
    entityName: 'group',
  },
  fieldType: FieldType.SearchSelect,
  validation: mixed().required(),
};

export const UserGroupActionCreateBulk: React.FC<
  IUserGroupActionCreateBulkProps
> = ({ entities, options }) => {
  if (!entities || entities.length === 0) {
    return null;
  }

  return (
    <Modal
      size="small"
      header="Add to Workspace"
      viewComponent={({ toggleModal }) => (
        <ActionComponentBulk
          entitiesWithPermissionCount={entities.length}
          entitiesSelectedCount={entities.length}
          onClick={toggleModal}
          content="Add to Workspace"
          icon="sitemap"
        />
      )}
      content={({ toggleModal }) => (
        <EntityCreateContainer
          entityName={UserGroup.entityName}
          batch
        >
          {({ create, status }) => (
            <FormReusable
              submitButtonText="Add to Workspace"
              onCancel={toggleModal}
              status={status}
              onSubmit={(values: any) => {
                create(
                  entities.map((e: any) => ({
                    group_id: values.group_id,
                    user_id: e.user.id,
                  })),
                  options,
                );
              }}
              fields={[groupField]}
            />
          )}
        </EntityCreateContainer>
      )}
    />
  );
};
