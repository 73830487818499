/**
 * Labstep
 *
 * @module prosemirror/extensions/expanded/plugin
 * @desc Expanded plugin definition to store expanded items state
 */

import { Plugin, PluginKey } from 'prosemirror-state';

export const expandedPluginKey = new PluginKey('expanded');

const expandedPlugin = new Plugin({
  key: expandedPluginKey,
  state: {
    init: () => ({
      file: null,
    }),
    apply: (tr, prev) => tr.getMeta(expandedPluginKey) || prev,
  },
});

export default expandedPlugin;
