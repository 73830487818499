/**
 * Labstep
 *
 * @module components/Resource/Preview
 * @desc Preview for resource
 */

import React from 'react';
import EntityPreview from 'labstep-web/components/Entity/Preview';
import ResourceTemplatePreview from 'labstep-web/components/ResourceTemplate/Preview';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import ResourceActionMenu from 'labstep-web/components/Resource/Action/Menu';
import { Action } from 'labstep-web/components/Resource/Action/Menu/types';
import {
  IResourcePreviewProps,
  IResourcePreviewManagerProps,
} from './types';

export const ResourcePreview: React.FC<IResourcePreviewProps> = ({
  resource,
  ...rest
}) => (
  <EntityPreview
    actionMenu={
      <ResourceActionMenu
        resource={resource}
        actions={[
          Action.set_resource_template,
          Action.tag,
          Action.create_order_request,
          Action.share,
          Action.duplicate,
          Action.delete,
          Action.transfer_ownership,
        ]}
      />
    }
    entity={resource}
    secondaryInfo={<MetadataManager entity={resource} />}
    {...rest}
  />
);

const ResourcePreviewManager: React.FC<
  IResourcePreviewManagerProps
> = ({ resource, ...rest }) =>
  resource.is_template ? (
    <ResourceTemplatePreview {...rest} resource={resource} />
  ) : (
    <ResourcePreview {...rest} resource={resource} />
  );

export default ResourcePreviewManager;
