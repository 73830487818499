/**
 * Labstep
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const fieldEntityName: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'name',
  validation: rules.entity.name_nulable,
  placeholder: 'Enter a name',
  header: true,
};
