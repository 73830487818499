import { AvailableFormat, ElementalAnalysis } from './types';

/**
 * MarvinJS Service. Talks to MarvinJS API deployed on Labstep.
 */
export class MarvinJSService {
  private baseUrl: string;

  public constructor() {
    this.baseUrl = 'https://marvinjs.labstep.com/rest-v1';
  }

  /**
   *
   * @param data
   * @returns Structure in SMILES format
   */
  public getSmiles(data: string): Promise<string> {
    return this.getStructure(data, 'smiles', null);
  }

  /**
   *
   * @param structure Structure to convert
   * @param outputFormat Format to convert to
   * @param inputFormat Format to convert from
   * @returns Converted structure
   */
  public getStructure(
    structure: string,
    outputFormat: 'png' | 'jpeg' | 'svg' | AvailableFormat,
    inputFormat: AvailableFormat | null,
  ): Promise<string> {
    const url = `${this.baseUrl}/util/calculate/molExport`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        structure,
        parameters: outputFormat,
        inputFormat,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (Array.isArray(result)) {
          return result[0].structure;
        }
        return (result as any).structure;
      });
  }

  /**
   *
   * @param source Structure to get chemical properties for
   * @returns Chemical properties (Formula + Mass) of the structure
   */
  public getChemicalProperties(
    source: string,
  ): Promise<Pick<ElementalAnalysis, 'formula' | 'mass'>[]> {
    const url = `${this.baseUrl}/util/calculate/elementalAnalysis`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        structure: source,
        mass: true,
        formula: true,
      }),
    }).then((res) => res.json());
  }
}

const marvinJSService = new MarvinJSService();

export default marvinJSService;
