/**
 * Labstep
 *
 * @module components/Group/Settings/Inventory
 */

import React from 'react';
import { TagType } from 'labstep-web/models/tag.model';
import ResourceTemplateIndex from 'labstep-web/screens/ResourceTemplate/Index';
import ScreensResourceLocationIndex from 'labstep-web/screens/ResourceLocation/Index';
import TagIndex from 'labstep-web/screens/Tag/Index';
import LayoutLinks from 'labstep-web/components/Layout/Links';
import PremiumFeatureScreenGuard from 'labstep-web/core/PremiumFeature/ScreenGuard';
import { IGroupSettingsInventoryProps } from './types';

const GroupSettingsInventory: React.FC<
  IGroupSettingsInventoryProps
> = ({ group }) => {
  return (
    <div id="group-settings-inventory" style={{ flex: 1 }}>
      <PremiumFeatureScreenGuard unstyled premiumFeature="inventory">
        <LayoutLinks
          inverted
          links={[
            {
              children: 'Categories',
              route: {
                to: 'group_settings_inventory_categories',
                params: { id: group.id },
              },
              render: () => <ResourceTemplateIndex group={group} />,
            },
            {
              children: 'Locations',
              route: {
                to: 'group_settings_inventory_locations',
                params: { id: group.id },
              },
              render: () => (
                <ScreensResourceLocationIndex group={group} />
              ),
            },
            {
              children: 'Tags',
              route: {
                to: 'group_settings_inventory_tags',
                params: { id: group.id },
              },
              render: () => (
                <TagIndex group={group} type={TagType.resource} />
              ),
            },
          ]}
        />
      </PremiumFeatureScreenGuard>
    </div>
  );
};

export default GroupSettingsInventory;
