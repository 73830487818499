/**
 * Labstep
 */

import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { agGridProseMirrorClass } from 'labstep-web/prosemirror/components/NodeView/Conditions';
import { navigation } from 'labstep-web/services/navigation';
import { ignoreElementsOnClick } from './constants';

export const getMessage =
  (experiment: Experiment, experimentWorkflow?: ExperimentWorkflow) =>
  (location: { pathname: string }): boolean | string =>
    location.pathname.startsWith(
      experimentWorkflow
        ? navigation.get('experiment_workflow_show', {
            id: experimentWorkflow.id,
          })
        : navigation.get('experiment_show', {
            id: experiment.id,
          }),
    )
      ? true
      : 'You have unsaved changes, are you sure you want to leave?';

export const getOnClick =
  (
    editing: boolean,
    setEditMode: VoidFunction,
    setViewMode: VoidFunction,
  ): React.MouseEventHandler<HTMLDivElement> =>
  (e): void => {
    const element = e.target;
    if (!(element instanceof HTMLElement)) {
      return;
    }
    const targetOutsideProsemirror =
      element.getAttribute('id') !== 'prose-mirror-editor' &&
      !element.closest('#prose-mirror-editor');
    if (targetOutsideProsemirror) {
      return;
    }

    const targetInsideGrapecity =
      element.getAttribute('gcuielement') === 'gcSpread' ||
      !!element.closest('div[gcuielement="gcSpread"]');
    const targetInsideAGGrid = Array.from(
      document.getElementsByClassName(agGridProseMirrorClass),
    ).some((agGrid) => agGrid.contains(element));
    if (targetInsideGrapecity || targetInsideAGGrid) {
      if (editing) {
        setViewMode();
      }
      return;
    }

    if (
      !ignoreElementsOnClick.some(
        (parent) => !!element.closest(parent),
      )
    ) {
      if (!editing) {
        setEditMode();
      }
    }
  };
