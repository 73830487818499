/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/History
 * @desc Buttons to undo/redo
 */

import React from 'react';
import { modToPlatformText } from 'labstep-web/prosemirror/utils';
import { redo, undo } from 'prosemirror-history';
import ToolbarButton from 'labstep-web/prosemirror/components/Toolbar/Button';
import { IHistoryToolbarProps } from './types';

const ExtensionHistoryToolbar: React.FC<IHistoryToolbarProps> = ({
  state,
  dispatch,
}) => (
  <div style={{ display: 'flex' }}>
    <ToolbarButton
      icon="undo"
      popup={`Undo (${modToPlatformText()}+Z)`}
      disabled={!undo(state)}
      onMouseDown={() => {
        undo(state, dispatch);
      }}
    />
    <ToolbarButton
      icon="redo"
      // TODO: the shortcut for windows is different
      popup={`Redo (${modToPlatformText()}+⇧+Z)`}
      disabled={!redo(state)}
      onMouseDown={() => {
        redo(state, dispatch);
      }}
    />
  </div>
);

export default ExtensionHistoryToolbar;
