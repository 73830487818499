/**
 * Labstep
 *
 * @module components/Layout/MainContent/Center
 * @desc Layout center
 */

import cx from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import { ILayoutMainContentCenterProps } from './types';

const LayoutMainContentCenter: React.FC<
  ILayoutMainContentCenterProps
> = ({ children, className }) => (
  <div
    className={cx(className, styles.container)}
    id="main-content-center"
  >
    {children}
  </div>
);

export default LayoutMainContentCenter;
