/**
 * Labstep
 *
 * @module components/Group/Action/SetHome
 * @desc SetHome a group
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ActionComponent from 'labstep-web/core/Action/Component';
import { UserGroup } from 'labstep-web/models/user-group.model';
import React from 'react';
import { IGroupActionSetHomeProps } from './types';

export const canSetHome = (userGroup: UserGroup) =>
  !userGroup.is_home && !userGroup.group.deleted_at;

export const GroupActionSetHome: React.FC<
  IGroupActionSetHomeProps
> = ({ userGroup, actionComponentProps }) => (
  <EntityUpdateContainer
    entityName={UserGroup.entityName}
    id={userGroup.id}
  >
    {({ update, status }) => (
      <ActionComponent
        {...actionComponentProps}
        status={status}
        text="Set as Home"
        icon="home"
        onClick={() => update({ is_home: true })}
      />
    )}
  </EntityUpdateContainer>
);

const ShouldRenderContainer: React.FC<IGroupActionSetHomeProps> = (
  props,
) => {
  if (canSetHome(props.userGroup)) {
    return <GroupActionSetHome {...props} />;
  }
  return null;
};

export default ShouldRenderContainer;
