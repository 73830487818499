/**
 * Labstep
 */

import ShowEditSelect from 'labstep-web/core/Form/ShowEdit/Select';
import { TARGET_ALLOWED_ACTIONS_LOCK_OPTIONS } from 'labstep-web/models/entity-state.model';
import isEqual from 'lodash/isEqual';
import React from 'react';
import {
  LOCK_SETTINGS_KEYS,
  fieldEntityStateTargetAllowedActionsLock,
} from '../../fields';
import { IEntityStateFormShowEditTargetAllowedActionsLockProps } from './types';

const EntityStateFormShowEditTargetAllowedActionsLock: React.FC<
  IEntityStateFormShowEditTargetAllowedActionsLockProps
> = ({ entityState }) => (
  <ShowEditSelect
    entity={entityState}
    field={fieldEntityStateTargetAllowedActionsLock}
    name="permitted actions"
    isClearable={false}
    preSubmit={(values) => {
      const option =
        TARGET_ALLOWED_ACTIONS_LOCK_OPTIONS[
          values[
            fieldEntityStateTargetAllowedActionsLock.name as LOCK_SETTINGS_KEYS
          ] as LOCK_SETTINGS_KEYS
        ];
      const body = {
        [fieldEntityStateTargetAllowedActionsLock.name]:
          option.target_allowed_actions_lock,
        is_lock: option.is_lock,
      };

      return body;
    }}
  >
    {Object.keys(TARGET_ALLOWED_ACTIONS_LOCK_OPTIONS).find((key) =>
      isEqual(
        TARGET_ALLOWED_ACTIONS_LOCK_OPTIONS[key as LOCK_SETTINGS_KEYS]
          .permissions,
        entityState.target_allowed_actions_lock,
      ),
    )}
  </ShowEditSelect>
);

export default EntityStateFormShowEditTargetAllowedActionsLock;
