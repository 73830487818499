/**
 * Labstep
 *
 * @module core/DataGrid/CellRenderer/MultiSelect
 * @desc CellRenderer MultiSelect
 */

import Label from 'labstep-web/core/Label';
import { Entity } from 'labstep-web/models/entity.model';
import React from 'react';
import DataGridPlaceholder from '../../Placeholder';
import { ICellRendererMultiSelectProps } from './types';

export const DataGridCellRendererMultiSelect: React.FC<
  ICellRendererMultiSelectProps<Entity>
> = React.memo((params) => (
  <>
    {params.props.values.length ? (
      params.props.values.map((value) => (
        <Label key={value} circular>
          {value}
        </Label>
      ))
    ) : (
      <DataGridPlaceholder
        children="Select"
        params={params}
        editable={params.props.editable}
      />
    )}
  </>
));

export default DataGridCellRendererMultiSelect;
