/**
 * Labstep
 *
 * @module components/Organization/Settings/Sso
 * @desc Displays whether organization SSO is enabled or disabled
 */

import React from 'react';
import PremiumFeatureToggle from 'labstep-web/components/Organization/PremiumFeatureToggle';
import OrganizationSamlActionCreate from 'labstep-web/components/OrganizationSAML/Action/Create';
import ModalDefault from 'labstep-web/core/Modal/Default';
import OrganizationSAMLShow from 'labstep-web/components/OrganizationSAML/Show';
import ActionComponent from 'labstep-web/core/Action/Component';
import Checkbox from 'labstep-web/core/Checkbox';
import { IOrganizationSettingsSsoProps } from './types';

export const OrganizationSettingsSso: React.FC<
  IOrganizationSettingsSsoProps
> = ({ organization }) => {
  if (!organization.hasFeatureEnabled('sso')) {
    return <PremiumFeatureToggle premiumFeature="sso" />;
  }
  return (
    <div>
      <OrganizationSamlActionCreate
        organization={organization}
        actionComponentProps={{
          type: 'checkbox',
          elementProps: {
            checked: !!organization.organization_saml,
            disabled: organization.organization_saml?.is_enabled,
            toggle: true,
          },
        }}
      />
      {organization.organization_saml && (
        <>
          {organization.organization_saml.is_enabled ? (
            <Checkbox toggle checked disabled />
          ) : (
            <ModalDefault
              header="SSO Details"
              content={
                <OrganizationSAMLShow organization={organization} />
              }
              size="large"
              viewComponent={({
                toggleModal,
              }): React.ReactElement => (
                <ActionComponent
                  type="text"
                  text="Setup Details"
                  onClick={toggleModal}
                />
              )}
            />
          )}
        </>
      )}
    </div>
  );
};

export default OrganizationSettingsSso;
