/**
 * Labstep
 *
 * @desc Create Form for resource
 */

import React from 'react';
import EntityFormCreateTemplatable from 'labstep-web/components/Entity/Form/Create/Templatable';
import { IResourceFormCreateProps } from './types';

export const ResourceFormCreate: React.FC<
  IResourceFormCreateProps
> = ({ defaultValues, options }) => (
  <EntityFormCreateTemplatable
    entityName="resource"
    options={options}
    defaultValues={defaultValues}
  />
);

export default ResourceFormCreate;
