/**
 * Labstep
 *
 * @module components/Layout/Group/List/Preview
 * @desc A list of 3 groups + View all + Plus button to create new one
 */

import GroupItem from 'labstep-web/components/Group/Item';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import Link from 'labstep-web/core/Link';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Group } from 'labstep-web/models/group.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { params } from './params';
import styles from './styles.module.scss';
import { IChildrenProps, IGroupListPreviewProps } from './types';

export const GroupListPreview: React.FC<IGroupListPreviewProps> = ({
  activeGroup,
}) => (
  <div className={styles.container}>
    <div>
      <ReadOnMountHOC
        type="cursor"
        entityName="user_group"
        params={params}
        pagination={{
          loader: false,
          showMore: false,
          infinite: false,
        }}
      >
        {({ entities: userGroups }: IChildrenProps) => (
          <>
            {userGroups
              .filter(
                (userGroup) =>
                  !userGroup.deleted_at &&
                  userGroup.group.id !== activeGroup.id,
              )
              .map((userGroup) => (
                <div
                  key={userGroup.id}
                  className={styles.dropdownItem}
                >
                  <Link
                    to="group_show"
                    params={{ id: userGroup.group.id }}
                  >
                    <GroupItem group={userGroup.group} />
                  </Link>
                </div>
              ))}
          </>
        )}
      </ReadOnMountHOC>
    </div>
    <div className={styles.all}>
      <Link to="group_index">
        All {getHumanReadableEntityName(Group.entityName, true, true)}
      </Link>
    </div>
  </div>
);

export default withActiveGroup(GroupListPreview);
