/**
 * Labstep
 *
 * @module core/Text/Error
 * @desc TextError (red color for error)
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import styles from './styles.module.scss';
import { ICoreTextErrorProps } from './types';

const TextError: React.FC<ICoreTextErrorProps> = ({
  children,
  withIcon,
}) => (
  <span className={styles.textError} data-testid="text-error">
    {withIcon && <Icon name="exclamation triangle" />}
    {children}
  </span>
);

export default TextError;
