/**
 * Labstep
 *
 * @module containers/ActiveGroup
 * @desc Container for ui
 */

import { connect } from 'react-redux';
import React from 'react';
import { LabstepReduxState } from 'labstep-web/state/types';
import {
  selectActiveGroup,
  selectActiveGroupId,
  setActiveGroupId,
} from 'labstep-web/state/slices/activeGroup';
import {
  IWithActiveGroupInjectedProps,
  IWithActiveGroupMapDispatchToProps,
  IWithActiveGroupMapStateToProps,
} from './types';

const mapStateToProps = (
  state: LabstepReduxState,
): IWithActiveGroupMapStateToProps => {
  return {
    activeGroup: selectActiveGroup(state),
    activeGroupId: selectActiveGroupId(state),
  };
};

const mapDispatchToProps: IWithActiveGroupMapDispatchToProps = {
  setActiveGroupId,
};

export const withActiveGroup = <
  P extends IWithActiveGroupInjectedProps,
  S,
>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<P, Exclude<keyof P, keyof IWithActiveGroupInjectedProps>>
> => connect(mapStateToProps, mapDispatchToProps)(Component as any);
