/**
 * Labstep
 */

export class ProsemirrorService {
  static transformDecorationState(
    state: any[],
    position = 0,
    depth = 1,
  ) {
    return state
      .filter((d, index) => (index + 1) % 3 === 0)
      .reduce((result, decoration, index) => {
        const localTransformedDecorations = decoration.local.map(
          (localDecoration) => {
            const { from, to } = localDecoration;
            const { comment } = localDecoration.type.spec;
            const startingPos = state[index * 3] + position + depth;
            return {
              from: from + startingPos,
              to: to + startingPos,
              ...comment,
            };
          },
        );

        const newPosition = state[index * 3] + position;
        const childrenTransformedDecorations =
          ProsemirrorService.transformDecorationState(
            decoration.children,
            newPosition,
            depth + 1,
          );
        return [
          ...result,
          ...localTransformedDecorations,
          ...childrenTransformedDecorations,
        ];
      }, []);
  }
}
