/**
 * Labstep
 *
 * @module components/OrderRequest/Card/EmptyState
 * @desc Empty state for group
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Card from 'labstep-web/core/Card/Card';
import OrderRequestCreateAction from 'labstep-web/components/OrderRequest/Action/Create';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { STRINGS } from 'labstep-web/strings';
import { IOrderRequestEmptyStateProps } from './types';

const label = getHumanReadableEntityName(
  OrderRequest.entityName,
  true,
  true,
);

export const OrderRequestEmptyState: React.FC<
  IOrderRequestEmptyStateProps
> = (props) => (
  <EmptyState
    title={`No ${OrderRequest.getHumanReadableEntityName(
      true,
      true,
    )}`}
    explanation={STRINGS.order_request.empty_state}
    src="/img/empty-state/orderRequests.svg"
    action={<OrderRequestCreateAction />}
    {...props}
  />
);

const OrderRequestEmptyStateCard = () => (
  <Card header={label}>
    <OrderRequestEmptyState />
  </Card>
);

export default OrderRequestEmptyStateCard;
