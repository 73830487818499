/**
 * Labstep
 *
 * @author     Mira Davidson <mira.davidson@labstep.com>
 *
 * @module screens/Organization/LegacyDomain
 * @desc Legacy url that redirects an organization specific domain to app.labstep
 */

import React, { useEffect } from 'react';

export const ScreensOrganizationLegacyDomainProduction: React.FC =
  () => {
    useEffect(() => {
      const url = window.location.href;
      window.location.replace(
        url.replace(window.location.host, 'app.labstep.com'),
      );
    }, []);

    return null;
  };

export const ScreensOrganizationLegacyDomainStaging: React.FC =
  () => {
    useEffect(() => {
      const url = window.location.href;
      window.location.replace(
        url.replace(window.location.host, 'app-staging.labstep.com'),
      );
    }, []);

    return null;
  };
