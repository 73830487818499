/**
 * Labstep
 *
 * @module prosemirror/nodes/horizontal-rule/commands
 * @desc Commands for Horizontal rule node
 */

import NodeHorizontalRule from 'labstep-web/prosemirror/nodes/horizontal-rule';

export const createHorizontalRuleCmd = (state, dispatch) => {
  dispatch(
    state.tr
      .replaceSelectionWith(
        state.schema.nodes[NodeHorizontalRule.key].create(),
      )
      .scrollIntoView(),
  );
  return true;
};
