/**
 * Labstep
 *
 * @module components/ExperimentWorkflowLink/Action/Menu
 * @desc Action Menu for experiment workflow link
 */

import React from 'react';
import ActionMenu from 'labstep-web/core/Action/Menu';
import ExperimentWorkflowLinkActionDelete from 'labstep-web/components/ExperimentWorkflowLink/Action/Delete';
import { IExperimentWorkflowLinkActionMenuProps } from './types';

export const ExperimentWorkflowLinkActionMenu: React.FC<
  IExperimentWorkflowLinkActionMenuProps
> = ({ experimentWorkflowLink, type, actions = [] }) => (
  <ActionMenu type={type}>
    {actions.includes('delete') && (
      <ExperimentWorkflowLinkActionDelete
        experimentWorkflowLink={experimentWorkflowLink}
      />
    )}
  </ActionMenu>
);

ExperimentWorkflowLinkActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default ExperimentWorkflowLinkActionMenu;
