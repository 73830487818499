/**
 * Labstep
 */

import { PremiumFeatureConfig } from 'labstep-web/config/premium-feature';
import {
  OptionWithConfig,
  PremiumKey,
  Source,
} from 'labstep-web/config/premium-feature/types';
import { useHasFeatureFlagsEnabled } from 'labstep-web/hooks/feature-flag';
import { PremiumFeature } from 'labstep-web/models/organization.model';
import { useMemo } from 'react';

function getPremiumFeatures(
  premiumKeys: PremiumKey[],
  source: Source,
): PremiumFeature[] {
  return Object.entries(PremiumFeatureConfig).reduce<
    PremiumFeature[]
  >((matchedFeatures, [featureKey, featureConfig]) => {
    if (
      premiumKeys.some((key) =>
        featureConfig.premiumKeys.includes(key),
      ) &&
      (featureConfig.show.includes(source) ||
        featureConfig.hide.includes(source))
    ) {
      matchedFeatures.push(featureKey as PremiumFeature);
    }
    return matchedFeatures;
  }, []);
}

/**
 * Custom hook to determine the visibility and status of premium features for a list of options.
 *
 * @template T - The type of objects in the options array.
 * @template K - The key within each option object that is used to match with the premium feature configuration.
 *
 * @param {T[]} options - Array of objects representing the options. Each object must include the key specified by `keyName`.
 * @param {K} keyName - The key in each option object to match against the premium feature configuration.
 * @param {Source} source - The source type for configuration.
 *
 * @returns {Array<T & { premiumFeature?: PremiumFeature; show: boolean; premiumFeatureEnabled: boolean; }>}
 * - Returns only the options that should be shown, extended with premium feature details.
 */
export const usePremiumFeatureStatus = <
  T extends Record<string, any>,
  K extends keyof T,
>(
  options: T[],
  keyName: K,
  source: Source,
): OptionWithConfig<T>[] => {
  const extractedPremiumKeys: PremiumKey[] = options.map(
    (obj) => obj[keyName],
  );

  const premiumFeatures = getPremiumFeatures(
    extractedPremiumKeys,
    source,
  );

  const featureFlags = useHasFeatureFlagsEnabled(premiumFeatures);

  const features = Object.entries(PremiumFeatureConfig).map(
    ([name, config]) => ({ name, config }),
  );

  const optionsWithConfig = useMemo(() => {
    return options.map((option) => {
      const feature = features.find((feat) =>
        feat.config.premiumKeys.find(
          (key) =>
            key === option[keyName] &&
            (feat.config.show.includes(source) ||
              feat.config.hide.includes(source)),
        ),
      );

      if (!feature) {
        return {
          ...option,
          premiumFeature: undefined,
          show: true,
          premiumFeatureEnabled: true,
        } as OptionWithConfig<T>;
      }

      return {
        ...option,
        premiumFeature: feature.name,
        show:
          feature.config.show.includes(source) ||
          featureFlags[feature.name as PremiumFeature],
        premiumFeatureEnabled:
          featureFlags[feature.name as PremiumFeature] ?? false,
      } as OptionWithConfig<T>;
    });
  }, [options, featureFlags]);

  return optionsWithConfig.filter((o) => o.show);
};
