/**
 * Labstep
 *
 * @module components/ResourceLocation/Action/DeleteMap
 * @desc Action to delete file
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalConfirmation from 'labstep-web/core/Modal/Confirmation';
import React from 'react';
import { IResourceLocationActionDeleteMapProps } from './types';

export const ResourceLocationActionDeleteMap: React.FC<
  IResourceLocationActionDeleteMapProps
> = ({ resourceLocation }) => (
  <EntityUpdateContainer
    entityName={resourceLocation.entityName}
    id={resourceLocation.guid}
  >
    {({ status, update }) => (
      <ModalConfirmation
        header="Delete Map"
        status={status}
        onConfirm={({ toggleModal }) => {
          update(
            { map_data: null },
            {
              onSuccess: toggleModal,
            },
          );
        }}
        message="Are you sure you want to delete the position map for this location?"
        confirmButtonContent="Delete"
      >
        {({ toggleModal }) => (
          <ActionComponent
            type="icon"
            icon="trash"
            elementProps={{ popup: { content: 'Delete Map' } }}
            onClick={toggleModal}
          />
        )}
      </ModalConfirmation>
    )}
  </EntityUpdateContainer>
);

export default ResourceLocationActionDeleteMap;
