/**
 * Labstep
 *
 * @module components/ProtocolTimer/Action/Reset
 * @desc Action to reset timer to original time
 */

import React from 'react';
import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { IProtocolTimerResetActionContainerProps } from './types';

export const ProtocolTimerResetActionContainer: React.FC<
  IProtocolTimerResetActionContainerProps
> = ({ protocolTimer, options, actionComponentProps }) => (
  <EntityActionUpdate
    entityName={protocolTimer.entityName}
    id={protocolTimer.idAttr}
    body={{
      paused_at: null,
      ended_at: null,
    }}
    options={options}
    actionComponentProps={actionComponentProps}
  />
);

export default ProtocolTimerResetActionContainer;
