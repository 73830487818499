/**
 * Labstep
 *
 * @module screens/ResourceTemplate/Show/Center
 * @desc ResourceTemplate Show Center
 */

import EntityPrimaryInfo from 'labstep-web/components/Entity/PrimaryInfo';
import ResourceTemplateActionMenu from 'labstep-web/components/ResourceTemplate/Action/Menu';
import { Action } from 'labstep-web/components/ResourceTemplate/Action/Menu/types';
import ResourceTemplateLayoutLinks from 'labstep-web/components/ResourceTemplate/LayoutLinks';
import { ICONS } from 'labstep-web/constants/icons';
import InformationCard from 'labstep-web/core/Card/Information';
import React from 'react';
import { ICenterProps } from './types';

const Center: React.FC<ICenterProps> = ({ resourceTemplate }) => (
  <InformationCard
    actionMenu={
      <ResourceTemplateActionMenu
        resourceTemplate={resourceTemplate}
        actions={[
          Action.duplicate,
          Action.share,
          Action.delete,
          Action.transfer_ownership,
          Action.export,
        ]}
      />
    }
    primaryInfo={
      <EntityPrimaryInfo
        entity={resourceTemplate}
        icon={ICONS.resource_template.primary}
      />
    }
    bottom={
      <ResourceTemplateLayoutLinks
        resourceTemplate={resourceTemplate}
      />
    }
  />
);

export default Center;
