/**
 * Labstep
 */

import EntityActionUpdateCheckbox from 'labstep-web/components/Entity/Action/Update/Checkbox';
import React from 'react';
import { IGroupActionSetIsSignatureTwoFactorRequiredProps } from './types';

export const GroupActionSetIsSignatureTwoFactorRequired: React.FC<
  IGroupActionSetIsSignatureTwoFactorRequiredProps
> = ({ group }) =>
  group.canEnableSignatureTwoFactorRequirement ? (
    <EntityActionUpdateCheckbox
      entity={group}
      field={'is_signature_two_factor_required' as const}
      toggle
      optimistic
    />
  ) : null;

export default GroupActionSetIsSignatureTwoFactorRequired;
