/**
 * Labstep
 *
 * @module components/EntityImport/Action/AddRow
 * @desc Add a row in DataGrid
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import { useAgGridServiceFromContext } from 'labstep-web/core/DataGrid/context';

export const EntityImportActionAddRow: React.FC = () => {
  const agGridService = useAgGridServiceFromContext();
  if (!agGridService) {
    return null;
  }

  return (
    <ActionComponent
      onClick={(): void => agGridService.addNewRow()}
      type="text"
      icon="plus"
      text="Add row"
    />
  );
};

export default EntityImportActionAddRow;
