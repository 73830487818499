/**
 * Labstep
 *
 * @module components/ProtocolTimer/Modal
 * @desc Modal for protocol timer
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import ProtocolTimerActionEdit from 'labstep-web/components/ProtocolTimer/Action/Edit';
import ProtocolTimerActionReset from 'labstep-web/components/ProtocolTimer/Action/Reset';
import { ProtocolTimerItemContainer } from 'labstep-web/containers/ProtocolTimer/Item';
import {
  hasTimerStarted,
  isRunning,
  isRunningLow,
} from 'labstep-web/containers/ProtocolTimer/Item/utils';
import ActionComponent from 'labstep-web/core/Action/Component';
import React from 'react';
import ProtocolTimerModalContentTimer from './Timer';
import styles from './styles.module.scss';
import {
  IProtocolTimerModalContentContainerProps,
  IProtocolTimerModalContentProps,
} from './types';

export const ProtocolTimerModalContent: React.FC<
  IProtocolTimerModalContentProps
> = ({ protocolTimer, timeRemaining, updateProtocolTimer }) => {
  const onClick = (evt: Event) => {
    evt.stopPropagation();
    updateProtocolTimer();
  };
  const running = isRunning(protocolTimer);

  const hasTimerBeenRun = !hasTimerStarted(
    timeRemaining,
    protocolTimer,
  );

  const color = isRunningLow(timeRemaining) ? 'red' : 'blue';

  return (
    <>
      <div />
      <div>
        <ProtocolTimerModalContentTimer
          timeRemaining={timeRemaining}
          color={color}
        />
        <Can
          entityName={protocolTimer.entityName}
          id={protocolTimer.id}
          action={Action.edit}
          field={['ended_at', 'paused_at']}
        >
          <div className={styles.buttonContainer}>
            <ActionComponent
              type="icon"
              onClick={onClick}
              elementProps={{ size: 'big', color }}
              icon={running ? 'pause' : 'play'}
            />
            {!hasTimerBeenRun && !running && (
              <ProtocolTimerActionEdit
                viewComponent={({ toggleModal }) => (
                  <ActionComponent
                    type="icon"
                    onClick={toggleModal}
                    icon="pencil"
                    elementProps={{
                      size: 'big',
                    }}
                  />
                )}
                protocolTimer={protocolTimer}
              />
            )}
            {!running && (
              <ProtocolTimerActionReset
                actionComponentProps={{
                  disabled: running,
                  type: 'children',
                  children: ({ onClick: reset, status }) => (
                    <ActionComponent
                      type="icon"
                      icon="undo"
                      onClick={reset}
                      status={status}
                      disabled={running}
                      elementProps={{
                        size: 'big',
                      }}
                    />
                  ),
                }}
                protocolTimer={protocolTimer}
              />
            )}
          </div>
        </Can>
      </div>
    </>
  );
};

export const ProtocolTimerModalContentContainer: React.FC<
  IProtocolTimerModalContentContainerProps
> = ({ protocolTimer, protocol }) => (
  <div className={styles.container}>
    <ProtocolTimerItemContainer protocolTimer={protocolTimer}>
      {(containerProps) => (
        <ProtocolTimerModalContent
          protocol={protocol}
          protocolTimer={protocolTimer}
          {...containerProps}
        />
      )}
    </ProtocolTimerItemContainer>
  </div>
);

export default ProtocolTimerModalContentContainer;
