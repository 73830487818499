/**
 * Labstep
 *
 * @module components/App
 * @desc Main App component
 */

import FullPageError from 'labstep-web/components/Layout/FullPageError';
import LoadingContent from 'labstep-web/components/Layout/LoadingContent';
import Toast from 'labstep-web/components/Toast';
import { ERRORS } from 'labstep-web/constants/error';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import {
  withAuthenticatedUser,
  withAuthenticatedUserEntity,
} from 'labstep-web/containers/AuthenticatedUser';
import { EntityReadEntityAnyContainer } from 'labstep-web/containers/Entity/Read/Entity/Any';
import { Group } from 'labstep-web/models/group.model';
import ScreensGroupHomeless from 'labstep-web/screens/GroupHomeless';
import ScreensOrganizationCreate from 'labstep-web/screens/Organization/Create';
import ScreensOrganizationFreeTrialEnded from 'labstep-web/screens/Organization/FreeTrialEnded';
import {
  ScreensOrganizationLegacyDomainProduction,
  ScreensOrganizationLegacyDomainStaging,
} from 'labstep-web/screens/Organization/LegacyDomain';
import {
  CRICK_HOST_LEGACY,
  UKDRI_HOST_LEGACY_PRODUCTION,
  UKDRI_HOST_LEGACY_STAGING,
} from 'labstep-web/screens/Organization/LegacyDomain/constants';
import ScreensUserBlocked from 'labstep-web/screens/User/Blocked';
import ScreensUserDeleted from 'labstep-web/screens/User/Deleted';
import bugsnagService from 'labstep-web/services/bugsnag.service';
import { intercomService } from 'labstep-web/services/intercom.service';
import { LogRocketService } from 'labstep-web/services/logrocket.service';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import {
  IMainAppContainerProps,
  IMainAppProps,
  IRehydrationGuardProps,
} from './types';

export class MainApp extends React.Component<
  IMainAppProps,
  { loading: boolean; mounted: boolean }
> {
  constructor(props: IMainAppProps) {
    super(props);
    this.state = {
      loading: false,
      // We guarantee that readUser will be called before any nested component
      // mounts and fires another api call
      mounted: false,
    };
  }

  componentDidMount() {
    const { authenticatedUser } = this.props;
    this.setState({ mounted: true });
    if (authenticatedUser.token) {
      this.readUser();
    }
  }

  componentDidUpdate(prevProps) {
    const { authenticatedUser } = this.props;
    if (
      (authenticatedUser.token &&
        !prevProps.authenticatedUser.token) ||
      (authenticatedUser.token &&
        authenticatedUser.enabled &&
        !prevProps.authenticatedUser.enabled)
    ) {
      this.readUser();
    }
  }

  updateLoading() {
    // Set timeout so that subsequent redux actions (e.g. SET_AUTHENTICATED_USER_DISABLED) are processed
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1);
  }

  readUser() {
    const { read, readGroup, setActiveGroupId, activeGroupId } =
      this.props;

    const onSuccess = ({ response, meta }) => {
      // Identify User in analytics
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const user: any = response.entities.user[response.result];
      if (
        !(
          user &&
          user.user_organizations[0] &&
          user.user_organizations[0].organization &&
          user.user_organizations[0].organization.identifier ===
            'max_planck_society'
        )
      ) {
        LogRocketService.identify(user.username, user.name);
      }
      bugsnagService.identify(user);
      intercomService.identify(meta.denormalized_payload);

      const setUserGroup = () => {
        setActiveGroupId(user.group);
        this.updateLoading();
      };

      if (!activeGroupId) {
        setUserGroup();
      } else {
        readGroup(activeGroupId, {
          onSuccess: () => this.updateLoading(),
          onFail: () => setUserGroup(),
        });
      }
    };

    this.setState({ loading: true });
    read({ onSuccess, onFail: () => this.updateLoading() });
  }

  render() {
    const { loading, mounted } = this.state;
    const {
      authenticatedUserEntityStatus,
      authenticatedUserEntity,
      authenticatedUser,
      activeGroup,
      read,
    } = this.props;

    const error =
      authenticatedUserEntityStatus &&
      authenticatedUserEntityStatus.error;

    const loadingComponent = (
      <LoadingContent
        status={authenticatedUserEntityStatus}
        loader="full_screen"
        children={undefined}
      />
    );

    if (
      loading ||
      !mounted ||
      (error && error.status !== 403) ||
      (authenticatedUser.token && !authenticatedUserEntityStatus)
    ) {
      return loadingComponent;
    }

    if (
      authenticatedUserEntity &&
      authenticatedUserEntity.is_signup_enterprise &&
      !authenticatedUserEntity.userOrganization
    ) {
      return <ScreensOrganizationCreate read={read} />;
    }

    if (authenticatedUserEntity && !activeGroup) {
      return <ScreensGroupHomeless />;
    }

    if (
      authenticatedUserEntity &&
      authenticatedUserEntity.userOrganization &&
      authenticatedUserEntity.userOrganization.organization
        .hasTrialEnded
    ) {
      return (
        <ScreensOrganizationFreeTrialEnded
          userOrganization={authenticatedUserEntity.userOrganization}
        />
      );
    }

    return <Routes />;
  }
}

export const MainAppContainer: React.FC<IMainAppContainerProps> = (
  props,
) => (
  <EntityReadEntityAnyContainer entityName={Group.entityName}>
    {({ read: readGroup }) => (
      <MainApp readGroup={readGroup} {...props} />
    )}
  </EntityReadEntityAnyContainer>
);

export const ConnectedMainApp = withAuthenticatedUserEntity(
  withActiveGroup(MainAppContainer),
);

/**
 * Wrap around in AlertProvider
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AlertWrapper: React.FC<any> = ({
  authenticatedUser,
  loggingStatus,
}) => {
  if (
    window.location.host === CRICK_HOST_LEGACY ||
    window.location.host === UKDRI_HOST_LEGACY_PRODUCTION
  ) {
    return <ScreensOrganizationLegacyDomainProduction />;
  }

  if (window.location.host === UKDRI_HOST_LEGACY_STAGING) {
    return <ScreensOrganizationLegacyDomainStaging />;
  }

  if (
    loggingStatus &&
    loggingStatus.error &&
    loggingStatus.error.data &&
    loggingStatus.error.data.error_code ===
      ERRORS.ORGANIZATION_INVALID_IP
  ) {
    return <ScreensUserBlocked />;
  }

  if (authenticatedUser.deleted_at) {
    return <ScreensUserDeleted />;
  }
  return <ConnectedMainApp authenticatedUser={authenticatedUser} />;
};

export const ConnectedAlertWrapper =
  withAuthenticatedUser(AlertWrapper);

/**
 * Rehydrates before loading app
 */
export const RehydrationGuard: React.FC<IRehydrationGuardProps> = ({
  rehydrated,
  maintenance,
}) => {
  if (rehydrated) {
    return maintenance ? (
      <FullPageError message="Labstep is going through maintenance. Please come back in an hour." />
    ) : (
      <BrowserRouter>
        <ConnectedAlertWrapper />
        <Toaster
          toastOptions={{
            success: {
              style: {
                background: '#0b3e8c',
              },
            },
            error: {
              style: {
                background: '#dc3b3b',
              },
            },
          }}
        />
        <Toast />
      </BrowserRouter>
    );
  }
  return (
    <BrowserRouter>
      <LoadingContent
        defaultFetching
        status={null}
        loader="full_screen"
        children={undefined}
      />
    </BrowserRouter>
  );
};

export const mapStateToProps = (state) => ({
  rehydrated: state.ui.rehydrated,
  maintenance: state.ui.maintenance,
});

export const ConnectedRehydrationGuard =
  connect(mapStateToProps)(RehydrationGuard);

export default () => (
  <>
    <ConnectedRehydrationGuard />
  </>
);
