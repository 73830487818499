/**
 * Labstep
 *
 * @module components/Device/Booking
 * @desc TimelineGrid for Device booking
 */

import FullCalendar from '@fullcalendar/react';
import Event from 'labstep-web/components/DeviceBooking/TimeGrid/Event';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import {
  useHasAccessCreate,
  useHasAccessMultiple,
} from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import FormShowEditDateToggleComponent from 'labstep-web/core/Form/ShowEdit/Date/ToggleComponent';
import Header from 'labstep-web/core/Header';
import Loader from 'labstep-web/core/Loader';
import Portal from 'labstep-web/core/ReactPortal';
import TimeGrid from 'labstep-web/core/TimeGrid';
import { DeviceBooking } from 'labstep-web/models/device-booking.model';
import { dateRange, format } from 'labstep-web/services/date.service';
import { isEqual } from 'lodash';
import React from 'react';
import styles from './styles.module.scss';
import {
  IDeviceBookingTimeGridProps,
  IDeviceBookingTimeGridState,
  IDeviceBookingTimeGridWithAccessProps,
} from './types';
import { formatEvent } from './utils';

// FullCalendar element to insert title
export const FC_TITLE_SELECTOR =
  '.fc-header-toolbar > .fc-toolbar-chunk:nth-child(2)';
export const FC_TIMEGRID_SELECTOR = '.fc-timegrid';

export class DeviceBookingTimeGrid extends React.Component<
  IDeviceBookingTimeGridProps,
  IDeviceBookingTimeGridState
> {
  constructor(props: IDeviceBookingTimeGridProps) {
    super(props);
    this.state = { mounted: false };
  }

  shouldComponentUpdate(
    nextProps: IDeviceBookingTimeGridProps,
    nextState: IDeviceBookingTimeGridState,
  ) {
    return (
      !isEqual(this.props.deviceBookings, nextProps.deviceBookings) ||
      !isEqual(this.props.status, nextProps.status) ||
      !isEqual(this.props.updateStatuses, nextProps.updateStatuses) ||
      !isEqual(this.state, nextState)
    );
  }

  render() {
    const {
      deviceBookings,
      device,
      status,
      updateStatuses,
      update,
      setActiveEditModal,
      setParams,
      searchParams,
      expanded,
      canEdit,
      deviceBookingsCanEdit,
      canCreateDeviceBooking,
    } = this.props;

    const timeGridRef = React.createRef<FullCalendar>();

    const isReading = status && status.isFetching;
    const isUpdating = updateStatuses.some(
      (updateStatus) => updateStatus.isFetching,
    );
    const isLoading = isReading || isUpdating;

    const handleDateChange = (dates) => {
      const calendarApi = timeGridRef.current.getApi();
      calendarApi.gotoDate(dates[0]);
    };

    return (
      <div className={styles.container}>
        {this.state.mounted && searchParams.started_at_within && (
          <>
            <Portal
              parent={document.querySelector(FC_TITLE_SELECTOR)}
            >
              <div className={styles.title}>
                <FormShowEditDateToggleComponent
                  onChange={handleDateChange}
                  viewComponent={
                    <Header size="mini" noMargin>
                      {dateRange(
                        searchParams.started_at_within,
                        searchParams.ended_at_within,
                      )}
                    </Header>
                  }
                  dateOnly
                  canEdit
                  defaultDate={searchParams.started_at_within}
                />
              </div>
            </Portal>
            <Portal
              parent={document.querySelector(FC_TIMEGRID_SELECTOR)}
            >
              {isReading && <Loader active />}
            </Portal>
          </>
        )}
        <EntityCreateContainer entityName={DeviceBooking.entityName}>
          {({ create }) => (
            <TimeGrid
              ref={timeGridRef}
              initialView={
                expanded ? 'timeGridWeek' : 'timeGridThreeDay'
              }
              headerToolbar={{
                left: 'prev,next today',
                right: expanded
                  ? 'timeGridWeek,timeGridDay'
                  : 'timeGridThreeDay,timeGridDay',
              }}
              events={deviceBookings.map((deviceBooking) =>
                formatEvent(
                  deviceBooking,
                  isLoading ||
                    !deviceBookingsCanEdit[deviceBooking.id],
                ),
              )}
              eventChange={({ event }) =>
                update(
                  event.id,
                  {
                    started_at: format(event.start),
                    ended_at: format(event.end),
                  },
                  {
                    optimistic: true,
                  },
                )
              }
              select={(event) => {
                create(
                  {
                    started_at: format(event.start),
                    ended_at: format(event.end),
                    device_id: device.id,
                  },
                  {
                    onSuccess: ({ response }) =>
                      setActiveEditModal({
                        entityName: DeviceBooking.entityName,
                        id: response.result,
                      }),
                  },
                );
              }}
              eventContent={({ event }) => (
                <Event
                  deviceBooking={event.extendedProps.deviceBooking}
                  disabled={isLoading}
                />
              )}
              eventOverlap={false}
              selectOverlap={false}
              selectable={canCreateDeviceBooking && !isLoading}
              editable={canEdit && !isLoading}
              datesSet={(arg) => {
                setParams({
                  started_at_within: format(arg.start),
                  ended_at_within: format(arg.end),
                });
              }}
              viewDidMount={() => {
                this.setState({
                  mounted: true,
                });
              }}
              // set date when changing between modal view
              initialDate={searchParams.started_at_within}
            />
          )}
        </EntityCreateContainer>
      </div>
    );
  }
}

export const DeviceBookingTimeGridWithAccess: React.FC<
  IDeviceBookingTimeGridWithAccessProps
> = (props) => {
  const canEdit = useHasAccess(
    props.device.entityName,
    props.device.id,
    Action.edit,
  );

  const deviceBookingsCanEdit = useHasAccessMultiple(
    DeviceBooking.entityName,
    props.deviceBookings.map((e) => e.id),
    Action.device_booking_edit,
  );

  const canCreateDeviceBooking = useHasAccessCreate(
    DeviceBooking.entityName,
    props.device.entityName,
    props.device.id,
  );

  return (
    <DeviceBookingTimeGrid
      canEdit={canEdit}
      deviceBookingsCanEdit={deviceBookingsCanEdit}
      canCreateDeviceBooking={canCreateDeviceBooking}
      {...props}
    />
  );
};

export default DeviceBookingTimeGridWithAccess;
