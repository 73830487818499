/**
 * Labstep
 *
 * @module core/Chemistry/Editor/Marvin
 * @desc MarvinJS Editor
 */

import React from 'react';
import { IChemistryEditorMarvinProps } from './types';

const loadScript = (url: string): void => {
  const script = document.createElement('script');

  script.src = url;
  script.async = true;

  document.body.appendChild(script);
};
export class ChemistryEditorMarvin extends React.Component<
  IChemistryEditorMarvinProps,
  { editorReady: boolean }
> {
  public constructor(props: IChemistryEditorMarvinProps) {
    super(props);
    this.onLoad = this.onLoad.bind(this);
    this.state = {
      editorReady: false,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public componentDidMount(): void {
    loadScript('/marvinjs/gui/lib/promise-1.0.0.min.js');
    loadScript('/marvinjs/js/marvinjslauncher.js');
  }

  public onLoad = async (): Promise<void> => {
    const editor = await (window as any).MarvinJSUtil.getEditor(
      '#sketch',
    );
    this.props.onLoad(editor);
    this.setState({ editorReady: true });
  };

  public render(): React.ReactElement {
    return (
      <iframe
        hidden={this.props.hidden}
        title="chemical-editor"
        id="sketch"
        onLoad={() => {
          setTimeout(this.onLoad, 500);
        }}
        src="/marvinjs/editorws.html"
        width="100%"
        data-toolbars={this.props['data-toolbars'] || 'reporting'}
        height={this.state.editorReady ? '500' : '0'}
      />
    );
  }
}

export default ChemistryEditorMarvin;
