/**
 * Labstep
 *
 * @module screens/PurchaseOrder/Index/Breadcrumb
 * @desc PurchaseOrder Index Breadcrumb
 */

import { ICONS } from 'labstep-web/constants/icons';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import ScreensIndexBreadcrumb from 'labstep-web/screens/Breadcrumb';
import React from 'react';

export const PurchaseOrderIndexBreadcrumb: React.FC = () => (
  <ScreensIndexBreadcrumb
    text={`All ${PurchaseOrder.getHumanReadableEntityName(
      true,
      true,
    )}`}
    icon={ICONS[PurchaseOrder.entityName].primary}
  />
);

export default PurchaseOrderIndexBreadcrumb;
