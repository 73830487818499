/**
 * Labstep
 *
 * @module components/Organization/Form/fields
 * @desc Organization fields
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const fieldOrganizationName: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'Name',
  name: 'name',
  validation: rules.organization.name,
  placeholder: 'Give your organization a name',
};

export const fieldOrganizationWhitelistIp: IFieldWithoutActionProps =
  {
    name: 'whitelist_ip',
    placeholder: 'Enter the IPs you would like to be whitelisted',
    fieldType: FieldType.SelectMulti,
    validation: rules.organization.whitelist_ip,
    elementProps: {
      pattern: 'ip',
    },
  };
