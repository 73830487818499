/**
 * Labstep
 */

export const precautionaryStatements = {
  P101: `If medical advice is needed, have product container or label at hand.`,
  P102: `Keep out of reach of children.`,
  P103: `Read label before use`,
  P201: `Obtain special instructions before use.`,
  P202: `Do not handle until all safety precautions have been read and understood.`,
  P210: `Keep away from heat, hot surface, sparks, open flames and other ignition sources. - No smoking.`,
  P211: `Do not spray on an open flame or other ignition source.`,
  P212: `Avoid heating under confinement or reduction of the desensitized agent.`,
  P220: `Keep away from clothing and other combustible materials.`,
  P221: `Take any precaution to avoid mixing with combustibles`,
  P222: `Do not allow contact with air.`,
  P223: `Do not allow contact with water.`,
  P230: `Keep wetted.`,
  P231: `Handle under inert gas.`,
  P232: `Protect from moisture.`,
  P233: `Keep container tightly closed.`,
  P234: `Keep only in original container.`,
  P235: `Keep cool.`,
  P240: `Ground/bond container and receiving equipment.`,
  P241: `Use explosion-proof equipment.`,
  P242: `Use only non-sparking tools.`,
  P243: `Take precautionary measures against static discharge.`,
  P244: `Keep valves and fittings free from oil and grease.`,
  P250: `Do not subject to grinding/shock/friction`,
  P251: `Do not pierce or burn, even after use.`,
  P260: `Do not breathe dust/fume/gas/mist/vapors/spray.`,
  P261: `Avoid breathing dust/fume/gas/mist/vapors/spray.`,
  P262: `Do not get in eyes, on skin, or on clothing.`,
  P263: `Avoid contact during pregnancy/while nursing.`,
  P264: `Wash thoroughly after handling.`,
  P270: `Do not eat, drink or smoke when using this product.`,
  P271: `Use only outdoors or in a well-ventilated area.`,
  P272: `Contaminated work clothing should not be allowed out of the workplace.`,
  P273: `Avoid release to the environment.`,
  P280: `Wear protective gloves/protective clothing/eye protection/face protection.`,
  P281: `Use personal protective equipment as required.`,
  P282: `Wear cold insulating gloves/face shield/eye protection.`,
  P283: `Wear fire resistant or flame retardant clothing.`,
  P284: `[In case of inadequate ventilation] Wear respiratory protection.`,
  P285: `In case of inadequate ventilation wear respiratory protection.`,
  'P231+P232': `Handle under inert gas. Protect from moisture.`,
  'P235+P410': `Keep cool. Protect from sunlight.`,
  P301: `IF SWALLOWED:`,
  P302: `IF ON SKIN:`,
  P303: `IF ON SKIN (or hair):`,
  P304: `IF INHALED:`,
  P305: `IF IN EYES:`,
  P306: `IF ON CLOTHING:`,
  P307: `IF exposed:`,
  P308: `IF exposed or concerned:`,
  P309: `IF exposed or if you feel unwell`,
  P310: `Immediately call a POISON CENTER or doctor/physician.`,
  P311: `Call a POISON CENTER or doctor`,
  P312: `Call a POISON CENTER or doctor if you feel unwell.`,
  P313: `Get medical advice/attention.`,
  P314: `Get medical advice/attention if you feel unwell.`,
  P315: `Get immediate medical advice/attention.`,
  P320: `Specific treatment is urgent.`,
  P321: `Specific treatment.`,
  P322: `Specific measures.`,
  P330: `Rinse mouth.`,
  P331: `Do NOT induce vomiting.`,
  P332: `IF SKIN irritation occurs:`,
  P333: `If skin irritation or rash occurs:`,
  P334: `Immerse in cool water [or wrap in wet bandages].`,
  P335: `Brush off loose particles from skin.`,
  P336: `Thaw frosted parts with lukewarm water. Do not rub affected area.`,
  P337: `If eye irritation persists:`,
  P338: `Remove contact lenses, if present and easy to do. Continue rinsing.`,
  P340: `Remove victim to fresh air and keep at rest in a position comfortable for breathing.`,
  P341: `If breathing is difficult, remove victim to fresh air and keep at rest in a position comfortable for breathing.`,
  P342: `If experiencing respiratory symptoms:`,
  P350: `Gently wash with plenty of soap and water.`,
  P351: `Rinse cautiously with water for several minutes.`,
  P352: `Wash with plenty of water/[see label]`,
  P353: `Rinse skin with water [or shower].`,
  P360: `Rinse immediately contaminated clothing and skin with plenty of water before removing clothes.`,
  P361: `Take off immediately all contaminated clothing.`,
  P362: `Take off contaminated clothing.`,
  P363: `Wash contaminated clothing before reuse.`,
  P364: `And wash it before reuse.`,
  P370: `In case of fire:`,
  P371: `In case of major fire and large quantities:`,
  P372: `Explosion risk.`,
  P373: `DO NOT fight fire when fire reaches explosives.`,
  P374: `Fight fire with normal precautions from a reasonable distance.`,
  P376: `Stop leak if safe to do so.`,
  P377: `Leaking gas fire: Do not extinguish, unless leak can be stopped safely.`,
  P378: `Use [see label] to extinguish.`,
  P380: `Evacuate area.`,
  P381: `In case of leakage, eliminate all ignition sources.`,
  P390: `Absorb spillage to prevent material damage.`,
  P391: `Collect spillage.`,
  'P301+P310': `IF SWALLOWED: Immediately call a POISON CENTER/doctor`,
  'P301+P312': `IF SWALLOWED: call a POISON CENTER/doctor IF you feel unwell.`,
  'P301+P330+P331': `IF SWALLOWED: Rinse mouth. Do NOT induce vomiting.`,
  'P302+P334': `IF ON SKIN: Immerse in cool water [or wrap in wet bandages].`,
  'P302+P335+P334': `Brush off loose particles from skin. Immerse in cool water [or wrap in wet bandages].`,
  'P302+P350': `IF ON SKIN: Gently wash with plenty of soap and water.`,
  'P302+P352': `IF ON SKIN: wash with plenty of water.`,
  'P303+P361+P353': `IF ON SKIN (or hair): Take off Immediately all contaminated clothing. Rinse SKIN with water [or shower].`,
  'P304+P312': `IF INHALED: Call a POISON CENTER/doctor if you feel unwell.`,
  'P304+P340': `IF INHALED: Remove person to fresh air and keep comfortable for breathing.`,
  'P304+P341': `IF INHALED: If breathing is difficult, remove victim to fresh air and keep at rest in a position comfortable for breathing.`,
  'P305+P351+P338': `IF IN EYES: Rinse cautiously with water for several minutes. Remove contact lenses if present and easy to do - continue rinsing.`,
  'P306+P360': `IF ON CLOTHING: Rinse Immediately contaminated CLOTHING and SKIN with plenty of water before removing clothes.`,
  'P307+P311': `IF exposed: call a POISON CENTER or doctor/physician.`,
  'P308+P311': `IF exposed or concerned: Call a POISON CENTER/doctor`,
  'P308+P313': `IF exposed or concerned: Get medical advice/attention.`,
  'P309+P311': `IF exposed or if you feel unwell: call a POISON CENTER or doctor/physician.`,
  'P332+P313': `IF SKIN irritation occurs: Get medical advice/attention.`,
  'P333+P313': `IF SKIN irritation or rash occurs: Get medical advice/attention.`,
  'P335+P334': `Brush off loose particles from skin. Immerse in cool water/wrap in wet bandages.`,
  'P337+P313': `IF eye irritation persists: Get medical advice/attention.`,
  'P342+P311': `IF experiencing respiratory symptoms: Call a POISON CENTER/doctor`,
  'P361+P364': `Take off immediately all contaminated clothing and wash it before reuse.`,
  'P362+P364': `Take off contaminated clothing and wash it before reuse.`,
  'P370+P376': `in case of fire: Stop leak if safe to do so.`,
  'P370+P378': `In case of fire: Use [see label] to extinguish.`,
  'P370+P380': `In case of fire: Evacuate area.`,
  'P370+P380+P375': `In case of fire: Evacuate area. Fight fire remotely due to the risk of explosion.`,
  'P371+P380+P375': `In case of major fire and large quantities: Evacuate area. Fight fire remotely due to the risk of explosion.`,
  P401: `Store in accordance with [see label]`,
  P402: `Store in a dry place.`,
  P403: `Store in a well-ventilated place.`,
  P404: `Store in a closed container.`,
  P405: `Store locked up.`,
  P406: `Store in corrosive resistant/[see label] container with a resistant inner liner.`,
  P407: `Maintain air gap between stacks or pallets.`,
  P410: `Protect from sunlight.`,
  P411: `Store at temperatures not exceeding [see label] °C/[see label]°F.`,
  P412: `Do not expose to temperatures exceeding 50 °C/ 122 °F.`,
  P413: `Store bulk masses greater than [see label] at temperatures not exceeding [see label]`,
  P420: `Store separately.`,
  P422: `Store contents under [see label]`,
  'P402+P404': `Store in a dry place. Store in a closed container.`,
  'P403+P233': `Store in a well-ventilated place. Keep container tightly closed.`,
  'P403+P235': `Store in a well-ventilated place. Keep cool.`,
  'P410+P403': `Protect from sunlight. Store in a well-ventilated place.`,
  'P410+P412': `Protect from sunlight. Do not expose to temperatures exceeding 50 °C/122°F.`,
  'P411+P235': `Store at temperatures not exceeding [see label]. Keep cool.`,
  P501: `Dispose of contents/container to [see label]`,
  P502: `Refer to manufacturer or supplier for information on recovery or recycling`,
};

export const hazardClassPictograms = {
  GHS01: {
    src: 'https://pubchem.ncbi.nlm.nih.gov/images/ghs/GHS01.svg',
    additional: ['Explosives'],
  },
  GHS02: {
    src: 'https://pubchem.ncbi.nlm.nih.gov/images/ghs/GHS02.svg',
    additional: ['Flammables'],
  },
  GHS03: {
    src: 'https://pubchem.ncbi.nlm.nih.gov/images/ghs/GHS03.svg',
    additional: ['Oxidizers'],
  },
  GHS04: {
    src: 'https://pubchem.ncbi.nlm.nih.gov/images/ghs/GHS04.svg',
    additional: ['Compressed Gases'],
  },

  GHS05: {
    src: 'https://pubchem.ncbi.nlm.nih.gov/images/ghs/GHS05.svg',
    additional: ['Corrosives'],
  },
  GHS06: {
    src: 'https://pubchem.ncbi.nlm.nih.gov/images/ghs/GHS06.svg',
    additional: ['Acute Toxicity'],
  },
  GHS07: {
    src: 'https://pubchem.ncbi.nlm.nih.gov/images/ghs/GHS07.svg',
    additional: ['Irritant'],
  },
  GHS08: {
    src: 'https://pubchem.ncbi.nlm.nih.gov/images/ghs/GHS08.svg',
    additional: ['Health Hazard'],
  },
  GHS09: {
    src: 'https://pubchem.ncbi.nlm.nih.gov/images/ghs/GHS09.svg',
    additional: ['Environment'],
  },
};

export const ghsHazardStatements = {
  H200: {
    code: 'H200',
    hazard_statements: 'Unstable Explosive',
    hazard_class: 'Explosives',
    category: 'Unstable Explosive',
    pictogram_src: ['/images/ghs/GHS01.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P372', 'P373', 'P380'],
      storage: ['P401'],
      disposal: ['P501'],
    },
  },
  H201: {
    code: 'H201',
    hazard_statements: 'Explosive; mass explosion hazard',
    hazard_class: 'Explosives',
    category: 'Div 1.1',
    pictogram_src: ['/images/ghs/GHS01.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P230', 'P240', 'P250', 'P280'],
      response: ['P370+P380', 'P372', 'P373'],
      storage: ['P401'],
      disposal: ['P501'],
    },
  },
  H202: {
    code: 'H202',
    hazard_statements: 'Explosive; severe projection hazard',
    hazard_class: 'Explosives',
    category: 'Div 1.2',
    pictogram_src: ['/images/ghs/GHS01.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P230', 'P240', 'P250', 'P280'],
      response: ['P370+P380', 'P372', 'P373'],
      storage: ['P401'],
      disposal: ['P501'],
    },
  },
  H203: {
    code: 'H203',
    hazard_statements: 'Explosive; fire, blast or projection hazard',
    hazard_class: 'Explosives',
    category: 'Div 1.3',
    pictogram_src: ['/images/ghs/GHS01.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P230', 'P240', 'P250', 'P280'],
      response: ['P370+P380', 'P372', 'P373'],
      storage: ['P401'],
      disposal: ['P501'],
    },
  },
  H204: {
    code: 'H204',
    hazard_statements: 'Fire or projection hazard',
    hazard_class: 'Explosives',
    category: 'Div 1.4',
    pictogram_src: ['/images/ghs/GHS01.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P210', 'P240', 'P250', 'P280'],
      response: ['P370+P380', 'P372', 'P373'],
      codes_storage: ['P401'],
      disposal: ['P501'],
    },
  },
  H205: {
    code: 'H205',
    hazard_statements: 'May mass explode in fire',
    hazard_class: 'Explosives',
    category: 'Div 1.5',
    pictogram_src: '',
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P230', 'P240', 'P250', 'P280'],
      response: ['P370+P380', 'P372', 'P373'],
      storage: ['P401'],
      disposal: ['P501'],
    },
  },
  H206: {
    code: 'H206',
    hazard_statements:
      'Fire, blast or projection hazard; increased risk of explosion if desensitizing agent is reduced',
    hazard_class: 'Desensitized explosives',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P212', 'P230', 'P233', 'P280'],
      response: ['P370+P380+P375'],
      storage: ['P401'],
      disposal: ['P501'],
    },
  },
  H207_category_2: {
    code: 'H207',
    hazard_statements:
      'Fire or projection hazard; increased risk of explosion if desensitizing agent is reduced',
    hazard_class: 'Desensitized explosives',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P212', 'P230', 'P233', 'P280'],
      response: ['P370+P380+P375'],
      storage: ['P401'],
      disposal: ['P501'],
    },
  },
  H207_category_3: {
    code: 'H207',
    hazard_statements:
      'Fire or projection hazard; increased risk of explosion if desensitizing agent is reduced',
    hazard_class: 'Desensitized explosives',
    category: 'Category 3',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P210', 'P212', 'P230', 'P233', 'P280'],
      response: ['P370+P380+P375'],
      storage: ['P401'],
      disposal: ['P501'],
    },
  },
  H208: {
    code: 'H208',
    hazard_statements:
      'Fire hazard; increased risk of explosion if desensitizing agent is reduced',
    hazard_class: 'Desensitized explosives',
    category: 'Category 4',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P210', 'P212', 'P230', 'P233', 'P280'],
      response: ['P371+P380+P375'],
      storage: ['P401'],
      disposal: ['P501'],
    },
  },
  H220: {
    code: 'H220',
    hazard_statements: 'Extremely flammable gas',
    hazard_class: 'Flammable gases',
    category:
      '1A: Flammable gas, Pyrophoric gas, Chemically unstable gas A,B',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210'],
      response: ['P377, P381'],
      storage: ['P403'],
      disposal: [],
    },
  },
  H221: {
    code: 'H221',
    hazard_statements: 'Flammable gas',
    hazard_class: 'Flammable gases',
    category: '1B',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210'],
      response: ['P377, P381'],
      storage: ['P403'],
      disposal: [],
    },
  },
  H221_category_2: {
    code: 'H221',
    hazard_statements: 'Flammable gas',
    hazard_class: 'Flammable gases',
    category: 'Category 2',
    pictogram_src: '',
    signal_word: 'Warning',
    codes: {
      prevention: ['P210'],
      response: ['P377, P381'],
      storage: ['P403'],
      disposal: [],
    },
  },
  H222_category_1: {
    code: 'H222',
    hazard_statements: 'Extremely flammable aerosol',
    hazard_class: 'Aerosols',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P211', 'P251'],
      response: [],
      storage: ['P410+P412'],
      disposal: [],
    },
  },
  H223: {
    code: 'H223',
    hazard_statements: 'Flammable aerosol',
    hazard_class: 'Aerosols',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P210', 'P211', 'P251'],
      response: [],
      storage: ['P410+P412'],
      disposal: [],
    },
  },
  H224: {
    code: 'H224',
    hazard_statements: 'Extremely flammable liquid and vapor',
    hazard_class: 'Flammable liquids',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: [
        'P210',
        'P233',
        'P240',
        'P241',
        'P242',
        'P243',
        'P280',
      ],
      response: ['P303+P361+P353', 'P370+P378'],
      storage: ['P403+P235'],
      disposal: ['P501'],
    },
  },
  H225: {
    code: 'H225',
    hazard_statements: 'Highly Flammable liquid and vapor',
    hazard_class: 'Flammable liquids',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: [
        'P210',
        'P233',
        'P240',
        'P241',
        'P242',
        'P243',
        'P280',
      ],
      response: ['P303+P361+P353', 'P370+P378'],
      storage: ['P403+P235'],
      disposal: ['P501'],
    },
  },
  H226: {
    code: 'H226',
    hazard_statements: 'Flammable liquid and vapor',
    hazard_class: 'Flammable liquids',
    category: 'Category 3',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: [
        'P210',
        'P233',
        'P240',
        'P241',
        'P242',
        'P243',
        'P280',
      ],
      response: ['P303+P361+P353', 'P370+P378'],
      storage: ['P403+P235'],
      disposal: ['P501'],
    },
  },
  H227: {
    code: 'H227',
    hazard_statements: 'Combustible liquid',
    hazard_class: 'Flammable liquids',
    category: 'Category 4',
    pictogram_src: '',
    signal_word: 'Warning',
    codes: {
      prevention: ['P210', 'P280'],
      response: ['P370+P378'],
      storage: ['P403+P235'],
      disposal: ['P501'],
    },
  },
  H228_category_1: {
    code: 'H228',
    hazard_statements: 'Flammable solid',
    hazard_class: 'Flammable solids',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P240', 'P241', 'P280'],
      response: ['P370+P378'],
      storage: [],
      disposal: [],
    },
  },
  H228_category_2: {
    code: 'H228',
    hazard_statements: 'Flammable solid',
    hazard_class: 'Flammable solids',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P210', 'P240', 'P241', 'P280'],
      response: ['P370+P378'],
      storage: [],
      disposal: [],
    },
  },
  H229_category_1: {
    code: 'H229',
    hazard_statements: 'Pressurized container: may burst if heated',
    hazard_class: 'Aerosols',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P211', 'P251'],
      response: [],
      storage: ['P410+P412'],
      disposal: [],
    },
  },
  H229_category_2: {
    code: 'H229',
    hazard_statements: 'Pressurized container: may burst if heated',
    hazard_class: 'Aerosols',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P210', 'P211', 'P251'],
      response: [],
      storage: ['P410+P412'],
      disposal: [],
    },
  },
  H229_category_3: {
    code: 'H229',
    hazard_statements: 'Pressurized container: may burst if heated',
    hazard_class: 'Aerosols',
    category: 'Category 3',
    pictogram_src: '',
    signal_word: 'Warning',
    codes: {
      prevention: ['P210', 'P211', 'P251'],
      response: [],
      storage: ['P410+P412'],
      disposal: [],
    },
  },
  H230: {
    code: 'H230',
    hazard_statements:
      'May react explosively even in the absence of air',
    hazard_class: 'Flammable gases ',
    category: '1A, Chemically unstable gas A',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: '',
    codes: {
      prevention: ['P202'],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  H231: {
    code: 'H231',
    hazard_statements:
      'May react explosively even in the absence of air at elevated pressure and/or temperature',
    hazard_class: 'Flammable gases ',
    category: '1A, Chemically unstable gas B',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: '',
    codes: {
      prevention: ['P202'],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  H232: {
    code: 'H232',
    hazard_statements: 'May ignite spontaneously if exposed to air',
    hazard_class: 'Flammable gases ',
    category: '1A, Pyrophoric gas',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P222'],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  H240: {
    code: 'H240',
    hazard_statements: 'Heating may cause an explosion',
    hazard_class:
      'Self-reactive substances and mixtures; Organic peroxides',
    category: 'Type A',
    pictogram_src: ['/images/ghs/GHS01.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P220', 'P234', 'P280'],
      response: ['P370+P378', 'P370+P380+P375'],
      storage: ['P403+P235', 'P411', 'P420'],
      disposal: ['P501'],
    },
  },
  H241: {
    code: 'H241',
    hazard_statements: 'Heating may cause a fire or explosion',
    hazard_class:
      'Self-reactive substances and mixtures; Organic peroxides',
    category: 'Type B',
    pictogram_src: ['/images/ghs/GHS01.svg', '/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P220', 'P234', 'P280'],
      response: ['P370+P378', 'P370+P380+P375'],
      storage: ['P403+P235', 'P411', 'P420'],
      disposal: ['P501'],
    },
  },
  H242_type_c_d: {
    code: 'H242',
    hazard_statements: 'Heating may cause a fire',
    hazard_class:
      'Self-reactive substances and mixtures; Organic peroxides',
    category: 'Type C, D',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P220', 'P234', 'P280'],
      response: ['P370+P378'],
      storage: ['P403+P235', 'P411', 'P420'],
      disposal: ['P501'],
    },
  },
  H242_type_e_f: {
    code: 'H242',
    hazard_statements: 'Heating may cause a fire',
    hazard_class:
      'Self-reactive substances and mixtures; Organic peroxides',
    category: 'Type E, F',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P210', 'P220', 'P234', 'P280'],
      response: ['P370+P378'],
      storage: ['P403+P235', 'P411', 'P420'],
      disposal: ['P501'],
    },
  },
  H250: {
    code: 'H250',
    hazard_statements: 'Catches fire spontaneously if exposed to air',
    hazard_class: 'Pyrophoric liquids; Pyrophoric solids',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P222', 'P280'],
      response: ['P302+P334', 'P370+P378'],
      storage: ['P422'],
      disposal: [],
    },
  },
  H251: {
    code: 'H251',
    hazard_statements: 'Self-heating; may catch fire',
    hazard_class: 'Self-heating substances and mixtures',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P235+P410', 'P280'],
      response: [],
      storage: ['P407', 'P413', 'P420'],
      disposal: [],
    },
  },
  H252: {
    code: 'H252',
    hazard_statements:
      'Self-heating in large quantities; may catch fire',
    hazard_class: 'Self-heating substances and mixtures',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P235+P410', 'P280'],
      response: [],
      storage: ['P407', 'P413', 'P420'],
      disposal: [],
    },
  },
  H260: {
    code: 'H260',
    hazard_statements:
      'In contact with water releases flammable gases which may ignite spontaneously',
    hazard_class:
      'Substances and mixtures which in contact with water, emit flammable gases',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P223', 'P231+P232', 'P280'],
      response: ['P335+P334', 'P370+P378'],
      storage: ['P402+P404'],
      disposal: ['P501'],
    },
  },
  H261_category_2: {
    code: 'H261',
    hazard_statements: 'In contact with water releases flammable gas',
    hazard_class:
      'Substances and mixtures which in contact with water, emit flammable gases',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P223', 'P231+P232', 'P280'],
      response: ['P335+P334', 'P370+P378'],
      storage: ['P402+P404'],
      disposal: ['P501'],
    },
  },
  H261_category_3: {
    code: 'H261',
    hazard_statements: 'In contact with water releases flammable gas',
    hazard_class:
      'Substances and mixtures which in contact with water, emit flammable gases',
    category: 'Category 3',
    pictogram_src: ['/images/ghs/GHS02.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P231+P232', 'P280'],
      response: ['P370+P378'],
      storage: ['P402+P404'],
      disposal: ['P501'],
    },
  },
  H270: {
    code: 'H270',
    hazard_statements: 'May cause or intensify fire; oxidizer',
    hazard_class: 'Oxidizing gases',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS03.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P220', 'P244'],
      response: ['P370+P376'],
      storage: ['P403'],
      disposal: [],
    },
  },
  H271: {
    code: 'H271',
    hazard_statements: 'May cause fire or explosion; strong Oxidizer',
    hazard_class: 'Oxidizing liquids; Oxidizing solids',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS03.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P220', 'P221', 'P280', 'P283'],
      response: ['P306+P360', 'P371+P380+P375', 'P370+P378'],
      storage: [],
      disposal: ['P501'],
    },
  },
  H272_category_2: {
    code: 'H272',
    hazard_statements: 'May intensify fire; oxidizer',
    hazard_class: 'Oxidizing liquids; Oxidizing solids',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS03.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P220', 'P221', 'P280'],
      response: ['P370+P378'],
      storage: [],
      disposal: ['P501'],
    },
  },
  H272_category_3: {
    code: 'H272',
    hazard_statements: 'May intensify fire; oxidizer',
    hazard_class: 'Oxidizing liquids; Oxidizing solids',
    category: 'Category 3',
    pictogram_src: ['/images/ghs/GHS03.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P210', 'P220', 'P221', 'P280'],
      response: ['P370+P378'],
      storage: [],
      disposal: ['P501'],
    },
  },
  H280: {
    code: 'H280',
    hazard_statements:
      'Contains gas under pressure; may explode if heated',
    hazard_class: 'Gases under pressure',
    category: 'Compressed gas, Liquefied gas, Dissolved gas',
    pictogram_src: ['/images/ghs/GHS04.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: [],
      response: [],
      storage: ['P410+P403'],
      disposal: [],
    },
  },
  H281: {
    code: 'H281',
    hazard_statements:
      'Contains refrigerated gas; may cause cryogenic burns or injury',
    hazard_class: 'Gases under pressure',
    category: 'Refrigerated liquefied gas',
    pictogram_src: ['/images/ghs/GHS04.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P282'],
      response: ['P336', 'P315'],
      storage: ['P403'],
      disposal: [],
    },
  },
  H282: {
    code: 'H282',
    hazard_statements:
      'Extremely flammable chemical under pressure: may explode if heated',
    hazard_class: 'Chemicals under pressure',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS02.svg', '/images/ghs/GHS04.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P210', 'P211'],
      response: ['P370+P378', 'P376', 'P381'],
      storage: ['P410+P403'],
      disposal: [],
    },
  },
  H283: {
    code: 'H283',
    hazard_statements:
      'Flammable chemical under pressure: may explode if heated',
    hazard_class: 'Chemicals under pressure',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS02.svg', '/images/ghs/GHS04.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P210', 'P211'],
      response: ['P370+P378', 'P376', 'P381'],
      storage: ['P410+P403'],
      disposal: [],
    },
  },
  H284: {
    code: 'H284',
    hazard_statements:
      'Chemical under pressure: may explode if heated',
    hazard_class: 'Chemicals under pressure',
    category: 'Category 3',
    pictogram_src: ['/images/ghs/GHS04.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P210'],
      response: ['P376'],
      storage: ['P410+P403'],
      disposal: [],
    },
  },
  H290: {
    code: 'H290',
    hazard_statements: 'May be corrosive to metals',
    hazard_class: 'Corrosive to Metals',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS05.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P234'],
      response: ['P390'],
      storage: ['P404'],
      disposal: [],
    },
  },
  H300: {
    code: 'H300',
    hazard_statements: 'Fatal if swallowed',
    hazard_class: 'Acute toxicity, oral',
    category: 'Category 1, 2',
    pictogram_src: ['/images/ghs/GHS06.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P264', 'P270'],
      response: ['P301+P310', 'P321', 'P330'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H301: {
    code: 'H301',
    hazard_statements: 'Toxic if swallowed',
    hazard_class: 'Acute toxicity, oral',
    category: 'Category 3',
    pictogram_src: ['/images/ghs/GHS06.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P264', 'P270'],
      response: ['P301+P310', 'P321', 'P330'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H302: {
    code: 'H302',
    hazard_statements: 'Harmful if swallowed',
    hazard_class: 'Acute toxicity, oral',
    category: 'Category 4',
    pictogram_src: ['/images/ghs/GHS07.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P264', 'P270'],
      response: ['P301+P312', 'P330'],
      storage: [],
      disposal: ['P501'],
    },
  },
  H303: {
    code: 'H303',
    hazard_statements: 'May be harmful if swallowed',
    hazard_class: 'Acute toxicity, oral',
    category: 'Category 5',
    pictogram_src: '',
    signal_word: 'Warning',
    codes: {
      prevention: [],
      response: ['P312'],
      storage: [],
      disposal: [],
    },
  },
  H304: {
    code: 'H304',
    hazard_statements: 'May be fatal if swallowed and enters airways',
    hazard_class: 'Aspiration hazard',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: [],
      response: ['P301+P310', 'P331'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H305: {
    code: 'H305',
    hazard_statements: 'May be fatal if swallowed and enters airways',
    hazard_class: 'Aspiration hazard',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: [],
      response: ['P301+P310', 'P331'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H310: {
    code: 'H310',
    hazard_statements: 'Fatal in contact with skin',
    hazard_class: 'Acute toxicity, dermal',
    category: 'Category 1, 2',
    pictogram_src: ['/images/ghs/GHS06.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P262', 'P264', 'P270', 'P280'],
      response: ['P302+P350', 'P310', 'P322', 'P361', 'P363'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H311: {
    code: 'H311',
    hazard_statements: 'Toxic in contact with skin',
    hazard_class: 'Acute toxicity, dermal',
    category: 'Category 3',
    pictogram_src: ['/images/ghs/GHS06.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P280'],
      response: ['P302+P352', 'P312', 'P322', 'P361', 'P363'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H312: {
    code: 'H312',
    hazard_statements: 'Harmful in contact with skin',
    hazard_class: 'Acute toxicity, dermal',
    category: 'Category 4',
    pictogram_src: ['/images/ghs/GHS07.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P280'],
      response: ['P302+P352', 'P312', 'P322', 'P363'],
      storage: [],
      disposal: ['P501'],
    },
  },
  H313: {
    code: 'H313',
    hazard_statements: 'May be harmful in contact with skin',
    hazard_class: 'Acute toxicity, dermal',
    category: 'Category 5',
    pictogram_src: '',
    signal_word: '',
    codes: {
      prevention: [],
      response: ['P312'],
      storage: [],
      disposal: [],
    },
  },
  H314: {
    code: 'H314',
    hazard_statements: 'Causes severe skin burns and eye damage',
    hazard_class: 'Skin corrosion/irritation',
    category: 'Category 1A, 1B, 1C',
    pictogram_src: ['/images/ghs/GHS05.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P260', 'P264', 'P280'],
      response: [
        'P301+P330+P331',
        'P303+P361+P353',
        'P363',
        'P304+P340',
        'P310',
        'P321',
        'P305+P351+P338',
      ],
      codes_storage: ['P405'],
      codes_disposal: ['P501'],
    },
  },
  H315: {
    code: 'H315',
    hazard_statements: 'Causes skin irritation',
    hazard_class: 'Skin corrosion/irritation',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS07.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P264', 'P280'],
      response: ['P302+P352', 'P321', 'P332+P313', 'P362'],
      storage: [],
      disposal: [],
    },
  },
  H316: {
    code: 'H316',
    hazard_statements: 'Causes mild skin irritation',
    hazard_class: 'Skin corrosion/irritation',
    category: 'Category 3',
    pictogram_src: '',
    signal_word: 'Warning',
    codes: {
      prevention: [],
      response: ['P332+P313'],
      storage: [],
      disposal: [],
    },
  },
  H317: {
    code: 'H317',
    hazard_statements: 'May cause an allergic skin reaction',
    hazard_class: 'Sensitization, Skin',
    category: 'Category 1, 1A, 1B',
    pictogram_src: ['/images/ghs/GHS07.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P261', 'P272', 'P280'],
      response: ['P302+P352', 'P333+P313', 'P321', 'P363'],
      storage: [],
      disposal: ['P501'],
    },
  },
  H318: {
    code: 'H318',
    hazard_statements: 'Causes serious eye damage',
    hazard_class: 'Serious eye damage/eye irritation',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS05.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P280'],
      response: ['P305+P351+P338', 'P310'],
      storage: [],
      disposal: [],
    },
  },
  H319: {
    code: 'H319',
    hazard_statements: 'Causes serious eye irritation',
    hazard_class: 'Serious eye damage/eye irritation',
    category: 'Category 2A',
    pictogram_src: ['/images/ghs/GHS07.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P264', 'P280'],
      response: ['P305+P351+P338', 'P337+P313'],
      storage: [],
      disposal: [],
    },
  },
  H320: {
    code: 'H320',
    hazard_statements: 'Causes eye irritation',
    hazard_class: 'Serious eye damage/eye irritation',
    category: 'Category 2B',
    pictogram_src: '',
    signal_word: 'Warning',
    codes: {
      prevention: ['P264'],
      response: ['P305+P351+P338', 'P337+P313'],
      storage: [],
      disposal: [],
    },
  },
  H330: {
    code: 'H330',
    hazard_statements: 'Fatal if inhaled',
    hazard_class: 'Acute toxicity, inhalation',
    category: 'Category 1, 2',
    pictogram_src: ['/images/ghs/GHS06.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P260', 'P271', 'P284'],
      response: ['P304+P340', 'P310', 'P320'],
      storage: ['P403+P233', 'P405'],
      disposal: ['P501'],
    },
  },
  H331: {
    code: 'H331',
    hazard_statements: 'Toxic if inhaled',
    hazard_class: 'Acute toxicity, inhalation',
    category: 'Category 3',
    pictogram_src: ['/images/ghs/GHS06.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P261', 'P271'],
      response: ['P304+P340', 'P311', 'P321'],
      storage: ['P403+P233', 'P405'],
      disposal: ['P501'],
    },
  },
  H332: {
    code: 'H332',
    hazard_statements: 'Harmful if inhaled',
    hazard_class: 'Acute toxicity, inhalation',
    category: 'Category 4',
    pictogram_src: ['/images/ghs/GHS07.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P261', 'P271'],
      response: ['P304+P340', 'P312', 'P304+P312'],
      storage: [],
      disposal: [],
    },
  },
  H333: {
    code: 'H333',
    hazard_statements: 'May be harmful if inhaled',
    hazard_class: 'Acute toxicity, inhalation',
    category: 'Category 5',
    pictogram_src: '',
    signal_word: 'Warning',
    codes: {
      prevention: ['P261', 'P271'],
      response: ['P304+P340', 'P312', 'P304+P312'],
      storage: [],
      disposal: [],
    },
  },
  H334: {
    code: 'H334',
    hazard_statements:
      'May cause allergy or asthma symptoms or breathing difficulties if inhaled',
    hazard_class: 'Sensitization, respiratory',
    category: 'Category 1, 1A, 1B',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P261', 'P285'],
      response: ['P304+P341', 'P342+P311'],
      storage: [],
      disposal: ['P501'],
    },
  },
  H335: {
    code: 'H335',
    hazard_statements: 'May cause respiratory irritation',
    hazard_class:
      'Specific target organ toxicity, single exposure; Respiratory tract irritation',
    category: 'Category 3',
    pictogram_src: ['/images/ghs/GHS07.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P261', 'P271'],
      response: ['P304+P340', 'P312'],
      storage: ['P403+P233', 'P405'],
      disposal: ['P501'],
    },
  },
  H336: {
    code: 'H336',
    hazard_statements: 'May cause drowsiness or dizziness',
    hazard_class:
      'Specific target organ toxicity, single exposure; Narcotic effects',
    category: 'Category 3',
    pictogram_src: ['/images/ghs/GHS07.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P261', 'P271'],
      response: ['P304+P340', 'P312'],
      storage: ['P403+P233', 'P405'],
      disposal: ['P501'],
    },
  },
  H340: {
    code: 'H340',
    hazard_statements: 'May cause genetic defects',
    hazard_class: 'Germ cell mutagenicity',
    category: 'Category 1A, 1B',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P308+P313'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H341: {
    code: 'H341',
    hazard_statements: 'Suspected of causing genetic defects',
    hazard_class: 'Germ cell mutagenicity',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P308+P313'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H350: {
    code: 'H350',
    hazard_statements: 'May cause cancer',
    hazard_class: 'Carcinogenicity',
    category: 'Category 1A, 1B',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P308+P313'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H350i: {
    code: 'H350i',
    hazard_statements: 'May cause cancer by inhalation',
    hazard_class: 'Carcinogenicity',
    category: 'Category 1A, 1B',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P308+P313'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H351: {
    code: 'H351',
    hazard_statements: 'Suspected of causing cancer',
    hazard_class: 'Carcinogenicity',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P308+P313'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H360: {
    code: 'H360',
    hazard_statements: 'May damage fertility or the unborn child',
    hazard_class: 'Reproductive toxicity',
    category: 'Category 1A, 1B',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P308+P313'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H360F: {
    code: 'H360F',
    hazard_statements: 'May damage fertility',
    hazard_class: 'Reproductive toxicity',
    category: 'Category 1A, 1B',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P308+P313'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H360D: {
    code: 'H360D',
    hazard_statements: 'May damage the unborn child',
    hazard_class: 'Reproductive toxicity',
    category: 'Category 1A, 1B',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P308+P313'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H360FD: {
    code: 'H360FD',
    hazard_statements:
      'May damage fertility; May damage the unborn child',
    hazard_class: 'Reproductive toxicity',
    category: 'Category 1A, 1B',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P308+P313'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H360Fd: {
    code: 'H360Fd',
    hazard_statements:
      'May damage fertility; Suspected of damaging the unborn child',
    hazard_class: 'Reproductive toxicity',
    category: 'Category 1A, 1B',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P308+P313'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H360Df: {
    code: 'H360Df',
    hazard_statements:
      'May damage the unborn child; Suspected of damaging fertility',
    hazard_class: 'Reproductive toxicity',
    category: 'Category 1A, 1B',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P308+P313'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H361: {
    code: 'H361',
    hazard_statements:
      'Suspected of damaging fertility or the unborn child',
    hazard_class: 'Reproductive toxicity',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P308+P313'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H361f: {
    code: 'H361f',
    hazard_statements: 'Suspected of damaging fertility',
    hazard_class: 'Reproductive toxicity',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P308+P313'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H361d: {
    code: 'H361d',
    hazard_statements: 'Suspected of damaging the unborn child',
    hazard_class: 'Reproductive toxicity',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P308+P313'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H361fd: {
    code: 'H361fd',
    hazard_statements:
      'Suspected of damaging fertility; Suspected of damaging the unborn child',
    hazard_class: 'Reproductive toxicity',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P201', 'P202', 'P281'],
      response: ['P308+P313'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H362: {
    code: 'H362',
    hazard_statements: 'May cause harm to breast-fed children',
    hazard_class:
      'Reproductive toxicity, effects on or via lactation',
    category: 'Additional category',
    pictogram_src: '',
    signal_word: '',
    codes: {
      prevention: ['P201', 'P260', 'P263', 'P264', 'P270'],
      response: ['P308+P313'],
      storage: [],
      disposal: [],
    },
  },
  H370: {
    code: 'H370',
    hazard_statements: 'Causes damage to organs',
    hazard_class: 'Specific target organ toxicity, single exposure',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P260', 'P264', 'P270'],
      response: ['P307+P311', 'P321'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H371: {
    code: 'H371',
    hazard_statements: 'May cause damage to organs',
    hazard_class: 'Specific target organ toxicity, single exposure',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P260', 'P264', 'P270'],
      response: ['P309+P311'],
      storage: ['P405'],
      disposal: ['P501'],
    },
  },
  H372: {
    code: 'H372',
    hazard_statements:
      'Causes damage to organs through prolonged or repeated exposure',
    hazard_class: 'Specific target organ toxicity, repeated exposure',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: ['P260', 'P264', 'P270'],
      response: ['P314'],
      storage: [],
      disposal: ['P501'],
    },
  },
  H373: {
    code: 'H373',
    hazard_statements:
      'Causes damage to organs through prolonged or repeated exposure',
    hazard_class: 'Specific target organ toxicity, repeated exposure',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS08.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P260'],
      response: ['P314'],
      storage: [],
      disposal: ['P501'],
    },
  },
  H400: {
    code: 'H400',
    hazard_statements: 'Very toxic to aquatic life',
    hazard_class:
      'Hazardous to the aquatic environment, acute hazard',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS09.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P273'],
      response: ['P391'],
      storage: [],
      disposal: ['P501'],
    },
  },
  H401: {
    code: 'H401',
    hazard_statements: 'Toxic to aquatic life',
    hazard_class:
      'Hazardous to the aquatic environment, acute hazard',
    category: 'Category 2',
    pictogram_src: '',
    signal_word: '',
    codes: {
      prevention: ['P273'],
      response: [],
      storage: [],
      disposal: ['P501'],
    },
  },
  H402: {
    code: 'H402',
    hazard_statements: 'Harmful to aquatic life',
    hazard_class:
      'Hazardous to the aquatic environment, acute hazard',
    category: 'Category 3',
    pictogram_src: '',
    signal_word: '',
    codes: {
      prevention: ['P273'],
      response: [],
      storage: [],
      disposal: ['P501'],
    },
  },
  H410: {
    code: 'H410',
    hazard_statements:
      'Very toxic to aquatic life with long lasting effects',
    hazard_class:
      'Hazardous to the aquatic environment, long-term hazard',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS09.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: ['P273'],
      response: ['P391'],
      storage: [],
      disposal: ['P501'],
    },
  },
  H411: {
    code: 'H411',
    hazard_statements:
      'Toxic to aquatic life with long lasting effects',
    hazard_class:
      'Hazardous to the aquatic environment, long-term hazard',
    category: 'Category 2',
    pictogram_src: ['/images/ghs/GHS09.svg'],
    signal_word: '',
    codes: {
      prevention: ['P273'],
      response: ['P391'],
      storage: [],
      disposal: ['P501'],
    },
  },
  H412: {
    code: 'H412',
    hazard_statements:
      'Harmful to aquatic life with long lasting effects',
    hazard_class:
      'Hazardous to the aquatic environment, long-term hazard',
    category: 'Category 3',
    pictogram_src: '',
    signal_word: '',
    codes: {
      prevention: ['P273'],
      response: [],
      storage: [],
      disposal: ['P501'],
    },
  },
  H413: {
    code: 'H413',
    hazard_statements:
      'May cause long lasting harmful effects to aquatic life',
    hazard_class:
      'Hazardous to the aquatic environment, long-term hazard',
    category: 'Category 4',
    pictogram_src: '',
    signal_word: '',
    codes: {
      prevention: ['P273'],
      response: [],
      storage: [],
      disposal: ['P501'],
    },
  },
  H420: {
    code: 'H420',
    hazard_statements:
      'Harms public health and the environment by destroying ozone in the upper atmosphere',
    hazard_class: 'Hazardous to the ozone layer',
    category: 'Category 1',
    pictogram_src: ['/images/ghs/GHS07.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: ['P502'],
    },
  },
  'H300+H330': {
    code: 'H300+H330',
    hazard_statements: 'Fatal if swallowed or if inhaled',
    hazard_class: 'Acute toxicity, oral; acute toxicity, inhalation',
    category: 'Category 1, 2',
    pictogram_src: ['/images/ghs/GHS06.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  'H310+H330': {
    code: 'H310+H330',
    hazard_statements: 'Fatal in contact with skin or if inhaled',
    hazard_class:
      'Acute toxicity, dermal; acute toxicity, inhalation',
    category: 'Category 1, 2',
    pictogram_src: ['/images/ghs/GHS06.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  'H300+H310+H330': {
    code: 'H300+H310+H330',
    hazard_statements:
      'Fatal if swallowed, in contact with skin or if inhaled',
    hazard_class:
      'Acute toxicity, oral; acute toxicity, dermal; acute toxicity, inhalation',
    category: 'Category 1, 2',
    pictogram_src: ['/images/ghs/GHS06.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  'H301+H311': {
    code: 'H301+H311',
    hazard_statements: 'Toxic if swallowed or in contact with skin',
    hazard_class: 'Acute toxicity, oral; acute toxicity, dermal',
    category: 'Category 3',
    pictogram_src: ['/images/ghs/GHS06.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  'H301+H331': {
    code: 'H301+H331',
    hazard_statements: 'Toxic if swallowed or if inhaled',
    hazard_class: 'Acute toxicity, oral; acute toxicity, inhalation',
    category: 'Category 3',
    pictogram_src: ['/images/ghs/GHS06.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  'H311+H331': {
    code: 'H311+H331',
    hazard_statements: 'Toxic in contact with skin or if inhaled.',
    hazard_class:
      'Acute toxicity, dermal; acute toxicity, inhalation',
    category: 'Category 3',
    pictogram_src: ['/images/ghs/GHS06.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  'H301+H311+H331': {
    code: 'H301+H311+H331',
    hazard_statements:
      'Toxic if swallowed, in contact with skin or if inhaled',
    hazard_class:
      'Acute toxicity, oral; acute toxicity, dermal; acute toxicity, inhalation',
    category: 'Category 3',
    pictogram_src: ['/images/ghs/GHS06.svg'],
    signal_word: 'Danger',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  'H302+H312': {
    code: 'H302+H312',
    hazard_statements: 'Harmful if swallowed or in contact with skin',
    hazard_class: 'Acute toxicity, oral; acute toxicity, dermal',
    category: 'Category 4',
    pictogram_src: ['/images/ghs/GHS07.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  'H302+H332': {
    code: 'H302+H332',
    hazard_statements: 'Harmful if swallowed or if inhaled',
    hazard_class: 'Acute toxicity, oral; acute toxicity, inhalation',
    category: 'Category 4',
    pictogram_src: ['/images/ghs/GHS07.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  'H312+H332': {
    code: 'H312+H332',
    hazard_statements: 'Harmful in contact with skin or if inhaled',
    hazard_class:
      'Acute toxicity, dermal; acute toxicity, inhalation',
    category: 'Category 4',
    pictogram_src: ['/images/ghs/GHS07.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  'H302+H312+H332': {
    code: 'H302+H312+H332',
    hazard_statements:
      'Harmful if swallowed, in contact with skin or if inhaled',
    hazard_class:
      'Acute toxicity, oral; acute toxicity, dermal; acute toxicity, inhalation',
    category: 'Category 4',
    pictogram_src: ['/images/ghs/GHS07.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  'H303+H313': {
    code: 'H303+H313',
    hazard_statements:
      'May be harmful if swallowed or in contact with skin',
    hazard_class: 'Acute toxicity, oral; acute toxicity, dermal',
    category: 'Category 5',
    pictogram_src: '',
    signal_word: 'Warning',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  'H303+H333': {
    code: 'H303+H333',
    hazard_statements: 'May be harmful if swallowed or if inhaled',
    hazard_class: 'Acute toxicity, oral; acute toxicity, inhalation',
    category: 'Category 5',
    pictogram_src: '',
    signal_word: 'Warning',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  'H313+H333': {
    code: 'H313+H333',
    hazard_statements:
      'May be harmful in contact with skin or if inhaled',
    hazard_class:
      'Acute toxicity, dermal; acute toxicity, inhalation',
    category: 'Category 5',
    pictogram_src: '',
    signal_word: 'Warning',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  'H303+H313+H333': {
    code: 'H303+H313+H333',
    hazard_statements:
      'May be harmful if swallowed, in contact with skin or if inhaled',
    hazard_class:
      'Acute toxicity, oral; acute toxicity, dermal; acute toxicity, inhalation',
    category: 'Category 5',
    pictogram_src: '',
    signal_word: 'Warning',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
  'H315+H320': {
    code: 'H315+H320',
    hazard_statements: 'Cause skin and eye irritation',
    hazard_class:
      'Skin corrosion/irritation and serious eye damage/eye irritation',
    category: 'Category 2, 2B',
    pictogram_src: ['/images/ghs/GHS07.svg'],
    signal_word: 'Warning',
    codes: {
      prevention: [],
      response: [],
      storage: [],
      disposal: [],
    },
  },
};
