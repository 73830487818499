/**
 * Labstep
 *
 * @module language
 * @desc Holds all language strings. Strings (with translations) should be generated by running a script
 * and not dynamically
 */

import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Integration } from 'labstep-web/models/integration.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { Resource } from 'labstep-web/models/resource.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { User } from 'labstep-web/models/user.model';
import { getPossibleStrings } from './entities/utils';
import { tag } from './entities/tag';

export const STRINGS = {
  experiment_workflow: {
    ...getPossibleStrings(ExperimentWorkflow.entityName),
    info: {
      completed: 'Completed',
      started: 'Started',
      unstarted: 'Unstarted',
      signed: 'Signed',
      unsigned: 'Unsigned',
    },
  },
  protocol_collection: getPossibleStrings(
    ProtocolCollection.entityName,
  ),
  purchase_order: {
    ...getPossibleStrings('order'),
  },
  order_request: {
    ...getPossibleStrings(OrderRequest.entityName),
    empty_state: `Track the order requests for the resources you need to restock. Include all relevant order information, such as PO numbers, receipts or invoices. Seamlessly add the items back to your inventory on arrival.`,
    info: {
      urgent: 'Urgent',
    },
  },
  integration: getPossibleStrings(Integration.entityName),
  user: getPossibleStrings(User.entityName),
  tag,
  resource: {
    ...getPossibleStrings(Resource.entityName),
    info: {
      available: 'Items available',
      unavailable: 'No items available',
    },
  },
  resource_item: {
    ...getPossibleStrings(ResourceItem.entityName),
    info: {
      available: 'Available',
      unavailable: 'Unavailable',
    },
  },
  resource_template: {
    ...getPossibleStrings(Resource.entityName, true),
  },
  entity_import: getPossibleStrings('data import'),
  file: getPossibleStrings('file'),
  device: getPossibleStrings('device'),
  group: {
    actions: {
      share: `Invite`,
    },
  },
  entity: {
    actions: {
      tag: 'Add Tags',
      assign: 'Assign to',
    },
  },
  filter: {
    header: {
      tag: tag.plural.capitalized,
      created_by: 'Created by',
      assigned_to: 'Assigned to',
      requested_by: 'Requested by',
      date_range: 'Date Created',
      status: 'Status',
      status_type: 'Status Type',
      extension: 'File Type',
      type: 'Type',
      signed_type: 'Signatures',
    },
  },
  navigation: {
    protocol_library: getPossibleStrings('protocol library'),
    inventory: getPossibleStrings('inventory'),
    message_board: getPossibleStrings('message board'),
    group_setting: getPossibleStrings('workspace setting'),
    general: getPossibleStrings('general'),
    resource_location: getPossibleStrings('location'),
    overview: getPossibleStrings('overview'),
    ordering: getPossibleStrings('order management'),
    device: getPossibleStrings('instruments & devices'),
  },
  actions: {
    show_more: 'show more',
  },
  export_client: {
    message:
      'Workspaces can be exported using our Desktop Client. Click below to download for your platform.',
    urlMac:
      'https://github.com/Labstep/desktop-client-releases/releases/download/v0.1.15/Labstep.Desktop.Client-0.1.15.pkg ',
    urlWindows:
      'https://github.com/Labstep/desktop-client-releases/releases/download/v0.1.15/Labstep.Desktop.Client.Setup.0.1.15.exe',
  },
};
