/**
 * Labstep
 *
 * @desc Component to do global search
 */

import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import ActionComponent from 'labstep-web/core/Action/Component';
import MediaQuery from 'labstep-web/core/MediaQuery';
import { Size } from 'labstep-web/core/MediaQuery/types';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import { withRouter } from 'react-router';
import GlobalSearchContent from './Content';
import { DATA_TEST_ID } from './contants';
import { ILayoutGlobalSearchProps } from './types';

export const LayoutGlobalSearch: React.FC<
  ILayoutGlobalSearchProps
> = ({ location, authenticatedUserEntity }) =>
  authenticatedUserEntity.hasGroupNotDeleted ? (
    <MediaQuery size={Size.tablet_desktop}>
      <ModalDefault
        key={location.key}
        header="Global Search"
        size="large"
        viewComponent={({ toggleModal }): React.ReactElement => (
          <ActionComponent
            type="icon"
            elementProps={{
              color: 'blue',
              popup: { content: 'Global Search' },
            }}
            onClick={toggleModal}
            dataTestId={DATA_TEST_ID.action_component}
            icon="search"
          />
        )}
        content={<GlobalSearchContent />}
      />
    </MediaQuery>
  ) : null;

export default withAuthenticatedUserEntity(
  withRouter(LayoutGlobalSearch),
);
