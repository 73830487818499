/**
 * Labstep
 *
 * @module services/websocket
 * @desc Wrapper around Socket IO
 */

import { Action } from 'labstep-web/models/action.model';
import { EMPTY, Observable } from 'rxjs';
import io, { Socket } from 'socket.io-client';
import { configService } from './config.service';

export class WebsocketService {
  private socket: Socket | null;

  public constructor() {
    this.socket = null;
  }

  public init(token: string): Observable<Action> {
    if (
      !configService.websocketUrl ||
      configService.websocketUrl.length === 0
    ) {
      return EMPTY;
    }

    if (token === null) {
      this.destroy();
      return EMPTY;
    }

    if (this.socket) {
      this.destroy();
    }

    return new Observable((observer) => {
      this.socket = io(configService.websocketUrl, {
        transports: ['websocket'],
        query: {
          auth_token: token,
        },
        reconnection: false,
      });

      this.socket.on('connect', () => {
        observer.next({
          type: 'WEBSOCKET_CONNECT',
          payload: {
            client_id: this.socket ? this.socket.id : null,
          },
        });
      });

      this.socket.on('disconnect', () => {
        observer.next({
          type: 'WEBSOCKET_DISCONNECT',
        });
      });

      this.socket.on('error', (error) => {
        observer.next({
          type: 'WEBSOCKET_ERROR',
          payload: error,
        });
      });

      this.socket.on('message', (data) => {
        try {
          const message = JSON.parse(data);
          if (message) {
            return observer.next({
              type: 'WEBSOCKET_MESSAGE',
              payload: message,
            });
          }
          return undefined;
        } catch (e) {
          return observer.next({
            type: 'WEBSOCKET_INVALID_MESSAGE',
          });
        }
      });

      observer.next({
        type: 'WEBSOCKET_CHECK_CONNECTED',
      });
    });
  }

  public get connected(): boolean {
    if (this.socket) {
      return this.socket.connected;
    }
    return false;
  }

  public destroy(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  public joinRoom(room: string): void {
    if (this.socket) {
      this.socket.emit('joinRoom', {
        room,
      });
    }
  }
}

export const websocketService = new WebsocketService();
