/**
 * Labstep
 *
 * @module containers/User/ForgotPassword
 * @desc Forgot password container
 */

import { forgotPassword } from 'labstep-web/state/actions/user';
import { selectApiStatus } from 'labstep-web/state/selectors/ui';
import { connect } from 'react-redux';

const ForgotPasswordContainer = ({ children, ...rest }: any) =>
  children(rest);

const mapStateToProps = (state: any) => ({
  status: selectApiStatus(state, 'forgot_password'),
});

const mapDispatchToProps = (dispatch: (...args: any[]) => void) => ({
  forgotPassword: (body: any, options: any) => {
    dispatch(forgotPassword(body, { ...options }));
  },
});

export const UserForgotPasswordContainer = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordContainer);
