/**
 * Labstep
 *
 * @module state/selectors/toast
 * @desc Selectors for toasts
 */

/**
 * Selects toast items
 *
 * @function
 * @param  {object} state
 * @return {object} - Toast state
 */
export const selectToast = (state: any) => state.ui.toast;
