/**
 * Labstep
 *
 * @module screens/Comment/Show
 * @desc Comment show
 */

import CommentThreadDetail from 'labstep-web/components/Comment/Thread/Detail';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Comment } from 'labstep-web/models/comment.model';
import React from 'react';
import { ICommentShowProps } from './types';

const CommentShow: React.FC<ICommentShowProps> = ({ id }) => (
  <ReadOnMountHOC
    type="entities"
    loading={{ loader: 'placeholder', cached: true }}
    entityName={Comment.entityName}
    params={{
      get_single: 1,
      id,
    }}
    children={({ entity: comment }) => (
      <CommentThreadDetail comment={comment} />
    )}
  />
);

export default CommentShow;
