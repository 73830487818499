/**
 * Labstep
 *
 * @module containers/Ping
 * @desc container to ping the API
 */

import { ping } from 'labstep-web/state/actions/ping';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

export type PingContainerChildrenProps = ReturnType<
  typeof mapDispatchToProps
>;

const Container = ({
  children,
  ...rest
}: {
  children: (
    props: PingContainerChildrenProps,
  ) => React.ReactElement | null;
} & PingContainerChildrenProps): React.ReactElement | null =>
  children(rest);

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ping(...args: Parameters<typeof ping>) {
    dispatch(ping(...args));
  },
});

export const PingContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
