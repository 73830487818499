/**
 * Labstep
 *
 * @module state/actions/user
 * @desc Redux actions for User
 */

import * as sfApi from 'labstep-web/services/sf-api.service';
import {
  CHANGE_PASSWORD,
  CLEAR_LOGIN_ERROR,
  CONFIRM_TOKEN,
  CONSENT_GDPR,
  FORGOT_PASSWORD,
  INTERNAL_LOGIN,
  INTERNAL_LOGIN_CHECK,
  RESEND_CONFIRMATION_EMAIL,
  RESET_PASSWORD,
} from 'labstep-web/state/constants/user';
import { selectAuthenticatedUsername } from 'labstep-web/state/selectors/authenticated-user';
import { IOptions } from 'labstep-web/typings';

export type InternalLoginActionType = (
  userData: any,
  options?: IOptions,
) => any;

/**
 * Login check to see if user has signed internally or with sso.
 *
 * @function
 */
export const internalLoginCheckAction: InternalLoginActionType = ({
  userData,
  options = {},
}) =>
  sfApi.post({
    type: INTERNAL_LOGIN_CHECK,
    route: {
      custom: 'app_publicapi_user_logincheck',
    },
    body: userData,
    ...options,
  });

/**
 * Login with username/password
 *
 * @function
 */
export const internalLoginAction: InternalLoginActionType = ({
  userData,
  options = {},
}) =>
  sfApi.post({
    type: INTERNAL_LOGIN,
    route: {
      custom: 'app_publicapi_user_login',
    },
    body: userData,
    meta: { authProvider: 'internal' },
    ...options,
  });

export const requestGetSAMLLoginUrlActionType = 'GET_SAML_LOGIN_URL';

/**
 * Get saml login url
 */
export const getSAMLLoginUrlAction = (
  organization: string,
  options: any = {},
) =>
  sfApi.get({
    type: requestGetSAMLLoginUrlActionType,
    route: {
      custom: 'app_publicapi_saml_geturl',
    },
    params: { organization },
    ...options,
  });

export const requestSAMLLoginActionType = 'SAML_LOGIN';

/**
 * Login via SAML SSO
 */
export const samlLoginAction = (
  organization: string,
  body: any,
  options: any = {},
) =>
  sfApi.post({
    type: requestSAMLLoginActionType,
    body,
    route: {
      custom: 'app_publicapi_saml_token',
    },
    meta: {
      customReducer: true,
      action_type: requestSAMLLoginActionType,
    },
    params: { organization },
    ...options,
  });

/**
 * Login via social media
 *
 * @function
 */
export const socialMediaLogin = ({
  onSuccess,
  onFail,
  sdkResponse,
}: any): any => ({
  type: 'SOCIAL_MEDIA_LOGIN',
  meta: {
    onSuccess,
    onFail,
    sdkResponse,
  },
});

/**
 * Login via google
 *
 * @function
 */
export const googleLoginAction = ({
  onSuccess,
  onFail,
  sdkResponse,
}: any): any => socialMediaLogin({ onSuccess, onFail, sdkResponse });

/**
 * Logout
 *
 * @function
 */
export const logoutAction = (options?: IOptions) => ({
  type: 'START_LOGOUT',
  meta: {
    ...options,
  },
});

/**
 * Internal login
 *
 * @function
 */
export const internalRegisterAction = ({
  userData,
  onSuccess,
  onFail,
}: any): any => ({
  type: 'INTERNAL_REGISTER',
  meta: {
    userData,
    onSuccess,
    onFail,
  },
});

export type ClearLoginError = () => { type: string };

/**
 * Clear login error
 *
 * @function
 */
export const clearLoginError: ClearLoginError = () => ({
  type: CLEAR_LOGIN_ERROR,
});

/**
 * Consent to marketing activities
 *
 * @function
 */
export const toggleGDPRConsent = (): any => ({
  type: CONSENT_GDPR,
});

/**
 * Confirm registration with a token
 *
 * @function
 */
export const confirmToken = (token: string, options?: any): any =>
  sfApi.post({
    type: CONFIRM_TOKEN,
    route: {
      custom: 'app_publicapi_user_confirm',
    },
    body: { token },
    meta: { token },
    ...options,
  });

/**
 * Resend confirmation email
 *
 * @function
 */
export const resendConfirmationEmail =
  (): any =>
  (dispatch: (...args: any[]) => void, getState: () => any): any =>
    dispatch(
      sfApi.post({
        type: RESEND_CONFIRMATION_EMAIL,
        route: {
          custom: 'app_publicapi_user_confirmresend',
        },
        body: { email: selectAuthenticatedUsername(getState()) },
      }),
    );

/**
 * Forgot password
 *
 * @function
 */
export const forgotPassword = (body: string, options?: any): any =>
  sfApi.post({
    type: FORGOT_PASSWORD,
    route: {
      custom: 'app_publicapi_user_resetpasswordsendemail',
    },
    meta: {
      action_type: 'forgot_password',
      customReducer: 1,
    },
    body,
    ...options,
  });

/**
 * Reset password
 *
 * @function
 */
export const resetPassword = (body: string, options?: any): any =>
  sfApi.post({
    type: RESET_PASSWORD,
    route: {
      custom: 'app_publicapi_user_resetpassword',
    },
    meta: {
      action_type: 'reset_password',
      customReducer: 1,
    },
    body,
    ...options,
  });

/**
 * Change password
 *
 * @function
 */
export const changePassword = (body: string, options?: any): any =>
  sfApi.post({
    type: CHANGE_PASSWORD,
    route: {
      custom: 'app_api_user_changepassword',
    },
    meta: {
      action_type: 'change_password',
      customReducer: 1,
    },
    body,
    ...options,
  });

/**
 * Read authenticated user
 *
 * @function
 */
export const readAuthenticatedUser = (options?: any): any => ({
  type: 'READ_AUTHENTICATED_USER',
  meta: {
    options,
  },
});

/**
 * Logout by bypassing server
 *
 * @function
 */
export const logoutHardAction = () => ({
  type: 'LOGOUT_SUCCESS',
});
