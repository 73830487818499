import { ILoadingContentProps } from 'labstep-web/components/Layout/LoadingContent/types';
import {
  IReadOnMountContainerProps,
  IReadOnMountEntitiesProps,
} from 'labstep-web/hoc/ReadOnMount/types';

export const getReadProps = (
  entityName: string,
  id: number | string,
  at?: string,
  loader = 'inline' as ILoadingContentProps['loader'],
) => {
  const loading: IReadOnMountContainerProps['loading'] = {
    loader,
    cached: true,
  };
  if (entityName === 'file' && !at) {
    return { type: 'entity', entityName, id, loading } as const;
  }
  const props: Omit<IReadOnMountEntitiesProps, 'children'> & {
    loading: IReadOnMountContainerProps['loading'];
  } = {
    type: 'entities',
    entityName,
    params: { id, get_single: 1, is_deleted: 'both' },
    loading,
  };
  if (at) {
    props.params = { ...props.params, at };
  }
  return props;
};
