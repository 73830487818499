/**
 * Labstep
 */

import NodeCode from 'labstep-web/prosemirror/nodes/code';
import NodeExperimentTable from 'labstep-web/prosemirror/nodes/experiment-table';
import NodeExperimentTimer from 'labstep-web/prosemirror/nodes/experiment-timer';
import NodeExperimentValue from 'labstep-web/prosemirror/nodes/experiment-value';
import NodeFile from 'labstep-web/prosemirror/nodes/file';
import NodeJupyterNotebook from 'labstep-web/prosemirror/nodes/jupyter-notebook';
import NodeMetadata from 'labstep-web/prosemirror/nodes/metadata';
import NodeMolecule from 'labstep-web/prosemirror/nodes/molecule';
import NodeProtocolTimer from 'labstep-web/prosemirror/nodes/protocol-timer';
import NodeProtocolTable from 'labstep-web/prosemirror/nodes/protocol-table';
import NodeProtocolValue from 'labstep-web/prosemirror/nodes/protocol-value';
import NodeReference from 'labstep-web/prosemirror/nodes/reference';

const nodes = [
  NodeCode,
  NodeExperimentTable,
  NodeExperimentTimer,
  NodeExperimentValue,
  NodeFile,
  NodeJupyterNotebook,
  NodeMetadata,
  NodeMolecule,
  NodeProtocolTimer,
  NodeProtocolTable,
  NodeProtocolValue,
  NodeReference,
];

/** Clicking on these elements should not trigger editing */
export const ignoreElementsOnClick = [
  ...nodes.map((node) => `[data-${node.key}-id]`),
  '.protocol-step-thread',
  'a',
];
