/**
 * Labstep
 */

import { Action } from 'labstep-web/models/action.model';

/**
 * Adds status to the state for request
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @param  {string} key - Key
 * @param  {object} args - Extra arguments
 * @return {object}
 */
export const requestStatus = (
  state: any,
  action: Action,
  key: string,
  args?: any,
) => {
  const status = state[key] ? state[key].status : {};
  return {
    ...state,
    [key]: {
      ...state[key],
      status: {
        ...status,
        isFetching: true,
        error: null,
        ...args,
      },
    },
  };
};

/**
 * Adds status to the state for receive
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @param  {string} key - Key
 * @param  {object} args - Extra arguments
 * @return {object}
 */
export const receiveStatus = (
  state: any,
  action: Action,
  key: string,
  args?: any,
) => {
  let items = null;
  const status = {
    isFetching: false,
    error: null,
    ...args,
  };
  if (action.payload && action.payload.result) {
    items = action.payload.result;
    if (action.type.includes('READ')) {
      status.cached = Date.now();
    }
  }
  return {
    ...state,
    [key]: {
      status,
      items,
    },
  };
};

/**
 * Adds status to the state for fail
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @param  {string} key - Key
 * @return {object}
 */
export const failStatus = (
  state: any,
  action: Action,
  key: string,
) => ({
  ...state,
  [key]: {
    status: {
      isFetching: false,
      error: action.error,
    },
  },
});
