/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/Conditions
 * @desc Renders an Conditions node
 */

import ProtocolConditionCollapsibleCard from 'labstep-web/components/ProtocolCondition/CollapsibleCard';
import { IProtocolConditionCollapsibleCardProps } from 'labstep-web/components/ProtocolCondition/CollapsibleCard/types';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import Read from '../Read';
import { IConditionsNodeProps } from './types';

export const agGridProseMirrorClass = 'ag-grid-prosemirror';

export class ConditionsNode extends React.Component<IConditionsNodeProps> {
  // eslint-disable-next-line class-methods-use-this
  public shouldComponentUpdate(): boolean {
    return false;
  }

  public render(): JSX.Element {
    const { entity, timestamp } = this.props;
    return (
      <Read
        entityName={entity.entityName}
        id={entity.id}
        timestamp={timestamp}
      >
        {({
          entity: protocol,
        }: {
          entity: Experiment | Protocol;
        }): JSX.Element => {
          const props: IProtocolConditionCollapsibleCardProps = {
            protocol,
            initialExpandedState: true,
            gridProps: {
              className: agGridProseMirrorClass,
              defaultColDef: { resizable: false },
            },
          };
          if (timestamp) {
            props.gridProps = {
              ...props.gridProps,
              editable: false,
              params: { at: timestamp },
            };
          }
          return (
            <div contentEditable={false}>
              <ProtocolConditionCollapsibleCard {...props} />
            </div>
          );
        }}
      </Read>
    );
  }
}

export default ConditionsNode;
