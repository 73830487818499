/**
 * Labstep
 *
 * @module components/ShareLink/Accept
 * @desc Component to accept sharelink and re-direct
 */

import ShareLinkNotGroupOrganization from 'labstep-web/components/ShareLink/Accept/Entity';
import ShareLinkRedirect from 'labstep-web/components/ShareLink/Redirect';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { ShareLinkAcceptContainer } from 'labstep-web/containers/ShareLinkAccept';
import { useToggle } from 'labstep-web/hooks/toggle';
import React from 'react';
import ShareLinkGroupOrOrganization from './Entity/GroupOrOrganization';
import {
  IShareLinkAcceptProps,
  IShareLinkAcceptReadContainerProps,
} from './types';

export const ShareLinkAccept: React.FC<IShareLinkAcceptProps> = ({
  shareLink,
  accept,
  status,
  authenticatedUserEntity,
  setActiveGroupId,
}) => {
  const [isToggled, toggle] = useToggle(false);

  if (isToggled) {
    return <ShareLinkRedirect shareLink={shareLink} />;
  }

  if (
    shareLink.organization &&
    authenticatedUserEntity.userOrganization &&
    shareLink.organization.id ===
      authenticatedUserEntity.userOrganization.organization.id
  ) {
    return <ShareLinkRedirect shareLink={shareLink} />;
  }

  if (shareLink.organization || shareLink.group) {
    return (
      <ShareLinkGroupOrOrganization
        shareLink={shareLink}
        onClick={() =>
          accept(
            {},
            { noOutput: true, onSuccess: toggle, toast: true },
          )
        }
        status={status}
      />
    );
  }

  return (
    <ShareLinkNotGroupOrganization
      shareLink={shareLink}
      status={status}
      onChange={(option) => {
        accept(
          {
            group_id: option.id,
          },
          {
            noOutput: true,
            onSuccess: () => {
              // This is needed because if active group is null
              // And then it's set here the whole component will re-render
              // And the redirection won't happen
              setTimeout(() => setActiveGroupId(option.id));
              toggle();
            },
            toast: true,
          },
        );
      }}
    />
  );
};

export const ShareLinkAcceptReadContainer: React.FC<
  IShareLinkAcceptReadContainerProps
> = ({ shareLink, ...rest }) => (
  <ShareLinkAcceptContainer token={shareLink.token}>
    {(props) => (
      <ShareLinkAccept {...props} shareLink={shareLink} {...rest} />
    )}
  </ShareLinkAcceptContainer>
);

export default withAuthenticatedUserEntity(
  withActiveGroup(ShareLinkAcceptReadContainer),
);
