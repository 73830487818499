/**
 * Labstep
 *
 * @module state/reducers/thumbnail
 * @desc Reducers for thumbnail
 */

import { REFRESH_THUMBNAIL_STATUSES_ACTION_TYPE } from 'labstep-web/state/actions/thumbnail';
import omit from 'lodash/omit';
import { combineReducers } from 'redux';
import { flushWithTimestamp } from './utils';

export interface IThumbnailReducerKeyStateProps {
  fileGuid: string;
  params: { [key: string]: any };
  url: string | null;
  status:
    | 'loading'
    | 'loaded'
    | 'failed'
    | 'error'
    | 'waiting_for_fresh_jwt'
    | null;
  retry: number;
}

export interface IThumbnailReducerStateProps {
  [key: string]: IThumbnailReducerKeyStateProps;
}

export type IActionType = {
  type: string;
  meta: {
    origin: any;
    key: string;
    fileGuid: string;
    params: any;
  };
  payload: {
    url: string;
  };
  error?: {
    data: {
      message?: string;
    };
  };
};

export type ThumbnailReducerType = (
  state: IThumbnailReducerStateProps,
  action: IActionType,
) => IThumbnailReducerStateProps;

/**
 * List of thumbnails
 */
export const thumbnails: ThumbnailReducerType = (
  state = {},
  action,
) => {
  if (action.type === 'SERVERLESS_REQUEST_THUMBNAIL') {
    return {
      ...state,
      [action.meta.key]: {
        ...state[action.meta.key],
        fileGuid: action.meta.fileGuid,
        params: action.meta.params,
        status: 'loading',
        url: null,
      },
    };
  }
  if (action.type === 'SERVERLESS_SUCCESS_THUMBNAIL') {
    return {
      ...state,
      [action.meta.key]: {
        ...state[action.meta.key],
        status: 'loaded',
        url: action.payload.url,
      },
    };
  }
  if (action.type === 'SERVERLESS_FAIL_THUMBNAIL') {
    // "File is a link" results from a bug on the web
    // This has been fixed, but the cache may still have
    // these files stored, so we need to flush these out
    if (action.error?.data?.message === 'File is a link') {
      return omit(state, action.meta.key);
    }
    return {
      ...state,
      [action.meta.key]: {
        ...state[action.meta.key],
        status: 'failed',
        url: null,
      },
    };
  }
  if (action.type === 'THUMBNAIL_ERROR') {
    return {
      ...state,
      [action.meta.key]: {
        ...state[action.meta.key],
        fileGuid: action.meta.fileGuid,
        params: action.meta.params,
        status: 'error',
        retry: state[action.meta.key].retry
          ? state[action.meta.key].retry + 1
          : 1,
      },
    };
  }
  if (
    action.type === 'JWT_EXPIRED' &&
    action.meta &&
    action.meta.origin &&
    action.meta.origin.type === 'SERVERLESS_REQUEST_THUMBNAIL'
  ) {
    return {
      ...state,
      [action.meta.origin.meta.key]: {
        ...state[action.meta.origin.meta.key],
        status: 'waiting_for_fresh_jwt',
        url: null,
      },
    };
  }
  if (action.type === 'THUMBNAIL_FLUSH_WITH_TIMESTAMP') {
    return flushWithTimestamp(state);
  }

  // Fixes: https://linear.app/labstep/issue/LAB-1434/images-uploaded-to-experiment-not-showing-for-some-users
  if (action.type === REFRESH_THUMBNAIL_STATUSES_ACTION_TYPE) {
    return Object.keys(state).reduce(
      (result, key) => ({
        ...result,
        [key]: {
          ...state[key],
          status: state[key].status !== 'loaded' ? null : 'loaded',
        },
      }),
      {},
    );
  }

  return state;
};

const reducers = combineReducers({
  thumbnails,
});

export default reducers;
