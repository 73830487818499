/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Create
 * @desc Create Action for experiment workflow
 */

import React from 'react';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { IActionComponentProps } from 'labstep-web/core/Action/Component/types';
import { IEntityActionCreateContainerProps } from 'labstep-web/components/Entity/Action/Create/types';
import { addActionComponentPropsToDefault } from 'labstep-web/core/Action/Component/utils';
import { IExperimentWorkflowActionCreateProps } from './types';

export const ExperimentWorkflowActionCreate: React.FC<
  IExperimentWorkflowActionCreateProps
> = ({
  protocol,
  body,
  actionComponentProps: extraActionComponentProps,
}) => {
  const redirectRoute: IEntityActionCreateContainerProps['redirectRoute'] =
    ({ response }) =>
      ({
        to: protocol ? 'experiment_show' : 'experiment_workflow_show',
        params: {
          id: protocol
            ? response.entities.experiment_workflow[response.result]
                .experiments[0]
            : response.result,
        },
      } as const);

  let actionComponentProps = {
    type: 'button',
    elementProps: {
      ...(protocol && { color: 'green' }),
      primary: !protocol,
    },
    ...(protocol && { text: 'Start' }),
  } as IActionComponentProps;

  actionComponentProps = addActionComponentPropsToDefault(
    actionComponentProps,
    extraActionComponentProps,
  );

  return (
    <EntityActionCreate
      entityName="experiment_workflow"
      redirectRoute={redirectRoute}
      actionComponentProps={actionComponentProps}
      body={protocol ? { protocol_id: protocol.id, ...body } : body}
    />
  );
};

export default ExperimentWorkflowActionCreate;
