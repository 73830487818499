/**
 * Labstep
 *
 * @module containers/Entity/Read/Entity/Any
 * @desc Container for reading one entity by ID
 */

import { readEntity } from 'labstep-web/state/actions/entity';
import { IOptions } from 'labstep-web/typings';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { IEntityReadEntityAnyContainerProps } from './types';

export type EntityReadEntityAnyContainerChildrenProps = ReturnType<
  typeof mapDispatchToProps
>;

const Container = ({
  children,
  ...rest
}: EntityReadEntityAnyContainerChildrenProps & {
  children: React.FC<
    Omit<EntityReadEntityAnyContainerChildrenProps, 'children'>
  >;
}) => children(rest);

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: {
    entityName: string;
  },
) => ({
  read(id: number | string, options?: IOptions) {
    dispatch(readEntity(ownProps.entityName, id, options));
  },
});

export const EntityReadEntityAnyContainer: React.FC<IEntityReadEntityAnyContainerProps> =
  connect(null, mapDispatchToProps)(Container);
