/**
 * Labstep
 *
 * @desc ShowEdit component for IUPACName
 */

import React from 'react';
import PubChemMetadataFormShowEditGeneric from 'labstep-web/components/PubChemMetadata/Form/ShowEdit/Generic';
import { fieldName } from 'labstep-web/components/PubChemMetadata/Form/fields';
import { IPubChemMetadataFormShowEditIUPACNameProps } from './types';

const PubChemMetadataFormShowEditIUPACName: React.FC<
  IPubChemMetadataFormShowEditIUPACNameProps
> = ({ parent, pubChemMetadata }) => (
  <PubChemMetadataFormShowEditGeneric
    pubChemMetadata={pubChemMetadata}
    parent={parent}
    field={fieldName}
  />
);

export default PubChemMetadataFormShowEditIUPACName;
