/**
 * Labstep
 *
 * @desc Action to set notification alert
 */

import React, { useState } from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import NotificationAlertDetails from 'labstep-web/components/NotificationAlert/Details';
import NotificationAlertActionCreate from 'labstep-web/components/NotificationAlert/Action/Create';
import Popup from 'labstep-web/core/Popup';
import { INotificationAlertActionSetProps } from './types';

export const NotificationAlertActionSet: React.FC<
  INotificationAlertActionSetProps
> = ({ metadata }) => {
  const [open, setOpen] = useState(!metadata.hasActiveAlert);

  if (metadata.hasActiveAlert) {
    return (
      <Popup
        on="click"
        inverted={false}
        flowing
        open={open}
        onClose={() => setOpen(false)}
        trigger={
          <ActionComponent
            type="icon"
            onClick={() => setOpen(true)}
            elementProps={{
              name: 'bell',
              color: metadata.hasAlertFired ? 'red' : 'yellow',
              popup: {
                content: metadata.hasAlertFired ? (
                  <>
                    <div>{`Alert! ${
                      metadata.notification_alert.message || ''
                    }`}</div>
                    <div>Click to edit.</div>
                  </>
                ) : (
                  <>
                    <div>{metadata.alertTriggerTime}</div>
                    <div>Click to edit.</div>
                  </>
                ),
              },
            }}
          />
        }
        content={<NotificationAlertDetails metadata={metadata} />}
      />
    );
  }
  return <NotificationAlertActionCreate metadata={metadata} />;
};

export default NotificationAlertActionSet;
