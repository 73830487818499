/**
 * Labstep
 *
 * @module components/Group/Action/Create
 * @desc Action to create group
 */

import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import Button from 'labstep-web/core/Button';
import { Group } from 'labstep-web/models/group.model';
import { UserOrganizationType } from 'labstep-web/models/user-organization.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import styles from './styles.module.scss';
import { IGroupActionCreateProps } from './types';

export const GroupActionCreate: React.FC<IGroupActionCreateProps> = ({
  authenticatedUserEntity,
}) => {
  const { userOrganization } = authenticatedUserEntity;

  if (
    userOrganization &&
    [
      UserOrganizationType.guest,
      UserOrganizationType.viewer,
    ].includes(userOrganization.type)
  ) {
    return null;
  }

  if (
    userOrganization &&
    userOrganization.type === 'member' &&
    !userOrganization.organization.is_allow_member_create_group
  ) {
    return null;
  }

  const buttonText = `New ${getHumanReadableEntityName(
    Group.entityName,
    false,
    true,
  )}`;

  return (
    <Button
      size="small"
      route={{ to: 'group_create' }}
      primary
      className={styles.button}
    >
      {buttonText}
    </Button>
  );
};

export default withAuthenticatedUserEntity(GroupActionCreate);
