/**
 * Labstep
 */

import colDefResourceItemStatus from '..';

export const colDefStatusHidden = {
  ...colDefResourceItemStatus,
  hide: true,
};
