/**
 * Labstep
 *
 * @module components/Metadata/Item
 * @desc Experiment Value item
 */

import MetadataValue from 'labstep-web/components/Metadata/Value';
import { ICONS } from 'labstep-web/constants/icons';
import CardGeneric from 'labstep-web/core/Card/Generic';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { IMetadataCardPreviewProps } from './types';

export const MetadataCardPreview: React.FC<
  IMetadataCardPreviewProps
> = ({ metadata }) => (
  <CardGeneric
    headerImage={<Icon name={ICONS.metadata.default} />}
    headerHeader={
      <div>
        <span>{metadata.label}</span>
        <MetadataValue metadata={metadata} canEdit={false} />
      </div>
    }
  />
);

export default MetadataCardPreview;
