/**
 * Labstep
 *
 * @module components/Comment/Form/Create
 * @desc Reply bar for comments
 */

import ProfilePictureImage from 'labstep-web/components/Profile/Picture/Image';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityReadCursorContainer } from 'labstep-web/containers/Entity/Read/Cursor';
import { User } from 'labstep-web/models/user.model';
import React, { useEffect } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { Size } from '../Initials/types';
import style from './style';
import styles from './styles.module.scss';
import { IMentionsContainerProps, IMentionsProps } from './types';

export const Mentions: React.FC<IMentionsProps> = ({
  value,
  users,
  read,
  onChange,
  ...rest
}) => {
  useEffect(() => {
    if (users.length === 0) {
      read();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.container}>
      <MentionsInput
        allowSuggestionsAboveCursor
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        data-gramm_editor="false"
        {...rest}
        style={style}
      >
        <Mention
          renderSuggestion={(
            suggestion,
            search,
            highlightedDisplay,
          ) => (
            <div className={styles.suggestion}>
              <ProfilePictureImage
                size={Size.mini}
                linkable={false}
                entity={users.find((e) => e.id === suggestion.id)}
              />
              {highlightedDisplay}
            </div>
          )}
          trigger="@"
          markup="@[__display__](__id__)"
          displayTransform={(id, display) => {
            return `@${display}`;
          }}
          data={users.map((e) => ({
            id: e.id,
            display: e.name,
          }))}
          className={styles.mention}
        />
      </MentionsInput>
    </div>
  );
};

const MentionsContainer: React.FC<IMentionsContainerProps> = ({
  activeGroup,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setActiveGroupId,
  ...rest
}) => (
  <EntityReadCursorContainer
    entityName={User.entityName}
    params={{
      group_id: activeGroup?.id,

      count: 100,
    }}
  >
    {({ entities, read }) => (
      <Mentions read={read} users={entities} {...rest} />
    )}
  </EntityReadCursorContainer>
);

export default withActiveGroup(MentionsContainer);
