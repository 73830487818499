/**
 * Labstep
 *
 * @desc Action to create (or restore) notification alert
 */

import React from 'react';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { NotificationAlert } from 'labstep-web/models/notification-alert.model';
import { IActionComponentProps } from 'labstep-web/core/Action/Component/types';
import { INotificationAlertActionCreateProps } from './types';

export const DEFAULT_ACTION_COMPONENT_PROPS: IActionComponentProps = {
  type: 'icon',
  elementProps: {
    name: 'bell',
    popup: { content: 'Set Alert' },
  },
};

export const NotificationAlertActionCreate: React.FC<
  INotificationAlertActionCreateProps
> = ({ metadata, createOptions }) => {
  if (metadata.notification_alert) {
    return (
      <EntityActionUpdate
        entityName={metadata.notification_alert.entityName}
        id={metadata.notification_alert.guid}
        body={{ deleted_at: null }}
        actionComponentProps={DEFAULT_ACTION_COMPONENT_PROPS}
      />
    );
  }

  return (
    <EntityActionCreate
      parentName={metadata.entityName}
      parentId={metadata.guid}
      overwriteCanCreateProps={{
        parentName: metadata.entityName,
        parentId: metadata.id,
      }}
      entityName={NotificationAlert.entityName}
      actionComponentProps={DEFAULT_ACTION_COMPONENT_PROPS}
      options={createOptions}
    />
  );
};

export default NotificationAlertActionCreate;
