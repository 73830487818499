/**
 * Labstep
 *
 * @module state/slices/jupyter
 * @desc Selectors for Jupyter
 */

import { combineReducers } from '@reduxjs/toolkit';
import { jupyterAuthorizeSlice } from './authorize';
import { jupyterInstanceSlice } from './jupyterInstance';

export const jupyterReducers = combineReducers({
  authorize: jupyterAuthorizeSlice.reducer,
  jupyter_instance: jupyterInstanceSlice.reducer,
});
