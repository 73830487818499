/**
 * Labstep
 *
 * @module components/Group/Settings/Protocols
 */

import React from 'react';
import LayoutLinksState from 'labstep-web/components/Layout/Links/State';
import { TagType } from 'labstep-web/models/tag.model';
import TagIndex from 'labstep-web/screens/Tag/Index';
import { IGroupSettingsProtocolsProps } from './types';

const GroupSettingsProtocols: React.FC<
  IGroupSettingsProtocolsProps
> = ({ group }) => {
  return (
    <div id="group-settings-protocols" style={{ flex: 1 }}>
      <LayoutLinksState
        inverted
        tabs={[
          {
            key: 'Tags',
            render: () => (
              <TagIndex
                group={group}
                type={TagType.protocol_collection}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default GroupSettingsProtocols;
