/**
 * Labstep
 *
 * @module components/Table/Item
 * @desc Display Editable Table
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import TableCard from 'labstep-web/components/Table/Card';
import TableModal from 'labstep-web/components/Table/Modal';
import TableEditContainer from 'labstep-web/containers/Table/Edit';
import Icon from 'labstep-web/core/Icon';
import { ProtocolTable } from 'labstep-web/models/protocol-table.model';
import React, { useState } from 'react';
import { ITableItemContainerProps, ITableItemProps } from './types';

export const Spreadsheet = React.lazy(
  () => import('labstep-web/core/SpreadJS/spreadsheet'),
);

export const TableItem: React.FC<ITableItemProps> = ({
  table,
  onDelete,
  readOnly,
  allowLocking,
  ...rest
}) => {
  const canEdit = useHasAccess(
    table.entityName,
    table.idAttr,
    Action.edit,
    'data',
  );
  const [updateTable, setUpdateTable] = useState(false);
  const finalReadOnly = readOnly || !canEdit;

  const canDelete =
    !finalReadOnly &&
    onDelete &&
    table.entityName === ProtocolTable.entityName;
  return (
    <TableModal
      readOnly={finalReadOnly}
      allowLocking={allowLocking}
      table={table}
      viewComponent={({ toggleModal }) => (
        <TableCard
          name={table.name}
          data={table.data}
          readOnly={finalReadOnly}
          updateTable={updateTable}
          actions={
            canDelete && (
              <Icon name="trash" color="blue" onClick={onDelete} />
            )
          }
          toggleModal={() => {
            setUpdateTable(false);
            toggleModal();
          }}
          allowLocking={allowLocking}
          {...rest}
        />
      )}
      onDone={() => {
        setUpdateTable(true);
      }}
    />
  );
};

const TableItemContainer: React.FC<ITableItemContainerProps> = ({
  table,
  ...rest
}) => (
  <TableEditContainer table={table}>
    {(containerProps) => <TableItem {...containerProps} {...rest} />}
  </TableEditContainer>
);

export default TableItemContainer;
