/**
 * Labstep
 *
 * @module components/OrganizationSAML/Form/fields
 * @desc OrganizationSAML fields
 */

import { IFieldWithoutActionProps } from 'labstep-web/core/Form/Reusable/types';
import rules, {
  IOrganizationSAMLRules,
} from 'labstep-web/services/validation/rules';

export const getFormField = (
  fieldType: any,
  name: keyof IOrganizationSAMLRules,
  fieldLabel: string,
  placeholder?: string,
): IFieldWithoutActionProps => ({
  fieldType,
  name,
  fieldLabel,
  placeholder,
  validation: rules.organization_saml[name],
  fieldExplainer: placeholder,
});
