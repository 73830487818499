/**
 * Labstep
 *
 * @module components/ResourceLocation/Manager
 * @desc Manager of resource sub locations
 */

import React from 'react';
import ResourceLocationSearch from 'labstep-web/components/ResourceLocation/Search';
import ResourceLocationActionCreateSub from 'labstep-web/components/ResourceLocation/Action/CreateSub';
import { IResourceLocationManagerProps } from './types';

export const ResourceLocationManager: React.FC<
  IResourceLocationManagerProps
> = ({ resourceLocation }) => (
  <ResourceLocationSearch
    action={
      <ResourceLocationActionCreateSub
        resourceLocation={resourceLocation}
      />
    }
    outerResourceLocation={resourceLocation}
  />
);

export default ResourceLocationManager;
