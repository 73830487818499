/**
 * Labstep
 *
 * @module core/Pagination/Count
 * @desc
 */

import React from 'react';
import { IPaginationCountProps } from './types';

export const PaginationCount: React.FC<IPaginationCountProps> = ({
  from,
  to,
  total,
}) => <span>{`Showing ${from}-${to} of ${total}`}</span>;

export default PaginationCount;
