/**
 * Labstep
 *
 * @module core/Linkify
 * @desc Component to turn text to link. Wrapper around react-linkify.
 */

import React from 'react';
import ReactLinkify from 'react-linkify';

const Linkify: React.FC = ({ children }) => (
  <ReactLinkify
    componentDecorator={(decoratedHref, decoratedText, key) => (
      <a
        target="_blank"
        rel="noreferrer noopener"
        href={decoratedHref}
        key={key}
      >
        {decoratedText}
      </a>
    )}
  >
    {children}
  </ReactLinkify>
);

export default Linkify;
