/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Card/Overview
 * @desc Overview Card for protocol collection
 */

import EntityCardOverview from 'labstep-web/components/Entity/Card/Overview';
import ExperimentWorkflowActionCreateMenu from 'labstep-web/components/ExperimentWorkflow/Action/CreateMenu';
import ExperimentWorkflowActionMenu from 'labstep-web/components/ExperimentWorkflow/Action/Menu';
import { ExperimentWorkflowEmptyState } from 'labstep-web/components/ExperimentWorkflow/Card/EmptyState';
import { actions } from 'labstep-web/components/ExperimentWorkflow/ListOrTable/constants';
import { ICONS } from 'labstep-web/constants/icons';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import React from 'react';
import { IExperimentWorkflowCardOverviewProps } from './types';

export const ExperimentWorkflowCardOverview: React.FC<
  IExperimentWorkflowCardOverviewProps
> = (props) => (
  <EntityCardOverview
    {...props}
    emptyState={<ExperimentWorkflowEmptyState title={undefined} />}
    entityName={ExperimentWorkflow.entityName}
    icon={ICONS.experiment_workflow.primary}
    actionMenu={({ entity }) => (
      <ExperimentWorkflowActionMenu
        experimentWorkflow={entity}
        actions={actions}
      />
    )}
    action={<ExperimentWorkflowActionCreateMenu />}
    title="Latest Experiments"
  />
);

export default ExperimentWorkflowCardOverview;
