/**
 * Labstep
 *
 * @module components/OrderRequest/Action/RemovePurchaseOrder
 * @desc Action to set purchase_order_id for orderRequest to null
 */

import React from 'react';
import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { IOrderRequestActionRemovePurchaseOrderProps } from './types';

const OrderRequestActionRemovePurchaseOrder: React.FC<
  IOrderRequestActionRemovePurchaseOrderProps
> = ({ orderRequest }) => (
  <EntityActionUpdate
    id={orderRequest.id}
    entityName={orderRequest.entityName}
    body={{ purchase_order_id: null }}
    actionComponentProps={{
      type: 'option',
      text: 'Remove',
    }}
  />
);

export default OrderRequestActionRemovePurchaseOrder;
