/**
 * Labstep
 *
 * @module components/ResourceItem/Action/Edit
 * @desc Bulk edit form for resource item
 */

import {
  fieldResourceItemAmount,
  getFieldResourceItemUnit,
} from 'labstep-web/components/ResourceItem/Form/fields';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import FormReusable from 'labstep-web/core/Form/Reusable';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React from 'react';
import { IResourceItemFormEditBulkProps } from './types';

export const ResourceItemFormEditBulk: React.FC<
  IResourceItemFormEditBulkProps
> = ({ resourceItems, options }) => {
  const fieldResourceItemUnit = getFieldResourceItemUnit(false);
  const fields = [fieldResourceItemAmount, fieldResourceItemUnit];
  return (
    <EntityUpdateContainer
      entityName={ResourceItem.entityName}
      id={resourceItems.map((r) => r.id)}
    >
      {({ update, status }) => (
        <FormReusable
          status={status}
          autoFocus
          preSubmit={(values) => ({
            ...values,
            [fieldResourceItemUnit.name]:
              values[fieldResourceItemUnit.name]?.value,
          })}
          onSubmit={(values) => update(values, options)}
          fields={fields}
          submitButtonText="Set"
        />
      )}
    </EntityUpdateContainer>
  );
};

export default ResourceItemFormEditBulk;
