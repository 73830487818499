/**
 * Labstep
 *
 * @module state/epics/metadata
 */

import { Action } from 'labstep-web/models/action.model';
import { Metadata } from 'labstep-web/models/metadata';
import { readEntity } from 'labstep-web/state/actions/entity';
import { StateObservable } from 'redux-observable';
import { Observable, concat, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

export const getParentExperimentWorkflowId = (
  state: any,
  action: Action,
) => {
  const { identifier } = action.meta;

  const metadataThreadByIdKeys = state.entities.metadata_thread.byId;

  const parentMetadataThreadKey = Object.keys(
    metadataThreadByIdKeys,
  ).find(
    (key) =>
      metadataThreadByIdKeys[key].metadatas &&
      metadataThreadByIdKeys[key].metadatas.some(
        (metadata: Metadata) => metadata === identifier,
      ),
  );

  const experimentWorkflowByIdKeys =
    state.entities.experiment_workflow.byId;

  const parentExperimentWorkflowKey = Object.keys(
    experimentWorkflowByIdKeys,
  ).find(
    (key) =>
      Number(experimentWorkflowByIdKeys[key].metadata_thread) ===
      Number(parentMetadataThreadKey),
  );

  return parentExperimentWorkflowKey;
};

/**
 * Reloads experiment worfklow when metadata is deleted
 */
export const reloadParentEpic = (
  action$: Observable<Action>,
  state$: StateObservable<any>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => {
      if (action.type !== 'SUCCESS_DELETE_METADATA_RAW_OUTPUT') {
        return false;
      }
      return !!getParentExperimentWorkflowId(state$.value, action);
    }),
    map((action: Action) => {
      return readEntity(
        'experiment_workflow',
        Number(getParentExperimentWorkflowId(state$.value, action)),
      );
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_RELOAD_PARENT', payload: err }),
        source$,
      ),
    ),
  );
