import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import { DefaultSort } from 'labstep-web/components/Entity/Table/SortOptions/types';
import { Filter } from 'labstep-web/components/Filter/Menu/types';

export const bulkActions = [
  BulkAction.delete,
  BulkAction.resource_create_order_request,
  BulkAction.tag,
  BulkAction.transfer_ownership,
  BulkAction.share,
  BulkAction.update_template,
];

export const filters = [
  Filter.created_by,
  Filter.assigned_to,
  Filter.date_range,
  Filter.tag,
  Filter.deleted,
  Filter.metadata,
  Filter.available_resource_item_count,
  Filter.molecule,
];

export const sortOptions = {
  name: { label: 'Name', default_sort: DefaultSort.asc },
};
