/**
 * Labstep
 *
 * @module components/Layout/Container/Actions
 * @desc Container for actions
 */

import React from 'react';
import styles from './styles.module.scss';
import { ILayoutContainerActionsProps } from './types';

export const LayoutContainerActionButtons: React.FC<
  ILayoutContainerActionsProps
> = ({ children }) => (
  <div className={styles.containerActions}>{children}</div>
);

export default LayoutContainerActionButtons;
