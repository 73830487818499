/**
 * Labstep
 *
 * @module components/Folder/List
 * @desc List of folders
 */

import { FolderActionCreate } from 'labstep-web/components/Folder/Action/Create';
import FolderPaneList from 'labstep-web/components/Folder/Pane/List';
import { ICONS } from 'labstep-web/constants/icons';
import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import { Folder } from 'labstep-web/models/folder.model';
import React from 'react';
import { IFolderListPaneProps } from './types';

export const FolderListPane: React.FC<IFolderListPaneProps> = ({
  folderType,
}) => (
  <div id="folder-pane">
    <FolderPaneList
      folderType={folderType}
      action={<FolderActionCreate folderType={folderType} />}
      searchBarProps={{
        viewComponent: (
          <Header size="tiny">
            <Icon name={ICONS.folder.primary} />{' '}
            {Folder.getHumanReadableEntityName(true, true)}
          </Header>
        ),
      }}
    />
  </div>
);

export default FolderListPane;
