/**
 * Labstep
 *
 * @module components/Layout/Alert/OfflineContent
 * @desc Component for offline warning
 */

import { withAuthenticatedUser } from 'labstep-web/containers/AuthenticatedUser';
import Alert from 'labstep-web/core/Alert';
import React from 'react';

export const StagingWarning = () => (
  <Alert color="orange" centered noMargin>
    You seem to be on staging, changes made here won&apos;t be saved.
  </Alert>
);

export const allowedUser = (username) =>
  username.endsWith('@labstep.com');

export const StagingContentComponent = ({ authenticatedUser }) =>
  window.location.host.includes('staging') &&
  authenticatedUser &&
  authenticatedUser.username &&
  !allowedUser(authenticatedUser.username) && <StagingWarning />;

export default withAuthenticatedUser(StagingContentComponent);
