/**
 * Labstep
 *
 * @module core/Form/ShowEdit/Text
 * @desc A component to toggle between Show and Edit mode
 * for text fields. The intention is to toggle onBlur and do
 * an optimistic update. If the field is left empty no api
 * request will be send and the last saved value will be restored.
 */

import classnames from 'classnames';
import {
  useHasAccess,
  useHasAccessCreate,
} from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import UpdateOrCreateContainer from 'labstep-web/containers/UpdateOrCreate';
import { callIf } from 'labstep-web/services/react.service';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import { isEqual, omit, replace } from 'lodash';
import React from 'react';
import { ShowEditToggleComponent } from '..';
import { getDefaultValues } from '../../Edit';
import ReusableForm from '../../Reusable';
import { IFieldWithoutActionProps } from '../../Reusable/types';
import styles from './styles.module.scss';
import { IFormShowEditTextProps } from './types';

const FormShowEditText: React.FC<IFormShowEditTextProps> = ({
  field,
  value,
  entityName,
  id,
  entity,
  options,
  children,
  label,
  createProps,
  disabled,
  secret,
  initialEditingState,
  onToggle,
  hideValueIfCreateProps = true,
  ...rest
}) => {
  const name = field.fieldLabel || replace(field.name, /_/g, ' ');

  const hasValue =
    (value !== undefined ? value : entity[field.name]) !== null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fields: IFieldWithoutActionProps[] = [
    { ...omit(field, ['fieldLabel']) } as IFieldWithoutActionProps,
  ];

  const defaultValues = getDefaultValues(fields, entity);

  const finalId: string | number = id || entity.idAttr;

  const canEdit = useHasAccess(
    entityName,
    finalId,
    Action.edit,
    field.name,
  );

  const canCreate = useHasAccessCreate(
    entityName,
    createProps?.parentName,
    createProps?.parentId,
  );

  return (
    <UpdateOrCreateContainer
      createProps={createProps}
      entityName={entity.entityName}
      updateProps={{
        id: finalId,
      }}
    >
      {({ action, status }): React.ReactElement => (
        <ShowEditToggleComponent
          initialEditingState={initialEditingState}
          canEdit={!disabled && (createProps ? canCreate : canEdit)}
          children={children}
          name={name}
          label={label}
          explainer={field.fieldExplainer}
          hasValue={
            hideValueIfCreateProps
              ? hasValue && !createProps
              : hasValue
          }
          secret={secret}
          toggledComponent={({ toggle }): React.ReactElement => (
            <div
              className={classnames(styles.saveOnChange, {
                [styles.header]: field.header,
              })}
            >
              <ReusableForm
                fields={fields}
                defaultValues={createProps ? {} : defaultValues}
                submitButton={false}
                blurToggle={toggle}
                autoFocus
                status={createProps ? status : undefined}
                onSubmit={(values): void => {
                  if (
                    isEqual(values[field.name], entity[field.name]) &&
                    !createProps
                  ) {
                    toggle();
                    callIf(onToggle);
                    return;
                  }
                  action(
                    values,
                    enhanceOptions({
                      options,
                      onFail: createProps && toggle,
                    }),
                  );
                  if (!createProps) {
                    toggle();
                    callIf(onToggle);
                  }
                }}
              />
            </div>
          )}
          {...rest}
        />
      )}
    </UpdateOrCreateContainer>
  );
};

export default FormShowEditText;
