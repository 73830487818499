/**
 * Labstep
 *
 * @module containers/Ui/Sidebar
 * @desc Container for setting sidebar
 */

import { setSidebarActionCreator } from 'labstep-web/state/new/sidebar';
import React from 'react';
import { connect } from 'react-redux';
import {
  IWithSidebarInjectedProps,
  IWithSidebarMapDispatchToProps,
  IWithSidebarMapStateToProps,
} from './types';

const mapDispatchToProps: IWithSidebarMapDispatchToProps = {
  setSidebar: setSidebarActionCreator,
};

const mapStateToProps = (state: any): IWithSidebarMapStateToProps => {
  const { sidebar } = state;

  return { sidebar };
};

export const withSidebar = <P, S>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<P, Exclude<keyof P, keyof IWithSidebarInjectedProps>>
> => connect(mapStateToProps, mapDispatchToProps)(Component as any);
