/* eslint-disable no-param-reassign */
/**
 * Labstep
 *
 * @module services/sf-api
 * @desc Wrapper around HTTP Client to call the Symfony API
 */

import { AxiosResponse } from 'axios';
import { APP_VERSION } from 'labstep-web/constants/version';
import { Action } from 'labstep-web/models/action.model';
import { Device } from 'labstep-web/models/device.model';
import { EntityStateWorkflow } from 'labstep-web/models/entity-state-workflow.model';
import { EntityUserRole } from 'labstep-web/models/entity-user-role.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { File } from 'labstep-web/models/file.model';
import { Folder } from 'labstep-web/models/folder.model';
import { Group } from 'labstep-web/models/group.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { Resource } from 'labstep-web/models/resource.model';
import { Tag } from 'labstep-web/models/tag.model';
import { FILE_UPLOAD_PROGRESS } from 'labstep-web/state/constants/file';
import { Observable, Subject, from, of } from 'rxjs';
import { catchError, map, merge } from 'rxjs/operators';
import fosRouter from './fos-router';
import HttpClientService from './http-client.service';
import { getPluralInSchema } from './schema/helpers';

interface IApiOptions {
  route?: any;
  body?: any;
  meta?: any;
  onSuccess?: (...args: any[]) => void;
  onFail?: (...args: any[]) => void;
  type: string;
  params?: any;
  toast?: any;
  optimistic?: any;
}

/**
 * Get the common fields between different actions (CRUD)
 * @param  {string} apiMethod - GET/PUT/DELETE e.t.c.
 * @param  {string} entityName - Entity name e.g.: 'research_area'
 * @param  {bool} plural - is pluralized (Used for reading multiple entities)
 * @param  {bool} isPublic - Use public api route
 * @return {object} - returns object of common fields
 */
export const getRouteName = (
  apiMethod: string,
  entityName: string,
  plural = false,
  isPublic = false,
  batch = false,
  params = {} as any,
  filter = false,
): string => {
  const firstPartEntityName = entityName
    .toLowerCase()
    .replace(/_/g, '');
  const pluralized = plural
    ? getPluralInSchema(entityName)
    : entityName;
  const secondPartEntityName = pluralized
    .toLowerCase()
    .replace(/_/g, '');
  let prefix = '_api';
  if (isPublic) {
    prefix = '_publicapi';
  }

  const batchPostfix = batch ? 'batch' : '';
  const filterPostfix = filter ? 'filter' : '';

  return `app${prefix}_${firstPartEntityName}_${apiMethod.toLowerCase()}${batchPostfix}${filterPostfix}${secondPartEntityName}`;
};

/**
 * Gets route name for toggle
 * FIXME action variable name is misleading
 * @param  {string} action - Action e.g. 'set', 'add', 'remove'
 * @param  {string} entityName
 * @param  {string} parentName
 * @param  {bool} single - If using single toggle route
 * @return {string} - Route name
 */
export const getToggleRouteName = (
  action: string,
  entityName: string,
  parentName: string,
  single = false,
) => {
  const parentPart = parentName.replace(/_/g, '').toLowerCase();
  const entityNameFinal = single
    ? entityName
    : getPluralInSchema(entityName);
  const entityPart = entityNameFinal.replace(/_/g, '').toLowerCase();
  return `app_api_${parentPart}_${action}${entityPart}`;
};

/**
 * Send the HTTP Request with httpClient
 *
 * @function
 * @param  {string} method - method
 * @param  {object} params - params
 */
const call = (
  method: string,
  {
    route,
    body,
    meta,
    onSuccess,
    onFail,
    type,
    params,
    toast,
    optimistic,
  }: IApiOptions,
) => {
  let routeName = null;
  const { entityName } = route;

  if (route.toggle) {
    routeName = getToggleRouteName(
      route.action,
      entityName,
      route.parentName,
      route.single,
    );
  } else if (route.custom) {
    routeName = route.custom;
  } else {
    routeName = getRouteName(
      route.apiMethod,
      entityName,
      route.plural,
      route.isPublic,
      route.batch,
      params,
      route.filter,
    );
  }

  const url = fosRouter.generate(routeName, { ...params });
  const headers: any =
    method === 'GET'
      ? {}
      : {
          'Content-Type': 'application/json',
        };
  headers['labstep-web-app-version'] = APP_VERSION;

  const action: any = {
    type: `REQUEST_${type.toUpperCase()}`,
    meta: {
      method,
      url,
      headers,
      body,
      params,
      onSuccess,
      onFail,
      toast,
      optimistic,
      ...meta,
    },
  };

  return action;
};

/**
 * Send a GET HTTP Method
 * @function
 * @param  {object} props - props
 */
export const get = (props: any) => call('GET', props);

/**
 * Send a POST HTTP Method
 * @function
 * @param  {object} props - props
 */
export const post = (props: any) => call('POST', props);

/**
 * Send a PUT HTTP Method
 * @function
 * @param  {object} props - props
 */
export const put = (props: any) => call('PUT', props);

/**
 * Send a DELETE HTTP Method
 * @function
 * @param  {object} props - props
 */
export const remove = (props: any) => call('DELETE', props);

export const getRequestObs = (
  action: Action,
  clientUuid: string,
  activeGroupId?: Group['id'],
): Observable<Action> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let progressSubscriber!: Subject<any>;
  if (action.meta.upload) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    progressSubscriber = new Subject<any>();
  }

  // Inject creation uuid
  if (
    action.type.startsWith('REQUEST_CREATE') &&
    action.meta &&
    action.meta.uuid
  ) {
    action.meta.headers['labstep-creation-uuid'] = action.meta.uuid;
  }

  // Inject client uuid
  if (
    action.type.startsWith('REQUEST_') &&
    action.meta &&
    action.meta.body &&
    clientUuid
  ) {
    action.meta.headers['labstep-client-uuid'] = clientUuid;
  }

  // For POST batch endpoint
  if (
    Array.isArray(action.meta.body) &&
    action.type.includes('CREATE')
  ) {
    action.meta.body = {
      items: action.meta.body,
      group_id:
        action.type !== 'REQUEST_CREATE_PERMISSION' && activeGroupId
          ? activeGroupId
          : undefined,
    };
  }

  // Append group_id for primary entities
  if (
    (action.type.includes('CREATE') &&
      [
        ExperimentWorkflow.entityName,
        Resource.entityName,
        ProtocolCollection.entityName,
        OrderRequest.entityName,
        ResourceLocation.entityName,
        Tag.entityName,
        PurchaseOrder.entityName,
        File.entityName,
        Folder.entityName,
        Device.entityName,
        EntityUserRole.entityName,
        EntityStateWorkflow.entityName,
      ].indexOf(action.meta.entityName) > -1) ||
    action.type === 'REQUEST_CONVERT_URL'
  ) {
    if (
      action.type === 'REQUEST_CREATE_FILE' &&
      !action.meta.body.link_source
    ) {
      action.meta.body.append('group_id', activeGroupId);
    } else {
      action.meta.body.group_id = activeGroupId;
    }
  }

  const requestObservable = from(
    HttpClientService.send(
      action.meta.method,
      action.meta.url,
      action.meta.headers,
      action.meta.body,
      action.meta.upload
        ? (error): void => progressSubscriber.next(error)
        : undefined,
    ),
  ).pipe(
    map((payload: AxiosResponse['data']) => {
      if (
        action.type.includes('INTERNAL_LOGIN') &&
        payload.message === '2FA'
      ) {
        return {
          ...action,
          type: 'MFA_CODE_REQUIRED',
        };
      }
      return {
        ...action,
        type: `${action.type.replace(
          'REQUEST',
          'SUCCESS',
        )}_RAW_OUTPUT`,
        payload,
      };
    }),
    catchError((error) =>
      of({
        ...action,
        type: action.type.replace('REQUEST', 'FAIL'),
        error,
      }),
    ),
  );

  if (action.meta.upload) {
    return progressSubscriber.pipe(
      map((progress) => ({
        type: FILE_UPLOAD_PROGRESS,
        meta: {
          upload: action.meta.upload,
        },
        payload: {
          progress: (progress.loaded / progress.total) * 100,
        },
      })),
      merge(requestObservable),
    );
  }

  return requestObservable;
};
