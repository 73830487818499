/**
 * Labstep
 *
 * @module components/Comment/Action/Pin
 * @desc Pin/Unpin a comment
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { ICONS } from 'labstep-web/constants/icons';
import { generateNewDateString } from 'labstep-web/services/date.service';
import React from 'react';
import { ICommentActionPinProps } from './types';

const CommentActionPin: React.FC<ICommentActionPinProps> = ({
  comment,
}) => (
  <EntityActionUpdate
    entityName={comment.entityName}
    id={comment.id}
    body={{
      pinned_at: !comment.pinned_at ? generateNewDateString() : null,
    }}
    options={{ toast: true }}
    actionComponentProps={{
      type: 'option',
      text: comment.pinned_at ? 'Unpin' : 'Pin',
      icon: ICONS.comment.actions.pin,
    }}
  />
);

export default CommentActionPin;
