/**
 * Labstep
 *
 * @module models/entity-state
 * @desc Typescript export class for EntityState
 */

import { Type } from 'class-transformer';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { EntityUser } from './entity-user.model';
import { Entity } from './entity.model';
import { Group } from './group.model';
import { User } from './user.model';

export class EntityUserRole extends Entity {
  public static readonly entityName = 'entity_user_role';

  public static readonly entityClass = 'entity_user_role' as const;

  public get entityName(): string {
    return EntityUserRole.entityName;
  }

  public constructor(data: Partial<EntityUserRole> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: never;

  public name!: string;

  public description!: string;

  public owner_id!: number;

  public author_id!: number;

  @Type(() => Group)
  public owner!: Group;

  @Type(() => User)
  public author!: User;

  @Type(() => EntityUser)
  public entity_users!: EntityUser[];

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
