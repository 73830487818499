/**
 * Labstep
 *
 * @module components/ProtocolCollection/ListOrTable
 * @desc List or table of protocolCollections
 */

import React from 'react';
import ProtocolCollectionTable from 'labstep-web/components/ProtocolCollection/Table';
import ProtocolCollectionList from 'labstep-web/components/ProtocolCollection/List';
import MasterIndexCenterListOrTable from 'labstep-web/screens/Master/Index/Center/ListOrTable';
import { IProtocolCollectionListOrTableProps } from './types';
import { actions } from './constants';

export const ProtocolCollectionListOrTable: React.FC<
  IProtocolCollectionListOrTableProps
> = ({ protocolCollections, ...rest }) => (
  <MasterIndexCenterListOrTable
    tableComponent={
      <ProtocolCollectionTable
        showCollaborators
        actions={actions}
        protocolCollections={protocolCollections}
        {...rest}
      />
    }
    listComponent={
      <ProtocolCollectionList
        actions={actions}
        protocolCollections={protocolCollections}
      />
    }
  />
);

export default ProtocolCollectionListOrTable;
