/**
 * Labstep
 */

import { IStatus } from 'labstep-web/typings';

/**
 * Of the given statuses, returns the first one that is fetching
 * @param statuses Statuses
 * @returns
 */
export const getStatusIsFetching = (
  statuses: Record<number, IStatus> | undefined,
) => {
  if (!statuses) {
    return undefined;
  }
  return Object.values(statuses).find((s) => s.isFetching);
};
