/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Info/DateRange
 * @desc Show edit experimentWorkflow startedAt and endedAt
 */

import React from 'react';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import ExperimentWorkflowInfoStartedAt from 'labstep-web/components/ExperimentWorkflow/Info/StartedAt';
import ExperimentWorkflowInfoEndedAt from 'labstep-web/components/ExperimentWorkflow/Info/EndedAt';
import styles from './styles.module.scss';
import { IExperimentWorkflowInfoDateRangeProps } from './types';

export const ExperimentWorkflowInfoDateRange: React.FC<
  IExperimentWorkflowInfoDateRangeProps
> = ({ experimentWorkflow }) => (
  <Flex wrap className={styles.container}>
    <div>
      <Icon name="calendar alternate outline" />
    </div>
    <ExperimentWorkflowInfoStartedAt
      experimentWorkflow={experimentWorkflow}
    />
    <span>&nbsp;-&nbsp;</span>
    <ExperimentWorkflowInfoEndedAt
      experimentWorkflow={experimentWorkflow}
    />
  </Flex>
);

export default ExperimentWorkflowInfoDateRange;
