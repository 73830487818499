/**
 * Labstep
 */

export const getStateWithUpdatedLockedAt = (state, action) => {
  return Object.keys(state).reduce((result, key) => {
    if (
      action.meta.denormalized_payload.experiment_ids.includes(
        Number(key),
      )
    ) {
      return {
        ...result,
        [key]: {
          ...state[key],
          locked_at: action.meta.body.locked_at,
        },
      };
    }
    return { ...result, [key]: state[key] };
  }, {});
};
