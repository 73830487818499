/**
 * Labstep
 *
 * @module core/Menu
 * @desc Wrapper around Semantic Menu
 */

import SemanticMenu from 'semantic-ui-react/dist/commonjs/collections/Menu';

export default SemanticMenu;
