/**
 * Labstep
 *
 * @module core/Form/ReactForm/SelectMulti
 * @desc Input with tokenized items
 */

import React from 'react';
import { Controller } from 'react-hook-form';
import CoreSelectMulti from 'labstep-web/core/Select/Multi';
import { ISelectMultiFieldProps } from './types';

export const SelectMultiField: React.FC<ISelectMultiFieldProps> = ({
  elementProps,
  control,
  name,
  onKeyPress,
  ...rest
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: reactHookFormField }): React.ReactElement => {
      const { value, onChange: setValue } = reactHookFormField;

      return (
        <CoreSelectMulti
          defaultValues={value}
          {...elementProps}
          {...rest}
          onChange={(val: string[]) => {
            const selectedVal = val;
            if (selectedVal.length <= 0) {
              setValue(undefined as any);
            } else {
              setValue(selectedVal as any);
            }
          }}
        />
      );
    }}
  />
);

export default SelectMultiField;
