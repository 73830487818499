/**
 * Labstep
 *
 * @module core/Message
 * @desc Wrapper around semantic-ui-react Message
 */

import Message from 'semantic-ui-react/dist/commonjs/collections/Message';

export default Message;
