/**
 * Labstep
 *
 * @module models/experiment-workflow-link
 * @desc Typescript export class for ExperimentWorkflowLink
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { ExperimentWorkflow } from './experiment-workflow.model';

export class ExperimentWorkflowLink extends Entity {
  public static readonly idAttr = 'guid' as const;

  static readonly entityName = 'experiment_workflow_link';

  get entityName(): string {
    return ExperimentWorkflowLink.entityName;
  }

  constructor(data: Partial<ExperimentWorkflowLink> = {}) {
    super();
    Object.assign(this, data);
  }

  @Type(() => ExperimentWorkflow)
  src: ExperimentWorkflow;

  @Type(() => ExperimentWorkflow)
  dest: ExperimentWorkflow;

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
