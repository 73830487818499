/**
 * Labstep
 *
 * @module components/Comment/Card/EmptyState
 * @desc Empty state for tag
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';

export const CommentEmptyState: React.FC = () => (
  <EmptyState
    src="/img/empty-state/comments.svg"
    title="No notes"
    explanation="Add any additional notes here and start a conversation about it with your collaborators. Click the + icon to attach images, files, tables, or longer text."
  />
);

export default CommentEmptyState;
