/**
 * Labstep
 *
 * @module components/Table/SortOptions/Order
 * @desc Sort options order action
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import styles from './styles.module.scss';
import { ISortOptionsOrderProps } from './types';

const SortOptionsOrder: React.FC<ISortOptionsOrderProps> = ({
  activeSortKey,
  setParams,
}) => {
  const isOrderDesc = activeSortKey[0] === '-';
  return (
    <span
      className={styles.arrowContainer}
      onClick={(): void => {
        setParams({
          sort: isOrderDesc
            ? activeSortKey.slice(1)
            : `-${activeSortKey}`,
        });
      }}
    >
      <Icon
        name="long arrow alternate up"
        disabled={isOrderDesc}
        link
      />
      <Icon
        name="long arrow alternate down"
        disabled={!isOrderDesc}
        link
        className={styles.arrowDown}
      />
    </span>
  );
};

export default SortOptionsOrder;
