/**
 * Labstep
 *
 * @module core/Table
 * @desc Core dropdown component. Wrapper around semantic ui dropdown.
 */

import Table from 'semantic-ui-react/dist/commonjs/collections/Table';

export default Table;
