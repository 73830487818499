/**
 * Labstep
 *
 * @module components/ResourceLocation/Action/Delete
 * @desc Action to delete file
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { IResourceLocationActionDeleteProps } from './types';

export const ResourceLocationActionDelete: React.FC<
  IResourceLocationActionDeleteProps
> = ({ resourceLocation, actionComponentProps, options }) => (
  <EntityActionDelete
    actionComponentProps={actionComponentProps}
    entity={resourceLocation}
    options={options}
    confirmMessage={
      resourceLocation.deleted_at
        ? undefined
        : `Are you sure you want to delete this ${ResourceLocation.getHumanReadableEntityName()}? All sublocations will be deleted as well.`
    }
  />
);

export default ResourceLocationActionDelete;
