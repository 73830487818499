/**
 * Labstep
 *
 * @desc Protocol Condition DataGrid
 */

import React from 'react';
import Loadable from 'labstep-web/core/Loadable';
import { IProtocolConditionDataGridProps } from './types';

export const ProtocolConditionDataGridComponent = React.lazy(
  () => import('./component'),
);

const ProtocolConditionDataGrid: React.FC<
  IProtocolConditionDataGridProps
> = (props) => (
  <Loadable>
    <ProtocolConditionDataGridComponent {...props} />
  </Loadable>
);

export default ProtocolConditionDataGrid;
