/**
 * Labstep
 */

import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import NodeReference from 'labstep-web/prosemirror/nodes/reference';

export const replaceWithReference = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
  from: number,
  to: number,
  attrs,
) => {
  const node = state.schema.nodes[NodeReference.key].create(attrs);
  dispatch(state.tr.replaceRangeWith(from, to, node));
};
