/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/Show/Center/Pane
 * @desc Pane listing experiments
 */

import classnames from 'classnames';
import { ICONS } from 'labstep-web/constants/icons';
import Icon from 'labstep-web/core/Icon';
import List from 'labstep-web/core/List';
import Mobile from 'labstep-web/core/MediaQuery/Mobile';
import Select from 'labstep-web/core/Select';
import TextWithCount from 'labstep-web/core/Text/WithCount';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Experiment } from 'labstep-web/models/experiment.model';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import {
  IExperimentWorkflowShowCenterPaneContainerProps,
  IExperimentWorkflowShowCenterPaneProps,
} from './types';

export const ExperimentWorkflowShowCenterPane: React.FC<
  IExperimentWorkflowShowCenterPaneProps
> = ({ onClick, right, activeExperiment, experiments, count }) => (
  <div className={styles.container}>
    <Mobile>
      {({ match }) =>
        match ? (
          <Select
            value={{
              ...activeExperiment,
              name:
                experiments[0].id === activeExperiment.id
                  ? 'Entry'
                  : activeExperiment.name,
            }}
            options={experiments.map((experiment, index) => ({
              ...experiment,
              name: index === 0 ? 'Entry' : experiment.name,
            }))}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option}
            onChange={(option) => {
              onClick(experiments.find((e) => e.id === option.id));
            }}
          />
        ) : (
          <List>
            {experiments.map((experiment, index) => (
              <div
                key={experiment.id}
                onClick={() => onClick(experiment)}
                className={classnames(styles.item, {
                  [styles.active]:
                    activeExperiment.id === experiment.id,
                })}
              >
                <Icon
                  name={
                    index === 0
                      ? ICONS.experiment_workflow.primary
                      : ICONS.experiment.primary
                  }
                />

                <TextWithCount
                  text={index === 0 ? 'Entry' : experiment.name}
                  count={count(experiment)}
                />
              </div>
            ))}
          </List>
        )
      }
    </Mobile>
    <div>{right(activeExperiment)}</div>
  </div>
);

const ExperimentWorkflowShowCenterPaneContainer: React.FC<
  IExperimentWorkflowShowCenterPaneContainerProps
> = ({ experimentWorkflow, right, ...rest }) => {
  const [activeExperiment, setActiveExperiment] = useState(
    experimentWorkflow.root_experiment,
  );

  useEffect(() => {
    if (experimentWorkflow.experiment_count === 0) {
      setActiveExperiment(experimentWorkflow.root_experiment);
    }
  }, [
    experimentWorkflow.experiment_count,
    experimentWorkflow.root_experiment,
  ]);

  return experimentWorkflow.experiment_count ? (
    <ReadOnMountHOC
      type="cursor"
      entityName={Experiment.entityName}
      params={{
        is_root: false,
        experiment_workflow_id: experimentWorkflow.id,
      }}
      children={({ entities: experiments }) => (
        <ExperimentWorkflowShowCenterPane
          {...rest}
          experiments={[
            experimentWorkflow.root_experiment,
            ...experiments,
          ]}
          activeExperiment={activeExperiment}
          onClick={setActiveExperiment}
          right={right}
        />
      )}
    />
  ) : (
    <>{right(activeExperiment)}</>
  );
};

export default ExperimentWorkflowShowCenterPaneContainer;
