/**
 * Labstep
 *
 * @module containers/ProtocolTimer/Item
 * @desc ProtocolTimer
 */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { defaultFormat } from 'labstep-web/services/date.service';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import {
  IProtocolTimerItemContainerProps,
  IProtocolTimerItemProps,
} from './types';
import { getRemainingTime } from './utils';

const INTERVAL_MS = 500;

export const ProtocolTimerItem: React.FC<IProtocolTimerItemProps> = ({
  update,
  protocolTimer,
  children,
}) => {
  const { paused_at, ended_at, hours, minutes, seconds } =
    protocolTimer;

  const updateProtocolTimer = () => {
    if (!paused_at && !ended_at) {
      update({
        ended_at: moment
          .utc()
          .add({ hours, minutes, seconds })
          .format(defaultFormat),
      });
    } else if (paused_at === null) {
      update({
        paused_at: moment.utc().format(defaultFormat),
      });
    } else {
      const diff = moment(ended_at).diff(moment(paused_at));
      update({
        paused_at: null,
        ended_at: moment.utc().add(diff).format(defaultFormat),
      });
    }
  };
  const [timeRemaining, setTimeRemaining] = useState(
    getRemainingTime(protocolTimer),
  );

  useEffect(() => {
    setTimeRemaining(getRemainingTime(protocolTimer));
  }, [
    protocolTimer.hours,
    protocolTimer.minutes,
    protocolTimer.seconds,
  ]);

  useEffect(() => {
    const updateTimeRemaining = () => {
      setTimeRemaining(getRemainingTime(protocolTimer));
    };

    let timer: string | number | NodeJS.Timeout | undefined = 0;

    const startTimer = () => {
      timer = setInterval(updateTimeRemaining, INTERVAL_MS);
    };

    // When user plays timer
    if (!paused_at && !!ended_at) {
      startTimer();
    }

    // When user pauses timer
    if (!!paused_at && timer) {
      clearInterval(timer);
    }

    // When user clicks reset timer
    if (!paused_at && !ended_at) {
      updateTimeRemaining();
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [paused_at, ended_at]);

  return children({
    timeRemaining,
    updateProtocolTimer,
  });
};

export const ProtocolTimerItemContainer: React.FC<
  IProtocolTimerItemContainerProps
> = ({ protocolTimer, ...rest }) => (
  <EntityUpdateContainer
    entityName={protocolTimer.entityName}
    id={protocolTimer.idAttr}
    optimistic
  >
    {(containerProps) => (
      <ProtocolTimerItem
        protocolTimer={protocolTimer}
        {...containerProps}
        {...rest}
      />
    )}
  </EntityUpdateContainer>
);
