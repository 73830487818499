/**
 * Labstep
 *
 * @module components/App/Routes/Transition
 * @desc App routes based on CSS transitions
 */

import FullScreenContainer from 'labstep-web/components/Layout/FullScreen';
import { subLinks } from 'labstep-web/components/Layout/FullScreen/Links/constants';
import { ProtocolConditionModalProvider } from 'labstep-web/components/ProtocolCondition/Modal/context';
import Route from 'labstep-web/core/Route';
import { Experiment } from 'labstep-web/models/experiment.model';
import ExperimentShow from 'labstep-web/screens/Experiment/Show';
import ExperimentWorkflowShow from 'labstep-web/screens/ExperimentWorkflow/Show';
import { navigation } from 'labstep-web/services/navigation';
import React, { useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import styles from './styles.module.scss';
import './styles.scss';
import { IExperimentWorkflowExperimentRouteProps } from './types';

// track exact path so we can navigate back to it
export const experimentWorkflowSubRoutes = [
  'experiment_workflow_show',
  'experiment_workflow_show_workflow',
  'experiment_workflow_show_metadata',
  'experiment_workflow_show_devices',
  'experiment_workflow_show_values',
  'experiment_workflow_show_activity',
  'experiment_workflow_show_thread',
  'experiment_workflow_show_signatures',
  'experiment_workflow_show_conditions',
  'experiment_workflow_show_experiment_workflows',
  'experiment_workflow_show_value_show',
  'experiment_workflow_show_metadata_show',
  'experiment_workflow_show_thread_show',
  'experiment_workflow_show_metadata_show_thread',
  'experiment_workflow_show_step_show_thread',
  'experiment_workflow_show_metadata_show_thread_show',
  'experiment_workflow_show_step_show_thread_show',
  'experiment_workflow_show_state_workflow',
] as const;

export const ExperimentWorkflowExperimentRoute: React.FC<
  IExperimentWorkflowExperimentRouteProps
> = () => {
  const experimentRef =
    useRef<IExperimentWorkflowExperimentRouteProps>();
  const experimentWorkflowRef =
    useRef<IExperimentWorkflowExperimentRouteProps>();
  const isConditionsMatch = useRouteMatch(
    navigation.get('experiment_show_conditions'),
  );
  return (
    <>
      <Route path={navigation.get('experiment_show')}>
        {(childrenProps: IExperimentWorkflowExperimentRouteProps) => {
          if (childrenProps.match) {
            experimentRef.current = childrenProps;
          }
          const experimentShow = (
            <div className={styles.container}>
              <div className="transition-page">
                <ExperimentShow
                  id={
                    childrenProps.match
                      ? childrenProps.match.params.id
                      : experimentRef?.current?.match.params.id
                  }
                  activeExperimentWorkflowRoute={
                    experimentWorkflowRef?.current?.match
                  }
                />
              </div>
            </div>
          );
          return (
            <ProtocolConditionModalProvider
              entityName={Experiment.entityName}
              previousLocation={
                experimentWorkflowRef?.current?.match?.url
              }
            >
              <CSSTransition
                timeout={500}
                classNames={{
                  enter: styles.sliderEnter,
                  enterDone: styles.sliderEnterDone,
                  exitActive: styles.sliderExitActive,
                }}
                in={childrenProps.match !== null}
                unmountOnExit
                exit={
                  !(childrenProps.history.location.state as any)
                    ?.is_root && !isConditionsMatch
                }
                enter={!isConditionsMatch}
              >
                {experimentShow}
              </CSSTransition>
            </ProtocolConditionModalProvider>
          );
        }}
      </Route>
      <Route
        exact
        path={experimentWorkflowSubRoutes.map((p) =>
          navigation.get(p),
        )}
      >
        {(childrenProps: IExperimentWorkflowExperimentRouteProps) => {
          if (childrenProps.match) {
            experimentWorkflowRef.current = childrenProps;
          }
          return (
            <CSSTransition
              timeout={1000}
              in={childrenProps.match !== null}
              unmountOnExit
            >
              <FullScreenContainer
                breadcrumb={{
                  organization: true,
                  group: true,
                  module: subLinks.experiments,
                }}
              >
                <ExperimentWorkflowShow
                  id={
                    childrenProps.match
                      ? childrenProps.match.params.id
                      : experimentWorkflowRef?.current?.match.params
                          .id
                  }
                  activeExperimentWorkflowRoute={
                    experimentWorkflowRef?.current?.match
                  }
                />
              </FullScreenContainer>
            </CSSTransition>
          );
        }}
      </Route>
    </>
  );
};

export default ExperimentWorkflowExperimentRoute;
