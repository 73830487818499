/**
 * Labstep
 *
 * @module components/ResourceLocation/Form/Create
 * @desc Createable ResourceLocationMap
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import {
  DEFAULT_DIMENSION_VALUE,
  fieldResourceLocationMapColumns,
  fieldResourceLocationMapRows,
} from 'labstep-web/components/ResourceLocation/Form/fields';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import FormReusable from 'labstep-web/core/Form/Reusable';
import React from 'react';
import { IResourceLocationMapFormCreateProps } from './types';

export const ResourceLocationMapFormCreate: React.FC<
  IResourceLocationMapFormCreateProps
> = ({ resourceLocation }) => {
  const defaultValues: any = {
    rows: DEFAULT_DIMENSION_VALUE,
    columns: DEFAULT_DIMENSION_VALUE,
  };
  return (
    <Can
      entityName={resourceLocation.entityName}
      id={resourceLocation.guid}
      action={Action.edit}
    >
      <EntityUpdateContainer
        entityName={resourceLocation.entityName}
        id={resourceLocation.guid}
      >
        {({ update, status }) => (
          <FormReusable
            autoFocus
            status={status}
            defaultValues={defaultValues}
            fields={[
              fieldResourceLocationMapRows,
              fieldResourceLocationMapColumns,
            ]}
            onSubmit={({ rows, columns }) => {
              update({
                map_data: {
                  rowCount: rows,
                  columnCount: columns,
                  data: {},
                },
              });
            }}
          />
        )}
      </EntityUpdateContainer>
    </Can>
  );
};

export default ResourceLocationMapFormCreate;
