/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/Left
 * @desc Button to display a list of actions on the left
 */

import React from 'react';
import Dropdown from 'labstep-web/core/Dropdown';
import ToolbarButton from 'labstep-web/prosemirror/components/Toolbar/Button';
import ToolbarLink from 'labstep-web/prosemirror/components/Toolbar/Link';
import ToolbarFile from 'labstep-web/prosemirror/components/Toolbar/File';
import ToolbarMetadata from 'labstep-web/prosemirror/components/Toolbar/Metadata';
import ToolbarExperiment from 'labstep-web/prosemirror/components/Toolbar/Experiment';
import ToolbarProtocolValue from 'labstep-web/prosemirror/components/Toolbar/ProtocolValue';
import ToolbarProtocolTable from 'labstep-web/prosemirror/components/Toolbar/ProtocolTable';
import ToolbarProtocolTimer from 'labstep-web/prosemirror/components/Toolbar/ProtocolTimer';
import ToolbarProtocolStep from 'labstep-web/prosemirror/components/Toolbar/ProtocolStep';
import CodeToolbar from 'labstep-web/prosemirror/components/Toolbar/Code';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { IToolbarInlineProps } from './types';
import styles from './styles.module.scss';

export const ToolbarInline: React.FC<IToolbarInlineProps> = ({
  view,
  experimentWorkflow,
  entity,
}) => {
  let experiment: Experiment = null;
  let protocol: Protocol = null;
  if (entity instanceof Experiment) {
    experiment = entity;
  } else {
    protocol = entity;
  }
  const { state, dispatch } = view;
  return (
    <Dropdown
      className={styles.dropdown}
      trigger={
        <ToolbarButton
          popup="Insert Elements"
          icon="plus circle"
          className={styles.dropdownIcon}
        />
      }
    >
      {experiment ? (
        <>
          {!experimentWorkflow && (
            <ToolbarProtocolStep
              state={state}
              dispatch={dispatch}
              protocol={experiment}
              isToolbarButton={false}
            />
          )}
          {experimentWorkflow && (
            <ToolbarExperiment
              view={view}
              experimentWorkflow={experimentWorkflow}
            />
          )}
          <ToolbarProtocolTable
            state={state}
            dispatch={dispatch}
            entity={experiment}
          />
          <ToolbarProtocolValue view={view} protocol={experiment} />
          <ToolbarMetadata
            state={state}
            dispatch={dispatch}
            entity={entity}
          />
          {!experimentWorkflow && (
            <ToolbarProtocolTimer
              state={state}
              dispatch={dispatch}
              protocol={experiment}
            />
          )}
        </>
      ) : (
        <>
          <ToolbarProtocolStep
            state={state}
            dispatch={dispatch}
            protocol={protocol}
            isToolbarButton={false}
          />
          <ToolbarProtocolTable
            state={state}
            dispatch={dispatch}
            entity={protocol}
          />
          <ToolbarProtocolValue view={view} protocol={protocol} />
          <ToolbarMetadata
            state={state}
            dispatch={dispatch}
            entity={entity}
          />
          <ToolbarProtocolTimer
            state={state}
            dispatch={dispatch}
            protocol={protocol}
          />
        </>
      )}
      <ToolbarFile
        key={1}
        state={state}
        dispatch={dispatch}
        entity={entity}
      />
      <ToolbarFile
        key={2}
        state={state}
        dispatch={dispatch}
        entity={entity}
        isImage
      />
      <ToolbarLink state={state} dispatch={dispatch} />
      <CodeToolbar state={state} dispatch={dispatch} />
    </Dropdown>
  );
};
export default ToolbarInline;
