/**
 * Labstep
 *
 * @module state/selectors/user
 * @desc Selectors for User
 */

import { hasKey } from './helpers';

/**
 * Select confirm email status
 *
 * @function
 * @param  {object} state
 * @param  {string} token
 * @return {object} status
 */
export const selectConfirmUserStatus = (
  state: any,
  token: string,
) => {
  const { confirmingIds } = state.entities.user;
  return hasKey(confirmingIds, token)
    ? confirmingIds[token].status
    : false;
};

/**
 * Select resend confirmation email status
 *
 * @function
 * @param  {object} state
 * @return {object} status
 */
export const selectResendConfirmationEmailStatus = (state: any) => {
  const { confirmationEmail } = state.entities.user;
  return hasKey(confirmationEmail, 'confirmation_email')
    ? confirmationEmail.confirmation_email.status
    : false;
};
