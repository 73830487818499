/**
 * Labstep
 */

import { IActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { ResourceItem } from 'labstep-web/models/resource-item.model';

// eslint-disable-next-line no-shadow
export enum Action {
  duplicate = 'duplicate',
  delete = 'delete',
  assign = 'assign',
}

export interface IResourceItemActionMenuProps
  extends Pick<
    IActionMenuProps,
    Exclude<keyof IActionMenuProps, 'children'>
  > {
  resourceItem: ResourceItem;
  actions: Action[];
}
