/**
 * Labstep
 *
 * @module components/Resource/Form/ShowEdit/Name
 * @desc Name showedit field
 */

import ResourcePrimaryInfo from 'labstep-web/components/Resource/PrimaryInfo';
import { ICONS } from 'labstep-web/constants/icons';
import Identifier from 'labstep-web/core/Identifier';
import React from 'react';
import { IResourceFormShowEditNameProps } from './types';

const ResourceFormShowEditName: React.FC<
  IResourceFormShowEditNameProps
> = ({ resource }) => (
  <ResourcePrimaryInfo
    resource={resource}
    icon={
      resource.is_template
        ? ICONS.experiment_workflow.template
        : undefined
    }
    name={resource.name || ''}
    prefix={
      resource.custom_identifier && (
        <>
          <Identifier>{resource.custom_identifier}</Identifier>
        </>
      )
    }
  />
);

export default ResourceFormShowEditName;
