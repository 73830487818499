/**
 * Labstep
 *
 * @module components/Sequence/Action/Update
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import SequenceEditor from 'labstep-web/core/SequenceEditor';
import { Sequence } from 'labstep-web/models/sequence.model';
import React from 'react';
import { IChildrenProps, ISequenceActionUpdateProps } from './types';

const SequenceActionUpdate: React.FC<ISequenceActionUpdateProps> = ({
  sequenceId,
  onClose,
  ...rest
}) => {
  const canEdit = useHasAccess(
    Sequence.entityName,
    sequenceId,
    Action.edit,
  );
  return (
    <EntityUpdateContainer
      entityName={Sequence.entityName}
      id={sequenceId}
    >
      {({ update, entity: sequence }: IChildrenProps) => {
        if (!sequence) {
          return null;
        }
        return (
          <SequenceEditor
            sequenceData={sequence.sequenceData}
            onClose={onClose}
            readOnly={!canEdit}
            onSave={(sequenceData, options = {}) => {
              const { name, ...data } = sequenceData;
              update({ name, data: JSON.stringify(data) }, options);
            }}
            {...rest}
          />
        );
      }}
    </EntityUpdateContainer>
  );
};

export default SequenceActionUpdate;
