/**
 * Labstep
 *
 * @module components/Filter/Active
 * @desc Active Filters
 */

import React from 'react';
import { dateOnly } from 'labstep-web/services/date.service';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import FilterTagActive from 'labstep-web/components/Filter/Tag/Active';
import ExperimentWorkflowFilterStatusTypeActive from 'labstep-web/components/ExperimentWorkflow/Filter/StatusType/Active';
import ExperimentWorkflowFilterProtocolActive from 'labstep-web/components/ExperimentWorkflow/Filter/Protocol/Active';
import ExperimentWorkflowFilterResourceActive from 'labstep-web/components/ExperimentWorkflow/Filter/Resource/Active';
import ExperimentWorkflowFilterSignedActive from 'labstep-web/components/ExperimentWorkflow/Filter/Signed/Active';
import ResourceFilterActiveAvailableResourceItemCount from 'labstep-web/components/Resource/Filter/Active/AvailableResourceItemCount';
import FileFilterExtensionActive from 'labstep-web/components/File/Filter/Extension/Active';
import TagFilterTypeActive from 'labstep-web/components/Tag/Filter/Type/Active';
import OrderRequestFilterUrgentActive from 'labstep-web/components/OrderRequest/Filter/Urgent/Active';
import ResourceLocationFilterActive from 'labstep-web/components/ResourceLocation/Filter/Active';
import FilterSearchActive from 'labstep-web/components/Filter/Search/Active';
import MoleculeFilterActive from 'labstep-web/components/Molecule/Filter/Active';
import ExperimentWorkflowFilterStatusActive from 'labstep-web/components/ExperimentWorkflow/Filter/Status/Active';
import FilterPostActive from '../Post/Active';
import FilterDeletedActive from '../Deleted/Active';
import FilterUserActive from '../User/Active';
import FilterResourceItemStatusActive from '../ResourceItemStatus/Active';
import { IActiveFilterProps } from './types';
import styles from './styles.module.scss';
import { getHasActiveFilter } from './utils';

export const DateRangeActiveFilter: React.FC<any> = ({
  dateFrom,
  dateTo,
  clearParams,
}) => {
  if (!(dateFrom || dateTo)) {
    return null;
  }

  const toText = dateOnly(dateTo);
  const fromText = dateOnly(dateFrom);

  return (
    <RemoveLabel
      name={`${fromText} - ${toText}`}
      icon="calendar alternate"
      onRemoveClick={() =>
        clearParams &&
        clearParams(['created_at_from', 'created_at_to'])
      }
    />
  );
};

const ActiveFilter: React.FC<IActiveFilterProps> = ({
  searchParams,
  setParams,
  clearParams,
  showSearchQuery,
  setPostFilterType,
  removePostFilter,
}) => {
  const hasParams = getHasActiveFilter(searchParams, showSearchQuery);
  if (!hasParams) {
    return null;
  }
  return (
    <div data-testid="filter-active" className={styles.container}>
      <span>Filtering by:</span>
      <FilterPostActive
        searchParams={searchParams}
        setPostFilterType={setPostFilterType}
        removePostFilter={removePostFilter}
      />
      <DateRangeActiveFilter
        clearParams={clearParams}
        dateFrom={searchParams.created_at_from}
        dateTo={searchParams.created_at_to}
        setParams={setParams}
      />
      <FilterUserActive
        searchParams={searchParams}
        clearParams={clearParams}
        type="author"
      />
      <FilterTagActive
        setParams={setParams}
        searchParams={searchParams}
      />
      <TagFilterTypeActive
        clearParams={clearParams}
        searchParams={searchParams}
      />
      <FilterResourceItemStatusActive
        clearParams={clearParams}
        searchParams={searchParams}
      />
      <ExperimentWorkflowFilterStatusTypeActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <ExperimentWorkflowFilterStatusActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <ResourceFilterActiveAvailableResourceItemCount
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <FileFilterExtensionActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <ExperimentWorkflowFilterSignedActive
        clearParams={clearParams}
        searchParams={searchParams}
      />
      <ExperimentWorkflowFilterProtocolActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <ExperimentWorkflowFilterResourceActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <OrderRequestFilterUrgentActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <FilterDeletedActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <FilterUserActive
        searchParams={searchParams}
        clearParams={clearParams}
        type="assignee"
      />
      <ResourceLocationFilterActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <MoleculeFilterActive
        searchParams={searchParams}
        clearParams={clearParams}
        setParams={setParams}
      />
      {showSearchQuery && (
        <FilterSearchActive
          searchParams={searchParams}
          clearParams={clearParams}
        />
      )}
    </div>
  );
};

export default ActiveFilter;
