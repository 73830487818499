/**
 * Labstep
 */

import { ICONS } from 'labstep-web/constants/icons';
import { ExperimentWorkflowLink } from 'labstep-web/models/experiment-workflow-link.model';
import { JupyterNotebook } from 'labstep-web/models/jupyter-notebook.model';
import { Metadata } from 'labstep-web/models/metadata';
import { Molecule } from 'labstep-web/models/molecule.model';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { ProtocolStep } from 'labstep-web/models/protocol-step.model';
import { ProtocolTable } from 'labstep-web/models/protocol-table.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import NodeCode from 'labstep-web/prosemirror/nodes/code';
import NodeConditions from 'labstep-web/prosemirror/nodes/conditions';
import { createConditionsNodeAndSubmitElement } from 'labstep-web/prosemirror/nodes/conditions/commands';
import NodeExperiment from 'labstep-web/prosemirror/nodes/experiment';
import NodeExperimentWorkflowLink from 'labstep-web/prosemirror/nodes/experiment-workflow-link';
import NodeFile from 'labstep-web/prosemirror/nodes/file';
import NodeJupyterNotebook from 'labstep-web/prosemirror/nodes/jupyter-notebook';
import NodeMolecule from 'labstep-web/prosemirror/nodes/molecule';
import NodeProtocolStep from 'labstep-web/prosemirror/nodes/protocol-step';
import NodeProtocolTable from 'labstep-web/prosemirror/nodes/protocol-table';
import NodeProtocolTimer from 'labstep-web/prosemirror/nodes/protocol-timer';
import NodeProtocolValue from 'labstep-web/prosemirror/nodes/protocol-value';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import {
  IMenuCommandsElementProps,
  MenuCommandsLabel,
} from './types';

export const getHeader = (
  element: IMenuCommandsElementProps,
): string =>
  element.name || getHumanReadableEntityName(element.id, false, true);

export const MENU_COMMANDS_ELEMENTS: IMenuCommandsElementProps[] = [
  {
    id: 'protocol_collection',
    icon: ICONS.protocol.primary,
    description:
      'Insert a protocol detailing the methods followed / to be followed',
    key: NodeExperiment.key,
  },
  {
    id: 'metadata',
    icon: ICONS.metadata.default,
    description: 'Create a field for capturing input / output data',
    key: 'metadata',
    entityName: Metadata.entityName,
    name: 'Data',
  },
  {
    id: 'protocol_step',
    icon: ICONS.protocol.step,
    description: 'Add a step to be completed',
    key: NodeProtocolStep.key,
    entityName: ProtocolStep.entityName,
  },
  {
    id: 'protocol_table',
    icon: 'calculator',
    description: 'Insert a spreadsheet for doing calculations',
    key: NodeProtocolTable.key,
    entityName: ProtocolTable.entityName,
    name: 'Interactive Table',
  },
  {
    id: 'protocol_value',
    icon: ICONS.protocol.value,
    description:
      'Create a field for capturing resources used / produced',
    key: NodeProtocolValue.key,
    entityName: ProtocolValue.entityName,
    name: 'Inventory',
    premiumFeature: 'inventory',
  },
  {
    id: 'protocol_timer',
    icon: ICONS.protocol.timer,
    description: 'Insert an interactive timer',
    key: NodeProtocolTimer.key,
  },
  {
    id: 'file',
    icon: 'paperclip',
    description: 'Attach / embed a file',
    key: NodeFile.key,
  },
  {
    id: 'image',
    icon: 'images',
    description: 'Insert an inline image',
    key: NodeFile.key,
  },
  {
    id: 'link',
    icon: 'linkify',
    description: 'Insert a link to an external webpage',
    key: 'link',
  },
  {
    id: 'experiment_workflow_link',
    icon: ICONS.experiment_workflow.primary,
    description: 'Link to an existing experiment',
    key: NodeExperimentWorkflowLink.key,
    entityName: ExperimentWorkflowLink.entityName,
    name: 'Link to Experiment',
  },
  {
    id: 'html_table',
    icon: 'table',
    description: 'Insert a simple table',
    key: 'table',
    name: 'Basic Table',
  },
  {
    id: 'molecule',
    icon: ICONS.molecule.primary,
    description:
      'Insert chemical reaction schema / molecular structures',
    key: NodeMolecule.key,
    entityName: Molecule.entityName,
    name: 'Chemistry',
    premiumFeature: 'chemistry',
  },
  {
    id: 'code',
    icon: 'code',
    description: 'Display formatted code',
    key: NodeCode.key,
  },
  {
    id: 'jupyter_notebook',
    image: JupyterNotebook.icon,
    description: 'Insert executable code for analysing data',
    key: NodeJupyterNotebook.key,
    entityName: JupyterNotebook.entityName,
    premiumFeature: 'jupyter',
  },
  {
    id: 'protocol_device',
    icon: ICONS.device.primary,
    description: 'Link a device to this experiment',
    key: 'protocol_device',
    premiumFeature: 'devices',
  },
  {
    id: 'conditions',
    icon: ICONS.protocol_condition.primary,
    description:
      'Insert a table for specifiying inputs and outputs for different conditions',
    key: NodeConditions.key,
    name: `${ProtocolCondition.getHumanReadableEntityName(
      true,
      true,
    )} Table`,
    onCreate: createConditionsNodeAndSubmitElement,
    premiumFeature: 'multiplexing',
  },
];

export default MENU_COMMANDS_ELEMENTS;
