/**
 * Labstep
 *
 * @desc Bar with bulk selection and pagination action
 */

import React from 'react';
import { useBulkSelectContext } from 'labstep-web/containers/BulkSelect/context';
import BulkActions from '../BulkActions';
import styles from './styles.module.scss';
import { IActionBarProps } from './types';

const ActionBar: React.FC<IActionBarProps> = ({
  bulkActions,
  entityName,
}) => {
  const { isAtLeastOneSelected } = useBulkSelectContext();
  if (!isAtLeastOneSelected) {
    return null;
  }
  return (
    <div className={styles.actionsBar}>
      <BulkActions
        entityName={entityName}
        bulkActions={bulkActions}
      />
    </div>
  );
};

export default ActionBar;
