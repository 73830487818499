/**
 * Labstep
 */

import {
  getPubchemBody,
  getPubchemFieldValue,
} from 'labstep-web/components/Molecule/Pubchem/utils';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import {
  getEditable,
  getEntityDefault,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import { Molecule } from 'labstep-web/models/molecule.model';
import { isValid } from 'labstep-web/services/validation';
import React from 'react';
import { colDefPubchemType } from './types';

/**
 * Column definition for pubchem fields
 * @param field Field (IUPACName, MolecularWeight, Density, MolecularFormula, CAS)
 * @param options Options
 * @returns Column definition
 */
const colDefPubchem: colDefPubchemType = (field, options = {}) => {
  const { getNestedEntity, columnProps, getCreateProps } = options;
  const editable = getEditable(getNestedEntity);
  const getEntity = getNestedEntity || getEntityDefault;

  return {
    colId: `pubchem_${field.name}`,
    cellRenderer: (params) => {
      if (!params.data) {
        return '';
      }
      const fallback = (
        <DataGridPlaceholder params={params} editable={editable} />
      );
      const entity = getEntity(params);
      return entity instanceof Molecule
        ? getPubchemFieldValue(entity, field) || fallback
        : fallback;
    },
    headerName: field.fieldLabel,
    editable,
    valueGetter: (params): string | number | null => {
      const entity = getEntity(params);
      if (entity instanceof Molecule) {
        return getPubchemFieldValue(entity, field);
      }
      return null;
    },
    valueSetter: (params): boolean => {
      const entity = getEntity(params);
      if (!(entity instanceof Molecule)) {
        return false;
      }
      return setValue(
        'pubchem',
        getPubchemBody(entity, field, params.newValue),
        {
          entityName: entity.entityName,
          id: entity.idAttr,
          createProps: getCreateProps?.(params),
        },
        (value) => ({
          value,
          error: !isValid(params.newValue, field.validation),
        }),
      );
    },
    ...columnProps,
  };
};

export default colDefPubchem;
