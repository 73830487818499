/**
 * Labstep
 *
 * @module components/Device/Item
 * @desc Device Item
 */

import React from 'react';
import { CardHeader } from 'labstep-web/core/Card/Generic';
import ActionMenu from 'labstep-web/components/Device/Action/Menu';
import DeviceImageUploadable from 'labstep-web/components/Device/Image/Uploadable';
import styles from './styles.module.scss';
import { IDeviceItemProps } from './types';

const DeviceItem: React.FC<IDeviceItemProps> = ({
  device,
  actions,
  mainAction,
  detailsSecondary,
  linkable,
}) => {
  const title = {
    to: 'device_show',
    params: { id: device.id },
    title: device.name,
    disabled: !linkable,
  };

  return (
    <div className={styles.top}>
      <DeviceImageUploadable device={device} mobile />
      <div className={styles.header}>
        <CardHeader
          title={title}
          detailsSecondary={
            detailsSecondary && detailsSecondary({ device })
          }
          actionMenu={
            <ActionMenu actions={actions} device={device} />
          }
          mainAction={mainAction && mainAction({ device })}
        />
      </div>
    </div>
  );
};

DeviceItem.defaultProps = {
  linkable: true,
};

export default DeviceItem;
