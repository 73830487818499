/**
 * Labstep
 *
 * @module screens/Protocol/Printable
 * @desc Display printable version of an Protocol
 */

import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import Print from 'labstep-web/core/Print';
import { EntityExport } from 'labstep-web/models/entity-export.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import { IProtocolPrintScreenProps } from './types';

export const ProtocolPrintScreen: React.FC<
  IProtocolPrintScreenProps
> = ({ match }) => (
  <EntityCreateContainer entityName={EntityExport.entityName}>
    {({ status, create }) => (
      <Print
        id={match.params.id}
        entityName={Protocol.entityName}
        status={status}
        create={create}
      />
    )}
  </EntityCreateContainer>
);

export default ProtocolPrintScreen;
