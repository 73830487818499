/**
 * Labstep
 *
 * @module components/Metadata/Detail/Table
 * @desc Table for (meta)Data Detail
 */

import React from 'react';
import EntityDetailTable from 'labstep-web/components/Entity/Detail/Table';
import { IMetadataDetailTableValueProps } from './types';
import { getRows } from './utils';

export const MetadataDetailTableValue: React.FC<
  IMetadataDetailTableValueProps
> = ({ metadata, entity }) => (
  <EntityDetailTable rows={getRows(entity)} columns={[metadata]} />
);

export default MetadataDetailTableValue;
