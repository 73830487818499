/**
 * Labstep
 *
 * @module components/Entity/Draggable
 * @desc Entity Draggable
 */

import React from 'react';
import cx from 'classnames';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { getIdAttribute } from 'labstep-web/services/schema/helpers';
import styles from './styles.module.scss';
import { IEntityDraggableProps } from './types';

export const EntityDraggable: React.FC<IEntityDraggableProps> = ({
  children,
  entityId,
  entityGuid,
  entityName,
  isReference,
  disabled,
}) => {
  const canEdit = useHasAccess(
    entityName,
    (getIdAttribute(entityName) === 'guid'
      ? entityGuid
      : entityId) as string | number,
    Action.edit,
  );
  return (
    <div
      className={cx({ [styles.draggable]: !disabled })}
      draggable={!disabled && canEdit}
      onDragStart={(e) => {
        const data: any = {
          entity_id: entityId,
          entity_guid: entityGuid,
          entity_name: entityName,
          is_reference: isReference,
        };
        e.dataTransfer.setData('text', JSON.stringify(data));
      }}
    >
      {children}
    </div>
  );
};

export default EntityDraggable;
