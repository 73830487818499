/**
 * Labstep
 */

import { Action } from 'labstep-web/components/OrderRequest/Action/Menu/types';

export const actions = [
  Action.set_purchase_order,
  Action.share,
  Action.duplicate,
  Action.transfer_ownership,
  Action.tag,
  Action.revoke,
  Action.delete,
  Action.assign,
];
