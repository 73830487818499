/**
 * Labstep
 *
 * @module components/JupyterNotebook/Action/Edit
 * @desc Open a modal to edit a jupyter notebook
 */

import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import JupyterNotebookModalEdit from 'labstep-web/components/JupyterNotebook/Modal/Edit';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import { JupyterNotebook } from 'labstep-web/models/jupyter-notebook.model';
import React, { useEffect, useState } from 'react';
import { JupyterNotebookActionEditProps } from './types';

export const JupyterNotebookActionEdit: React.FC<
  JupyterNotebookActionEditProps
> = ({ jupyterNotebook, activeEditModal, setActiveEditModal }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      activeEditModal &&
      activeEditModal.entityName === JupyterNotebook.entityName &&
      activeEditModal.id === jupyterNotebook.id
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [activeEditModal, jupyterNotebook]);

  return (
    <JupyterNotebookModalEdit
      jupyterNotebook={jupyterNotebook}
      onClose={() => setActiveEditModal(null)}
      open={open}
      viewComponent={() => (
        <EntityActionEdit
          entity={jupyterNotebook}
          actionComponentProps={{
            type: 'icon',
            icon: 'external alternate',
            elementProps: {
              color: 'blue',
              popup: { content: 'Open Code Editor' },
            },
            onClick: (evt) => {
              setActiveEditModal({
                entityName: JupyterNotebook.entityName,
                id: jupyterNotebook.id,
              });
              evt.stopPropagation();
            },
          }}
        />
      )}
    />
  );
};

export default withActiveEditModal(JupyterNotebookActionEdit);
