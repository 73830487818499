/**
 * Labstep
 *
 * @module components/Entity/PrimaryInfo
 * @desc Primary Info section of entity
 */

import PermaLinkActionCopy from 'labstep-web/components/PermaLink/Action/Copy';
import { ICONS } from 'labstep-web/constants/icons';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import Header from 'labstep-web/core/Header';
import Hoverable from 'labstep-web/core/Hoverable';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import ResourceFormShowEditName from 'labstep-web/components/Resource/Form/ShowEdit/Name';
import { Resource } from 'labstep-web/models/resource.model';
import { fieldEntityName } from './fields';
import styles from './styles.module.scss';
import { IEntityPrimaryInfoProps } from './types';

export const convertToHeader = (children: React.ReactNode) => (
  <Header size="small" noMargin isDataPublic={false}>
    {children}
  </Header>
);

const EntityPrimaryInfo: React.FC<IEntityPrimaryInfoProps> = ({
  entity,
  prefix,
  icon,
  withPermaLink = true,
  name,
  disabled,
}) =>
  entity.entityName === 'resource' ? (
    <ResourceFormShowEditName resource={entity as Resource} />
  ) : (
    <Hoverable
      className={styles.container}
      hoverable={
        <>
          <Icon
            name={icon || ICONS[entity.entityName].primary}
            size="large"
          />
          {prefix && convertToHeader(prefix)}
          <ShowEditText
            entity={entity}
            entityName={entity.entityName}
            field={fieldEntityName}
            disabled={disabled}
          >
            {convertToHeader(name || entity.displayName)}
          </ShowEditText>
        </>
      }
      component={
        withPermaLink ? (
          <PermaLinkActionCopy entity={entity} />
        ) : undefined
      }
    />
  );

export default EntityPrimaryInfo;
