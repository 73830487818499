/**
 * Labstep
 *
 * @module components/ProtocolValue/Action/View
 * @desc Edit action for protocol value
 */

import ProtocolChildRouter from 'labstep-web/containers/Router/ProtocolChild';
import ActionComponent from 'labstep-web/core/Action/Component';
import React from 'react';
import { IProtocolValueActionViewProps } from './types';

export const ProtocolValueActionView: React.FC<
  IProtocolValueActionViewProps
> = ({ protocolValue, actionComponentProps }) => (
  <ProtocolChildRouter route="value_show" id={protocolValue.guid}>
    {({ navigate }) => (
      <ActionComponent
        type="option"
        text="Edit"
        icon="pencil"
        onClick={navigate}
        {...actionComponentProps}
      />
    )}
  </ProtocolChildRouter>
);

export default ProtocolValueActionView;
