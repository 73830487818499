/**
 * Labstep
 *
 * @module components/File/Action/Delete
 * @desc Action to delete file
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { IFileActionDeleteProps } from './types';

export const FileActionDelete: React.FC<IFileActionDeleteProps> = ({
  file,
}) =>
  file.parent_type === 'orphan' ? (
    <EntityActionDelete entity={file} />
  ) : null;

export default FileActionDelete;
