/**
 * Labstep
 *
 * @module components/EntityImport/Action/Create/Steps/ImportFileMapping/OptionWithPreview
 * @desc Option with Preview for EntityImport Create Step 2: ImportFileMapping
 */

import React from 'react';
import { components } from 'react-select';

export const EntityImportActionCreateStepsImportFileMappingOptionWithPreview: React.FC<
  React.ComponentProps<typeof components.Option>
> = (props) => {
  if (
    props.data &&
    typeof props.data === 'object' &&
    'preview' in props.data &&
    'label' in props.data
  ) {
    return (
      <components.Option {...props}>
        <strong>{props.data.label}</strong>
        <br />
        {props.data.preview}
      </components.Option>
    );
  }
  return <components.Option {...props} />;
};

export default EntityImportActionCreateStepsImportFileMappingOptionWithPreview;
