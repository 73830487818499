/**
 * Labstep
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import Button from 'labstep-web/core/Button';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import CoreCodeMirror from '..';
import styles from './styles.module.scss';
import {
  ICoreCodeMirrorModalProps,
  ICoreCodeMirrorModalState,
} from './types';

const languages = [
  { value: 'python', label: 'Python' },
  { value: 'r', label: 'R' },
  { value: 'clike', label: 'C, C++, C#' },
  { value: 'mathematica', label: 'Matlab' },
  { value: 'sas', label: 'Sas' },
];

class CoreCodeMirrorModal extends React.Component<
  ICoreCodeMirrorModalProps,
  ICoreCodeMirrorModalState
> {
  static defaultProps = {
    submitButtonText: 'Create',
  };

  constructor(props: ICoreCodeMirrorModalProps) {
    super(props);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    const mode = props.mode || 'python';
    const value = props.value || '';
    this.state = { mode, value };
  }

  handleLanguageChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ mode: event.target.value });
  }

  render() {
    const {
      onSubmit,
      status,
      viewComponent,
      submitButtonText,
      modalProps,
      ...rest
    } = this.props;
    return (
      <ModalDefault
        size="large"
        header="Code"
        content={({ toggleModal }) => (
          <div>
            <div className={styles.languageContainer}>
              <select
                value={this.state.mode}
                onChange={this.handleLanguageChange}
              >
                {languages.map((language) => (
                  <option key={language.value} value={language.value}>
                    {language.label}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.codeContainer}>
              <CoreCodeMirror
                onChange={(value) => this.setState({ value })}
                mode={this.state.mode}
                {...rest}
              />
            </div>
            <Button
              status={status}
              primary
              onClick={() =>
                onSubmit(
                  {
                    description: this.state.value,
                    type: this.state.mode,
                  },
                  toggleModal,
                )
              }
            >
              {submitButtonText}
            </Button>
          </div>
        )}
        viewComponent={({ toggleModal }) =>
          viewComponent ? (
            viewComponent({ toggleModal })
          ) : (
            <ActionComponent
              icon="code"
              text="Code Snippet"
              type="option"
              onClick={toggleModal}
            />
          )
        }
        {...modalProps}
      />
    );
  }
}

export default CoreCodeMirrorModal;
