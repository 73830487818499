/**
 * Labstep
 *
 * @author     Mira Davidson <mira.davidson@labstep.com>
 *
 * @module components/Layout/Alert/LegacyDomain
 * @desc Show toast when redirected from legacy Crick URL
 */

import React, { useEffect } from 'react';
import { withToast } from 'labstep-web/containers/Ui/Toast';
import { IWithToastInjectedProps } from 'labstep-web/containers/Ui/Toast/types';
import {
  CRICK_HOST_LEGACY,
  UKDRI_HOST_LEGACY_PRODUCTION,
} from 'labstep-web/screens/Organization/LegacyDomain/constants';

let displayed = false;

export const LayoutAlertLegacyDomain: React.FC<
  IWithToastInjectedProps
> = ({ showToast }) => {
  useEffect(() => {
    const referredLegacyDomain = [
      CRICK_HOST_LEGACY,
      UKDRI_HOST_LEGACY_PRODUCTION,
    ].find((domain) => document.referrer.includes(domain));
    if (referredLegacyDomain && !displayed) {
      showToast({
        type: 'info',
        message: (
          <>
            The{' '}
            <a href={`https://${referredLegacyDomain}`}>
              {referredLegacyDomain}
            </a>{' '}
            URL is deprecated and will be removed soon. Please update
            your links with{' '}
            <a href="https://app.labstep.com">app.labstep.com</a>.
          </>
        ),
        options: {
          header: 'Announcement',
          timeout: 10000,
        },
      });
      displayed = true;
    }
  }, []);

  return null;
};

export default withToast(LayoutAlertLegacyDomain);
