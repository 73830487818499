/**
 * Labstep
 */

import React from 'react';

export type childrenAsFunctionType = React.FC<{ match: boolean }>;

// eslint-disable-next-line no-shadow
export enum Size {
  phablet = 'phablet',
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
  large_desktop = 'large_desktop',
  mobile_tablet = 'mobile tablet',
  tablet_desktop = 'tablet desktop',
  small_desktop_desktop = 'small_desktop desktop',
}

export interface IMediaQueryProps {
  size: Size;
  children: childrenAsFunctionType | React.ReactNode;
}
