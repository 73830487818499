/**
 * Labstep
 *
 * @module components/ProtocolCollection/Action/Menu
 * @desc Menu for protocol collection
 */

import React from 'react';
import Delete from 'labstep-web/components/ProtocolCollection/Action/Delete';
import ActionMenu from 'labstep-web/core/Action/Menu';
import ProtocolActionDuplicate from 'labstep-web/components/Protocol/Action/Duplicate';
import ProtocolCollectionViewAction from 'labstep-web/components/ProtocolCollection/Action/View';
import ProtocolCollectionActionShare from 'labstep-web/components/ProtocolCollection/Action/Share';
import PrintAction from 'labstep-web/components/Protocol/Action/Print';
import AddToExperimentAction from 'labstep-web/components/Protocol/Action/AddToExperiment';
import EntityActionTransferOwnership from 'labstep-web/components/Entity/Action/TransferOwnership';
import EntityActionTag from 'labstep-web/components/Entity/Action/Tag';
import EntityActionRevoke from 'labstep-web/components/Entity/Action/Revoke';
import FolderActionAdd from 'labstep-web/components/Folder/Action/Add';
import FolderActionRemove from 'labstep-web/components/Folder/Action/Remove';
import EntityUserActionManage from 'labstep-web/components/EntityUser/Action/Manage';
import { Action, IProtocolCollectionActionMenuProps } from './types';

const ProtocolCollectionActionMenu: React.FC<
  IProtocolCollectionActionMenuProps
> = (props) => {
  const { protocolCollection, actions } = props;

  const protocol = protocolCollection.last_version;

  return (
    <ActionMenu type="icon">
      {actions.includes(Action.add_to_experiment) && (
        <AddToExperimentAction
          protocol={protocolCollection.last_version}
        />
      )}
      {actions.includes(Action.view) && (
        <ProtocolCollectionViewAction
          protocolCollection={protocolCollection}
        />
      )}
      {actions.includes(Action.share) && (
        <ProtocolCollectionActionShare
          protocolCollection={protocolCollection}
        />
      )}
      {actions.includes(Action.duplicate) && (
        <ProtocolActionDuplicate protocol={protocol} />
      )}
      {actions.includes(Action.add_to_folder) && (
        <FolderActionAdd parentEntity={protocolCollection} />
      )}
      {actions.includes(Action.remove_from_folder) && (
        <FolderActionRemove
          parentId={protocolCollection.id}
          parentName={protocolCollection.entityName}
        />
      )}
      {actions.includes(Action.print) && (
        <PrintAction protocol={protocol} />
      )}
      {actions.includes(Action.assign) && (
        <EntityUserActionManage entity={protocolCollection} />
      )}
      {actions.includes(Action.tag) && (
        <EntityActionTag parentEntity={protocolCollection} />
      )}
      {actions.includes(Action.revoke) && (
        <EntityActionRevoke entity={protocolCollection} />
      )}
      {actions.includes(Action.transfer_ownership) && (
        <EntityActionTransferOwnership entity={protocolCollection} />
      )}
      {actions.includes(Action.delete) && (
        <Delete protocolCollection={protocolCollection} />
      )}
    </ActionMenu>
  );
};

ProtocolCollectionActionMenu.defaultProps = {
  actions: [],
};

export default ProtocolCollectionActionMenu;
