/**
 * Labstep
 *
 * @module components/ProtocolValue/List
 * @desc Protocol Value list
 */

import EntityDraggable from 'labstep-web/components/Entity/Draggable';
import ProtocolValueItem from 'labstep-web/components/ProtocolValue/Item';
import List from 'labstep-web/core/List';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React from 'react';
import {
  IProtocolValueListContainerProps,
  IProtocolValueListProps,
} from './types';

export const ProtocolValueList: React.FC<IProtocolValueListProps> = ({
  protocolValues,
}) => (
  <List id="protocol-value-list">
    {protocolValues.map((value) => (
      <EntityDraggable
        key={value.id}
        entityId={value.id}
        entityGuid={value.guid}
        entityName={value.entityName}
      >
        <ProtocolValueItem protocolValue={value} />
      </EntityDraggable>
    ))}
  </List>
);

export const ProtocolValueListContainer: React.FC<
  IProtocolValueListContainerProps
> = ({ protocol }) => {
  const { entityName } = ProtocolValue;
  return (
    <ReadOnMountHOC
      type="cursor"
      entityName={entityName}
      params={{
        [protocol instanceof Experiment
          ? 'experiment_id'
          : 'protocol_id']: protocol.id,
        has_variable_template: false,
      }}
      children={({ entities: protocolValues }) => (
        <ProtocolValueList protocolValues={protocolValues} />
      )}
    />
  );
};

export default ProtocolValueList;
