/**
 * Labstep
 *
 * @module components/JupyterNotebook/Action/Run
 * @desc Run a jupyter notebook
 */

import { useHasAccessCreate } from 'labstep-web/components/Entity/Can';
import { withJupyterInstance } from 'labstep-web/containers/JupyterInstance';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import Loader from 'labstep-web/core/Loader';
import PopupContainer from 'labstep-web/core/Popup';
import { JupyterInstance } from 'labstep-web/models/jupyter-instance.model';
import React, { useEffect, useState } from 'react';
import { JupyterNotebookActionRunProps } from './types';

export const JupyterNotebookActionRun: React.FC<
  JupyterNotebookActionRunProps
> = ({
  jupyterNotebook,
  jupyterInstance,
  getJupyterInstance,
  postJupyterInstance,
}) => {
  const canCreate = useHasAccessCreate(
    JupyterInstance.entityName,
    jupyterNotebook.permission_entity_info?.entityName,
    jupyterNotebook.permission_entity_info?.id,
  );

  if (!jupyterNotebook.data || !canCreate) {
    return null;
  }

  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let intervalId: string | number | NodeJS.Timeout;

    if (isRunning) {
      intervalId = setInterval(() => {
        if (jupyterInstance?.run[jupyterNotebook.id]?.ended_at) {
          clearInterval(intervalId);
          setIsRunning(false);

          return;
        }

        getJupyterInstance('run', jupyterNotebook.id, true);
      }, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [jupyterInstance, getJupyterInstance]);

  if (isRunning) {
    return (
      <PopupContainer
        inverted
        size="mini"
        content="Running..."
        trigger={<Loader size="tiny" inline active />}
      />
    );
  }

  return (
    <ActionComponent
      type="icon"
      icon="play"
      elementProps={{
        popup: { content: 'Run Code' },
      }}
      onClick={() => {
        setIsRunning(true);
        postJupyterInstance('run', jupyterNotebook.id);
      }}
    />
  );
};

export default withJupyterInstance(JupyterNotebookActionRun);
