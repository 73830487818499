/**
 * Labstep
 *
 * @module components/EntityUser/Manager
 */

import EntityUserActionAdd from 'labstep-web/components/EntityUser/Action/Add';
import EntityUserTable from 'labstep-web/components/EntityUser/Table';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import React from 'react';
import { IEntityUserManagerProps } from './types';

const EntityUserManager: React.FC<IEntityUserManagerProps> = ({
  entity,
}) => {
  const hasSignatureFeatureEnabled = useHasFeatureFlagEnabled(
    'signature_workflow',
  );

  return (
    <div data-testid="entity-user-manager">
      <EntityUserActionAdd entity={entity} />
      <br />
      <ReadOnMountHOC
        type="cursor"
        pagination={{ loader: 'placeholder' }}
        entityName={EntityUser.entityName}
        params={{ [`${entity.entityName}_id`]: entity.id }}
        children={({ entities }) => (
          <EntityUserTable
            entity={entity}
            entityUsers={entities.filter(
              (e) => e.is_contributor || e.is_assigned,
            )}
            withEntityUserRole={hasSignatureFeatureEnabled}
          />
        )}
      />
    </div>
  );
};

export default EntityUserManager;
