/**
 * Labstep
 *
 * @module components/Layout/Alert/OfflineContent
 * @desc Component for offline warning
 */

import React, { useEffect, useState } from 'react';
import Alert from 'labstep-web/core/Alert';

const LayoutAlertOfflineContent: React.FC = () => {
  const [offline, setOffline] = useState(false);
  const [hide, setHide] = useState(false);

  /**
   * Due to the bug https://bugs.chromium.org/p/chromium/issues/detail?id=678075,
   * it's not reliable to detect if the browser is currently online or offline
   * based on `navigator.onLine`.
   * As a workaround, we always assume it's online on the first load, and change
   * the status upon `online` or `offline` events.
   *
   * Inspired by: https://github.com/vercel/swr/blob/main/_internal/src/utils/web-preset.ts
   */
  useEffect(() => {
    const onOnline = () => {
      setOffline(false);
    };
    const onOffline = () => {
      setOffline(true);
    };
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);
    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, []);

  const handleDismiss = () => {
    setHide(true);
  };

  if (!offline || hide) {
    return null;
  }
  return (
    <Alert color="red" centered noMargin onDismiss={handleDismiss}>
      Oh no, you&apos;ve lost internet connection! Please reconnect to
      the internet.
    </Alert>
  );
};

export default LayoutAlertOfflineContent;
