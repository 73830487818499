import { PremiumFeature } from 'labstep-web/models/organization.model';
import { PluginKey, Plugin } from 'prosemirror-state';

export const premiumFeaturesPluginKey = new PluginKey(
  'PREMIUM_FEATURES',
);

export const createPremiumFeaturesPlugin = (
  premiumFeatures: PremiumFeature[],
): Plugin =>
  new Plugin({
    key: premiumFeaturesPluginKey,
    state: {
      init: (): PremiumFeature[] => premiumFeatures,
      apply: (tr, prev): PremiumFeature[] => prev,
    },
  });
