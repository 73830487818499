/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/Code
 * @desc Button to insert a code node
 */

import React from 'react';
import { isSingleBlockSelectedAndAllowsContent } from 'labstep-web/prosemirror/utils';
import Dropdown from 'labstep-web/core/Dropdown';
import CodeMirror from 'labstep-web/core/CodeMirror/Modal';
import NodeCode from 'labstep-web/prosemirror/nodes/code';
import { ICodeToolbarProps } from './types';

const CodeToolbar: React.FC<ICodeToolbarProps> = ({
  dispatch,
  state,
}) => (
  <CodeMirror
    onSubmit={({ description: value, type: mode }, toggleModal) => {
      const { tr } = state;
      const content = state.schema.nodes[NodeCode.key].create({
        mode,
        value,
      });
      tr.insert(state.selection.$from.pos, content);
      dispatch(tr);
      toggleModal();
    }}
    status={null}
    viewComponent={({ toggleModal }) => (
      <Dropdown.Item
        onClick={toggleModal}
        disabled={!isSingleBlockSelectedAndAllowsContent(state)}
        icon="code"
        text="Code Snippet"
      />
    )}
  />
);

export default CodeToolbar;
