/**
 * Labstep
 *
 * @module models/resource
 * @desc Typescript export class for Resource
 */

import { Type } from 'class-transformer';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import { Entity } from 'labstep-web/models/entity.model';
import { File as FileModel } from 'labstep-web/models/file.model';
import { Group } from 'labstep-web/models/group.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import { Molecule } from 'labstep-web/models/molecule.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { PermaLink } from 'labstep-web/models/perma-link.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Thread } from 'labstep-web/models/thread.model';
import { User } from 'labstep-web/models/user.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { PermissionActions } from 'labstep-web/typings';
import { CustomIdentifierSet } from './custom-identifier-set.model';
import { ResourceLocation } from './resource-location.model';

export type ResourceEntityNameType = 'resource';

export class Resource extends Entity {
  public static readonly entityName: ResourceEntityNameType =
    'resource';

  public static readonly templateEntityName = 'resource_template';

  public readonly defaultImageSrc =
    '/img/resource-category/default.svg';

  public get entityName(): typeof Resource.entityName {
    return Resource.entityName;
  }

  public constructor(data: Partial<Resource> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public name!: string | null;

  public is_template!: boolean;

  @Type(() => Group)
  public owner!: Group;

  public resource_item_count!: number;

  public available_resource_item_count!: number;

  public available_resource_item_count_alert_threshold!:
    | number
    | null;

  public entity_users_count!: number;

  /** Present only while searching for molecule structure */
  public molecule_structure?: string[];

  public metadata_molecule_svg!: string[];

  public allowed_actions!: PermissionActions[];

  public custom_identifier!: string;

  @Type(() => Molecule)
  public molecule?: Molecule;

  @Type(() => ResourceItem)
  public resource_item_template?: ResourceItem;

  @Type(() => OrderRequest)
  public order_request_template?: OrderRequest;

  @Type(() => OrderRequest)
  public order_request?: OrderRequest;

  @Type(() => FileModel)
  public image?: FileModel;

  @Type(() => User)
  public author!: User;

  @Type(() => Thread)
  public thread?: Thread;

  @Type(() => MetadataThread)
  public metadata_thread!: MetadataThread;

  @Type(() => PermaLink)
  public perma_link!: PermaLink;

  @Type(() => Resource)
  public template!: Resource | null;

  @Type(() => EntityUser)
  public entity_users_preview!: EntityUser[];

  @Type(() => CustomIdentifierSet)
  public _custom_identifier_set?: CustomIdentifierSet;

  public get displayName(): string {
    if (this.custom_identifier) {
      return this.name
        ? `${this.custom_identifier} - ${this.name}`
        : this.custom_identifier;
    }
    return (
      this.name ||
      `Untitled ${getHumanReadableEntityName(
        this.entityName,
        false,
        true,
      )}`
    );
  }

  public get custom_identifier_set(): CustomIdentifierSet | null {
    return this._custom_identifier_set?.deleted_at ||
      !this._custom_identifier_set
      ? null
      : this._custom_identifier_set;
  }

  public set custom_identifier_set(
    customIdentifierSet: CustomIdentifierSet,
  ) {
    this._custom_identifier_set = customIdentifierSet;
  }

  public get hasNonDeletedResourceItemTemplate(): boolean {
    return (
      !!this.resource_item_template &&
      !this.resource_item_template.deleted_at
    );
  }

  public get nonDeletedTemplate(): Resource | null {
    if (this.template && !this.template.deleted_at) {
      return this.template;
    }
    return null;
  }

  public get hasNonDeletedOrderRequestTemplate(): boolean {
    return (
      !!this.order_request_template &&
      !this.order_request_template.deleted_at
    );
  }

  public get hasTemplateWithNonDeletedResourceItemTemplate(): boolean {
    return (
      !!this.template?.resource_item_template &&
      !this.template.resource_item_template.deleted_at
    );
  }

  public get templateMetadatas(): Metadata[] {
    if (this.hasNonDeletedResourceItemTemplate) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.resource_item_template!.metadata_thread.metadatas;
    }

    if (this.hasTemplateWithNonDeletedResourceItemTemplate) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.template!.resource_item_template!.metadata_thread
        .metadatas;
    }

    return [];
  }

  /**
   * Checks if the resource has any metadatas that are required
   * but do not have a value.
   */
  public get requiredMetadatasWithoutValue(): Metadata[] {
    const requiredMetadatas = this.metadata_thread.metadatas.filter(
      (metadata) =>
        metadata.is_required &&
        !metadata.template_id &&
        !metadata.hasValue,
    );

    if (this.nonDeletedTemplate) {
      requiredMetadatas.push(
        ...this.nonDeletedTemplate.metadata_thread.metadatas.filter(
          (metadata) =>
            metadata.is_required &&
            !this.metadata_thread.metadatas.find(
              (m) => m.template_id === metadata.id,
            )?.hasValue,
        ),
      );
    }
    return requiredMetadatas;
  }

  /*

  The location to use for new items of this resource.
  Returns the location of either the custom item template (if defined)
  or the category item template (if defined)

  */

  public get itemTemplateLocation():
    | ResourceLocation
    | null
    | undefined {
    if (this.hasNonDeletedResourceItemTemplate) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.resource_item_template!.resource_location;
    }

    if (this.hasTemplateWithNonDeletedResourceItemTemplate) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.template!.resource_item_template!.resource_location;
    }

    return null;
  }

  public static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
    isTemplate?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
      isTemplate,
    );
  }
}
