/**
 * Labstep
 *
 * @module models/thread
 * @desc Typescript export class for Thread
 */

import { Type } from 'class-transformer';
import { Comment as CommentModel } from 'labstep-web/models/comment.model';
import { Metadata } from 'labstep-web/models/metadata';
import { Protocol } from 'labstep-web/models/protocol.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Entity } from 'labstep-web/models/entity.model';
import { ProtocolStep } from './protocol-step.model';

export class Thread extends Entity {
  static readonly entityName = 'thread';

  get entityName(): string {
    return Thread.entityName;
  }

  constructor(data: Partial<Thread> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  comment_count!: number;

  @Type(() => CommentModel)
  comments!: CommentModel[];

  @Type(() => ExperimentWorkflow)
  experiment_workflow!: ExperimentWorkflow;

  @Type(() => ProtocolStep)
  protocol_step!: ProtocolStep;

  @Type(() => Experiment)
  experiment!: Experiment;

  @Type(() => Protocol)
  protocol!: Protocol;

  @Type(() => Metadata)
  metadata!: Metadata;
}
