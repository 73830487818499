/**
 * Labstep
 *
 * @module components/Filter/Active/WithSort
 * @desc Container for active filter and sort
 */

import SortOptionsActive from 'labstep-web/components/Entity/Table/SortOptions/Active';
import ActiveFilter from 'labstep-web/components/Filter/Active';
import ActionComponent from 'labstep-web/core/Action/Component';
import { useParamsContext } from 'labstep-web/hoc/Params/context';
import React from 'react';
import { getHasActiveFilter } from '../utils';
import styles from './styles.module.scss';
import { IFilterActiveWithSortProps } from './types';

export const FilterActiveWithSort: React.FC<
  IFilterActiveWithSortProps
> = ({ sortOptions, defaultSortOptions }) => {
  const {
    setParams,
    clearAll,
    searchParams,
    clearParams,
    setPostFilterType,
    removePostFilter,
  } = useParamsContext();
  return (
    <div className={styles.container}>
      <ActiveFilter
        setParams={setParams}
        searchParams={searchParams}
        clearParams={clearParams}
        setPostFilterType={setPostFilterType}
        removePostFilter={removePostFilter}
      />
      {sortOptions && (
        <SortOptionsActive
          searchParams={searchParams}
          clearParams={clearParams}
          sortOptions={sortOptions}
          setParams={setParams}
          defaultSortOptions={defaultSortOptions}
        />
      )}
      {getHasActiveFilter(searchParams) ? (
        <ActionComponent
          type="text"
          text="Clear all"
          onClick={(): void => {
            clearAll(
              [
                'folder_id',
                'folder_group_not_id',
                'template_id',
                'sort',
              ],
              true,
            );
          }}
        />
      ) : null}
    </div>
  );
};

export default FilterActiveWithSort;
