/**
 * Labstep
 */

import Checkbox from 'labstep-web/core/Form/ReactForm/Checkbox';
import DateTimePickerField from 'labstep-web/core/Form/ReactForm/DateTimePicker';
import FileField from 'labstep-web/core/Form/ReactForm/File';
import ProseMirrorHtml from 'labstep-web/core/Form/ReactForm/ProseMirrorHtml';
import ReactSelect from 'labstep-web/core/Form/ReactForm/ReactSelect';
import SearchSelect from 'labstep-web/core/Form/ReactForm/SearchSelect';
import SelectMulti from 'labstep-web/core/Form/ReactForm/SelectMulti';
import SelectOptions from 'labstep-web/core/Form/ReactForm/SelectOptions';
import Text from 'labstep-web/core/Form/ReactForm/Text';
import TextArea from 'labstep-web/core/Form/ReactForm/TextArea';

export const fieldTypes = {
  Text,
  ProseMirrorHtml,
  ReactSelect,
  TextArea,
  SearchSelect,
  SelectMulti,
  SelectOptions,
  DateTimePicker: DateTimePickerField,
  File: FileField,
  Checkbox,
};
