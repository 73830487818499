/**
 * Labstep
 *
 * @module components/Signature/SignatureCardToBeAssigned
 * @desc Signature card for assigning signatures
 */

import EntityUserActionAdd from 'labstep-web/components/EntityUser/Action/Add';
import EntityUserActionMenu from 'labstep-web/components/EntityUser/Action/Menu';
import { Action } from 'labstep-web/components/EntityUser/Action/Menu/types';
import ProfilePictureImage from 'labstep-web/components/Profile/Picture/Image';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import Card from 'labstep-web/core/Card/Generic';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import { Size } from 'labstep-web/core/Initials/types';
import { SignatureRequirement } from 'labstep-web/models/signature-requirement.model';
import React from 'react';
import SignatureActionCreate from '../../Action/Create';
import styles from './styles.module.scss';
import { ISignatureCardToBeAssignedProps } from './types';

export const SignatureCardToBeAssigned: React.FC<
  ISignatureCardToBeAssignedProps
> = ({
  experimentWorkflow,
  authenticatedUserEntity,
  assignee,
  entityUserRole,
  entityState,
  entityUser,
}) => {
  const currentEntityState = experimentWorkflow.entity_state;

  let action: JSX.Element | null = null;

  if (currentEntityState.id === entityState.id) {
    if (authenticatedUserEntity.id === assignee?.id) {
      action = (
        <SignatureActionCreate
          experimentWorkflow={experimentWorkflow}
          actionComponentProps={{ text: 'Sign', type: 'button' }}
          body={{
            signature_requirement_id:
              (
                entityState.entity_user_role_requirements.find(
                  (e) => e.entity_user_role.id === entityUserRole.id,
                )?.signature_requirement as SignatureRequirement
              )?.id || null,
          }}
        />
      );
    } else {
      experimentWorkflow.signature_requests
        .filter((s) => !s.deleted_at)
        .find((s) => s.user.id === assignee?.id);
      action = (
        <div>
          <p>Awaiting Signature</p>
        </div>
      );
    }
  }

  return (
    <div data-testid="assigned-signature-card">
      <Card
        className={
          assignee ? styles.cardAssigned : styles.cardUnassigned
        }
        headerSuper={
          <Flex
            spacing="between"
            vAlignContent="center"
            data-testid="to-be-assigned-content"
          >
            <Flex
              spacing="gap"
              vAlignContent="center"
              hAlignContent="left"
            >
              <Icon name="edit" size="large" disabled />
              {assignee ? (
                <ProfilePictureImage
                  entity={assignee}
                  size={Size.mini}
                />
              ) : (
                <Flex
                  vAlignContent="center"
                  hAlignContent="center"
                  className={styles.emptyProfile}
                  disabled
                >
                  <Icon name="circle" size="large" disabled />
                </Flex>
              )}

              <Flex column>
                <span>{entityUserRole.name}</span>
                <span className={styles.name}>{assignee?.name}</span>
                {!assignee && (
                  <span className={styles.description}>
                    {entityUserRole.description}
                  </span>
                )}
              </Flex>
            </Flex>
            {assignee ? (
              <Flex>
                {action}
                {entityUser && (
                  <EntityUserActionMenu
                    entity={experimentWorkflow}
                    entityUser={entityUser}
                    actions={[Action.toggle_assign]}
                  />
                )}
              </Flex>
            ) : (
              <EntityUserActionAdd
                entity={experimentWorkflow}
                entityUserRole={entityUserRole}
              />
            )}
          </Flex>
        }
      />
    </div>
  );
};

export default withAuthenticatedUserEntity(SignatureCardToBeAssigned);
