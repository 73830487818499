/**
 * Labstep
 *
 * @module containers/Entity/Update
 * @desc Container for updating an entity
 */

import { updateEntity } from 'labstep-web/state/actions/entity';
import {
  selectEntity,
  selectUpdateEntityStatus,
} from 'labstep-web/state/selectors/entity';
import { LabstepReduxState } from 'labstep-web/state/types';
import { IOptions } from 'labstep-web/typings';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IEntityUpdateContainerProps, OwnProps } from './types';

export type EntityUpdateContainerChildrenProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

const Container = ({ children, ...rest }: any) => children(rest);

const mapStateToProps = (
  state: LabstepReduxState,
  ownProps: OwnProps,
) => {
  if (ownProps.batch || !ownProps.id) {
    return {};
  }
  return {
    entity: selectEntity(state, ownProps.entityName, ownProps.id),
    status: selectUpdateEntityStatus(
      state,
      ownProps.entityName,
      ownProps.id,
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps,
) => ({
  update(
    body: Parameters<typeof updateEntity>[2],
    options: IOptions,
  ) {
    dispatch(
      updateEntity(ownProps.entityName, ownProps.id, body, {
        batch: ownProps.batch,
        optimistic: ownProps.optimistic,
        ...options,
      }),
    );
  },
});

export const EntityUpdateContainer: React.FC<IEntityUpdateContainerProps> =
  connect(mapStateToProps, mapDispatchToProps)(Container);
