/**
 * Labstep
 *
 * @module components/Resource/Info/Preview
 * @desc Preview for resource
 */

import React from 'react';
import Flex from 'labstep-web/core/Flex';
import MetadataListPreview from 'labstep-web/components/Metadata/Preview';
import ResourcePreview from 'labstep-web/components/Resource/Preview';
import { IResourceInfoPreviewProps } from './types';
import styles from './styles.module.scss';

const ResourceInfoPreview: React.FC<IResourceInfoPreviewProps> = ({
  resource,
  showMetadata,
  showIcon,
}) => (
  <Flex grow={1} column>
    <div className={styles.info}>
      <ResourcePreview resource={resource} showIcon={showIcon} />
      {showMetadata && resource.metadata_thread && (
        <MetadataListPreview entity={resource} withIcon />
      )}
    </div>
  </Flex>
);

export default ResourceInfoPreview;
