/**
 * Labstep
 *
 * @module components/ResourceTemplate/Preview
 * @desc Preview for resource template
 */

import React from 'react';
import EntityPreview from 'labstep-web/components/Entity/Preview';
import ResourceTemplateLayoutLinks from 'labstep-web/components/ResourceTemplate/LayoutLinks';
import { IResourceTemplatePreviewProps } from './types';

const ResourceTemplatePreview: React.FC<
  IResourceTemplatePreviewProps
> = ({ resource, ...rest }) => (
  <EntityPreview
    entity={resource}
    bottom={
      <ResourceTemplateLayoutLinks
        resourceTemplate={resource}
        withRoute={false}
      />
    }
    {...rest}
  />
);

export default ResourceTemplatePreview;
