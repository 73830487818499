/**
 * Labstep
 *
 * @module components/Protocol/Action/Print
 * @desc Action to print a protocol
 */

import React from 'react';
import PrintAction from 'labstep-web/components/Entity/Action/Print';
import { IProtocolPrintActionProps } from './types';

export const ProtocolPrintAction: React.FC<
  IProtocolPrintActionProps
> = ({ protocol }) => <PrintAction entity={protocol} />;

export default ProtocolPrintAction;
