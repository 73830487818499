/**
 * Labstep
 *
 * @module components/File/TypeImage
 * @desc An image showing the file type
 */

import React from 'react';
import Image from 'labstep-web/core/Image';
import { IFileTypeImageProps } from './types';

const FileTypeImage: React.FC<IFileTypeImageProps> = ({
  file,
  size,
}) => <Image src={file.iconPath} size={size} />;

FileTypeImage.defaultProps = {
  size: 'small',
};

export default FileTypeImage;
