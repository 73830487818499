/**
 * Labstep
 *
 * @module models/profile
 * @desc Typescript export class for Profile
 */

import { Type } from 'class-transformer';
import { File as FileModel } from 'labstep-web/models/file.model';
import { ResearchArea } from 'labstep-web/models/research-area.model';
import { Entity } from 'labstep-web/models/entity.model';

export class Profile extends Entity {
  static readonly entityName = 'profile';

  get entityName(): string {
    return Profile.entityName;
  }

  constructor(data: Partial<Profile> = {}) {
    super();
    Object.assign(this, data);
  }

  id: number;

  about?: string;

  @Type(() => FileModel)
  photo: FileModel;

  @Type(() => ResearchArea)
  research_areas: ResearchArea[];
}
