/**
 * Labstep
 *
 * @module components/Layout/Container/ActionButtons
 * @desc Container for action buttons
 */

import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { ILayoutContainerActionButtonsProps } from './types';

export const LayoutContainerActionButtons: React.FC<
  ILayoutContainerActionButtonsProps
> = ({ children, className }) => (
  <div className={cx(styles.container, className)}>{children}</div>
);

export default LayoutContainerActionButtons;
