/**
 * Labstep
 *
 * @module screens/OrderRequest/Show/Center
 * @desc OrderRequest Show Center
 */

import EntityUserCard from 'labstep-web/components/EntityUser/Card';
import OrderRequestActionMenu from 'labstep-web/components/OrderRequest/Action/Menu';
import { Action } from 'labstep-web/components/OrderRequest/Action/Menu/types';
import PermaLinkActionCopy from 'labstep-web/components/PermaLink/Action/Copy';
import { ICONS } from 'labstep-web/constants/icons';
import InformationCard from 'labstep-web/core/Card/Information';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import OrderRequestShowCenterSecondaryInfo from './SecondaryInfo';
import { IScreensOrderRequestShowCenterProps } from './types';

const ScreensOrderRequestShowCenter: React.FC<
  IScreensOrderRequestShowCenterProps
> = ({ orderRequest }) => (
  <InformationCard
    additionalActions={<EntityUserCard entity={orderRequest} />}
    actionMenu={
      <OrderRequestActionMenu
        orderRequest={orderRequest}
        actions={[
          Action.tag,
          Action.duplicate,
          Action.share,
          Action.delete,
          Action.transfer_ownership,
          Action.set_purchase_order,
        ]}
      />
    }
    primaryInfo={
      <Flex vAlignContent="center">
        <Header size="small" noMargin>
          <Icon name={ICONS.order_request.primary} />
          <span>Request for {orderRequest.resource.displayName}</span>
        </Header>
        <PermaLinkActionCopy entity={orderRequest} />
      </Flex>
    }
    secondaryInfo={
      <OrderRequestShowCenterSecondaryInfo
        orderRequest={orderRequest}
        showStatusLog
      />
    }
  />
);

export default ScreensOrderRequestShowCenter;
