/**
 * Labstep
 *
 * @module containers/User/Register/Internal
 * @desc Register container
 */

import {
  clearLoginError,
  internalRegisterAction,
} from 'labstep-web/state/actions/user';
import { selectLoginStatus } from 'labstep-web/state/selectors/authenticated-user';
import { connect } from 'react-redux';

export type UserRegisterInternalContainerChildrenProps = ReturnType<
  typeof mapDispatchToProps
> &
  ReturnType<typeof mapStateToProps>;

const Container = ({
  children,
  ...rest
}: UserRegisterInternalContainerChildrenProps & {
  children: React.FC<
    Omit<UserRegisterInternalContainerChildrenProps, 'children'>
  >;
}) => children(rest);

const mapStateToProps = (state: any) => ({
  status: selectLoginStatus(state),
});

const mapDispatchToProps = (dispatch: (...args: any[]) => void) => ({
  registerInternal: (userData: any, { onSuccess, onFail }: any) => {
    dispatch(internalRegisterAction({ userData, onSuccess, onFail }));
  },
  clearLoginError: () => {
    dispatch(clearLoginError());
  },
});

export const UserRegisterInternalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
