/**
 * Labstep
 *
 * @module components/Entity/Action/Disable/BulkUsers
 * @desc Disable
 */

import React from 'react';
import {
  capitalize,
  getHumanReadableEntityName,
} from 'labstep-web/services/i18n.service';
import ActionComponentBulk from 'labstep-web/core/Action/Component/Bulk';
import { User } from 'labstep-web/models/user.model';
import { EntityActionDeleteComponent } from '../../Delete';
import { IEntityActionDisableBulkUsersProps } from './types';

export const EntityActionDisableBulkUsers: React.FC<
  IEntityActionDisableBulkUsersProps
> = ({ entities, options }) => {
  const name = getHumanReadableEntityName(User.entityName, true);
  const users = entities.map((e) => e.user.id);

  return (
    <EntityActionDeleteComponent
      entityName={User.entityName}
      id={users}
      message={`Are you sure you want to disable these ${name}?`}
      header={`Disable ${capitalize(name)}`}
      isActive
      confirmButtonContent="Disable"
      actionComponentProps={{
        type: 'children',
        children: ({ onClick }) => (
          <ActionComponentBulk
            entitiesWithPermissionCount={users.length}
            entitiesSelectedCount={entities.length}
            content="Disable users"
            icon="ban"
            onClick={onClick}
          />
        ),
      }}
      options={options}
    />
  );
};
