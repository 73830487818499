/**
 * Labstep
 *
 * @module components/Entity/ActionView
 * @desc Core view action
 */

import React from 'react';
import { navigation } from 'labstep-web/services/navigation';
import ActionComponent from 'labstep-web/core/Action/Component';
import { Link } from 'react-router-dom';
import { IEntityActionViewAllProps } from './types';

export const EntityActionViewAll: React.FC<
  IEntityActionViewAllProps
> = ({ entityName, params, actionComponentProps }) => {
  const pathname = navigation.get(`${entityName}_index`, params);

  return (
    <ActionComponent
      type="option"
      text="View All"
      elementProps={{ as: Link, to: { pathname } }}
      icon="eye"
      {...actionComponentProps}
    />
  );
};

export default EntityActionViewAll;
