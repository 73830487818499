/**
 * Labstep
 *
 * @module components/ProtocolValue/Action/Delete
 * @desc Action to delete
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { IProtocolValueDeleteActionProps } from './types';

export const ProtocolValueDeleteAction: React.FC<
  IProtocolValueDeleteActionProps
> = ({ protocolValue, ...rest }) => (
  <EntityActionDelete {...rest} entity={protocolValue} />
);

export default ProtocolValueDeleteAction;
