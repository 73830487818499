/**
 * Labstep
 *
 * @module components/Comment/Action/CreateFile
 * @desc Reply bar for comments
 */

import React from 'react';
import FileActionCreate from 'labstep-web/components/File/Action/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import Dropdown from 'labstep-web/core/Dropdown';
import { ICommentCreateFileProps } from './types';

export const CommentCreateFile: React.FC<ICommentCreateFileProps> = ({
  create,
  showOnly,
}) => (
  <Dropdown.Item className="no-padding">
    <FileActionCreate
      orphan
      multiple
      showOnly={showOnly}
      options={{
        onSuccess: ({ response }) =>
          create({ file_id: [response.result] }),
      }}
    >
      {({ toggleModal }) => (
        <div>
          <ActionComponent
            onClick={toggleModal}
            type="option"
            icon={showOnly === 'cloud' ? 'cloud' : 'computer'}
            text={
              showOnly === 'cloud'
                ? 'From Cloud Provider'
                : 'From Device / Camera'
            }
          />
        </div>
      )}
    </FileActionCreate>
  </Dropdown.Item>
);

export default CommentCreateFile;
