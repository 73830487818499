import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import { getSelectedText } from 'labstep-web/prosemirror/utils';
import { TextSelection } from 'prosemirror-state';
import {
  Comment,
  CommentActionTypeDelete,
  CommentActionTypeNew,
  commentPluginKey,
} from '../plugins';

// Command for adding comment
export const addComment = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
  id: string | number,
) => {
  const sel = state.selection;
  if (sel.empty) {
    return false;
  }
  if (dispatch) {
    const text = getSelectedText(state);
    dispatch(
      state.tr
        .setMeta(commentPluginKey, {
          type: CommentActionTypeNew,
          from: sel.from,
          to: sel.to,
          comment: new Comment(text, id),
        })
        .setSelection(new TextSelection(sel.$from, sel.$from)),
    );
  }
  return true;
};

export const deleteComment = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
  comment: Comment,
) => {
  if (dispatch) {
    dispatch(
      state.tr.setMeta(commentPluginKey, {
        type: CommentActionTypeDelete,
        comment,
      }),
    );
  }
  return true;
};
