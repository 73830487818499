/**
 * Labstep
 *
 * @module core/DataGrid/CellEditor
 * @desc Generic CellEditor parent component
 */

import { Entity } from 'labstep-web/models/entity.model';
import { ParentEntityType } from 'labstep-web/core/Form/ShowEdit/SearchSelect/types';
import React from 'react';
import { ICellEditorSearchSelectProps } from './SearchSelect/types';
import DataGridCellEditorSearchSelect from './SearchSelect';
import { ICellEditorFileProps } from './File/types';
import DataGridCellEditorFile from './File';
import DataGridCellEditorShowEditOptions from './ShowEdit/Options';
import { ICellEditorShowEditOptionsProps } from './ShowEdit/Options/types';
import { ICellEditorDateProps } from './Date/types';
import DataGridCellEditorDate from './Date';
import DataGridCellEditorSearchSelectModal from './SearchSelect/Modal';
import { ICellEditorSearchSelectModalProps } from './SearchSelect/Modal/types';
import { ICellEditorSelectProps } from './Select/types';
import DataGridCellEditorSelect from './Select';

class DataGridCellEditor extends React.Component {
  public static Date: React.FC<ICellEditorDateProps<Entity>>;

  public static File: React.FC<ICellEditorFileProps<Entity>>;

  public static SearchSelect: React.FC<
    ICellEditorSearchSelectProps<Entity>
  >;

  public static Select: React.FC<ICellEditorSelectProps<Entity>>;

  public static SearchSelectModal: React.FC<
    ICellEditorSearchSelectModalProps<ParentEntityType>
  >;

  public static ShowEditOptions: React.FC<
    ICellEditorShowEditOptionsProps<Entity>
  >;
}

DataGridCellEditor.Date = DataGridCellEditorDate;
DataGridCellEditor.File = DataGridCellEditorFile;
DataGridCellEditor.SearchSelect = DataGridCellEditorSearchSelect;
DataGridCellEditor.Select = DataGridCellEditorSelect;
DataGridCellEditor.SearchSelectModal =
  DataGridCellEditorSearchSelectModal;
DataGridCellEditor.ShowEditOptions =
  DataGridCellEditorShowEditOptions;

export default DataGridCellEditor;
