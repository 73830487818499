/**
 * Labstep
 *
 * @module Signature/Form/Create
 * @desc Signature Create Form
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { fieldSignatureStatement } from 'labstep-web/components/Signature/Form/fields';
import { fieldUserCode } from 'labstep-web/components/User/Form/fields';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import Button from 'labstep-web/core/Button';
import FormCreate from 'labstep-web/core/Form/Create';
import { IFieldProps } from 'labstep-web/core/Form/Reusable/types';
import Icon from 'labstep-web/core/Icon';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Signature } from 'labstep-web/models/signature.model';
import React from 'react';
import { SignatureTypeAction } from '../../SignAndRejectButtons/types';
import { getDefaultStatement } from '../CreateSignReject/utils';
import { ISignatureFormCreateProps } from './types';

export const SignatureFormCreate: React.FC<
  ISignatureFormCreateProps
> = ({ experimentWorkflow, options, activeGroup, body = {} }) => {
  let fields: IFieldProps[] = [fieldSignatureStatement];
  if (experimentWorkflow.owner.is_signature_two_factor_required) {
    fields = [...fields, fieldUserCode];
  }
  const defaultSignatureStatement = getDefaultStatement(
    SignatureTypeAction.sign,
    experimentWorkflow.entity_state?.entity_user_role_requirements[0]
      ?.signature_requirement,
    activeGroup,
  );
  return (
    <FormCreate
      autoFocus
      fields={fields}
      entityName={Signature.entityName}
      parentName={ExperimentWorkflow.entityName}
      defaultValues={{
        ...body,
        is_lock: !!experimentWorkflow.entity_state_workflow,
        [fieldSignatureStatement.name]: defaultSignatureStatement,
      }}
      parentId={experimentWorkflow.id}
      options={options}
      submitButton={({ submitForm, status, setValue }) => (
        <div>
          <Can
            entityName={experimentWorkflow.entityName}
            id={experimentWorkflow.id}
            action={Action.lock}
          >
            {!experimentWorkflow.locked_at &&
              !experimentWorkflow.entity_state_workflow && (
                <Button
                  primary
                  basic
                  type="button"
                  status={status}
                  onClick={() => {
                    setValue('is_lock', true);
                    submitForm();
                  }}
                >
                  <Icon name={ICONS.signature.actions.lock} />
                  Sign and Lock
                </Button>
              )}
          </Can>
          <Button
            status={status}
            type="button"
            primary
            onClick={submitForm}
          >
            Sign
          </Button>
        </div>
      )}
    />
  );
};

export default withActiveGroup(SignatureFormCreate);
