/**
 * Labstep
 *
 * @module components/DataTable/Action/Edit
 * @desc Edit a comment table
 */

import React from 'react';
import TableModal from 'labstep-web/components/Table/Modal';
import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import { IDataTableEditActionProps } from './types';

export const DataTableEditAction: React.FC<
  IDataTableEditActionProps
> = ({ comment }) => (
  <TableModal
    table={comment.data_table}
    allowLocking
    viewComponent={({ toggleModal }) => (
      <EntityActionEdit
        entity={comment}
        actionComponentProps={{
          type: 'option',
          onClick: toggleModal,
        }}
      />
    )}
  />
);

export default DataTableEditAction;
