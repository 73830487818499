/**
 * Labstep
 *
 * @module services/google
 * @desc Google Service
 */

import { GoogleError } from 'labstep-web/errors/google-error';
import { WindowService, windowService } from './window.service';

class GoogleService {
  windowService: WindowService;

  constructor(windowServiceInstance: WindowService) {
    this.windowService = windowServiceInstance;
  }

  logout() {
    return new Promise((resolve) => {
      const googleAuth =
        this.windowService.gapi && this.windowService.gapi.auth2;
      if (!googleAuth) {
        throw new GoogleError('Google API is not available');
      }

      const auth2 = this.windowService.gapi.auth2.getAuthInstance();
      if (!auth2) {
        throw new GoogleError(
          'Google API Auth2 could not provide an auth instance',
        );
      }

      const signOutPromise = auth2.signOut();

      signOutPromise.then(
        () => {
          resolve({});
        },
        (err: Error) => {
          throw new GoogleError(
            `Something went wrong during the logout (${err.name})`,
          );
        },
      );
    });
  }
}

export const googleService = new GoogleService(windowService);
