/**
 * Labstep
 *
 * @module models/entity-export
 * @desc Typescript export class for EntityExport
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { Group } from 'labstep-web/models/group.model';
import { LogEntry } from 'labstep-web/models/log-entry.model';
import { User } from 'labstep-web/models/user.model';
import { UAParser } from 'labstep-web/services/parser/user-agent';

export class EntityExport extends Entity {
  static readonly entityName = 'entity_export';

  get entityName(): string {
    return EntityExport.entityName;
  }

  static TYPE_CUSTOM = 'custom';

  static TYPE_HTML = 'html';

  static TYPE_HTML_FILE = 'html_file';

  static TYPE_PRINT = 'print';

  static TYPE_ZIP = 'zip';

  static TYPE_CSV = 'csv';

  static TYPE_FILE_DOWNLOAD = 'file_download';

  constructor(data: Partial<EntityExport> = {}) {
    super();
    Object.assign(this, data);
  }

  created_at!: string;

  type!: string;

  query_entity_name!: string;

  @Type(() => LogEntry)
  log_entry_create!: LogEntry;

  @Type(() => User)
  author!: User;

  @Type(() => Group)
  owner!: Group;

  get userAgentObject(): UAParser.IResult {
    const parser = new UAParser();

    parser.setUA(this.log_entry_create.client_user_agent);

    return parser.getResult();
  }

  get browser(): UAParser.IBrowser {
    return this.userAgentObject.browser;
  }

  get device(): UAParser.IDevice {
    return this.userAgentObject.device;
  }

  get os(): UAParser.IOS {
    return this.userAgentObject.os;
  }

  get printDeviceType(): string {
    const { device } = this.userAgentObject;
    return device.type || 'desktop';
  }
}
