/**
 * Labstep
 *
 * @module components/ResourceLocation/Card/EmptyState
 * @desc Empty state for ResourceLocation
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import ResourceLocationActionCreate from 'labstep-web/components/ResourceLocation/Action/Create';
import ResourceLocationActionCreateSub from 'labstep-web/components/ResourceLocation/Action/CreateSub';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { IResourceSubLocationEmptyStateProps } from './types';

export const ResourceSubLocationEmptyState: React.FC<
  IResourceSubLocationEmptyStateProps
> = (props) => (
  <EmptyState
    src="/img/empty-state/locations.svg"
    title="No sub-locations"
    explanation="Add sub-locations to allow more granular specification of item locations."
    action={<ResourceLocationActionCreateSub {...props} />}
  />
);

export const ResourceLocationEmptyState: React.FC = () => (
  <EmptyState
    src="/img/empty-state/locations.svg"
    title={`No ${ResourceLocation.getHumanReadableEntityName(
      true,
      true,
    )}`}
    explanation="Use resource locations to manage the storage locations used by this workspace. You can use these to indicate the location of your resource items in your inventory."
    action={<ResourceLocationActionCreate />}
  />
);

export default ResourceLocationEmptyState;
