/**
 * Labstep
 *
 * @module components/ResourceItem/Action/Delete
 * @desc Action to delete
 */

import React from 'react';
import DeleteAction from 'labstep-web/components/Entity/Action/Delete';
import { IResourceItemDeleteActionProps } from './types';

export const ResourceItemDeleteAction: React.FC<
  IResourceItemDeleteActionProps
> = ({ resourceItem, ...rest }) => (
  <DeleteAction {...rest} entity={resourceItem} />
);

export default ResourceItemDeleteAction;
