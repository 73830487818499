/**
 * Labstep
 *
 * @module screens/Jupyter/Authorize/Center
 * @desc Jupyter Authorize OAuth
 */

import LayoutLoadingContent from 'labstep-web/components/Layout/LoadingContent';
import { withJupyterAuthorize } from 'labstep-web/containers/JupyterAuthorize';
import qs from 'qs';
import React from 'react';
import { JupyterAuthorizeProps } from './types';

export class JupyterAuthorize extends React.Component<JupyterAuthorizeProps> {
  componentDidMount() {
    const params: any = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    const { response_type, redirect_uri, client_id, state } = params;

    this.props.authorize(
      response_type,
      redirect_uri,
      client_id,
      state,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <LayoutLoadingContent
        status={{ isFetching: true }}
        loader="full_screen"
        children={null}
        message="Authenticating..."
      />
    );
  }
}

export default withJupyterAuthorize(JupyterAuthorize);
