/**
 * Labstep
 *
 * @module components/Molecule/Precautionarys
 * @desc Showing molecule hazards
 */

import React from 'react';
import { precautionaryStatements } from 'labstep-web/components/Molecule/Safety/constants';
import { MoleculeSafetyCodeList } from '..';
import { IMoleculeSafetyPrecautionaryProps } from './types';

export const PRECAUTIONARY_CODE_TYPES = [
  'General',
  'Prevention',
  'Response',
  'Storage',
  'Disposal',
];

export const MoleculeSafetyPrecautionary: React.FC<
  IMoleculeSafetyPrecautionaryProps
> = ({ parent, pubChemMetadata }) => {
  const { precautionary } = pubChemMetadata.Safety;

  return (
    <>
      {PRECAUTIONARY_CODE_TYPES.map((type) => (
        <MoleculeSafetyCodeList
          key={type}
          pubChemMetadata={pubChemMetadata}
          parent={parent}
          type="precautionary"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          subtype={type as any}
          label={type}
          codes={(precautionary?.[type] || []).map((h) => ({
            code: h,
            info: precautionaryStatements[h],
          }))}
        />
      ))}
    </>
  );
};

export default MoleculeSafetyPrecautionary;
