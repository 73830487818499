/**
 * Labstep
 *
 * @module components/PermissionRole/Form/fields
 * @desc Fields for PermissionRole
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const fieldName: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'Name',
  name: 'name',
  validation: rules.permission_role.name,
  placeholder: 'Enter name',
};
