/**
 * Labstep
 *
 * @module components/Tag/List
 * @desc List of tags
 */

import TagItem from 'labstep-web/components/Tag/Item';
import Link from 'labstep-web/core/Link';
import { Device } from 'labstep-web/models/device.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { Resource } from 'labstep-web/models/resource.model';
import { IRouting } from 'labstep-web/services/navigation.types';
import React from 'react';
import styles from './styles.module.scss';
import { ITagListProps } from './types';

// FIXME: Core List component should be used here. In order to do that we need to allow
// Core list to layout the children horizontaly

const routesMapping = {
  [Device.entityName]: 'device_index',
  [ExperimentWorkflow.entityName]: 'experiment_workflow_index',
  [ProtocolCollection.entityName]: 'protocol_collection_index',
  [Resource.entityName]: 'resource_index',
  [OrderRequest.entityName]: 'order_request_index',
} as const;

export const TagList: React.FC<ITagListProps> = ({
  tags,
  parentEntity,
  ...rest
}) => (
  <div id="tag-list" className={styles.tagList}>
    {tags.map((tag) => {
      let to: keyof IRouting;
      if (
        parentEntity.entityName === Resource.entityName &&
        parentEntity.is_template
      ) {
        to = 'resource_template_index';
      } else {
        to = routesMapping[parentEntity.entityName];
      }
      return (
        <Link key={tag.id} to={to} query={{ tag_id: tag.id }}>
          <TagItem tag={tag} parentEntity={parentEntity} {...rest} />
        </Link>
      );
    })}
  </div>
);

export default TagList;
