/**
 * Labstep
 *
 * @module components/Device/List
 * @desc Device list
 */

import React from 'react';
import Item from 'labstep-web/components/Device/Item';
import { IDeviceListProps } from './types';
import styles from './styles.module.scss';

const DeviceList: React.FC<IDeviceListProps> = ({
  devices,
  ...rest
}) => (
  <div id="device-list" role="list">
    {devices.map((device) => (
      <div key={device.id} className={styles.card}>
        <Item key={device.id} device={device} {...rest} />
      </div>
    ))}
  </div>
);

export default DeviceList;
