/**
 * Labstep
 *
 * @module core/Card/Generic
 * @desc Generic Card
 */

import React from 'react';
import { humanReadableDate } from 'labstep-web/services/date.service';
import cx from 'classnames';
import SimpleCard from 'labstep-web/core/Card/Card';
import Mobile from 'labstep-web/core/MediaQuery/Mobile';
import Link from 'labstep-web/core/Link';
import Header from 'labstep-web/core/Header';
import styles from './styles.module.scss';
import {
  ICardHeaderProps,
  IGenericCardProps,
  IHeaderLinkTitleProps,
} from './types';

export const HeaderLinkTitle: React.FC<IHeaderLinkTitleProps> = ({
  title,
}) => (
  <Header className={styles.headerDetailsHeader} size="xsmall">
    {title.disabled ? (
      <span>{title.title}</span>
    ) : (
      <Link to={title.to} params={title.params} query={title.query}>
        {title.title}
      </Link>
    )}
  </Header>
);

export const CardHeader: React.FC<ICardHeaderProps> = (props) => {
  const {
    headerSuper,
    image,
    title,
    subtitle,
    customSubtitle,
    header,
    actionMenu,
    detailsSecondary,
    mainAction,
    headerRight,
    reverse,
    fluid,
  } = props;

  return (
    <Mobile>
      {({ match }) => (
        <>
          {headerSuper && (
            <div className={styles.headerSuperContainer}>
              {headerSuper}
            </div>
          )}
          <div className={styles.cardHeader}>
            <div className={styles.headerContainer}>
              <div className={styles.headerImageMain}>
                {image && (
                  <div className={styles.headerImage}>{image}</div>
                )}

                <div
                  className={cx(styles.headerMain, {
                    [styles.fluid]: fluid,
                  })}
                >
                  <div
                    className={cx(styles.headerDetailsPrimary, {
                      reverse,
                    })}
                  >
                    {title && title.to ? (
                      <HeaderLinkTitle title={title} />
                    ) : (
                      title
                    )}
                    {(subtitle || customSubtitle) && (
                      <div>
                        {subtitle ? (
                          <span className={styles.headerSubTitle}>
                            {humanReadableDate(subtitle.date)}
                          </span>
                        ) : (
                          customSubtitle
                        )}
                      </div>
                    )}
                  </div>
                  {header && (
                    <div
                      className={cx({
                        [styles.headerHeader]: title || subtitle,
                      })}
                    >
                      {header}
                    </div>
                  )}
                </div>
              </div>

              {detailsSecondary && (
                <div className={styles.headerDetailsSecondary}>
                  {detailsSecondary}
                </div>
              )}

              <div className={styles.headerRight}>
                {headerRight}
                {!match && mainAction && (
                  <div className={styles.mainAction}>
                    {mainAction}
                  </div>
                )}
                <div className={styles.actionMenu}>{actionMenu}</div>
              </div>
            </div>
            {match && mainAction && (
              <div className={styles.mobileMainAction}>
                {mainAction}
              </div>
            )}
          </div>
        </>
      )}
    </Mobile>
  );
};

/**
 * A generic card component that has 3 sections Header, Body and Footer
 */
const GenericCard: React.FC<IGenericCardProps> = (props) => {
  const {
    headerSuper,
    headerImage,
    headerTitle,
    headerSubtitle,
    customSubtitle,
    headerHeader,
    headerRight,
    body,
    footer,
    actionMenu,
    headerDetailsSecondary,
    mainAction,
    // top,
    reverse,
    className,
    fluid,
    topLeft,
  } = props;

  return (
    <SimpleCard className={cx(styles.main, className)}>
      {topLeft}
      <div>
        <CardHeader
          headerSuper={headerSuper}
          image={headerImage}
          title={headerTitle}
          subtitle={headerSubtitle}
          header={headerHeader}
          detailsSecondary={headerDetailsSecondary}
          actionMenu={actionMenu}
          mainAction={mainAction}
          reverse={reverse}
          customSubtitle={customSubtitle}
          fluid={fluid}
          headerRight={headerRight}
        />
        {body && <div className={styles.body}>{body}</div>}
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </SimpleCard>
  );
};

export default GenericCard;
