/**
 * Labstep
 *
 * @module components/Group/Form/ShowEdit/DefaultEntityStateWorkflow
 * @desc Set the default_entity_state_workflow for this group
 */

import React from 'react';
import FormShowEditSearchSelect from 'labstep-web/core/Form/ShowEdit/SearchSelect';
import EntityLink from 'labstep-web/components/Entity/Link';
import { EntityStateWorkflow } from 'labstep-web/models/entity-state-workflow.model';
import { IGroupFormShowEditDefaultEntityStateWorkflowProps } from './types';

export const GroupFormShowEditDefaultEntityStateWorkflow: React.FC<
  IGroupFormShowEditDefaultEntityStateWorkflowProps
> = ({ group }) => (
  <FormShowEditSearchSelect
    childEntityName={EntityStateWorkflow.entityName}
    parentEntity={group}
    searchSelectProps={{
      params: { group_id: group.id },
      creatable: true,
      createBody: { group_id: group.id },
    }}
    childKeyName="default_entity_state_workflow"
  >
    {group.default_entity_state_workflow && (
      <div>
        <EntityLink entity={group.default_entity_state_workflow} />
      </div>
    )}
  </FormShowEditSearchSelect>
);

export default GroupFormShowEditDefaultEntityStateWorkflow;
