/**
 * Labstep
 *
 * @module src/core/Portal
 * @desc Core transitional portal component. Exporting Semantic ui implementation
 */

import Portal from 'semantic-ui-react/dist/commonjs/addons/Portal';

export default Portal;
