/**
 * Labstep
 *
 * @module components/Resource/Action/Create
 * @desc Create Action for resource
 */

import React from 'react';
import { navigation } from 'labstep-web/services/navigation';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { withRouter } from 'react-router';
import ModalDefault from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import EntityFormCreateTemplatable from 'labstep-web/components/Entity/Form/Create/Templatable';
import { CanCreate } from 'labstep-web/components/Entity/Can';
import { IEntityActionCreateTemplatableProps } from './types';

export const EntityActionCreateTemplatable: React.FC<
  IEntityActionCreateTemplatableProps
> = ({
  entityName,
  templateId,
  history,
  actionComponentProps = {},
}) => {
  const text = `New ${getHumanReadableEntityName(
    entityName,
    false,
    true,
  )}`;
  return (
    <ModalDefault
      header={text}
      content={({ toggleModal }): React.ReactElement => (
        <EntityFormCreateTemplatable
          entityName={entityName}
          templateId={templateId}
          showLoaderWhileCreating
          options={{
            onSuccess: ({ response }): void => {
              toggleModal();
              history.push(
                navigation.get(`${entityName}_show`, {
                  id: response.result,
                }),
              );
            },
          }}
        />
      )}
      viewComponent={({ toggleModal }): React.ReactElement => (
        <CanCreate entityName="resource">
          <ActionComponent
            type="button"
            text={text}
            {...actionComponentProps}
            onClick={toggleModal}
          />
        </CanCreate>
      )}
    />
  );
};

export default withRouter(EntityActionCreateTemplatable);
