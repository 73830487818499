/**
 * Labstep
 *
 * @module components/RichText/Action/Create
 * @desc Action to create comment post
 */

import React from 'react';
import validationRules from 'labstep-web/services/validation/rules';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import Modal from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import { FieldType } from 'labstep-web/core/Form/Reusable/types';
import { IRichTextActionCreateProps } from './types';

const RichTextActionCreate: React.FC<IRichTextActionCreateProps> = ({
  create,
  status,
}) => (
  <Modal
    size="fullscreen"
    header={`Create ${getHumanReadableEntityName('rich_text')}`}
    content={({ toggleModal }) => (
      <ReusableForm
        status={status}
        fields={[
          {
            name: 'description',
            fieldType: FieldType.ProseMirrorHtml,
            validation: validationRules.rich_text.description,
          },
        ]}
        onSubmit={({ description }) => {
          create(
            { rich_text: { type: 'html', description } },
            { onSuccess: toggleModal },
          );
        }}
      />
    )}
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        type="option"
        onClick={toggleModal}
        text="Long text"
        icon="file text"
      />
    )}
  />
);

export default RichTextActionCreate;
