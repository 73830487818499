/**
 * Labstep
 *
 * @module core/ProtocolValue/DataGrid
 * @desc ProtocolValue DataGrid
 */

import React from 'react';
import Loadable from 'labstep-web/core/Loadable';
import { IProtocolValueVariableDataGridProps } from './types';

export const ProtocolValueVariableDataGridComponent = React.lazy(
  () => import('./component'),
);

const ProtocolValueVariableDataGrid: React.FC<
  IProtocolValueVariableDataGridProps
> = (props) => (
  <Loadable>
    <ProtocolValueVariableDataGridComponent {...props} />
  </Loadable>
);

export default ProtocolValueVariableDataGrid;
