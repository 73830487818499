/**
 * Labstep
 *
 * @module components/Metadata/Value/Sequence
 * @desc Metadata Value Sequence
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import SequencePreview from 'labstep-web/components/Sequence/Preview';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { Sequence } from 'labstep-web/models/sequence.model';
import React from 'react';
import { IMetadataValueSequenceProps } from './types';

export const MetadataValueSequence: React.FC<
  IMetadataValueSequenceProps
> = ({ metadata, setActiveEditModal, createProps }) => {
  if (!metadata.sequence) {
    if (createProps) {
      return (
        <EntityActionCreate
          parentName={createProps.parentName}
          parentId={createProps.parentId}
          entityName={Metadata.entityName}
          body={{
            ...createProps.createBody,
            sequence: metadata.sequenceCreateBody,
            type: MetadataType.sequence,
          }}
          options={{
            onSuccess: ({ response }) =>
              setActiveEditModal({
                entityName: Sequence.entityName,
                id: response.entities.metadata[response.result]
                  .sequence,
              }),
          }}
          actionComponentProps={{
            type: 'text',
            text: 'Draw structure',
          }}
        />
      );
    }
    return null;
  }
  return (
    <SequencePreview
      sequence={metadata.sequence}
      expandIconComponent={
        createProps
          ? ({ actionComponentProps }) => (
              <EntityActionCreate
                parentName={createProps.parentName}
                parentId={createProps.parentId}
                entityName={Metadata.entityName}
                body={{
                  ...createProps.createBody,
                  sequence: metadata.sequenceCreateBody,
                  type: MetadataType.sequence,
                }}
                options={{
                  onSuccess: ({ response }) =>
                    setActiveEditModal({
                      entityName: Sequence.entityName,
                      id: response.entities.metadata[response.result]
                        .sequence,
                    }),
                }}
                actionComponentProps={actionComponentProps}
              />
            )
          : undefined
      }
    />
  );
};

export default withActiveEditModal(MetadataValueSequence);
