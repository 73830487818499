/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/Molecule
 * @desc Renders a Molecule node
 */

import React from 'react';
import { useReactNodeView } from 'labstep-web/prosemirror/components/ReactNodeView';
import Read from 'labstep-web/prosemirror/components/NodeView/Read';
import MoleculeCollapsibleCard from 'labstep-web/components/Molecule/CollapsibleCard';
import { Molecule } from 'labstep-web/models/molecule.model';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { IMoleculeNodeProps } from './types';

export class MoleculeNode extends React.Component<IMoleculeNodeProps> {
  shouldComponentUpdate() {
    return Boolean(!this);
  }

  render() {
    const { id, entity, canEdit, timestamp } = this.props;
    return (
      // prevent selection of inner text
      <div contentEditable={false}>
        <Read
          entityName={Molecule.entityName}
          timestamp={timestamp}
          id={id}
        >
          {({ entity: molecule }) => (
            <MoleculeCollapsibleCard
              molecule={molecule}
              entity={entity}
              canEdit={canEdit}
            />
          )}
        </Read>
      </div>
    );
  }
}

const MoleculeNodeView = ({ entity, timestamp }) => {
  const context = useReactNodeView();
  const { guid } = context.node.attrs;
  const canEdit = useHasAccess(
    entity.entityName,
    entity.id,
    Action.edit,
  ) as boolean;
  return (
    <MoleculeNode
      id={guid}
      canEdit={canEdit}
      timestamp={timestamp}
      entity={entity}
    />
  );
};

export default MoleculeNodeView;
