/**
 * Labstep
 *
 * @module core/Ref
 * @desc Wrapper around semantic-ui-react Ref
 */

import React from 'react';
import { RefProps, Ref as SemanticRef } from 'semantic-ui-react';

export const Ref = (props: RefProps) => {
  return <SemanticRef {...props} />;
};

export const RefWrapper: React.FC<{
  innerRef: any;
}> = ({ innerRef, children }) =>
  innerRef ? (
    <Ref innerRef={innerRef}>
      <>{children}</>
    </Ref>
  ) : (
    <>{children}</>
  );

export default Ref;
