/**
 * Labstep
 */

import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import { Node as ProsemirrorNode } from 'prosemirror-model';
import { TextSelection } from 'prosemirror-state';
import { NodeKeys } from 'labstep-web/prosemirror/nodes/constants';
import {
  isSingleBlockSelected,
  isWrappingPossible,
  wrapIn,
} from 'labstep-web/prosemirror/utils';

export const wrapInProtocolStep = (
  state: IStateDispatchProps['state'],
  dispatch?: IStateDispatchProps['dispatch'],
  attrs?: { id: number; guid: string },
) => {
  const nodeType = state.schema.nodes[NodeKeys.protocol_step];

  if (!isWrappingPossible(nodeType, state)) {
    return false;
  }
  if (dispatch) {
    dispatch(
      wrapIn(
        nodeType,
        attrs,
        state.tr,
        state.selection.$from,
        state.selection.$to,
      ),
    );
  }
  return true;
};

export const turnListItemsToSteps = (
  state: IStateDispatchProps['state'],
  dispatch?: IStateDispatchProps['dispatch'],
  attrsArr?: { [key: string]: any }[],
) => {
  const {
    selection: { from, to },
    doc,
  } = state;

  let nodes = [];
  doc.nodesBetween(from, to, (node, pos) => {
    if (
      node.type.name === 'bullet_list' ||
      node.type.name === 'ordered_list'
    ) {
      nodes = [...nodes, { node, pos }];
    }
    return false;
  });
  if (nodes.length === 1) {
    const list: ProsemirrorNode = nodes[0].node;
    const position = [nodes[0].pos, nodes[0].pos + list.nodeSize];
    let listItems = [];
    list.descendants((node) => {
      listItems = [...listItems, node];
      return false;
    });
    if (dispatch && attrsArr) {
      let content = [];
      attrsArr.forEach((attrs, index) => {
        const step = state.schema.nodes[
          NodeKeys.protocol_step
        ].create(attrs, listItems[index].content);
        content = [...content, step];
      });
      dispatch(
        state.tr.replaceWith(position[0], position[1], content),
      );
    }
    return true;
  }
  return false;
};

export const unwrapFromProtocolStep = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
  guid: string,
) => {
  state.doc.descendants((n, pos) => {
    if (
      ['experiment_step', 'protocol_step'].includes(n.type.name) &&
      n.attrs.guid === guid
    ) {
      const content = [];
      n.descendants((child) => {
        content.push(child);
        return false;
      });
      dispatch(state.tr.replaceWith(pos, pos + n.nodeSize, content));
    }
  });
};

export const enterParagraphAfterStep = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
) => {
  if (!isSingleBlockSelected(state)) {
    return false;
  }
  let stepPositionArray;
  let selectedBlockPositionArray;
  state.doc.nodesBetween(
    state.selection.from,
    state.selection.to,
    (n, pos) => {
      if (n.type.name.includes('step')) {
        stepPositionArray = [pos, pos + n.nodeSize];
        return true;
      }
      selectedBlockPositionArray = [pos, pos + n.nodeSize];
      return false;
    },
  );

  if (!stepPositionArray) {
    return false;
  }

  let stepBlocks: { node: ProsemirrorNode; pos: number }[] = [];
  state.doc.nodesBetween(
    stepPositionArray[0],
    stepPositionArray[1],
    (n, pos) => {
      if (n.type.name.includes('step')) {
        return true;
      }
      stepBlocks = [...stepBlocks, { node: n, pos }];
      return false;
    },
  );

  const lastStepBlock = stepBlocks[stepBlocks.length - 1];
  if (
    lastStepBlock.pos === selectedBlockPositionArray[0] &&
    lastStepBlock.node.type.name === 'paragraph' &&
    lastStepBlock.node.content.size === 0
  ) {
    if (dispatch) {
      const sel = stepPositionArray[1];
      const transaction = state.tr.insert(
        sel,
        state.schema.nodes.paragraph.createAndFill(),
      );
      transaction.doc.nodesBetween(
        transaction.selection.from,
        transaction.selection.to,
        (n, pos) => {
          if (n.type.name.includes('step')) {
            stepPositionArray = [pos, pos + n.nodeSize];
            return true;
          }
          return false;
        },
      );
      transaction.setSelection(
        TextSelection.create(
          transaction.doc,
          stepPositionArray[1] + 1,
        ),
      );
      transaction.delete(
        selectedBlockPositionArray[0],
        selectedBlockPositionArray[1],
      );
      dispatch(transaction);
    }
    return true;
  }

  return false;
};
