/**
 * Labstep
 *
 * @module components/Entity/ActionPrint
 * @desc Print the page
 */

import React from 'react';
import { withRouter } from 'react-router';
import { navigation } from 'labstep-web/services/navigation';
import ActionComponent from 'labstep-web/core/Action/Component';
import { IEntityActionPrintProps } from './types';

export const EntityActionPrint: React.FC<IEntityActionPrintProps> = ({
  entity,
  history,
  actionComponentProps,
}) => {
  const params = entity ? { id: entity.id } : undefined;
  const to = `${entity.entityName}_print` as const;
  const pathname = navigation.get(to, params);
  return (
    <ActionComponent
      type="option"
      onClick={() => history.push(pathname)}
      text="Print"
      icon="print"
      {...actionComponentProps}
    />
  );
};

export default withRouter(EntityActionPrint);
