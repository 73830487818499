/**
 * Labstep
 *
 * @module components/Folder/Form/Edit
 * @desc Folder Form Edit
 */

import React from 'react';
import EditForm from 'labstep-web/core/Form/Edit';
import { fieldName } from 'labstep-web/components/Folder/Form/fields';
import { IFolderEditFormProps } from './types';

export const FolderEditForm: React.FC<IFolderEditFormProps> = ({
  folder,
  ...rest
}) => (
  <EditForm
    autoFocus
    entityName={folder.entityName}
    defaultValues={{ name: folder.name }}
    entity={folder}
    fields={[fieldName]}
    {...rest}
  />
);

export default FolderEditForm;
