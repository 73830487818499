/**
 * Labstep
 *
 * @module components/Filter/Post/Active/Operator
 * @desc AND/OR operator for Filter Post Active
 */

import Dropdown from 'labstep-web/core/Dropdown';
import { PostFilterOperator } from 'labstep-web/services/query-parameter.service';
import React from 'react';
import { IFilterPostActiveOperatorProps } from './types';

const FilterPostActiveOperator: React.FC<
  IFilterPostActiveOperatorProps
> = ({ type, setPostFilterType }) => (
  <Dropdown icon={null} trigger={<b>{type.toUpperCase()}</b>}>
    {Object.values(PostFilterOperator).map((operator) => (
      <Dropdown.Item
        key={operator}
        onClick={(): void => setPostFilterType(operator)}
      >
        {operator.toUpperCase()}
      </Dropdown.Item>
    ))}
  </Dropdown>
);

export default FilterPostActiveOperator;
