/**
 * Labstep
 */

import { fieldSignatureRequirementRejectEntityState } from 'labstep-web/components/SignatureRequirement/Form/fields';
import ShowEditSelect from 'labstep-web/core/Form/ShowEdit/Select';
import React from 'react';
import { ISignatureRequirementFormShowEditRejectEntityStateProps } from './types';

export const SignatureRequirementFormShowEditRejectEntityState: React.FC<
  ISignatureRequirementFormShowEditRejectEntityStateProps
> = ({ signatureRequirement, entityStates, entityState }) => {
  const entityStatesBefore = entityStates.filter(
    (e) => e.position < entityState.position,
  );
  if (entityStatesBefore.length === 0) {
    return null;
  }
  return (
    <div data-testid="signature-requirement-form-show-edit-reject-entity-state-content">
      <ShowEditSelect
        entity={signatureRequirement}
        field={fieldSignatureRequirementRejectEntityState(
          entityStatesBefore,
        )}
        name="Reject state"
        childKeyName="reject_entity_state"
      >
        {
          entityStatesBefore.find(
            (e) =>
              e.id === signatureRequirement.reject_entity_state?.id,
          )?.name
        }
      </ShowEditSelect>
    </div>
  );
};

export default SignatureRequirementFormShowEditRejectEntityState;
