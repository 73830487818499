/**
 * Labstep
 *
 * @module components/Filter/CreatedBy
 * @desc Filter Created By
 */

import React from 'react';
import FilterUser from 'labstep-web/components/Filter/User';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import { STRINGS } from 'labstep-web/strings';
import { IFilterCreatedByProps } from './types';

export const FilterCreatedBy: React.FC<IFilterCreatedByProps> = ({
  filter = Filter.created_by,
  ...rest
}) => (
  <FilterUser
    type="author"
    header={STRINGS.filter.header[filter]}
    icon="user circle"
    {...rest}
  />
);

export default FilterCreatedBy;
