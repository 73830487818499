/**
 * Labstep
 *
 * @module components/Organization/Settings
 * @desc Organization settings
 */

import React from 'react';
import OrganizationFormShowEditName from 'labstep-web/components/Organization/Form/ShowEdit/Name';
import OrganizationSettingsWhitelistIp from 'labstep-web/components/Organization/Settings/WhitelistIp';
import OrganizationSettingsAllowMemberCreateGroup from 'labstep-web/components/Organization/Settings/AllowMemberCreateGroup';
import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import OrganizationSettingsSso from 'labstep-web/components/Organization/Settings/Sso';
import OrganizationSettingsMfa from 'labstep-web/components/Organization/Settings/Mfa';
import { Organization } from 'labstep-web/models/organization.model';
import { IHorizontalTableProps } from 'labstep-web/core/Table/Horizontal/types';
import { IOrganizationSettingsProps } from './types';

export const getRows = (
  organization: Organization,
): IHorizontalTableProps['rows'] => {
  const rows = [
    {
      header: 'Name',
      content: (): React.ReactNode => (
        <OrganizationFormShowEditName organization={organization} />
      ),
    },
    {
      header: 'Single Sign On',
      content: (): React.ReactNode => (
        <OrganizationSettingsSso organization={organization} />
      ),
    },
    {
      header: 'Two Factor Authentication',
      content: (): React.ReactNode => (
        <OrganizationSettingsMfa organization={organization} />
      ),
    },
    {
      header: 'IP Whitelist',
      content: (): React.ReactNode => (
        <OrganizationSettingsWhitelistIp
          organization={organization}
        />
      ),
    },
    {
      header: 'Workspaces',
      content: (): React.ReactNode => (
        <OrganizationSettingsAllowMemberCreateGroup
          organization={organization}
        />
      ),
    },
  ];

  return rows;
};

export const OrganizationSettings: React.FC<
  IOrganizationSettingsProps
> = ({ organization }) => (
  <div id="organization-settings">
    <HorizontalTable
      padded
      headerProps={{ width: 3 }}
      rows={getRows(organization)}
      columns={[organization]}
    />
  </div>
);

export default OrganizationSettings;
