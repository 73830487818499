/**
 * Labstep
 *
 * @module core/Form/Reusable/Group
 * @desc Form group to submit multiple forms
 */

import Button from 'labstep-web/core/Button';
import Flex from 'labstep-web/core/Flex';
import React, { useState } from 'react';
import ReusableForm from '..';
import { IReusableFormProps } from '../types';
import { IReusableFormGroupProps } from './types';

const ReusableFormGroup: React.FC<IReusableFormGroupProps> = ({
  onSubmit,
  addAnother = true,
  ...rest
}) => {
  const [count, setCount] = useState(0);
  const onSubmitWithReset: IReusableFormProps['onSubmit'] = (
    values,
  ) => {
    onSubmit(values);
    setCount(count + 1);
  };

  const submitButton: React.FC<{ submitForm: VoidFunction }> = ({
    submitForm,
  }) => (
    <Flex>
      {addAnother && (
        <Button primary basic type="submit" children="Add another" />
      )}
      <Button
        primary
        type="button"
        children="Done"
        onClick={(): void => {
          submitForm();
        }}
      />
    </Flex>
  );

  return (
    <ReusableForm
      key={String(count)}
      onSubmit={onSubmitWithReset}
      submitButton={submitButton}
      {...rest}
    />
  );
};

export default ReusableFormGroup;
