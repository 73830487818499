/**
 * Labstep
 *
 * @module components/Experiment/Action/SetIsUseLatestProtocol
 * @desc Toggle Experiment is_use_latest_protocol
 */

import React from 'react';
import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { ExperimentActionSetIsUseLatestProtocolProps } from './types';

export const ExperimentActionSetIsUseLatestProtocol: React.FC<
  ExperimentActionSetIsUseLatestProtocolProps
> = ({ experiment }) => (
  <EntityActionUpdate
    entityName={experiment.entityName}
    id={experiment.id}
    body={{
      is_use_latest_protocol: !experiment.is_use_latest_protocol,
    }}
    optimistic
    actionComponentProps={{
      type: 'option',
      icon: experiment.is_use_latest_protocol
        ? 'thumbtack'
        : 'sync alternate',
      text: experiment.is_use_latest_protocol
        ? 'Fix to current version'
        : 'Always use latest version',
      elementProps: {
        checked: !!experiment.is_use_latest_protocol,
        toggle: true,
      },
    }}
  />
);

export default ExperimentActionSetIsUseLatestProtocol;
