/**
 * Labstep
 *
 * @module components/ResourceLocation/Card/EmptyState
 * @desc Empty state for ResourceLocationMap
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import ResourceLocationMapActionCreate from 'labstep-web/components/ResourceLocation/Action/CreateMap';
import { IResourceLocationCardEmptyStateMapProps } from './types';

export const ResourceLocationCardEmptyStateMap: React.FC<
  IResourceLocationCardEmptyStateMapProps
> = (props) => (
  <EmptyState
    src="/img/empty-state/locations.svg"
    title="No map"
    explanation="Create a physical map to indicate where items and sublocations sit within this location."
    action={<ResourceLocationMapActionCreate {...props} />}
  />
);

export default ResourceLocationCardEmptyStateMap;
