/**
 * Labstep
 *
 * @module components/UserShareLink/Action/TogglePermissions
 * @desc Toggle view/edit permissions for user access to ShareLink
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import { VerticalDivider } from 'labstep-web/core/Divider';
import Dropdown from 'labstep-web/core/Dropdown';
import Icon from 'labstep-web/core/Icon';
import Popup from 'labstep-web/core/Popup';
import {
  Permission,
  PermissionType,
} from 'labstep-web/models/permission.model';
import { capitalize } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IIconProps } from '../Icon/types';
import styles from './styles.module.scss';
import { IPermissionIconProps, IPermissionMenuProps } from './types';

export const PermissionIcon: React.FC<IPermissionIconProps> = ({
  type,
  withText,
}) => {
  let icon: IIconProps['name'] = 'eye';
  switch (type) {
    case PermissionType.edit:
      icon = 'pencil';
      break;
    case PermissionType.owner:
      icon = 'user';
      break;
    default:
  }

  const trigger = <Icon name={icon} />;

  if (withText) {
    return (
      <div className={styles.container}>
        {trigger}
        <div className="text">{Permission.permissionsText[type]}</div>
      </div>
    );
  }

  return (
    <Popup
      trigger={trigger}
      content={`${capitalize(type)} permissions`}
    />
  );
};

export const permissionsText = {
  [PermissionType.view]: 'Can view only',
  [PermissionType.edit]: 'Can view & edit',
  [PermissionType.owner]: 'Owner',
};

export const PermissionMenu: React.FC<IPermissionMenuProps> = ({
  type,
  onClick,
  withOwner,
  withText,
}) => (
  <Dropdown
    direction="left"
    icon={null}
    trigger={
      <div className={styles.container}>
        <PermissionIcon type={type} withText={withText} />
        <VerticalDivider />
        <Icon name="chevron down" />
      </div>
    }
  >
    <ActionComponent
      type="option"
      icon={
        (type === PermissionType.view
          ? 'check'
          : 'no icon') as IIconProps['name']
      }
      text={permissionsText[PermissionType.view]}
      onClick={() => onClick({ type: PermissionType.view })}
    />
    <ActionComponent
      type="option"
      icon={
        (type === PermissionType.edit
          ? 'check'
          : 'no icon') as IIconProps['name']
      }
      text={permissionsText[PermissionType.edit]}
      onClick={() => onClick({ type: PermissionType.edit })}
    />
    {withOwner && (
      <ActionComponent
        type="option"
        icon={
          (type === PermissionType.owner
            ? 'check'
            : 'no icon') as IIconProps['name']
        }
        text={permissionsText[PermissionType.owner]}
        onClick={() => onClick({ type: PermissionType.owner })}
      />
    )}
  </Dropdown>
);
