/**
 * Labstep
 *
 * @module components/Signature/List
 * @desc Signature list
 */

import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import React from 'react';
import { SignatureListEntityState } from './Component';
import { ISignatureListEntityStateContainerProps } from './types';

export const SignatureListEntityStateContainer: React.FC<
  ISignatureListEntityStateContainerProps
> = (props) => (
  <ReadOnMountHOC
    type="cursor"
    pagination={{ loader: 'placeholder' }}
    entityName={EntityUser.entityName}
    params={{
      experiment_workflow_id: props.experimentWorkflow.id,
      count: 100,
    }}
  >
    {({ entities }) => (
      <SignatureListEntityState
        {...props}
        entityUsers={entities.filter(
          (e) => e.is_contributor || e.is_assigned,
        )}
      />
    )}
  </ReadOnMountHOC>
);

export default SignatureListEntityStateContainer;
