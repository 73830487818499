/**
 * Labstep
 *
 * @module screens/ResourceItem/Index
 * @desc Resource Item Index
 */

import { IEntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import EntityImportModal from 'labstep-web/components/EntityImport/Modal/Trigger';
import ResourceItemActionCreate from 'labstep-web/components/ResourceItem/Action/Create';
import ResourceItemEmptyState from 'labstep-web/components/ResourceItem/Card/EmptyState';
import ResourceItemListOrTable from 'labstep-web/components/ResourceItem/ListOrTable';
import { ParamsStateContextProvider } from 'labstep-web/hoc/Params/context';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import MasterIndex from 'labstep-web/screens/Master/Index';
import columnDefs from 'labstep-web/screens/ResourceItem/Index/colDefs';
import { PostFilterStatus } from 'labstep-web/services/postFilter/filters/status';
import { PostFilterOperator } from 'labstep-web/services/query-parameter.service';
import React, { useCallback, useMemo } from 'react';
import ResourceItemIndexBreadcrumb from './Breadcrumb';
import ResourceItemIndexLeftPane from './LeftPane';
import { bulkActions, filters, sortOptions } from './constants';
import { IScreensResourceItemIndexProps } from './types';
import {
  getActiveResourceTemplateGuid,
  getComponentWithResourceTemplate,
} from './utils';

export const ScreensResourceItemIndex: React.FC<
  IScreensResourceItemIndexProps
> = ({ group, links }) => {
  const breadcrumb = useMemo(
    () => <ResourceItemIndexBreadcrumb />,
    [],
  );
  const action = useCallback(
    ({ searchParams }): React.ReactElement => {
      return getComponentWithResourceTemplate(
        searchParams,
        (resourceTemplate?: Resource): React.ReactElement => (
          <ResourceItemActionCreate
            withRedirect={false}
            resourceTemplate={resourceTemplate}
            extraOptions={
              <EntityImportModal
                targetEntityName={ResourceItem.entityName}
                templateId={resourceTemplate?.id}
              />
            }
          />
        ),
      );
    },
    [],
  );

  const noResultsMessage = useCallback(
    ({ searchParams }): React.ReactElement =>
      getComponentWithResourceTemplate(
        searchParams,
        (resourceTemplate?: Resource): React.ReactElement => (
          <ResourceItemEmptyState
            action={
              <ResourceItemActionCreate
                withRedirect={false}
                resourceTemplate={resourceTemplate}
              />
            }
            secondaryAction={
              <EntityImportModal
                targetEntityName={ResourceItem.entityName}
                templateId={resourceTemplate?.id}
                actionComponentProps={{
                  type: 'button',
                  elementProps: { basic: true },
                }}
              />
            }
          />
        ),
      ),
    [action],
  );

  const leftPane = useMemo(
    () => <ResourceItemIndexLeftPane links={links} />,
    [links],
  );

  const params = useMemo(
    () => ({
      group_id: group.id,
      is_template: false,
      skip_total: 1,
    }),
    [group.id],
  );

  const initialSearchParams = useMemo(
    () => ({
      filter: [
        {
          type: PostFilterOperator.and,
          predicates: PostFilterStatus.getNode(['available']),
        },
        {
          type: PostFilterOperator.and,
          predicates: [],
        },
      ],
    }),
    [],
  );

  const entityView = useCallback((searchParams) => {
    const guid = getActiveResourceTemplateGuid(searchParams);
    return {
      context: 'resource_item_index',
      entityName: ResourceItem.entityName,
      parameters: guid ? { resource_template_guid: guid } : undefined,
    };
  }, []);

  const paramsStateContext = useMemo(
    () => ({ persistEntity: ResourceItem.entityName }),
    [],
  );

  return (
    <ParamsStateContextProvider value={paramsStateContext}>
      <MasterIndex
        breadcrumb={breadcrumb}
        leftPane={leftPane}
        filters={filters}
        params={params}
        initialSearchParams={initialSearchParams}
        noResultsMessage={noResultsMessage}
        action={action}
        entityName={ResourceItem.entityName}
        bulkActions={bulkActions}
        sortOptions={sortOptions}
        entityView={entityView}
        tableFormat={false}
        hideAction
        usePostFilter
        useAllPages
      >
        {({
          entities,
          readNextPage,
          searchParams,
          status,
        }: IEntitySearchContainerChildrenProps) =>
          getComponentWithResourceTemplate(
            searchParams,
            (resourceTemplate) => (
              <ResourceItemListOrTable
                resourceItems={entities}
                templateId={resourceTemplate?.id}
                loadMore={readNextPage}
                status={status}
                columnDefs={columnDefs}
              />
            ),
          )
        }
      </MasterIndex>
    </ParamsStateContextProvider>
  );
};

export default ScreensResourceItemIndex;
