/**
 * Labstep
 */

import { Node } from 'prosemirror-model';

export const addAttrsToClass = (
  node: Node,
  dom: HTMLElement,
  attrs = ['fullWidth'],
) => {
  dom.classList.add(...attrs.filter((attr) => node.attrs[attr]));
};
