/**
 * Labstep
 *
 * @module prosemirror/extensions/steps/plugins
 * @desc Steps plugin
 */

import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

export const stepsPluginKey = new PluginKey('steps');

/**
 * This is necessary to trigger an update for experiment/protocol step
 * nodeviews when editable changes
 */
export const stepsPlugin = new Plugin({
  key: stepsPluginKey,
  state: {
    init(_, { doc }) {
      return DecorationSet.create(doc, []);
    },
    apply(tr, value) {
      const action = tr.getMeta(stepsPluginKey);
      const { doc } = tr;
      if (action) {
        if (action.editable) {
          return DecorationSet.create(doc, []);
        }
        const decos = [];
        const { size } = doc.content;
        doc.nodesBetween(0, size, (node, pos) => {
          if (node.type.name.endsWith('step')) {
            decos.push(
              Decoration.node(pos, pos + node.nodeSize, {
                class: 'editable0',
              }),
            );
          }
          return false;
        });
        return DecorationSet.create(doc, decos);
      }
      return value;
    },
  },
  props: {
    decorations(state) {
      return stepsPlugin.getState(state);
    },
  },
});
