/**
 * Labstep
 *
 * @module state/reducers/file
 * @desc Reducers for File
 */

import { Action } from 'labstep-web/models/action.model';
import { failStatus, receiveStatus, requestStatus } from './helpers';
import { getEntityReducers } from './entity';

/**
 * Update the state when downloading files
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @return {object}
 */
export const downloadingIds = (state: any = {}, action: Action) => {
  switch (action.type) {
    case 'REQUEST_FILE_DOWNLOAD':
      return requestStatus(state, action, action.meta.params.id);
    case 'SUCCESS_FILE_DOWNLOAD':
      return receiveStatus(state, action, action.meta.params.id);
    case 'FAIL_FILE_DOWNLOAD':
      return failStatus(state, action, action.meta.params.id);
    default:
      return state;
  }
};

export default getEntityReducers('file', {
  downloadingIds,
});
