/**
 * Labstep
 *
 * @module prosemirror/extensions/referencing/commands/selectors
 * @desc Selectors for referencing plugin
 */

import { EditorState } from 'prosemirror-state';
import {
  initialState,
  referencingPluginKey,
} from 'labstep-web/prosemirror/extensions/referencing/plugin';
import { PluginState } from 'labstep-web/prosemirror/extensions/referencing/plugin/types';

/**
 *
 * @description Returns the plugin state
 * @param state
 * @returns object
 */
export const getState = (state: EditorState): PluginState => {
  const plugin = state.plugins.find(
    (p) => p.spec.key === referencingPluginKey,
  );
  if (!plugin) {
    return initialState;
  }
  return plugin.getState(state);
};

/**
 *
 * @description Returns isOpen of referencing plugin
 * @param state
 * @returns boolean
 */
export const getIsOpen = (state: EditorState) => {
  return getState(state).isOpen;
};

/**
 *
 * @description Returns selectedCategory of referencing plugin
 * @param state
 * @returns string
 */
export const getSelectedCategory = (state: EditorState) => {
  return getState(state).selectedCategory;
};
