/**
 * Labstep
 *
 * @module components/UserOrganization/Table
 * @desc A multilist listing user organizations
 */

import React from 'react';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import EntityLinkComponent from 'labstep-web/components/Entity/Link/Component';
import UserShowProfile from 'labstep-web/components/User/Show/Profile';
import UserOrganizationActionMenu from 'labstep-web/components/UserOrganization/Action/Menu';
import { UserOrganization } from 'labstep-web/models/user-organization.model';
import { Size } from 'labstep-web/core/Initials/types';
import TextWithHelp from 'labstep-web/core/Text/WithHelp';
import { EntityTable } from 'labstep-web/components/Entity/Table';
import Flex from 'labstep-web/core/Flex';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import UserOrganizationActionUpdate from '../Action/Update';
import { IUserOrganizationTableProps } from './types';

export const UserOrganizationTable: React.FC<
  IUserOrganizationTableProps
> = ({ userOrganizations, authenticatedUserEntity }) => {
  const userNameColumn = {
    header: 'Users',
    content: (
      userOrganization: UserOrganization,
    ): React.ReactElement => (
      <Flex spacing="gap" vAlignContent="center">
        <ProfilePicture
          size={Size.mini}
          entity={userOrganization.user}
        />
        <UserShowProfile
          user={userOrganization.user}
          viewComponent={
            <EntityLinkComponent entity={userOrganization.user} />
          }
        />
      </Flex>
    ),
  };

  const columns = [
    {
      header: <TextWithHelp text="Organization Role" />,
      content: (
        userOrganization: UserOrganization,
      ): React.ReactElement =>
        userOrganization.user.deleted_at ? (
          <p>Disabled</p>
        ) : (
          <UserOrganizationActionUpdate
            userOrganization={userOrganization}
          />
        ),
    },
  ];

  return (
    <EntityTable
      id="organisation-users-table"
      entities={userOrganizations}
      primaryColumn={userNameColumn}
      extraColumns={columns}
      actionMenu={({ entity }) => (
        <UserOrganizationActionMenu
          userOrganization={entity as UserOrganization}
        />
      )}
      withCheckbox={authenticatedUserEntity.isOrganizationAdmin}
      searchParams={{}}
      setParams={() => {}}
    />
  );
};

export default withAuthenticatedUserEntity(UserOrganizationTable);
