/**
 * Labstep
 *
 * @module containers/User/ResetPassword
 * @desc Reset password container
 */

import { resetPassword } from 'labstep-web/state/actions/user';
import { selectApiStatus } from 'labstep-web/state/selectors/ui';
import { connect } from 'react-redux';

const ResetPasswordContainer = ({ children, ...rest }: any) =>
  children(rest);

const mapStateToProps = (state: any) => ({
  status: selectApiStatus(state, 'reset_password'),
});

const mapDispatchToProps = {
  resetPassword,
};

export const UserResetPasswordContainer = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordContainer);
