/**
 * Labstep
 *
 * @module components/File/Action/Download
 * @desc Download file button
 */

import cx from 'classnames';
import { FileDownloadContainer } from 'labstep-web/containers/File/Download';
import ActionComponent from 'labstep-web/core/Action/Component';
import { fileService } from 'labstep-web/services/file.service';
import React from 'react';
import styles from './styles.module.scss';
import {
  IFileActionDownloadContainerProps,
  IFileActionDownloadProps,
} from './types';

export class FileActionDownload extends React.Component<IFileActionDownloadProps> {
  constructor(props: IFileActionDownloadProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    evt.preventDefault();
    const { downloadFile, file } = this.props;
    downloadFile({
      id: file.id,
      onSuccess: this.props.onSuccess || fileService.downloadFile,
    });
  }

  render() {
    const { children, unstyled, actionComponentProps, status, file } =
      this.props;
    const classes = cx({
      [styles.unstyled]: unstyled,
    });
    return actionComponentProps ? (
      <ActionComponent
        text="Download"
        icon="download"
        onClick={this.onClick}
        status={status}
        {...actionComponentProps}
      />
    ) : (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        style={status.isFetching ? { color: 'grey' } : {}}
        className={classes}
        href="#"
        onClick={this.onClick}
        target="_blank"
        title="Download"
      >
        {children || file.name}
      </a>
    );
  }
}

export const FileActionDownloadContainer: React.FC<
  IFileActionDownloadContainerProps
> = ({ file, ...rest }) => {
  if (!file.canDownload) {
    return null;
  }
  const fileToUse = file.image_annotation || file;
  return (
    <FileDownloadContainer file={fileToUse}>
      {(containerProps) => (
        <FileActionDownload
          {...rest}
          {...containerProps}
          file={fileToUse}
        />
      )}
    </FileDownloadContainer>
  );
};

FileActionDownloadContainer.defaultProps = {
  unstyled: false,
};

export default FileActionDownloadContainer;
