/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Action/Menu
 * @desc Action Menu for EntityStateWorkflow
 */

import EntityActionTransferOwnership from 'labstep-web/components/Entity/Action/TransferOwnership';
import EntityActionView from 'labstep-web/components/Entity/Action/View';
import EntityStateWorkflowActionDelete from 'labstep-web/components/EntityStateWorkflow/Action/Delete';
import EntityStateWorkflowActionDuplicate from 'labstep-web/components/EntityStateWorkflow/Action/Duplicate';
import ExperimentWorfklowActionShare from 'labstep-web/components/EntityStateWorkflow/Action/Share';
import EntityUserActionManage from 'labstep-web/components/EntityUser/Action/Manage';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import ActionMenu from 'labstep-web/core/Action/Menu';
import { useModalRoute } from 'labstep-web/hooks/modalRoute';
import React from 'react';
import { ENTITY_STATE_WORKFLOW_CONFIGURE_ROUTE_ID } from '../ConfigureWorkflow';
import { Action, IEntityStateWorkflowActionMenuProps } from './types';

export const EntityStateWorkflowActionMenu: React.FC<
  IEntityStateWorkflowActionMenuProps
> = ({ entityStateWorkflow, type, actions = [] }) => {
  const { toggleModal } = useModalRoute(
    ENTITY_STATE_WORKFLOW_CONFIGURE_ROUTE_ID,
  );
  return (
    <ActionMenu type={type}>
      {actions.includes(Action.view) && (
        <EntityActionView
          entityName="entity_state_workflow"
          params={{ id: entityStateWorkflow.id }}
        />
      )}
      {actions.includes(Action.configure) && (
        <ActionComponent
          type="option"
          text="Configure"
          icon="settings"
          onClick={() =>
            toggleModal({
              entityStateWorkflowId: entityStateWorkflow.id,
            })
          }
        />
      )}
      {actions.includes(Action.assign) && (
        <EntityUserActionManage entity={entityStateWorkflow} />
      )}
      {actions.includes(Action.duplicate) && (
        <EntityStateWorkflowActionDuplicate
          entityStateWorkflow={entityStateWorkflow}
        />
      )}
      {actions.includes(Action.share) && (
        <ExperimentWorfklowActionShare
          entityStateWorkflow={entityStateWorkflow}
        />
      )}
      {actions.includes(Action.transfer_ownership) && (
        <EntityActionTransferOwnership entity={entityStateWorkflow} />
      )}
      {actions.includes(Action.delete) && (
        <EntityStateWorkflowActionDelete
          entityStateWorkflow={entityStateWorkflow}
        />
      )}
    </ActionMenu>
  );
};

EntityStateWorkflowActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default EntityStateWorkflowActionMenu;
