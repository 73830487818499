/**
 * Labstep
 *
 * @module components/Folder/Breadcrumb
 * @desc Breadcrumb for Folder
 */

import FolderActionCreate from 'labstep-web/components/Folder/Action/Create';
import FolderFormShowEditName from 'labstep-web/components/Folder/Form/ShowEdit/Name';
import FolderPaneList from 'labstep-web/components/Folder/Pane/List';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import Desktop from 'labstep-web/core/MediaQuery/Desktop';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Folder, FolderType } from 'labstep-web/models/folder.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import ScreensIndexBreadcrumb from 'labstep-web/screens/Breadcrumb';
import React from 'react';
import styles from './styles.module.scss';
import {
  FolderBreadcrumbItemProps,
  FolderBreadcrumbProps,
} from './types';

export const FolderBreadcrumbItem: React.FC<
  FolderBreadcrumbItemProps
> = ({ searchParams }) => (
  <>
    {searchParams.folder_group_not_id && (
      <Desktop>
        {({ match }) =>
          match ? (
            <div style={{ minWidth: '200px' }}>
              <span>Unsorted</span>
            </div>
          ) : (
            <span>Unsorted</span>
          )
        }
      </Desktop>
    )}
    {searchParams.folder_id && (
      <EntityReadEntityContainer
        entityName={Folder.entityName}
        id={searchParams.folder_id}
      >
        {({ entity }) => (
          <div>
            {entity && (
              <Desktop>
                {({ match }) =>
                  match ? (
                    <div style={{ minWidth: '200px' }}>
                      <FolderFormShowEditName folder={entity} />
                    </div>
                  ) : (
                    <span>{entity.name}</span>
                  )
                }
              </Desktop>
            )}
          </div>
        )}
      </EntityReadEntityContainer>
    )}
  </>
);

export const FolderBreadcrumb: React.FC<FolderBreadcrumbProps> = ({
  type,
}) => {
  const entityName =
    type === FolderType.experiment_workflow
      ? ExperimentWorkflow.getHumanReadableEntityName(true, true)
      : ProtocolCollection.getHumanReadableEntityName(true, true);

  const icon = ICONS[type].primary;

  return (
    <ScreensIndexBreadcrumb
      text={`All ${entityName}`}
      rightMobileIcon={ICONS.folder.primary}
      dropdownContent={({ header }) => (
        <div className={styles.contentContainer}>
          {header}
          <FolderPaneList folderType={type} />
          <FolderActionCreate folderType={type} />
        </div>
      )}
      icon={icon}
      right={({ searchParams }) =>
        searchParams.folder_id || searchParams.folder_group_not_id ? (
          <FolderBreadcrumbItem searchParams={searchParams} />
        ) : null
      }
    />
  );
};

export default FolderBreadcrumb;
