/**
 * Labstep
 *
 * @module core/Form
 * @desc Wrapper around semantic-ui-react Form
 */

import Form from 'semantic-ui-react/dist/commonjs/collections/Form';

export default Form;
