/**
 * Labstep
 *
 * @module components/ResourceLocation/Form/Create
 * @desc Createable ResourceLocation - Auto save on change
 */

import React from 'react';
import FormCreate from 'labstep-web/core/Form/Create';
import { fieldResourceLocationName } from 'labstep-web/components/ResourceLocation/Form/fields';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { IResourceLocationFormCreateProps } from './types';

export const ResourceLocationFormCreate: React.FC<
  IResourceLocationFormCreateProps
> = (props) => (
  <FormCreate
    entityName={ResourceLocation.entityName}
    fields={[fieldResourceLocationName]}
    {...props}
  />
);

export default ResourceLocationFormCreate;
