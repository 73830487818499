/**
 * Labstep
 *
 * @module prosemirror/components/Menu/File
 * @desc Menu to display when selecting a single file node
 */

import MenuFileActionDelete from 'labstep-web/prosemirror/components/Menu/File/Action/Delete';
import MenuFileActionViewInline from 'labstep-web/prosemirror/components/Menu/File/Action/ViewInline';
import MenuFileActionList from 'labstep-web/prosemirror/components/Menu/File/ActionList';
import NodeFile from 'labstep-web/prosemirror/nodes/file';
import {
  SelectionNode,
  getSelectionNodeType,
} from 'labstep-web/prosemirror/utils/selection';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { IMenuFileProps } from './types';

/** Number of pixels to display menu above selection */
export const LINE_HEIGHT = 40;

export const MenuFile: React.FC<IMenuFileProps> = ({
  state,
  dispatch,
  cursorPosition,
  editable,
}) => {
  const [fileNode, setFileNode] = useState<SelectionNode | null>(
    null,
  );

  useEffect(() => {
    setFileNode(getSelectionNodeType(state, NodeFile.key));
  }, [state]);

  if (
    !fileNode ||
    !fileNode.node ||
    !fileNode.node.attrs ||
    !(fileNode.node.attrs.id || !fileNode.node.attrs.guid)
  ) {
    return null;
  }

  return (
    <div
      className={styles.container}
      style={{
        position: 'fixed',
        top: `${cursorPosition.top - LINE_HEIGHT}px`,
        left: `${cursorPosition.left}px`,
      }}
    >
      <MenuFileActionList
        id={fileNode.node.attrs.id || fileNode.node.attrs.guid}
        viewInlineAction={
          !fileNode.node.attrs.fullWidth &&
          editable && (
            <MenuFileActionViewInline
              state={state}
              dispatch={dispatch}
              fileNode={fileNode}
            />
          )
        }
        showAnnotate={editable}
        additionalActions={
          editable && (
            <MenuFileActionDelete state={state} dispatch={dispatch} />
          )
        }
      />
    </div>
  );
};

export default MenuFile;
