/**
 * Labstep
 */

import { Filter } from 'labstep-web/components/Filter/Menu/types';

export const filters: Filter[] = [
  Filter.created_by,
  Filter.assigned_to,
  Filter.date_range,
  Filter.tag,
  Filter.available_resource_item_count,
  Filter.molecule,
];
