/**
 * Labstep
 *
 * @module core/Collapsible/Card
 * @desc Card component that contains a number of CollapsibleItem
 */

import React from 'react';
import classNames from 'classnames';
import CollapsibleCardItem from './Item';
import { ICollapsibleCardItemContainerProps } from './Item/types';
import styles from './styles.module.scss';
import { ICollapsibleCardProps } from './types';

export const CollapsibleCard: React.FC<ICollapsibleCardProps> & {
  Item: React.FC<ICollapsibleCardItemContainerProps>;
} = ({ children, attached }) => (
  <div
    data-testid="collapsible-card"
    className={classNames({ [styles.container]: !attached })}
  >
    {children}
  </div>
);

CollapsibleCard.Item = CollapsibleCardItem;

export default CollapsibleCard;
