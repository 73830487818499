/**
 * Labstep
 *
 * @module state/constants/user
 * @desc Constants for User
 */

/**
 * List of custom api actions
 */
export const userCustomApiActions = [
  'forgot_password',
  'reset_password',
  'change_password',
];

export const INTERNAL_LOGIN = 'INTERNAL_LOGIN';

export const INTERNAL_LOGIN_CHECK = 'INTERNAL_LOGIN_CHECK';

export const SUCCESS_INTERNAL_LOGIN = 'SUCCESS_INTERNAL_LOGIN';

export const INTERNAL_REGISTER = 'INTERNAL_REGISTER';

export const SUCCESS_INTERNAL_REGISTER = 'SUCCESS_INTERNAL_REGISTER';

export const REGISTER_USER_IS_NEW = 'REGISTER_USER_IS_NEW';

export const LOGOUT = 'LOGOUT';

export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';

export const CONSENT_GDPR = 'CONSENT_GDPR';

export const CONFIRM_TOKEN = 'CONFIRM_TOKEN';

export const RESEND_CONFIRMATION_EMAIL = 'RESEND_CONFIRMATION_EMAIL';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';

export const RESET_PASSWORD = 'RESET_PASSWORD';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const SET_AUTHENTICATED_USER_DISABLED =
  'SET_AUTHENTICATED_USER_DISABLED';

export const EXPORT_DATA = 'EXPORT_DATA';
