/**
 * Labstep
 *
 * @module components/Log/List
 * @desc List of logs
 */

import classnames from 'classnames';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import Icon from 'labstep-web/core/Icon';
import { Size } from 'labstep-web/core/Initials/types';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import { ILogListProps, ILogListReadContainerProps } from './types';

export const LogList: React.FC<ILogListProps> = ({
  logs,
  getOnClickFunction,
  activeLogId,
  showExternalIcon = true,
}) => {
  if (logs.length === 0) {
    return <div className="noLogs">No changes yet</div>;
  }

  return (
    <div className={styles.list}>
      {logs.map((log) => {
        const onClick = getOnClickFunction
          ? getOnClickFunction(log)
          : undefined;
        return (
          <div
            key={log.id}
            onClick={onClick}
            className={classnames(styles.item, {
              [styles.active]: activeLogId === log.id,
              [styles.selectable]: !!onClick,
            })}
          >
            <ProfilePicture
              size={Size.mini}
              entity={log.author}
              linkable={false}
            />
            <div className={styles.message}>
              <span>{humanReadableDate(log.created_at)}</span>
              <div>{`${log.author.name} ${log.message}`}</div>
            </div>
            {!!onClick && showExternalIcon && (
              <Icon name="external alternate" />
            )}
          </div>
        );
      })}
    </div>
  );
};

export const LogListReadContainer: React.FC<
  ILogListReadContainerProps
> = ({ entity, extraParams, ...rest }) => (
  <ReadOnMountHOC
    type="cursor"
    pagination={{
      infinite: false,
    }}
    entityName="log"
    params={{
      [`${entity.entityName}_id`]: entity.id,
      ...extraParams,
    }}
    children={({ entities }) => <LogList logs={entities} {...rest} />}
  />
);

export default LogListReadContainer;
