/**
 * Labstep
 *
 * @module prosemirror/extensions/sanitization/plugin
 * @desc Sanitize document to prevent crashes
 */

import {
  NodeWithContent,
  sanitizeRow,
} from 'labstep-web/prosemirror/utils/sanitization';
import { Plugin, PluginKey } from 'prosemirror-state';

export const sanitizationPluginKey = new PluginKey('sanitization');

const sanitizationPlugin = new Plugin({
  key: sanitizationPluginKey,
  appendTransaction(transactions, oldState, newState) {
    if (!transactions[0].getMeta(sanitizationPluginKey)) {
      return null;
    }
    const newTransaction = newState.tr;
    let needsNewTransaction = false;

    newState.doc.nodesBetween(
      0,
      newState.doc.content.size,
      (node, pos) => {
        if (node.type.name === 'table_row') {
          try {
            node.check();
          } catch {
            const sanitized = sanitizeRow(
              node.toJSON() as NodeWithContent,
            );
            needsNewTransaction = true;
            newTransaction.replaceRangeWith(
              pos,
              pos + node.content.size,
              newState.schema.nodeFromJSON(sanitized),
            );
          }
        }
      },
    );

    if (needsNewTransaction) {
      return newTransaction;
    }

    return null;
  },
});

export default sanitizationPlugin;
