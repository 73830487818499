/**
 * Labstep
 *
 * @module screens/ProtocolCollection/Show/Center
 * @desc Center Component of Protocol Show
 * Shows alert if the user cannot edit this version
 */

import EntityPrimaryInfo from 'labstep-web/components/Entity/PrimaryInfo';
import ExperimentWorkflowActionCreate from 'labstep-web/components/ExperimentWorkflow/Action/Create';
import Container from 'labstep-web/components/Layout/Container';
import ProtocolActionMenu from 'labstep-web/components/Protocol/Action/Menu';
import ProtocolActionRestore from 'labstep-web/components/Protocol/Action/Restore';
import ProtocolActionSave from 'labstep-web/components/Protocol/Action/Save';
import ProtocolChangelog from 'labstep-web/components/Protocol/Changelog';
import ProtocolFormShowEditState from 'labstep-web/components/Protocol/Form/ShowEdit/State/Container';
import LockMessage from 'labstep-web/components/ProtocolCollection/Info/LockMessage';
import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import InformationCard from 'labstep-web/core/Card/Information';
import Flex from 'labstep-web/core/Flex';
import Link from 'labstep-web/core/Link';
import React from 'react';
import styles from './styles.module.scss';
import { ICenterProps } from './types';

const ProtocolShowCenter: React.FC<ICenterProps> = ({ protocol }) => (
  <div className={styles.container}>
    <InformationCard
      actionMenu={
        <ProtocolActionMenu
          protocol={protocol}
          actions={['print', 'duplicate', 'restore']}
        />
      }
      primaryInfo={
        <EntityPrimaryInfo
          entity={protocol}
          icon={ICONS.protocol_collection.primary}
        />
      }
      secondaryInfo={
        <Flex vAlignContent="center" wrap>
          <ProtocolChangelog
            protocol={protocol}
            protocolCollection={protocol.collection}
          />
          {protocol.is_last_version ? (
            <ProtocolActionSave
              protocolCollection={protocol.collection}
            />
          ) : (
            <>
              <ProtocolActionRestore
                protocol={protocol}
                actionComponentProps={{ type: 'text' }}
              />
              <Link
                to="protocol_collection_show"
                params={{ id: protocol.collection.id }}
                unstyled
              >
                <ActionComponent
                  type="text"
                  text="Go to Latest Version"
                  icon="copy"
                />
              </Link>
            </>
          )}

          <ExperimentWorkflowActionCreate
            protocol={protocol}
            actionComponentProps={{
              type: 'text',
              icon: 'check circle',
            }}
          />
        </Flex>
      }
      bottom={
        <>
          <Container>
            <LockMessage
              protocol={protocol}
              protocolCollection={protocol.collection}
            />
          </Container>
          <ProtocolFormShowEditState
            key={protocol.id}
            protocolCollection={protocol.collection}
            protocol={protocol}
          />
        </>
      }
    />
  </div>
);

export default ProtocolShowCenter;
