/**
 * Labstep
 *
 * @module components/OrderRequest/Card/EmptyState
 * @desc Empty state for group
 */

import React from 'react';
import ResourceItemActionImport from 'labstep-web/components/ResourceItem/Action/Import';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { IOrderRequestEmptyStateResourceItemProps } from './types';

export const OrderRequestEmptyStateResourceItem: React.FC<
  IOrderRequestEmptyStateResourceItemProps
> = ({ orderRequest }) => (
  <EmptyState
    title="No items have been added to inventory yet."
    explanation={`Once the items from this request have been received click below to add the new items of ${orderRequest.resource.name} to your inventory.`}
    src="/img/empty-state/orderRequests.svg"
    action={
      <ResourceItemActionImport
        resource={orderRequest.resource}
        orderRequest={orderRequest}
        actionComponentProps={{
          type: 'button',
          text: 'Add Items to Inventory',
        }}
      />
    }
  />
);

export default OrderRequestEmptyStateResourceItem;
