/**
 * Labstep
 *
 * @module components/Layout/LoadingContent
 * @desc Displays loading animation and errors
 */

import React from 'react';
import { objectOrFunction } from 'labstep-web/services/react.service';
import Alert from 'labstep-web/core/Alert';
import Loader from 'labstep-web/core/Loader';
import LoaderLogo from 'labstep-web/core/Loader/Logo';
import Placeholder from 'labstep-web/core/Placeholder';
import LayoutMainContentCenter from 'labstep-web/components/Layout/MainContent/Center';
import LayoutFullScreenPage from 'labstep-web/components/Layout/FullScreenPage';
import ViewInline from 'labstep-web/prosemirror/components/Inline/View';
import Header from 'labstep-web/core/Header';
import styles from './styles.module.scss';
import { ILoadingContentProps } from './types';
import { getError, getStatus } from './utils';

/**
 * A component that uses the status object to render a loader (spinner, alert e.t.c)
 * while the content is being fetched. Once the content has been fetched the
 * content is rendered.
 */
export const LayoutLoadingContent: React.FC<ILoadingContentProps> = (
  props,
) => {
  const {
    status,
    children,
    loader,
    defaultFetching,
    center,
    message,
    customError,
  } = props;

  const { isFetching, error } = getStatus(status, defaultFetching);

  if (isFetching) {
    switch (loader) {
      case 'alert':
        return <Alert type="loading" />;
      case 'spinner': {
        const inline = center ? 'centered' : true;
        return <Loader active inline={inline} />;
      }
      case 'grid_cell': {
        return <Loader active inline size="small" />;
      }
      case 'show':
      case 'main_content':
        return (
          <LayoutMainContentCenter>
            <div className={styles.logoContainer}>
              <LoaderLogo />
              <div>{message}</div>
            </div>
          </LayoutMainContentCenter>
        );
      case 'placeholder':
        return <Placeholder />;
      case false:
        return <span />;
      case 'full_screen':
        return (
          <div className={styles.fullPageLoader}>
            <LoaderLogo />
            <div>{message}</div>
          </div>
        );
      case 'inline':
        return <ViewInline />;
      case 'header':
        return <Header>{message}</Header>;
      default:
        return <div />;
    }
  }
  if (error) {
    switch (loader) {
      case 'alert':
      case 'full_screen':
        return (
          <LayoutFullScreenPage>
            <div className={styles.errorContent}>
              {(customError && customError(error)) ||
                getError(error, true)}
            </div>
          </LayoutFullScreenPage>
        );
      case false:
        return <span />;
      case 'main_content':
      default:
        return (
          <LayoutFullScreenPage>
            {(customError && customError(error)) || getError(error)}
          </LayoutFullScreenPage>
        );
    }
  }

  return <>{objectOrFunction(children)}</>;
};

LayoutLoadingContent.defaultProps = {
  loader: 'spinner',
  defaultFetching: false,
  center: true,
};

export default LayoutLoadingContent;
