/**
 * Labstep
 *
 * @module components/Table/Form/Edit
 * @desc Edit form for protocol table
 */

import React from 'react';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import { withToast } from 'labstep-web/containers/Ui/Toast';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import {
  ITableDebounceContainerProps,
  ITableDebounceContainerState,
  ITableEditContainerProps,
} from './types';

export class TableDebounceContainer extends React.Component<
  ITableDebounceContainerProps,
  ITableDebounceContainerState
> {
  fun: any = null;

  constructor(props) {
    super(props);
    this.state = { debouncing: false };
    this.onChange = this.onChange.bind(this);
  }

  onChange(data, onSuccess) {
    const { update, options, table } = this.props;
    if (!isEqual(data, table.data)) {
      if (this.fun) {
        this.fun.cancel();
      }
      this.fun = debounce(() => {
        update({ data }, enhanceOptions({ options, onSuccess }));
        this.setState({ debouncing: false });
      }, 2000);
      this.setState({ debouncing: true });
      this.fun();
    }
  }

  render() {
    const { status, children, showToast } = this.props;

    const { debouncing } = this.state;
    const onError = (message) => {
      showToast({
        type: 'error',
        message,
        options: { timeout: 4000 },
      });
    };
    const { onChange } = this;
    const debouncingStatus = {
      ...status,
      isFetching: status.isFetching || debouncing,
    };
    return children({ debouncingStatus, onChange, onError });
  }
}

export const TableEditContainer: React.FC<
  ITableEditContainerProps
> = ({ table, children, showToast, options, ...rest }) => (
  <EntityUpdateContainer
    entityName={table.entityName}
    id={table.idAttr}
  >
    {({ update, status }) => (
      <TableDebounceContainer
        table={table}
        update={update}
        status={status}
        showToast={showToast}
        options={options}
      >
        {(containerProps) =>
          children({ table, ...containerProps, ...rest })
        }
      </TableDebounceContainer>
    )}
  </EntityUpdateContainer>
);

export default withToast(TableEditContainer);
