import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

interface IPossibleStrings {
  singular: {
    lowercase: string;
    capitalized: string;
  };
  plural: {
    lowercase: string;
    capitalized: string;
  };
}

export const getPossibleStrings = (
  text: string,
  isTemplate?: boolean,
): IPossibleStrings => ({
  singular: {
    lowercase: getHumanReadableEntityName(
      text,
      false,
      false,
      isTemplate,
    ),
    capitalized: getHumanReadableEntityName(
      text,
      false,
      true,
      isTemplate,
    ),
  },
  plural: {
    lowercase: getHumanReadableEntityName(
      text,
      true,
      false,
      isTemplate,
    ),
    capitalized: getHumanReadableEntityName(
      text,
      true,
      true,
      isTemplate,
    ),
  },
});
