/**
 * Labstep
 *
 * @desc Handles box oauth after user grants access
 */

import axios from 'axios';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { BOX_AUTH_TOKEN_INFO_KEY } from 'labstep-web/core/BoxPicker';
import { configService } from 'labstep-web/services/config.service';
import qs from 'query-string';
import React, { useEffect } from 'react';
import { IScreensOAuthBoxProps } from './types';

export const ScreensOAuthBox: React.FC<IScreensOAuthBoxProps> = (
  props,
) => {
  const { search } = props.location;
  useEffect(() => {
    const { code } = qs.parse(search);
    axios({
      method: 'GET',
      url: `${configService.boxUrl}/callback-url?code=${code}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${props.authenticatedUserEntity.token}`,
      },
    }).then(({ data }) => {
      localStorage.setItem(
        BOX_AUTH_TOKEN_INFO_KEY,
        JSON.stringify(data),
      );
      window.close();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>Processing...</div>;
};

export default withAuthenticatedUserEntity(ScreensOAuthBox);
