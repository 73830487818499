/**
 * Labstep
 */

import { IActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { Folder, FolderType } from 'labstep-web/models/folder.model';
import { IOptions } from 'labstep-web/typings';

// eslint-disable-next-line no-shadow
export enum Action {
  edit = 'edit',
  delete = 'delete',
  add_subfolders = 'add_subfolders',
  update_outer = 'update_outer',
}

export interface IFolderActionMenuProps
  extends Pick<
    IActionMenuProps,
    Exclude<keyof IActionMenuProps, 'children' | 'options'>
  > {
  folder: Folder;
  actions: Action[];
  options?: IOptions;
  folderType: FolderType;
}
