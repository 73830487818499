/**
 * Labstep
 *
 * @module models/permalink
 * @desc Typescript export class for PermaLink
 */

import { Entity } from 'labstep-web/models/entity.model';
import { navigation } from 'labstep-web/services/navigation';

type PermaLinkParentName =
  | 'experimentWorkflow'
  | 'protocolCollection'
  | 'resource'
  | 'resourceItem';

type PermaLinkParentType =
  | 'experiment_workflow'
  | 'protocol_collection'
  | 'resource'
  | 'resource_item';

export class PermaLink extends Entity {
  public static readonly idAttr = 'token' as const;

  static readonly entityName = 'perma_link';

  get entityName(): string {
    return PermaLink.entityName;
  }

  constructor(data: Partial<PermaLink> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  token!: string;

  parent_name!: PermaLinkParentName;

  parent_type!: PermaLinkParentType;

  parent_id!: number;

  get url(): string {
    return navigation.get(
      'permalink_show',
      { token: this.token },
      true,
    );
  }
}
