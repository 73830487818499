/**
 * Labstep
 */

import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolConditionVariableType } from 'labstep-web/models/protocol-condition.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { navigation } from 'labstep-web/services/navigation';
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import {
  IProtocolConditionModalProviderProps,
  IProtocolConditionVariableManagerTypes,
  ProtocolConditionModalContextType,
} from './types';

export const ProtocolConditionModalContext =
  createContext<ProtocolConditionModalContextType>({
    activeVariable: null,
    setActiveVariable: (): null => null,
    variableManager: null,
    setVariableManager: (): null => null,
    isOpen: false,
    setIsOpen: (): null => null,
    close: (): null => null,
    path: null,
  });

export const useProtocolConditionModalContext =
  (): ProtocolConditionModalContextType => {
    const context = useContext(ProtocolConditionModalContext);
    if (!context) {
      throw new Error(
        'useProtocolConditionModal must be used within a ProtocolConditionModalProvider',
      );
    }
    return context;
  };

export const ProtocolConditionModalProvider: React.FC<
  IProtocolConditionModalProviderProps
> = ({
  entityName,
  previousLocation: defaultPreviousLocation,
  children,
}) => {
  // Router hooks
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const path = navigation.get(`${entityName}_show_conditions`);
  const isOpen = !!useRouteMatch(path);

  // State
  const [activeVariable, setActiveVariable] =
    useState<Partial<ProtocolConditionVariableType> | null>(null);
  const [previousLocation, setPreviousLocation] = useState<
    typeof history.location | string | null
  >(defaultPreviousLocation || null);
  const [variableManager, setVariableManager] =
    useState<IProtocolConditionVariableManagerTypes>(null);
  const setVariableManagerAndResetActiveVariable = useCallback(
    (type: IProtocolConditionVariableManagerTypes): void => {
      setActiveVariable(null);
      setVariableManager(type);
    },
    [],
  );

  const setIsOpen = (protocol: Experiment | Protocol): void => {
    if (!isOpen) {
      setPreviousLocation(history.location);
      let to: string;
      if (protocol instanceof Protocol) {
        if (protocol.is_last_version) {
          to = navigation.get('protocol_collection_show_conditions', {
            // if we don't have collection available, it means
            // we're already in the collection context
            id: protocol.collection?.id || match.params.id,
          });
        } else {
          to = navigation.get('protocol_show_conditions', {
            id: protocol.id,
          });
        }
      } else if (protocol.is_root) {
        to = navigation.get('experiment_workflow_show_conditions', {
          id: protocol.experiment_workflow.id,
        });
      } else {
        to = navigation.get('experiment_show_conditions', {
          id: protocol.id,
        });
      }
      history.push(to);
    }
  };

  const close = (): void => {
    if (previousLocation) {
      history.push(previousLocation);
    } else {
      history.push(
        navigation.get(`${entityName}_show`, match.params),
      );
    }
  };

  const value = React.useMemo(
    () => ({
      activeVariable,
      setActiveVariable,
      variableManager,
      setVariableManager: setVariableManagerAndResetActiveVariable,
      close,
      isOpen,
      setIsOpen,
      path,
    }),
    [activeVariable, variableManager, isOpen],
  );
  return (
    <ProtocolConditionModalContext.Provider
      value={value}
      children={children}
    />
  );
};
