/**
 * Labstep
 *
 * @module screens/ResourceItem/Show
 * @desc ResourceItem Show Screen
 */

import DocumentHead from 'labstep-web/core/DocumentHead';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import MasterShow from 'labstep-web/screens/Master/Show';
import React from 'react';
import Center from './Center';
import Right from './Right';
import {
  IResourceItemShowScreenContainerProps,
  IResourceItemShowScreenProps,
} from './types';

export const ResourceItemShowScreen: React.FC<
  IResourceItemShowScreenProps
> = ({ resourceItem }) => (
  <>
    <DocumentHead title={resourceItem.name} />
    <MasterShow
      entity={resourceItem}
      center={<Center resourceItem={resourceItem} />}
      right={<Right resourceItem={resourceItem} />}
    />
  </>
);

const ResourceItemShowScreenContainer: React.FC<
  IResourceItemShowScreenContainerProps
> = ({ match }: any) => (
  <ReadOnMountHOC
    type="entities"
    entityName={ResourceItem.entityName}
    params={{
      get_single: 1,
      id: match.params.id,
    }}
    loading={{ loader: 'show', cached: true }}
    children={({ entity }) => (
      <ResourceItemShowScreen resourceItem={entity} />
    )}
  />
);

export default ResourceItemShowScreenContainer;
