/**
 * Labstep
 */

import { Action } from 'labstep-web/models/action.model';

export const addMetadataOptionToTemplate = (
  state,
  action: Action,
) => {
  const { variable_template_guid, template_id } =
    action.meta.denormalized_payload;
  const templateId = variable_template_guid
    ? Object.keys(state).find(
        (id) => state[id].guid === variable_template_guid,
      )
    : template_id;

  const newValues = Object.keys(
    action.meta.body.options.values,
  ).filter((v) => !(v in state[templateId].options.values));

  if (newValues.length) {
    const templateOptions = { ...state[templateId].options };
    newValues.forEach((v) => {
      templateOptions.values[v] = false;
    });
    return {
      ...state,
      [templateId]: {
        ...state[templateId],
        options: templateOptions,
      },
    };
  }
  return state;
};
