/**
 * Labstep
 *
 * @module services/navigator
 * @desc Proxy around browser navigator
 */

export class NavigatorService {
  navigator: Navigator;

  constructor(navigator: Navigator) {
    this.navigator = navigator;
  }

  get isMac(): boolean {
    if (this.navigator) {
      return /Mac/.test(this.navigator.platform);
    }

    return false;
  }
}

export const navigatorService = new NavigatorService(navigator);
