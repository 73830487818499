/**
 * Labstep
 *
 * @desc Lightbox. Wrapper around react-image-lightbox
 */

import React, { useState } from 'react';
import 'react-image-lightbox/style.css';
import { useToggle } from 'labstep-web/hooks/toggle';
import ReactImageLightbox from 'react-image-lightbox';
import { ILightboxProps } from './types';

const Lightbox: React.FC<ILightboxProps> = ({
  children,
  images,
  ...rest
}) => {
  const [toggled, toggle] = useToggle();
  const [index, setIndex] = useState(0);

  return (
    <>
      {children({ toggle })}

      {toggled && (
        <ReactImageLightbox
          imageCaption={images[index].caption}
          mainSrc={images[index].src}
          nextSrc={images[(index + 1) % images.length].src}
          prevSrc={
            images[(index + images.length - 1) % images.length].src
          }
          onCloseRequest={toggle}
          onMovePrevRequest={() =>
            setIndex((index + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setIndex((index + 1) % images.length)
          }
          {...rest}
        />
      )}
    </>
  );
};

export default Lightbox;
