/**
 * Labstep
 */

import { Device } from 'labstep-web/models/device.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { File as FileModel } from 'labstep-web/models/file.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';

/**
 * Entity Name of options on the LHS
 */
export const ENTITY_NAME_OPTIONS = [
  ExperimentWorkflow.entityName,
  ProtocolCollection.entityName,
  Resource.entityName,
  ResourceItem.entityName,
  PurchaseOrder.entityName,
  OrderRequest.entityName,
  Device.entityName,
  FileModel.entityName,
];

export type EntityNameOptions =
  | typeof ExperimentWorkflow.entityName
  | typeof ProtocolCollection.entityName
  | typeof Resource.entityName
  | typeof ResourceItem.entityName
  | typeof PurchaseOrder.entityName
  | typeof OrderRequest.entityName
  | typeof Device.entityName
  | typeof FileModel.entityName;
