/**
 * Labstep
 */

import { Experiment } from 'labstep-web/models/experiment.model';
import { File } from 'labstep-web/models/file.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { updateEntity } from 'labstep-web/state/actions/entity';
import store from 'labstep-web/state/store';
import { IOptions } from 'labstep-web/typings';

export const updateFile = (
  entity: Experiment | Protocol,
  file: File,
  options?: IOptions,
): void => {
  let body: any = {};
  if (entity instanceof Experiment) {
    body = { experiment_id: entity.id };
  } else {
    body = { protocol_id: entity.id };
  }
  store.dispatch(
    updateEntity(File.entityName, file.id, body, options),
  );
};
