/**
 * Labstep
 *
 * @module components/ResourceItem/Action/Delete/Template
 * @desc Action to delete a ResourceItem template
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { IResourceItemActionDeleteTemplateProps } from './types';

export const ResourceItemActionDeleteTemplate: React.FC<
  IResourceItemActionDeleteTemplateProps
> = ({ resourceItem, ...rest }) => (
  <EntityActionDelete
    entity={resourceItem}
    actionComponentProps={{
      type: 'button',
      text: resourceItem.deleted_at
        ? 'Enable Custom Template'
        : 'Disable Template',
    }}
    skipConfirmation
    {...rest}
  />
);

export default ResourceItemActionDeleteTemplate;
