/**
 * Labstep
 *
 * @module prosemirror/extensions
 * @desc Plugins are used to extend the behavior of the editor
 */

import { keymap } from 'prosemirror-keymap';
import { baseKeymap } from 'prosemirror-commands';
import ExtensionAlignment from './alignment';
import ExtensionHistory from './history';
import ExtensionCursor from './cursor';
import ExtensionKeymap from './keymap';
import ExtensionReferencing from './referencing';
import ExtensionCommands from './slash';
import loaderPlugin from './loader/plugin';
import placeholderPlugin from './placeholder/plugin';
import expandedPlugin from './expanded/plugin';
import editablePlugin from './editable/plugin';
import sanitizationPlugin from './sanitization/plugin';

export const extensions = [
  ExtensionAlignment,
  ExtensionHistory,
  ExtensionCursor,
  ExtensionKeymap,
];

export const getSuggestionPlugins = () => [
  ...ExtensionReferencing.plugins,
  ...ExtensionCommands.plugins,
  loaderPlugin,
  keymap(ExtensionReferencing.keymap),
  keymap(ExtensionCommands.keymap),
];

export const getExtensionsPlugins = () => {
  const extensionsKeymap = extensions.reduce(
    (result, extension) => [...result, keymap(extension.keymap)],
    [],
  );

  const extensionsPlugins = extensions
    .filter((extension) => extension.plugins)
    .reduce(
      (result, extension) => [...result, ...extension.plugins],
      [],
    );

  return [
    ...extensionsKeymap,
    ...extensionsPlugins,
    expandedPlugin,
    editablePlugin,
    sanitizationPlugin,
    placeholderPlugin,
    keymap(baseKeymap),
  ];
};
