/**
 * Labstep
 *
 * @module screens/Authentication/SignupEnterprise
 * @desc Signup Screen for Enterprise
 */

import SignupForm from 'labstep-web/components/User/Form/Signup';
import UserSigninSocialMedia from 'labstep-web/components/User/Signin/SocialMedia';
import { withParams } from 'labstep-web/containers/Params';
import { UserRegisterInternalContainer } from 'labstep-web/containers/User/Register/Internal';
import Divider from 'labstep-web/core/Divider';
import DocumentHead from 'labstep-web/core/DocumentHead';
import Icon from 'labstep-web/core/Icon';
import Image from 'labstep-web/core/Image';
import Columns from 'labstep-web/screens/Authentication/Columns';
import {
  AlreadyHaveAnAccount,
  Benefits,
  SharedBody,
  SharedFooter,
  TermsAndConditions,
} from 'labstep-web/screens/Authentication/Shared';
import qs from 'query-string';
import React from 'react';
import { Message } from 'semantic-ui-react';
import Link from 'labstep-web/core/Link';
import SignupLeft from '../Signup/Left';
import styles from './styles.module.scss';

class Register extends React.Component<any> {
  componentDidMount() {
    const { setParams, location } = this.props;
    if (location.search.startsWith('?')) {
      const params = qs.parse(location.search);
      if (params.referralCode) {
        setParams({
          referralCode: params.referralCode,
          refSource: params.refSource,
        });
      }
    }

    this.props.clearLoginError();
  }

  componentDidUpdate(prevProps) {
    const { match, clearLoginError } = this.props;
    if (prevProps.match.url !== match.url) {
      clearLoginError();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    const body = { is_signup_enterprise: true } as {
      is_signup_enterprise: true;
    };

    return (
      <div>
        <SharedBody>
          <UserSigninSocialMedia body={body} mode="signup" />

          <Divider horizontal>Or</Divider>
          <SignupForm body={body} />
        </SharedBody>

        <SharedFooter>
          <AlreadyHaveAnAccount />
          <TermsAndConditions />
          <p>
            The trial agreement and documents governing the terms of
            your free trial are included in the terms and conditions.
          </p>
          <Message>
            <p>
              <b>Looking for Labstep Academia?</b>
            </p>
            <p>
              Labstep Academic is a free version of Labstep available
              to University students, faculty or institute members,
              and independent researchers.
            </p>
            <Link to="signup">Sign up for Labstep Academia</Link>
          </Message>
        </SharedFooter>
      </div>
    );
  }
}

export const RegisterColumns = (props) => (
  <>
    <DocumentHead title="Signup" />
    <Columns
      inverted
      logo={
        <Image className={styles.logo} src="/img/logo/logo.svg" />
      }
      header="Sign up for Labstep Industry"
      explainer="Start your 7 day free trial today"
      left={
        <SignupLeft
          bottomQuote={{
            quote:
              'The onboarding process was quick, efficient, and streamlined. It was easy to migrate our whole company onto the platform.',
            name: 'Carl Brown',
            affiliation: 'Scientist, Sherlock Bioscience, US',
          }}
        />
      }
      right={<Register {...props} />}
      centerAlign={false}
    />
  </>
);

const ScreensAuthenticationSignup = (props) => (
  <UserRegisterInternalContainer>
    {(registerProps) => (
      <RegisterColumns {...registerProps} {...props} />
    )}
  </UserRegisterInternalContainer>
);

export default withParams(ScreensAuthenticationSignup);
