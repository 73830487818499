/**
 * Labstep
 *
 * @module components/Entity/Action/Lock
 * @desc Action to toggle lock for an entity
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ActionComponent from 'labstep-web/core/Action/Component';
import Header from 'labstep-web/core/Header';
import ModalConfirmation from 'labstep-web/core/Modal/Confirmation';
import { Group } from 'labstep-web/models/group.model';
import { generateNewDateString } from 'labstep-web/services/date.service';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import React from 'react';
import {
  IEntityActionLockComponentProps,
  IEntityActionLockProps,
} from './types';

export const EntityActionLockComponent: React.FC<
  IEntityActionLockComponentProps
> = ({
  entityName,
  id,
  options,
  actionComponentProps,
  locked,
  disabled,
  isTemplate,
}) => (
  <EntityUpdateContainer entityName={entityName} id={id}>
    {({ update, status }) => (
      <ModalConfirmation
        header="Warning"
        message={
          <div>
            <Header size="tiny">{`Are you sure you want to ${
              locked ? 'unlock' : 'lock'
            } this ${getHumanReadableEntityName(
              entityName,
              false,
              false,
              isTemplate,
            )}?`}</Header>
            <p>
              {locked
                ? `Once unlocked it can be edited by users with edit permission for this ${getHumanReadableEntityName(
                    entityName,
                    false,
                    false,
                    isTemplate,
                  )}.`
                : `Once locked it will no longer be editable and can
                    only be unlocked by the ${Group.getHumanReadableEntityName()} owner.`}
            </p>
          </div>
        }
        onConfirm={({ toggleModal }) => {
          update(
            {
              locked_at: locked ? null : generateNewDateString(),
            },
            enhanceOptions({ options, onSuccess: toggleModal }),
          );
        }}
        status={status}
      >
        {({ toggleModal }) => (
          <ActionComponent
            type="option"
            disabled={!!disabled}
            onClick={toggleModal}
            text={locked ? 'Unlock' : 'Lock'}
            icon={
              locked
                ? ICONS.entity.actions.unlock
                : ICONS.entity.actions.lock
            }
            elementProps={
              typeof disabled === 'object'
                ? {
                    popup: {
                      content: disabled.message,
                    },
                  }
                : undefined
            }
            {...actionComponentProps}
          />
        )}
      </ModalConfirmation>
    )}
  </EntityUpdateContainer>
);

export const EntityActionLock: React.FC<IEntityActionLockProps> = ({
  entity,
  options = {},
  actionComponentProps,
  disabled,
}) => (
  <Can
    entityName={entity.entityName}
    id={entity.idAttr}
    action={entity.locked_at ? Action.unlock : Action.lock}
  >
    <EntityActionLockComponent
      id={entity.idAttr}
      entityName={entity.entityName}
      options={options}
      actionComponentProps={actionComponentProps}
      locked={!!entity.locked_at}
      disabled={disabled}
      isTemplate={entity.is_template}
    />
  </Can>
);

export default EntityActionLock;
