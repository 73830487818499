/**
 * Labstep
 *
 * @module core/Label/Toggle
 * @desc This large toggle is used in journeys such as selecting tier pricing options,
 * when a pill with extra information may be required and an icon isn’t enough to
 * describe the context change.
 */

import Flex from 'labstep-web/core/Flex';
import React, { useCallback } from 'react';
import LabelToggleItem from './Item';
import styles from './styles.module.scss';
import { ILabelToggleProps } from './types';

export const LabelToggle: React.FC<ILabelToggleProps> = ({
  items,
  onClick: onClickProp,
  header,
  activeItemId: activeItemIdProp,
}) => {
  const [activeItemId, setActiveItemId] = React.useState(
    activeItemIdProp || items[0].id,
  );
  const onClick = useCallback(
    (item) => {
      setActiveItemId(item.id);
      onClickProp(item);
    },
    [onClickProp],
  );
  return (
    <Flex className={styles.container} vAlignContent="center">
      {header}
      <Flex
        className={styles.toggle}
        vAlignContent="center"
        shrink={0}
      >
        {items.map((item) => (
          <LabelToggleItem
            key={item.text}
            text={item.text}
            action={item.action}
            isActive={item.id === activeItemId}
            onClick={() => onClick(item)}
            icon={item.icon}
            iconColor={item.iconColor}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default LabelToggle;
