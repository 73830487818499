/**
 * Labstep
 *
 * @module components/ResourceTemplate/Action/ShareLink
 * @desc Share a resourceTemplate with all users on the platform
 */

import React from 'react';
import EntityActionShare from 'labstep-web/components/Entity/Action/Share';
import { IResourceTemplateActionShareProps } from './types';

export const ResourceTemplateActionShare: React.FC<
  IResourceTemplateActionShareProps
> = ({ resourceTemplate }) => (
  <EntityActionShare entity={resourceTemplate} />
);

export default ResourceTemplateActionShare;
