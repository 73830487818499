/**
 * Labstep
 *
 * @module components/Organization/Billing/InvoiceList
 * @desc Display a list of organization invoices
 */

import SimpleTable from 'labstep-web/core/Table/Simple';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { OrganizationInvoice } from 'labstep-web/models/organization-invoice.model';
import { dateOnly } from 'labstep-web/services/date.service';
import React from 'react';
import { OrganizationInvoiceListProps } from './types';

export const OrganizationInvoiceList: React.FC<
  OrganizationInvoiceListProps
> = ({ organizationInvoices }) => {
  const columns = [
    {
      header: 'ID',
      content: (organizationInvoice: OrganizationInvoice) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={organizationInvoice.hosted_invoice_url}
        >
          {organizationInvoice.number}
        </a>
      ),
      cellProps: { style: { flex: 3 } },
    },
    {
      header: 'Date Collected',
      content: (organizationInvoice: OrganizationInvoice) =>
        dateOnly(organizationInvoice.created),
      cellProps: { style: { flex: 3 } },
    },
    {
      header: 'Amount',
      content: (organizationInvoice: OrganizationInvoice) =>
        `${organizationInvoice.currencyDisplay}${organizationInvoice.amountDisplay}`,
      cellProps: { style: { flex: 1 } },
    },
    {
      header: '',
      content: (organizationInvoice: OrganizationInvoice) =>
        organizationInvoice.paid ? 'Received' : 'Pending',
      cellProps: { style: { flex: 1 } },
    },
  ];

  if (organizationInvoices.length === 0) {
    return null;
  }

  return (
    <SimpleTable rows={organizationInvoices} columns={columns} />
  );
};

export const OrganizationInvoiceListContainer: React.FC = () => {
  return (
    <ReadOnMountHOC
      type="cursor"
      pagination={{
        loader: 'placeholder',
      }}
      entityName={OrganizationInvoice.entityName}
      params={{}}
      children={({ entities }) => (
        <div id="organization-invoices">
          <OrganizationInvoiceList organizationInvoices={entities} />
        </div>
      )}
    />
  );
};

export default OrganizationInvoiceListContainer;
