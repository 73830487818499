/**
 * Labstep
 *
 * @module state/epics/entity
 * @desc Redux epic for entity actions
 */

import { defaultCursor } from 'labstep-web/constants/cursor';
import { POST_FILTER_PAGE_SIZE } from 'labstep-web/hoc/Params';
import { Action } from 'labstep-web/models/action.model';
import * as sfApi from 'labstep-web/services/sf-api.service';
import {
  createEntity,
  getRoute,
  readEntitiesPage,
} from 'labstep-web/state/actions/entity';
import { selectCreateEntityAllStatuses } from 'labstep-web/state/selectors/entity';
import snakeCase from 'lodash/snakeCase';
import { StateObservable } from 'redux-observable';
import { Observable, concat, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

/**
 * Send request
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const readCursorActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<any>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) =>
      action.type.includes('PREPARE_READ_CURSOR'),
    ),
    map((action: Action) => {
      const {
        cursorOptions,
        entityName,
        entitiesName,
        identifier,
        options,
        params,
      } = action.meta;
      let cursor = defaultCursor;
      const refresh = cursorOptions.refresh || cursorOptions.mount;

      const readingId =
        state$.value.entities[entityName].readingIds[identifier];
      const nextCursor = readingId && readingId.next_cursor;

      if (!refresh && nextCursor) {
        cursor = nextCursor;
      }

      return sfApi.get({
        type: `READ_CURSOR_${snakeCase(entityName)}`,
        route: getRoute('get', entityName, true),
        meta: {
          identifier,
          cursor,
          cursorOptions,
          normalize: entitiesName,
        },
        params: { ...params, cursor },
        ...options,
      });
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_ENTITY_READ_CURSOR', payload: err }),
        source$,
      ),
    ),
  );

/**
 * Send request
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const createEntityIfNotCreatingActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<any>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type.includes('CHECK_CREATING_ON_CREATE_ENTITY') &&
        selectCreateEntityAllStatuses(
          state$.value,
          action.payload.entityName,
        ).every((status) => !status.isFetching),
    ),
    map((action: Action) => {
      return createEntity(
        action.payload.entityName,
        action.payload.data,
        action.payload.parentName,
        action.payload.parentId,
        action.payload.uuid,
        action.payload.options,
        action.payload.childKeyName,
      );
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_CREATE_ENTITY_IF_NOT_CREATING',
          payload: err,
        }),
        source$,
      ),
    ),
  );

export const readNextPageActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<any>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) =>
      action.type.includes('PREPARE_READ_NEXT_PAGE'),
    ),
    map((action: Action) => {
      const {
        entityName,
        params,
        identifier,
        options,
        usePostFilter,
      } = action.meta;

      const readingState =
        state$.value.entities[entityName].readingIds[identifier];
      const pages = Object.keys(readingState.pages);
      const lastPage = Number(pages[pages.length - 1]);
      const lastPageItems = readingState.pages[lastPage].items;

      if (
        params.skip_total
          ? lastPageItems.length >=
            (usePostFilter ? POST_FILTER_PAGE_SIZE : params.count)
          : lastPage < readingState.totalPages
      ) {
        return readEntitiesPage(
          entityName,
          params,
          lastPage + 1,
          options,
          usePostFilter,
        );
      }
      return {
        type: 'READ_NEXT_PAGE_NO_MORE_PAGES',
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_ENTITY_READ_NEXT_PAGE',
          payload: err,
        }),
        source$,
      ),
    ),
  );
