/**
 * Labstep
 *
 * @module screens/Experiment/Edit/Right/Metadatas
 * @desc Experiment Value Screens
 */

import Container from 'labstep-web/components/Layout/Container';
import MetadataActionCreateDataModal from 'labstep-web/components/Metadata/Action/Create/DataModal';
import MetadataActionCreateFile from 'labstep-web/components/Metadata/Action/Create/File';
import MetadataCardList from 'labstep-web/components/Metadata/CardList';
import { ICONS } from 'labstep-web/constants/icons';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import ListCollapsible from 'labstep-web/core/List/Collapsible';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Experiment } from 'labstep-web/models/experiment.model';
import React from 'react';
import { IExperimentWorkflowEditRightMetadatasProps } from './types';

const ExperimentWorkflowEditRightMetadatas: React.FC<
  IExperimentWorkflowEditRightMetadatasProps
> = ({ experimentWorkflow }) => {
  const createAction = (
    <MetadataActionCreateDataModal
      entity={experimentWorkflow.root_experiment}
    />
  );

  const showEmptyState =
    experimentWorkflow.metadata_count +
      experimentWorkflow.root_experiment.metadata_count ===
    0;
  return (
    <>
      <div style={{ display: showEmptyState ? 'block' : 'none' }}>
        <MetadataActionCreateFile
          metadataThreadId={
            experimentWorkflow.root_experiment.metadata_thread.id
          }
        >
          <EmptyState
            src="/img/empty-state/data.svg"
            explanation="Add data fields to capture variable input parameters, such as incubation temperatures, or output data, such as purification yields, in a quick and structured fashion."
            action={createAction}
          />
        </MetadataActionCreateFile>
      </div>
      <div style={{ display: showEmptyState ? 'none' : 'block' }}>
        <Container>{createAction}</Container>

        <MetadataCardList
          entity={experimentWorkflow.root_experiment}
        />
        <ReadOnMountHOC
          type="cursor"
          entityName={Experiment.entityName}
          params={{
            is_root: false,
            experiment_workflow_id: experimentWorkflow.id,
          }}
          children={({ entities }) => {
            const experiments = entities.filter((e) => !e.deleted_at);

            return (
              <ListCollapsible
                sections={experiments.map((experiment) => ({
                  icon: ICONS.experiment.primary,
                  text: experiment.name,
                  count: experiment.metadata_count,
                  component: <MetadataCardList entity={experiment} />,
                }))}
              />
            );
          }}
        />
      </div>
    </>
  );
};

export default ExperimentWorkflowEditRightMetadatas;
