/**
 * Labstep
 *
 * @module components/ProtocolTimer/Notifier
 * @desc A component to notify user when alert is running low (under 10s)
 */

import React, { useEffect, useRef } from 'react';
import { useToggle } from 'labstep-web/hooks/toggle';
import {
  isRunning,
  isAtZero,
  isRunningLow,
} from 'labstep-web/containers/ProtocolTimer/Item/utils';
import ProtocolTimerModal from 'labstep-web/components/ProtocolTimer/Modal';
import Alert from 'labstep-web/core/Alert';
import Icon from 'labstep-web/core/Icon';
import { getTimeText } from 'labstep-web/core/Time';
import styles from './styles.module.scss';
import { IProtocolTimerAlertProps } from './types';

export const ProtocolTimerAlert: React.FC<
  IProtocolTimerAlertProps
> = ({ protocolTimer, protocol, timeRemaining }) => {
  const audio = useRef(new Audio('/audio/timer-alarm.wav'));
  const [isMute, toggleIsMute] = useToggle();
  const [isVisible, toggleIsVisible] = useToggle(
    isRunning(protocolTimer) && isRunningLow(timeRemaining),
  );

  const playAudio = () => {
    audio.current.play();
  };

  const pauseAudio = () => {
    audio.current.pause();
    audio.current.currentTime = 0;
  };

  // set loop on init
  useEffect(() => {
    audio.current.loop = true;
  }, []);

  // pause audio on mute
  useEffect(() => {
    if (isRunning(protocolTimer)) {
      if (isMute) {
        pauseAudio();
      } else {
        playAudio();
      }
    }
  }, [isMute]);

  // play/pause audio
  useEffect(() => {
    if (
      isAtZero(timeRemaining) &&
      isRunning(protocolTimer) &&
      !isMute
    ) {
      playAudio();
    } else {
      pauseAudio();
    }
  }, [timeRemaining, protocolTimer, isMute]);

  if (!isVisible) {
    return null;
  }

  return (
    <ProtocolTimerModal
      protocolTimer={protocolTimer}
      protocol={protocol}
      viewComponent={
        <div
          data-testid="protocol-timer-alert"
          className={styles.container}
        >
          <Alert color="red" centered noMargin>
            <div className={styles.alertContainer}>
              <Icon
                data-testid="mute"
                size="large"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleIsMute();
                }}
                name={isMute ? 'volume off' : 'volume up'}
                color="white"
              />
              <div className={styles.nameContainer}>
                {protocolTimer.name} <b>ending soon</b>
              </div>
              <div>
                <b>{getTimeText(timeRemaining)}</b>
                <Icon
                  data-testid="close"
                  onClick={() => {
                    if (!isMute) {
                      toggleIsMute();
                    }
                    toggleIsVisible();
                  }}
                  name="times"
                  color="white"
                />
              </div>
            </div>
          </Alert>
        </div>
      }
    />
  );
};

export default ProtocolTimerAlert;
