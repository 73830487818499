/**
 * Labstep
 *
 * @module components/ResourceTemplate/Action/Duplicate
 * @desc Button to duplicate a resource template
 */

import React from 'react';
import EntityActionDuplicate from 'labstep-web/components/Entity/Action/Duplicate';
import { IResourceTemplateDuplicateActionProps } from './types';

export const ResourceTemplateDuplicateAction: React.FC<
  IResourceTemplateDuplicateActionProps
> = ({ resourceTemplate }) => (
  <EntityActionDuplicate
    entity={resourceTemplate}
    body={{ is_template: true }}
  />
);

export default ResourceTemplateDuplicateAction;
