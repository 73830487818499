/**
 * Labstep
 *
 * @module components/Academic/SetupPayment/AcademicPlusSeats
 * @desc Select seats
 */

import Message from 'labstep-web/core/Message';
import CurrencyService, {
  Currency,
} from 'labstep-web/services/currency.service';
import { intervalAdverb } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import { IAcademicPlusSeatsProps } from './types';

export const MaxAcademicPlusSeats = 5;

export const AcademicPlusSeats: React.FC<IAcademicPlusSeatsProps> = ({
  userCount,
  priceCurrency,
  activePlanPrice,
  maxSeats,
  setMaxSeats,
}) => {
  if (!activePlanPrice.unit_amount) {
    return null;
  }

  const seatPrice = CurrencyService.print(
    activePlanPrice.unit_amount,
    priceCurrency.toUpperCase() as Currency,
    false,
  );
  const totalPrice = CurrencyService.print(
    activePlanPrice.unit_amount * maxSeats,
    priceCurrency.toUpperCase() as Currency,
    false,
  );

  return (
    <div className={styles.container}>
      <div className={styles.plan}>
        <div
          style={{
            display: 'flex',
          }}
        >
          <div className={styles.userCounter}>
            <div>There are currently</div>
            <div data-testid="academic-users">{userCount}</div>
            <div>users in workspace you own</div>
          </div>
          <div className={styles.userCounter}>
            <div>I want to pay for</div>
            <div>
              <div
                data-testid="minus-button"
                className={styles.seatAdjustButton}
                onClick={() => {
                  setMaxSeats(Math.max(1, maxSeats - 1));
                }}
              >
                -
              </div>
              <div
                data-testid="selected-seat-count"
                className={styles.selectedSeatCount}
              >
                {maxSeats}
              </div>
              <div
                data-testid="plus-button"
                className={styles.seatAdjustButton}
                onClick={() => {
                  setMaxSeats(maxSeats + 1);
                }}
              >
                +
              </div>
            </div>
            <div>
              <i>Academic+</i> Seats
            </div>
          </div>
        </div>
      </div>

      <div className={styles.plan}>
        <div>Total cost</div>
        <div data-testid="seat-price" className={styles.amount}>
          {seatPrice}
          {' per seat, paid '}
          {intervalAdverb[activePlanPrice.interval]}
        </div>
        <div data-testid="total-price" className={styles.totalPrice}>
          <span className={styles.price}>{totalPrice}</span>
          <span className={styles.interval}>
            /{activePlanPrice.interval}
          </span>
        </div>
      </div>

      <div className={styles.plan}>
        {maxSeats < userCount ? (
          <Message warning className={styles.message}>
            <b>Warning:</b> Users in workspaces you own not allocated
            an Academic+ seat will no longer be able to access those
            workspaces
          </Message>
        ) : (
          maxSeats > MaxAcademicPlusSeats && (
            <Message error className={styles.message}>
              {`To add more than ${MaxAcademicPlusSeats} users. Please
              contact info@labstep.com.`}
            </Message>
          )
        )}
      </div>
    </div>
  );
};
