/**
 * Labstep
 *
 * @module components/ProtocolCondition/DataGrid/EmptyState
 * @desc Empty state for ProtocolCondition
 */

import EmptyState from 'labstep-web/core/Card/EmptyState';
import React from 'react';

export const ProtocolConditionEmptyState: React.FC = () => (
  <EmptyState
    src="/img/empty-state/inventory.svg"
    title="No Data Found"
    explanation="We couldn't find any experiments using this protocol."
  />
);
