/**
 * Labstep
 *
 * @module screens/PurchaseOrder/Show/Center
 * @desc PurchaseOrder Show Center
 */

import { EntityActionLock } from 'labstep-web/components/Entity/Action/Lock';
import EntityPrimaryInfo from 'labstep-web/components/Entity/PrimaryInfo';
import EntityUserCard from 'labstep-web/components/EntityUser/Card';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import PurchaseOrderActionMenu from 'labstep-web/components/PurchaseOrder/Action/Menu';
import { Action } from 'labstep-web/components/PurchaseOrder/Action/Menu/types';
import PurchaseOrderUpdateDropDown from 'labstep-web/components/PurchaseOrder/Action/UpdateStatusDropdown';
import PurchaseOrderDetails from 'labstep-web/components/PurchaseOrder/Details';
import PurchaseOrderFormShowEditCurrency from 'labstep-web/components/PurchaseOrder/Form/ShowEdit/Currency';
import ActionComponent from 'labstep-web/core/Action/Component';
import InformationCard from 'labstep-web/core/Card/Information';
import Link from 'labstep-web/core/Link';
import TextWithCount from 'labstep-web/core/Text/WithCount';
import { PurchaseOrderStatus } from 'labstep-web/models/purchase-order.model';
import React from 'react';
import styles from './styles.module.scss';
import { IPurchaseOrderShowCenterProps } from './types';

export const PurchaseOrderShowCenter: React.FC<
  IPurchaseOrderShowCenterProps
> = ({ purchaseOrder }) => {
  return (
    <div id="purchase-order-show-screen" className={styles.container}>
      <InformationCard
        additionalActions={<EntityUserCard entity={purchaseOrder} />}
        actionMenu={
          <PurchaseOrderActionMenu
            purchaseOrder={purchaseOrder}
            actions={[Action.print, Action.delete]}
          />
        }
        primaryInfo={<EntityPrimaryInfo entity={purchaseOrder} />}
        secondaryInfo={
          <MetadataManager
            entity={purchaseOrder}
            secondaryAction={
              <Link to="settings_order_info" unstyled>
                <ActionComponent
                  text="Edit Default Metadata"
                  type="text"
                  icon="copy"
                />
              </Link>
            }
            tertiaryAction={
              purchaseOrder.status ===
                PurchaseOrderStatus.completed && (
                <EntityActionLock
                  entity={purchaseOrder}
                  actionComponentProps={{ type: 'text' }}
                />
              )
            }
            extraRows={[
              {
                header: (
                  <TextWithCount
                    header
                    text="Status"
                    icon="info circle"
                  />
                ),
                content: (entity) => (
                  <PurchaseOrderUpdateDropDown
                    purchaseOrder={entity}
                  />
                ),
              },
              {
                header: (
                  <TextWithCount
                    header
                    text="Currency"
                    icon="dollar"
                  />
                ),
                content: (entity) => (
                  <PurchaseOrderFormShowEditCurrency
                    purchaseOrder={entity}
                  />
                ),
              },
            ]}
          />
        }
        bottom={
          <PurchaseOrderDetails purchaseOrder={purchaseOrder} />
        }
      />
    </div>
  );
};

export default PurchaseOrderShowCenter;
