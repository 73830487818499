/**
 * Labstep
 *
 * @module core/DataGrid/ColumnHeader/EntityImport
 * @desc DataGrid Header for EntityImport
 */

import Dropdown from 'labstep-web/core/Dropdown';
import DropdownPortal from 'labstep-web/core/Dropdown/Portal';
import { EntityImportColDef } from 'labstep-web/models/entity-import.model';
import React from 'react';
import DataGridColumnHeaderEntityImportMetadataActionCreate from './Action/Create';
import DataGridColumnHeaderEntityImportMetadataActionDelete from './Action/Delete';
import DataGridColumnHeaderEntityImportMetadataActionMatch from './Action/Match';
import { IDataGridColumnHeaderEntityImportMetadataProps } from './types';

export const DataGridColumnHeaderEntityImportMetadata: React.FC<
  IDataGridColumnHeaderEntityImportMetadataProps
> = ({ trigger, context, column }) => {
  const { template, targetEntityName } = context;
  const { isCustom, isRequired } =
    column.getColDef() as EntityImportColDef;
  return (
    <DropdownPortal trigger={trigger}>
      {isCustom && template && (
        <>
          <DataGridColumnHeaderEntityImportMetadataActionMatch
            column={column}
            template={template}
            targetEntityName={targetEntityName}
          />
          <DataGridColumnHeaderEntityImportMetadataActionCreate
            column={column}
            template={template}
            targetEntityName={targetEntityName}
          />
          <Dropdown.Divider />
        </>
      )}
      {!isRequired && (
        <DataGridColumnHeaderEntityImportMetadataActionDelete
          column={column}
        />
      )}
    </DropdownPortal>
  );
};

export default DataGridColumnHeaderEntityImportMetadata;
