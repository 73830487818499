/**
 * Labstep
 *
 * @module components/Signature/Card/EmptyState
 * @desc Empty state for signature
 */

import EmptyState from 'labstep-web/core/Card/EmptyState';
import React from 'react';
import { ISignatureEmptyStateProps } from './types';

export const SignatureEmptyState: React.FC<
  ISignatureEmptyStateProps
> = ({ action, explanationText }) => (
  <EmptyState
    src="/img/empty-state/signatures.svg"
    explanation={explanationText}
    action={action}
  />
);

export default SignatureEmptyState;
