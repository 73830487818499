/**
 * Labstep
 *
 * @module components/ResourceLocation/Form/Edit
 * @desc Editable ResourceLocation - Auto save on change
 */

import React from 'react';
import EditForm from 'labstep-web/core/Form/Edit';
import { fieldResourceLocationName } from '../fields';
import { IResourceLocationEditFormProps } from './types';

export const ResourceLocationEditForm: React.FC<
  IResourceLocationEditFormProps
> = ({ resourceLocation, options }) => (
  <EditForm
    entityName="resource_location"
    entity={resourceLocation}
    fields={[fieldResourceLocationName]}
    options={options}
  />
);

export default ResourceLocationEditForm;
