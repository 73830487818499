/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/Protocol/Active
 * @desc Active filter component for protocol
 */

import { ICONS } from 'labstep-web/constants/icons';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import {
  IExperimentWorkflowFilterProtocolActiveProps,
  IProtocolChildrenProps,
  IProtocolCollectionChildrenProps,
} from './types';

const ExperimentWorkflowFilterProtocolActive: React.FC<
  IExperimentWorkflowFilterProtocolActiveProps
> = ({ clearParams, searchParams }) => (
  <>
    {'protocol_id' in searchParams && (
      <EntityReadEntityContainer
        entityName={Protocol.entityName}
        id={searchParams.protocol_id}
      >
        {({ entity: protocol }: IProtocolChildrenProps) => {
          return protocol ? (
            <RemoveLabel
              icon={ICONS.protocol_collection.primary}
              name={`${protocol.nameAndVersion}`}
              onRemoveClick={
                clearParams && (() => clearParams(['protocol_id']))
              }
            />
          ) : null;
        }}
      </EntityReadEntityContainer>
    )}
    {'protocol_collection_id' in searchParams && (
      <EntityReadEntityContainer
        entityName={ProtocolCollection.entityName}
        id={searchParams.protocol_collection_id}
      >
        {({
          entity: protocolCollection,
        }: IProtocolCollectionChildrenProps) => {
          return protocolCollection ? (
            <RemoveLabel
              icon={ICONS.protocol_collection.primary}
              name={`${protocolCollection.name}`}
              onRemoveClick={
                clearParams &&
                (() => clearParams(['protocol_collection_id']))
              }
            />
          ) : null;
        }}
      </EntityReadEntityContainer>
    )}
  </>
);

export default ExperimentWorkflowFilterProtocolActive;
