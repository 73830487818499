/**
 * Labstep
 *
 * @module components/ShareLinkInvitation/Action/Create/Organization
 * @desc An action to create a sharelink invitation for organization
 */

import React from 'react';
import UserOrganizationActionAddBulkUsers from 'labstep-web/components/UserOrganization/Action/AddBulkUsers';
import ActionComponent from 'labstep-web/core/Action/Component';
import {
  ISharelinkInvitationActionCreateOrganizationProps,
  ISharelinkInvitationActionCreateOrganizationViewComponentProps,
} from './types';
import SharelinkInvitationActionCreateOrganizationDisabled from './Disabled';

export const SharelinkInvitationActionCreateOrganizationViewComponent: React.FC<
  ISharelinkInvitationActionCreateOrganizationViewComponentProps
> = ({ toggleModal }) => (
  <ActionComponent
    onClick={toggleModal}
    type="button"
    text="Invite"
  />
);

export const SharelinkInvitationActionCreateOrganization: React.FC<
  ISharelinkInvitationActionCreateOrganizationProps
> = ({ organization }) => {
  if (organization.logged_user_user_organization.type !== 'admin') {
    return null;
  }
  if (organization.noSeatsLeft || organization.is_saml_enabled) {
    return (
      <SharelinkInvitationActionCreateOrganizationDisabled
        organization={organization}
      />
    );
  }

  return (
    <UserOrganizationActionAddBulkUsers organization={organization} />
  );
};

export default SharelinkInvitationActionCreateOrganization;
