/**
 * Labstep
 *
 * @module core/Sortable/Table
 * @desc Component for sorting table
 */

import React from 'react';
import Table from 'labstep-web/core/Table';
import SortableContainer from '../Container';
import Sortable from '..';
import { ISortableTableProps } from './types';

const SortableTable: React.FC<ISortableTableProps> = ({
  renderItem,
  disabled,
  className,
  isTableBody,
  ...rest
}) => (
  <SortableContainer
    {...rest}
    render={({ items, onSortEnd }): JSX.Element => {
      const Container = isTableBody ? Table.Body : Table;
      return (
        <Container className={className}>
          <Sortable
            renderItem={renderItem}
            items={items}
            disabled={disabled}
            onSortEnd={onSortEnd}
            hideOverlay
          />
        </Container>
      );
    }}
  />
);

export default SortableTable;
