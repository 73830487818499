/**
 * Labstep
 *
 * @module models/resource-item
 * @desc Typescript export class for ResourceItem
 */

import { Type } from 'class-transformer';
import { User } from 'labstep-web/models/user.model';
import { Entity } from 'labstep-web/models/entity.model';
import { Thread } from 'labstep-web/models/thread.model';
import { Resource } from 'labstep-web/models/resource.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { PermaLink } from 'labstep-web/models/perma-link.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { File as FileModel } from 'labstep-web/models/file.model';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import { UNITS } from 'labstep-web/constants/unit';
import { getAmountUnitText } from 'labstep-web/services/amount-unit.service';

export type ResourceItemStatus = 'available' | 'unavailable';

export const ResourceItemStatusValues = {
  available: 'Available',
  unavailable: 'Unavailable',
};

export type ResourceItemEntityNameType = 'resource_item';

export class ResourceItem extends Entity {
  public static readonly entityName: ResourceItemEntityNameType =
    'resource_item';

  public readonly defaultImageSrc =
    '/img/resource-category/default.svg';

  public get entityName(): typeof ResourceItem.entityName {
    return ResourceItem.entityName;
  }

  public constructor(data: Partial<ResourceItem> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public item_id!: number;

  public amount!: string | null;

  public amount_alert_threshold!: number | null;

  public unit!: string | null;

  public name!: string | null;

  public is_template!: boolean;

  public entity_users_count!: number;

  public stock_checked_at!: string | null;

  public input_experiment_ids?: number[];

  public input_protocol_condition_guids?: string[];

  public get displayName(): string {
    return (
      this.name || `${this.resource.displayName} #${this.item_id}`
    );
  }

  public get nonDeletedTemplate(): ResourceItem | null | undefined {
    if (this.is_template) {
      return null;
    }
    if (this.resource.hasNonDeletedResourceItemTemplate) {
      return this.resource.resource_item_template;
    }

    if (this.resource.hasTemplateWithNonDeletedResourceItemTemplate) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.resource.template!.resource_item_template;
    }

    return null;
  }

  public get printQuantity(): string {
    if (this.amount === null) {
      return '';
    }
    return `${this.amount} ${this.unit || ''}`;
  }

  public get printIdNumber(): string {
    return `#${this.id}`;
  }

  public get stockCheckDue(): boolean {
    return (
      !this.stock_checked_at ||
      Date.now() - Date.parse(this.stock_checked_at) >
        1000 * 60 * 60 * 24
    );
  }

  public get unitDisplayValue(): string | null | undefined {
    const { unit } = this;
    if (!unit) {
      return null;
    }
    if (UNITS[unit]) {
      return UNITS[unit].displayValue;
    }
    return unit;
  }

  public get amountUnitTextNoUnitOnly(): string | null {
    return getAmountUnitText(
      this.amount,
      this.unitDisplayValue,
      true,
    );
  }

  @Type(() => FileModel)
  public image?: FileModel;

  @Type(() => User)
  public author!: User;

  @Type(() => Thread)
  public thread?: Thread;

  @Type(() => Resource)
  public resource!: Resource;

  @Type(() => MetadataThread)
  public metadata_thread!: MetadataThread;

  @Type(() => ProtocolValue)
  public protocol_value_origin?: ProtocolValue;

  @Type(() => ResourceLocation)
  public resource_location?: ResourceLocation;

  @Type(() => PermaLink)
  public perma_link!: PermaLink;

  @Type(() => OrderRequest)
  public order_request!: OrderRequest;

  @Type(() => EntityUser)
  public entity_users_preview!: EntityUser[];

  public status!: ResourceItemStatus;

  public static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
    isTemplate?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
      isTemplate,
    );
  }
}
