/**
 * Labstep
 *
 * @module components/User/Form/ShowEdit/Biography
 * @desc Show edit for user biography
 */

import React from 'react';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import Pre from 'labstep-web/core/Pre';
import { fieldUserBiography } from 'labstep-web/components/User/Form/fields';
import { IUserFormShowEditBiographyProps } from './types';

export const UserFormShowEditBiography: React.FC<
  IUserFormShowEditBiographyProps
> = ({ user }) => (
  <ShowEditText
    optimistic
    entityName={user.entityName}
    entity={user}
    field={fieldUserBiography}
    id={user.id}
  >
    <Pre>{user.biography}</Pre>
  </ShowEditText>
);

export default UserFormShowEditBiography;
