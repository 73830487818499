/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/InputOutput
 * @desc Metadata field type input/output
 */

import React from 'react';
import EntityActionUpdateDropdownPill from 'labstep-web/components/Entity/Form/ShowEdit/InputOutput';
import { IMetadataShowEditInputOutputProps } from './types';

export const MetadataShowEditInputOutput: React.FC<
  IMetadataShowEditInputOutputProps
> = ({ metadata }) => (
  <EntityActionUpdateDropdownPill entity={metadata} />
);

export default MetadataShowEditInputOutput;
