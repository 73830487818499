/**
 * Labstep
 *
 * @module prosemirror/components/ReactNodeViewPortals
 * @desc Integrates React into ProseMirror
 * @see https://github.com/johnkueh/prosemirror-react-nodeviews
 */

import React, { ReactText, useContext, useReducer } from 'react';

export const ReactNodeViewPortals: React.FC<{
  portals: ReactNodeViewPortalsState;
}> = ({ portals }): React.ReactElement => (
  <>{Object.values(portals).map((obj) => obj.portal)}</>
);

const initialState = {};
const ReactNodeViewPortalsContext = React.createContext<{
  createPortal: (portal: any) => void;
  state: Partial<ReactNodeViewPortalsState>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  createPortal: () => {
    // Do nothing
  },
  state: {},
});

function reducer(state: ReactNodeViewPortalsState, action: Action) {
  switch (action.type) {
    case 'createPortal':
      return {
        ...state,
        [action.key]: {
          portal: action.portal,
        },
      };
    default:
      return state;
  }
}

const ReactNodeViewPortalsProvider: React.FC<{
  children: ({
    data,
  }: {
    data: ReactNodeViewPortalsState;
  }) => React.ReactNode;
}> = ({ children }) => {
  const [data, dispatch] = useReducer(reducer, initialState);

  return (
    <ReactNodeViewPortalsContext.Provider
      value={{
        createPortal: (portal: any) => {
          return dispatch({
            type: 'createPortal',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            key: portal.key!,
            portal,
          });
        },
        state: data,
      }}
    >
      {children({ data })}
    </ReactNodeViewPortalsContext.Provider>
  );
};

export type ReactNodeViewPortalsState = {
  [key: string]: any;
};

type Action = {
  type: 'createPortal';
  key: ReactText;
  portal: any;
};

export const useReactNodeViewPortals = () =>
  useContext(ReactNodeViewPortalsContext);

export default ReactNodeViewPortalsProvider;
