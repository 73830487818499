/**
 * Labstep
 *
 * @module components/Group/SearchSelect
 * @desc Search groups
 */

import React from 'react';
import SearchSelect from 'labstep-web/core/Select/Search';
import { IGroupSearchSelectProps } from './types';

const GroupSearchSelect: React.FC<IGroupSearchSelectProps> = (
  props,
) => <SearchSelect searchKey="name" entityName="group" {...props} />;

export default GroupSearchSelect;
