/**
 * Labstep
 *
 * @module components/File/List
 * @desc List of comments
 */

import React from 'react';
import { humanReadableDate } from 'labstep-web/services/date.service';
import FileActionMenu from 'labstep-web/components/File/Action/Menu';
import List from 'labstep-web/core/List';
import GenericCard from 'labstep-web/core/Card/Generic';
import { FileNamePreview } from 'labstep-web/components/File/Table';
import { IFileListProps } from './types';

const FileList: React.FC<IFileListProps> = ({ files, actions }) => (
  <List id="files-list">
    {files.map((file) => (
      <GenericCard
        key={file.id}
        headerTitle={<FileNamePreview file={file} />}
        headerHeader={
          <>
            <span>CREATED ON</span>
            <div>{humanReadableDate(file.created_at)}</div>
          </>
        }
        actionMenu={<FileActionMenu file={file} actions={actions} />}
      />
    ))}
  </List>
);

export default FileList;
