/**
 * Labstep
 *
 * @module components/Entity/Table
 * @desc Table listing all entities
 */

import React from 'react';
import Link from 'labstep-web/core/Link';
import FormShowEditLink from 'labstep-web/core/Form/ShowEdit/Link';
import { IRouting } from 'labstep-web/services/navigation.types';
import { fieldEntityName } from '../Form/fields';
import { IEntityLinkProps } from './types';
import EntityLinkComponent from './Component';

export const EntityLink: React.FC<IEntityLinkProps> = ({
  entity,
  editableName,
  showIcon,
  secondary,
  to,
  displayName,
  children,
  disabled,
}) => {
  const link = (
    <Link
      to={to || (`${entity.entityName}_show` as keyof IRouting)}
      params={{ id: entity.idAttr }}
      disabled={disabled}
    >
      {children || (
        <EntityLinkComponent
          secondary={secondary}
          showIcon={showIcon}
          displayName={displayName}
          entity={entity}
          disabled={disabled}
        />
      )}
    </Link>
  );

  if (editableName) {
    return (
      <FormShowEditLink
        link={link}
        entity={entity}
        field={fieldEntityName(entity.entityName)}
        value={entity.displayName}
        entityName={entity.entityName}
      />
    );
  }

  return link;
};

export default EntityLink;
