/**
 * Labstep
 *
 * @module core/Form/ShowEdit/SearchSelect/Modal
 * @desc A showedit modal component for search select
 */

import SearchSelectModal from 'labstep-web/core/Select/Search/Modal';
import { callIf } from 'labstep-web/services/react.service';
import React from 'react';
import { FormShowEditSearchSelectContainer } from '..';
import { ParentEntityType } from '../types';
import { IFormShowEditSearchSelectModalProps } from './types';

export const FormShowEditSearchSelectModal = <
  TParentEntity extends ParentEntityType,
>({
  parentEntity,
  childEntityName,
  childKeyName,
  optimistic,
  disabled,
  onChange: customOnChange,
  preventDefaultOnChange,
  ...rest
}: IFormShowEditSearchSelectModalProps<TParentEntity>) => (
  <FormShowEditSearchSelectContainer
    parentEntity={parentEntity}
    childKeyName={childKeyName}
    childEntityName={childEntityName}
    optimistic={optimistic}
    disabled={disabled}
  >
    {({
      onChange,
      status,
      canEdit,
      hasValue,
    }): React.ReactElement => (
      <SearchSelectModal
        entityName={childEntityName}
        hasValue={hasValue}
        canEdit={canEdit}
        onChange={(value): void => {
          if (!preventDefaultOnChange) {
            onChange(value);
          }
          callIf(customOnChange, value);
        }}
        status={status}
        {...rest}
      />
    )}
  </FormShowEditSearchSelectContainer>
);

export default FormShowEditSearchSelectModal;
