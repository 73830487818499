/**
 * Labstep
 *
 * @module models/device-data
 * @desc Typescript export class for DeviceData
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { Device } from 'labstep-web/models/device.model';
import { File as FileModel } from 'labstep-web/models/file.model';

// eslint-disable-next-line no-shadow
export enum DeviceDataType {
  default = 'default',
  numeric = 'numeric',
  file = 'file',
}

export const getDeviceDataPrintValue = (deviceData: DeviceData) => {
  if (deviceData.type === DeviceDataType.numeric) {
    if (deviceData.number && deviceData.unit) {
      return `${deviceData.number} ${deviceData.unit}`;
    }
    if (deviceData.number) {
      return `${deviceData.number}`;
    }
    return null;
  }
  if (deviceData.type === 'file') {
    return deviceData.files
      .map((file: FileModel) => file.name)
      .join(', ');
  }
  if (deviceData.type === DeviceDataType.default) {
    return deviceData.value;
  }

  return '';
};

export class DeviceData extends Entity {
  static readonly entityName = 'device_data';

  get entityName(): string {
    return DeviceData.entityName;
  }

  constructor(data: Partial<DeviceData> = {}) {
    super();
    Object.assign(this, data);
  }

  id: number;

  name: string;

  value: string;

  number: number;

  unit: string;

  type: DeviceDataType;

  @Type(() => FileModel)
  files: FileModel[];

  @Type(() => Device)
  device: Device;

  get printValue(): string {
    return getDeviceDataPrintValue(this);
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
