/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/Metadata
 * @desc Button to insert a Metadata node
 */

import MetadataActionCreateModal from 'labstep-web/components/Metadata/Action/Create/Modal';
import { ICONS } from 'labstep-web/constants/icons';
import Dropdown from 'labstep-web/core/Dropdown';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import { IMetadataToolbarProps } from './types';

const MetadataToolbar: React.FC<IMetadataToolbarProps> = ({
  entity,
  dispatch,
  state,
}) => (
  <MetadataActionCreateModal
    showSelectInModal
    entity={entity}
    entityName={entity.entityName}
    isTemplate={entity instanceof Protocol}
    options={{
      onSuccess: ({ response }) => {
        const { tr } = state;
        const content = state.schema.nodes.metadata.create({
          id: response.result,
        });
        tr.insert(
          state.selection.$from.pos,
          content,
        ).scrollIntoView();
        dispatch?.(tr);
      },
    }}
    actionComponentProps={{
      type: 'children',
      children: ({ onClick }) => (
        <Dropdown.Item
          onClick={onClick}
          icon={ICONS.metadata.default}
          text="Data"
        />
      ),
    }}
  />
);

export default MetadataToolbar;
