/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Commands/Menu/Element
 * @desc A commands menu element
 */

import React from 'react';
import Ref from 'labstep-web/core/Ref';
import { handleSubmitElement } from 'labstep-web/prosemirror/extensions/slash/commands';
import { getHeader } from 'labstep-web/prosemirror/components/Menu/Commands/elements';
import PremiumFeatureGuard from 'labstep-web/core/PremiumFeature/Guard';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import MenuItem from '../Item';
import { IMenuCommandsMenuElementProps } from './types';

const MenuCommandsMenuElement: React.FC<
  IMenuCommandsMenuElementProps
> = ({
  element,
  index,
  state,
  dispatch,
  i,
  activeItemRef,
  topLevelPermissionEntity,
}) => {
  const isActive = index === i;

  const item =
    element.premiumFeature && !element.premiumFeatureEnabled ? (
      <PremiumFeatureGuard
        key={element.id}
        premiumFeature={element.premiumFeature}
        component={({ toggleModal }): React.ReactElement => {
          const menuItem = (
            <MenuItem
              icon={element.icon}
              image={element.image}
              label={element.label}
              description={element.description}
              active={isActive}
              header={getHeader(element)}
              premiumFeature={element.premiumFeature}
              onClick={(): void => {
                if (toggleModal) {
                  toggleModal();
                } else {
                  handleSubmitElement(state, dispatch, i);
                }
              }}
            />
          );

          if (
            topLevelPermissionEntity &&
            element.premiumFeature === 'jupyter'
          ) {
            return (
              <Can
                entityName={topLevelPermissionEntity.entityName}
                id={topLevelPermissionEntity.id}
                action={Action.jupyter}
              >
                {menuItem}
              </Can>
            );
          }
          return menuItem;
        }}
      />
    ) : (
      <MenuItem
        icon={element.icon}
        image={element.image}
        label={element.label}
        description={element.description}
        active={isActive}
        header={getHeader(element)}
        onClick={(): void => {
          handleSubmitElement(state, dispatch, i);
        }}
      />
    );

  return isActive ? (
    <Ref key={element.id} innerRef={activeItemRef}>
      {item}
    </Ref>
  ) : (
    item
  );
};

export default MenuCommandsMenuElement;
