/**
 * Labstep
 *
 * @module screens/User/Show/Profile
 * @desc User Profile
 */

import React from 'react';
import UserBasic from 'labstep-web/components/User/Basic';
import LayoutLinksState from 'labstep-web/components/Layout/Links/State';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { IUserShowProfileProps } from './types';
import { getLinks } from './utils';

const UserShowProfile: React.FC<IUserShowProfileProps> = ({
  user,
  ...rest
}) => (
  <ModalDefault
    size="large"
    closeOnDimmerClick
    content={
      <div>
        <UserBasic user={user} />
        <LayoutLinksState tabs={getLinks(user)} />
      </div>
    }
    {...rest}
  />
);

export default UserShowProfile;
