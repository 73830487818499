/**
 * Labstep
 *
 * @module models/comment
 * @desc Typescript export class for Comment
 */

import { Type } from 'class-transformer';
import { DataTable } from 'labstep-web/models/data-table.model';
import { File as FileModel } from 'labstep-web/models/file.model';
import { Thread } from 'labstep-web/models/thread.model';
import { User } from 'labstep-web/models/user.model';
import { UserMention } from 'labstep-web/models/user-mention.model';
import { Resource } from 'labstep-web/models/resource.model';
import { RichText } from 'labstep-web/models/rich-text.model';
import { Molecule } from 'labstep-web/models/molecule.model';
import { Sequence } from 'labstep-web/models/sequence.model';
import { Entity } from 'labstep-web/models/entity.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

export class Comment extends Entity {
  static readonly entityName = 'comment';

  get entityName(): string {
    return Comment.entityName;
  }

  constructor(data: any = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  body!: string;

  /** FIXME Additional header (used for experiment step comment) */
  header?: string;

  locked_at?: string;

  pinned_at?: string;

  @Type(() => User)
  author!: User;

  // Mentioned users
  @Type(() => UserMention)
  user_mentions!: UserMention[];

  @Type(() => Thread)
  thread!: Thread;

  @Type(() => Thread)
  parent_thread!: Thread;

  @Type(() => DataTable)
  data_table!: DataTable;

  @Type(() => Resource)
  resource?: Resource;

  @Type(() => RichText)
  rich_text?: RichText;

  @Type(() => Molecule)
  molecule?: Molecule;

  @Type(() => Sequence)
  sequence?: Sequence;

  @Type(() => FileModel)
  files?: FileModel[];

  get parentThreadIds(): Thread['id'] | Thread['id'][] {
    if (!this.parent_thread) {
      return [];
    }

    return this.parent_thread.id;
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
