/**
 * Labstep
 */

import List from 'labstep-web/core/List';
import NumberCircle from 'labstep-web/core/Number/Circle';
import Desktop from 'labstep-web/core/MediaQuery/Desktop';
import React from 'react';
import styles from './styles.module.scss';
import { IModalWizardHeaderStepProps } from './types';

export const ModalWizardHeaderStep: React.FC<
  IModalWizardHeaderStepProps
> = ({ step, number, active, complete }) => (
  <List.Item>
    <Desktop>
      {({ match }) => (
        <div className={styles.step}>
          <NumberCircle
            number={number}
            primary={active}
            complete={complete}
          />
          {match ? step : null}
        </div>
      )}
    </Desktop>
  </List.Item>
);

export default ModalWizardHeaderStep;
