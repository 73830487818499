/**
 * Labstep
 */

export class GoogleError extends Error {
  readonly provider = 'google';

  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
