/**
 * Labstep
 *
 * @module core/SimpleTable
 * @desc Component that provides simplifed API for rendering tables by providng row and column functions
 */

import React from 'react';
import Table from 'labstep-web/core/Table';
import SimpleTableHeader from './Header';
import SimpleTableBody from './Body';
import { ISimpleTableProps } from './types';

export const SimpleTable: React.FC<ISimpleTableProps> = ({
  columns,
  rows,
  hideHeader,
  footer,
  selectedRowI,
  ...rest
}) => (
  <Table fixed {...rest}>
    {!hideHeader && <SimpleTableHeader columns={columns} />}
    <SimpleTableBody
      columns={columns}
      rows={rows}
      footer={footer}
      selectedRowI={selectedRowI}
    />
  </Table>
);

export default SimpleTable;
