/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/Table
 * @desc Button to insert a table
 */

import React from 'react';
import {
  addColumnAfter,
  addColumnBefore,
  addRowAfter,
  addRowBefore,
  deleteColumn,
  deleteRow,
  deleteTable,
  mergeCells,
  splitCell,
} from 'prosemirror-tables';
import ToolbarButton from 'labstep-web/prosemirror/components/Toolbar/Button';
import Dropdown from 'labstep-web/core/Dropdown';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ITableToolbarProps } from './types';

const TableToolbar: React.FC<ITableToolbarProps> = ({
  state,
  dispatch,
}) =>
  addRowBefore(state) ? (
    <Dropdown
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      trigger={<ToolbarButton popup="Table" icon="table" />}
      icon={null}
    >
      <ActionComponent
        type="option"
        text="Insert row above"
        disabled={!addRowBefore(state)}
        elementProps={{
          onMouseDown: (evt) => {
            evt.preventDefault();
            addRowBefore(state, dispatch);
          },
        }}
      />
      <ActionComponent
        type="option"
        text="Insert row below"
        disabled={!addRowAfter(state)}
        elementProps={{
          onMouseDown: (evt) => {
            evt.preventDefault();
            addRowAfter(state, dispatch);
          },
        }}
      />
      <ActionComponent
        type="option"
        text="Insert column left"
        disabled={!addColumnBefore(state)}
        elementProps={{
          onMouseDown: (evt) => {
            evt.preventDefault();
            addColumnBefore(state, dispatch);
          },
        }}
      />
      <ActionComponent
        type="option"
        text="Insert column right"
        disabled={!addColumnAfter(state)}
        elementProps={{
          onMouseDown: (evt) => {
            evt.preventDefault();
            addColumnAfter(state, dispatch);
          },
        }}
      />
      <ActionComponent
        type="option"
        text="Delete row(s)"
        disabled={!deleteRow(state)}
        elementProps={{
          onMouseDown: (evt) => {
            evt.preventDefault();
            deleteRow(state, dispatch);
          },
        }}
      />
      <ActionComponent
        type="option"
        text="Delete column(s)"
        disabled={!deleteColumn(state)}
        elementProps={{
          onMouseDown: (evt) => {
            evt.preventDefault();
            deleteColumn(state, dispatch);
          },
        }}
      />
      <ActionComponent
        type="option"
        text="Merge cells"
        disabled={!mergeCells(state)}
        elementProps={{
          onMouseDown: (evt) => {
            evt.preventDefault();
            mergeCells(state, dispatch);
          },
        }}
      />
      <ActionComponent
        type="option"
        text="Split cell"
        disabled={!splitCell(state)}
        elementProps={{
          onMouseDown: (evt) => {
            evt.preventDefault();
            splitCell(state, dispatch);
          },
        }}
      />
      <ActionComponent
        type="option"
        text="Delete table"
        disabled={!deleteTable(state)}
        elementProps={{
          onMouseDown: (evt) => {
            evt.preventDefault();
            deleteTable(state, dispatch);
          },
        }}
      />
    </Dropdown>
  ) : null;

export default TableToolbar;
