/**
 * Labstep
 *
 * @module components/Device/Card/EmptyState
 * @desc Empty state for device
 */

import React from 'react';
import EntityActionCreateMenu from 'labstep-web/components/Entity/Action/Create/Menu';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Button from 'labstep-web/core/Button';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { Device } from 'labstep-web/models/device.model';
import { IDeviceEmptyStateProps } from './types';

const label = getHumanReadableEntityName(
  Device.entityName,
  true,
  true,
);
const DeviceEmptyState: React.FC<IDeviceEmptyStateProps> = ({
  action,
}) => (
  <EmptyState
    src="/img/empty-state/devices.svg"
    title={`No ${label}`}
    explanation="Represent all the devices in your lab on Labstep. Set up devices to send data to Labstep. Fetch data from the device and attach to your samples and experiments in a single click."
    action={action}
    secondaryAction={
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://help.labstep.com/en/articles/4522313-instrument-device-integration-guide"
      >
        <Button as="a" primary basic>
          Find out more
        </Button>
      </a>
    }
  />
);

export default DeviceEmptyState;
