/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/List
 * @desc Button to insert a list
 */

import React from 'react';
import { liftListItem, sinkListItem } from 'prosemirror-schema-list';
import { toggleList } from 'labstep-web/prosemirror/nodes/list/commands';
import { isInList } from 'labstep-web/prosemirror/nodes/list/utils';
import { modToPlatformText } from 'labstep-web/prosemirror/utils';
import ToolbarButton from 'labstep-web/prosemirror/components/Toolbar/Button';
import { IListToolbarProps } from './types';

const ListToolbar: React.FC<IListToolbarProps> = ({
  state,
  dispatch,
}) => {
  const shortcutPrefix = modToPlatformText();
  return (
    <div style={{ display: 'flex' }}>
      <ToolbarButton
        icon="unordered list"
        popup={`Bullet List (${shortcutPrefix}+⇧+8)`}
        disabled={
          !toggleList(
            state.schema.nodes.bullet_list,
            state.schema.nodes.list_item,
          )(state)
        }
        isActive={isInList(state, 'bullet')}
        onMouseDown={() => {
          toggleList(
            state.schema.nodes.bullet_list,
            state.schema.nodes.list_item,
          )(state, dispatch);
        }}
      />
      <ToolbarButton
        icon="ordered list"
        popup={`Numbered List (${shortcutPrefix}+⇧+7)`}
        disabled={
          !toggleList(
            state.schema.nodes.ordered_list,
            state.schema.nodes.list_item,
          )(state)
        }
        isActive={isInList(state, 'ordered')}
        onMouseDown={() => {
          toggleList(
            state.schema.nodes.ordered_list,
            state.schema.nodes.list_item,
          )(state, dispatch);
        }}
      />
      <ToolbarButton
        icon="indent"
        popup="Increase Indent (Tab)"
        disabled={!sinkListItem(state.schema.nodes.list_item)(state)}
        onMouseDown={() => {
          sinkListItem(state.schema.nodes.list_item)(state, dispatch);
        }}
      />
      <ToolbarButton
        icon="outdent"
        popup="Decrease Indent (⇧+Tab)"
        disabled={!liftListItem(state.schema.nodes.list_item)(state)}
        onMouseDown={() => {
          liftListItem(state.schema.nodes.list_item)(state, dispatch);
        }}
      />
    </div>
  );
};

export default ListToolbar;
