/**
 * Labstep
 *
 * @module state/reducers/entity/creatingIds;
 * @desc Reducer for created entities
 */

import {
  isRequestCreateTypeAction,
  isSuccessCreateTypeAction,
} from 'labstep-web/state/constants/helpers';
import { requestStatus } from 'labstep-web/state/reducers/helpers';
import handleAction from 'labstep-web/state/reducers/entity/utils';

/**
 * Returns reducer for created entities
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} action - Redux action
 */
const creatingIds =
  (entityName: string, entityNameUppercase: string) =>
  (state: any = {}, action: any): any => {
    let args = {};

    // Add body as extra argument. Originaly used for invitation to keep track of invited user email.
    if (isRequestCreateTypeAction(action)) {
      args =
        action.type === 'REQUEST_CREATE_FILE'
          ? // Differentiate body for upload file vs create file (through picker)
            {
              body: action.meta.body.link_source
                ? action.meta.body
                : action.meta.upload.file,
              hideProgress: action.meta.body.link_source
                ? false
                : action.meta.upload.hideProgress,
              progress: 0,
            }
          : { body: action.meta.body };
    }

    if (
      action.type === 'FILE_UPLOAD_PROGRESS' &&
      entityName === 'file'
    ) {
      return requestStatus(state, action, action.meta.upload.key, {
        progress: Math.round(action.payload.progress),
      });
    }

    // Add newly create entity id as extra argument
    if (
      isSuccessCreateTypeAction(action) &&
      !Array.isArray(action.payload.result)
    ) {
      args = { id: action.payload.result };
    }
    return handleAction(
      state,
      action,
      'CREATE',
      action.meta && action.meta.uuid,
      entityName,
      entityNameUppercase,
      args,
    );
  };

export default creatingIds;
