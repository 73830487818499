/**
 * Labstep
 */

import { Action } from 'labstep-web/components/File/Action/Menu/types';

export const actions = [
  Action.download,
  Action.preview,
  Action.delete,
];
