/**
 * Labstep
 *
 * @module components/CustomIdentifierSet/Preview
 * @desc Preview for Custom Identifier Set
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import Hoverable from 'labstep-web/core/Hoverable';
import React from 'react';
import styles from './styles.module.scss';
import { ICustomIdentifierSetPreviewProps } from './types';

export const CustomIdentifierSetPreview: React.FC<
  ICustomIdentifierSetPreviewProps
> = ({ customIdentifierSet, canCreate }) => {
  return (
    <Hoverable
      className={styles.preview}
      hoverable={
        <>
          <span>{`Prefix: ${customIdentifierSet.settings.prefix}`}</span>
          <span>{`Min. Digits: ${customIdentifierSet.settings.zero_padding}`}</span>
          <span>{`Preview: ${customIdentifierSet.getPreview()}`}</span>
        </>
      }
      component={
        canCreate ? (
          <EntityActionDelete
            entity={customIdentifierSet}
            actionComponentProps={{
              type: 'icon',
              icon: 'close',
            }}
            skipConfirmation
          />
        ) : null
      }
    />
  );
};

export default CustomIdentifierSetPreview;
