/**
 * Labstep
 */

import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import { PostFilterMetadatas } from 'labstep-web/services/postFilter/metadatas';

export const PostFilterResourceMetadatasDefault =
  new PostFilterMetadatas(
    [
      ['label', PostFilterComparison.eq],
      ['value', PostFilterComparison.eq],
    ],
    'resource.metadatas',
  );

export const PostFilterResourceMetadatasNumeric =
  new PostFilterMetadatas(
    [
      ['label', PostFilterComparison.eq],
      ['number', PostFilterComparison.eq],
    ],
    'resource.metadatas',
  );

export const PostFilterResourceMetadatasDate =
  new PostFilterMetadatas(
    [
      ['label', PostFilterComparison.eq],
      ['date', PostFilterComparison.eq],
    ],
    'resource.metadatas',
  );

export const PostFilterResourceMetadatasOptions =
  new PostFilterMetadatas(
    [
      ['label', PostFilterComparison.eq],
      ['options', PostFilterComparison.contains],
    ],
    'resource.metadatas',
  );
