/**
 * Labstep
 *
 * @module core/OutboundLink
 * @desc Link to a target url
 */

import React from 'react';

interface IOutboundLinkProps {
  [key: string]: any;
}

export default class OutboundLink extends React.Component<IOutboundLinkProps> {
  static defaultProps = {
    target: null,
    to: null,
    onClick: null,
  };

  handleClick = (event: HTMLElementEventMap['click']) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(event);
    }
  };

  render() {
    const props = {
      ...this.props,
      href: this.props.to,
      onClick: this.handleClick,
    };
    return React.createElement('a', props);
  }
}
