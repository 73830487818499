/**
 * Labstep
 *
 * @module containers/ActiveEditModal
 * @desc Active Edit Modal container
 */

import { connect } from 'react-redux';
import {
  setActiveEditModal,
  selectActiveEditModal,
} from 'labstep-web/state/slices/activeEditModal';
import { IWithActiveEditModalInjectedProps } from './types';

const mapStateToProps = (state: any) => ({
  activeEditModal: selectActiveEditModal(state),
});

const mapDispatchToProps = {
  setActiveEditModal,
};

export const withActiveEditModal = <
  P extends IWithActiveEditModalInjectedProps,
  S,
>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<P, Exclude<keyof P, keyof IWithActiveEditModalInjectedProps>>
> => connect(mapStateToProps, mapDispatchToProps)(Component as any);
