/**
 * Labstep
 */

import { IActionComponentProps } from './types';

// temp fix for merging elementProps
// TODO: merge by type
export const addActionComponentPropsToDefault = (
  defaultActionComponentProps: IActionComponentProps,
  extraActionComponentProps,
) => {
  const { elementProps, ...rest } = defaultActionComponentProps;

  return {
    ...rest,
    ...extraActionComponentProps,
    elementProps: {
      ...elementProps,
      ...extraActionComponentProps?.elementProps,
    },
  };
};
