/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/Required
 * @desc Metadata field required
 */

import React from 'react';
import EntityActionUpdateCheckbox from 'labstep-web/components/Entity/Action/Update/Checkbox';
import { IMetadataActionUpdateIsRequiredProps } from './types';

export const MetadataActionUpdateIsRequired: React.FC<
  IMetadataActionUpdateIsRequiredProps
> = ({ metadata }) => (
  <EntityActionUpdateCheckbox
    entity={metadata}
    field={'is_required' as const}
    optimistic
    toggle={false}
  />
);

export default MetadataActionUpdateIsRequired;
