/**
 * Labstep
 *
 * @module models/order
 * @desc Typescript export class for PurchaseOrder
 */

import { Type } from 'class-transformer';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import { Entity } from 'labstep-web/models/entity.model';
import { Group } from 'labstep-web/models/group.model';
import { Log } from 'labstep-web/models/log.model';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { ShareLink } from 'labstep-web/models/share-link.model';
import { Thread } from 'labstep-web/models/thread.model';
import { User } from 'labstep-web/models/user.model';
import CurrencyService, {
  Currency,
} from 'labstep-web/services/currency.service';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { PermissionActions } from 'labstep-web/typings';
import { Metadata } from './metadata';
import { PermaLink } from './perma-link.model';

// eslint-disable-next-line no-shadow
export enum PurchaseOrderStatus {
  open = 'open',
  pending = 'pending',
  completed = 'completed',
}

// eslint-disable-next-line no-shadow
export enum PurchaseOrderStatusValues {
  open = 'Open',
  pending = 'Pending',
  completed = 'Completed',
}

export class PurchaseOrder extends Entity {
  static readonly entityName = 'purchase_order';

  get entityName(): typeof PurchaseOrder.entityName {
    return PurchaseOrder.entityName;
  }

  id!: number;

  static get defaultMetadatas(): Metadata[] {
    return [
      new Metadata({
        label: 'PO#',
        type: MetadataType.default,
        is_required: false,
      }),
      new Metadata({
        label: 'Confirmation#',
        type: MetadataType.default,
        is_required: false,
      }),
      new Metadata({
        label: 'Tracking#',
        type: MetadataType.default,
        is_required: false,
      }),
      new Metadata({
        label: 'Invoice#',
        type: MetadataType.default,
        is_required: false,
      }),
      new Metadata({
        label: 'Shipping & Handling',
        type: MetadataType.default,
        is_required: false,
      }),
      new Metadata({
        label: 'Vendor',
        type: MetadataType.default,
        is_required: false,
      }),
      new Metadata({
        label: 'Vendor URL',
        type: MetadataType.default,
        is_required: false,
      }),
    ];
  }

  constructor(data: Partial<PurchaseOrder> = {}) {
    super();
    Object.assign(this, data);
  }

  entity_users_count!: number;

  currency!: Currency;

  handling_amount!: number;

  discount_amount!: number;

  allowed_actions!: PermissionActions[];

  allowed_actions_lock!: PermissionActions[];

  @Type(() => Group)
  owner!: Group;

  @Type(() => User)
  author!: User;

  @Type(() => Thread)
  thread!: Thread;

  @Type(() => ShareLink)
  share_link!: ShareLink;

  @Type(() => PermaLink)
  perma_link!: PermaLink;

  @Type(() => MetadataThread)
  metadata_thread!: MetadataThread;

  @Type(() => OrderRequest)
  order_requests!: OrderRequest[];

  @Type(() => EntityUser)
  entity_users_preview!: EntityUser[];

  name!: string;

  status!: PurchaseOrderStatus;

  @Type(() => Log)
  pending_log!: Log;

  @Type(() => Log)
  completed_log!: Log;

  order_request_count!: number;

  amount_total!: number;

  tax_rate!: number;

  locked_at!: string | null;

  amount_sub_total!: number;

  is_template!: boolean;

  get totalWithVat(): number {
    return this.amount_total * (1 + (this.tax_rate || 0) / 100);
  }

  get printTotalWithVat(): string {
    return CurrencyService.print(this.totalWithVat, this.currency);
  }

  get printVat(): string {
    if (this.tax_rate === null) {
      return '';
    }
    return `${this.tax_rate}%`;
  }

  get printHandling(): string {
    return CurrencyService.print(this.handling_amount, this.currency);
  }

  get printDiscount(): string {
    return `-${CurrencyService.print(
      this.discount_amount,
      this.currency,
    )}`;
  }

  get printSubTotal(): string {
    return CurrencyService.print(
      this.amount_sub_total,
      this.currency,
    );
  }

  get printTotal(): string {
    return CurrencyService.print(this.amount_total, this.currency);
  }

  get printStatus(): string {
    return PurchaseOrderStatusValues[this.status];
  }

  get nonAttachedOrderRequests(): OrderRequest[] {
    return this.order_requests.filter(
      (orderRequest) => orderRequest.purchase_order !== null,
    );
  }

  get canAddOrRemoveOrderRequest(): boolean {
    return this.status === PurchaseOrderStatus.open;
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
