/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Action/ConfigureWorkflow
 * @desc Search tags
 */

import { VerticalDivider } from 'labstep-web/core/Divider';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import Message from 'labstep-web/core/Message';
import { EntityState } from 'labstep-web/models/entity-state.model';
import React from 'react';
import EntityStateWorkflowModalLeftPanel from '../../../Modal/LeftPanel';
import EntityStateWorkflowModalRightPanel from '../../../Modal/RightPanel';
import { IEntityStateWorkflowActionConfigureWorkflowContentProps } from './types';

export const EntityStateWorkflowActionConfigureWorkflowContent: React.FC<
  IEntityStateWorkflowActionConfigureWorkflowContentProps
> = ({ entityStateWorkflow, selectedEntityState }) => {
  const [selectedStateId, setSelectedStateId] = React.useState<
    EntityState['id'] | null
  >(selectedEntityState?.id || null);

  const selectedState = selectedStateId
    ? entityStateWorkflow.entity_states.find(
        (entityState) => entityState.id === selectedStateId,
      )
    : entityStateWorkflow.entity_states[0];

  return (
    <Flex column grow spacing="gap">
      {entityStateWorkflow &&
        entityStateWorkflow.experiment_workflow_template_count >
          0 && (
          <Message warning>
            <Icon name="exclamation triangle" />
            <b>Warning: </b>Any changes made here will affect{' '}
            <b>all</b> experiments using this Workflow.{' '}
          </Message>
        )}
      <Flex spacing="gap" data-testid="configure-workflow-content">
        <EntityStateWorkflowModalLeftPanel
          entityStateWorkflow={entityStateWorkflow}
          selectedState={selectedState}
          onSelect={setSelectedStateId}
        />
        <VerticalDivider stretch />
        <div
          data-testid="right-panel"
          style={{ paddingLeft: '10px' }}
        >
          {selectedState && (
            <EntityStateWorkflowModalRightPanel
              entityStateWorkflow={entityStateWorkflow}
              entityState={selectedState}
            />
          )}
        </div>
      </Flex>
    </Flex>
  );
};

export default EntityStateWorkflowActionConfigureWorkflowContent;
