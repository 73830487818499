/**
 * Labstep
 *
 * @module containers/ShareLinkEmail
 * @desc Email ShareLink container
 */

import {
  confirmMfaAction,
  confirmMfaActionType,
  disableMfaAction,
  disableMfaActionType,
  setupMfaAction,
  setupMfaActionType,
} from 'labstep-web/state/actions/mfa';
import { selectApiStatus } from 'labstep-web/state/selectors/ui';
import { connect } from 'react-redux';
import { IMfaContainerProps } from './types';

const Container = ({ children, ...rest }) => children(rest);

const mapStateToProps = (state) => ({
  setupMfaStatus: selectApiStatus(state, setupMfaActionType),
  confirmMfaStatus: selectApiStatus(state, confirmMfaActionType),
  disableMfaStatus: selectApiStatus(state, disableMfaActionType),
});

const mapDispatchToProps = (dispatch) => ({
  setupMfa(options) {
    dispatch(setupMfaAction(options));
  },
  confirmMfa(body, options) {
    dispatch(confirmMfaAction(body, options));
  },
  disableMfa(body, options) {
    dispatch(disableMfaAction(body, options));
  },
});

export const MfaContainer: React.FC<IMfaContainerProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
