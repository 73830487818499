/**
 * Labstep
 */

import { EntityUser } from 'labstep-web/models/entity-user.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';

export const checkUserAssignToSignature = (
  experimentWorkflow: ExperimentWorkflow,
  authenticatedUserEntityId: number,
  entityUsers: EntityUser[],
): boolean => {
  if (
    !experimentWorkflow.entity_state ||
    experimentWorkflow.entity_state
      .getSignaturesAtRound(
        experimentWorkflow.signatures,
        experimentWorkflow.entity_state_round,
      )
      .filter((signature) => !signature.revoked_at)
      .some(
        (signature) =>
          signature.author.id === authenticatedUserEntityId,
      )
  ) {
    return false;
  }

  return (
    experimentWorkflow.entity_state &&
    experimentWorkflow.entity_state.entity_user_role_requirements.some(
      (entityUserRoleRequirement) =>
        entityUserRoleRequirement.signature_requirement &&
        entityUsers
          .filter(
            (e) =>
              e.entity_user_role?.id ===
              entityUserRoleRequirement.entity_user_role.id,
          )
          .some(
            (entityUser) =>
              entityUser.user.id === authenticatedUserEntityId,
          ),
    )
  );
};
