/**
 * Labstep
 *
 * @module state/reducers/ui-persistent
 * @desc Reducers for ui (persistent - will be rehydrated on app boot)
 */

import { ERRORS } from 'labstep-web/constants/error';
import { Action } from 'labstep-web/models/action.model';
import {
  IFilters,
  IUiPersistentState,
  SetEntityViewParamsActionType,
  State,
  TypeSetUiCountPerPageAction,
  TypeSetUiExperimentWorkflowViewAction,
  TypeSetUiSearchParamsAction,
  TypeToggleUiFilterAction,
} from 'labstep-web/state/actions/ui-persistent.types';
import isEqual from 'lodash/isEqual';
import { combineReducers } from 'redux';

export const initialStateFilters: IFilters = {
  device: {
    assignee: {
      state: State.collapsed,
    },
    created_by: {
      state: State.collapsed,
    },
    tag: {
      state: State.collapsed,
    },
  },
  entity_state: {
    unstarted: {
      state: State.collapsed,
    },
    started: {
      state: State.collapsed,
    },
    completed: {
      state: State.collapsed,
    },
  },
  experiment_workflow: {
    status: {
      state: State.collapsed,
    },
    status_type: {
      state: State.collapsed,
    },
    signatures: {
      state: State.collapsed,
    },
    assignee: {
      state: State.collapsed,
    },
    created_by: {
      state: State.collapsed,
    },
    tag: {
      state: State.collapsed,
    },
  },
  protocol_collection: {
    assignee: {
      state: State.collapsed,
    },
    created_by: {
      state: State.collapsed,
    },
    tag: {
      state: State.collapsed,
    },
  },
  resource: {
    assignee: {
      state: State.collapsed,
    },
    resource_template: {
      state: State.uncollapsed,
    },
    created_by: {
      state: State.collapsed,
    },
    tag: {
      state: State.collapsed,
    },
  },
  resource_item: {
    assignee: {
      state: State.collapsed,
    },
    status: {
      state: State.uncollapsed,
    },
    created_by: {
      state: State.collapsed,
    },
    tag: {
      state: State.collapsed,
    },
  },
  order_request: {
    assignee: {
      state: State.collapsed,
    },
    status: {
      state: State.uncollapsed,
    },
    created_by: {
      state: State.collapsed,
    },
    tag: {
      state: State.collapsed,
    },
  },
  purchase_order: {
    assignee: {
      state: State.collapsed,
    },
    status: {
      state: State.uncollapsed,
    },
    created_by: {
      state: State.collapsed,
    },
    tag: {
      state: State.collapsed,
    },
  },
  file: {
    extension: {
      state: State.uncollapsed,
    },
    created_by: {
      state: State.collapsed,
    },
    tag: {
      state: State.collapsed,
    },
  },
  tag: {
    created_by: {
      state: State.collapsed,
    },
    tag: {
      state: State.collapsed,
    },
    tag_type: {
      state: State.uncollapsed,
    },
  },
};

export const haveKeysChanged = (state) => {
  if (
    !isEqual(Object.keys(initialStateFilters), Object.keys(state))
  ) {
    return true;
  }
  return Object.keys(initialStateFilters).reduce(
    (result, entityKey) => {
      return (
        !isEqual(
          Object.keys(initialStateFilters[entityKey]),
          Object.keys(state[entityKey]),
        ) || result
      );
    },
    false,
  );
};

export const filters = (state = initialStateFilters, action) => {
  if (
    action &&
    [
      'SUCCESS_LOGOUT',
      'SUCCESS_INTERNAL_LOGIN',
      'READ_AUTHENTICATED_USER',
    ].includes(action.type)
  ) {
    if (haveKeysChanged(state)) {
      return initialStateFilters;
    }
  }
  if (action.type === 'SET_UI_FILTER') {
    const toggleUiFilterAction = action as TypeToggleUiFilterAction;
    const oldFilterState =
      state[toggleUiFilterAction.entityName][
        toggleUiFilterAction.filter
      ].state;
    const newFilterState =
      oldFilterState === State.collapsed
        ? State.uncollapsed
        : State.collapsed;
    return {
      ...state,
      [toggleUiFilterAction.entityName]: {
        ...state[toggleUiFilterAction.entityName],
        [toggleUiFilterAction.filter]: {
          state: newFilterState,
        },
      },
    };
  }

  return state;
};

export const activeShareLink = (state = null, action) => {
  if (
    action.type === 'SUCCESS_READ_SHARE_LINK' &&
    !action.meta.denormalized_payload.deleted_at
  ) {
    return action.payload.result;
  }

  const errorCode =
    action.error && action.error.data && action.error.data.error_code;
  if (
    errorCode &&
    [
      ERRORS.SHARE_LINK_ORGANIZATION_EXPIRED,
      ERRORS.SHARE_LINK_ORGANIZATION_ALREADY_MEMBER,
      ERRORS.SHARE_LINK_NOT_FOUND,
      ERRORS.SHARE_LINK_DELETED,
      ERRORS.SHARE_LINK_ACCESS_DENIED,
    ].indexOf(errorCode) > -1
  ) {
    return null;
  }

  if (
    action.type.startsWith('REQUEST_READ') &&
    action.type.includes('GROUP')
  ) {
    return null;
  }

  if (action.type === 'SUCCESS_LOGOUT') {
    return null;
  }

  return state;
};

export const experimentWorkflowView = (
  state = 'table',
  action: TypeSetUiExperimentWorkflowViewAction,
) => {
  if (action.type === 'SET_UI_EXPERIMENT_WORKFLOW_VIEW') {
    return action.view;
  }

  return state;
};

export const searchParams = (
  state = {},
  action: TypeSetUiSearchParamsAction,
) => {
  if (action.type === 'SET_UI_SEARCH_PARAMS') {
    return {
      ...state,
      [action.pathname]: action.params,
    };
  }

  return state;
};

export const countPerPage = (
  state = 10,
  action: TypeSetUiCountPerPageAction,
) => {
  if (action.type === 'SET_UI_COUNT_PER_PAGE') {
    return action.count;
  }

  return state;
};

/**
 * @description Reducer to store timestamp of last request action
 */
export const lastActionTimestamp = (state = null, action) => {
  if (action.type.includes('REQUEST')) {
    return Date.now();
  }

  return state;
};

/**
 * @description Reducer to store entity view params
 */
export const entityViewParams = (
  // eslint-disable-next-line default-param-last
  state: IUiPersistentState['entity_view_params'] = {},
  action: Action,
): IUiPersistentState['entity_view_params'] => {
  if (action.type === SetEntityViewParamsActionType) {
    const { entityName, params, groupId } = action.meta.body;

    return {
      ...state,
      [groupId]: {
        ...state[groupId],
        [entityName]: params,
      },
    };
  }

  return state;
};

const reducers = combineReducers({
  filters,
  active_share_link: activeShareLink,
  experiment_workflow_view: experimentWorkflowView,
  count_per_page: countPerPage,
  search_params: searchParams,
  // temporary solution to store entity params in redux
  // until we store them in the EntityView entity
  entity_view_params: entityViewParams,
});

export default reducers;
