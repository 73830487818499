/**
 * Labstep
 *
 * @module
 * @desc Typescript export class for NotificationAlert
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { Metadata } from 'labstep-web/models/metadata';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

export class NotificationAlert extends Entity {
  public static readonly idAttr = 'guid' as const;

  public static readonly entityName = 'notification_alert';

  public get entityName(): string {
    return NotificationAlert.entityName;
  }

  public constructor(data: Partial<NotificationAlert> = {}) {
    super();
    Object.assign(this, data);
  }

  public id: number;

  public minutes_before: number;

  public message: string;

  @Type(() => Metadata)
  public metadata: Metadata;

  public static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
