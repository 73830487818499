/**
 * Labstep
 *
 * @module components/ResourceItem/PermaLinkReader
 * @desc Resource Item PermaLinkReader
 */

import PermaLinkActionQRScan from 'labstep-web/components/PermaLink/Action/QRScan';
import PermaLinkReader from 'labstep-web/components/PermaLink/Reader';
import ResourceUpdateStockCheckPreview from 'labstep-web/components/ResourceItem/Action/UpdateStockCheck/Preview';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React from 'react';
import styles from './styles.module.scss';
import { IResourceItemPermaLinkReaderProps } from './types';

export const getError = (error) => {
  const { status } = error;
  if (status) {
    if (status === 404) {
      return (
        <EmptyState
          src="/img/error/404.svg"
          title="Oops!"
          explanation="Looks like that item doesn’t exist"
        />
      );
    }
    if (status === 403) {
      return (
        <EmptyState
          src="/img/error/locked.svg"
          title="You don't have the right permissions to check in this item"
          explanation=""
        />
      );
    }
  }
  return null;
};

export const ResourceItemPermaLinkReader: React.FC<
  IResourceItemPermaLinkReaderProps
> = ({ resourceLocation }) => (
  <div className={styles.actionContainer}>
    <PermaLinkReader description="Scan the QR code on an item to check it in">
      {({ id, reset }) => (
        <div className={styles.actionContainer}>
          <ReadOnMountHOC
            type="entities"
            entityName={ResourceItem.entityName}
            params={{ id, get_single: 1 }}
            loading={{
              loader: 'placeholder',
              customError: (error) => getError(error),
            }}
            children={({ entity }) => (
              <ResourceUpdateStockCheckPreview
                resourceItem={entity}
                resourceLocation={resourceLocation}
              />
            )}
          />
          <PermaLinkActionQRScan
            actionComponentProps={{
              text: 'Scan Next Item',
              onClick: reset,
            }}
          />
        </div>
      )}
    </PermaLinkReader>
  </div>
);

export default ResourceItemPermaLinkReader;
