/**
 * Labstep
 *
 * @module screens/ResourceItem/Show/Center/SecondaryInfo
 * @desc ResourceItem Show Center Secondary Info
 */

import React from 'react';
import EntityLink from 'labstep-web/components/Entity/Link';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import { getResourceItemDetails } from 'labstep-web/components/ResourceItem/Details';
import List from 'labstep-web/core/List';
import MetadataTableExtraRows from 'labstep-web/components/Metadata/Table/ExtraRows';
import Card from 'labstep-web/core/Card/Card';
import { IResourceItemShowCenterSecondaryInfoProps } from './types';

export const ResourceItemShowCenterContentSecondaryInfo: React.FC<
  IResourceItemShowCenterSecondaryInfoProps
> = ({ resourceItem }) => (
  <List>
    <Card>
      <MetadataTableExtraRows
        rows={getResourceItemDetails(resourceItem)}
        entity={resourceItem}
      />
    </Card>
    <Card
      data-testid="resource-item-show-metadatas-resource"
      size="mini"
      header={
        <>
          Metadata for <EntityLink entity={resourceItem.resource} />
        </>
      }
    >
      <MetadataManager entity={resourceItem.resource} />
    </Card>
    <Card
      data-testid="resource-item-show-metadatas"
      size="mini"
      header="Metadata specific to this item"
    >
      <MetadataManager entity={resourceItem} />
    </Card>
  </List>
);

export default ResourceItemShowCenterContentSecondaryInfo;
