/**
 * Labstep
 *
 * @module config/saml
 * @desc Organizations using SAML SSO
 */

export interface IOrganization {
  identifier: string;
  displayName: string | null;
  domain?: string;
}

export const ORGANIZATIONS: IOrganization[] = [
  {
    identifier: 'crick_production',
    displayName: 'Crick Institute',
    domain: 'labstep.crick.ac.uk',
  },
  {
    identifier: 'crick_production',
    displayName: 'Crick Institute',
    domain: 'crick.labstep.com',
  },
  {
    identifier: 'crick',
    displayName: 'Crick Institute',
  },
  {
    identifier: 'dri_staging',
    displayName: 'DRI (Staging)',
  },
  {
    identifier: 'dri_production',
    displayName: 'DRI',
    domain: 'ukdri.labstep.com',
  },
  {
    identifier: 'dri',
    displayName: 'DRI',
  },
  {
    identifier: 'exscientia_staging',
    displayName: 'Exscientia (Staging)',
  },
  {
    identifier: 'exscientia_production',
    displayName: 'Exscientia',
  },
  {
    identifier: 'jumpcloud_staging',
    displayName: 'Labstep JumpCloud (Staging)',
  },
  {
    identifier: 'jumpcloud_production',
    displayName: 'Labstep JumpCloud (Production)',
  },
  {
    identifier: 'azure_staging',
    displayName: 'Labstep Azure (Staging)',
  },
  {
    identifier: 'azure_production',
    displayName: 'Labstep Azure (Production)',
  },
  {
    identifier: 'futuremeat_staging',
    displayName: 'Future Meat (Staging)',
  },
  {
    identifier: 'futuremeat_production',
    displayName: 'Future Meat',
  },
  {
    identifier: 'ochrebio_production',
    displayName: 'Ochre Bio',
  },
];
