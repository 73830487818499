/**
 * Labstep
 *
 * @module components/Entity/DataGrid/Action/ToggleColumnOption
 * @desc Entity DataGrid Action Toggle Column Option
 */

import Dropdown from 'labstep-web/core/Dropdown';
import Checkbox from 'labstep-web/core/Checkbox';
import { EntityViewContextColumnOptionsConsumer } from 'labstep-web/components/EntityView/context';
import React from 'react';
import { EntityViewColumnOptions } from 'labstep-web/models/entity-view.model';
import styles from './styles.module.scss';

export const EntityDataGridActionToggleColumnOption: <
  T extends keyof EntityViewColumnOptions,
>(props: {
  colId: T;
  columnOption: keyof EntityViewColumnOptions[T];
  columnName: string;
}) => JSX.Element | null = ({ colId, columnOption, columnName }) => (
  <EntityViewContextColumnOptionsConsumer colId={colId}>
    {({ columnOptions, setColumnOptions }) => (
      <Dropdown.Item className={styles.option}>
        <div>Show {columnName}</div>
        <Checkbox
          toggle
          checked={!!columnOptions[columnOption]}
          onChange={() => {
            setColumnOptions({
              [columnOption]: !columnOptions[columnOption],
            } as Partial<EntityViewColumnOptions[typeof colId]>);
          }}
        />
      </Dropdown.Item>
    )}
  </EntityViewContextColumnOptionsConsumer>
);

export default EntityDataGridActionToggleColumnOption;
