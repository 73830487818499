/**
 * Labstep
 *
 * @module components/EntityImport/Action/Create/Steps/FieldDefinitions/Fields
 * @desc Fields for EntityImport Create Step 3: FieldDefinitions
 */

import MetadataActionCreateModal from 'labstep-web/components/Metadata/Action/Create/Modal';
import MetadataActionDelete from 'labstep-web/components/Metadata/Action/Delete';
import MetadataActionEdit from 'labstep-web/components/Metadata/Action/Edit';
import ResourceItemActionCreateTemplateMetadata from 'labstep-web/components/ResourceItem/Action/Create/Template/Metadata';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import ActionMenu from 'labstep-web/core/Action/Menu';
import Dropdown from 'labstep-web/core/Dropdown';
import Label from 'labstep-web/core/Label';
import TextWithHelp from 'labstep-web/core/Text/WithHelp';
import TextWithIcon from 'labstep-web/core/Text/WithIcon';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { MetadataTypeValues } from 'labstep-web/models/metadata/types';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import { AGGridEntityImportExcelService } from 'labstep-web/services/agGrid/ag-grid-entity-import-excel.service';
import { AGGridEntityImportService } from 'labstep-web/services/agGrid/ag-grid-entity-import.service';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import partition from 'lodash/partition';
import React from 'react';
import EntityImportActionCreateStepsFieldDefinitionsFieldsTable from './Table';
import { IEntityImportActionCreateStepsFieldDefinitionsFieldsProps } from './types';

export const getDropdownActionComponentProps = (entityName: string) =>
  ({
    type: 'option',
    icon: 'plus',
    text: `New ${getHumanReadableEntityName(
      entityName,
      false,
      true,
    )} Metadata`,
  }) as const;

export const textActionComponentProps = {
  type: 'text',
  icon: 'plus',
  text: 'Add Field',
} as const;

export const EntityImportActionCreateStepsFieldDefinitionsFields: React.FC<
  IEntityImportActionCreateStepsFieldDefinitionsFieldsProps
> = ({
  entityName,
  importData,
  headerRowIndex,
  headerMap,
  setHeaderMap,
  columnDefs,
  selectedTemplate,
  setNFieldsAdded,
}) => {
  const columnDefsNotMatched = columnDefs.filter(
    (colDef) =>
      Object.values(headerMap).indexOf(colDef.colId!) === -1,
  );
  const unmatchedHeaderMapKeys = Object.keys(headerMap).filter(
    (key) => !headerMap[key],
  );
  const unmatchedRows = unmatchedHeaderMapKeys.map((key) => {
    const preview = importData
      ? AGGridEntityImportExcelService.getColumnFirstValue(
          importData,
          key,
          headerRowIndex,
        )
      : '';

    const name = importData
      ? AGGridEntityImportExcelService.getCellValue(
          AGGridEntityImportExcelService.getHeaderRow(
            importData,
            headerRowIndex,
          )[key],
        )
      : '';
    return {
      id: key,
      name: importData
        ? AGGridEntityImportExcelService.getCellValue(
            AGGridEntityImportExcelService.getHeaderRow(
              importData,
              headerRowIndex,
            )[key],
          )
        : '',
      preview,
      fieldType: (
        <Dropdown trigger="Select">
          {columnDefsNotMatched.map((colDef) => (
            <ActionComponent
              type="option"
              key={colDef.colId}
              text={colDef.headerName}
              icon={colDef.headerComponentParams?.icon}
              cornerIcon={colDef.headerComponentParams?.cornerIcon}
              onClick={() => {
                setHeaderMap((prev) => ({
                  ...prev,
                  [key]: colDef.colId!,
                }));
              }}
            />
          ))}
          {entityName === ResourceItem.entityName && (
            <ResourceItemActionCreateTemplateMetadata
              defaultValues={{ label: name }}
              resource={selectedTemplate as Resource}
              actionComponentProps={getDropdownActionComponentProps(
                entityName,
              )}
              options={{
                onSuccess: ({ response }): void => {
                  const metadata =
                    response.entities.metadata[response.result];
                  setHeaderMap((prev) => ({
                    ...prev,
                    [key]: AGGridEntityImportService.getMetadataColId(
                      metadata,
                      ResourceItem.entityName,
                    ),
                  }));
                  setNFieldsAdded((prev) => prev + 1);
                },
              }}
            />
          )}
          <MetadataActionCreateModal
            defaultValues={{ label: name }}
            entity={selectedTemplate}
            isTemplate
            entityName={selectedTemplate.entityName}
            actionComponentProps={getDropdownActionComponentProps(
              selectedTemplate.entityName,
            )}
            disableAddAnother
            options={{
              onSuccess: ({ response }): void => {
                const metadata =
                  response.entities.metadata[response.result];
                setHeaderMap((prev) => ({
                  ...prev,
                  [key]: AGGridEntityImportService.getMetadataColId(
                    metadata,
                    selectedTemplate.entityName,
                  ),
                }));
                setNFieldsAdded((prev) => prev + 1);
              },
            }}
          />
        </Dropdown>
      ),
      message: {
        type: 'warning' as const,
        text: 'Unmatched column',
      },
      action: (
        <ActionComponent
          type="icon"
          elementProps={{
            color: 'red',
            noBackground: true,
            popup: {
              content: 'Remove column from import',
            },
          }}
          icon="minus circle"
          onClick={() => {
            setHeaderMap((prev) => {
              const newHeaderMap = { ...prev };
              delete newHeaderMap[key];
              return newHeaderMap;
            });
          }}
        />
      ),
    };
  });

  let templateRows = columnDefs.map((colDef) => {
    const headerMapKey = Object.keys(headerMap).find(
      (key) => headerMap[key] === colDef.colId,
    );
    const preview =
      importData && headerMapKey
        ? AGGridEntityImportExcelService.getColumnFirstValue(
            importData,
            headerMapKey,
            headerRowIndex,
          )
        : '';
    const metadata = colDef.entity_import?.metadata;
    return {
      id: colDef.colId!,
      name: colDef.headerName!,
      entityName: colDef.entityName,
      fieldType: (
        <Label circular>
          <TextWithIcon
            icon={colDef.headerComponentParams?.icon}
            text={
              colDef.entity_import?.metadata
                ? MetadataTypeValues[
                    colDef.entity_import.metadata.type
                  ]
                : colDef.headerName!
            }
          />
        </Label>
      ),
      preview,
      message: headerMapKey
        ? {
            text: 'Field matched to file column',
            type: 'success' as const,
          }
        : undefined,
      action: (
        <ActionMenu>
          {headerMapKey ? (
            <ActionComponent
              type="option"
              icon="redo"
              text="Unmatch File Column"
              onClick={() => {
                setHeaderMap((prev) => ({
                  ...prev,
                  [headerMapKey]: null,
                }));
              }}
            />
          ) : null}
          {metadata ? (
            <>
              <MetadataActionEdit
                entity={selectedTemplate}
                metadata={metadata}
              />
              <MetadataActionDelete
                entity={selectedTemplate}
                metadata={metadata}
              />
            </>
          ) : null}
        </ActionMenu>
      ),
    };
  });

  let resourceRows: typeof templateRows = [];
  if (entityName === ResourceItem.entityName) {
    [resourceRows, templateRows] = partition(
      templateRows,
      (row) => row.entityName === Resource.entityName,
    );
  }

  return (
    <>
      {unmatchedRows.length ? (
        <EntityImportActionCreateStepsFieldDefinitionsFieldsTable
          dataTestId="unmatched-columns-table"
          header="Unmatched Columns"
          rows={unmatchedRows}
          headerNames={[
            'Column Name',
            <TextWithHelp
              key="field-type"
              text="Match to Template Field"
              helperText="Field types allow you to define what kind of data will be present in a field, and to edit specific settings related to the type.

                Some fields, such as Amount and Location, are expected by Labstep and so can't be changed and are displayed in grey."
            />,
            'Data Preview',
          ]}
        />
      ) : null}
      {entityName === ResourceItem.entityName && (
        <div>
          <EntityImportActionCreateStepsFieldDefinitionsFieldsTable
            dataTestId="resource-fields-table"
            header={`${getHumanReadableEntityName(
              Resource.entityName,
              false,
              true,
            )} Template`}
            action={
              <MetadataActionCreateModal
                entity={selectedTemplate}
                isTemplate
                entityName={selectedTemplate.entityName}
                actionComponentProps={textActionComponentProps}
              />
            }
            rows={resourceRows}
          />
        </div>
      )}
      <div>
        <EntityImportActionCreateStepsFieldDefinitionsFieldsTable
          dataTestId="template-fields-table"
          header={`${getHumanReadableEntityName(
            entityName,
            false,
            true,
          )} Template`}
          rows={templateRows}
          action={
            entityName === ResourceItem.entityName ? (
              <ResourceItemActionCreateTemplateMetadata
                resource={selectedTemplate as Resource}
                actionComponentProps={textActionComponentProps}
              />
            ) : (
              <MetadataActionCreateModal
                entity={selectedTemplate}
                isTemplate
                entityName={selectedTemplate.entityName}
                actionComponentProps={textActionComponentProps}
              />
            )
          }
        />
      </div>
    </>
  );
};

export const EntityImportActionCreateStepsFieldDefinitionsFieldsContainer: React.FC<
  IEntityImportActionCreateStepsFieldDefinitionsFieldsProps
> = ({ selectedTemplate, ...rest }) => (
  <ReadOnMountHOC
    type="entity"
    entityName={selectedTemplate.entityName}
    id={selectedTemplate.idAttr}
    loading={{ loader: 'placeholder' }}
  >
    {({ entity }) => (
      <EntityImportActionCreateStepsFieldDefinitionsFields
        selectedTemplate={entity}
        {...rest}
      />
    )}
  </ReadOnMountHOC>
);

export default EntityImportActionCreateStepsFieldDefinitionsFieldsContainer;
