/**
 * Labstep
 *
 * @module components/OrderRequest/Details
 * @desc Info about order request
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import OrderRequestActionUpdateStatus from 'labstep-web/components/OrderRequest/Action/UpdateStatus';
import OrderRequestFormShowEditPurchaseOrder from 'labstep-web/components/OrderRequest/Form/ShowEdit/PurchaseOrder';
import OrderRequestFormShowEditQuantity from 'labstep-web/components/OrderRequest/Form/ShowEdit/Quantity';
import OrderRequestPrice from 'labstep-web/components/OrderRequest/Price';
import { ICONS } from 'labstep-web/constants/icons';
import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import { ITableRowType } from 'labstep-web/core/Table/Horizontal/types';
import TextWithCount from 'labstep-web/core/Text/WithCount';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import React from 'react';
import OrderRequestActionUpdateUrgent from '../Action/UpdateUrgent';
import { IOrderRequestDetailsProps } from './types';

export const getOrderRequestDetails = () => {
  const rows: ITableRowType<OrderRequest>[] = [
    {
      header: <TextWithCount text="Resource" icon="flask" header />,
      content: (orderRequest) => (
        <EntityLink entity={orderRequest.resource} secondary />
      ),
    },
    {
      header: (
        <TextWithCount text="Status" icon="info circle" header />
      ),
      content: (orderRequest) => (
        <OrderRequestActionUpdateStatus
          orderRequest={orderRequest}
          withAdditionalInfo={false}
          alignAsRow
        />
      ),
    },
    {
      header: (
        <TextWithCount text="Urgent" icon="exclamation" header />
      ),
      content: (orderRequest) => (
        <OrderRequestActionUpdateUrgent orderRequest={orderRequest} />
      ),
    },
    {
      header: (
        <TextWithCount text="Number of Items" icon="hashtag" header />
      ),
      content: (orderRequest) => (
        <OrderRequestFormShowEditQuantity
          orderRequest={orderRequest}
        />
      ),
    },
    {
      header: (
        <TextWithCount
          text="Order"
          icon={ICONS.purchase_order.primary}
          header
        />
      ),
      content: (orderRequest) => (
        <OrderRequestFormShowEditPurchaseOrder
          orderRequest={orderRequest}
        />
      ),
    },
    {
      dataTestId: 'order-request-price',
      header: (
        <TextWithCount
          text="Price"
          icon="money bill alternate outline"
          header
        />
      ),
      content: (orderRequest) => (
        <OrderRequestPrice orderRequest={orderRequest} />
      ),
    },
  ];

  return rows;
};

export const OrderRequestDetails: React.FC<
  IOrderRequestDetailsProps
> = ({ orderRequest: entity }) => {
  return (
    <HorizontalTable
      padded
      borderless
      headerProps={{ width: 3 }}
      rows={getOrderRequestDetails()}
      columns={[entity]}
    />
  );
};

export default OrderRequestDetails;
