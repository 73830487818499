/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/CreateFromTemplate
 * @desc Create experiment workflow from a template
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import Container from 'labstep-web/components/Layout/Container';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import Link from 'labstep-web/core/Link';
import Modal from 'labstep-web/core/Modal/Default';
import SearchSelect from 'labstep-web/core/Select/Search';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import {
  IExperimentWorkflowActionCreateFromTemplateContainerProps,
  IExperimentWorkflowActionCreateFromTemplateWithActiveGroupProps,
} from './types';

export const ExperimentWorkflowActionCreateFromTemplateWithActiveGroup: React.FC<
  IExperimentWorkflowActionCreateFromTemplateWithActiveGroupProps
> = ({ create, history, activeGroup, body = {}, ...rest }) => (
  <Modal
    size="small"
    header={`New ${ExperimentWorkflow.getHumanReadableEntityName(
      false,
      true,
    )} from Template`}
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        onClick={toggleModal}
        text="From Template"
        type="option"
        {...rest}
      />
    )}
    content={
      <>
        <Container>
          <Link to="experiment_workflow_template_index" underline>
            Manage Templates
          </Link>
        </Container>
        <SearchSelect
          entityName={ExperimentWorkflow.entityName}
          placeholder="Search templates..."
          openMenuOnFocus
          autoFocus
          // https://github.com/Labstep/web/issues/6505
          isClearable={false}
          params={{ is_template: true, group_id: activeGroup?.id }}
          onChange={(option) => {
            create(
              {
                ...body,
                source_id: option.id,
              },
              {
                onSuccess: ({ response }) => {
                  history.push(
                    navigation.get('experiment_workflow_show', {
                      id: response.result,
                    }),
                  );
                },
              },
            );
          }}
        />
      </>
    }
  />
);

export const ExperimentWorkflowActionCreateFromTemplate =
  withActiveGroup(
    ExperimentWorkflowActionCreateFromTemplateWithActiveGroup,
  );

export const ExperimentWorkflowActionCreateFromTemplateContainer: React.FC<
  IExperimentWorkflowActionCreateFromTemplateContainerProps
> = (props) => (
  <CanCreate entityName="experiment_workflow">
    <EntityCreateContainer
      entityName={ExperimentWorkflow.entityName}
      showLoaderWhileCreating
    >
      {({ create, status }) => (
        <ExperimentWorkflowActionCreateFromTemplate
          create={create}
          status={status}
          {...props}
        />
      )}
    </EntityCreateContainer>
  </CanCreate>
);

export default withRouter(
  ExperimentWorkflowActionCreateFromTemplateContainer,
);
