/**
 * Labstep
 *
 * @module components/Resource/Form/ShowEdit/ResourceLocation
 * @desc ShowEdit for Resource Location
 */

import React from 'react';
import classnames from 'classnames';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import FormShowEditSelect from 'labstep-web/core/Form/ShowEdit/Select';
import Flex from 'labstep-web/core/Flex';
import {
  fieldResourceItemAmount,
  getFieldResourceItemUnit,
} from 'labstep-web/components/ResourceItem/Form/fields';
import styles from 'labstep-web/components/ProtocolValue/Form/ShowEdit/AmountUnit/styles.module.scss';
import stylesShowEdit from 'labstep-web/core/Form/ShowEdit/styles.module.scss';
import { IResourceItemFormShowEditResourceLocationProps } from './types';

const ResourceItemFormShowEdit: React.FC<
  IResourceItemFormShowEditResourceLocationProps
> = ({ resourceItem, disabled }) => (
  <Flex className={styles.container}>
    <ShowEditText
      entityName={resourceItem.entityName}
      entity={resourceItem}
      field={fieldResourceItemAmount}
      disabled={disabled}
    >
      {resourceItem.amount}
    </ShowEditText>
    <FormShowEditSelect
      creatable={{
        formatCreateLabel: (input) => `Custom unit "${input}"`,
      }}
      entity={resourceItem}
      field={getFieldResourceItemUnit(
        false,
        resourceItem.unit || undefined,
      )}
      disabled={disabled}
      customShow={({ toggle }) => (
        <div
          data-testid="show-edit-toggle"
          onClick={toggle}
          className={classnames(
            stylesShowEdit.toggleContainer,
            'toggle',
          )}
        >
          {resourceItem.unitDisplayValue}
        </div>
      )}
    >
      {resourceItem.unitDisplayValue}
    </FormShowEditSelect>
  </Flex>
);

export default ResourceItemFormShowEdit;
