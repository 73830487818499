/**
 * Labstep
 *
 * @module components/User/Settings/LoginSecurity
 * @desc Login Security section
 */

import React from 'react';
import ChangePassword from './ChangePassword';

const LoginSecurity: React.FC = () => (
  <div id="login-security">
    <span>Password</span>
    <ChangePassword />
  </div>
);

export default LoginSecurity;
