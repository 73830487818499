/**
 * Labstep
 *
 * @module screens/User/Show/Settings
 * @desc User Settings
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import LayoutLinksState from 'labstep-web/components/Layout/Links/State';
import { ICONS } from 'labstep-web/constants/icons';
import Icon from 'labstep-web/core/Icon';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import styles from './styles.module.scss';
import { IUserShowSettingsProps } from './types';
import { getLinks } from './utils';

const UserShowSettings: React.FC<IUserShowSettingsProps> = ({
  user,
  ...rest
}) => (
  <Can action={Action.edit} entityName={user.entityName} id={user.id}>
    <ModalDefault
      size="large"
      closeOnDimmerClick
      content={
        <div>
          <div className={styles.primaryInfo}>
            <Icon
              name={ICONS.navigation.settings}
              size="large"
              color="grey"
            />
            Account Settings
          </div>
          <LayoutLinksState tabs={getLinks(user)} inverted />
        </div>
      }
      {...rest}
    />
  </Can>
);

export default UserShowSettings;
