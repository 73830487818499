/**
 * Labstep
 *
 * @module containers/Entity/Read/Cursor
 * @desc Container for reading entities with cursor pagination
 */

import { Action } from 'labstep-web/models/action.model';
import { readEntitiesCursor } from 'labstep-web/state/actions/entity';
import {
  selectCurrentCursor,
  selectCursorItems,
  selectCursorTotal,
  selectNextCursor,
  selectReadCursorStatus,
} from 'labstep-web/state/selectors/entity';
import { LabstepReduxState } from 'labstep-web/state/types';
import { IOptions } from 'labstep-web/typings';
import { connect } from 'react-redux';
import {
  CursorOptions,
  IEntityReadCursorContainerChildrenProps,
  IEntityReadCursorContainerProps,
} from './types';

const Container = ({
  children,
  ...rest
}: {
  children: (
    props: IEntityReadCursorContainerChildrenProps,
  ) => React.ReactElement | null;
} & IEntityReadCursorContainerChildrenProps): React.ReactElement | null =>
  children(rest);

const mapStateToProps = (
  state: LabstepReduxState,
  ownProps: IEntityReadCursorContainerProps,
) => ({
  entities: selectCursorItems(
    state,
    ownProps.entityName,
    ownProps.params,
  ),
  status: selectReadCursorStatus(
    state,
    ownProps.entityName,
    ownProps.params,
  ),
  currentCursor: selectCurrentCursor(
    state,
    ownProps.entityName,
    ownProps.params,
  ),
  nextCursor: selectNextCursor(
    state,
    ownProps.entityName,
    ownProps.params,
  ),
  total: selectCursorTotal(
    state,
    ownProps.entityName,
    ownProps.params,
  ),
});

const mapDispatchToProps = (
  dispatch: (args: Action) => void,
  ownProps: IEntityReadCursorContainerProps,
) => ({
  /**
   * Read entities cursor
   * @param  {object} options - Additional options (onSucces, onFail e.t.c)
   * @param  {object} cursorOptions - Accepts 'mount' and 'refresh' bool fields
   * (overwrite all existing data with the newly fetched)
   */
  read(options?: IOptions, cursorOptions: CursorOptions = {}) {
    dispatch(
      readEntitiesCursor(
        ownProps.entityName,
        ownProps.params,
        options,
        cursorOptions,
      ),
    );
  },
});

/**
 * Read Cursor Container connecting a component with state
 * Returns orginal component with
 * read function, status of action, nextCursor and entities
 */
export const EntityReadCursorContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
