/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/DeviceData
 * @desc ShowEdit for DeviceData
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import FileViewer from 'labstep-web/components/File/Viewer';
import MetadataActionSetLatestDeviceData from 'labstep-web/components/Metadata/Action/SetLatestDeviceData';
import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import Flex from 'labstep-web/core/Flex';
import FormShowEditSearchSelect from 'labstep-web/core/Form/ShowEdit/SearchSelect';
import Popup from 'labstep-web/core/Popup';
import SubHeader from 'labstep-web/core/SubHeader';
import { DeviceData } from 'labstep-web/models/device-data.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import { IMetadataFormShowEditDeviceDataProps } from './types';
import {
  DeviceDataOptionComponent,
  deviceDataGetOptionLabel,
} from './utils';

export const MetadataFormShowEditDeviceData: React.FC<
  IMetadataFormShowEditDeviceDataProps
> = ({ metadata, entity }) => {
  const canEdit = useHasAccess(
    metadata.entityName,
    metadata.id,
    Action.edit,
    'device_data',
  );
  if (!metadata.protocol_device) {
    return null;
  }
  const style = { paddingLeft: 10, paddingRight: 10 };
  if (entity.entityName === Protocol.entityName) {
    return (
      <SubHeader style={style}>To be fetched from device</SubHeader>
    );
  }
  if (!metadata.device_data && canEdit) {
    return (
      <div style={style}>
        <MetadataActionSetLatestDeviceData metadata={metadata} />
      </div>
    );
  }
  const showComponent = (toggle: VoidFunction) => (
    <Flex>
      <Flex grow={1}>
        {metadata.device_data &&
        metadata.device_data.files &&
        metadata.device_data.files.length > 0 ? (
          <>
            {metadata.device_data.files
              .filter((file) => file.deleted_at === null)
              .map((file) => (
                <FileViewer key={file.guid} file={file} />
              ))}
          </>
        ) : (
          metadata.printValue
        )}
      </Flex>
      <Flex style={{ paddingLeft: 10 }}>
        <Popup
          inverted
          trigger={
            // Div needed to show popup
            <div>
              {canEdit && (
                <ActionComponent
                  onClick={toggle}
                  icon={ICONS.device.primary}
                  type="icon"
                />
              )}
            </div>
          }
          on="hover"
          content={`Fetched from ${metadata.protocol_device!.name}`}
        />
      </Flex>
    </Flex>
  );

  return (
    <FormShowEditSearchSelect
      parentEntity={metadata}
      childEntityName={DeviceData.entityName}
      searchSelectProps={{
        params: {
          type: metadata.type,
          device_id: metadata.protocol_device.device.id,
        },
        components: { Option: DeviceDataOptionComponent },
        getOptionLabel: deviceDataGetOptionLabel,
      }}
      customShow={({ toggle }) => showComponent(toggle)}
    />
  );
};

export default MetadataFormShowEditDeviceData;
