/**
 * Labstep
 *
 * @module services/logrocket
 * @desc Logrocket integration
 * @see https://docs.logrocket.com
 */

import { APP_VERSION } from 'labstep-web/constants/version';
import bugsnagService from 'labstep-web/services/bugsnag.service';
import { configService } from 'labstep-web/services/config.service';
import LogRocket from 'logrocket';
import { SensitiveService } from './sensitive.service';

export class LogRocketService {
  static init(): void {
    if (!configService.logrocketAppId) {
      return;
    }

    LogRocket.init(configService.logrocketAppId, {
      release: APP_VERSION,
      dom: {
        inputSanitizer: true,
        textSanitizer: true,
        privateClassNameBlocklist: ['lr-hide-this'],
      },
      network: {
        requestSanitizer: (request) =>
          SensitiveService.purifyRequest(request),
      },
    });
    LogRocket.getSessionURL((sessionURL) => {
      bugsnagService.addMetadata('LogRocket', { sessionURL });
    });
  }

  static identify(username: string, name: string): void {
    if (!configService.logrocketAppId) {
      return;
    }

    LogRocketService.init();
    LogRocket.identify(username, { name });
  }

  static log(data: Record<string, unknown>): void {
    if (!configService.logrocketAppId) {
      return;
    }

    LogRocket.log(data);
  }

  static captureException(error: Error): void {
    if (!configService.logrocketAppId) {
      return;
    }

    LogRocket.captureException(error);
  }
}
