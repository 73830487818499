/**
 * Labstep
 * @description This is to be the file where all utils for state (redux) will be held
 */

import sortBy from 'lodash/sortBy';

type IObject = { [key: string]: any };

export const orderObjectByKey = (object: IObject) =>
  sortBy(Object.keys(object)).reduce(
    (result, key) => ({ ...result, [key]: object[key] }),
    {},
  );

export const stringifyParamsInOrder = (object: IObject) =>
  JSON.stringify(orderObjectByKey(object));
