/**
 * Labstep
 *
 * @module components/Metadata/ShowEdit/Value/WithClear
 * @desc Rendering ShowEditValue + Clear action for metadata
 */

import React from 'react';
import FormShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import ActionComponent from 'labstep-web/core/Action/Component';
import { fieldUnit } from '../../fields';
import { IMetadataFormShowEditUnitProps } from './types';

const MetadataFormShowEditUnit: React.FC<
  IMetadataFormShowEditUnitProps
> = ({ metadata, createProps }) =>
  metadata.type === 'numeric' ? (
    <FormShowEditText
      createProps={createProps}
      entity={metadata}
      entityName={metadata.entityName}
      field={fieldUnit}
      hideValueIfCreateProps={false}
      customPlaceholder={({ toggle }): React.ReactElement => (
        <ActionComponent
          type="icon"
          icon="subscript"
          onClick={toggle}
          elementProps={{ popup: { content: 'Add unit(s)' } }}
        />
      )}
    >
      {metadata.unit}
    </FormShowEditText>
  ) : null;

export default MetadataFormShowEditUnit;
