/**
 * Labstep
 *
 * @module state/constants/file
 * @desc Constants for File
 */

export const FILE_UPLOAD_PROGRESS = 'FILE_UPLOAD_PROGRESS';

export const FILE_DOWNLOAD = 'FILE_DOWNLOAD';
