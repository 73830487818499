/**
 * Labstep
 *
 * @module components/UserOrganization/Action/ManagePermissions
 * @desc Update workspace permissions per user
 */

import EntitySearch from 'labstep-web/components/Entity/Search';
import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import { GroupEmptyState } from 'labstep-web/components/Group/Card/EmptyState';
import UserGroupTable from 'labstep-web/components/User/Table';
import UserGroupActionMenu from 'labstep-web/components/UserGroup/Action/Menu';
import { Action } from 'labstep-web/components/UserGroup/Action/Menu/types';
import { UserGroup } from 'labstep-web/models/user-group.model';
import React from 'react';
import GroupActionAddUser from '../../AddUser';
import { GroupActionShare } from '../../Share';
import { IGroupActionManageMembersTableProps } from './types';

export const GroupActionManageMembersTable: React.FC<
  IGroupActionManageMembersTableProps
> = ({ group }) => {
  const confirmMessage = `Are you sure you want to remove these members from ${group.name}?`;

  return (
    <EntitySearch
      entityName={UserGroup.entityName}
      params={{
        group_id: group.id,
      }}
      bulkActions={[
        {
          action: BulkAction.delete,
          props: {
            confirmMessage,
            header: 'Remove Members',
            confirmButtonContent: 'Remove',
            actionComponentProps: {
              type: 'button',
              icon: 'ban',
              text: 'Remove from Workspace',
            },
          },
        },
      ]}
      action={
        group.canInvite ? (
          <GroupActionShare
            actionComponentProps={{
              type: 'button',
            }}
            group={group}
          />
        ) : (
          <GroupActionAddUser group={group} />
        )
      }
      noResultsMessage={<GroupEmptyState isActionAllowed={false} />}
      searchBarProps={{
        placeholder: `Search ${group.name}'s members ...`,
      }}
    >
      {({ entities }) => (
        <UserGroupTable
          userGroups={entities}
          actionMenu={({ entity }: { entity: UserGroup }) => (
            <UserGroupActionMenu
              userGroup={entity}
              actions={[Action.delete_user_group]}
            />
          )}
        />
      )}
    </EntitySearch>
  );
};
