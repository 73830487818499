/**
 * Labstep
 *
 * @module screens/Resource/Show/Right
 * @desc Resource Show Right
 */

import EntityTimeMachine from 'labstep-web/components/Entity/TimeMachine';
import ExperimentWorkflowLinkedList from 'labstep-web/components/ExperimentWorkflow/LinkedList';
import RightPane from 'labstep-web/components/Layout/RightPane';
import MetadataManagerList from 'labstep-web/components/Metadata/Manager/List';
import MetadataShow, {
  MetadataShowName,
} from 'labstep-web/components/Metadata/Show';
import ProtocolDeviceManager from 'labstep-web/components/ProtocolDevice/Manager';
import ProtocolStepManager from 'labstep-web/components/ProtocolStep/Manager';
import ProtocolTimerManager from 'labstep-web/components/ProtocolTimer/Manager';
import ProtocolValueManager from 'labstep-web/components/ProtocolValue/Manager';
import ProtocolValueShow, {
  ProtocolValueShowName,
} from 'labstep-web/components/ProtocolValue/Show';
import ThreadForm from 'labstep-web/components/Thread/Form';
import { ICONS } from 'labstep-web/constants/icons';
import { IIconProps } from 'labstep-web/core/Icon/types';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import { IScreensProtocolCollectionShowRightProps } from './types';

const ScreensProtocolCollectionShowRight: React.FC<
  IScreensProtocolCollectionShowRightProps
> = ({ protocolCollection }) => (
  <RightPane
    route={{
      to: 'protocol_collection_show',
      params: { id: protocolCollection.id },
    }}
    subPages={[
      {
        name: 'Steps',
        icon: ICONS.protocol.step,
        count: protocolCollection.last_version.protocol_step_count,
        route: {
          to: 'protocol_collection_show_steps',
          params: { id: protocolCollection.id },
        },
        component: (
          <ProtocolStepManager
            protocol={protocolCollection.last_version}
            protocolCollection={protocolCollection}
          />
        ),
      },
      {
        name: 'Timers',
        icon: ICONS.protocol.timer,
        count: protocolCollection.last_version.protocol_timer_count,
        route: {
          to: 'protocol_collection_show_timers',
          params: { id: protocolCollection.id },
        },
        component: (
          <ProtocolTimerManager
            protocol={protocolCollection.last_version}
          />
        ),
      },
      {
        name: 'Inventory',
        icon: ICONS.resource.primary,
        count: protocolCollection.last_version.protocol_value_count,
        route: {
          to: 'protocol_collection_show_values',
          params: { id: protocolCollection.id },
        },
        component: (
          <ProtocolValueManager
            protocol={protocolCollection.last_version}
          />
        ),
        premiumFeature: 'inventory',
      },
      {
        name: 'Data',
        icon: ICONS.metadata.primary,
        count: protocolCollection.last_version.metadata_count,
        route: {
          to: 'protocol_collection_show_metadata',
          params: { id: protocolCollection.id },
        },
        component: (
          <MetadataManagerList
            entity={protocolCollection.last_version}
            isTemplate
          />
        ),
      },
      {
        name: 'Devices',
        icon: ICONS.device.primary,
        count: protocolCollection.last_version.protocol_device_count,
        route: {
          to: 'protocol_collection_show_devices',
          params: { id: protocolCollection.id },
        },
        component: (
          <ProtocolDeviceManager
            parent={protocolCollection.last_version}
          />
        ),
        premiumFeature: 'devices',
      },
      {
        name: 'Linked Experiments',
        icon: ICONS.experiment_workflow.primary,
        route: {
          to: 'protocol_collection_show_experiments',
          params: { id: protocolCollection.id },
        },
        component: (
          <ExperimentWorkflowLinkedList
            params={{ protocol_collection_id: protocolCollection.id }}
          />
        ),
      },
      {
        name: 'Notes',
        icon: ICONS.thread.primary,
        count: protocolCollection.thread.comment_count,
        route: {
          to: 'protocol_collection_show_thread',
          params: { id: protocolCollection.id },
        },
        component: (
          <ThreadForm
            createThreadId={protocolCollection.thread.id}
            readThreadIds={protocolCollection.thread.id}
            entityNameLabel="notes"
          />
        ),
      },
      {
        name: 'Activity',
        icon: 'history' as IIconProps['name'],
        route: {
          to: 'protocol_collection_show_activity',
          params: { id: protocolCollection.id },
        },
        component: (
          <EntityTimeMachine
            logEntity={protocolCollection}
            documentEntity={protocolCollection.last_version}
          />
        ),
      },
    ]}
    subPagesNested={[
      {
        icon: ICONS.protocol_value.primary,
        route: {
          to: 'protocol_collection_show_value_show',
        },
        backRoute: {
          to: 'protocol_collection_show_values',
          params: { id: protocolCollection.id },
        },
        component: ({ match }) => (
          <ProtocolValueShow id={match.params.childId} />
        ),
        name: ({ match }) => (
          <ProtocolValueShowName id={match.params.childId} />
        ),
      },
      {
        icon: ICONS.metadata.primary,
        route: {
          to: 'protocol_collection_show_metadata_show',
        },
        backRoute: {
          to: 'protocol_collection_show_metadata',
          params: { id: protocolCollection.id },
        },
        component: ({ match }) => (
          <MetadataShow
            id={match.params.childId}
            parentEntityName={Protocol.entityName}
          />
        ),
        name: ({ match }) => (
          <MetadataShowName id={match.params.childId} />
        ),
      },
    ]}
  />
);

export default ScreensProtocolCollectionShowRight;
