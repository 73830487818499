/**
 * Labstep
 */

import { OrganizationPlanPrice } from 'labstep-web/models/organization-plan-price.model';
import { CurrencyISO } from 'labstep-web/services/currency.service';

export const defaultCurrency: CurrencyISO = 'usd';
export const supportedCurrencies: CurrencyISO[] = [
  'usd',
  'gbp',
  'eur',
];

export const supportedIntervals: OrganizationPlanPrice['interval'][] =
  ['month', 'year'];

export const intervalLabels = {
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly',
  year: 'Annual',
} as const;

export type PlanInterval = keyof typeof intervalLabels; // 'day' | 'week' | 'month' | 'year'
