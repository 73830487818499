/**
 * Labstep
 */

import { liftListItem, wrapInList } from 'prosemirror-schema-list';
import { findParentNode } from 'prosemirror-utils';
import { ProseMirrorCommand } from 'labstep-web/prosemirror/marks/types';
import bugsnagService from 'labstep-web/services/bugsnag.service';

function isList(node, schema) {
  return (
    node.type === schema.nodes.bullet_list ||
    node.type === schema.nodes.ordered_list
  );
}

type TypeToggleList = (listType, itemType) => ProseMirrorCommand;

export const toggleList: TypeToggleList = (listType, itemType) => {
  return (state, dispatch) => {
    try {
      const { schema, selection } = state;
      const { $from, $to } = selection;
      const range = $from.blockRange($to);

      if (!range) {
        return false;
      }

      const parentList = findParentNode((node) =>
        isList(node, schema),
      )(selection);

      if (
        range.depth >= 1 &&
        parentList &&
        range.depth - parentList.depth <= 1
      ) {
        if (parentList.node.type === listType) {
          return liftListItem(itemType)(state, dispatch);
        }

        if (
          isList(parentList.node, schema) &&
          listType.validContent(parentList.node.content)
        ) {
          const { tr } = state;

          tr.setNodeMarkup(parentList.pos, listType);

          if (dispatch) {
            dispatch(tr);
          }

          return true;
        }
      }

      return wrapInList(listType)(state, dispatch);
    } catch (e) {
      bugsnagService.notify(e);
      return false;
    }
  };
};
