/**
 * Labstep
 *
 * @module components/Layout/LoadingEntityCreate
 * @desc Displays loading animation on entity creation
 */

import React from 'react';
import { objectOrFunction } from 'labstep-web/services/react.service';
import LoaderLogo from 'labstep-web/core/Loader/Logo';
import LayoutFullScreenPage from 'labstep-web/components/Layout/FullScreenPage';
import { getError, getStatus } from '../LoadingContent/utils';
import styles from './styles.module.scss';
import { ILoadingEntityCreateProps } from './types';

export const LoadingEntityCreate: React.FC<
  ILoadingEntityCreateProps
> = (props) => {
  const { defaultFetching, status, children, message } = props;

  const { isFetching, error } = getStatus(status, defaultFetching);

  if (isFetching) {
    return (
      <LayoutFullScreenPage>
        <div className={styles.fullPageLoader}>
          <LoaderLogo />
          <div className={styles.message}>{message}</div>
        </div>
      </LayoutFullScreenPage>
    );
  }

  if (error) {
    return (
      <LayoutFullScreenPage>
        <div className={styles.errorContent}>
          {getError(error, true)}
        </div>
      </LayoutFullScreenPage>
    );
  }

  return <>{objectOrFunction(children)}</>;
};
