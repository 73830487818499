/**
 * Labstep
 *
 * @module components/Folder/Form/ShowEdit/Name
 * @desc ShowEditName for Folder
 */

import React from 'react';
import FormShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import { Folder } from 'labstep-web/models/folder.model';
import { fieldName } from 'labstep-web/components/Folder/Form/fields';
import { IFolderFormShowEditNameProps } from './types';

export const FolderFormShowEditName: React.FC<
  IFolderFormShowEditNameProps
> = ({ folder }) => (
  <FormShowEditText
    entityName={Folder.entityName}
    entity={folder}
    field={fieldName}
  >
    {folder.name}
  </FormShowEditText>
);

export default FolderFormShowEditName;
