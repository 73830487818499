/**
 * Labstep
 *
 * @module components/EntityUser/Action/Add
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import SearchSelect from 'labstep-web/core/Select/Search';
import { useToggle } from 'labstep-web/hooks/toggle';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import { User } from 'labstep-web/models/user.model';
import React from 'react';
import { IEntityUserActionAddProps } from './types';

export const EntityUserActionAdd: React.FC<
  IEntityUserActionAddProps
> = ({ entity, activeGroup, entityUserRole }) => {
  const [isToggled, toggle] = useToggle();
  return (
    <Can
      entityName={entity.entityName}
      id={entity.idAttr}
      action={Action.assign}
    >
      <EntityCreateContainer
        entityName={EntityUser.entityName}
        parentId={entity.id}
        parentName={entity.entityName}
      >
        {({ create }) => {
          if (entityUserRole && !isToggled) {
            return (
              <ActionComponent
                type="button"
                text="Assign"
                onClick={toggle}
              />
            );
          }
          const additionalParams = entityUserRole
            ? {
                no_entity_user_role_experiment_workflow_id:
                  entity.guid,
              }
            : {};
          return (
            <SearchSelect
              value={null}
              autoFocus={!!entityUserRole}
              placeholder="Search for users to assign"
              entityName={User.entityName}
              onBlur={entityUserRole ? toggle : undefined}
              params={{
                group_id: activeGroup?.id,
                ...additionalParams,
              }}
              onChange={(selectedOption) => {
                create({
                  user_id: selectedOption.id,
                  is_assigned: true,
                  entity_user_role_id: entityUserRole
                    ? entityUserRole.id
                    : null,
                });
                if (entityUserRole) {
                  toggle();
                }
              }}
            />
          );
        }}
      </EntityCreateContainer>
    </Can>
  );
};

export default withActiveGroup(EntityUserActionAdd);
