/**
 * Labstep
 */

import {
  createSlice,
  type PayloadAction,
  type SliceCaseReducers,
} from '@reduxjs/toolkit';
import { ActiveMetabaseUrlState } from './types';

const initialState: ActiveMetabaseUrlState = null;

export const activeMetabaseUrlSlice = createSlice<
  ActiveMetabaseUrlState,
  SliceCaseReducers<ActiveMetabaseUrlState>
>({
  name: 'activeMetabaseUrl',
  initialState,
  reducers: {
    setActiveMetabaseUrl: (
      _,
      action: PayloadAction<ActiveMetabaseUrlState>,
    ) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type === 'SUCCESS_CREATE_METABASE_URL',
      (_, action) => action.payload,
    );
  },
});

export const selectActiveMetabaseUrl = (state: any) =>
  state.activeMetabaseUrl;

export const { setActiveMetabaseUrl } =
  activeMetabaseUrlSlice.actions;

export default activeMetabaseUrlSlice.reducer;
