/**
 * Labstep
 */

import { DeviceBooking } from 'labstep-web/models/device-booking.model';

export const formatEvent = (
  deviceBooking: DeviceBooking,
  disabled?: boolean,
) => ({
  id: deviceBooking.id.toString(),
  start: deviceBooking.started_at,
  end: deviceBooking.ended_at,
  extendedProps: { deviceBooking },
  editable: !disabled,
});
