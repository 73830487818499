/**
 * Labstep
 */

import ActionComponentLabel from 'labstep-web/core/Action/Component/Label';
import React from 'react';
import { SemanticICONS } from 'semantic-ui-react';
import SignatureListEntityStateCardsToBeAssigned from '../../EntityState/ToBeAssigned';
import styles from '../styles.module.scss';
import { SignatureListTimelineRequiredSignaturesProps } from './types';

export const SignatureListTimelineRequiredSignatures: React.FC<
  SignatureListTimelineRequiredSignaturesProps
> = ({ experimentWorkflow, entityUsers }) => {
  const numberOfSignaturesRequiredLeft =
    experimentWorkflow.getNumberOfSignaturesRequiredLeft(
      experimentWorkflow.entity_state,
    );
  const numberOfSignatureSigned =
    experimentWorkflow.entity_state.getSignaturesAtRound(
      experimentWorkflow.signatures,
      experimentWorkflow.entity_state_round,
    ).length;

  if (numberOfSignaturesRequiredLeft === 0) {
    return null;
  }

  return (
    <>
      <div className={styles.box}>
        <SignatureListEntityStateCardsToBeAssigned
          entityState={experimentWorkflow.entity_state}
          experimentWorkflow={experimentWorkflow}
          round={experimentWorkflow.entity_state_round}
          entityUsers={entityUsers}
        />
      </div>

      {numberOfSignatureSigned === 0 && (
        <div className={styles.stateBox}>
          <ActionComponentLabel
            iconSize="large"
            icon={
              experimentWorkflow.entity_state.icon as SemanticICONS
            }
            text={experimentWorkflow.entity_state.name}
          />
        </div>
      )}
    </>
  );
};
