/**
 * Labstep
 */

import { Action } from 'labstep-web/models/action.model';
import {
  canCalcStoichiometry,
  Chemical,
} from 'labstep-web/models/chemical.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import {
  calcAmount,
  calcMolarAmount,
} from 'labstep-web/services/chemical.service';
import { modifyAmount } from 'labstep-web/services/amount-unit.service';

/**
 * Check if chemical update action
 */
export const isChemicalUpdateAction = (action: Action) =>
  action.type.includes('REQUEST_UPDATE_CHEMICAL');

/**
 * Check if molecule limiting_chemical_guid update action
 */
export const isMoleculeLimitingChemicalUpdateAction = (
  action: Action,
) =>
  action.type.includes('REQUEST_UPDATE_MOLECULE') &&
  action.meta.body.limiting_chemical_guid;

/**
 * Check if protocol_value is linked to chemical and amount/unit changed
 */
export const isProtocolValueWithChemicalUpdateAction = (
  state,
  action: Action,
) =>
  action.type.includes('REQUEST_UPDATE_PROTOCOL_VALUE') &&
  ('amount' in action.meta.body || 'unit' in action.meta.body) &&
  !!state.value.entities.protocol_value.byId[action.meta.identifier]
    .has_chemical;

/**
 * Get limiting chemical of molecule
 */
export const getLimitingChemical = (
  chemicals,
  moleculeId: string,
) => {
  const limitingChemicalId = Object.keys(chemicals).find(
    (id) =>
      chemicals[id].is_limiting &&
      chemicals[id].molecule === moleculeId,
  );
  return chemicals[limitingChemicalId];
};

/**
 * Get chemical with protocol_value id
 */
export const getChemicalByProtocolValueId = (
  chemicals,
  protocolValueId: string,
) => {
  const chemicalId = Object.keys(chemicals).find(
    (id) => chemicals[id].protocol_value === protocolValueId,
  );
  return chemicals[chemicalId];
};

/**
 * Update chemical molar_amount + protocolValue amount
 */
export const updateChemical = (
  chemical,
  chemicals,
  protocolValues,
  shouldCalcMolarAmount: boolean,
  shouldCalcAmount = true,
  molarAmount = null,
) => {
  const limitingChemical = getLimitingChemical(
    chemicals,
    chemical.molecule,
  );
  const protocolValue = protocolValues[chemical.protocol_value];
  if (shouldCalcMolarAmount && limitingChemical) {
    // eslint-disable-next-line no-param-reassign
    chemical.molar_amount = calcMolarAmount(
      chemical,
      protocolValue,
      limitingChemical,
    );
  }
  // mimic backend behaviour
  const canNotUpdate =
    molarAmount && chemical.molar_amount === molarAmount;
  if (
    shouldCalcAmount &&
    chemical.type === 'reactant' &&
    !canNotUpdate
  ) {
    protocolValue.amount = calcAmount(
      chemical,
      protocolValue,
      limitingChemical,
    );
  }
};

/**
 * Cascade update non-limiting chemicals on molecule
 */
export const cascadeUpdateNonLimitingChemicals = (
  chemicals,
  protocolValues,
  moleculeId,
) =>
  Object.values(chemicals).forEach((chemical: any) => {
    if (
      !chemical.is_limiting &&
      chemical.type !== 'solvent' &&
      chemical.molecule === moleculeId
    ) {
      updateChemical(
        chemical,
        chemicals,
        protocolValues,
        true,
        canCalcStoichiometry(chemical),
        chemical.molar_amount,
      );
    }
  });

/**
 * Find chemical and set is_limiting true
 */
export const setIsLimiting = (
  chemicals,
  limiting_chemical_guid: string,
  moleculeId: string,
) =>
  Object.values(chemicals).forEach((chemical: any) => {
    if (chemical.molecule === moleculeId) {
      // eslint-disable-next-line no-param-reassign
      chemical.is_limiting = chemical.guid === limiting_chemical_guid;
    }
  });

/**
 * Create stoichiometry table
 */
export const createStoichiometryTable = (state) => ({
  [Chemical.entityName]: {
    ...state.value.entities[Chemical.entityName].byId,
  },
  [ProtocolValue.entityName]: {
    ...state.value.entities[ProtocolValue.entityName].byId,
  },
});

/**
 * Adjust amount
 */
export const adjustAmount = (protocolValue, toUnit: string) => {
  // eslint-disable-next-line no-param-reassign
  protocolValue.amount = modifyAmount(
    protocolValue.amount,
    protocolValue.unit,
    toUnit,
  );
};

/**
 * Calculate stoichiometry table with updated chemistry and protocol_value entities
 */
export const calcStoichiometryTable = (state, action: Action) => {
  const stoichiometryTable = createStoichiometryTable(state);
  const chemicals = stoichiometryTable[Chemical.entityName];
  const protocolValues = stoichiometryTable[ProtocolValue.entityName];

  if (isMoleculeLimitingChemicalUpdateAction(action)) {
    setIsLimiting(
      chemicals,
      action.meta.body.limiting_chemical_guid,
      action.meta.identifier,
    );
    cascadeUpdateNonLimitingChemicals(
      chemicals,
      protocolValues,
      action.meta.identifier,
    );
  } else if (isChemicalUpdateAction(action)) {
    const chemical = chemicals[action.meta.identifier];
    Object.assign(chemical, action.meta.body);
    if (canCalcStoichiometry(chemical)) {
      updateChemical(
        chemical,
        chemicals,
        protocolValues,
        !action.meta.body.molar_amount,
        !(chemical.is_limiting && action.meta.body.purity),
      );
    }
    if (chemical.is_limiting) {
      cascadeUpdateNonLimitingChemicals(
        chemicals,
        protocolValues,
        chemical.molecule,
      );
    }
  } else {
    const protocolValue = protocolValues[action.meta.identifier];
    // if (action.meta.body.unit) {
    //   adjustAmount(protocolValue, action.meta.body.unit);
    // }
    Object.assign(protocolValue, action.meta.body);
    const chemical = getChemicalByProtocolValueId(
      chemicals,
      action.meta.identifier,
    );
    if (canCalcStoichiometry(chemical)) {
      updateChemical(
        chemical,
        chemicals,
        protocolValues,
        true,
        !!action.meta.body.unit,
      );
    }
    if (chemical.is_limiting) {
      cascadeUpdateNonLimitingChemicals(
        chemicals,
        protocolValues,
        chemical.molecule,
      );
    }
  }

  return stoichiometryTable;
};
