/**
 * Labstep
 *
 * @module components/Folder/Item
 * @desc Folder label
 */

import React from 'react';
import Label from 'labstep-web/core/Label';
import EntityActionRemove from 'labstep-web/components/Entity/Action/Remove';
import styles from './styles.module.scss';
import { IFolderItemProps } from './types';

const FolderItem: React.FC<IFolderItemProps> = ({
  folder,
  parentEntity,
}) => (
  <Label size="large" circular className={styles.folderItem}>
    <span className={styles.folderName}>{folder.folder_path}</span>
    <span className={styles.removeAction}>
      <EntityActionRemove
        noConfirmation
        entity={null}
        entityId={folder.id}
        entityName={folder.entityName}
        parentName={parentEntity.entityName}
        parentId={parentEntity.id}
        actionComponentProps={{ type: 'icon', icon: 'times' }}
      />
    </span>
  </Label>
);

export default FolderItem;
