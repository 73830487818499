/**
 * Labstep
 *
 * @module components/ProtocolStep/List
 * @desc List of ProtocolStep
 */

import React from 'react';
import List from 'labstep-web/core/List';
import ProtocolStepItem from 'labstep-web/components/ProtocolStep/Item';
import { IProtocolStepListProps } from './types';

const ProtocolStepList: React.FC<IProtocolStepListProps> = ({
  protocolSteps,
  protocol,
  protocolCollection,
}) => (
  <List id="protocol-step-list">
    {protocolSteps.map((protocolStep, index) => (
      <ProtocolStepItem
        key={protocolStep.guid}
        protocol={protocol}
        protocolCollection={protocolCollection}
        protocolStep={protocolStep}
        index={index}
      />
    ))}
  </List>
);

export default ProtocolStepList;
