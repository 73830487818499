/**
 * Labstep
 *
 * @module components/File/Card/EmptyState
 * @desc Empty state for resource
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import FileActionCreate from 'labstep-web/components/File/Action/Create';
import { File } from 'labstep-web/models/file.model';

export const FileEmptyState: React.FC = () => (
  <EmptyState
    src="/img/empty-state/files.svg"
    title={`No ${File.getHumanReadableEntityName(
      true,
      true,
    )} Uploaded`}
    explanation="All the files uploaded to experiments, protocols and resources in this workspace will appear here. Upload more files here, or via the Labstep Folder Watcher."
    action={<FileActionCreate multiple />}
  />
);

export default FileEmptyState;
