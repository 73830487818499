/**
 * Labstep
 */

import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import { PostFilter } from 'labstep-web/services/postFilter';

export const PostFilterMolecule = new PostFilter(
  [['inchis', PostFilterComparison.contains]],
  'metadatas.molecule',
);
