/**
 * Labstep
 */

import { fieldSignatureRequirementDaysToSign } from 'labstep-web/components/SignatureRequirement/Form/fields';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import React from 'react';
import { ISignatureRequirementFormShowEditDaysToSignProps } from './types';

export const SignatureRequirementFormShowEditDaysToSign: React.FC<
  ISignatureRequirementFormShowEditDaysToSignProps
> = ({ signatureRequirement }) => (
  <ShowEditText
    entityName={signatureRequirement.entityName}
    entity={signatureRequirement}
    field={fieldSignatureRequirementDaysToSign}
  >
    {signatureRequirement.days_to_sign}
  </ShowEditText>
);

export default SignatureRequirementFormShowEditDaysToSign;
