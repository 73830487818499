/**
 * Labstep
 *
 * @module components/Comment/Action/Delete
 * @desc Comment Delete action
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { ICommentActionDeleteProps } from './types';

const CommentActionDelete: React.FC<ICommentActionDeleteProps> = ({
  comment,
}) => (
  <EntityActionDelete
    // Removing any mention to entityName ('comment') because of notes
    humanReadableName=" "
    entity={comment}
  />
);

export default CommentActionDelete;
