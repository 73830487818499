/**
 * Labstep
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { EntityUserRole } from 'labstep-web/models/entity-user-role.model';
import rules from 'labstep-web/services/validation/rules';

export const fieldEntityUserRoleRequirementEntityUserRole = (
  entityUserRoles: EntityUserRole[],
): IFieldWithoutActionProps => ({
  fieldType: FieldType.ReactSelect,
  fieldLabel: 'Role',
  name: 'entity_user_role_id',
  validation: rules.entity_user_role_requirement.entity_user_role,
  elementProps: {
    options: entityUserRoles.map((entityUserRole) => ({
      label: entityUserRole.name,
      value: entityUserRole.id,
    })),
  },
});

export const fieldEntityUserRoleRequirementNumberRequiredName =
  'number_required';

export const fieldEntityUserRoleRequirementNumberRequired: IFieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    fieldLabel: 'Equivalence',
    name: fieldEntityUserRoleRequirementNumberRequiredName,
    validation: rules.entity_user_role_requirement.number_required,
  };

export const automationOff = {
  label: 'Off',
  value: null,
};

export const getAutomationTypeAuthor = (
  entityStateWorkflowId: string,
  entityUserRoleId: string,
) => ({
  label: 'The user who created the experiment',
  value: {
    type: 'experiment_workflow_create',
    trigger_type: 'log',
    trigger_log_type: 'experiment_workflow_created',
    filter: [
      {
        type: 'and',
        path: 'entityStateWorkflow',
        predicates: [
          {
            attribute: 'id',
            comparison: 'eq',
            value: entityStateWorkflowId,
          },
        ],
      },
    ],
    action: 'set_entity_user_role',
    payload: { entity_user_role_id: entityUserRoleId },
  },
});

export const getAutomationTypeContributor = (
  entityStateWorkflowId: string,
  entityStateId: string,
  entityUserRoleId: string,
) => ({
  label: 'Anyone who edits the experiment at this stage',
  value: {
    type: 'experiment_workflow_edit',
    trigger_type: 'log',
    trigger_log_type: 'experiment_workflow_updated',
    filter: [
      {
        type: 'and',
        path: 'entityStateWorkflow',
        predicates: [
          {
            attribute: 'id',
            comparison: 'eq',
            value: entityStateWorkflowId,
          },
        ],
      },
      {
        type: 'and',
        path: 'entityState',
        predicates: [
          {
            attribute: 'id',
            comparison: 'eq',
            value: entityStateId,
          },
        ],
      },
    ],
    action: 'set_entity_user_role',
    payload: { entity_user_role_id: entityUserRoleId },
  },
});

export const getFieldAutomation = (
  entityStateWorkflowId: string,
  entityStateId: string,
  entityUserRoleId: string,
): IFieldWithoutActionProps => ({
  name: 'automation',
  validation: rules.entity_user_role_requirement.automation,
  fieldType: FieldType.ReactSelect,
  elementProps: {
    options: [
      automationOff,
      getAutomationTypeAuthor(
        entityStateWorkflowId,
        entityUserRoleId,
      ),
      getAutomationTypeContributor(
        entityStateWorkflowId,
        entityStateId,
        entityUserRoleId,
      ),
    ],
  },
});
