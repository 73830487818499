/**
 * Labstep
 *
 * @module components/Molecule/Hazards
 * @desc Showing molecule hazards
 */

import React from 'react';
import classnames from 'classnames';
import Popup from 'labstep-web/core/Popup';
import Header from 'labstep-web/core/Header';
import Image from 'labstep-web/core/Image';
import Hoverable from 'labstep-web/core/Hoverable';
import ActionAdd from 'labstep-web/components/Molecule/Safety/ActionAdd';
import ActionRemove from 'labstep-web/components/Molecule/Safety/ActionRemove';
import { hazardClassPictograms } from '../constants';
import { IMoleculeSafetyPictogramsProps } from './types';
import styles from './styles.module.scss';

export const MoleculeSafetyPictograms: React.FC<
  IMoleculeSafetyPictogramsProps
> = ({ parent, pubChemMetadata, isInline, size }) => {
  const { pictograms } = pubChemMetadata.Safety;
  return (
    <div
      className={classnames({
        [styles.container]: isInline,
      })}
    >
      {!isInline && <Header size="mini">Pictograms</Header>}
      <div
        className={classnames(styles.pictograms, {
          [styles.inline]: isInline,
        })}
      >
        {(pictograms || []).map((h) => {
          const pictogram =
            hazardClassPictograms[
              h as keyof typeof hazardClassPictograms
            ];
          if (!pictogram) {
            return null;
          }
          return (
            <Popup
              key={h}
              trigger={
                // Need to wrap in div otherwise popup doesn't show
                <div className={styles.container}>
                  <Hoverable
                    component={
                      parent && (
                        <ActionRemove
                          code={h}
                          pubChemMetadata={pubChemMetadata}
                          type="pictograms"
                          parent={parent}
                        />
                      )
                    }
                    hoverable={
                      <Image size={size} inline src={pictogram.src} />
                    }
                  />
                </div>
              }
              content={pictogram.additional.join(', ')}
            />
          );
        })}
      </div>
      {parent && (
        <ActionAdd
          pubChemMetadata={pubChemMetadata}
          type="pictograms"
          parent={parent}
        />
      )}
      <br />
    </div>
  );
};

export default MoleculeSafetyPictograms;
