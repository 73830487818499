/**
 * Labstep
 */

import * as sfApi from 'labstep-web/services/sf-api.service';

export interface ICreateStripeCheckoutSessionBody {
  price_id: string;
  currency: string;
  quantity: number;
  success_url: string;
  cancel_url: string;
}

export const createStripeCheckoutSessionActionType =
  'create_stripe_checkout_session';

export const createStripeCheckoutSessionAction = (
  body: ICreateStripeCheckoutSessionBody,
  options = {},
) =>
  sfApi.post({
    type: 'CREATE_STRIPE_CHECKOUT_SESSION',
    route: {
      custom: 'app_api_stripe_createcheckoutsession',
    },
    body,
    meta: {
      action_type: createStripeCheckoutSessionActionType,
      customReducer: 1,
    },
    ...options,
  });

export interface ICreateStripeBillingPortalSessionBody {
  return_url: string;
}

export const createStripeBillingPortalSessionActionType =
  'create_stripe_billing_portal_session';

export const createStripeBillingPortalSessionAction = (
  body: ICreateStripeBillingPortalSessionBody,
  options = {},
) =>
  sfApi.post({
    type: 'CREATE_STRIPE_BILLING_PORTAL_SESSION',
    route: {
      custom: 'app_api_stripe_createbillingportalsession',
    },
    body,
    meta: {
      action_type: createStripeBillingPortalSessionActionType,
      customReducer: 1,
    },
    ...options,
  });
