/**
 * Labstep
 *
 * @module prosemirror/schema
 * @desc ProseMirror schema
 */

import { Schema } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import { spec as nodesSpec } from 'labstep-web/prosemirror/nodes';
import { spec as marksSpec } from 'labstep-web/prosemirror/marks';

const schema = new Schema({
  nodes: nodesSpec,
  marks: marksSpec,
});

export type LabstepEditorState = EditorState;

export default schema;
