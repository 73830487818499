/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Form/ShowEdit/Name
 * @desc Name showedit field
 */

import EntityPrimaryInfo from 'labstep-web/components/Entity/PrimaryInfo';
import { ICONS } from 'labstep-web/constants/icons';
import Identifier from 'labstep-web/core/Identifier';
import React from 'react';
import { IExperimentWorkflowFormShowEditNameProps } from './types';

const ExperimentWorkflowFormShowEditName: React.FC<
  IExperimentWorkflowFormShowEditNameProps
> = ({ experimentWorkflow }) => (
  <EntityPrimaryInfo
    entity={experimentWorkflow}
    icon={
      experimentWorkflow.is_template
        ? ICONS.experiment_workflow.template
        : undefined
    }
    name={experimentWorkflow.nameNoCustomIdentifier}
    prefix={
      experimentWorkflow.custom_identifier && (
        <>
          <Identifier>
            {experimentWorkflow.custom_identifier}
          </Identifier>
          -
        </>
      )
    }
  />
);

export default ExperimentWorkflowFormShowEditName;
