/**
 * Labstep
 *
 * @module components/PurchaseOrder/Table
 * @desc Table listing all purchaseOrders
 */

import React from 'react';
import { humanReadableDate } from 'labstep-web/services/date.service';
import EntityTable from 'labstep-web/components/Entity/Table';
import PurchaseOrderActionMenu from 'labstep-web/components/PurchaseOrder/Action/Menu';
import PurchaseOrderStatus from 'labstep-web/components/PurchaseOrder/Status';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import { IPurchaseOrderTableProps } from './types';

export const PurchaseOrderDate = (purchaseOrder: PurchaseOrder) =>
  purchaseOrder.pending_log &&
  humanReadableDate(purchaseOrder.pending_log.created_at);

export const PurchaseOrderTable: React.FC<
  IPurchaseOrderTableProps
> = ({ purchaseOrders, showOrganization, actions, ...rest }) => {
  let extraColumns = [
    {
      header: 'Order Date',
      content: PurchaseOrderDate,
      // sortKey: 'created_at',
      cellProps: { style: { flex: 2 } },
    },
    {
      header: 'Status',
      content: (purchaseOrder: PurchaseOrder) => (
        <PurchaseOrderStatus purchaseOrder={purchaseOrder} />
      ),
      sortKey: 'status',
      cellProps: { style: { flex: 2 } },
    },
    {
      header: 'Items',
      content: (purchaseOrder: PurchaseOrder) =>
        `${purchaseOrder.order_request_count}`,
      cellProps: { style: { flex: 2 } },
    },
    {
      header: 'Total',
      content: (purchaseOrder: PurchaseOrder) =>
        purchaseOrder.printTotalWithVat,
      cellProps: { style: { flex: 2 } },
    },
  ];

  if (showOrganization) {
    extraColumns = [
      {
        header: 'Organization',
        content: (purchaseOrder: PurchaseOrder) =>
          purchaseOrder.owner.organization.name,
        cellProps: { style: { flex: 1 } },
      },
      ...extraColumns,
    ];
  }

  return (
    <EntityTable
      {...rest}
      id="purchase-order-table"
      entities={purchaseOrders}
      extraColumns={extraColumns}
      actionMenu={({ entity }) => (
        <PurchaseOrderActionMenu
          purchaseOrder={entity}
          actions={actions}
        />
      )}
    />
  );
};

export default PurchaseOrderTable;
