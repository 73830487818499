/**
 * Labstep
 *
 * @module prosemirror/extensions/paste
 * @desc Handle paste
 */

import { Experiment } from 'labstep-web/models/experiment.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import NodeFile from 'labstep-web/prosemirror/nodes/file';
import { uploadFile } from 'labstep-web/state/actions/file';
import store from 'labstep-web/state/store';
import { EditorState } from 'prosemirror-state';

export const onCreateFilesSuccess = (
  state: EditorState,
  dispatch,
  response,
) => {
  const id = response.result[0];
  const { tr } = state;
  const node = state.schema.nodes[NodeFile.key].create({
    id,
  });
  tr.setStoredMarks([]);
  tr.insert(state.selection.$from.pos, node);
  dispatch(tr);
};

export const createFiles = (
  state: EditorState,
  dispatch,
  entity: Experiment | Protocol,
  files,
): void => {
  store.dispatch(
    uploadFile(
      {
        file: files[0],
        entityType: entity.entityName,
        entityId: entity.id,
      },
      {
        onSuccess: ({ response }) =>
          onCreateFilesSuccess(state, dispatch, response),
      },
    ),
  );
};
