/**
 * Labstep
 */

import * as sfApi from 'labstep-web/services/sf-api.service';

export const shareLinkAcceptActionType = 'share_link_accept';

export const shareLinkAcceptAction = (
  token: string,
  body: any = {},
  options: any = {},
): any =>
  sfApi.post({
    type: 'SHARELINK_ACCEPT',
    route: {
      custom: 'app_api_sharelink_accept',
    },
    meta: {
      action_type: shareLinkAcceptActionType,
      customReducer: 1,
    },
    params: {
      token,
    },
    body,
    ...options,
  });
