/**
 * Labstep
 *
 * @module components/Layout/RightPane/Index
 * @desc Index of all Right Pane subpages
 */

import React from 'react';
import LinkButton from 'labstep-web/core/Link/Button';
import Icon from 'labstep-web/core/Icon';
import List from 'labstep-web/core/List';
import ElementCard from 'labstep-web/core/Card/Element';
import TextWithCount from 'labstep-web/core/Text/WithCount';
import PremiumFeatureLockIcon from 'labstep-web/core/PremiumFeature/LockIcon';
import { RightPaneSubPage } from '../SubPage';
import { IRightPaneIndexProps } from './types';
import styles from './styles.module.scss';

export const RightPaneIndex: React.FC<IRightPaneIndexProps> = ({
  subPages,
  desktop,
}) =>
  desktop ? (
    <RightPaneSubPage desktop>
      <List data-testid="right-pane-index-list">
        {subPages.map((page) => (
          <LinkButton key={page.name} route={page.route}>
            <ElementCard
              icon={page.icon}
              name={
                <>
                  <TextWithCount
                    text={page.name}
                    count={page.count}
                  />
                  {page.premiumFeature && (
                    <PremiumFeatureLockIcon
                      premiumFeature={page.premiumFeature}
                    />
                  )}
                </>
              }
              right={<Icon name="angle right" size="large" />}
            />
          </LinkButton>
        ))}
      </List>
    </RightPaneSubPage>
  ) : (
    <div className={styles.mobileMenu}>
      {subPages.map((page) => (
        <LinkButton key={page.name} route={page.route}>
          <Icon name={page.icon} />
        </LinkButton>
      ))}
    </div>
  );

export default RightPaneIndex;
