/**
 * Labstep
 *
 * @module components/Layout/Links
 * @desc State based sublinks + rendering
 */

import React, { useState } from 'react';
import classnames from 'classnames';
import styles from '../styles.module.scss';
import { ILayoutLinksStateProps } from './types';

export const LayoutLinksState: React.FC<ILayoutLinksStateProps> = ({
  tabs,
  inverted,
  stretch,
}) => {
  const [activeTabKey, setActiveTabKey] = useState(tabs[0].key);
  const activeTab = tabs.find((tab) => activeTabKey === tab.key);
  return (
    <div data-testid="layout-links">
      <div className={styles.linksContainer}>
        {tabs.map((tab) => (
          <div
            key={tab.key}
            className={classnames(styles.link, {
              [styles.inverted]: inverted,
              [styles.stretch]: stretch,
              active: activeTabKey === tab.key,
            })}
            onClick={() => setActiveTabKey(tab.key)}
          >
            {tab.key}
          </div>
        ))}
      </div>
      <div>{activeTab ? activeTab.render({}) : null}</div>
    </div>
  );
};

export default LayoutLinksState;
