/**
 * Labstep
 *
 * @module services/fos-routing
 * @desc FOS Routing
 */

interface IContextRouting {
  base_url: string;
  prefix: string;
  host: string;
  scheme: string;
}

class FosRouting {
  routesRouting: any;

  contextRouting: IContextRouting;

  constructor() {
    this.contextRouting = {
      base_url: '',
      prefix: '',
      host: '',
      scheme: '',
    };
    this.routesRouting = [];
  }

  setData = (data: any) => {
    this.setBaseUrl(data.base_url);
    this.setRoutes(data.routes);
    if ('prefix' in data) {
      this.setPrefix(data.prefix);
    }
    this.setHost(data.host);
    this.setScheme(data.scheme);
  };

  setRoutes = (routes: any) => {
    this.routesRouting = routes || [];
  };

  getRoutes = () => this.routesRouting;

  setBaseUrl = (baseUrl: string) => {
    this.contextRouting.base_url = baseUrl;
  };

  getBaseUrl = () => this.contextRouting.base_url;

  setPrefix = (prefix: string) => {
    this.contextRouting.prefix = prefix;
  };

  getPrefix = () => this.contextRouting.prefix;

  setScheme = (scheme: string) => {
    this.contextRouting.scheme = scheme;
  };

  getScheme = () => this.contextRouting.scheme;

  setHost = (host: string) => {
    this.contextRouting.host = host;
  };

  getHost = () => this.contextRouting.host;

  getRoute = (name: string) => {
    const prefixedName: string = this.contextRouting.prefix + name;
    if (!this.routesRouting[prefixedName]) {
      if (!this.routesRouting[name]) {
        throw new Error(`The route "${name}" does not exist.`);
      }
    } else {
      return this.routesRouting[prefixedName];
    }
    return this.routesRouting[name];
  };

  buildQueryParams = (prefix: string, params: any, add: any) => {
    const rbracket = new RegExp(/\[]$/);
    if (params instanceof Array) {
      params.forEach((val, i) => {
        if (rbracket.test(prefix)) {
          add(prefix, val);
        } else {
          this.buildQueryParams(
            `${prefix}[${typeof val === 'object' ? i : ''}]`,
            val,
            add,
          );
        }
      });
    } else if (typeof params === 'object') {
      Object.keys(params).forEach((name) =>
        this.buildQueryParams(
          `${prefix}[${name}]`,
          params[name],
          add,
        ),
      );
    } else {
      add(prefix, params);
    }
  };

  generate = (
    name: string,
    optParams: any,
    absolute = false,
  ): string => {
    const route = this.getRoute(name);
    const params = optParams || {};
    const unusedParams = { ...params };
    const schemaVar = '_scheme';
    let url = '';
    let optional = true;
    let host = '';
    (route.tokens || []).forEach((token: string[]) => {
      if (token[0] === 'text') {
        url = token[1] + url;
        optional = false;
        return;
      }
      if (token[0] === 'variable') {
        const hasDefault = (route.defaults || {})[token[3]];
        if (
          !optional ||
          !hasDefault ||
          ((params || {})[token[3]] &&
            params[token[3]] !== route.defaults[token[3]])
        ) {
          let value;
          if ((params || {})[token[3]]) {
            value = params[token[3]];
            delete unusedParams[token[3]];
          } else if (hasDefault) {
            value = route.defaults[token[3]];
          } else if (optional) {
            return;
          } else {
            throw new Error(
              `The route "${name}" requires the parameter "${token[3]}".`,
            );
          }
          const empty =
            value === true || value === false || value === '';
          if (!empty || !optional) {
            let encodedValue = encodeURIComponent(value).replace(
              /%2F/g,
              '/',
            );
            if (encodedValue === 'null' && value === null) {
              encodedValue = '';
            }
            url = token[1] + encodedValue + url;
          }
          optional = false;
        } else if (hasDefault) {
          delete unusedParams[token[3]];
        }
        return;
      }
      throw new Error(
        `The token type "${token[0]}" is not supported.`,
      );
    });
    if (url === '') {
      url = '/';
    }
    (route.hosttokens || []).forEach((token: string[]) => {
      let value;
      if (token[0] === 'text') {
        host = token[1] + host;
        return;
      }
      if (token[0] === 'variable') {
        if ((params || {})[token[3]]) {
          value = params[token[3]];
          delete unusedParams[token[3]];
        } else if (route.defaults[token[3]]) {
          value = route.defaults[token[3]];
        }
        host = token[1] + value + host;
      }
    });
    url = this.contextRouting.base_url + url;
    if (
      route.requirements[schemaVar] &&
      this.getScheme() !== route.requirements[schemaVar]
    ) {
      url = `${route.requirements[schemaVar]}://${
        host || this.getHost()
      }${url}`;
    } else if (host && this.getHost() !== host) {
      url = `${this.getScheme()}://${host}${url}`;
    } else if (absolute) {
      url = `${this.getScheme()}://${this.getHost()}${url}`;
    }
    if (Object.keys(unusedParams).length > 0) {
      const queryParams: any[] = [];
      const add = (key: any, value: any) => {
        let newValue = value;
        newValue =
          typeof newValue === 'function' ? newValue() : newValue;
        newValue = newValue === null ? '' : newValue;
        queryParams.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(
            newValue,
          )}`,
        );
      };
      Object.keys(unusedParams).forEach((prefix) =>
        this.buildQueryParams(prefix, unusedParams[prefix], add),
      );
      url = `${url}?${queryParams.join('&').replace(/%20/g, '+')}`;
    }
    return url;
  };
}

export default FosRouting;
