/**
 * Labstep
 *
 * @module core/MediaQuery/Mobile
 * @desc MediaQuery for Mobile
 */

import MediaQuery from 'labstep-web/core/MediaQuery';
import {
  IMediaQueryProps,
  Size,
} from 'labstep-web/core/MediaQuery/types';
import React from 'react';

type IMediaQueryMobileProps = Omit<IMediaQueryProps, 'size'>;

const MediaQueryMobile: React.FC<IMediaQueryMobileProps> = ({
  children,
}) => <MediaQuery size={Size.mobile}>{children}</MediaQuery>;

export default MediaQueryMobile;
