/**
 * Labstep
 *
 * @module constants/unit
 * @desc List of units for conversion
 * @see Symfony/src/AppBundle/Listener/ProtocolValueAmountDeductListener::UNITS
 */

export interface Unit {
  displayValue?: string;
  name: string;
  base: string;
  modifier: number;
}

/**
 * @desc Units without displayValue are needed for calculations but
 * won't be displayed in the dropdown
 */
export const UNITS: { [key: string]: Unit } = {
  l: { displayValue: 'L', name: 'l', base: 'l', modifier: 0 },
  pl: { displayValue: 'pL', name: 'pl', base: 'l', modifier: -12 },
  nl: { displayValue: 'nL', name: 'nl', base: 'l', modifier: -9 },
  ul: { name: 'ul', base: 'l', modifier: -6 },
  μl: { displayValue: 'μL', name: 'μl', base: 'l', modifier: -6 },
  ml: { displayValue: 'mL', name: 'ml', base: 'l', modifier: -3 },
  'cm³': { name: 'cm³', base: 'l', modifier: -3 },
  cm3: { name: 'cm3', base: 'l', modifier: -3 },
  'cm^': { name: 'cm^3', base: 'l', modifier: -3 },
  g: { displayValue: 'g', name: 'g', base: 'g', modifier: 0 },
  pg: { displayValue: 'pg', name: 'pg', base: 'g', modifier: -12 },
  ng: { displayValue: 'ng', name: 'ng', base: 'g', modifier: -9 },
  ug: { name: 'ug', base: 'g', modifier: -6 },
  μg: { displayValue: 'μg', name: 'μg', base: 'g', modifier: -6 },
  mg: { displayValue: 'mg', name: 'mg', base: 'g', modifier: -3 },
  kg: { displayValue: 'kg', name: 'kg', base: 'g', modifier: 3 },
  aliquots: {
    displayValue: 'aliquots',
    name: 'aliquots',
    base: 'aliquots',
    modifier: 0,
  },
  vials: {
    displayValue: 'vials',
    name: 'vials',
    base: 'vials',
    modifier: 0,
  },
  pieces: {
    displayValue: 'pieces',
    name: 'pieces',
    base: 'pieces',
    modifier: 0,
  },
  cells: {
    displayValue: 'cells',
    name: 'cells',
    base: 'cells',
    modifier: 0,
  },
  units: {
    displayValue: 'units',
    name: 'units',
    base: 'units',
    modifier: 0,
  },
  plates: {
    displayValue: 'plates',
    name: 'plates',
    base: 'plates',
    modifier: 0,
  },
  tsp: { displayValue: 'tsp', name: 'tsp', base: 'tsp', modifier: 0 },
  tbsp: {
    displayValue: 'tbsp',
    name: 'tbsp',
    base: 'tbsp',
    modifier: 0,
  },
  colonies: {
    displayValue: 'colonies',
    name: 'colonies',
    base: 'colonies',
    modifier: 0,
  },
  drops: {
    displayValue: 'drops',
    name: 'drops',
    base: 'drops',
    modifier: 0,
  },
};

export const getBase = (unit: string) => {
  if (!unit) {
    return '';
  }
  const normalizedUnit = unit.trim().toLowerCase();

  if (UNITS[normalizedUnit]) {
    return UNITS[normalizedUnit].base;
  }

  return normalizedUnit;
};

export const UNITS_CHEMICAL = Object.values(UNITS).filter(
  (u) => u.base === 'l' || u.base === 'g',
);

export const unitsAsOptions = (
  isChemical = false,
  additionalUnit?: string,
) => {
  const unitsToUse = isChemical
    ? UNITS_CHEMICAL
    : Object.values(UNITS);
  const units = unitsToUse
    .filter((unit: Unit) => unit.displayValue)
    .map((unit: Unit) => ({
      label: unit.displayValue,
      value: unit.name,
    }));

  if (additionalUnit && !(additionalUnit in UNITS)) {
    return [
      { label: additionalUnit, value: additionalUnit },
      ...units,
    ];
  }
  return units;
};
