/**
 * Labstep
 *
 * @module components/JupyterNotebook/Action/Automation
 * @desc Automation a jupyter notebook
 */

import JupyterNotebookModalAutomation from 'labstep-web/components/JupyterNotebook/Modal/Automation';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import React from 'react';
import { JupyterNotebookActionAutomationProps } from './types';

export const JupyterNotebookActionAutomation: React.FC<
  JupyterNotebookActionAutomationProps
> = ({ jupyterNotebook, authenticatedUserEntity }) => {
  if (
    !jupyterNotebook.data ||
    !authenticatedUserEntity.premiumFeatures.includes(
      'jupyter_automation',
    )
  ) {
    return null;
  }

  return (
    <JupyterNotebookModalAutomation
      jupyterNotebook={jupyterNotebook}
    />
  );
};

export default withAuthenticatedUserEntity(
  JupyterNotebookActionAutomation,
);
