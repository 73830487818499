/**
 * Labstep
 */

import { EditorState } from 'prosemirror-state';

// export const isBulletListNode = (node: Node): boolean => {
//   return node.type.name === 'bullet_list';
// };

// export const isOrderedListNode = (node: Node): boolean => {
//   return node.type.name === 'ordered_list';
// };

// export const isListNode = (node: Node): boolean => {
//   if (node instanceof Node) {
//     return isBulletListNode(node) || isOrderedListNode(node);
//   }
//   return false;
// };

/**
 * Checks if the current selection is within a single list
 */
export const isInList = (
  state: EditorState,
  type: 'bullet' | 'ordered',
): boolean => {
  const {
    selection: { from, to },
    doc,
  } = state;

  let nodes = [];
  doc.nodesBetween(from, to, (node) => {
    // If not list / list item
    if (!node.type.name.includes('list')) {
      // Stop recursing
      return false;
    }
    nodes = [...nodes, node];
    return true;
  });

  if (nodes.length === 0) {
    return false;
  }

  return nodes.reverse().reduce((result, node) => {
    if (result !== null) {
      return result;
    }

    if (type === 'bullet' && node.type.name === 'ordered_list') {
      return false;
    }

    if (type === 'ordered' && node.type.name === 'bullet_list') {
      return false;
    }

    if (type === 'bullet' && node.type.name === 'bullet_list') {
      return true;
    }

    if (type === 'ordered' && node.type.name === 'ordered_list') {
      return true;
    }

    return result;
  }, null);
};

/**
 * Count how many list items (depth 1) there are in a list
 */
export const countListItems = (state: EditorState) => {
  let count = 0;
  state.doc.nodesBetween(
    state.selection.from,
    state.selection.to,
    (n) => {
      if (n.type.name.includes('_list')) {
        count = n.childCount;
      }
      return false;
    },
  );
  return count;
};

export const getListNode = (state: EditorState): any => {
  const {
    selection: { from, to },
    doc,
  } = state;

  let nodes = [];
  doc.nodesBetween(from, to, (node, pos) => {
    if (
      node.type.name === 'bullet_list' ||
      node.type.name === 'ordered_list'
    ) {
      nodes = [...nodes, { node, pos }];
    }
    return false;
  });
  return nodes.length && nodes[0];
};
