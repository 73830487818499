/**
 * Labstep
 *
 * @module components/User/Form/Signin
 * @desc Signin form
 */

import React from 'react';
import Message from 'labstep-web/core/Message';
import Icon from 'labstep-web/core/Icon';
import styles from './styles.module.scss';
import { IUserFormSigninUsernameProps } from './types';

export const UserFormSigninUsername: React.FC<
  IUserFormSigninUsernameProps
> = ({ username, onClick }) => (
  <Message
    content={
      <div className={styles.message}>
        <div>{username}</div>
        <Icon name="times" onClick={onClick} />
      </div>
    }
  />
);

export default UserFormSigninUsername;
