/**
 * Labstep
 *
 * @module components/ResourceTemplate/Action/Delete
 * @desc Action to delete
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { IResourceTemplateDeleteActionProps } from './types';

export const ResourceTemplateDeleteAction: React.FC<
  IResourceTemplateDeleteActionProps
> = ({ resourceTemplate, ...rest }) => (
  <EntityActionDelete {...rest} entity={resourceTemplate} />
);

export default ResourceTemplateDeleteAction;
