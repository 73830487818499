/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Referencing/Menu/Creatable/Action
 * @desc CTA for creating elements as a reference
 */

import React from 'react';
import Menu from 'labstep-web/core/Menu';
import { handleCreateItem } from 'labstep-web/prosemirror/extensions/referencing/commands';
import { IMenuReferencingMenuCreatableActionProps } from './types';

const MenuReferencingMenuCreatableAction: React.FC<
  IMenuReferencingMenuCreatableActionProps
> = ({ state, dispatch, selectedCategory, token, active, index }) => (
  <Menu.Item
    key={index}
    active={active}
    onClick={() => handleCreateItem(state, dispatch, index)}
  >
    <b>
      Add new {selectedCategory.label}
      {token?.text.length ? ` "${token.text}"` : ''}
    </b>
  </Menu.Item>
);

export default MenuReferencingMenuCreatableAction;
