/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/ProtocolTimer
 * @desc Button to insert an ProtocolTimer
 */

import ProtocolTimerActionCreateForm from 'labstep-web/components/ProtocolTimer/Action/Create/Form';
import { ICONS } from 'labstep-web/constants/icons';
import Dropdown from 'labstep-web/core/Dropdown';
import { ProtocolTimer } from 'labstep-web/models/protocol-timer.model';
import NodeProtocolTimer from 'labstep-web/prosemirror/nodes/protocol-timer';
import {
  canAddElement,
  getSelectedTextForElement,
} from 'labstep-web/prosemirror/utils';
import React from 'react';
import { ITimerToolbarProps } from './types';

const TimerToolbar: React.FC<ITimerToolbarProps> = ({
  protocol,
  dispatch,
  state,
}) => (
  <ProtocolTimerActionCreateForm
    viewComponent={({ toggleModal }) => (
      <Dropdown.Item
        onClick={toggleModal}
        disabled={!canAddElement(state)}
        icon={ICONS.protocol.timer}
        text={ProtocolTimer.getHumanReadableEntityName(false, true)}
      />
    )}
    defaultValues={{ name: getSelectedTextForElement(state) }}
    protocol={protocol}
    options={{
      onSuccess: ({ response }) => {
        const { tr } = state;
        const node = state.schema.nodes[NodeProtocolTimer.key].create(
          {
            guid: response.result,
          },
        );
        tr.setStoredMarks([]);
        tr.insert(state.selection.$to.pos, node);
        dispatch?.(tr);
      },
    }}
  />
);

export default TimerToolbar;
