/**
 * Labstep
 *
 * @module components/Molecule/Placeholder
 * @desc Placeholder for Molecule on Mobile
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Button from 'labstep-web/core/Button';
import { IMoleculeMobilePlaceholderProps } from './types';

const MoleculeMobilePlaceholder: React.FC<
  IMoleculeMobilePlaceholderProps
> = ({ onClose }) => (
  <EmptyState
    title="The Molecule Editor is only available on Desktop."
    explanation="For the best experience we block access to our molecule editor on mobile devices."
    src="/img/empty-state/warning.svg"
    action={
      <Button primary onClick={onClose}>
        Close
      </Button>
    }
  />
);

export default MoleculeMobilePlaceholder;
