/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/ExperimentManager
 * @desc Experiment Manager for Experiment Worfklow
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import ExperimentEmptyState from 'labstep-web/components/Experiment/EmptyState';
import ExperimentList from 'labstep-web/components/Experiment/List';
import ExperimentWorkflowAddProtocolAction from 'labstep-web/components/ExperimentWorkflow/Action/AddProtocol';
import Container from 'labstep-web/components/Layout/Container';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Experiment } from 'labstep-web/models/experiment.model';
import React from 'react';
import { IExperimentManagerProps } from './types';

export const ExperimentManager: React.FC<IExperimentManagerProps> = ({
  experimentWorkflow,
}) => (
  <ReadOnMountHOC
    type="cursor"
    entityName={Experiment.entityName}
    params={{
      is_root: false,
      experiment_workflow_id: experimentWorkflow.id,
    }}
    children={({ entities, total, status }) => {
      const experiments = entities.filter((e) => !e.deleted_at);

      if (total - (entities.length - experiments.length) === 0) {
        return (
          <ExperimentEmptyState
            experimentWorkflow={experimentWorkflow}
          />
        );
      }

      return (
        <div>
          <Can
            entityName={experimentWorkflow.entityName}
            id={experimentWorkflow.id}
            action={Action.experiment_create}
          >
            <Container>
              <ExperimentWorkflowAddProtocolAction
                experimentWorkflow={experimentWorkflow}
              />
            </Container>
          </Can>

          <ExperimentList
            experiments={experiments}
            experimentWorkflowId={experimentWorkflow.id}
            status={status}
          />
        </div>
      );
    }}
  />
);

export default ExperimentManager;
