/**
 * Labstep
 */

import { EntityUserRoleSelectPanel } from 'labstep-web/components/EntityUserRole/SelectPanel';
import { systemOptions } from 'labstep-web/constants/entity-user-role';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { useToggle } from 'labstep-web/hooks/toggle';
import { EntityUserRoleRequirement } from 'labstep-web/models/entity-user-role-requirement.model';
import { Group } from 'labstep-web/models/group.model';
import React from 'react';
import { IEntityUserRoleRequirementActionCreateProps } from './types';

const EntityUserRoleRequirementActionCreate: React.FC<
  IEntityUserRoleRequirementActionCreateProps
> = ({ entityState, activeGroup }) => {
  const [isToggled, toggle] = useToggle();

  return (
    <>
      {isToggled ? (
        <EntityCreateContainer
          entityName={EntityUserRoleRequirement.entityName}
          parentId={entityState.id}
          parentName={entityState.entityName}
        >
          {({ create }) => (
            <>
              {isToggled && (
                <EntityUserRoleSelectPanel
                  systemOptions={systemOptions}
                  group={activeGroup as Group}
                  onBlur={toggle}
                  onChange={(entityUserRole) => {
                    if (entityUserRole) {
                      create({
                        entity_user_role_id: entityUserRole.id,
                        number_required: 1,
                      });
                      toggle();
                    }
                  }}
                  entityType="Role"
                  allowDelete
                  openMenuOnFocus
                />
              )}
            </>
          )}
        </EntityCreateContainer>
      ) : (
        <ActionComponent
          type="text"
          text="Add Role Requirement"
          icon="add"
          onClick={toggle}
        />
      )}
    </>
  );
};

export default withActiveGroup(EntityUserRoleRequirementActionCreate);
