/**
 * Labstep
 *
 * @module screens/ResourceItem/Master/Center
 * @desc Master Center screen for protocol collection
 */

import React from 'react';
import EntitySearch from 'labstep-web/components/Entity/Search';
import ResourceItemEmptyState from 'labstep-web/components/ResourceItem/Card/EmptyState';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import ResourceItemListOrTable from '../ListOrTable';
import { IResourceItemEntitySearchProps } from './types';

export const ResourceItemEntitySearch: React.FC<
  IResourceItemEntitySearchProps
> = ({ tableFormat, columnDefs, templateId, ...rest }) => (
  <EntitySearch
    entityName={ResourceItem.entityName}
    tableFormat={false}
    filters={[Filter.deleted, Filter.resource_item_status]}
    bulkActions={[
      BulkAction.delete,
      BulkAction.resource_item_update_status,
      BulkAction.resource_item_update_resource_location,
      BulkAction.resource_item_update_amount,
    ]}
    noResultsMessage={<ResourceItemEmptyState />}
    {...rest}
  >
    {({ entities, status }) => (
      <ResourceItemListOrTable
        resourceItems={entities}
        status={status}
        columnDefs={columnDefs}
        tableFormat={tableFormat}
        templateId={templateId}
      />
    )}
  </EntitySearch>
);

export default ResourceItemEntitySearch;
