/**
 * Labstep
 *
 * @module state/selectors/ui
 * @desc Selectors for UI
 */

import { createSelector } from 'reselect';
import { selectEntity } from './entity';

export const selectActiveEntity = createSelector(
  (state: any) => state.ui.active_entity,
  (activeEntity: any) => activeEntity,
);

export const selectConnectivity = createSelector(
  (state: any) => state.ui.is_connected,
  (is_connected: any) => is_connected,
);

// FIXME Using action variable name is misleading, should be type instead
export const selectApiStatus = (state: any, action: string) =>
  state.ui.api_actions[action];

export const selectConsent = (state: any) => {
  const { consent } = state.ui;
  return consent;
};

export const selectPrintLink = (state: any, id: number) =>
  state.ui.print[id];

export const selectUi = (state: any) => ({
  ...state.ui,
  context: state.ui.context
    ? selectEntity(state, 'group', state.ui.context)
    : state.ui.context,
});
