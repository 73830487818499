/**
 * Labstep
 *
 * @module components/Layout/MainContent
 * @desc Layout Component for the main content. Renders three columns.
 * The navigation bar on the left, the main content in the center, and
 * addition content on the right
 */

import React from 'react';
import { navigation } from 'labstep-web/services/navigation';
import Route from 'labstep-web/core/Route';
import TopBar from 'labstep-web/components/Layout/Menu/TopBar';
import Desktop from 'labstep-web/core/MediaQuery/Desktop';
import { LeftDesktop } from '../FullScreen';
import styles from './styles.module.scss';
import { IMainContentProps } from './types';

const MainContent: React.FC<IMainContentProps> = ({
  center,
  breadcrumb,
}) => (
  <div id="page-wrap" className={styles.container}>
    <TopBar breadcrumb={breadcrumb} />
    <div className={styles.center} id="main-content-children">
      <Desktop>
        <Route path={navigation.get('group_show')}>
          <LeftDesktop />
        </Route>
      </Desktop>
      {center}
    </div>
  </div>
);

export default MainContent;
