/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/UpdateEntityState/ModalWizard
 * @desc Modal wizard for updating the entityState
 */

import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ModalRouteStateType } from 'labstep-web/hooks/modalRoute/types';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Group } from 'labstep-web/models/group.model';
import React from 'react';
import { useLocation } from 'react-router';
import StepAssign from './StepAssign';
import StepOverview from './StepOverview';
import StepSignatures from './StepSignatures';
import { IExperimentWorkflowActionUpdateEntityStateModalWizardProps } from './types';
import { getHaveAllEntityUserRolesForNextEntityBeenAssigned } from './utils';

export const UPDATE_ENTITY_STATE_MODAL_ROUTE_ID =
  'update-entity-state';

const Component: React.FC<
  IExperimentWorkflowActionUpdateEntityStateModalWizardProps
> = ({ experimentWorkflow, activeGroup, entityUsers }) => {
  const [entityState] = React.useState(
    experimentWorkflow.entity_state,
  );
  const [nextEntityState] = React.useState(
    experimentWorkflow.nextEntityState,
  );

  const steps = ['Overview'];
  const signatureRequirements = entityState.signature_requirements;
  if (signatureRequirements.length > 0) {
    steps.push('Signatures');
  }
  if (nextEntityState?.rolesToBeAssigned.length) {
    steps.push('Assign Roles');
  }

  return (
    <EntityUpdateContainer
      entityName={experimentWorkflow.entityName}
      id={experimentWorkflow.id}
    >
      {({ update, status }) => (
        <ModalWizard
          onFinish={() => {
            update({
              entity_state_id: nextEntityState?.id,
            });
          }}
          steps={steps}
          title="Update Status"
          routeId={UPDATE_ENTITY_STATE_MODAL_ROUTE_ID}
          preventScroll
          mode="mini"
        >
          <StepOverview
            experimentWorkflow={experimentWorkflow}
            activeGroup={activeGroup as Group}
            entityState={entityState}
            nextEntityState={nextEntityState}
          />
          {steps.includes('Signatures') && (
            <ModalWizard.Step
              index={1}
              description="Ensure the required signatures below have been added before proceeding."
              isContinueButtonDisabled={
                !experimentWorkflow.hasMetSignatureRequirementsForEntityState(
                  entityState,
                  experimentWorkflow.entity_state_round,
                )
              }
            >
              <StepSignatures
                experimentWorkflow={experimentWorkflow}
                entityState={entityState}
              />
            </ModalWizard.Step>
          )}
          {steps.includes('Assign Roles') && (
            <ModalWizard.Step
              index={steps.length - 1}
              description="Ensure that users have been assigned to the roles below before proceeding."
              isContinueButtonDisabled={
                !getHaveAllEntityUserRolesForNextEntityBeenAssigned(
                  entityUsers,
                ) || status?.isFetching
              }
            >
              <StepAssign
                experimentWorkflow={experimentWorkflow}
                nextEntityState={nextEntityState}
              />
            </ModalWizard.Step>
          )}
        </ModalWizard>
      )}
    </EntityUpdateContainer>
  );
};

const ComponentConnected = withActiveGroup(Component);

const ExperimentWorkflowActionUpdateEntityStateModalWizard: React.FC =
  () => {
    const { state } = useLocation<ModalRouteStateType>();
    if (!state) {
      return null;
    }
    const { experimentWorkflow } = state as {
      experimentWorkflow: ExperimentWorkflow;
    };
    if (!experimentWorkflow || !experimentWorkflow.nextEntityState) {
      return null;
    }
    return (
      <EntityReadEntityContainer
        entityName={experimentWorkflow.entityName}
        id={experimentWorkflow.id}
      >
        {({ entity }) => (
          <ReadOnMountHOC
            type="cursor"
            pagination={{ loader: 'placeholder' }}
            entityName={EntityUser.entityName}
            params={{
              experiment_workflow_id: experimentWorkflow.id,
              count: 100,
            }}
          >
            {({ entities }) => (
              <ComponentConnected
                experimentWorkflow={entity}
                entityUsers={entities.filter(
                  (e: EntityUser) =>
                    e.is_contributor || e.is_assigned,
                )}
              />
            )}
          </ReadOnMountHOC>
        )}
      </EntityReadEntityContainer>
    );
  };

export default ExperimentWorkflowActionUpdateEntityStateModalWizard;
