/**
 * Labstep
 *
 * @module components/Metadata/Detail/Table/Settings
 * @desc Table for (meta)Data Detail Settings
 */

import EntityDetailTable from 'labstep-web/components/Entity/Detail/Table';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import React from 'react';
import { IMetadataDetailTableSettingsProps } from './types';
import { getRows } from './utils';

export const MetadataDetailTableSettings: React.FC<
  IMetadataDetailTableSettingsProps
> = ({ metadata, entity, activeGroup }) => (
  <EntityDetailTable
    rows={getRows(entity, metadata, activeGroup?.id)}
    columns={[metadata]}
  />
);

export default withActiveGroup(MetadataDetailTableSettings);
