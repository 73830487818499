/**
 * Labstep
 *
 * @module components/EntityImport/Modal
 * @desc Modal for EntityImport
 */

import { EntityImportModalTriggerProps } from 'labstep-web/components/EntityImport/Modal/Trigger/types';
import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import { useModalRoute } from 'labstep-web/hooks/modalRoute';
import React from 'react';
import { ENTITY_IMPORT_ROUTE_ID } from '..';

export const EntityImportModalTrigger: React.FC<
  EntityImportModalTriggerProps
> = ({
  targetEntityName,
  templateId,
  viewComponent,
  actionComponentProps,
}) => {
  const { open, toggleModal } = useModalRoute(ENTITY_IMPORT_ROUTE_ID);
  const toggleModalWithState = (): void => {
    toggleModal({
      templateId,
      entityName: targetEntityName,
    });
  };
  return (
    viewComponent?.({ open, toggleModal: toggleModalWithState }) || (
      <ActionComponent
        type="option"
        text="Import"
        icon={ICONS.entity_import.primary}
        onClick={(): void => toggleModalWithState()}
        {...actionComponentProps}
      />
    )
  );
};

export default EntityImportModalTrigger;
