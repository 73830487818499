/**
 * Labstep
 *
 * @module components/Molecule/StoichiometryTable
 * @desc Stoichiometry table storing chemicals
 */

import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import CollapsibleCard from 'labstep-web/core/Collapsible/Card';
import TableSimple from 'labstep-web/core/Table/Simple';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import {
  Chemical,
  ChemicalType,
} from 'labstep-web/models/chemical.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { callIf } from 'labstep-web/services/react.service';
import React from 'react';
import styles from './styles.module.scss';
import {
  IIMoleculeStoichiometryTableCreateContainerProps,
  IMoleculeStoichiometryTableContainerProps,
  IMoleculeStoichiometryTableProps,
} from './types';
import { createChemicalsFromMetadata, getColumns } from './utils';

export const MAX_COUNT = 25;

const chemicalTypes = [
  'reactant',
  'product',
  'solvent',
] as ChemicalType[];

export const MoleculeStoichiometryTable: React.FC<
  IMoleculeStoichiometryTableProps
> = ({
  chemicals,
  type,
  getSearchComponent,
  showActionLinkToInventory,
  actions,
  isTemplate,
  ...rest
}) => (
  <CollapsibleCard.Item
    name={getHumanReadableEntityName(type, true, true)}
    initialExpandedState
    content={
      <>
        {!!chemicals.length && (
          <TableSimple
            className={styles.table}
            singeLine
            fixed={false}
            attached
            basic
            celled
            columns={getColumns(
              type,
              !!isTemplate,
              !!showActionLinkToInventory,
            )}
            rows={chemicals}
          />
        )}
        {callIf(getSearchComponent, type)}
      </>
    }
    actions={actions}
    {...rest}
  />
);

export const MoleculeStoichiometryTableContainer: React.FC<
  IMoleculeStoichiometryTableContainerProps
> = ({
  guid,
  fetchMetadata,
  createProtocolValueProps,
  createChemicalProps,
  attached,
  ...rest
}) => {
  const isFetching = [
    createProtocolValueProps,
    createChemicalProps,
  ].some((p) => p.status && p.status.isFetching);

  return (
    <ReadOnMountHOC
      type="cursor"
      entityName={Chemical.entityName}
      params={{ molecule_guid: guid, count: MAX_COUNT }}
      children={({ entities: chemicals, read }) => {
        // chemical can get deleted during sync
        const chemicalsNonDeleted = chemicals.filter(
          (chemical: Chemical) => !chemical.deleted_at,
        );
        return (
          <>
            {chemicalTypes.map((type) => (
              <CollapsibleCard key={type} attached={attached}>
                <MoleculeStoichiometryTable
                  chemicals={chemicalsNonDeleted.filter(
                    (chemical: Chemical) => chemical.type === type,
                  )}
                  type={type}
                  actions={
                    fetchMetadata &&
                    type !== 'solvent' && (
                      <ActionComponent
                        disabled={isFetching}
                        type="text"
                        text="Auto-detect"
                        icon="crosshairs"
                        onClick={() =>
                          fetchMetadata((metadata) =>
                            createChemicalsFromMetadata(
                              chemicalsNonDeleted,
                              metadata,
                              guid,
                              createProtocolValueProps.create,
                              createChemicalProps.create,
                              read,
                            ),
                          )
                        }
                      />
                    )
                  }
                  {...rest}
                />
              </CollapsibleCard>
            ))}
          </>
        );
      }}
    />
  );
};

export const MoleculeStoichiometryTableCreateContainer: React.FC<
  IIMoleculeStoichiometryTableCreateContainerProps
> = ({ parent, ...rest }) => (
  <EntityCreateContainer
    entityName={ProtocolValue.entityName}
    parentName={parent.entityName}
    parentId={parent.id}
    batch
  >
    {(createProtocolValueProps) => (
      <EntityCreateContainer entityName={Chemical.entityName} batch>
        {(createChemicalProps) => (
          <MoleculeStoichiometryTableContainer
            createProtocolValueProps={createProtocolValueProps}
            createChemicalProps={createChemicalProps}
            isTemplate={
              (parent instanceof Experiment && parent.isTemplate) ||
              parent instanceof Protocol
            }
            {...rest}
          />
        )}
      </EntityCreateContainer>
    )}
  </EntityCreateContainer>
);

export default MoleculeStoichiometryTableCreateContainer;
