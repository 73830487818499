/**
 * Labstep
 */

import colDefName from 'labstep-web/components/Entity/DataGrid/colDefs/name';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import ResourceItemShowCenterContentSecondaryInfo from 'labstep-web/screens/ResourceItem/Show/Center/SecondaryInfo';
import React from 'react';

export const colDefResourceItemNameNoLink = colDefName(
  ResourceItem.entityName,
  {
    getEntityPreviewProps: (resourceItem) => ({
      secondaryInfo: (
        <ResourceItemShowCenterContentSecondaryInfo
          resourceItem={resourceItem as ResourceItem}
        />
      ),
    }),
    noLink: true,
  },
);
