/**
 * Labstep
 */

import FormShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import React from 'react';
import { fieldEntityUserRoleRequirementNumberRequired } from '../../fields';
import { IEntityUserRoleRequirementFormShowEditNumberRequired } from './types';

const EntityUserRoleRequirementFormShowEditNumberRequired: React.FC<
  IEntityUserRoleRequirementFormShowEditNumberRequired
> = ({ entityUserRoleRequirement }) => {
  return (
    <FormShowEditText
      entityName={entityUserRoleRequirement.entityName}
      entity={entityUserRoleRequirement}
      field={fieldEntityUserRoleRequirementNumberRequired}
      placeholder="Enter number required"
    >
      {entityUserRoleRequirement.number_required}
    </FormShowEditText>
  );
};

export default EntityUserRoleRequirementFormShowEditNumberRequired;
