/**
 * Labstep
 *
 * @module components/ResourceLocation/MapModal
 * @desc Modal to show grid
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import GridLayoutDefault from 'labstep-web/core/GridLayout/Default';
import React, { useState } from 'react';
import ChildComponent from './ChildComponent';
import Right from './Right';
import styles from './styles.module.scss';
import {
  IResourceLocationMapModalContentContainerProps,
  IResourceLocationMapModalContentProps,
} from './types';

export const ResourceLocationMapModalContent: React.FC<
  IResourceLocationMapModalContentProps
> = ({
  resourceLocation,
  initialSelectedState = null,
  initialSelectionInfo,
  update,
}) => {
  const canEdit = useHasAccess(
    resourceLocation.entityName,
    resourceLocation.guid,
    Action.edit,
  );
  const [selected, setSelected] = useState(initialSelectedState);
  const [layout, setLayout] = useState(
    resourceLocation.initialLayout,
  );
  const [numberOfChanges, setNumberOfChanges] = useState(0);
  const [selectionInfo, setSelectionInfo] = useState(
    initialSelectionInfo,
  );

  const onChange = (data) => {
    setLayout(data);
    setNumberOfChanges(numberOfChanges + 1);
    setTimeout(() => setSelected(null), 20);
    update(
      {
        map_data: {
          ...resourceLocation.map_data,
          data,
        },
      },
      {
        noOutput: true,
        toast: true,
      },
    );
  };

  const removeItem = (i) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [i]: filteredItem, ...rest } = layout;
    onChange(rest);
  };

  const addItem = (x, y) => {
    onChange({
      ...layout,
      [selected]: {
        ...selectionInfo,
        item: { x, y, w: 1, h: 1, i: selected },
      },
    });
    setSelectionInfo(null);
  };

  const handleOnItemClick = (i, info) => {
    if (selected === i) {
      setSelected(null);
      setSelectionInfo(null);
    } else {
      setSelected(i);
      setSelectionInfo(info);
    }
  };

  return (
    <div className={styles.container}>
      {selected && !resourceLocation.map_data.data[selected] && (
        <p>
          Click a cell to specify the position of the item within this
          location
        </p>
      )}
      <div className={styles.contentContainer}>
        <div>
          <GridLayoutDefault
            numberOfChanges={numberOfChanges}
            addItem={addItem}
            rows={resourceLocation.map_data.rowCount}
            columns={resourceLocation.map_data.columnCount}
            layout={layout}
            removeItem={removeItem}
            selected={selected}
            setSelected={setSelected}
            childComponent={(props) => <ChildComponent {...props} />}
            onChange={onChange}
            resourceLocation={resourceLocation}
            readOnly={!canEdit}
          />
        </div>
        <div>
          <Right
            selected={selected}
            onItemClick={handleOnItemClick}
            resourceLocation={resourceLocation}
            onDeleteSuccess={removeItem}
          />
        </div>
      </div>
    </div>
  );
};

const ResourceLocationMapModalContentContainer: React.FC<
  IResourceLocationMapModalContentContainerProps
> = (props) => (
  <EntityUpdateContainer
    optimistic
    entityName={props.resourceLocation.entityName}
    id={props.resourceLocation.guid}
  >
    {({ update }) => (
      <ResourceLocationMapModalContent {...props} update={update} />
    )}
  </EntityUpdateContainer>
);

export default ResourceLocationMapModalContentContainer;
