/**
 * Labstep
 *
 * @module containers/Router/ProtocolChild
 * @desc Helper container for protocol child with router
 */

import React from 'react';
import { withRouter, useRouteMatch } from 'react-router-dom';
import { navigation, routing } from 'labstep-web/services/navigation';
import {
  IProtocolChildRouterChildrenProps,
  IProtocolChildRouterProps,
} from './types';

/** Get child route based on current route context */
export const getChildRoute = (
  route: string,
):
  | {
      name: keyof typeof routing;
      params: Record<string, string>;
    }
  | undefined => {
  const paths = {
    experiment_show: `experiment_show_${route}`,
    experiment_workflow_show: `experiment_workflow_show_${route}`,
    protocol_collection_show: `protocol_collection_show_${route}`,
    protocol_show: `protocol_show_${route}`,
  } as const;

  let childRoute;
  (Object.keys(paths) as (keyof typeof paths)[]).forEach((p) => {
    const match = useRouteMatch(navigation.get(p));
    if (match) {
      childRoute = { name: paths[p], params: match.params };
    }
  });
  return childRoute;
};

export const ProtocolChildRouter: React.FC<
  IProtocolChildRouterProps
> = ({ history, route, id, children, params = {}, state }) => {
  const childRoute = getChildRoute(route);
  let to: IProtocolChildRouterChildrenProps['to'] | undefined;
  let navigate:
    | IProtocolChildRouterChildrenProps['navigate']
    | undefined;
  if (childRoute && routing[childRoute.name]) {
    to = navigation.get(childRoute.name, {
      ...childRoute.params,
      childId: id,
      ...params,
    });
    navigate = () => history.push(to as string, state);
  }

  return children({ navigate, to });
};

export default withRouter(ProtocolChildRouter);
