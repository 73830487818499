/**
 * Labstep
 *
 * @module components/SignatureRequest/Action/ViewList
 * @desc A modal to show a list of signature requests
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import SignatureRequestList from 'labstep-web/components/SignatureRequest/List';
import { ISignatureRequestActionViewListProps } from './types';

export const SignatureRequestActionViewList: React.FC<
  ISignatureRequestActionViewListProps
> = ({ experimentWorkflow }) => (
  <ModalDefault
    size="large"
    header="Pending Requests"
    content={() => (
      <SignatureRequestList experimentWorkflow={experimentWorkflow} />
    )}
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        type="text"
        text="View pending requests"
        onClick={toggleModal}
      />
    )}
  />
);

export default SignatureRequestActionViewList;
