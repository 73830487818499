/**
 * Labstep
 *
 * @module components/Resource/Action/EditItemTemplate
 * @desc Action for editing item template
 */

import React from 'react';
import Modal from 'labstep-web/core/Modal/Default';
import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import ResourceItemManagerTemplate from 'labstep-web/components/ResourceItem/Manager/Template';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { IResourceItemActionEditTemplateProps } from './types';

export const ResourceItemActionEditTemplate: React.FC<
  IResourceItemActionEditTemplateProps
> = ({ resource, actionComponentProps }) => (
  <Modal
    viewComponent={({ toggleModal }) => (
      <EntityActionEdit
        entity={resource}
        actionComponentProps={{
          type: 'text',
          icon: 'copy',
          text: `Edit ${ResourceItem.getHumanReadableEntityName(
            false,
            false,
            true,
          )}`,
          onClick: toggleModal,
          ...actionComponentProps,
        }}
      />
    )}
    content={<ResourceItemManagerTemplate resource={resource} />}
    size="large"
    closeOnDimmerClick
  />
);

export default ResourceItemActionEditTemplate;
