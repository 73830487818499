/**
 * Labstep
 *
 * @module components/Group/List/Transfer
 * @desc Preview group transfer
 */

import GroupList from 'labstep-web/components/Group/List';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Group } from 'labstep-web/models/group.model';
import React from 'react';
import { GroupListTransferProps } from './types';

export const GroupListTransfer: React.FC<GroupListTransferProps> = ({
  authenticatedUserEntity,
  options,
}) => (
  <ReadOnMountHOC
    type="cursor"
    entityName={Group.entityName}
    params={{
      is_owner: 1,
      count: 100,
    }}
    children={({ entities }) =>
      entities.length ? (
        <>
          <p>
            The following workspaces will be transferred to the new
            organization
          </p>
          <GroupList groups={entities} hideActions />
          <EntityUpdateContainer entityName={Group.entityName} batch>
            {({ update, status }) => (
              <ActionComponent
                type="button"
                text="Transfer workspaces"
                status={status}
                onClick={() => {
                  update(
                    entities.map((group: Group) => ({
                      id: group.guid,
                      organization_id:
                        authenticatedUserEntity.userOrganizationOrganization!
                          .guid,
                    })),
                    options,
                  );
                }}
              />
            )}
          </EntityUpdateContainer>
        </>
      ) : (
        <>
          <p>No workspaces to transfer</p>
          <EntityUpdateContainer entityName={Group.entityName} batch>
            {({ update, status }) => (
              <ActionComponent
                type="button"
                text="Continue"
                status={status}
                onClick={() => {
                  update(
                    entities.map((group: Group) => ({
                      id: group.guid,
                      organization_id:
                        authenticatedUserEntity.userOrganizationOrganization!
                          .guid,
                    })),
                    options,
                  );
                }}
              />
            )}
          </EntityUpdateContainer>
        </>
      )
    }
  />
);

export default withAuthenticatedUserEntity(GroupListTransfer);
