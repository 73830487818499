/**
 * Labstep
 *
 * @module containers/Notification/SetViewed
 * @desc Container for setting notifications as viewed
 */

import { setAllNotificationAsViewed } from 'labstep-web/state/actions/notification';
import { selectApiStatus } from 'labstep-web/state/selectors/ui';
import { connect } from 'react-redux';

const Container = ({ children, ...rest }: any) => children(rest);

const mapStateToProps = (state: any) => ({
  status: selectApiStatus(state, 'set_all_notifications_as_viewed'),
});

const mapDispatchToProps = (dispatch: (...args: any[]) => void) => ({
  setAllNotificationAsViewed() {
    dispatch(setAllNotificationAsViewed());
  },
});

/**
 * Delete Cotainer connecting a component with state
 * Returns orginal component with
 * delete function and status of action
 */
export const NotificationsSetViewedContainer = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
