/**
 * Labstep
 *
 * @module components/Group/Item
 * @desc Item component for group
 */

import React from 'react';
import { Size } from 'labstep-web/core/Initials/types';
import { GroupInitials } from '../Initials';
import { IGroupItemProps } from './types';
import styles from './styles.module.scss';

export const GroupItem: React.FC<IGroupItemProps> = ({ group }) => (
  <div
    data-testid={`group-initials-${group.id}`}
    className={styles.item}
  >
    <GroupInitials group={group} size={Size.mini} />
    <div className={styles.name}>{group.name}</div>
  </div>
);

export default GroupItem;
