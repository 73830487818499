/**
 * Labstep
 *
 * @module components/JupyterNotebook/Form/ShowEdit/Name
 * @desc ShowEdit for name for resource item
 */

import React from 'react';
import ShowEdit from 'labstep-web/core/Form/ShowEdit/Text';
import { fieldJupyterNotebookName } from 'labstep-web/components/JupyterNotebook/Form/fields';
import { IJupyterNotebookFormShowEditNameProps } from './types';

const JupyterNotebookFormShowEditName: React.FC<
  IJupyterNotebookFormShowEditNameProps
> = ({ jupyterNotebook }) => (
  <ShowEdit
    entityName={jupyterNotebook.entityName}
    entity={jupyterNotebook}
    field={fieldJupyterNotebookName}
  >
    {jupyterNotebook.name}
  </ShowEdit>
);

export default JupyterNotebookFormShowEditName;
