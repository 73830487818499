/**
 * Labstep
 *
 * @module screens/Group/Show
 * @desc Group Show Screen
 */

import FullPageError from 'labstep-web/components/Layout/FullPageError';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import DocumentHead from 'labstep-web/core/DocumentHead';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import MasterShow from 'labstep-web/screens/Master/Show';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Center from './Center';
import {
  IGroupShowScreenProps,
  IShowMainContentProps,
} from './types';

export const GroupShowScreen: React.FC<IGroupShowScreenProps> = ({
  group,
}) => (
  <>
    <DocumentHead title={group.name} />
    <MasterShow
      entity={group}
      center={<Center group={group} />}
      noBorder
    />
  </>
);

export class ShowMainContent extends React.Component<
  IShowMainContentProps,
  { mounted: boolean }
> {
  constructor(props: IShowMainContentProps) {
    super(props);
    this.state = { mounted: false };
  }

  componentDidMount() {
    const { group, setActiveGroupId } = this.props;
    setActiveGroupId(group.id);
    this.setState({ mounted: true });
  }

  render() {
    if (!this.state.mounted) {
      return null;
    }
    const { group } = this.props;

    return <GroupShowScreen group={group} />;
  }
}

export const WithUiShowMainContent = withActiveGroup(ShowMainContent);

export const Show = ({ match }) => (
  <ReadOnMountHOC
    type="entity"
    loading={{ loader: 'main_content', cached: true }}
    entityName="group"
    id={match.params.id}
    FallbackComponent={<FullPageError showSignout />}
    children={({ entity }) => (
      <WithUiShowMainContent group={entity} />
    )}
  />
);

export const ShowRedirect = () => (
  <Switch>
    <Redirect
      exact
      from={navigation.get('group_show')}
      to={navigation.get('group_overview')}
    />
    <Route component={Show} />
  </Switch>
);

export default ShowRedirect;
