/**
 * Labstep
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const DEFAULT_ROWS_VALUE = 5;
export const DEFAULT_COLUMNS_VALUE = 2;

export const fieldHtmlTableRows: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'rows',
  fieldLabel: 'Rows',
  validation: rules.htmlTable.rows,
  header: true,
};

export const fieldHtmlTableColumns: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'columns',
  fieldLabel: 'Columns',
  validation: rules.htmlTable.columns,
  header: true,
};
