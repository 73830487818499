/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/Index/Breadcrumb
 * @desc ExperimentWorkflow Index Breadcrumb
 */

import FolderBreadcrumb from 'labstep-web/components/Folder/Breadcrumb';
import { FolderType } from 'labstep-web/models/folder.model';
import React from 'react';

export const ExperimentWorkflowIndexBreadcrumb: React.FC = () => (
  <FolderBreadcrumb type={FolderType.experiment_workflow} />
);

export default ExperimentWorkflowIndexBreadcrumb;
