/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * Labstep
 *
 * @module core/QRCode
 * @desc QRCode wrapper around qrcode.react
 */

import React from 'react';
import ReactQRCode from 'qrcode.react';
import { IQRCodeProps } from './types';
import styles from './styles.module.scss';

const CANVAS_SELECTOR = '#qrcode > canvas';

class QRCode extends React.Component<IQRCodeProps> {
  static defaultProps = {
    name: 'labstep',
    download: true,
  };

  downloadRef: any;

  constructor(props) {
    super(props);
    this.handleDownloadClick = this.handleDownloadClick.bind(this);
  }

  handleDownloadClick() {
    const { name } = this.props;
    const canvas: HTMLCanvasElement =
      document.querySelector(CANVAS_SELECTOR);
    this.downloadRef.href = canvas.toDataURL();
    this.downloadRef.download = `${name}-qrcode.png`;
  }

  render() {
    const { download, name, ...rest } = this.props;
    return (
      <div id="qrcode" className={styles.container}>
        <ReactQRCode {...rest} />
        <br />
        <div>
          <a
            onClick={this.handleDownloadClick}
            // eslint-disable-next-line no-return-assign
            ref={(ref: any): any => (this.downloadRef = ref)}
          >
            <span>Print QR Code</span>
          </a>
        </div>
      </div>
    );
  }
}

export default QRCode;
