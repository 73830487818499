/**
 * Labstep
 *
 * @module core/Location
 * @desc A location aware (dummy) component
 * Use this to fire any actions you want on location change
 *
 * TODO: Move away from this dummy component. Instead user history service to
 * fire events
 */

import { menuName as leftMenuName } from 'labstep-web/components/Layout/Menu/Left';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import { withSidebar } from 'labstep-web/containers/Ui/Sidebar';
import bugsnagService from 'labstep-web/services/bugsnag.service';
import store from 'labstep-web/state/store';
import React from 'react';
import { withRouter } from 'react-router';
import { ILocationProps } from './types';

export const isLocationTheSame = (prev: any, next: any): boolean =>
  prev.pathname === next.pathname && prev.search === next.search;

const eventName = 'locationChange';

export class Location extends React.Component<ILocationProps> {
  componentDidMount() {
    const { location } = this.props;
    const path = `${location.pathname}${location.search}`;

    store.dispatch({
      type: 'LOCATION_CHANGE',
      meta: {
        path,
      },
    });
  }

  componentDidUpdate(prevProps: ILocationProps) {
    const {
      location,
      history: { action },
      setSidebar,
      activeEditModal,
      setActiveEditModal,
    } = this.props;

    if (location.key !== prevProps.location.key) {
      setSidebar(false, leftMenuName);
    }

    if (isLocationTheSame(prevProps.location, location)) {
      return;
    }

    if (activeEditModal) {
      setActiveEditModal(null);
    }

    if (action === 'PUSH') {
      // On navigation to new page - scroll to top
      window.scrollTo(0, 0);
      const event = new Event(eventName);
      window.dispatchEvent(event);
    }
    const path = `${location.pathname}${location.search}`;
    bugsnagService.leaveBreadcrumb('Route changed', { path });

    store.dispatch({
      type: 'LOCATION_CHANGE',
      meta: {
        path,
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return <span />;
  }
}

// Create a new component that is "connected" (to borrow redux
// terminology) to the router.
export default withRouter(withSidebar(withActiveEditModal(Location)));
