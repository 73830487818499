/**
 * Labstep
 *
 * @module core/DataGrid/ColumnHeader/EntityImport
 * @desc DataGrid Header for EntityImport
 */

import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import Icon from 'labstep-web/core/Icon';
import Popup from 'labstep-web/core/Popup';
import { EntityImportColDef } from 'labstep-web/models/entity-import.model';
import { Resource } from 'labstep-web/models/resource.model';
import { CustomIdentifierSet } from 'labstep-web/models/custom-identifier-set.model';
import { RESOURCE_IDENTIFIER_COL_NAME } from 'labstep-web/services/agGrid/ag-grid-entity-import.service';
import { useAgGridEntityImportServiceFromContext } from '../../context';
import { IDataGridColumnHeaderEntityImportProps } from './types';
import DataGridColumnHeaderEntityImportCustom from './Metadata';
import DataGridColumnHeaderEntityImportDateFormat from './DateFormat';
import styles from './styles.module.scss';

export const DataGridColumnHeaderEntityImport: React.FC<
  IDataGridColumnHeaderEntityImportProps
> = (props) => {
  const agGridEntityImportService =
    useAgGridEntityImportServiceFromContext();
  const colDef = props.column.getColDef() as
    | EntityImportColDef
    | undefined; // This can happen

  const [hasEmptyIdentifier, setHasEmptyIdentifier] = useState(false);

  useEffect(() => {
    if (agGridEntityImportService && colDef) {
      setHasEmptyIdentifier(
        agGridEntityImportService.hasEmptyIdentifier(),
      );
    }
  }, [agGridEntityImportService, colDef]);

  if (!colDef) {
    return null;
  }
  let customIdentifierSet: CustomIdentifierSet | null = null;

  if (props.context.template instanceof Resource) {
    customIdentifierSet =
      props.context.template._custom_identifier_set;
  }

  const warningIcon = (
    <Icon
      color="yellow"
      name="warning sign"
      data-testid="resource_identifier_warning_icon"
      popup={{
        content:
          'Identifiers must be pre-allocated before import. Click to allocate.',
      }}
      onClick={() => {
        if (customIdentifierSet) {
          let number = customIdentifierSet.number_auto_increment + 1;
          const { prefix, zero_padding } =
            customIdentifierSet.settings;

          const colId = props.column.getColId();

          const existingValues = new Set<string>();
          props.api.forEachNode((rowNode) => {
            const cellValue = rowNode.data[colId];
            if (cellValue && cellValue.startsWith(prefix)) {
              existingValues.add(cellValue);
            }
          });

          props.api.forEachNode((rowNode) => {
            const cellValue = rowNode.data[colId];
            let newValue: string;
            if (!cellValue) {
              do {
                newValue = `${prefix}${String(number).padStart(
                  zero_padding,
                  '0',
                )}`;
                number += 1;
              } while (existingValues.has(newValue));
              rowNode.setDataValue(colId, newValue);
            }
          });
          props.api.refreshHeader();
        }
      }}
    />
  );

  return (
    <DataGridColumnHeaderEntityImportCustom
      trigger={
        <span
          className={classNames(styles.columnHeader, {
            [styles.clickable]: colDef.isCustom || !colDef.isRequired,
          })}
        >
          <span>
            {colDef.isCustom && (
              <Popup
                content="This column does not match any fields on the template. Click to match it to a column or create a new one."
                trigger={<Icon name="warning sign" color="yellow" />}
              />
            )}
            {props.icon && (
              <>
                <Icon
                  name={props.icon}
                  cornerIcon={props.cornerIcon}
                />{' '}
              </>
            )}
            {colDef.headerName}
            {props.info && (
              <>
                {' '}
                <Icon
                  name="info circle"
                  popup={{ content: props.info }}
                />{' '}
              </>
            )}

            <DataGridColumnHeaderEntityImportDateFormat
              colDef={colDef}
            />
          </span>
          {colDef.headerName === RESOURCE_IDENTIFIER_COL_NAME &&
            hasEmptyIdentifier &&
            warningIcon}
        </span>
      }
      {...props}
    />
  );
};

export default DataGridColumnHeaderEntityImport;
