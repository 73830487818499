/**
 * Labstep
 *
 * @module components/ResourceItem/Action/Menu
 * @desc Action Menu for resourceItem
 */

import React from 'react';
import ActionMenu from 'labstep-web/core/Action/Menu';
import ResourceItemActionDelete from 'labstep-web/components/ResourceItem/Action/Delete';
import ResourceItemActionDuplicate from 'labstep-web/components/ResourceItem/Action/Duplicate';
import EntityUserActionManage from 'labstep-web/components/EntityUser/Action/Manage';
import { Action, IResourceItemActionMenuProps } from './types';

export const ResourceItemActionMenu: React.FC<
  IResourceItemActionMenuProps
> = ({ resourceItem, type, actions = [] }) => (
  <ActionMenu type={type}>
    {actions.includes(Action.duplicate) && (
      <ResourceItemActionDuplicate resourceItem={resourceItem} />
    )}
    {actions.includes(Action.assign) && (
      <EntityUserActionManage entity={resourceItem} />
    )}
    {actions.includes(Action.delete) && (
      <ResourceItemActionDelete resourceItem={resourceItem} />
    )}
  </ActionMenu>
);

ResourceItemActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default ResourceItemActionMenu;
