/**
 * Labstep
 *
 * @module core/Table/Collapsible
 * @description A table with collapsible rows
 */

import classnames from 'classnames';
import EntitySortableTable from 'labstep-web/components/Entity/Sortable/Table';
import DragHandle from 'labstep-web/core/Sortable/Item/DragHandle';
import SortableItemTable from 'labstep-web/core/Sortable/Item/Table';
import Table from 'labstep-web/core/Table';
import SimpleTableCollapsible from 'labstep-web/core/Table/Simple/Collapsible';
import { Metadata } from 'labstep-web/models/metadata';
import React from 'react';
import styles from './styles.module.scss';
import {
  IEntityTableDragSortableProps,
  ISortableTableBody,
} from './types';

export const sortableTableBody: ISortableTableBody =
  (sortable, disabled, additionalItemRows) =>
  ({ rows, columns }) => (
    <EntitySortableTable
      className={classnames(styles.body, styles.borderless)}
      entityName={sortable}
      items={rows}
      disabled={disabled}
      isTableBody={!additionalItemRows}
      renderItem={(item) => (
        <SortableItemTable
          key={item.id}
          id={item.id}
          additionalRows={additionalItemRows?.(item as Metadata)}
        >
          {columns.map((column, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Table.Cell key={index} {...column.cellProps}>
              {index === 0 ? (
                <DragHandle>{column.content(item)}</DragHandle>
              ) : (
                column.content(item)
              )}
            </Table.Cell>
          ))}
        </SortableItemTable>
      )}
    />
  );

const EntityTableDragSortable: React.FC<
  IEntityTableDragSortableProps
> = ({
  sections,
  columns,
  sortable,
  disabled,
  additionalItemRows,
  ...rest
}) => {
  const sortableSections = sections.map((section) => ({
    ...section,
    customBody:
      section.customBody ||
      sortableTableBody(sortable, disabled, additionalItemRows),
  }));

  return (
    <SimpleTableCollapsible
      columns={columns}
      sections={sortableSections}
      {...rest}
    />
  );
};

export default EntityTableDragSortable;
