/**
 * Labstep
 *
 * @module core/Progress
 * @desc Wrapper around semantic-ui-react Progress
 */

import Progress from 'semantic-ui-react/dist/commonjs/modules/Progress';

export default Progress;
