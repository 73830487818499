/**
 * Labstep
 *
 * @module core/MediaQuery/Desktop
 * @desc MediaQuery for Desktop
 */

import MediaQuery from 'labstep-web/core/MediaQuery';
import {
  IMediaQueryProps,
  Size,
} from 'labstep-web/core/MediaQuery/types';
import React from 'react';

type IMediaQueryDesktopProps = Omit<IMediaQueryProps, 'size'>;

const MediaQueryDesktop: React.FC<IMediaQueryDesktopProps> = ({
  children,
}) => <MediaQuery size={Size.desktop}>{children}</MediaQuery>;

export default MediaQueryDesktop;
