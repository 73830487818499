/**
 * Labstep
 *
 * @module
 * @desc Modal showing empy state for premium features
 */

import ModalDefault from 'labstep-web/core/Modal/Default';
import PremiumFeatureEmptyState from 'labstep-web/core/PremiumFeature/EmptyState';
import React from 'react';
import { IPremiumFeatureModalProps } from './types';

export const PremiumFeatureModal: React.FC<
  IPremiumFeatureModalProps
> = ({ premiumFeature, ...rest }) => (
  <ModalDefault
    dataTestId="premium-feature-modal"
    header="Upgrade Now"
    content={
      <PremiumFeatureEmptyState premiumFeature={premiumFeature} />
    }
    {...rest}
  />
);
export default PremiumFeatureModal;
