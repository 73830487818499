/**
 * Labstep
 *
 * @module screens/Authentication/ResetPassword
 * @desc ResetPasswordScreen
 */

import ResetPasswordForm from 'labstep-web/components/User/Form/ResetPassword';
import { UserResetPasswordContainer } from 'labstep-web/containers/User/ResetPassword';
import Button from 'labstep-web/core/Button';
import DocumentHead from 'labstep-web/core/DocumentHead';
import Image from 'labstep-web/core/Image';
import Columns from 'labstep-web/screens/Authentication/Columns';
import React from 'react';
import styles from './styles.module.scss';

export const ResetPasswordComponent = ({
  resetPassword,
  status,
  match: {
    params: { token },
  },
}) => {
  const successful =
    status && status.isFetching === false && status.error === null;

  if (successful) {
    return (
      <div>
        <p>
          <b>Your password has been succesfuly reset!</b>
        </p>
        <Button route={{ to: 'login' }} primary fluid>
          Login
        </Button>
      </div>
    );
  }

  return (
    <ResetPasswordForm
      token={token}
      resetPassword={resetPassword}
      status={status}
    />
  );
};

export const ResetPasswordColumns = (props) => (
  <>
    <DocumentHead title="Reset Password" />
    <Columns
      left={
        <div className={styles.imageContainer}>
          <Image src="/img/error/locked.svg" fluid />
        </div>
      }
      right={<ResetPasswordComponent {...props} />}
      header="Reset password"
      explainer=""
    />
  </>
);

const ResetPasswordScreenContainer = (props) => (
  <UserResetPasswordContainer>
    {(containerProps) => (
      <ResetPasswordColumns {...containerProps} {...props} />
    )}
  </UserResetPasswordContainer>
);

export default ResetPasswordScreenContainer;
