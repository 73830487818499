/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/Print
 * @desc Display printable version of an ExperimentWorkflow
 */

import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import Print from 'labstep-web/core/Print';
import { EntityExport } from 'labstep-web/models/entity-export.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import React from 'react';
import { IExperimentWorkflowPrintScreenProps } from './types';

export const ExperimentWorkflowPrintScreen: React.FC<
  IExperimentWorkflowPrintScreenProps
> = ({ match }) => (
  <EntityCreateContainer entityName={EntityExport.entityName}>
    {({ status, create }) => (
      <Print
        id={match.params.id}
        entityName={ExperimentWorkflow.entityName}
        status={status}
        create={create}
      />
    )}
  </EntityCreateContainer>
);

export default ExperimentWorkflowPrintScreen;
