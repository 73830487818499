/**
 * Labstep
 */

import { Action } from 'labstep-web/models/action.model';

/**
 * Returns the state when requesting a count
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @param  {string} identifier - Identifier
 * @return {object}
 */
export const requestCount = (
  state: any,
  action: Action,
  identifier: string,
) => ({
  ...state,
  [identifier]: {
    ...state[identifier],
    status: {
      isFetching: true,
      error: null,
    },
  },
});

/**
 * Returns the state when receiving a count
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @param  {string} identifier - Identifier
 * @return {object}
 */
export const receiveCount = (
  state: any,
  action: Action,
  identifier: string,
) => ({
  ...state,
  [identifier]: {
    ...state[identifier],
    total: action.payload,
    status: {
      isFetching: false,
      error: null,
    },
  },
});

/**
 * Returns the state when requesting a count
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @param  {string} identifier - Identifier
 * @return {object}
 */
export const failCount = (
  state: any,
  action: Action,
  identifier: string,
) => ({
  ...state,
  [identifier]: {
    ...state[identifier],
    status: {
      isFetching: false,
      error: action.error,
    },
  },
});
