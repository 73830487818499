/**
 * Labstep
 *
 * @module screens/Authentication/SAML/GetLoginUrl
 * @desc Login screen for saml sso
 */

import { UserGetSAMLLoginUrlContainer } from 'labstep-web/containers/User/GetSAMLLoginUrl';
import Loader from 'labstep-web/core/Loader';
import { windowService } from 'labstep-web/services/window.service';
import React from 'react';
import styles from './styles.module.scss';
import {
  GetSAMLLoginUrlContainerProps,
  GetSAMLLoginUrlProps,
} from './types';

export class GetSAMLLoginUrl extends React.Component<GetSAMLLoginUrlProps> {
  componentDidMount() {
    let { organization } = this.props;
    const { getSAMLLoginUrl } = this.props;
    if (!organization) {
      organization = windowService.detectOrganization();
    }
    getSAMLLoginUrl(organization.identifier);
  }

  render() {
    const { status } = this.props;
    return (
      <div className={styles.container}>
        {!(status && status.error) ? (
          <div className={styles.loaderContainer}>
            <Loader active size="huge">
              Redirecting...
            </Loader>
          </div>
        ) : (
          <div>
            Oops something went wrong! Please refresh your page. If
            the problem still persist please contact Labstep at
            info@labstep.com
          </div>
        )}
      </div>
    );
  }
}

const GetSAMLLoginUrlContainer: React.FC<
  GetSAMLLoginUrlContainerProps
> = ({ organization }) => (
  <UserGetSAMLLoginUrlContainer>
    {({ getSAMLLoginUrl, status }) => (
      <GetSAMLLoginUrl
        organization={organization}
        getSAMLLoginUrl={getSAMLLoginUrl}
        status={status}
      />
    )}
  </UserGetSAMLLoginUrlContainer>
);

export default GetSAMLLoginUrlContainer;
