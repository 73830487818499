/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/ColorPicker
 * @desc Color picker for text and background color
 */

import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { uniq } from 'lodash';
import { TwitterPicker } from 'react-color';
import { toggleMark } from 'prosemirror-commands';
import { EditorState } from 'prosemirror-state';
import { Popup } from 'semantic-ui-react';
import {
  getAllNodesAttribute,
  getSelectionAttribute,
  removeMark,
  setMark,
} from 'labstep-web/prosemirror/marks/utils';
import ToolbarButton from 'labstep-web/prosemirror/components/Toolbar/Button';
import MarkColor from 'labstep-web/prosemirror/marks/color';
import MarkBackgroundColor from 'labstep-web/prosemirror/marks/background-color';
import {
  IColorPickerProps,
  IColorPickerToolbarProps,
  IColorPickerTwitterProps,
} from './types';
import {} from './styles.module.scss';

/** Default colors */
export const DEFAULT_COLORS = [
  '#FF6900',
  '#FCB900',
  '#7BDCB5',
  '#00D084',
  '#8ED1FC',
  '#0693E3',
  '#ABB8C3',
  '#EB144C',
  '#F78DA7',
];

/** Default text color */
export const DEFAULT_TEXT_COLOR = '#000000';

/** Default background color */
export const DEFAULT_BACKGROUND_COLOR = '#FFFFFF';

/**
 * Return color from selection or storedMarks and only
 * if a single color is selected, otherwise return defaultColor
 *
 * @param state
 * @param attr
 * @param defaultColor
 * @return Found color or defaultColor if not found or multiple colors found
 */
export const getSelectionColor = (
  state: EditorState,
  attr: string,
  defaultColor: string,
): string => {
  if (!state.schema.marks[attr]) {
    return defaultColor;
  }

  const colors = getSelectionAttribute(
    state,
    state.schema.marks[attr],
  );

  return colors.length === 1 && colors[0] ? colors[0] : defaultColor;
};

export const detectColors = (
  state: EditorState,
  attr: string,
  defaultColor: string,
): string[] => {
  const detectedColors = getAllNodesAttribute(state, attr);

  return uniq(
    [defaultColor, ...detectedColors, ...DEFAULT_COLORS].map(
      (color: string) => color.toUpperCase(),
    ),
  ).slice(0, 10);
};

export const ColorPickerTwitter: React.FC<
  IColorPickerTwitterProps
> = ({ view, attr, defaultColor, handleClose }) => {
  const [colors, setColors] = useState([]);

  useEffect(() => {
    if (colors.length === 0) {
      setColors(detectColors(view.state, attr, defaultColor));
    }
  }, [colors.length, view.state, attr, defaultColor]);

  return (
    <div
      onClick={(e: any) => {
        if (
          e.target &&
          e.target.parentNode &&
          e.target.parentNode.nodeName === 'SPAN'
        ) {
          handleClose();
          view.focus();
        }
      }}
      onKeyPress={(e: any) => {
        if (e.charCode === 13) {
          handleClose();
          view.focus();
        }
      }}
    >
      <TwitterPicker
        triangle="hide"
        onChangeComplete={(e: any) => {
          if (e.hex === defaultColor) {
            removeMark(view.state.schema.marks[attr])(
              view.state,
              view.dispatch,
            );
          } else {
            setMark(view.state.schema.marks[attr], {
              [attr]: e.hex,
            })(view.state, view.dispatch);
          }
        }}
        colors={colors}
      />
    </div>
  );
};

export const ColorPicker: React.FC<IColorPickerProps> = ({
  view,
  attr,
  title,
  icon,
  defaultColor,
  popupStyle,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const selectionColor = getSelectionColor(
    view.state,
    attr,
    defaultColor,
  );
  return (
    <Popup
      basic={isMobile}
      trigger={
        <ToolbarButton
          icon={icon}
          displayColorBar
          color={selectionColor}
          popup={title}
          disabled={
            !toggleMark(view.state.schema.marks[attr], {})(view.state)
          }
          isActive={selectionColor !== defaultColor}
        />
      }
      disabled={
        !toggleMark(view.state.schema.marks[attr], {})(view.state)
      }
      position="bottom center"
      open={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      style={popupStyle}
    >
      <Popup.Content>
        <ColorPickerTwitter
          view={view}
          attr={attr}
          defaultColor={defaultColor}
          handleClose={handleClose}
        />
      </Popup.Content>
    </Popup>
  );
};

const ColorPickerToolbar: React.FC<IColorPickerToolbarProps> = ({
  view,
}) => (
  <div style={{ display: 'flex' }}>
    <ColorPicker
      view={view}
      attr={MarkColor.key}
      title="Text Color"
      icon="font"
      defaultColor={DEFAULT_TEXT_COLOR}
      popupStyle={{
        padding: 0,
        top: isMobile ? '40px' : 0,
        left: isMobile ? '40px' : '-30px',
      }}
    />
    <ColorPicker
      view={view}
      attr={MarkBackgroundColor.key}
      title="Highlight Color"
      icon="paint brush"
      defaultColor={DEFAULT_BACKGROUND_COLOR}
      popupStyle={{
        padding: 0,
        top: isMobile ? '40px' : 0,
        left: isMobile ? '40px' : '-46px',
      }}
    />
  </div>
);

export default ColorPickerToolbar;
