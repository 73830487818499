/**
 * Labstep
 *
 * @module components/DeviceBooking/Form/ShowEdit/StartedAt
 * @desc StartedAt show edit for device booking
 */

import React from 'react';
import FormShowEditDate from 'labstep-web/core/Form/ShowEdit/Date';
import {
  appendDateDifference,
  dateDifferenceInMinutes,
  format,
  subtractDateDifference,
} from 'labstep-web/services/date.service';
import { IDeviceBookingFormShowEditStartedAtProps } from './types';

export const getBody = (body, startedAt, endedAt) => {
  const difference = dateDifferenceInMinutes(endedAt, startedAt);
  if ('started_at' in body && body.started_at >= endedAt) {
    return {
      ...body,
      ended_at: format(
        appendDateDifference(body.started_at, difference),
      ),
    };
  }
  if ('ended_at' in body && body.ended_at <= startedAt) {
    return {
      ...body,
      started_at: format(
        subtractDateDifference(body.ended_at, difference),
      ),
    };
  }
  return body;
};

export const DeviceBookingFormShowEditStartedAt: React.FC<
  IDeviceBookingFormShowEditStartedAtProps
> = ({ deviceBooking }) => (
  <FormShowEditDate
    entity={deviceBooking}
    label="start"
    field="started_at"
    getBody={(body) =>
      getBody(body, deviceBooking.started_at, deviceBooking.ended_at)
    }
    optimistic
    // dateTimePickerOptions={{
    //   maxDate: deviceBooking.ended_at,
    // }}
  />
);

export default DeviceBookingFormShowEditStartedAt;
