/**
 * Labstep
 */

import { IActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';

// eslint-disable-next-line no-shadow
export enum Action {
  duplicate = 'duplicate',
  view = 'view',
  share = 'share',
  add_to_experiment = 'add_to_experiment',
  print = 'print',
  assign = 'assign',
  tag = 'tag',
  delete = 'delete',
  revoke = 'revoke',
  transfer_ownership = 'transfer_ownership',
  add_to_folder = 'add_to_folder',
  remove_from_folder = 'remove_from_folder',
}

export interface IProtocolCollectionActionMenuProps
  extends Pick<
    IActionMenuProps,
    Exclude<keyof IActionMenuProps, 'children'>
  > {
  protocolCollection: ProtocolCollection;
  actions: Action[];
}
