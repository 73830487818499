/**
 * Labstep
 */

import { combineReducers } from 'redux';

/**
 * If action is logging out we put the current state as undefined to reset all reducers
 *
 * @function
 * @param  {object} state
 */
export const logoutAwareCombineReducers = (reducers: any) => {
  const combinedReducers = combineReducers(reducers);
  return (state: any, action: any) => {
    if (action && action.type === 'SUCCESS_LOGOUT') {
      return combinedReducers(undefined, action as never);
    }
    return combinedReducers(state, action as never);
  };
};

export const logoutAwareReducer = (reducer) => (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'SUCCESS_LOGOUT') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return reducer(state, action);
};
