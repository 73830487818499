/**
 * Labstep
 *
 * @module components/Notification/List/Preview
 * @desc List of notification Preview Card
 */

import NotificationCardEmptyState from 'labstep-web/components/Notification/Card/EmptyState';
import NotificationPreviewCard from 'labstep-web/components/Notification/Card/Preview';
import Card from 'labstep-web/core/Card/Card';
import { HorizontalDivider } from 'labstep-web/core/Divider';
import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import Link from 'labstep-web/core/Link';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import React from 'react';
import styles from './styles.module.scss';
import { IMoreCountProps, INotificationListProps } from './types';

const count = 8;

export const MoreCount: React.FC<IMoreCountProps> = ({ total }) => (
  <Link unstyled to="notification_index">
    <Card className={styles.moreCard}>
      <Header className={styles.header} size="small" color="white">
        <span>+ {total - count}</span>
        <span> more</span>
      </Header>
    </Card>
  </Link>
);

export const NotificationList: React.FC<INotificationListProps> = ({
  notifications,
}) => (
  <>
    {notifications.length > 0 ? (
      <>
        {notifications.map((notification) => (
          <div key={notification.id} className={styles.listItem}>
            <NotificationPreviewCard notification={notification} />
          </div>
        ))}
      </>
    ) : (
      <NotificationCardEmptyState img={false} />
    )}
  </>
);

export const NotificationListPreviewContainer: React.FC = () => (
  <ReadOnMountHOC
    type="cursor"
    pagination={{ infinite: false, showMore: true }}
    entityName="notification"
    params={{ count }}
    children={({ entities, total }) => (
      <NotificationList notifications={entities} total={total} />
    )}
  />
);

export const NotificationListPreview: React.FC = () => (
  <div className={styles.container}>
    <div className={styles.headerContainer}>
      <Header className={styles.header} size="tiny">
        <Icon name="bell outline" />
        Notifications
      </Header>
      <div>
        <Link unstyled to="settings_notifications">
          <Icon
            name="cog"
            popup={{ content: 'Settings' }}
            color="blue"
          />
        </Link>

        <Link unstyled to="notification_index">
          <Icon
            name="external alternate"
            popup={{ content: 'View All' }}
            color="blue"
          />
        </Link>
      </div>
    </div>
    <HorizontalDivider />
    <div className={styles.notificationListContainer}>
      <NotificationListPreviewContainer />
    </div>
  </div>
);

export default NotificationListPreview;
