/**
 * Labstep
 *
 * @module components/OrderRequest/Action/RemovePurchaseOrder
 * @desc Bulk action to update purchase_order_id for order request
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ICONS } from 'labstep-web/constants/icons';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import React from 'react';
import { IOrderRequestActionRemovePurchaseOrderBulkProps } from './types';

export const OrderRequestActionRemovePurchaseOrderBulk: React.FC<
  IOrderRequestActionRemovePurchaseOrderBulkProps
> = ({ entities, options = {} }) => {
  const entityIdsAccessObject = useHasAccessMultiple(
    OrderRequest.entityName,
    entities ? entities.map((e) => e.id) : [],
    Action.edit,
  );

  const entitiesWithPermission = entities
    ? entities.filter((e) => entityIdsAccessObject[e.id])
    : [];

  if (
    !entities ||
    entities.length === 0 ||
    entitiesWithPermission.length === 0
  ) {
    return null;
  }

  return (
    <EntityActionUpdate
      entityName={OrderRequest.entityName}
      id={entitiesWithPermission.map((p) => p.id)}
      body={{ purchase_order_id: null }}
      options={options}
      actionComponentProps={{
        type: 'children',
        children: ({ onClick, status }) => (
          <BulkActionComponent
            entitiesWithPermissionCount={
              entitiesWithPermission.length
            }
            entitiesSelectedCount={entities.length}
            onClick={onClick}
            disabled={status && status.isFetching}
            content="Remove"
            icon={ICONS.entity.actions.remove}
          />
        ),
      }}
    />
  );
};

export default OrderRequestActionRemovePurchaseOrderBulk;
