/**
 * Labstep
 *
 * @module components/Metadata/DragIcon
 * @desc Icon for draggin metadata
 */

import { ICONS } from 'labstep-web/constants/icons';
import Icon from 'labstep-web/core/Icon';
import Popup from 'labstep-web/core/Popup';
import TextWithCount from 'labstep-web/core/Text/WithCount';
import React from 'react';
import { Icon as SemanticIcon } from 'semantic-ui-react';
import { IMetadataDragIcon } from './types';

const MetadataDragIcon: React.FC<IMetadataDragIcon> = ({
  metadata,
}) => (
  <TextWithCount
    header
    text={metadata.displayName}
    icon={ICONS.metadata.secondary}
    dataPrivate
    customIcon={
      !metadata.is_template ? undefined : (
        <Popup
          content="Template Field"
          size="small"
          on="hover"
          trigger={
            <SemanticIcon.Group style={{ marginRight: '5px' }}>
              <Icon name="bookmark" />
              <Icon corner="top right" name="box" />
            </SemanticIcon.Group>
          }
        />
      )
    }
  />
);
export default MetadataDragIcon;
