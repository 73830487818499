/**
 * Labstep
 *
 * @module components/User/Signin/SocialMedia
 * @desc Social Media Buttons for login/register
 */

import { UserRegisterSocialMediaContainer } from 'labstep-web/containers/User/Register/SocialMedia';
import React from 'react';
import GoogleLogin from './Google';
import { ISocialMediaLoginProps, ISocialMediaProps } from './types';

export const SocialMedia: React.FC<ISocialMediaProps> = ({
  loginGoogle,
  mode,
  body,
  options,
}) => (
  <GoogleLogin
    loginGoogle={loginGoogle}
    text={mode === 'signup' ? 'signup_with' : 'signin_with'}
    body={body}
    options={options}
  />
);

const SocialMediaLogin: React.FC<ISocialMediaLoginProps> = (
  props,
) => (
  <UserRegisterSocialMediaContainer>
    {({ loginGoogle }) => (
      <SocialMedia loginGoogle={loginGoogle} {...props} />
    )}
  </UserRegisterSocialMediaContainer>
);

export default SocialMediaLogin;
