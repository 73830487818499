/**
 * Labstep
 *
 * @module models/research-area
 * @desc Typescript export class for ResearchArea
 */

import { Entity } from 'labstep-web/models/entity.model';

export class ResearchArea extends Entity {
  static readonly entityName = 'research_area';

  get entityName(): string {
    return ResearchArea.entityName;
  }

  constructor(data: Partial<ResearchArea> = {}) {
    super();
    Object.assign(this, data);
  }

  id: number;

  label: string;
}
