/**
 * Labstep
 *
 * @module core/Modal/Default/Header
 * @desc Generic Modal Header component with Done button
 */

import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import Button from 'labstep-web/core/Button';
import React from 'react';
import { IModalDefaultHeaderProps } from './types';

export const ModalDefaultHeader: React.FC<
  IModalDefaultHeaderProps
> = ({ icon, text, onClose }) => (
  <Flex
    vAlignContent="center"
    style={{ justifyContent: 'space-between' }}
  >
    <div>
      <Icon name={icon} />
      {text}
    </div>
    <Button primary onClick={onClose}>
      Done
    </Button>
  </Flex>
);

export default ModalDefaultHeader;
