/**
 * Labstep
 *
 * @module components/Tree/Item
 * @desc Item (branch) in Tree
 */

import React from 'react';
import { useToggle } from 'labstep-web/hooks/toggle';
import FilterItem from 'labstep-web/components/Filter/Item';
import ToggleCollapse from 'labstep-web/core/ToggleCollapse';
import styles from './styles.module.scss';
import { ITreeItemProps } from './types';

export const TreeItem: React.FC<ITreeItemProps> = ({
  children,
  style,
  icon,
  text,
  active,
  onClick,
  ...rest
}) => {
  const [isToggled, toggle] = useToggle(active);
  return (
    <div style={style}>
      <div className={styles.row}>
        <div>
          <FilterItem
            icon={icon}
            text={text}
            actionShortcuts={
              children && (
                <ToggleCollapse
                  onClick={(e) => {
                    // prevent dropdown menu from closing on mobile
                    e.preventDefault();
                    e.stopPropagation();
                    toggle();
                  }}
                  isCollapsed={!isToggled}
                />
              )
            }
            active={active}
            onClick={() => {
              onClick();
              toggle();
            }}
            {...rest}
          />
        </div>
      </div>
      <div>{isToggled && children}</div>
    </div>
  );
};

export default TreeItem;
