/**
 * Labstep
 *
 * @module epics/jupyter
 * @desc Redux epic for jupyter
 */

import { APP_VERSION } from 'labstep-web/constants/version';
import { Action } from 'labstep-web/models/action.model';
import { authenticationService } from 'labstep-web/services/authentication.service';
import { configService } from 'labstep-web/services/config.service';
import { HttpClientService } from 'labstep-web/services/http-client.service';
import { windowService } from 'labstep-web/services/window.service';
import qs from 'query-string';
import { StateObservable } from 'redux-observable';
import { Observable, concat, from, of } from 'rxjs';
import {
  catchError,
  distinct,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { LabstepReduxState } from '../types';

/**
 * Call Jupyter OAuth endpoint.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onJupyterAuthorizeRequest = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'jupyterAuthorize/authorize',
    ),
    distinct((action: Action) => action),
    mergeMap((action: Action) => {
      return authenticationService
        .getAuthentication(action, state$)
        .pipe(
          mergeMap((actionOrAuthentication) => {
            if (
              actionOrAuthentication.type.endsWith('REFRESH_TOKEN')
            ) {
              return of(actionOrAuthentication);
            }

            const queryParameters = qs.stringify({
              ...action.meta.params,
            });
            const url = `${configService.jupyterLambdaUrl}/authorize?${queryParameters}`;
            const headers = {
              'labstep-web-app-version': APP_VERSION,
              Authorization: `Bearer ${actionOrAuthentication.meta?.authentication?.jwt}`,
            };

            return from(
              HttpClientService.send('get', url, headers),
            ).pipe(
              map((payload) => {
                return {
                  ...action,
                  type: `JUPYTER_OAUTH_AUTHORIZE_SUCCESS`,
                  payload,
                };
              }),
              catchError((error) =>
                of({
                  ...action,
                  type: `JUPYTER_OAUTH_AUTHORIZE_FAIL`,
                  error,
                }),
              ),
            );
          }),
        );
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_JUPYTER_OAUTH_AUTHORIZE_REQUEST',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Redirect to jupyterhub after successful OAuth authorize.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onJupyterAuthorizeSuccess = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'JUPYTER_OAUTH_AUTHORIZE_SUCCESS',
    ),
    distinct((action: Action) => action),
    tap((action: Action) => {
      if (action.payload && action.payload.redirectUri) {
        windowService.setLocation(action.payload.redirectUri);
      }
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_JUPYTER_OAUTH_AUTHORIZE_SUCCESS',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Call Jupyter GetJupyterInstance endpoint.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onJupyterGetJupyterInstance = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'jupyterInstance/getJupyterInstance',
    ),
    mergeMap((action: Action) => {
      return authenticationService
        .getAuthentication(action, state$)
        .pipe(
          mergeMap((actionOrAuthentication) => {
            if (
              actionOrAuthentication.type.endsWith('REFRESH_TOKEN')
            ) {
              return of(actionOrAuthentication);
            }

            const queryParameters = qs.stringify({
              ...action.meta,
            });
            const url = `${configService.jupyterLambdaUrl}/jupyter-instance?${queryParameters}`;
            const headers = {
              'labstep-web-app-version': APP_VERSION,
              Authorization: `Bearer ${actionOrAuthentication.meta?.authentication?.jwt}`,
            };

            return from(
              HttpClientService.send('get', url, headers),
            ).pipe(
              map((payload) => {
                return {
                  ...action,
                  type: 'GET_JUPYTER_INSTANCE_SUCCESS',
                  payload,
                };
              }),
              catchError((error) =>
                of({
                  ...action,
                  type: 'GET_JUPYTER_INSTANCE_FAIL',
                  error,
                }),
              ),
            );
          }),
        );
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_JUPYTER_GET_JUPYTER_INSTANCE',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Call Jupyter PostJupyterInstance endpoint.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onJupyterPostJupyterInstance = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'jupyterInstance/postJupyterInstance',
    ),
    mergeMap((action: Action) => {
      return authenticationService
        .getAuthentication(action, state$)
        .pipe(
          mergeMap((actionOrAuthentication) => {
            if (
              actionOrAuthentication.type.endsWith('REFRESH_TOKEN')
            ) {
              return of(actionOrAuthentication);
            }

            const url = `${configService.jupyterLambdaUrl}/jupyter-instance`;
            const headers = {
              'labstep-web-app-version': APP_VERSION,
              Authorization: `Bearer ${actionOrAuthentication.meta?.authentication?.jwt}`,
            };

            return from(
              HttpClientService.send(
                'post',
                url,
                headers,
                action.meta,
              ),
            ).pipe(
              map((payload) => {
                return {
                  ...action,
                  type: 'POST_JUPYTER_INSTANCE_SUCCESS',
                  payload,
                };
              }),
              catchError((error) =>
                of({
                  ...action,
                  type: 'POST_JUPYTER_INSTANCE_FAIL',
                  error,
                }),
              ),
            );
          }),
        );
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_JUPYTER_POST_JUPYTER_INSTANCE',
          payload: err,
        }),
        source$,
      ),
    ),
  );
