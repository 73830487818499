/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Print
 * @desc Action to print an experiment workflow
 */

import React from 'react';
import PrintAction from 'labstep-web/components/Entity/Action/Print';
import { IExperimentWorkflowPrintActionProps } from './types';

export const ExperimentWorkflowPrintAction: React.FC<
  IExperimentWorkflowPrintActionProps
> = ({ experimentWorkflow }) => (
  <PrintAction entity={experimentWorkflow} />
);

export default ExperimentWorkflowPrintAction;
