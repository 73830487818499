/**
 * Labstep
 *
 * @module components/Chemical/Form/ShowEdit/InventoryMultipleResources
 * @desc ShowEditInventoryMultipleResources for Chemical
 */

import ResourcePreviewCard from 'labstep-web/components/Resource/Preview/Card';
import ActionComponent from 'labstep-web/core/Action/Component';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { IChemicalFormShowEditInventoryMultipleResourcesProps } from './types';

export const ChemicalFormShowEditInventoryMultipleResources: React.FC<
  IChemicalFormShowEditInventoryMultipleResourcesProps
> = ({ resources, status, update, options }) => {
  const [selectedId, setSelectedId] = useState(resources[0].id);
  return (
    <div className={styles.container}>
      <p>
        Multiple resources were found in your inventory with this
        chemical structure. Please select which you would like to use
        in this experiment.
      </p>
      {resources.map((resource) => (
        <ResourcePreviewCard
          key={resource.id}
          resource={resource}
          cardProps={{
            className:
              resource.id === selectedId ? styles.selected : null,
            onClick: () => setSelectedId(resource.id),
          }}
        />
      ))}
      <ActionComponent
        type="button"
        text="Done"
        onClick={() => update({ resource_id: selectedId }, options)}
        status={status}
        elementProps={{ fluid: true }}
      />
    </div>
  );
};

export default ChemicalFormShowEditInventoryMultipleResources;
