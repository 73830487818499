/**
 * Labstep
 */

// eslint-disable-next-line no-shadow
export enum DefaultSort {
  asc = 'asc',
  desc = 'desc',
}

export interface ISortOption {
  label: string;
  default_sort: DefaultSort.asc | DefaultSort.desc;
}

export interface ISortOptions {
  created_at?: ISortOption;
  name?: ISortOption;
  updated_at?: ISortOption;
  started_at?: ISortOption;
  ended_at?: ISortOption;
  received_at?: ISortOption;
  experiment_created_at?: ISortOption;
  experiment_count?: ISortOption;
  available_resource_item_count?: ISortOption;
  group_created_at?: ISortOption;
  group_name?: ISortOption;
}

export type DefaultSortOptions = {
  [K in keyof ISortOptions]?: K extends keyof ISortOptions
    ? ISortOptions[K]
    : never;
};

export interface ISortOptionsProps {
  sortOptions: ISortOptions;
  trigger?: React.ReactNode;
  /** Show order action */
  showOrderAction?: boolean;
  defaultSortOptions?: DefaultSortOptions;
}
