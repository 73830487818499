import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import { DefaultSort } from 'labstep-web/components/Entity/Table/SortOptions/types';
import { Filter } from 'labstep-web/components/Filter/Menu/types';

export const bulkActions = [
  BulkAction.tag,
  BulkAction.delete,
  BulkAction.transfer_ownership,
  BulkAction.share,
  BulkAction.update_template,
];

export const bulkActionsTemplate = [
  BulkAction.delete,
  BulkAction.transfer_ownership,
  BulkAction.share,
];

export const filters = [Filter.deleted];

export const sortOptions = {
  name: { label: 'Name', default_sort: DefaultSort.asc },
};
