/**
 * Labstep
 *
 * @module models/log-entry
 * @desc Typescript export class for LogEntry
 */

import { Entity } from 'labstep-web/models/entity.model';

export class LogEntry extends Entity {
  static readonly entityName = 'log_entry';

  get entityName(): string {
    return LogEntry.entityName;
  }

  constructor(data: Partial<LogEntry> = {}) {
    super();
    Object.assign(this, data);
  }

  action!: string;

  logged_at!: string;

  client_ip!: string;

  client_user_agent!: string;
}
