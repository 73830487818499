/**
 * Labstep
 *
 * @module core/Text/Small
 * @desc TextSmall (Dates etc)
 */

import React from 'react';
import styles from './styles.module.scss';

const TextSmall: React.FC = ({ children }) => (
  <small className={styles.textSmall}>{children}</small>
);

export default TextSmall;
