/**
 * Labstep
 *
 * @module screens/SignatureRequest/List
 * @desc SignatureRequest List
 */

import SignatureRequestActionDelete from 'labstep-web/components/SignatureRequest/Action/Delete';
import UserPreview from 'labstep-web/components/User/Preview';
import CardGeneric from 'labstep-web/core/Card/Generic';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { SignatureRequest } from 'labstep-web/models/signature-request.model';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import {
  ISignatureRequestListContainerProps,
  ISignatureRequestListProps,
} from './types';

export const SignatureRequestList: React.FC<
  ISignatureRequestListProps
> = ({ signatureRequests }) => {
  const activeSignatureRequests = signatureRequests.filter(
    (signatureRequest) => !signatureRequest.deleted_at,
  );
  return (
    <div id="signature-request-list">
      {activeSignatureRequests.length > 0
        ? activeSignatureRequests.map((activeSignatureRequest) => (
            <CardGeneric
              key={activeSignatureRequest.id}
              headerHeader={
                <div className={styles.preview}>
                  <span>
                    {humanReadableDate(
                      activeSignatureRequest.created_at,
                    )}
                  </span>
                  <div>
                    <UserPreview
                      user={activeSignatureRequest.author}
                    />
                    <span>{` requested signature from `}</span>
                    <UserPreview user={activeSignatureRequest.user} />
                  </div>
                </div>
              }
              actionMenu={
                <SignatureRequestActionDelete
                  signatureRequest={activeSignatureRequest}
                  actionComponentProps={{
                    icon: 'times',
                    type: 'icon',
                  }}
                />
              }
            />
          ))
        : 'No requests pending.'}
    </div>
  );
};

export const SignatureRequestListContainer: React.FC<
  ISignatureRequestListContainerProps
> = ({ experimentWorkflow }) => (
  <ReadOnMountHOC
    type="cursor"
    entityName={SignatureRequest.entityName}
    params={{
      [`${experimentWorkflow.entityName}_id`]: experimentWorkflow.id,
    }}
    children={({ entities: signatureRequests }) => (
      <SignatureRequestList signatureRequests={signatureRequests} />
    )}
  />
);

export default SignatureRequestListContainer;
