/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/Status/Select
 * @desc Select filter for status
 */

import Container from 'labstep-web/components/Layout/Container';
import LayoutContainerActionButtons from 'labstep-web/components/Layout/Container/ActionButtons';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import SearchSelect from 'labstep-web/core/Select/Search';
import { EntityState } from 'labstep-web/models/entity-state.model';
import React from 'react';
import { IExperimentWorkflowFilterStatusSelectContainerProps } from './types';

export const ExperimentWorkflowFilterStatusSelect: React.FC<
  IExperimentWorkflowFilterStatusSelectContainerProps
> = ({ setParams, activeGroup }) => {
  return (
    <Modal
      header="Filter by Status"
      content={({ toggleModal }) => (
        <div>
          <Container>
            <SearchSelect
              isCursor
              entityName={EntityState.entityName}
              params={{
                group_id: activeGroup?.id,
                group_by_name: 1,
              }}
              onChange={(selectedOption) => {
                setParams({
                  entity_state_name: selectedOption
                    ? selectedOption.name
                    : undefined,
                });
                toggleModal();
              }}
              value={null}
            />
          </Container>
          <LayoutContainerActionButtons>
            <ActionComponent
              type="button"
              text="Done"
              onClick={toggleModal}
            />
          </LayoutContainerActionButtons>
        </div>
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon={ICONS.experiment_workflow.info.started}
          text="Status"
          onClick={toggleModal}
        />
      )}
    />
  );
};

export default withActiveGroup(ExperimentWorkflowFilterStatusSelect);
