/**
 * Labstep
 *
 * @module screens/PurchaseOrder/Index
 * @desc PurchaseOrder index
 */

import React, { useMemo } from 'react';
import { PurchaseOrderEmptyState } from 'labstep-web/components/PurchaseOrder/Card/EmptyState';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import PurchaseOrderListOrTable from 'labstep-web/components/PurchaseOrder/ListOrTable';
import PurchaseOrderActionCreate from 'labstep-web/components/PurchaseOrder/Action/Create';
import MasterIndex from 'labstep-web/screens/Master/Index';
import { IScreensPurchaseOrderIndexProps } from './types';
import PurchaseOrderIndexBreadcrumb from './Breadcrumb';
import PurchaseOrderIndexLeftPane from './LeftPane';
import { bulkActions, filters } from './constants';

export const ScreensPurchaseOrderIndex: React.FC<
  IScreensPurchaseOrderIndexProps
> = ({ group, links }) => {
  const breadcrumb = useMemo(
    () => <PurchaseOrderIndexBreadcrumb />,
    [],
  );
  const leftPane = useMemo(
    () => <PurchaseOrderIndexLeftPane links={links} />,
    [links],
  );
  const params = useMemo(
    () => ({
      group_id: group.id,
    }),
    [group.id],
  );
  const noResultsMessage = useMemo(
    () => <PurchaseOrderEmptyState />,
    [],
  );
  const action = useMemo(() => <PurchaseOrderActionCreate />, []);
  return (
    <MasterIndex
      breadcrumb={breadcrumb}
      leftPane={leftPane}
      params={params}
      noResultsMessage={noResultsMessage}
      entityName={PurchaseOrder.entityName}
      action={action}
      bulkActions={bulkActions}
      filters={filters}
    >
      {({ entities, ...rest }): React.ReactElement => (
        <PurchaseOrderListOrTable
          purchaseOrders={entities}
          {...rest}
        />
      )}
    </MasterIndex>
  );
};

export default ScreensPurchaseOrderIndex;
