/**
 * Labstep
 *
 * @module screens/Group/Create
 * @desc Create a group form
 */

import React from 'react';
import { withRouter } from 'react-router';
import { navigation } from 'labstep-web/services/navigation';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import FormCreate from 'labstep-web/core/Form/Create';
import DocumentHead from 'labstep-web/core/DocumentHead';
import Header from 'labstep-web/core/Header';
import LayoutFullScreenPage from 'labstep-web/components/Layout/FullScreenPage';
import { fieldName } from 'labstep-web/components/Group/Form/fields';
import { LimitTypes } from 'labstep-web/constants/limits';
import { useHasExceededLimit } from 'labstep-web/hooks/limits/limits';
import { ShareLinkLimitReached } from 'labstep-web/components/ShareLink/Accept/Entity/GroupOrOrganization/LimitReached';
import { Group } from 'labstep-web/models/group.model';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import styles from './styles.module.scss';

const title = `Create new ${getHumanReadableEntityName('group')}`;

export const Center = ({
  history,
  reload,
  authenticatedUserEntity,
}) => {
  const onSuccess = ({ response }) => {
    history.push(
      navigation.get('group_show', { id: response.result }),
    );
    if (reload) {
      window.location.reload();
    }
  };

  const entityDisplayName = getHumanReadableEntityName(
    Group.entityName,
  );

  const showGroupLimitWarning = useHasExceededLimit(
    authenticatedUserEntity.group_count,
    LimitTypes.MAX_GROUPS_PER_USER,
  );

  if (showGroupLimitWarning) {
    return (
      <ShareLinkLimitReached
        heading={`
      You have reached your ${entityDisplayName} limit`}
        description={`
      You have already joined the maximum number of ${entityDisplayName} that an
      Academic user can join.`}
        onClick={() => {}}
        showUpgrade
      />
    );
  }

  return (
    <div className={styles.container}>
      <Header>{`Creating a new ${getHumanReadableEntityName(
        'group',
        false,
        true,
      )}...`}</Header>
      <div className={styles.formContainer}>
        <FormCreate
          entityName="group"
          parentName="user"
          autoFocus
          fields={[fieldName]}
          options={{ onSuccess }}
        />
      </div>
    </div>
  );
};

export const CreateGroup = (props) => {
  return (
    <>
      <DocumentHead title={title} />
      <LayoutFullScreenPage>
        <Center {...props} />
      </LayoutFullScreenPage>
    </>
  );
};

export default withAuthenticatedUserEntity(withRouter(CreateGroup));
