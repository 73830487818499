/**
 * Labstep
 *
 * @module components/ShareLink/Action/Create
 * @desc Create a ShareLink
 */

import LoadingContent from 'labstep-web/components/Layout/LoadingContent';
import ShareLinkInfo from 'labstep-web/components/ShareLink/Info';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import React from 'react';
import {
  ICreateShareLinkContainerProps,
  ICreateShareLinkProps,
} from './types';

export class CreateShareLink extends React.Component<ICreateShareLinkProps> {
  componentDidMount() {
    const { create, entity, parentEntity } = this.props;

    if (!entity) {
      create({
        [`${parentEntity.entityName}_id`]: parentEntity.id,
      });
    }
  }

  render() {
    const { entity, status, parentEntity, children, ...rest } =
      this.props;
    const shareLink = entity;

    const viewComponent = children ? (
      children({ shareLink })
    ) : (
      <ShareLinkInfo
        shareLink={shareLink}
        parentEntity={parentEntity}
        {...rest}
      />
    );

    if (entity) {
      return viewComponent;
    }

    return (
      <LoadingContent
        defaultFetching
        status={status}
        loader="placeholder"
      >
        {viewComponent}
      </LoadingContent>
    );
  }
}

export const CreateShareLinkContainer: React.FC<
  ICreateShareLinkContainerProps
> = ({ parentEntity, ...rest }) => (
  <EntityCreateContainer
    entityName="share_link"
    uuid={`share_link_${parentEntity.entityName}_${parentEntity.id}`}
    children={(containerProps) => (
      <CreateShareLink
        parentEntity={parentEntity}
        {...containerProps}
        {...rest}
      />
    )}
  />
);

export default CreateShareLinkContainer;
