/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/DeviceData
 * @desc ShowEdit for DeviceData
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import { withToast } from 'labstep-web/containers/Ui/Toast';
import ActionComponent from 'labstep-web/core/Action/Component';
import Loader from 'labstep-web/core/Loader';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { DeviceData } from 'labstep-web/models/device-data.model';
import { Metadata } from 'labstep-web/models/metadata';
import React, { useState } from 'react';
import { IMetadataActionSetLatestDeviceDataProps } from './types';

export const MetadataActionSetLatestDeviceData: React.FC<
  IMetadataActionSetLatestDeviceDataProps
> = ({ metadata, deviceId, options, showToast }) => {
  const [isToggled, setIsToggled] = useState(false);

  if (isToggled) {
    return (
      <EntityUpdateContainer
        entityName={Metadata.entityName}
        id={metadata.id}
      >
        {({ update }): React.ReactElement => (
          <ReadOnMountHOC
            type="cursor"
            loading={false}
            entityName={DeviceData.entityName}
            params={{
              device_id:
                deviceId || metadata.protocol_device?.device.id,
              type: metadata.type,
            }}
            options={{
              onSuccess: ({ response }): void => {
                if (response.items.result.length === 0) {
                  showToast({
                    type: 'error',
                    message: 'No data found from this device',
                  });
                  setIsToggled(false);
                } else {
                  update(
                    {
                      device_data_id: response.items.result[0],
                    },
                    options,
                  );
                }
              },
            }}
          >
            {(): React.ReactElement => <Loader active inline />}
          </ReadOnMountHOC>
        )}
      </EntityUpdateContainer>
    );
  }

  return (
    <ActionComponent
      onClick={(): void => setIsToggled(true)}
      type="text"
      text="Fetch from device"
      icon="download"
      elementProps={{ placeholder: true }}
    />
  );
};

export default withToast(MetadataActionSetLatestDeviceData);
