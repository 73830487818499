/**
 * Labstep
 *
 * @module core/Text/Placeholder
 * @desc TextPlaceholder (Add and Placeholder)
 */

import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import { ITextPlaceholderProps } from './types';

const TextPlaceholder: React.FC<ITextPlaceholderProps> = ({
  children,
  onClick,
}) => (
  <span
    className={classNames(styles.textPlaceholder, {
      [styles.clickable]: !!onClick,
    })}
    onClick={onClick}
  >
    {children}
  </span>
);

export default TextPlaceholder;
