/**
 * Labstep
 *
 * @module components/SignatureRequest/Action/Delete
 * @desc Action to delete
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { ISignatureRequestDeleteActionProps } from './types';

export const SignatureRequestDeleteAction: React.FC<
  ISignatureRequestDeleteActionProps
> = ({ signatureRequest, ...rest }) => (
  <EntityActionDelete {...rest} entity={signatureRequest} />
);

export default SignatureRequestDeleteAction;
