/**
 * Labstep
 *
 * @module components/EntityState/Settings/About
 * @desc About update
 */

import React from 'react';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import Pre from 'labstep-web/core/Pre';
import { fieldDescription } from 'labstep-web/components/EntityState/Form/fields';
import { IEntityStateFormShowEditDescriptionProps } from './types';

export const EntityStateFormShowEditDescription: React.FC<
  IEntityStateFormShowEditDescriptionProps
> = ({ entityState }) => (
  <ShowEditText
    entityName={entityState.entityName}
    entity={entityState}
    field={fieldDescription}
    placeholder="Add description"
  >
    <Pre>{entityState.description}</Pre>
  </ShowEditText>
);

export default EntityStateFormShowEditDescription;
