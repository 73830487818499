/**
 * Labstep
 */

import DeviceActionMenu from 'labstep-web/components/Device/Action/Menu';
import { Action } from 'labstep-web/components/Device/Action/Menu/types';
import EntityLink from 'labstep-web/components/Entity/Link';
import EntityTable from 'labstep-web/components/Entity/Table';
import { Device } from 'labstep-web/models/device.model';
import React from 'react';
import { IDeviceTemplateTableProps } from './types';

const DeviceTemplateTable: React.FC<IDeviceTemplateTableProps> = ({
  deviceTemplates,
  ...rest
}) => {
  return (
    <EntityTable
      id="device-category-table"
      entities={deviceTemplates}
      primaryColumn={{
        header: 'Name',
        content: (device: Device) => <EntityLink entity={device} />,
        cellProps: { style: { flex: 3 } },
      }}
      actionMenu={({ entity }) => (
        <DeviceActionMenu
          device={entity}
          actions={[
            Action.share,
            Action.duplicate,
            Action.transfer_ownership,
            Action.delete,
          ]}
        />
      )}
      {...rest}
    />
  );
};

export default DeviceTemplateTable;
