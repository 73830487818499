/**
 * Labstep
 *
 * @module containers/File/GetURL
 * @desc Return the signed url of a file
 */

import { FileDownloadContainer } from 'labstep-web/containers/File/Download';
import Loader from 'labstep-web/core/Loader';
import React, { Component } from 'react';
import {
  IFileGetURLContainerProps,
  IFileGetURLProps,
  IFileGetURLState,
} from './types';

export class FileGetURL extends Component<
  IFileGetURLProps,
  IFileGetURLState
> {
  constructor(props: IFileGetURLProps) {
    super(props);
    this.loadFile = this.loadFile.bind(this);
    this.state = { url: undefined };
  }

  componentDidMount() {
    this.loadFile();
  }

  loadFile() {
    const { downloadFile, file } = this.props;
    downloadFile({
      id: file.id,
      onSuccess: ({ response }: any) => {
        this.setState({ url: response.signed_url });
      },
    });
  }

  render() {
    const { url } = this.state;
    const { status, children } = this.props;

    return status && !status.isFetching && !status.error && url ? (
      <>{children({ url })}</>
    ) : (
      <Loader active />
    );
  }
}

export const FileGetURLContainer: React.FC<
  IFileGetURLContainerProps
> = ({ children, file }) => (
  <FileDownloadContainer file={file}>
    {(containerProps) => (
      <FileGetURL
        file={file}
        children={children as any}
        {...containerProps}
      />
    )}
  </FileDownloadContainer>
);

export default FileGetURLContainer;
