/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Action/Create
 * @desc Create Action
 */

import React from 'react';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { EntityStateWorkflow } from 'labstep-web/models/entity-state-workflow.model';
import { IEntityStateWorkflowActionCreateProps } from './types';

export const EntityStateWorkflowActionCreate: React.FC<
  IEntityStateWorkflowActionCreateProps
> = ({ group }) => (
  <EntityActionCreate
    entityName={EntityStateWorkflow.entityName}
    body={{
      target_entity_class: 'ExperimentWorkflow',
      group_id: group.guid,
    }}
    redirectRoute={({ response }) => ({
      to: 'entity_state_workflow_show',
      params: { id: response.result },
    })}
    actionComponentProps={{
      type: 'button',
    }}
  />
);

export default EntityStateWorkflowActionCreate;
