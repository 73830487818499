/**
 * Labstep
 *
 * @module components/Filter/Pane
 * @desc Generic Filter Pane
 */

import FilterAssignedToList from 'labstep-web/components/Filter/AssignedTo/List';
import FilterCreatedByList from 'labstep-web/components/Filter/CreatedBy/List';
import FilterTagList from 'labstep-web/components/Filter/Tag/List';
import { ICONS } from 'labstep-web/constants/icons';
import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { IFilterPaneProps } from './types';

const FilterPane: React.FC<IFilterPaneProps> = ({
  filters = ['created_by', 'assigned_to', 'tag'],
  extraFilters,
  entityName,
  filterCreatedByText,
}) => (
  <div id="filter-pane" className={styles.container}>
    <Header size="tiny">
      <Icon name={ICONS.filter.primary} /> Filter Shortcuts
    </Header>
    {extraFilters}
    {filters.includes('created_by') && (
      <FilterCreatedByList
        header={filterCreatedByText}
        entityName={entityName}
      />
    )}
    {filters.includes('assigned_to') && (
      <FilterAssignedToList entityName={entityName} />
    )}
    {filters.includes('tag') && (
      <FilterTagList entityName={entityName} />
    )}
  </div>
);

export default FilterPane;
