/**
 * Labstep
 */

import { arrayMove } from '@dnd-kit/sortable';
import { Column } from 'ag-grid-community';
import { ISortableProps } from 'labstep-web/core/Sortable/types';
import { ItemWithColumn } from './types';

/**
 * Returns a function bound to the onSortEnd prop of dnd-kit sortable
 * @param items ItemWithColumn array
 * @param fn Function to call when sorting is complete
 * @returns Column array
 */
export const onSortEndColumns =
  (
    items: ItemWithColumn[],
    fn: (columns: Column[]) => void,
  ): ISortableProps<ItemWithColumn>['onSortEnd'] =>
  ({ oldIndex, newIndex }) => {
    const newItems = arrayMove(items, oldIndex, newIndex);
    const newColumns = itemsToColumns(newItems);
    fn(newColumns);
  };

/**
 * Creates an array of objects with the column and its id
 * For use with dnd-kit sortable
 * @param columns Column array
 * @returns ItemWithColumn array
 */
export const columnsToItems = (columns: Column[]): ItemWithColumn[] =>
  columns
    .filter((column) => column.getId()) // sanity type check
    .map((column) => ({
      id: column.getId(),
      column,
    }));

/**
 * Creates an array of columns from an array of objects with the column and its id
 * @param items ItemWithColumn array
 * @returns Column array
 */
export const itemsToColumns = (items: ItemWithColumn[]): Column[] => {
  return items.map((item) => item.column);
};
