/**
 * Labstep
 */

import { NAME_MAPPPINGS } from 'labstep-web/components/PubChemMetadata/Form/fields';
import { Molecule } from 'labstep-web/models/molecule.model';
import { colDefPubchemFieldType } from '../DataGrid/colDefs/pubchem/types';

export const getPubchemBody = (
  entity: Molecule,
  field: colDefPubchemFieldType,
  value: string | number,
): Molecule['pubchem'] => {
  return {
    products: entity.pubchem?.products || [],
    reactants: [
      {
        ...(entity.properties || Molecule.metadataDefault),
        [NAME_MAPPPINGS[field.name]]: value,
      },
    ],
  };
};

export const getPubchemFieldValue = (
  entity: Molecule,
  field: colDefPubchemFieldType,
): string | number | null =>
  entity.properties?.[NAME_MAPPPINGS[field.name]] || null;
