/**
 * Labstep
 *
 * @desc Message show edit for notification alert
 */

import React from 'react';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import Pre from 'labstep-web/core/Pre';
import { fieldNotificationAlertMessage } from 'labstep-web/components/NotificationAlert/Form/fields';
import { INotificationAlertFormShowEditMessageProps } from './types';

export const NotificationAlertFormShowEditMessage: React.FC<
  INotificationAlertFormShowEditMessageProps
> = ({ notificationAlert }) => (
  <ShowEditText
    entityName={notificationAlert.entityName}
    entity={notificationAlert}
    field={fieldNotificationAlertMessage}
  >
    <Pre>{notificationAlert.message}</Pre>
  </ShowEditText>
);

export default NotificationAlertFormShowEditMessage;
