/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Main
 * @desc All available menus in ProseMirror
 * Each menu will handle its position and visibility based on its own business logic
 */

import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import MenuCode from 'labstep-web/prosemirror/components/Menu/Code';
import MenuCommands from 'labstep-web/prosemirror/components/Menu/Commands';
import MenuComment from 'labstep-web/prosemirror/components/Menu/Comment';
import MenuFile from 'labstep-web/prosemirror/components/Menu/File';
import MenuLink from 'labstep-web/prosemirror/components/Menu/Link';
import MenuReferencing from 'labstep-web/prosemirror/components/Menu/Referencing';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { IMenuMainProps } from './types';

export const MenuMain: React.FC<IMenuMainProps> = ({
  entity,
  experimentWorkflow,
  protocolCollection,
  view,
  state,
  dispatch,
  cursorPosition,
  editable,
}) => {
  const menuReferencingProps = {
    experimentWorkflow: experimentWorkflow as ExperimentWorkflow,
    state,
    dispatch,
    cursorPosition,
    entity,
  };
  return (
    <>
      <MenuFile
        state={state}
        dispatch={dispatch}
        view={view}
        cursorPosition={cursorPosition}
        editable={editable}
      />
      <MenuComment
        state={state}
        dispatch={dispatch}
        view={view}
        cursorPosition={cursorPosition}
      />
      {editable && (
        <>
          <MenuCode
            state={state}
            dispatch={dispatch}
            view={view}
            cursorPosition={cursorPosition}
          />
          <MenuLink
            state={state}
            dispatch={dispatch}
            view={view}
            cursorPosition={cursorPosition}
          />
          <MenuReferencing {...menuReferencingProps} />
          {!isMobile && (
            <MenuCommands
              state={state}
              dispatch={dispatch}
              view={view}
              entity={entity}
              experimentWorkflow={experimentWorkflow}
              protocolCollection={protocolCollection}
              cursorPosition={cursorPosition}
            />
          )}
        </>
      )}
    </>
  );
};

export default MenuMain;
