/**
 * Labstep
 *
 * @module components/Filter/AssignedTo
 * @desc Filter for assigned to
 */

import React from 'react';
import FilterUserList from 'labstep-web/components/Filter/User/List';
import { STRINGS } from 'labstep-web/strings';
import { IFilterAssignedToListProps } from './types';

export const FilterAssignedToList: React.FC<
  IFilterAssignedToListProps
> = ({ entityName, usePostFilter }) => (
  <FilterUserList
    header={STRINGS.filter.header.assigned_to}
    name="assignee"
    field="assignee_id"
    entityName={entityName}
    usePostFilter={usePostFilter}
  />
);

export default FilterAssignedToList;
