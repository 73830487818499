/**
 * Labstep
 *
 * @module services/html-element
 * @desc HTML element operations
 *
 */

/**
 * Set scroll top of parent element if child element overflows.
 *
 * @function
 * @param  {object} parentEl - Parent element
 * @param  {object} childEl - Child element
 * @param  {number} marginTop - Margin top
 */
export const getScrollTop = (
  parentEl: HTMLElement,
  childEl: HTMLElement,
  marginTop = 0,
): number | null => {
  const { scrollTop, offsetHeight: parentHeight } = parentEl;
  const { offsetTop, offsetHeight: childHeight } = childEl;
  const offsetBottom = offsetTop + childHeight;
  const scrollBottom = scrollTop + parentHeight;

  // child element top above parent top
  if (offsetTop - marginTop < scrollTop) {
    return offsetTop - marginTop;
  }

  // child element bottom below parent bottom
  if (offsetBottom > scrollBottom) {
    return offsetBottom - parentHeight;
  }

  return null;
};

export const getScrollParent = (
  node: HTMLElement | null,
): HTMLElement | null => {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  }
  return getScrollParent(node.parentElement);
};
