/**
 * Labstep
 */

import { IReadOnMountProps, InequalFuncType } from './types';

/** TODO: Check if we can just refactor to true on any params change */
export const paramsToCheck = ['id', 'guid', 'not_group_id'];

/** Check if read should update */
export const inequalFunc: InequalFuncType = (
  prevProps: IReadOnMountProps,
  props: IReadOnMountProps,
) => {
  const idChange = props.id && prevProps.id !== props.id;
  let paramsChange = false;
  if (props.params) {
    paramsChange = paramsToCheck.some(
      (p) =>
        props.params?.[p] &&
        prevProps.params?.[p] !== props.params[p],
    );
  }
  return idChange || paramsChange;
};
