/**
 * Labstep
 *
 * @module components/Group/Form/ShowEdit/DefaultExperimentWorkflowTemplate
 * @desc Set the default_experiment_workflow_template for this group
 */

import React from 'react';
import FormShowEditSearchSelect from 'labstep-web/core/Form/ShowEdit/SearchSelect';
import EntityLink from 'labstep-web/components/Entity/Link';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { is } from 'date-fns/locale';
import { IGroupFormShowEditDefaultExperimentWorkflowTemplateProps } from './types';

export const GroupFormShowEditDefaultExperimentWorkflowTemplate: React.FC<
  IGroupFormShowEditDefaultExperimentWorkflowTemplateProps
> = ({ group }) => (
  <FormShowEditSearchSelect
    placeholder={`Specify ${ExperimentWorkflow.getHumanReadableEntityName(
      false,
      false,
      true,
    )}`}
    childEntityName={ExperimentWorkflow.entityName}
    parentEntity={group}
    searchSelectProps={{
      params: { group_id: group.id, is_template: true },
      creatable: true,
      createBody: { group_id: group.id, is_template: true },
    }}
    childKeyName="default_experiment_workflow_template"
  >
    {group.default_experiment_workflow_template && (
      <div>
        <EntityLink
          entity={group.default_experiment_workflow_template}
        />
      </div>
    )}
  </FormShowEditSearchSelect>
);

export default GroupFormShowEditDefaultExperimentWorkflowTemplate;
