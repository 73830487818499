/**
 * Labstep
 */

import { ICONS } from 'labstep-web/constants/icons';
import { IActionComponentProps } from 'labstep-web/core/Action/Component/types';

export const updateAmountDeductedAtActionComponentProps = {
  type: 'button',
  showIcon: true,
  icon: ICONS.protocol_value.info.amount_used,
  text: 'Deduct Amount Used',
  elementProps: {
    basic: true,
    fluid: true,
  },
} as IActionComponentProps;
