/**
 * Labstep
 */

export const RESET_POSITION = 'RESET_POSITION';

export const resetPositionActionCreator = (entityName, entities) => ({
  type: RESET_POSITION,
  entityName,
  entities,
});
