/**
 * Labstep
 *
 * @module screens/ResourceLocation/Show
 * @desc ResourceLocation Show Screen
 */

import DocumentHead from 'labstep-web/core/DocumentHead';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import MasterShow from 'labstep-web/screens/Master/Show';
import { isGuid } from 'labstep-web/services/utils.service';
import React from 'react';
import Center from './Center';
import Right from './Right';
import {
  IResourceLocationShowScreenContainerProps,
  IResourceLocationShowScreenProps,
} from './types';

export const ResourceLocationShowScreen: React.FC<
  IResourceLocationShowScreenProps
> = ({ resourceLocation }) => (
  <>
    <DocumentHead title={resourceLocation.name} />
    <MasterShow
      entity={resourceLocation}
      center={<Center resourceLocation={resourceLocation} />}
      right={<Right resourceLocation={resourceLocation} />}
    />
  </>
);

const ResourceLocationShowScreenContainer: React.FC<
  IResourceLocationShowScreenContainerProps
> = ({ match }) => {
  // Detect if path includes id or guid
  // TODO: Remove this once we have migrated the resource location map_data
  const params = isGuid(match.params.id)
    ? { guid: match.params.id }
    : { id: Number(match.params.id) };
  return (
    <ReadOnMountHOC
      type="entities"
      entityName={ResourceLocation.entityName}
      params={{
        get_single: 1,
        is_deleted: 'both',
        ...params,
      }}
      loading={{ loader: 'show' }}
      children={({ entity }) => (
        <ResourceLocationShowScreen resourceLocation={entity} />
      )}
    />
  );
};

export default ResourceLocationShowScreenContainer;
