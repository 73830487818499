/**
 * Labstep
 *
 * @module screens/OrderRequest/Show
 * @desc OrderRequest Show Screen
 */

import DocumentHead from 'labstep-web/core/DocumentHead';
import Redirect from 'labstep-web/core/Redirect';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import MasterShow from 'labstep-web/screens/Master/Show';
import React from 'react';
import Center from './Center';
import Right from './Right';
import {
  IOrderRequestShowScreenContainerProps,
  IOrderRequestShowScreenProps,
} from './types';

export const OrderRequestShowScreen: React.FC<
  IOrderRequestShowScreenProps
> = ({ orderRequest }) => {
  if (orderRequest.is_template) {
    return <Redirect to="app_homepage" />;
  }
  return (
    <>
      <DocumentHead title={orderRequest.name} />
      <MasterShow
        entity={orderRequest}
        center={<Center orderRequest={orderRequest} />}
        right={<Right orderRequest={orderRequest} />}
      />
    </>
  );
};

const OrderRequestShowScreenContainer: React.FC<
  IOrderRequestShowScreenContainerProps
> = ({ match }: any) => (
  <ReadOnMountHOC
    type="entities"
    entityName={OrderRequest.entityName}
    params={{
      get_single: 1,
      is_deleted: 'both',
      id: match.params.id,
    }}
    loading={{ loader: 'show', cached: true }}
    children={({ entity }) => (
      <OrderRequestShowScreen orderRequest={entity} />
    )}
  />
);

export default OrderRequestShowScreenContainer;
