/**
 * Labstep
 */

import { IWithAuthenticatedUserEntityInjectedProps } from 'labstep-web/containers/AuthenticatedUser/types';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';

export interface ISignatureSignAndRejectButtons
  extends IWithAuthenticatedUserEntityInjectedProps {
  experimentWorkflow: ExperimentWorkflow;
  entityUsers: EntityUser[];
}
export interface ISignatureSignAndRejectButtonsContainerProps
  extends Omit<ISignatureSignAndRejectButtons, 'entityUsers'> {}

// eslint-disable-next-line no-shadow
export enum SignatureTypeAction {
  reject = 'Reject',
  sign = 'Sign',
}
