import { Action } from 'redux';

type ISetSidebarActionType = {
  type: 'SIDEBAR_SET';
  key: string;
  visible: boolean;
};

export type ISetSidebarActionCreatorType = (
  visible: boolean,
  key: string,
) => ISetSidebarActionType;

export const setSidebarActionCreator: ISetSidebarActionCreatorType = (
  visible,
  key,
) => ({
  type: 'SIDEBAR_SET',
  key,
  visible,
});

export type ISidebarState = {
  [key: string]: boolean;
};

export const sidebar = (
  // eslint-disable-next-line default-param-last
  state = {},
  action: Action | ISetSidebarActionType,
): ISidebarState => {
  if (action.type === 'SIDEBAR_SET') {
    return {
      ...state,
      [(action as ISetSidebarActionType).key]: (
        action as ISetSidebarActionType
      ).visible,
    };
  }
  return state;
};

export const sidebarReducer = sidebar;
