/**
 * Labstep
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import MoleculeActionEdit from 'labstep-web/components/Molecule/Action/Edit';
import MoleculeStructure from 'labstep-web/components/Molecule/Structure';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import {
  getEditable,
  getEntityDefault,
  setValue,
  stopEditingAndFocus,
} from 'labstep-web/core/DataGrid/utils';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Metadata } from 'labstep-web/models/metadata';
import { Molecule } from 'labstep-web/models/molecule.model';
import React from 'react';
import { colDefMoleculeType } from '../types';

/**
 * Column definition for molecule structure (svg)
 * @param options Options
 * @returns Column definition
 */
const colDefStructure: colDefMoleculeType = (options = {}) => {
  const { getNestedEntity, columnProps, getCreateProps } = options;
  const editable = getEditable(getNestedEntity);
  const getEntity = getNestedEntity || getEntityDefault;

  return {
    colId: 'structure',
    cellEditor: (params) => {
      const entity = getEntity(params);
      if (!(entity instanceof Molecule)) {
        return null;
      }
      return (
        <ReadOnMountHOC
          type="entities"
          entityName={entity.entityName}
          params={{
            get_single: 1,
            guid: entity.guid,
          }}
          loading={{ loader: false }}
          children={({ entity: molecule }) => (
            <MoleculeActionEdit
              molecule={molecule}
              canEdit
              viewComponent={null}
              open
              onDone={(): void => stopEditingAndFocus(params)}
            />
          )}
        />
      );
    },
    cellEditorPopup: true,
    cellRenderer: (params) => {
      if (!params.data) {
        return '';
      }
      const entity = getEntity(params);
      const createProps = getCreateProps?.(params);
      if (createProps) {
        return (
          <EntityActionCreate
            parentName={createProps.parentName}
            parentId={createProps.parentId}
            entityName={Metadata.entityName}
            actionComponentProps={{
              type: 'text',
              text: 'Draw structure',
              icon: null,
              elementProps: { placeholder: true, gridCell: true },
            }}
            body={createProps.createBody}
            options={{
              onSuccess: () =>
                params.api.startEditingCell({
                  rowIndex: params.rowIndex,
                  colKey: params.column,
                }),
            }}
          />
        );
      }
      if (!(entity instanceof Molecule)) {
        return null;
      }
      return (
        <MoleculeStructure
          molecule={entity}
          fallback={
            <DataGridPlaceholder
              params={params}
              children="Draw structure"
              editable={editable}
            />
          }
        />
      );
    },
    headerName: 'Structure',
    editable,
    valueGetter: (params): string | null => {
      const entity = getEntity(params);
      if (entity instanceof Molecule) {
        return entity.svg;
      }
      return null;
    },
    valueSetter: (params): boolean => {
      const entity = getEntity(params);
      return setValue('svg', params.newValue, {
        entityName: entity.entityName,
        id: entity.idAttr,
      });
    },
    ...columnProps,
  };
};

export default colDefStructure;
