/**
 * Labstep
 *
 * @module components/User/Settings/Email
 * @desc Email
 */

import React from 'react';
import { IUserSettingsEmailProps } from './types';

export const UserSettingsEmail: React.FC<IUserSettingsEmailProps> = ({
  user,
}) => (
  <div>
    <div>
      <span>Email</span>
    </div>
    <p>{user.username}</p>
  </div>
);

export default UserSettingsEmail;
