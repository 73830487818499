/**
 * Labstep
 *
 * @module components/OrderRequest/Action/UpdateUrgent/Bulk
 * @desc Action to update is_urgent field
 */

import React from 'react';
import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { IOrderRequestActionUpdateUrgentProps } from './types';

const OrderRequestActionUpdateUrgent: React.FC<
  IOrderRequestActionUpdateUrgentProps
> = ({ entities, options }) => {
  const entityIdsAccessObject = useHasAccessMultiple(
    OrderRequest.entityName,
    entities ? entities.map((e) => e.id) : [],
    Action.edit,
  );

  const entitiesWithPermission = entities
    ? entities.filter((e) => entityIdsAccessObject[e.id])
    : [];

  if (
    !entities ||
    entities.length === 0 ||
    entitiesWithPermission.length === 0
  ) {
    return null;
  }

  return (
    <EntityActionUpdate
      entityName={OrderRequest.entityName}
      id={entitiesWithPermission.map(
        (orderRequest) => orderRequest.id,
      )}
      body={{ is_urgent: true }}
      options={options}
      actionComponentProps={{
        type: 'children',
        children: ({ onClick, status }) => (
          <BulkActionComponent
            disabled={status && status.isFetching}
            entitiesSelectedCount={entities.length}
            entitiesWithPermissionCount={
              entitiesWithPermission.length
            }
            onClick={onClick}
            content="Mark as urgent"
            icon="exclamation"
          />
        ),
      }}
    />
  );
};

export default OrderRequestActionUpdateUrgent;
