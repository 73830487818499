/**
 * Labstep
 *
 * @module components/ResourceItem/Form/Location
 * @desc ShowEdit Form for location field
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import Dropdown from 'labstep-web/core/Dropdown';
import Icon from 'labstep-web/core/Icon';
import {
  ResourceItem,
  ResourceItemStatusValues,
} from 'labstep-web/models/resource-item.model';
import React from 'react';
import { IResourceItemActionUpdateStatusProps } from './types';

const ResourceItemActionUpdateStatus: React.FC<
  IResourceItemActionUpdateStatusProps
> = ({ trigger, options = {}, disabled, ...rest }) => (
  <EntityUpdateContainer
    entityName={ResourceItem.entityName}
    id={
      'resourceItemIds' in rest
        ? rest.resourceItemIds
        : rest.resourceItem.id
    }
  >
    {({ update }) => (
      <Dropdown.Dropdown
        disabled={disabled}
        trigger={trigger}
        icon={null}
      >
        <Dropdown.Menu>
          {(
            Object.keys(
              ResourceItemStatusValues,
            ) as (keyof typeof ResourceItemStatusValues)[]
          ).map((key) => (
            <Dropdown.Item
              key={key}
              onClick={() => {
                update({ status: key }, options);
              }}
            >
              {ResourceItemStatusValues[key]}{' '}
              {'resourceItem' in rest &&
                rest.resourceItem.status === key && (
                  <Icon name="check" />
                )}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown.Dropdown>
    )}
  </EntityUpdateContainer>
);

export default ResourceItemActionUpdateStatus;
