/**
 * Labstep
 *
 * @module components/Entity/Action/Create/Menu
 * @desc Create Action Menu for entity
 */

import React from 'react';
import EntityActionCreateTemplatable from 'labstep-web/components/Entity/Action/Create/Templatable';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import ButtonWithDropdown from 'labstep-web/core/Button/WithDropdown';
import { CanCreate } from 'labstep-web/components/Entity/Can';
import { IEntityActionCreateMenuProps } from './types';

export const EntityActionCreateMenu: React.FC<
  IEntityActionCreateMenuProps
> = ({ templateId, extraOptions, entityName, ...props }) => {
  const entityActionCreate = templateId ? (
    <EntityActionCreate
      body={{ template_id: templateId }}
      entityName={entityName}
      redirectRoute={({ response }) => ({
        to: `${entityName}_show`,
        params: { id: response.result },
      })}
      actionComponentProps={{
        type: 'button',
      }}
      {...props}
    />
  ) : (
    <EntityActionCreateTemplatable
      entityName={entityName}
      templateId={templateId}
    />
  );

  return extraOptions ? (
    <CanCreate entityName={entityName}>
      <ButtonWithDropdown
        primaryAction={entityActionCreate}
        extraOptions={extraOptions}
      />
    </CanCreate>
  ) : (
    entityActionCreate
  );
};

export default EntityActionCreateMenu;
