/**
 * Labstep
 *
 * @module core/DataGrid/Overlay/Loading
 * @desc DataGrid Loading Overlay component
 */

import Loader from 'labstep-web/core/Loader';
import React from 'react';

export const DataGridOverlayLoading: React.FC = () => (
  <Loader size="small" inline active />
);

export default DataGridOverlayLoading;
