/**
 * Labstep
 *
 * @module screens/File/Index/LeftPane
 * @desc File Index LeftPane
 */

import FileFilterPane from 'labstep-web/components/File/Filter/Pane';
import LayoutLeftPane from 'labstep-web/components/Layout/LeftPane';
import React from 'react';

export const FileIndexLeftPane: React.FC = () => (
  <LayoutLeftPane>
    <FileFilterPane />
  </LayoutLeftPane>
);

export default FileIndexLeftPane;
