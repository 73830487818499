/**
 * Labstep
 */

import EntityFormShowEditName from 'labstep-web/components/Entity/Form/ShowEdit/Name';
import EntityStateActionUpdateColor from 'labstep-web/components/EntityState/Action/UpdateColor';
import EntityStateFormShowEditDescription from 'labstep-web/components/EntityState/Form/ShowEdit/Description';
import EntityStateFormShowEditTargetAllowedActionsLock from 'labstep-web/components/EntityState/Form/ShowEdit/TargetAllowedActionsLock';
import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import { EntityState } from 'labstep-web/models/entity-state.model';
import React from 'react';
import { IEntityStateWorkflowModalGeneralSettingsProps } from './types';

const EntityStateWorkflowModalGeneralSettings: React.FC<
  IEntityStateWorkflowModalGeneralSettingsProps
> = ({ entityState }) => (
  <div data-testid="entity-state-workflow-modal-general">
    <h3>General Settings</h3>
    <HorizontalTable
      headerProps={{ width: 6 }}
      columns={[entityState]}
      rows={[
        {
          header: 'Name',
          content: (state: EntityState) => (
            <EntityFormShowEditName entity={state} />
          ),
        },
        {
          header: 'Color',
          content: (state: EntityState) => (
            <EntityStateActionUpdateColor entityState={state} />
          ),
        },
        {
          header: 'Description',
          content: (state: EntityState) => (
            <EntityStateFormShowEditDescription entityState={state} />
          ),
        },
        {
          header: 'Lock Mode',
          content: (state: EntityState) => (
            <EntityStateFormShowEditTargetAllowedActionsLock
              entityState={state}
            />
          ),
        },
      ]}
    />
  </div>
);

export default EntityStateWorkflowModalGeneralSettings;
