/**
 * Labstep
 *
 * @module components/EntityImport/Modal
 * @desc Modal for EntityImport
 */

import React, { useEffect } from 'react';
import { ModalDefault } from 'labstep-web/core/Modal/Default';
import Header from 'labstep-web/core/Header';
import Flex from 'labstep-web/core/Flex';
import { Action } from 'labstep-web/components/EntityImport/Action/Menu/types';
import { useModalRoute } from 'labstep-web/hooks/modalRoute';
import { useLocation } from 'react-router';
import { EntityImportTargetEntityClass } from 'labstep-web/models/entity-import.model';
import EntityImportEmptyState from '../EmptyState';
import EntityImportTableContainer from '../Table/Container';

export const ENTITY_IMPORT_ROUTE_ID = 'entity-import';

export const EntityImportModal: React.FC = () => {
  const [entityName, setEntityName] =
    React.useState<EntityImportTargetEntityClass>();
  const [templateId, setTemplateId] = React.useState<number>();
  const { open, toggleModal } = useModalRoute(ENTITY_IMPORT_ROUTE_ID);
  const { state: locationState } = useLocation<Record<
    string,
    unknown
  > | null>();
  useEffect(() => {
    if (locationState?.entityName) {
      setEntityName(
        locationState.entityName as EntityImportTargetEntityClass,
      );
      setTemplateId(locationState.templateId as number);
    }
  }, [locationState?.entityName, locationState?.templateId]);
  if (!entityName) {
    return null;
  }
  return (
    <ModalDefault
      size="fullscreen"
      header="Import"
      modalHocProps={{ open, toggleModal }}
      content={
        <Flex column width="100%">
          <EntityImportEmptyState
            entityName={entityName}
            templateId={templateId}
          />
          <Header size="tiny">Previous Imports</Header>
          <EntityImportTableContainer
            targetEntityName={entityName}
            actions={[Action.revert]}
          />
        </Flex>
      }
    />
  );
};

export default EntityImportModal;
