/**
 * Labstep
 *
 * @module core/Form/ReactForm/TextArea
 * @desc Editable textarea as a form field
 */

import React from 'react';
import CoreTextArea from 'labstep-web/core/TextArea';
import CustomFieldWrapper from '../CustomFieldWrapper';

export const TextArea = (props: any) => (
  <CustomFieldWrapper
    fieldElement={CoreTextArea}
    defaultValue=""
    {...props}
  />
);

export default TextArea;
