/**
 * Labstep
 */

import React from 'react';
import Loader from 'labstep-web/core/Loader';
import Flex from 'labstep-web/core/Flex';
import EntityUserActionManageList from 'labstep-web/components/EntityUser/Action/Manage/List';
import SortOptions from './SortOptions';

export const getLastColumnHeader = ({ isLoading, sortOptions }) => (
  <Flex hAlignContent="right" marginRight="10px">
    {isLoading ? (
      <Loader size="tiny" inline active={isLoading} />
    ) : (
      sortOptions && <SortOptions sortOptions={sortOptions} />
    )}
  </Flex>
);

export const getLastColumn = ({ showCollaborators, actionMenu }) => ({
  header: null,
  content: (entity) => (
    <Flex
      hAlignContent="right"
      vAlignContent="center"
      style={{ justifyContent: 'flex-end', height: '100%' }}
    >
      {showCollaborators && (
        <EntityUserActionManageList entity={entity} />
      )}
      <>{actionMenu && actionMenu({ entity })}</>
    </Flex>
  ),
  cellProps: {
    style: { flex: `0 1 ${showCollaborators ? '175px' : '20px'}` },
  },
});
