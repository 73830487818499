/**
 * Labstep
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { SignatureRequirement } from 'labstep-web/models/signature-requirement.model';
import React from 'react';
import { IEntityUserRoleRequirementActionSignatureRequirementProps } from './types';

const EntityUserRoleRequirementActionSignatureRequirement: React.FC<
  IEntityUserRoleRequirementActionSignatureRequirementProps
> = ({ entityUserRoleRequirement }) => {
  const signatureRequirement =
    entityUserRoleRequirement._signature_requirement;

  if (!signatureRequirement) {
    return (
      <EntityActionCreate
        entityName={SignatureRequirement.entityName}
        body={{
          entity_user_role_requirement_id:
            entityUserRoleRequirement.id,
        }}
        actionComponentProps={{
          type: 'checkbox',
          elementProps: {
            label: 'Signature Required',
          },
        }}
      />
    );
  }

  return (
    <EntityActionDelete
      entity={signatureRequirement}
      skipConfirmation
      actionComponentProps={{
        type: 'checkbox',
        elementProps: {
          checked: signatureRequirement.deleted_at === null,
          label: 'Signature Required',
        },
      }}
    />
  );
};

export default EntityUserRoleRequirementActionSignatureRequirement;
