/**
 * Labstep
 *
 * @module screens/Tag/Index
 * @desc Tag manager page
 */

import TagActionCreate from 'labstep-web/components/Tag/Action/Create';
import TagEmptyState from 'labstep-web/components/Tag/Card/EmptyState';
import TagTable from 'labstep-web/components/Tag/Table';
import { Tag } from 'labstep-web/models/tag.model';
import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import React, { useMemo } from 'react';
import EntitySearch from 'labstep-web/components/Entity/Search';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import { ITagIndexProps } from './types';

const TagIndex: React.FC<ITagIndexProps> = ({ group, type }) => {
  const params = useMemo(
    () => ({ group_id: group.id, type }),
    [group.id],
  );
  const action = useMemo(
    () => <TagActionCreate defaultType={type} />,
    [],
  );
  const noResultsMessage = useMemo(
    () => <TagEmptyState type={type} />,
    [],
  );
  return (
    <EntitySearch
      entityName={Tag.entityName}
      params={params}
      tableFormat
      action={action}
      filters={[Filter.deleted, Filter.created_by]}
      noResultsMessage={noResultsMessage}
      bulkActions={[BulkAction.delete]}
    >
      {({ entities, ...rest }): React.ReactElement => (
        <TagTable tags={entities} {...rest} />
      )}
    </EntitySearch>
  );
};

export default TagIndex;
