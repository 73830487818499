/**
 * Labstep
 *
 * @module prosemirror/marks/color
 * @desc Text color mark
 */

import { LabstepMark } from 'labstep-web/prosemirror/marks/types';
import { toCSSColor } from 'labstep-web/prosemirror/marks/utils';

const MarkColor: LabstepMark = {
  key: 'color',
  spec: {
    attrs: {
      color: { default: null },
    },
    inline: true,
    group: 'inline',
    parseDOM: [
      {
        style: 'color',
        getAttrs: (color) => {
          if (color instanceof Node) {
            return false;
          }
          if (
            toCSSColor(color) === '#000000' ||
            toCSSColor(color) === ''
          ) {
            return false;
          }
          return {
            color: toCSSColor(color),
          };
        },
      },
    ],
    toDOM(node) {
      const { color } = node.attrs;
      let style = '';
      if (color) {
        style += `color: ${color};`;
      }
      return ['span', { style }, 0];
    },
  },
};

export default MarkColor;
