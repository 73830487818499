/**
 * Labstep
 *
 * @desc Service to normalize and type entities
 */

import camelCase from 'lodash/camelCase';
import { denormalize, normalize } from 'labstep-web/state/normalizr';
import schema from 'labstep-web/services/schema';
import { getPluralInSchema } from 'labstep-web/services/schema/helpers';

/**
 * @function
 * @desc Normalize entities with normalizr and then type them with class-transformer
 * @param any    payload   Array of entities or entity
 * @param string className Expected payload class name
 */
export const normalizeEntities = (
  payload: any,
  className: string,
): any =>
  normalize(
    payload,
    schema[camelCase(className) as keyof typeof schema],
  );

/**
 * @function
 * @desc Denormalize an entity with normalizr and then type it with class-transformer
 * @param any    input     Entity
 * @param string className Entity class name
 * @param array  entities  List of entities to denormalize from
 */
export const denormalizeEntity = (
  input: any,
  className: string,
  entities: any,
): any => {
  const denormalizedEntity: any = denormalize(
    input,
    schema[camelCase(className) as keyof typeof schema],
    entities,
  );
  return denormalizedEntity;
};

/**
 * @function
 * @desc Denormalize entities with normalizr and then type them with class-transformer
 * @param any    input     Array of entities
 * @param string className Entities class name
 * @param array  entities  List of entities to denormalize from
 */
export const denormalizeEntities = (
  input: any,
  className: string,
  entities: any,
): any => {
  const denormalizedEntities: any[] = denormalize(
    input,
    schema[
      camelCase(getPluralInSchema(className)) as keyof typeof schema
    ],
    entities,
  );
  return denormalizedEntities;
};
