/**
 * Labstep
 *
 * @module components/Filter/Entity
 * @desc A generic filter component for searching entities and filtering by them
 */

import LoadingContent from 'labstep-web/components/Layout/LoadingContent';
import { EntityReadEntitiesContainer } from 'labstep-web/containers/Entity/Read/Entities';
import React from 'react';
import {
  IFilterEntityContainerProps,
  IFilterEntityProps,
  IFilterEntityState,
} from './types';

export class FilterEntity extends React.Component<
  IFilterEntityProps,
  IFilterEntityState
> {
  constructor(props: IFilterEntityProps) {
    super(props);
    this.state = {
      shouldRead: Boolean(props.searchParams[props.searchParamKey]),
    };
  }

  componentDidMount() {
    const { read, entity } = this.props;
    if (this.state.shouldRead && !entity) {
      read({ onSuccess: () => this.setState({ shouldRead: false }) });
    }
  }

  render() {
    const { status, children } = this.props;

    if (!this.state.shouldRead) {
      return children();
    }

    return (
      <LoadingContent
        defaultFetching={this.state.shouldRead}
        status={status}
        loader={false}
      >
        {children()}
      </LoadingContent>
    );
  }
}

export const FilterEntityContainer: React.FC<
  IFilterEntityContainerProps
> = ({ getSingle, extraParams = {}, paramKey = 'id', ...props }) => {
  const params: any = {
    [paramKey]: props.searchParams[props.searchParamKey],
    ...extraParams,
  };
  if (getSingle) {
    params.get_single = 1;
  }
  return (
    <EntityReadEntitiesContainer
      entityName={props.entityName}
      params={params}
    >
      {({ read, entity, status }) => (
        <FilterEntity
          entity={entity}
          read={read}
          status={status}
          {...props}
        />
      )}
    </EntityReadEntitiesContainer>
  );
};

FilterEntityContainer.defaultProps = {
  paramKey: 'id',
  getSingle: true,
};

export default FilterEntityContainer;
