/**
 * Labstep
 *
 * @module components/ProtocolTimer/Card
 * @desc ProtocolTimer Card
 */

import ProtocolTimerActionMenu from 'labstep-web/components/ProtocolTimer/Action/Menu';
import { ICONS } from 'labstep-web/constants/icons';
import { ProtocolTimerItemContainer } from 'labstep-web/containers/ProtocolTimer/Item';
import ElementCard from 'labstep-web/core/Card/Element';
import Time from 'labstep-web/core/Time';
import React from 'react';
import { IProtocolTimerCardProps } from './types';

const ProtocolTimerCard: React.FC<IProtocolTimerCardProps> = ({
  protocolTimer,
  ...rest
}) => (
  <ElementCard
    icon={ICONS.protocol.timer}
    name={protocolTimer.name}
    actions={
      <ProtocolTimerActionMenu
        protocolTimer={protocolTimer}
        actions={
          protocolTimer.is_experiment_child
            ? ['delete']
            : ['delete', 'edit']
        }
      />
    }
    right={
      protocolTimer.is_experiment_child ? (
        <ProtocolTimerItemContainer protocolTimer={protocolTimer}>
          {({ timeRemaining }) => <Time {...timeRemaining} />}
        </ProtocolTimerItemContainer>
      ) : null
    }
    {...rest}
  />
);

export default ProtocolTimerCard;
