/**
 * Labstep
 *
 * @module
 * @desc Selectors for premium features plugin
 */

import { PremiumFeature } from 'labstep-web/models/organization.model';
import { premiumFeaturesPluginKey } from 'labstep-web/prosemirror/extensions/premium-features/plugins';
import { EditorState } from 'prosemirror-state';

/**
 *
 * @description Returns the plugin state
 * @param state
 * @returns object
 */
export const getState = (state: EditorState): PremiumFeature[] => {
  return state.plugins
    .find((p) => p.spec.key === premiumFeaturesPluginKey)!
    .getState(state);
};
