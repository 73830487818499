/**
 * Labstep
 *
 * @module components/EntityUser/Action/Menu
 * @desc Action Menu for group
 */

import ActionsMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import EntityUserActionToggleAssign from '../ToggleAssign';
import { Action, IEntityUserActionMenuProps } from './types';

const EntityUserActionMenu: React.FC<IEntityUserActionMenuProps> = ({
  entityUser,
  type,
  actions = [],
  entity,
}) => {
  return (
    <ActionsMenu type={type}>
      {actions.includes(Action.toggle_assign) && (
        <EntityUserActionToggleAssign
          entity={entity}
          actionComponentProps={{ type: 'option' }}
          entityUser={entityUser}
        />
      )}
    </ActionsMenu>
  );
};

EntityUserActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default EntityUserActionMenu;
