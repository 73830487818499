/**
 * Labstep
 *
 * @module components/Permission/Manager
 * @desc Displays list of permissions
 */

import React from 'react';
import PermissionTable from 'labstep-web/components/Permission/Table';
import EntitySearch from 'labstep-web/components/Entity/Search';
import PermissionActionCreate from 'labstep-web/components/Permission/Action/Create';
import { Permission } from 'labstep-web/models/permission.model';
import { PermissionManagerProps } from './types';

export const PermissionManager: React.FC<PermissionManagerProps> = ({
  parentEntity,
}) => (
  <>
    <br />
    <PermissionActionCreate parentEntity={parentEntity} />
    <EntitySearch
      entityName={Permission.entityName}
      params={{
        [`${parentEntity.entityName}_id`]: parentEntity.id,
      }}
      hideFilters
      showEmptyStateOnSearchOnly
    >
      {({ entities }) => (
        <PermissionTable
          parentEntity={parentEntity}
          permissions={entities}
        />
      )}
    </EntitySearch>
  </>
);

export default PermissionManager;
