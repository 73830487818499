/**
 * Labstep
 *
 * @module components/CustomIdentifierSet/Form/fields
 * @desc Custom Identifier Set fields
 */

import rules from 'labstep-web/services/validation/rules';
import {
  IFieldWithoutActionProps,
  FieldType,
  IFieldProps,
} from 'labstep-web/core/Form/Reusable/types';
import { getPreview } from './utils';

export const fieldPrefix: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'prefix',
  fieldLabel: 'Prefix',
  placeholder: 'Specify Identifier prefix',
  validation: rules.custom_identifier_set.prefix,
};

export const fieldZeroPadding: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'zero_padding',
  fieldLabel: 'Minimum Number of Digits',
  placeholder: 'Specify minimum number of digits',
  validation: rules.custom_identifier_set.zero_padding,
};

export const fieldPreview: IFieldProps = {
  fieldType: FieldType.action,
  component: ({ values }) => getPreview(values) as any,
};
