/**
 * Labstep
 *
 * @module components/User/Form/ResetPassword
 * @desc ResetPassword form
 */

import Button from 'labstep-web/core/Button';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import React from 'react';
import {
  fieldUserSignupPassword,
  fieldUserSignupPasswordConfirmation,
} from '../fields';
import { printStatusError } from '../Signup';
import { IUserFormResetPasswordProps } from './types';

export const UserFormResetPassword: React.FC<
  IUserFormResetPasswordProps
> = ({ resetPassword, status, token }) => (
  <div>
    <ReusableForm
      onSubmit={(form) => resetPassword({ ...form, token })}
      fields={[
        fieldUserSignupPassword,
        fieldUserSignupPasswordConfirmation,
      ]}
      error={printStatusError(status)}
      submitButton={
        <Button fluid primary status={status}>
          Submit
        </Button>
      }
    />
  </div>
);

export default UserFormResetPassword;
