/**
 * Labstep
 *
 * @module state/actions/entity
 * @desc This service provides generic api methods for entities
 */

import { Action } from 'labstep-web/models/action.model';
import { PermissionRole } from 'labstep-web/models/permission-role.model';
import {
  getIdAttribute,
  getPluralInSchema,
} from 'labstep-web/services/schema/helpers';
import * as sfApi from 'labstep-web/services/sf-api.service';
import { stringifyParamsInOrder } from 'labstep-web/state/utils';
import { IOptions } from 'labstep-web/typings';
import camelCase from 'lodash/camelCase';
import omit from 'lodash/omit';
import snakeCase from 'lodash/snakeCase';
import { v4 } from 'uuid';
import { CreateEntityActionType } from './entity.types';

/**
 * Get the route
 * @param  {string} actionType - CREATE/READ/UPDATE/DELETE/READ_PAGE/READ_CURSOR
 * @param  {string} entityName - Entity name e.g.: 'research_area'
 * @param  {string} apiMethod - GET/PUT/DELETE e.t.c.
 * @param  {bool} plural - if second part of routeName should be pluralized
 * @return {object} - returns object of common fields
 */
export const getRoute = (
  apiMethod: string,
  entityName: string,
  plural = false,
  isPublic = false,
  batch = false,
) => ({
  apiMethod,
  entityName,
  plural,
  isPublic,
  batch,
});

/**
 * Creates a prefix for a uuid
 * @param  {string} parentName Name of parent entity
 * @param  {string} parentId   Id of parent entity
 */
export const getCreateKeyPrefix = (
  parentName: string,
  parentId: number | string,
  uuid?: string,
) => (uuid ? `${uuid}&` : `${parentName}_id=${parentId}&`);

/**
 * Creates a uuid for the entity to be created
 *
 * TODO Refactor as this is the only place we rely on uuid
 *
 * @param  {string} parentName Name of parent entity
 * @param  {string} parentId   Id of parent entity
 */
export const computeCreateKey = (
  parentName: string,
  parentId: number | string,
  uuid: string,
) => `${getCreateKeyPrefix(parentName, parentId, uuid)}${v4()}`;

/**
 * This is because permission_role_guid is not a valid parameter for creating a permission role anymore
 * We will be deprecating anything with _guid (24/01/2024)
 */
const EXCLUDED_ENTITIES = [PermissionRole.entityName];

const appendParentIdInBody = (
  body: any,
  parentName: string,
  parentId: any,
) => {
  if (parentName) {
    if (
      !Number.isInteger(parentId) &&
      EXCLUDED_ENTITIES.indexOf(parentName) === -1
    ) {
      return {
        ...body,
        [`${parentName}_id`]: parentId,
      };
    }
    return {
      ...body,
      [`${parentName}_id`]: parentId,
    };
  }
  return body;
};

export const createEntityIfNotCreating: CreateEntityActionType = (
  entityName,
  data,
  parentName,
  parentId,
  uuid,
  options?,
  childKeyName?,
) => ({
  type: 'CHECK_CREATING_ON_CREATE_ENTITY',
  payload: {
    entityName,
    data,
    parentName,
    parentId,
    uuid,
    options,
    childKeyName,
  },
});

/**
 * Creates an entity
 *
 * FIXME Refactor uuid
 *
 * @function
 * @param  {string} entityName - Entity name
 * @param  {object} data - Parameters for creating the new entity
 * @param  {string} parentName
 * @param  {number|string} parentId
 * @param  {object} options - Any additional parameter for API
 */
export const createEntity: CreateEntityActionType = (
  entityName,
  data,
  parentName?,
  parentId?,
  uuid?,
  options = {},
  childKeyName?,
) => {
  let normalize = entityName;
  let body;

  // Batch create
  if (options.batch) {
    normalize = getPluralInSchema(entityName);
    body = data.map((object: any) =>
      appendParentIdInBody(object, parentName, parentId),
    );
  } else {
    body = appendParentIdInBody(data, parentName, parentId);
  }

  const isPublic = options && options.isPublic;

  // In case we need to pass extra params
  const params = options.params || {};

  const additionalMeta = options.additionalMeta || {};

  return sfApi.post({
    type: `CREATE_${snakeCase(entityName)}`,
    route: getRoute(
      'post',
      entityName,
      false,
      isPublic,
      options.batch,
    ),
    body,
    params,
    meta: {
      uuid,
      parentName,
      parentId,
      entityName,
      body,
      normalize,
      childKeyName,
      ...additionalMeta,
    },
    ...options,
  });
};

/**
 * Get an entity
 *
 * @function
 * @param  {string} entityName - Entity name
 * @param  {number|string} entityId - Entity id or guid
 * @param  {object} options - Any additional parameter for API
 */
export const readEntity = (
  entityName: string,
  entityId: number | string,
  options?: any,
  meta?: any,
) => {
  const idAttribute = getIdAttribute(entityName);
  const isPublic = options && options.isPublic;

  return sfApi.get({
    type: `READ_${snakeCase(entityName)}`,
    route: getRoute('get', entityName, false, isPublic),
    params: { [idAttribute]: entityId },
    meta: { identifier: entityId, normalize: entityName, ...meta },
    ...options,
  });
};

/**
 * Get a list of entities
 *
 * @function
 * @param  {string} entityName - Entity name
 * @param  {object} params - Query parameters
 * @param  {object} options - Any additional parameter for API
 */
export const readEntities = (
  entityName: string,
  params: any,
  options?: any,
) => {
  const isGetSingle = params.get_single && params.get_single === 1;
  const isPublic = options && options.isPublic;
  const normalize = isGetSingle
    ? entityName
    : getPluralInSchema(entityName);
  let idAttribute = getIdAttribute(entityName);
  const { id, ...rest } = params;

  // fall back on id until migration complete
  const skipGuid = idAttribute === 'guid' && typeof id !== 'string';
  idAttribute = skipGuid ? 'id' : idAttribute;

  const paramsWithIdAttribute = id
    ? { [idAttribute]: id, ...rest }
    : params;

  return sfApi.get({
    type: `READ_${snakeCase(entityName)}`,
    route: getRoute('get', entityName, true, isPublic),
    meta: { identifier: stringifyParamsInOrder(params), normalize },
    ...options,
    params: paramsWithIdAttribute,
  });
};

/**
 * Get a page-based paginated list of entities
 *
 * @function
 * @param  entityName - Entity name
 * @param  params - Query parameters
 * @param  page - Page number (default 1)
 * @param  options - Any additional parameter for API
 */
export const readEntitiesPage = (
  entityName: string,
  params: Record<string, unknown>,
  page = 1,
  options?: IOptions,
  usePostFilter?: boolean,
): Action => {
  const isFilter = usePostFilter || !!params.filter;
  const paramsKey = isFilter ? 'body' : 'params';
  const apiMethod = isFilter ? 'post' : 'get';
  const entitiesName = getPluralInSchema(entityName);
  return sfApi[apiMethod]({
    type: `READ_PAGE_${snakeCase(entityName)}`,
    route: {
      apiMethod,
      entityName,
      plural: true,
      filter: isFilter,
    },
    meta: {
      identifier: stringifyParamsInOrder(params),
      page,
      normalize: entitiesName,
    },
    ...options,
    [paramsKey]: { ...params, page },
  });
};

/**
 * Prepare to read next page of entities
 * Followed by an epic that will dispatch readEntitiesPage
 * based on last page read
 *
 * @param entityName Entity name
 * @param params Parameters
 * @param usePostFilter Use post filter
 * @returns Action
 */
export const readEntitiesNextPage = (
  entityName: string,
  params: Record<string, unknown>,
  options?: IOptions,
  usePostFilter?: boolean,
): Action => ({
  type: `PREPARE_READ_NEXT_PAGE_${snakeCase(entityName)}`,
  meta: {
    identifier: stringifyParamsInOrder(params),
    entityName,
    params,
    options,
    usePostFilter,
  },
});

/**
 * Get a cursor-based paginated list of entities.
 *
 * @function
 * @param  {string} entityName - Entity name
 * @param  {object} params - Query parameters
 * @param  {bool} refresh - Should refresh data (overwrite all existing data with the newly fetched)
 * @param  {object} options - Any additional parameter for API
 */
export const readEntitiesCursor = (
  entityName: string,
  params: any,
  options?: any,
  cursorOptions?: any,
) => {
  const entitiesName = getPluralInSchema(entityName);
  const identifier = stringifyParamsInOrder(params);

  return {
    type: `PREPARE_READ_CURSOR_${snakeCase(
      entityName,
    ).toUpperCase()}`,
    meta: {
      identifier,
      entitiesName,
      entityName,
      params,
      options,
      cursorOptions,
    },
  };
};

/**
 * Updates an entity
 *
 * @function
 * @param  {string} entityName - Entity name
 * @param  {number | string} entityId - Entity id (multiple ids can be separated by comma) or guid
 * @param  {object} body - Parameters for updating the entity
 * @param  {object} options - Any additional parameter for API
 */
export const updateEntity = (
  entityName: string,
  entityId: number | string,
  body: any,
  options?: any,
  uuid?: string,
) => {
  const idAttribute = getIdAttribute(entityName);
  const batch = options && options.batch;
  const noOutput = options && options.noOutput;
  let id = entityId;
  let identifier = entityId;

  let params: any = {};

  if (!entityId) {
    id = body.id;
    identifier = uuid;
  }
  if (!batch) {
    params = { [idAttribute]: id };
  }
  if (batch) {
    identifier = uuid;
  }
  if (noOutput) {
    params.no_output = 1;
  }

  // Checking if entityId is not of type number to set plural normalization (batch update)
  const normalize =
    (Array.isArray(entityId) && entityId.length > 1) || batch
      ? getPluralInSchema(entityName)
      : entityName;

  const finalBody = !entityId && !batch ? omit(body, ['id']) : body;

  return sfApi.put({
    type: `UPDATE_${snakeCase(entityName)}`,
    route: getRoute('put', entityName, false, false, batch),
    params,
    body: finalBody,
    meta: {
      identifier,
      entityName,
      body: finalBody,
      normalize,
    },
    ...options,
  });
};

/**
 * Deletes an entity
 *
 * @function
 * @param  {string} entityName - Entity name
 * @param  {number|string} entityId - Entity id or guid
 * @param  {object} options - Any additional parameter for API
 */
export const deleteEntity = (
  entityName: string,
  entityId: number | string,
  options?: any,
  body?: any,
) =>
  sfApi.remove({
    type: `DELETE_${snakeCase(entityName)}`,
    route: getRoute('delete', entityName),
    params: { [`${getIdAttribute(entityName)}s`]: entityId },
    body,
    meta: {
      identifier: entityId,
      entityName,
      normalize: entityName,
    },
    ...options,
  });

/**
 * Computes toggle apiAction and verb
 * FIXME action variable name is misleading
 * @param  {string} action - Action name
 * @return {object} - Returns apiAction and actionVerb
 */
export const getToggleActionAndVerb = (action: string) => {
  let actionVerb;
  let apiAction;
  switch (action) {
    case 'add': {
      actionVerb = 'TO';
      apiAction = sfApi.put;
      break;
    }
    case 'remove': {
      actionVerb = 'FROM';
      apiAction = sfApi.remove;
      break;
    }
    case 'set': {
      actionVerb = 'TO';
      apiAction = sfApi.post;
      break;
    }
    default:
      break;
  }
  return { actionVerb, apiAction };
};

/**
 * Get action name for toggle
 * @param  {string} action - Action e.g. 'set', 'add'
 * @param  {string} entityName
 * @param  {string} parentName
 * @return {string} - Name of action
 */
export const getToggleActionName = (
  action: string,
  entityName: string,
  parentName: string,
) => {
  const { actionVerb } = getToggleActionAndVerb(action);
  return `${action}_${snakeCase(
    entityName,
  )}_${actionVerb}_${snakeCase(parentName)}`.toUpperCase();
};

/**
 * Adds or Removes ChildEntity to/from ParentEntity (Many to Many relationships)
 * FIXME action variable name is misleading
 * @param  {string} action - 'add' or 'remove'
 * @param  {string} entityName - Name of child entity
 * @param  {number} entityIds - Id of child entity
 * @param  {string} parentName - Name of parent entity
 * @param  {number} parentId - Name of parent Id
 * @param  {object} options - Additiona options
 */
export const toggleEntity = (
  action: string,
  entityName: string,
  entityIds: string | number | (string | number)[],
  parentName: string,
  parentId: string | (string | number)[] | number,
  options: any = {},
) => {
  const ids = Array.isArray(entityIds) ? entityIds.join() : entityIds;
  const { apiAction } = getToggleActionAndVerb(action);
  const { single } = options;

  const childKeyName = options.childKeyName || entityName;

  let paramsParentKey;
  let paramsEntityKey;

  if (single) {
    paramsEntityKey = `group_${getIdAttribute(childKeyName)}`;
    paramsParentKey = getIdAttribute(parentName);
  } else {
    paramsParentKey = `${parentName.toLowerCase()}_ids`;
    paramsEntityKey = `${childKeyName.toLowerCase()}_ids`;
  }
  paramsParentKey = camelCase(paramsParentKey);
  paramsEntityKey = camelCase(paramsEntityKey);

  return apiAction({
    type: getToggleActionName(action, entityName, parentName),
    route: {
      toggle: 1,
      action,
      entityName: childKeyName,
      parentName,
      single,
    },
    params: { [paramsParentKey]: parentId, [paramsEntityKey]: ids },
    meta: {
      action,
      entityName,
      entityIds,
      parentName,
      parentId,
      normalize: true,
      childKeyName,
    },
    ...options,
  });
};

export const getToggleKey = (
  entityName: string,
  entityIds: string | number | (string | number)[],
  parentName: string,
  parentId: string | number | (string | number)[],
) => {
  if (Array.isArray(entityIds)) {
    return `${parentName}_id=${parentId}_toggle_${entityName}`;
  }

  return `${parentName}_id=${parentId}_toggle_${entityName}_id=${entityIds}`;
};

/**
 * Add ChildEntity to ParentEntity (Many to Many relationships)
 * @param  {string} entityName - Name of child entity
 * @param  {number} entityIds - Id of child entity
 * @param  {string} parentName - Name of parent entity
 * @param  {number} parentId - Name of parent Id
 * @param  {object} options - Additiona options
 */
export const addEntity = (
  entityName: string,
  entityIds: string | number | (string | number)[],
  parentName: string,
  parentId: string | (string | number)[] | number,
  options?: any,
) =>
  toggleEntity(
    'add',
    entityName,
    entityIds,
    parentName,
    parentId,
    options,
  );

/**
 * Remove ChildEntity from ParentEntity (Many to Many relationships)
 * @param  {string} entityName - Name of child entity
 * @param  {number} entityIds - Id of child entity
 * @param  {string} parentName - Name of parent entity
 * @param  {number} parentId - Name of parent Id
 * @param  {object} options - Additiona options
 */
export const removeEntity = (
  entityName: string,
  entityIds: string | number | (string | number)[],
  parentName: string,
  parentId: string | (string | number)[] | number,
  options?: any,
) =>
  toggleEntity(
    'remove',
    entityName,
    entityIds,
    parentName,
    parentId,
    options,
  );

/**
 * Entities allowed for setEntity function
 * As this is a very powerful operation Triple check that calling
 * set on the entity won't have inadverten results
 * @type {array}
 */
const allowedEntitiesForSet = ['research_area'];

/**
 * Sets ChildEntity(ies) to ParentEntity (Many to Many relationships)
 * (Completely overrides previous values)
 * @param  {string} entityName - Name of child entity
 * @param  {number} entityIds - Id of child entity
 * @param  {string} parentName - Name of parent entity
 * @param  {number} parentId - Name of parent Id
 * @param  {object} options - Additiona options
 */
export const setEntity = (
  entityName: string,
  entityIds: string | number | (string | number)[],
  parentName: string,
  parentId: string | (string | number)[] | number,
  options?: any,
) => {
  if (allowedEntitiesForSet.indexOf(entityName) === -1) {
    const error = {
      message:
        'This action is not allowed for this entity. Be careful if you want to whitelist this entity.',
    };
    throw error;
  }

  return toggleEntity(
    'set',
    entityName,
    entityIds,
    parentName,
    parentId,
    options,
  );
};

/**
 * Counts entities
 *
 * @function
 * @param  {string} entityName - Entity name
 * @param  {object} params - Query parameters
 * @param  {object} options - Any additional parameter for API
 */
export const readEntitiesCount = (
  entityName: string,
  params: any,
  options?: any,
) =>
  sfApi.get({
    type: `READ_COUNT_${snakeCase(entityName)}`,
    route: getRoute('get', entityName, true),
    meta: {
      identifier: stringifyParamsInOrder({ ...params, get_count: 1 }),
      independent: true,
    },
    ...options,
    params: { ...params, get_count: 1 },
  });
