/**
 * Labstep
 *
 * @module prosemirror/extensions/history
 * @desc History management
 */

import { history, redo, undo } from 'prosemirror-history';
import { navigatorService } from 'labstep-web/services/navigator.service';
import { LabstepExtension } from '../types';

const ExtensionHistory: LabstepExtension = {
  keymap: {
    'Mod-z': undo,
    'Mod-y': (state, dispatch) => {
      if (!navigatorService.isMac) {
        return redo(state, dispatch);
      }
      return false;
    },
    'Shift-Mod-z': redo,
  },
  plugins: [history()],
};

export default ExtensionHistory;
