/**
 * Labstep
 */

import { ICONS } from 'labstep-web/constants/icons';
import { STRINGS } from 'labstep-web/strings';
import { ISubLinksProps } from './types';

export const subLinks: ISubLinksProps = {
  overview: {
    route: {
      to: 'group_show',
    },
    icon: ICONS.group.info.home,
    text: STRINGS.navigation.overview.singular.capitalized,
  },
  experiments: {
    route: {
      to: 'group_experiment_workflows',
    },
    icon: ICONS.experiment_workflow.primary,
    text: STRINGS.experiment_workflow.plural.capitalized,
  },
  protocols: {
    route: {
      to: 'group_protocol_collections',
    },
    icon: ICONS.protocol_collection.primary,
    text: STRINGS.navigation.protocol_library.singular.capitalized,
  },
  inventory: {
    route: {
      to: 'group_resources',
    },
    routes: ['group_resources', 'group_resource_items'],
    icon: ICONS.resource.primary,
    text: STRINGS.navigation.inventory.singular.capitalized,
    premiumFeature: 'inventory',
  },
  ordering: {
    route: {
      to: 'group_order_requests',
    },
    routes: ['group_order_requests', 'group_purchase_orders'],
    icon: ICONS.order_request.primary,
    text: STRINGS.navigation.ordering.singular.capitalized,
    premiumFeature: 'order_management',
  },
  devices: {
    route: {
      to: 'group_devices',
    },
    icon: ICONS.device.primary,
    text: STRINGS.navigation.device.singular.capitalized,
    premiumFeature: 'devices',
  },
  protocol_conditions: {
    route: {
      to: 'group_protocol_conditions',
    },
    icon: ICONS.metadata.primary,
    text: 'Data Explorer',
    premiumFeature: 'reporting',
  },
  files: {
    route: {
      to: 'group_files',
    },
    icon: ICONS.file.primary,
    text: 'Uploaded Files',
    hide: false,
  },
  settings: {
    route: {
      to: 'group_settings',
    },
    routes: [
      'group_settings',
      'group_users',
      'group_settings_experiment_workflow',
      'group_settings_experiment_workflow_templates',
      'group_settings_experiment_entity_state_workflow',
      'group_settings_experiment_tags',
      'group_settings_protocol_tags',
      'group_settings_inventory_categories',
      'group_settings_inventory_locations',
      'group_settings_inventory_tags',
      'group_settings_device_categories',
      'group_settings_device_tags',
      'group_settings_order_request_template',
      'group_settings_purchase_order_template',
      'group_settings_order_request_tags',
      'group_settings_export',
      'group_settings_auto_sharing',
      'group_settings_notifications',
    ],
    icon: 'cog',
    text: STRINGS.navigation.group_setting.plural.capitalized,
  },
};

export const primaryLinks = Object.values(subLinks).filter(
  (link) => !link.hide,
);

export const extraLinks = [];
