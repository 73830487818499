/**
 * Labstep
 *
 * @module core/Form/ShowEdit/SearchSelect
 * @desc A showedit component for search select
 */

import React from 'react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import ActionComponent from 'labstep-web/core/Action/Component';
import SearchSelect from 'labstep-web/core/Select/Search';
import { ShowEditToggleComponent } from 'labstep-web/core/Form/ShowEdit';
import {
  ISearchSelectViewComponentProps,
  IFormShowEditSearchSelectToggleProps,
} from './types';
import styles from './styles.module.scss';

export const FormShowEditSearchSelectToggleViewComponent: React.FC<
  ISearchSelectViewComponentProps
> = ({ children, status, onExpand }) => (
  <div className={styles.container}>
    <div data-testid="show-edit-toggle">{children}</div>
    <ActionComponent
      type="icon"
      icon="times"
      status={status}
      onClick={onExpand}
    />
  </div>
);

const FormShowEditSearchSelectToggle: React.FC<
  IFormShowEditSearchSelectToggleProps
> = ({
  children,
  searchSelectProps,
  canEdit,
  onChange,
  hasValue,
  value,
  entityName,
  isTemplate,
  ...rest
}) => (
  <ShowEditToggleComponent
    hasValue={!!hasValue}
    canEdit={!!canEdit}
    children={children}
    customShow={({ toggle }) => (
      <FormShowEditSearchSelectToggleViewComponent
        onExpand={toggle}
        children={children}
      />
    )}
    toggledComponent={({ toggle, close }) => (
      <div className={styles.searchSelectContainer}>
        <SearchSelect
          entityName={entityName}
          value={value}
          isClearable
          autoFocus
          // Need to use close here because onBlur is called unexpectedly
          // on mobile https://github.com/JedWatson/react-select/issues/5388
          onBlur={close}
          openMenuOnFocus
          onChange={(option) => {
            onChange?.(option);
            toggle();
          }}
          {...searchSelectProps}
        />
      </div>
    )}
    name={getHumanReadableEntityName(
      entityName,
      false,
      false,
      isTemplate,
    )}
    {...rest}
  />
);

export default FormShowEditSearchSelectToggle;
