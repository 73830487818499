/**
 * Labstep
 */

import { EntityView } from 'labstep-web/models/entity-view.model';
import {
  PostFilterComparison,
  PostFilterNodeType,
  PostFilterOperator,
} from 'labstep-web/services/query-parameter.service';

/**
 * Creates a filter based on the parameters
 * @param entityName Table entity name
 * @param parameters Parameters
 * @returns Filter
 */
export const createEntityViewFilter = (
  entityName: EntityView['entityName'],
  context: EntityView['context'],
  parameters?: EntityView['parameters'],
): PostFilterNodeType[] => {
  const parametersPredicates = parameters
    ? (Object.keys(parameters) as Array<keyof typeof parameters>).map(
        (k) => ({
          json_path: k,
          attribute: 'parameters',
          comparison: PostFilterComparison.eq,
          value: parameters[k] as string,
        }),
      )
    : [
        {
          attribute: 'parameters',
          comparison: PostFilterComparison.null,
        },
      ];
  return [
    {
      type: PostFilterOperator.and,
      predicates: [
        {
          attribute: 'entity_name',
          comparison: PostFilterComparison.eq,
          value: entityName,
        },
        {
          attribute: 'context',
          comparison: PostFilterComparison.eq,
          value: context,
        },
        ...parametersPredicates,
      ],
    },
  ];
};
