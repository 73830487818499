/**
 * Labstep
 *
 * @module core/TimeGrid
 * @desc Wrapper around FullCalendar with TimeGrid plugin
 */

import React, { forwardRef } from 'react';
import { dateOnly } from 'labstep-web/services/date.service';
import FullCalendar from 'labstep-web/core/FullCalendar';
import { IPluginName } from 'labstep-web/core/FullCalendar/types';
import { ITimeGridProps } from './types';

export const TimeGrid: React.FC<ITimeGridProps> = forwardRef(
  (props, ref) => (
    <FullCalendar
      ref={ref}
      pluginName={IPluginName.timeGrid}
      initialView="timeGridThreeDay"
      allDaySlot={false}
      views={{
        timeGridThreeDay: {
          type: 'timeGrid',
          dayCount: 3,
          buttonText: 'Week',
        },
      }}
      headerToolbar={{
        left: 'prev,next today',
        right: 'timeGridThreeDay,timeGridDay',
      }}
      buttonText={{ today: 'Today', day: 'Day', week: 'Week' }}
      dayHeaderContent={(arg) =>
        dateOnly(arg.date, {
          day: 'numeric',
          month: 'numeric',
          weekday: 'short',
        })
      }
      isGrid
      {...props}
    />
  ),
);

export default TimeGrid;
