/**
 * Labstep
 *
 * @desc ShowEdit component for MolecularFormula
 */

import React from 'react';
import PubChemMetadataFormShowEditGeneric from 'labstep-web/components/PubChemMetadata/Form/ShowEdit/Generic';
import { fieldMolecularFormula } from 'labstep-web/components/PubChemMetadata/Form/fields';
import { IPubChemMetadataFormShowEditMolecularFormulaProps } from './types';

const PubChemMetadataFormShowEditMolecularFormula: React.FC<
  IPubChemMetadataFormShowEditMolecularFormulaProps
> = ({ parent, pubChemMetadata }) => (
  <PubChemMetadataFormShowEditGeneric
    pubChemMetadata={pubChemMetadata}
    parent={parent}
    field={fieldMolecularFormula}
  />
);

export default PubChemMetadataFormShowEditMolecularFormula;
