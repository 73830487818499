/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Action/Share
 * @desc Share a experiment workflow with all users on the platform
 */

import React from 'react';
import EntityActionShare from 'labstep-web/components/Entity/Action/Share';
import { IEntityStateWorkflowActionShareProps } from './types';

export const EntityStateWorkflowActionShare: React.FC<
  IEntityStateWorkflowActionShareProps
> = ({ entityStateWorkflow }) => (
  <EntityActionShare entity={entityStateWorkflow} />
);

export default EntityStateWorkflowActionShare;
