/**
 * Labstep
 *
 * @module prosemirror/nodes
 * @desc ProseMirror Nodes Definitions
 */

import { keymap } from 'prosemirror-keymap';
import { NodeSpec } from 'prosemirror-model';
import { Plugin } from 'prosemirror-state';
import NodeParagraph from './paragraph';
import NodeDoc from './doc';
import NodeText from './text';
import NodeHorizontalRule from './horizontal-rule';
import NodeCodeBlock from './code-block';
import NodeBlockquote from './blockquote';
import NodeImage from './image';
import NodeHeading from './heading';
import NodeHardBreak from './hard-break';
import NodeExperiment from './experiment';
import NodeExperimentTable from './experiment-table';
import NodeExperimentTimer from './experiment-timer';
import NodeExperimentValue from './experiment-value';
import NodeExperimentStep from './experiment-step';
import NodeJupyterNotebook from './jupyter-notebook';
import NodeMolecule from './molecule';
import NodeProtocolTable from './protocol-table';
import NodeProtocolTimer from './protocol-timer';
import NodeProtocolValue from './protocol-value';
import NodeProtocolStep from './protocol-step';
import NodeMetadata from './metadata';
import NodeFile from './file';
import NodeReference from './reference';
import NodeCode from './code';
import {
  NodeTable,
  NodeTableCell,
  NodeTableHeader,
  NodeTableRow,
} from './table';
import {
  NodeBulletList,
  NodeListItem,
  NodeOrderedList,
} from './list';
import NodeExperimentWorkflowLink from './experiment-workflow-link';
import NodeConditions from './conditions';

export const nodes = [
  NodeParagraph,
  NodeDoc,
  NodeText,
  NodeHorizontalRule,
  NodeCodeBlock,
  NodeBlockquote,
  NodeImage,
  NodeHeading,
  NodeHardBreak,
  NodeTable,
  NodeTableCell,
  NodeTableHeader,
  NodeTableRow,
  NodeCode,
  NodeFile,
  NodeMetadata,
  NodeConditions,
  NodeExperiment,
  NodeExperimentTimer,
  NodeExperimentValue,
  NodeExperimentWorkflowLink,
  NodeExperimentTable,
  NodeExperimentStep,
  NodeJupyterNotebook,
  NodeMolecule,
  NodeProtocolTimer,
  NodeProtocolValue,
  NodeProtocolTable,
  NodeProtocolStep,
  NodeBulletList,
  NodeListItem,
  NodeOrderedList,
  NodeReference,
];

export const spec: Record<string, NodeSpec> = nodes.reduce(
  (result, node) => ({ ...result, [node.key]: node.spec }),
  {},
);

const nodesKeymap: Plugin[] = nodes.reduce(
  (result, node) =>
    node.keymap ? [...result, keymap(node.keymap)] : result,
  [] as Plugin[],
);

const nodesPlugins = nodes
  .filter((node) => node.plugins)
  .reduce(
    (result, node) =>
      node.plugins ? [...result, ...node.plugins] : result,
    [] as Plugin[],
  );

export const plugins = [...nodesKeymap, ...nodesPlugins];
