/**
 * Labstep
 *
 * @module state/reducers/entity/readingIds;
 * @desc Reducer for retrieved entities
 */

import handleAction from 'labstep-web/state/reducers/entity/utils';

/**
 * Returns reducer for retrieved entities
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} action - Redux action
 */
const readingIds =
  (entityName: string, entityNameUppercase: string) =>
  (state: any = {}, action: any): any =>
    handleAction(
      state,
      action,
      'READ',
      action.meta && action.meta.identifier,
      entityName,
      entityNameUppercase,
    );

export default readingIds;
