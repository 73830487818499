/**
 * Labstep
 *
 * @module config/icons
 * @desc Icons config file
 */

export const iconPopups = {
  pencil: 'Edit',
  times: 'Delete',
  trash: 'Delete',
  setting: 'Settings',
  question: 'Help',
  'sign-out': 'Sign Out',
  attach: 'Attach File',
  hdd: 'Attach Folder Watcher File',
  dna: 'Attach SnapGene/GenBank File (.dna, .gb, .gbk)',
  table: 'Attach Table',
  image: 'Attach Image',
  'bell outline': 'Notifications',
  'cloud download': 'Download',
  external: 'Preview',
  'user plus': 'Invite',
  'paint brush': 'Annotate',
  exclamation: 'Urgent',
  'share alternate': 'Share',
  'check circle outline': 'Mark as complete',
  'check circle': 'Mark as incomplete',
  history: 'Activity Log',
};
