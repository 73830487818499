/**
 * Labstep
 *
 * @module components/ResourceItem/ListOrTable
 * @desc List or table of resourceItems
 */

import React from 'react';
import ResourceItemList from 'labstep-web/components/ResourceItem/List';
import MasterIndexCenterListOrTable from 'labstep-web/screens/Master/Index/Center/ListOrTable';
import ResourceItemDataGrid from '../DataGrid/Container';
import { IResourceItemListOrTableProps } from './types';
import { actions } from './constants';

export const ResourceItemListOrTable: React.FC<
  IResourceItemListOrTableProps
> = ({
  resourceItems,
  templateId,
  status,
  loadMore,
  columnDefs,
  tableFormat,
}) => (
  <MasterIndexCenterListOrTable
    tableComponent={
      <ResourceItemDataGrid
        resourceItems={resourceItems}
        templateId={templateId}
        loadMore={loadMore}
        status={status}
        columnDefs={columnDefs}
      />
    }
    listComponent={
      <ResourceItemList
        resourceItems={resourceItems}
        actions={actions}
      />
    }
    tableFormat={tableFormat}
  />
);

export default ResourceItemListOrTable;
