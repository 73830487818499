/**
 * Labstep
 *
 * @module containers/User/Logout
 * @desc Logout container
 */

import { logoutAction } from 'labstep-web/state/actions/user';
import { IOptions } from 'labstep-web/typings';
import { connect } from 'react-redux';

export type UserLogoutChildrenProps = ReturnType<
  typeof mapDispatchToProps
>;

const LogoutContainer = ({
  children,
  ...rest
}: {
  children: (
    props: UserLogoutChildrenProps,
  ) => React.ReactElement | null;
} & UserLogoutChildrenProps) => children(rest);

const mapDispatchToProps = (dispatch: (...args: any[]) => void) => ({
  logout: (options?: IOptions) => {
    dispatch(logoutAction(options));
  },
  logoutWithoutApi: () => {
    dispatch({ type: 'LOGOUT_SUCCESS' });
  },
});

export const UserLogoutContainer = connect(
  () => ({}),
  mapDispatchToProps,
)(LogoutContainer);
