/**
 * Labstep
 *
 * @module prosemirror/utils/dnd
 * @desc ProseMirror Drag and Drop utils
 */

import { EditorView } from 'prosemirror-view';
import { createFiles } from 'labstep-web/prosemirror/extensions/paste';
import { reactPropsKey } from 'labstep-web/prosemirror/extensions/external-comm';
import { insertNode } from 'labstep-web/prosemirror/nodes/commands';

export const handleDrop = (view: EditorView, e: Event) => {
  // DragEvent not available on safari
  // https://github.com/Labstep/web/issues/5996
  // https://stackoverflow.com/questions/56137343/angular-not-working-on-safari-browser-but-working-well-on-other-browsers
  const event: any = e;
  if (!event.dataTransfer) {
    return false;
  }
  const { entity } = reactPropsKey.getState(view.state);
  if (event.dataTransfer.files) {
    const { files } = event.dataTransfer;
    if (files.length > 0) {
      e.preventDefault();
      createFiles(view.state, view.dispatch, entity, files);
      return true;
    }
  }
  const data = event.dataTransfer.getData('text');
  if (data.length > 0) {
    try {
      const { pos } = view.posAtCoords({
        left: event.clientX,
        top: event.clientY,
      });

      const parsedData = JSON.parse(data);
      const { entity_name, entity_id, entity_guid, is_reference } =
        parsedData;

      let key = entity_name;
      let attrs: any = {
        id: entity_id,
        guid: entity_guid,
      };

      if (is_reference) {
        key = 'reference';
        attrs = { ...attrs, entityName: entity_name };
      }

      insertNode(view.state, view.dispatch, key, pos, attrs);
      return true;
    } catch (err) {
      return false;
    }
  }
  return false;
};
