/**
 * Labstep
 *
 * @module components/Group/Initials
 * @desc Initials component for group
 */

import ProfilePicture from 'labstep-web/components/Profile/Picture';
import { withResponsive } from 'labstep-web/containers/Responsive';
import Initials from 'labstep-web/core/Initials';
import React from 'react';
import { IGroupInitialsProps } from './types';

export const GroupInitials: React.FC<IGroupInitialsProps> = ({
  group,
  ...rest
}) =>
  group.profile && group.profile.photo ? (
    <ProfilePicture
      {...rest}
      bordered
      entity={group}
      circular={false}
      rounded
    />
  ) : (
    <Initials
      {...rest}
      name={group.name}
      themeId={group.id}
      circular={false}
    />
  );

export default withResponsive(GroupInitials, 'mini');
