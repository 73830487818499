/**
 * Labstep
 *
 * @module components/PermissionRole/Form/Edit
 * @desc PermissionRole Form Edit
 */

import React from 'react';
import EditForm from 'labstep-web/core/Form/Edit';
import { fieldName } from 'labstep-web/components/PermissionRole/Form/fields';
import { IPermissionRoleEditFormProps } from './types';

export const PermissionRoleEditForm: React.FC<
  IPermissionRoleEditFormProps
> = ({ permissionRole, ...rest }) => (
  <EditForm
    autoFocus
    entityName={permissionRole.entityName}
    defaultValues={{ name: permissionRole.name }}
    entity={permissionRole}
    fields={[fieldName]}
    {...rest}
  />
);

export default PermissionRoleEditForm;
