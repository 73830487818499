/**
 * Labstep
 *
 * @module state/selectors/websocket
 * @desc Selectors for Websocket
 */

import { createSelector } from 'reselect';
import { User } from 'labstep-web/models/user.model';

/**
 * Select websocket connected
 *
 * @function
 * @param  {object} state
 * @return {boolean} connected
 */
export const selectWebsocketConnected = createSelector(
  (state: any) => state.websocket.connected,
  (connected: boolean) => connected,
);

/**
 * Select websocket room
 *
 * @function
 * @param  {object} state
 * @return {string|null} room
 */
export const selectWebsocketRoom = createSelector(
  (state: any) => state.websocket.room,
  (room?: string) => room,
);

/**
 * Select websocket users
 *
 * @function
 * @param  {object} state
 * @return {boolean} users
 */
export const selectWebsocketUsers = createSelector(
  (state: any) => state.websocket.users,
  (users: User[]) => users,
);
