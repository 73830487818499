/**
 * Labstep
 *
 * @module core/ProseMirror/Conflict
 * @desc Display a warning for the user to save his work and reload the page.
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import React from 'react';
import { ProseMirrorConflictProps } from './types';
import styles from './styles.module.scss';

const ProseMirrorConflict: React.FC<ProseMirrorConflictProps> = ({
  conflict,
  debouncing,
  forceRefresh,
  cancelDebouncing,
}) => {
  if (conflict) {
    if (debouncing) {
      cancelDebouncing(); // TODO: Refactor using epics?
    }
    return (
      <div id="prosemirror-conflict" className={styles.container}>
        This document has been edited by{' '}
        {conflict?.author
          ? `${conflict.author.first_name} ${conflict.author.last_name}`
          : 'someone else'}
        .
        <br />
        <b>Copy any unsaved changes</b>
        {`, `}
        <ActionComponent
          type="text"
          text="click here to load the latest version"
          onClick={forceRefresh}
        />
        , and then reapply your changes.
      </div>
    );
  }

  return null;
};

export default ProseMirrorConflict;
