/**
 * Labstep
 *
 * @module core/Select/NoIndicator
 * @desc Select without Dropdown Indicator
 */

import React from 'react';
import Select from 'labstep-web/core/Select';

export const SelectNoIndicator = (
  props: typeof Select.defaultProps,
) => (
  <Select
    {...props}
    components={{
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
    }}
  />
);

export default SelectNoIndicator;
