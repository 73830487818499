/**
 * Labstep
 *
 * @module components/ShareLink/Action/TogglePermissions
 * @desc Toggle view/edit permissions for ShareLink
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import { PermissionMenu } from 'labstep-web/core/Permission';
import { PermissionType } from 'labstep-web/models/permission.model';
import { ShareLinkType } from 'labstep-web/models/share-link.model';
import React from 'react';
import {
  IChildrenProps,
  IToggleShareLinkPermissionProps,
} from './types';

export const ToggleShareLinkPermissions: React.FC<
  IToggleShareLinkPermissionProps
> = ({ shareLinkId }) => (
  <EntityUpdateContainer entityName="share_link" id={shareLinkId}>
    {({ update, entity: shareLink }: IChildrenProps) => (
      <PermissionMenu
        type={
          shareLink.type === ShareLinkType.edit
            ? PermissionType.edit
            : PermissionType.view
        }
        onClick={update}
      />
    )}
  </EntityUpdateContainer>
);

export default ToggleShareLinkPermissions;
