/**
 * Labstep
 *
 * @module components/OrganizationPlan/Manager/Price/Select/Currency
 * @desc Select currency
 */

import React from 'react';
import { supportedCurrencies } from 'labstep-web/constants/organization-plan-price';
import Select from 'labstep-web/core/Select';
import styles from '../styles.module.scss';
import {
  OptionType,
  OrganizationPlanManagerPriceSelectCurrencyProps,
} from './types';

export const OrganizationPlanManagerPriceSelectCurrency: React.FC<
  OrganizationPlanManagerPriceSelectCurrencyProps
> = ({ activeCurrency, setActiveCurrency }) => (
  <div className={styles.container}>
    <div>Currency </div>
    <Select
      value={{
        label: activeCurrency.toUpperCase(),
        value: activeCurrency,
      }}
      options={supportedCurrencies.map((currency) => ({
        label: currency.toUpperCase(),
        value: currency,
      }))}
      onChange={(option: OptionType) =>
        setActiveCurrency(option.value)
      }
    />
  </div>
);

export default OrganizationPlanManagerPriceSelectCurrency;
