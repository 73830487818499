/**
 * Labstep
 *
 * @module containers/Entity/Update/Any
 * @desc EntityUpdateContainer but allows id specification
 */

import { updateEntity } from 'labstep-web/state/actions/entity';
import { selectUpdateEntityAllStatuses } from 'labstep-web/state/selectors/entity';
import { LabstepReduxState } from 'labstep-web/state/types';
import { IOptions } from 'labstep-web/typings';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { IEntityUpdateContainerProps, OwnProps } from './types';

export type EntityUpdateAnyContainerChildrenProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

const Container = ({
  children,
  ...rest
}: EntityUpdateAnyContainerChildrenProps & {
  children: (
    childrenProps: Omit<
      EntityUpdateAnyContainerChildrenProps,
      'children'
    >,
  ) => JSX.Element | null;
}) => children(rest);

const mapStateToProps = (
  state: LabstepReduxState,
  ownProps: OwnProps,
) => ({
  statuses: selectUpdateEntityAllStatuses(state, ownProps.entityName),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps,
) => ({
  update(
    id: string | number,
    body: Record<string, unknown>,
    options?: IOptions,
  ) {
    dispatch(
      updateEntity(ownProps.entityName, id, body, {
        batch: ownProps.batch,
        optimistic: ownProps.optimistic,
        ...options,
      }),
    );
  },
});

export const EntityUpdateAnyContainer: React.FC<IEntityUpdateContainerProps> =
  connect(mapStateToProps, mapDispatchToProps)(Container);
