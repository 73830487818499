/**
 * Labstep
 *
 * @module prosemirror/nodes/heading
 * @desc Heading rule node
 */

import { Node as ProseMirrorNode } from 'prosemirror-model';
import { setBlockType } from 'prosemirror-commands';
import { textblockTypeInputRule } from 'prosemirror-inputrules';
import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';
import NodeParagraph from 'labstep-web/prosemirror/nodes/paragraph';

// : (NodeType, number) → InputRule
// Given a node type and a maximum level, creates an input rule that
// turns up to that number of `#` characters followed by a space at
// the start of a textblock into a heading whose level corresponds to
// the number of `#` signs.
export function headingRule(nodeType, maxLevel) {
  return textblockTypeInputRule(
    new RegExp(`^(#{1,${maxLevel}})\\s$`),
    nodeType,
    (match) => ({ level: match[1].length }),
  );
}

const NodeHeading: LabstepNode = {
  key: 'heading',
  spec: {
    attrs: { level: { default: 1 } },
    content: 'inline*',
    group: 'block',
    defining: true,
    parseDOM: [
      { tag: 'h1', attrs: { level: 1 } },
      { tag: 'h2', attrs: { level: 2 } },
      { tag: 'h3', attrs: { level: 3 } },
      { tag: 'h4', attrs: { level: 4 } },
      { tag: 'h5', attrs: { level: 5 } },
      { tag: 'h6', attrs: { level: 6 } },
    ],
    toDOM(node: ProseMirrorNode) {
      return [`h${node.attrs.level}`, 0];
    },
  },
  keymap: {
    'Shift-Ctrl-1': (state, dispatch) =>
      setBlockType(state.schema.nodes[NodeHeading.key], { level: 1 })(
        state,
        dispatch,
      ),
    'Shift-Ctrl-2': (state, dispatch) =>
      setBlockType(state.schema.nodes[NodeHeading.key], { level: 2 })(
        state,
        dispatch,
      ),
    'Shift-Ctrl-3': (state, dispatch) =>
      setBlockType(state.schema.nodes[NodeHeading.key], { level: 3 })(
        state,
        dispatch,
      ),
    'Shift-Ctrl-4': (state, dispatch) =>
      setBlockType(state.schema.nodes[NodeHeading.key], { level: 4 })(
        state,
        dispatch,
      ),
    'Shift-Ctrl-5': (state, dispatch) =>
      setBlockType(state.schema.nodes[NodeHeading.key], { level: 5 })(
        state,
        dispatch,
      ),
    'Shift-Ctrl-6': (state, dispatch) =>
      setBlockType(state.schema.nodes[NodeHeading.key], { level: 6 })(
        state,
        dispatch,
      ),
    'Shift-Ctrl-7': (state, dispatch) =>
      setBlockType(state.schema.nodes[NodeParagraph.key], {
        level: 1,
      })(state, dispatch),
  },
  inputRules({ schema }) {
    return [headingRule(schema.nodes[NodeHeading.key], 6)];
  },
};

export default NodeHeading;
