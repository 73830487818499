/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Action/Duplicate
 * @desc Button to duplicate
 */

import EntityActionDuplicate from 'labstep-web/components/Entity/Action/Duplicate';
import React from 'react';
import { IEntityStateWorkflowDuplicateActionProps } from './types';

export const EntityStateWorkflowDuplicateAction: React.FC<
  IEntityStateWorkflowDuplicateActionProps
> = ({ entityStateWorkflow }) => (
  <EntityActionDuplicate entity={entityStateWorkflow} />
);

export default EntityStateWorkflowDuplicateAction;
