/**
 * Labstep
 *
 * @module components/OrderRequest/Price
 * @desc Order Request price (currency and amoutn)
 */

import React from 'react';
import OrderRequestFormShowEditAmount from 'labstep-web/components/OrderRequest/Form/ShowEdit/Amount';
import OrderRequestFormShowEditCurrency from 'labstep-web/components/OrderRequest/Form/ShowEdit/Currency';
import { IOrderRequestPriceProps } from './types';
import styles from './styles.module.scss';

export const OrderRequestPrice: React.FC<IOrderRequestPriceProps> = ({
  orderRequest,
}) => (
  <div className={styles.price}>
    <OrderRequestFormShowEditCurrency orderRequest={orderRequest} />
    <OrderRequestFormShowEditAmount orderRequest={orderRequest} />
  </div>
);

export default OrderRequestPrice;
