/**
 * Labstep
 *
 * @module components/User/Filter/User/Active
 * @desc Active filter component for user
 */

import React from 'react';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { IFilterUserActiveLabelProps } from './types';

const FilterUserActiveLabel: React.FC<
  IFilterUserActiveLabelProps
> = ({ user, type, onRemoveClick }) => (
  <RemoveLabel
    icon={type === 'assignee' ? 'user plus' : 'user circle'}
    name={user.name}
    onRemoveClick={onRemoveClick}
  />
);

export default FilterUserActiveLabel;
