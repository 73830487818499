/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Card/SubTitle
 * @desc Experiment Card SubTitle
 */

import React from 'react';
import { humanReadableDate } from 'labstep-web/services/date.service';
import UserShowProfile from 'labstep-web/components/User/Show/Profile';
import styles from './styles.module.scss';
import { IExperimentWorkflowCardSubTitleProps } from './types';

const ExperimentWorkflowCardSubTitle: React.FC<
  IExperimentWorkflowCardSubTitleProps
> = ({ experimentWorkflow, createdBy }) => (
  <>
    {createdBy && (
      <div className={styles.status}>
        {'Created by '}
        <UserShowProfile
          viewComponent={experimentWorkflow.author.name}
          user={experimentWorkflow.author}
        />
      </div>
    )}
    <div className={styles.status}>
      <b>{experimentWorkflow.ended_at ? 'Finished' : 'Live'}</b>
      {!experimentWorkflow.ended_at && ' - Started '}
      {' on '}
      {humanReadableDate(
        experimentWorkflow.ended_at || experimentWorkflow.started_at,
      )}
    </div>
  </>
);

ExperimentWorkflowCardSubTitle.defaultProps = {
  createdBy: false,
};

export default ExperimentWorkflowCardSubTitle;
