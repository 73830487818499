/**
 * Labstep
 *
 * @module components/Academic/Action/Upgrade
 * @desc Show button to upgrade academic account or complete setup
 */

import AcademicWizardPayment from 'labstep-web/components/Academic/Wizard/Payment';
import AcademicWizardSetup from 'labstep-web/components/Academic/Wizard/Setup';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import React from 'react';
import { AcademicActionUpgradeProps } from './types';

export const AcademicActionUpgrade: React.FC<
  AcademicActionUpgradeProps
> = ({ authenticatedUserEntity, actionComponentProps }) => {
  if (authenticatedUserEntity.stripe_subscription_id) {
    return (
      <AcademicWizardSetup
        actionComponentProps={actionComponentProps}
      />
    );
  }

  return (
    <AcademicWizardPayment
      actionComponentProps={actionComponentProps}
    />
  );
};

export default withAuthenticatedUserEntity(AcademicActionUpgrade);
