/**
 * Labstep
 */

import * as sfApi from 'labstep-web/services/sf-api.service';

export const PING = 'PING';

export const ping = (
  message: string,
  code: number,
  extraParams?: Record<string, unknown>,
) =>
  sfApi.get({
    type: 'PING',
    params: {
      message,
      code,
      ...extraParams,
    },
    route: {
      custom: 'app_ping_ping',
    },
  });
