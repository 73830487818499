/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/ProtocolDevice
 * @desc ShowEdit for metadata protocol device
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import EntityLink from 'labstep-web/components/Entity/Link';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import FormShowEditSearchSelectToggle from 'labstep-web/core/Form/ShowEdit/SearchSelect/Toggle';
import { Device } from 'labstep-web/models/device.model';
import { Metadata } from 'labstep-web/models/metadata';
import { ProtocolDevice } from 'labstep-web/models/protocol-device.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import {
  IMetadataFormShowEditProtocolDeviceContainerProps,
  IMetadataFormShowEditProtocolDeviceProps,
} from './types';

export const MetadataFormShowEditProtocolDevice: React.FC<
  IMetadataFormShowEditProtocolDeviceProps
> = ({ metadata, parent, params, create, update }) => {
  const canEdit = useHasAccess(
    metadata.entityName,
    metadata.id,
    Action.edit,
    'device',
  );
  return (
    <FormShowEditSearchSelectToggle
      placeholder="Select measurement device"
      entityName={Device.entityName}
      searchSelectProps={{
        creatable: true,
        params,
      }}
      isTemplate={false}
      value={metadata.protocol_device}
      canEdit={canEdit}
      hasValue={!!metadata.protocol_device}
      onChange={(option): void => {
        if (!option) {
          update({
            protocol_device_guid: null,
          });
        } else {
          create(
            {
              [`${
                parent instanceof Protocol ? 'protocol' : 'experiment'
              }_id`]: parent.id,
              device_id: option ? option.id : null,
            },
            {
              onSuccess: ({ response }) =>
                update({
                  protocol_device_guid: response.result,
                }),
            },
          );
        }
      }}
    >
      {metadata.protocol_device && (
        <EntityLink entity={metadata.protocol_device.device} />
      )}
    </FormShowEditSearchSelectToggle>
  );
};

export const MetadataFormShowEditProtocolDeviceContainer: React.FC<
  IMetadataFormShowEditProtocolDeviceContainerProps
> = ({ metadata, ...rest }) => (
  <EntityCreateContainer entityName={ProtocolDevice.entityName}>
    {({ create, status }): React.ReactElement => (
      <EntityUpdateContainer
        entityName={Metadata.entityName}
        id={metadata.id}
      >
        {({ update }): React.ReactElement => (
          <MetadataFormShowEditProtocolDevice
            metadata={metadata}
            create={create}
            status={status}
            update={update}
            {...rest}
          />
        )}
      </EntityUpdateContainer>
    )}
  </EntityCreateContainer>
);

export default MetadataFormShowEditProtocolDeviceContainer;
