/**
 * Labstep
 *
 * @module core/Time
 * @desc Displays time in stopwatch format
 */

import React from 'react';
import { ITimeProps } from './types';

export const pad = (str: string | number, max: number): string => {
  const string = str.toString();
  return string.length < max ? pad(`0${string}`, max) : string;
};

export const getTimeText = ({
  hours = 0,
  minutes = 0,
  seconds = 0,
  negative,
}: ITimeProps): string =>
  `${negative ? '-' : ''}${pad(hours, 2)}:${pad(minutes, 2)}:${pad(
    seconds,
    2,
  )}`;

/**
 * Returns time in stopwatch format
 */
const Time: React.FC<ITimeProps> = (props) => (
  <span>{getTimeText(props)}</span>
);

export default Time;
