/**
 * Labstep
 *
 * @module components/Experiment/List
 * @desc Experiment List
 */

import React from 'react';
import EntityStateWorkflowCard from 'labstep-web/components/EntityStateWorkflow/Card';
import List from 'labstep-web/core/List';
import styles from './styles.module.scss';
import { IEntityStateWorkflowListProps } from './types';

const EntityStateWorkflowList: React.FC<
  IEntityStateWorkflowListProps
> = ({
  entityStateWorkflows,
  actions,
  createdBy,
  mainActionType,
}) => (
  <List className={styles.feed} id="entity-state-workflow-list">
    {entityStateWorkflows.map((entityStateWorkflow) => (
      <EntityStateWorkflowCard
        key={`entity-state-workflow-${entityStateWorkflow.id}`}
        entityStateWorkflow={entityStateWorkflow}
        actions={actions}
        createdBy={createdBy}
        mainActionType={mainActionType}
      />
    ))}
  </List>
);

export default EntityStateWorkflowList;
