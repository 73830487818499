/**
 * Labstep
 *
 * @module models/folder
 * @desc Typescript export class for Folder
 */

import { Entity } from 'labstep-web/models/entity.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { PermissionActions } from 'labstep-web/typings';

// eslint-disable-next-line no-shadow
export enum FolderType {
  experiment_workflow = 'experiment_workflow',
  protocol_collection = 'protocol_collection',
}

export class Folder extends Entity {
  static readonly entityName = 'folder' as const;

  get entityName(): typeof Folder.entityName {
    return Folder.entityName;
  }

  get displayEntityName(): string {
    return this.entityName;
  }

  constructor(data: Partial<Folder> = {}) {
    super();
    Object.assign(this, data);
  }

  id: number;

  name: string;

  protocol_collection_count: number;

  experiment_workflow_count: number;

  type: FolderType;

  folder_path: string;

  inner_folder_ids: Folder['id'][];

  public allowed_actions!: PermissionActions[];

  get associatedEntitiesCount(): number {
    return this[`${this.type}_count`];
  }

  get isAssociated(): boolean {
    return this.associatedEntitiesCount > 0;
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
