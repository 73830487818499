/**
 * Labstep
 *
 * @module components/File/Action/Create
 * @desc Action to create file
 */

import React from 'react';
import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import { IPickerProps } from './types';
import styles from './styles.module.scss';

export const Picker: React.FC<IPickerProps> = ({
  icon,
  text,
  onClick,
  disabled,
}) => (
  <div
    onClick={disabled ? undefined : onClick}
    className={classnames(styles.picker, {
      [styles.disabled]: disabled,
    })}
  >
    <div>
      <Icon name={icon} />
    </div>
    <div>{text}</div>
  </div>
);

export default Picker;
