/**
 * Labstep
 *
 * @module prosemirror/extensions/editable/plugin
 * @desc Store editable in state
 */

import { Plugin, PluginKey } from 'prosemirror-state';

export const editablePluginKey = new PluginKey('editable');

const editablePlugin = new Plugin({
  key: editablePluginKey,
  state: {
    init: () => false,
    apply: (tr, prev) => {
      const meta = tr.getMeta(editablePluginKey);
      return meta !== undefined ? meta : prev;
    },
  },
});

export default editablePlugin;
