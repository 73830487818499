/**
 * Labstep
 */

import {
  columnResizing,
  goToNextCell,
  tableEditing,
} from 'prosemirror-tables';
import { keymap } from 'prosemirror-keymap';

const plugins = [
  tableEditing(),
  columnResizing({}),
  keymap({
    Tab: goToNextCell(1),
    'Shift-Tab': goToNextCell(-1),
  }),
];

export default plugins;
