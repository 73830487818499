/**
 * Labstep
 */

import { PostFilter } from 'labstep-web/services/postFilter';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';

export const PostFilterAvailableItems = new PostFilter([
  ['available_resource_item_count', PostFilterComparison.gte],
  ['available_resource_item_count', PostFilterComparison.lte],
]);
