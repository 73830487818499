/**
 * Labstep
 *
 * @module screens/Group/Show/Overview
 * @desc Overview column of GroupShow screen
 */

import ExperimentWorkflowCardOverview from 'labstep-web/components/ExperimentWorkflow/Card/Overview';
import GroupAboutCard from 'labstep-web/components/Group/Card/About';
import LayoutMainContentCenter from 'labstep-web/components/Layout/MainContent/Center';
import OrderRequestCardOverview from 'labstep-web/components/OrderRequest/Card/Overview';
import ProtocolCollectionCardOverview from 'labstep-web/components/ProtocolCollection/Card/Overview';
import Thread from 'labstep-web/components/Thread/Item';
import { useHasExceededLimit } from 'labstep-web/hooks/limits/limits';
import { LimitTypes } from 'labstep-web/constants/limits';
import { ICONS } from 'labstep-web/constants/icons';
import Card from 'labstep-web/core/Card/Card';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import { UserLimitReachedWarning } from 'labstep-web/components/Group/UserLimitReached';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import React from 'react';
import styles from './styles.module.scss';
import { IOverviewProps } from './types';

export const MessageBoard: React.FC<IOverviewProps> = ({ group }) => (
  <Card
    header={
      <>
        <Icon name={ICONS.thread.primary} /> Message Board
      </>
    }
    size="tiny"
  >
    <Thread
      thread={group.thread}
      noCommentsMessage={
        <EmptyState
          src="/img/empty-state/comments.svg"
          title="No comments"
          explanation="Start a discussion with workspace members."
        />
      }
    />
  </Card>
);

const Overview: React.FC<IOverviewProps> = ({ group }) => {
  const params = {
    group_id: group.id,
  };
  // TODO: Review with Barney if needed or not
  const showUserLimitWarning = false;
  // const showUserLimitWarning = useHasExceededLimit(
  //   group.user_count,
  //   LimitTypes.MAX_USERS_PER_GROUP,
  // );

  return (
    <LayoutMainContentCenter>
      <div className={styles.container}>
        {showUserLimitWarning ? (
          <UserLimitReachedWarning group={group} />
        ) : (
          <>
            <GroupAboutCard group={group} />
            <Flex wrap style={{ gap: '20px' }}>
              <div className={styles.item}>
                <ExperimentWorkflowCardOverview params={params} />
              </div>
              <div className={styles.item}>
                <ProtocolCollectionCardOverview params={params} />
              </div>
              {useHasFeatureFlagEnabled('order_management') && (
                <div className={styles.item}>
                  <OrderRequestCardOverview params={params} />
                </div>
              )}
              <div className={styles.item}>
                <MessageBoard group={group} />
              </div>
            </Flex>
          </>
        )}
      </div>
    </LayoutMainContentCenter>
  );
};

export default Overview;
