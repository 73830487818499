/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Duplicate
 * @desc Orderrequest action duplicate
 */

import React from 'react';
import EntityActionDuplicate from 'labstep-web/components/Entity/Action/Duplicate';
import { IOrderRequestActionDuplicateProps } from './types';

export const OrderRequestActionDuplicate: React.FC<
  IOrderRequestActionDuplicateProps
> = ({ orderRequest }) => (
  <EntityActionDuplicate entity={orderRequest} />
);

export default OrderRequestActionDuplicate;
