/**
 * Labstep
 *
 * @module components/Resource/OrderRequestTemplate
 * @desc ResourceTemplate Show Center OrderRequestTemplate
 */

import React from 'react';
import ResourceOrderRequestTemplateEdit from './Edit';
import ResourceOrderRequestTemplateEmptyState from './EmptyState';
import { ResourceOrderRequestTemplateProps } from './types';

const ResourceOrderRequestTemplate: React.FC<
  ResourceOrderRequestTemplateProps
> = ({ resource }) =>
  resource.hasNonDeletedOrderRequestTemplate ? (
    <ResourceOrderRequestTemplateEdit resource={resource} />
  ) : (
    <ResourceOrderRequestTemplateEmptyState resource={resource} />
  );

export default ResourceOrderRequestTemplate;
