/* eslint-disable @typescript-eslint/no-empty-function */
/**
 * Labstep
 */

import { IBulkSelectContainerChildrenProps } from 'labstep-web/containers/BulkSelect/types';
import React, { useContext } from 'react';

export const defaultBulkSelectContext: IBulkSelectContainerChildrenProps =
  {
    isSelected: () => false,
    toggleSelection: () => {
      // Do nothing
    },
    selectAll: () => {
      // Do nothing
    },
    unselectAll: () => {
      // Do nothing
    },
    selectAllVisible: () => {
      // Do nothing
    },
    canBulkSelect: false,
    isAtLeastOneSelected: false,
    selectedCount: 0,
    selected: [],
    selectedAll: false,
    selectedAllVisible: false,
    unselected: [],
    entities: [],
    isSelectComplete: false,
    requiredCount: 0,
    persistSelection: false,
  };

const BulkSelectContext =
  React.createContext<IBulkSelectContainerChildrenProps>(
    defaultBulkSelectContext,
  );

export const useBulkSelectContext =
  (): IBulkSelectContainerChildrenProps => {
    const context = useContext(BulkSelectContext);
    if (!context) {
      throw new Error(
        'useBulkSelectContext must be used within a BulkSelectContextProvider',
      );
    }
    return context;
  };

export default BulkSelectContext;
