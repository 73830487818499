/**
 * Labstep
 *
 * @module prosemirror/extensions/commands/commands/selectors
 * @desc Selectors for commands plugin
 */

import { EditorState } from 'prosemirror-state';
import { commandsPluginKey } from 'labstep-web/prosemirror/extensions/slash/plugin';
import { PluginState } from 'labstep-web/prosemirror/extensions/slash/plugin/types';

/**
 *
 * @description Returns the plugin state
 * @param state
 * @returns object
 */
export const getState = (state: EditorState): PluginState => {
  return state.plugins
    .find((p) => p.spec.key === commandsPluginKey)!
    .getState(state);
};

/**
 *
 * @description Returns isOpen of commands plugin
 * @param state
 * @returns boolean
 */
export const getIsOpen = (state: EditorState) => {
  return getState(state).isOpen;
};

/**
 *
 * @description Returns index of commands plugin
 * @param state
 * @returns string
 */
export const getIndex = (state: EditorState) => {
  return getState(state).index;
};

/**
 *
 * @description Returns elements of commands plugin
 * @param state
 * @returns string
 */
export const getAvailableElements = (state: EditorState) => {
  return getState(state).availableElements;
};
