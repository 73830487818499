/**
 * Labstep
 *
 * @module containers/JupyterAuthorize
 * @desc Sends OAuth authorize request with logged user JWT token
 */

import {
  authorize,
  selectJupyterAuthorizeStatus,
} from 'labstep-web/state/slices/jupyter/authorize';
import React from 'react';
import { connect } from 'react-redux';
import {
  WithJupyterAuthorizeInjectedProps,
  WithJupyterAuthorizeMapDispatchToProps,
  WithJupyterAuthorizeMapStateToProps,
} from './types';

const mapStateToProps: any = (
  state,
): WithJupyterAuthorizeMapStateToProps => ({
  status: selectJupyterAuthorizeStatus(state),
});

const mapDispatchToProps = (
  dispatch,
): WithJupyterAuthorizeMapDispatchToProps => ({
  authorize(
    responseType: string,
    redirectURI: string,
    clientId: string,
    state: string,
  ) {
    dispatch(
      authorize({ responseType, redirectURI, clientId, state }),
    );
  },
});

export const withJupyterAuthorize = <P, S>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<P, Exclude<keyof P, keyof WithJupyterAuthorizeInjectedProps>>
> => connect(mapStateToProps, mapDispatchToProps)(Component as any);
