/**
 * Labstep
 */

import { IActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { PermissionRole } from 'labstep-web/models/permission-role.model';

// eslint-disable-next-line no-shadow
export enum Action {
  edit = 'edit',
  delete = 'delete',
}

export interface IPermissionRoleActionMenuProps
  extends Pick<
    IActionMenuProps,
    Exclude<keyof IActionMenuProps, 'children' | 'options'>
  > {
  permissionRole: PermissionRole;
  actions: Action[];
}
