/**
 * Labstep
 *
 * @module components/Device/Filter/Pane
 * @desc Filter pane for device
 */

import FilterPane from 'labstep-web/components/Filter/Pane';
import { ICONS } from 'labstep-web/constants/icons';
import { Device } from 'labstep-web/models/device.model';
import { STRINGS } from 'labstep-web/strings';
import React from 'react';

const DeviceFilterPane: React.FC = () => (
  <FilterPane
    entityName={Device.entityName}
    filters={['created_by', 'assigned_to', 'tag']}
    icon={ICONS.device.primary}
    text={STRINGS.device.plural.capitalized}
  />
);

export default DeviceFilterPane;
