/**
 * Labstep
 *
 * @module components/User/Settings/LoginSecurity/ChangePassword
 * @desc Change Password
 */

import {
  fieldUserCurrentPassword,
  fieldUserNewSignupPassword,
  fieldUserNewSignupPasswordConfirmation,
} from 'labstep-web/components/User/Form/fields';
import { ToggleContainer } from 'labstep-web/containers/Toggle';
import { UserChangePasswordContainer } from 'labstep-web/containers/User/ChangePassword';
import ActionComponent from 'labstep-web/core/Action/Component';
import Button from 'labstep-web/core/Button';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import React from 'react';
import styles from './styles.module.scss';
import { IChangePasswordProps } from './types';

export const ChangePasswordComponent: React.FC<
  IChangePasswordProps
> = ({ toggled, toggle }) => (
  <div id="change-password">
    <div className={styles.options}>
      <ActionComponent
        type="text"
        text="Change password"
        onClick={toggle}
      />
    </div>
    {toggled && (
      <div>
        <div>
          Password needs to be at least 8 characters in length
        </div>
        <UserChangePasswordContainer>
          {({ status, changePassword }) => (
            <ReusableForm
              onSubmit={(values) => {
                changePassword(values, { onSuccess: toggle });
              }}
              error={status && status.error}
              fields={[
                fieldUserCurrentPassword,
                fieldUserNewSignupPassword,
                fieldUserNewSignupPasswordConfirmation,
              ]}
              submitButton={
                <Button primary loader status={status}>
                  Change Password
                </Button>
              }
            />
          )}
        </UserChangePasswordContainer>
      </div>
    )}
  </div>
);

export const ChangePasswordToggleContainer: React.FC<any> = () => (
  <ToggleContainer>
    {({ toggled, toggle }) => (
      <ChangePasswordComponent toggled={toggled} toggle={toggle} />
    )}
  </ToggleContainer>
);

export default ChangePasswordToggleContainer;
