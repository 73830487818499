/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Live
 * @desc A component to show live experiment workflows
 */

import ExperimentWorkflowActionFinishBulk from 'labstep-web/components/ExperimentWorkflow/Action/Finish/Bulk';
import ExperimentWorkflowList from 'labstep-web/components/ExperimentWorkflow/LiveList';
import SideMenu from 'labstep-web/components/Layout/Menu/Side';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { BulkSelectContainer } from 'labstep-web/containers/BulkSelect';
import BulkSelectContext from 'labstep-web/containers/BulkSelect/context';
import ActionComponent from 'labstep-web/core/Action/Component';
import Icon from 'labstep-web/core/Icon';
import Paginator from 'labstep-web/core/Paginator';
import Popup from 'labstep-web/core/Popup';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import React from 'react';
import { withRouter } from 'react-router';
import styles from './styles.module.scss';
import {
  ILiveExperimentWorkflowProps,
  ILiveExperimentWorkflowReadContainerProps,
} from './types';

export const LiveExperimentWorkflow: React.FC<
  ILiveExperimentWorkflowProps
> = ({ experimentWorkflows, readProps, viewComponent, portal }) => {
  if (experimentWorkflows.length === 0) {
    return null;
  }
  const liveExperimentWorkflows = experimentWorkflows.filter(
    (experimentWorkflow) =>
      !experimentWorkflow.ended_at &&
      !experimentWorkflow.deleted_at &&
      !experimentWorkflow.locked_at,
  );
  const finishedExperimentWorkflowsLength =
    experimentWorkflows.length - liveExperimentWorkflows.length;

  const totalUnfinishedExperimentWorkflowsCount =
    readProps.total - finishedExperimentWorkflowsLength;

  if (totalUnfinishedExperimentWorkflowsCount <= 0) {
    return null;
  }

  return (
    <SideMenu
      name="experiment-live"
      header="Live experiments"
      portal={portal}
      viewComponent={
        viewComponent || (
          <Popup
            inverted
            content="Live Experiments"
            trigger={
              <div className={styles.triggerContainer}>
                <Icon name="check" color="white" fitted />
                <div className={styles.dot}>
                  <div />
                </div>
              </div>
            }
          />
        )
      }
    >
      <BulkSelectContainer
        visibleEntities={liveExperimentWorkflows}
        total={totalUnfinishedExperimentWorkflowsCount}
      >
        <BulkSelectContext.Consumer>
          {(containerProps) => (
            <div className={styles.container}>
              <div className={styles.list}>
                {containerProps.isAtLeastOneSelected && (
                  <div className={styles.actionContainer}>
                    <ActionComponent
                      type="text"
                      text="Select All"
                      onClick={containerProps.selectAllVisible}
                    />
                    <ActionComponent
                      type="text"
                      text={`Selected (${containerProps.selectedCount})`}
                    />
                    <ExperimentWorkflowActionFinishBulk
                      options={{
                        onSuccess: containerProps.unselectAll,
                        toast: true,
                      }}
                      entities={
                        containerProps.selected as ExperimentWorkflow[]
                      }
                      icon={undefined}
                    />
                  </div>
                )}
                <Paginator
                  currentCursor={readProps.currentCursor}
                  nextCursor={readProps.nextCursor}
                  read={readProps.read}
                  status={readProps.status}
                  infinite={false}
                >
                  <ExperimentWorkflowList
                    isSelected={containerProps.isSelected}
                    toggleSelection={containerProps.toggleSelection}
                    experimentWorkflows={liveExperimentWorkflows}
                  />
                </Paginator>
              </div>
            </div>
          )}
        </BulkSelectContext.Consumer>
      </BulkSelectContainer>
    </SideMenu>
  );
};

export const LiveExperimentWorkflowReadContainer: React.FC<
  ILiveExperimentWorkflowReadContainerProps
> = ({ authenticatedUserEntity, viewComponent, portal }) =>
  authenticatedUserEntity.hasGroupNotDeleted ? (
    <ReadOnMountHOC
      type="cursor"
      loading={false}
      entityName={ExperimentWorkflow.entityName}
      params={{
        is_ended: false,
        is_started: true,
        author_id: authenticatedUserEntity.id,

        count: 10,
        is_locked: false,
        serializerGroups:
          'default,experiment_workflow_index,experiment_workflow_live_index',
      }}
      children={({ entities, ...rest }): React.ReactElement | null =>
        rest.total > 0 ? (
          <LiveExperimentWorkflow
            readProps={{ ...rest }}
            experimentWorkflows={entities}
            viewComponent={viewComponent}
            portal={portal}
          />
        ) : null
      }
    />
  ) : null;

// TODO: Refactor
// With router needed to render nested components on location change
export default withRouter(
  withAuthenticatedUserEntity(LiveExperimentWorkflowReadContainer),
);
