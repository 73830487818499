/**
 * Labstep
 *
 * @module screens/OrderRequest/Index/Breadcrumb
 * @desc OrderRequest Index Breadcrumb
 */

import { ICONS } from 'labstep-web/constants/icons';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import ScreensIndexBreadcrumb from 'labstep-web/screens/Breadcrumb';
import React from 'react';

export const OrderRequestIndexBreadcrumb: React.FC = () => (
  <ScreensIndexBreadcrumb
    text={`All ${OrderRequest.getHumanReadableEntityName(
      true,
      true,
    )}`}
    icon={ICONS[OrderRequest.entityName].primary}
  />
);

export default OrderRequestIndexBreadcrumb;
