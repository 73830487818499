/**
 * Labstep
 *
 * @module components/Protocol/Action/Save
 * @desc Save a protocol version
 */

import React from 'react';
import EntityActionCreateModal from 'labstep-web/components/Entity/Action/Create/Modal';
import FormCreate from 'labstep-web/core/Form/Create';
import { FieldType } from 'labstep-web/core/Form/Reusable/types';
import { navigation } from 'labstep-web/services/navigation';
import rules from 'labstep-web/services/validation/rules';
import { IProtocolActionSaveProps } from './types';

const placeholder = 'Short description of the new version (optional)';

export const ProtocolActionSave: React.FC<
  IProtocolActionSaveProps
> = ({ actionComponentProps, protocolCollection }) =>
  !protocolCollection.deleted_at ? (
    <EntityActionCreateModal
      parent={protocolCollection}
      entityName="protocol"
      header="Changelog"
      actionComponentProps={{
        text: 'New Version',
        type: 'text',
        icon: 'copy',
        ...actionComponentProps,
      }}
      content={() => (
        <FormCreate
          entityName="protocol"
          defaultValues={{
            protocol_collection_id: protocolCollection.id,
          }}
          autoFocus
          fields={[
            {
              name: 'changes',
              fieldType: FieldType.TextArea,
              placeholder,
              validation: rules.protocol.changes,
            },
          ]}
          options={{
            onSuccess: () => {
              window.location.href = navigation.get(
                'protocol_collection_show',
                {
                  id: protocolCollection.id,
                },
                true,
              );
            },
          }}
        />
      )}
    />
  ) : null;

export default ProtocolActionSave;
