/**
 * Labstep
 *
 * @module screens/Resource/Show
 * @desc Resource Show Screen
 */

import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import Redirect from 'labstep-web/core/Redirect';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Organization } from 'labstep-web/models/organization.model';
import React from 'react';
import ScreensOrganizationShowCenter from './Center';
import { IScreensOrganizationShowReadContainerProps } from './types';

export const ScreensOrganizationShowReadContainer: React.FC<
  IScreensOrganizationShowReadContainerProps
> = ({ match, authenticatedUserEntity }) => {
  const { identifier } = match.params;
  if (
    !authenticatedUserEntity.userOrganization ||
    authenticatedUserEntity.userOrganization?.organization
      .identifier !== identifier
  ) {
    return <Redirect to="app_homepage" />;
  }
  return (
    <ReadOnMountHOC
      type="entities"
      entityName={Organization.entityName}
      params={{ identifier, get_single: 1 }}
      loading={{ loader: 'full_screen' }}
      children={({ entity }) => (
        <ScreensOrganizationShowCenter organization={entity} />
      )}
    />
  );
};

export default withAuthenticatedUserEntity(
  ScreensOrganizationShowReadContainer,
);
