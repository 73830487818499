/**
 * Labstep
 *
 * @module components/Entity/Sortable/List
 * @desc Generic component for sorting entities
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import { ResetPositionContainer } from 'labstep-web/containers/Position';
import React from 'react';
import {
  IEntitySortableContainerProps,
  IEntitySortableProps,
  IEntitySortableState,
} from './types';

export class EntitySortableComponent extends React.Component<
  IEntitySortableProps,
  IEntitySortableState
> {
  static defaultProps = {
    sort: 'desc',
  };

  constructor(props) {
    super(props);
    this.state = { disabled: false };
    this.setDisabled = this.setDisabled.bind(this);
    this.setEnabled = this.setEnabled.bind(this);
    this.handleOnSuccess = this.handleOnSuccess.bind(this);
  }

  handleOnSuccess(positionSent, { response }, items) {
    this.setEnabled();
    const { entityName, resetPosition } = this.props;
    const { position } =
      response.entities[entityName][response.result];
    if (positionSent !== position) {
      resetPosition(
        entityName,
        items.map((item, index) => ({
          id: item.id,
          position: index + 1,
        })),
      );
    }
  }

  setDisabled() {
    this.setState({ disabled: true });
  }

  setEnabled() {
    this.setState({ disabled: false });
  }

  render() {
    const { entityName, render } = this.props;
    const { disabled } = this.state;

    return (
      <EntityUpdateContainer entityName={entityName}>
        {({ update }) =>
          render({
            disabled: disabled || !!this.props.disabled,
            onSortEnd: ({ position, id, items }) => {
              this.setDisabled();
              update(
                { position, id },
                {
                  onSuccess: (response) =>
                    this.handleOnSuccess(position, response, items),
                  onFail: this.setEnabled,
                },
              );
            },
          })
        }
      </EntityUpdateContainer>
    );
  }
}

const EntitySortableContainer: React.FC<
  IEntitySortableContainerProps
> = (props) => (
  <ResetPositionContainer>
    {({ resetPosition }) => (
      <EntitySortableComponent
        resetPosition={resetPosition}
        {...props}
      />
    )}
  </ResetPositionContainer>
);

export default EntitySortableContainer;
