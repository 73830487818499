/**
 * Labstep
 *
 * @module components/Entity/Action/Update/SearchSelect
 * @desc Entity Update action with SearchSelect
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import SearchSelect from 'labstep-web/core/Select/Search';
import { callIf } from 'labstep-web/services/react.service';
import React from 'react';
import { IEntityActionUpdateSearchSelectProps } from './types';

export const EntityActionUpdateSearchSelect: React.FC<
  IEntityActionUpdateSearchSelectProps
> = ({ entity, getBody, options, optimistic, onUpdate, ...rest }) => (
  <EntityUpdateContainer
    entityName={entity.entityName}
    id={entity.idAttr}
    optimistic={optimistic}
  >
    {({ update }): React.ReactElement => (
      <SearchSelect
        onChange={(value): void => {
          update(getBody(value), options);
          callIf(onUpdate, value);
        }}
        {...rest}
      />
    )}
  </EntityUpdateContainer>
);

export default EntityActionUpdateSearchSelect;
