/**
 * Labstep
 *
 * @module components/EntityUserRoleRequirement/Action/Menu
 * @desc Action Menu for group
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import ActionsMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import {
  Action,
  IEntityUserRoleRequirementActionMenuProps,
} from './types';

const EntityUserRoleRequirementActionMenu: React.FC<
  IEntityUserRoleRequirementActionMenuProps
> = ({ entityUserRoleRequirement, type, actions = [] }) => {
  return (
    <ActionsMenu type={type}>
      {actions.includes(Action.delete) && (
        <EntityActionDelete entity={entityUserRoleRequirement} />
      )}
    </ActionsMenu>
  );
};

EntityUserRoleRequirementActionMenu.defaultProps = {
  actions: [Action.delete],
  type: 'icon',
};

export default EntityUserRoleRequirementActionMenu;
