/**
 * Labstep
 *
 * @module core/Select/Search/Modal/EmptyState
 * @desc Search select modal empty state component
 */

import React from 'react';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { IActionComponentProps } from 'labstep-web/core/Action/Component/types';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { ISearchSelectModalEmptyStateProps } from './types';

const actionComponentProps = {
  type: 'button',
} as IActionComponentProps;

export const SearchSelectModalEmptyState: React.FC<
  ISearchSelectModalEmptyStateProps
> = ({ entityName, creatable, body, options, action }) => (
  <EmptyState
    src="/img/empty-state/protocols.svg"
    title={`No ${getHumanReadableEntityName(
      entityName,
      true,
    )} created yet`}
    action={
      creatable &&
      (action || (
        <EntityActionCreate
          entityName={entityName}
          body={body}
          options={options}
          actionComponentProps={actionComponentProps}
        />
      ))
    }
  />
);

export default SearchSelectModalEmptyState;
