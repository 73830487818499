/**
 * Labstep
 *
 * @module models/user-mention
 * @desc Typescript export class for UserMention
 */

import { Type } from 'class-transformer';
import { User } from 'labstep-web/models/user.model';
import { Entity } from 'labstep-web/models/entity.model';

export class UserMention extends Entity {
  static readonly entityName = 'user_mention';

  get entityName(): string {
    return UserMention.entityName;
  }

  constructor(data: Partial<UserMention> = {}) {
    super();
    Object.assign(this, data);
  }

  id: number;

  @Type(() => User)
  user: User;
}
