/**
 * Labstep
 *
 * @module components/Filter/Template
 * @desc Template filter
 */

import { ICONS } from 'labstep-web/constants/icons';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import SearchSelect from 'labstep-web/core/Select/Search';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { PostFilterTemplate } from 'labstep-web/services/postFilter/filters/template';
import React from 'react';
import { FilterTemplateProps } from './types';

const FilterTemplate: React.FC<FilterTemplateProps> = ({
  entityName,
  searchParams,
  setParams,
  addPostFilter,
  actionComponentProps,
}) => {
  const { activeGroup } = useActiveGroup();
  if (!activeGroup) {
    return null;
  }
  return (
    <Modal
      header={`Filter by ${getHumanReadableEntityName(
        entityName,
        false,
        true,
        true,
      )}`}
      content={({ toggleModal }) => {
        const component = (value?: any) => (
          <SearchSelect
            autoFocus
            entityName={entityName}
            onChange={(option) => {
              if (addPostFilter) {
                addPostFilter(
                  PostFilterTemplate.getNode([option?.guid]),
                  true,
                  1,
                );
              } else {
                setParams({
                  template_id: option.id,
                });
              }
              toggleModal();
            }}
            params={{
              is_template: 1,
              group_id: activeGroup.id,
            }}
            value={value}
          />
        );
        return searchParams.template_id ? (
          <EntityReadEntityContainer
            entityName={entityName}
            id={searchParams?.template_id}
          >
            {({ entity }) => component(entity)}
          </EntityReadEntityContainer>
        ) : (
          component()
        );
      }}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon={ICONS[entityName].primary}
          text={getHumanReadableEntityName(
            entityName,
            false,
            true,
            true,
          )}
          onClick={toggleModal}
          {...actionComponentProps}
        />
      )}
    />
  );
};

export default FilterTemplate;
