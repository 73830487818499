/**
 * Labstep
 *
 * @module components/Entity/ActionRemove
 * @desc Remove action
 */

import React from 'react';
import EntityActionToggle from '../Toggle';
import { IEntityActionRemoveProps } from './types';

const EntityActionRemove: React.FC<IEntityActionRemoveProps> = (
  props,
) => <EntityActionToggle action="remove" {...props} />;

export default EntityActionRemove;
