/**
 * Labstep
 *
 * @module components/Metadata/Action/Menu
 * @desc Action Menu for metadata
 */

import React from 'react';
import ActionMenu from 'labstep-web/core/Action/Menu';
import MetadataActionDelete from 'labstep-web/components/Metadata/Action/Delete';
import MetadataActionEdit from 'labstep-web/components/Metadata/Action/Edit';
import { IMetadataActionMenuProps } from './types';

export const MetadataActionMenu: React.FC<
  IMetadataActionMenuProps
> = ({ metadata, type, actions = [], entity, ...rest }) => (
  <ActionMenu type={type} {...rest}>
    {actions.includes('edit') && (
      <MetadataActionEdit
        actionComponentProps={{
          type: 'option',
          text: 'Edit',
          icon: 'pencil',
        }}
        entity={entity}
        metadata={metadata}
      />
    )}
    {actions.includes('delete') && (
      <MetadataActionDelete entity={entity} metadata={metadata} />
    )}
  </ActionMenu>
);

MetadataActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default MetadataActionMenu;
