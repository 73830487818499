/**
 * Labstep
 *
 * @module core/QrReader
 * @desc QrReader component
 */

import React from 'react';
import Loadable from 'labstep-web/core/Loadable';
import { IQrReaderProps } from './types';

export const QrReaderComponent = React.lazy(
  () => import('./component'),
);

const QrReader: React.FC<IQrReaderProps> = (props) => (
  <Loadable>
    <QrReaderComponent {...props} />
  </Loadable>
);

export default QrReader;
