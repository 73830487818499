/**
 * Labstep
 *
 * @module components/File/Filter/Extension/Active
 * @desc Active filter component for extension active
 */

import React from 'react';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { IFileFilterStatusActiveProps } from './types';

const FileFilterStatusActive: React.FC<
  IFileFilterStatusActiveProps
> = ({ clearParams, searchParams }) =>
  'extension' in searchParams && (
    <RemoveLabel
      name={searchParams.extension}
      onRemoveClick={
        clearParams && (() => clearParams(['extension']))
      }
    />
  );

export default FileFilterStatusActive;
