/**
 * Labstep
 *
 * @module prosemirror/extensions/toolbar/plugins
 * @desc Create Toolbar plugins
 */

import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import MenuText from 'labstep-web/prosemirror/components/Menu/Text';
import {
  TCreateReactPluginComponent,
  createReactPluginComponent,
} from 'labstep-web/prosemirror/components/ReactPluginComponent';
import ToolbarLeft from 'labstep-web/prosemirror/components/Toolbar/Left';
import { ToolbarMain } from 'labstep-web/prosemirror/components/Toolbar/Main';
import { Plugin } from 'prosemirror-state';
import { isMobile } from 'react-device-detect';

const createReactPlugin = ({
  entity,
  experimentWorkflow,
  component,
  onCreatePortal,
  element,
  alwaysShow,
}: Pick<
  TCreateReactPluginComponent,
  Exclude<keyof TCreateReactPluginComponent, 'view'>
>): Plugin => {
  return new Plugin({
    view(editorView) {
      return createReactPluginComponent({
        view: editorView,
        entity,
        experimentWorkflow,
        component,
        onCreatePortal,
        element,
        alwaysShow,
      });
    },
  });
};

const createToolbarPlugins = (
  entity: Experiment | Protocol,
  experimentWorkflow: ExperimentWorkflow,
  handleCreatePortal: TCreateReactPluginComponent['onCreatePortal'],
) => {
  let components = [MenuText];
  const toolbarMainElement = document.getElementById(
    `toolbar-main-${entity.id}`,
  );
  if (toolbarMainElement) {
    components = [...components, ToolbarMain];
  }
  if (!isMobile && !experimentWorkflow) {
    components = [...components, ToolbarLeft];
  }
  return components.map((component, index) =>
    createReactPlugin({
      entity,
      experimentWorkflow,
      component,
      onCreatePortal: handleCreatePortal,
      element: component === ToolbarMain && toolbarMainElement,
      // For MenuText always show so that we can display comment option
      alwaysShow: index === 0,
    }),
  );
};

export default createToolbarPlugins;
