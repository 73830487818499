/**
 * Labstep
 *
 * @module core/Form/Reusable
 * @desc Generic Form implementation to abstract all types of fields
 */

import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema, object } from 'yup';
import Form from 'labstep-web/core/Form';
import { useForm } from 'react-hook-form';
import Message from 'labstep-web/core/Form/ReactForm/Errors/Message';
import styles from './styles.module.scss';
import {
  IFieldWithoutActionProps,
  IReusableFormProps,
  IValues,
  preSubmitType,
} from './types';
import { flattenDefaultValues, getPreSubmit } from './utils';
import FormReusableActions from './Actions';
import FormReusableFields from './Fields';

const ReusableForm: React.FC<IReusableFormProps> = (props) => {
  const {
    fields,
    defaultValues,
    onSubmit,
    error,
    onFormChange,
    blurToggle,
    preSubmit: customPreSubmit,
    inline,
    status,
    autoFocus,
    cancelButton,
    submitButton,
    submitButtonText,
    color,
    onCancel,
    formRef,
  } = props;

  const schema: AnyObjectSchema = object(
    fields.reduce((acc, field) => {
      if (field.fieldType === 'action') {
        return acc;
      }
      return { ...acc, [field.name as string]: field.validation };
    }, {}),
  );

  const {
    handleSubmit,
    formState,
    getValues,
    setValue,
    control,
    reset,
    watch,
    register,
    unregister,
  } = useForm({
    defaultValues: flattenDefaultValues(defaultValues),
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const defaultPreSubmit = getPreSubmit(
    fields as IFieldWithoutActionProps[],
  );
  const preSubmit: preSubmitType = customPreSubmit
    ? (values): IValues => customPreSubmit(defaultPreSubmit(values))
    : (values): IValues => defaultPreSubmit(values);

  const watchAllFields = watch();

  React.useEffect(() => {
    const fieldNames = fields
      .filter((f) => f.fieldType !== 'action')
      .map((f) => (f as IFieldWithoutActionProps).name);
    const registerredFieldNames = Object.keys(control._fields);

    //  Register fields that are not registered yet and unregister fields that are not in the form anymore
    fieldNames.forEach((fieldName) => {
      if (!registerredFieldNames.includes(fieldName)) {
        register(fieldName);
      }
    });
    registerredFieldNames.forEach((fieldName) => {
      if (!fieldNames.includes(fieldName)) {
        unregister(fieldName);
      }
    });
  }, [fields]);

  React.useEffect(() => {
    if (onFormChange) {
      onFormChange(preSubmit(watchAllFields));
    }
  }, [watchAllFields]);

  const onSubmitFinal = handleSubmit((values): void => {
    onSubmit(preSubmit(values), reset);
  });

  React.useImperativeHandle(formRef, () => ({
    submit: onSubmitFinal,
  }));

  const values = getValues();

  const fieldsComponent = (
    <FormReusableFields
      values={values}
      submitForm={onSubmitFinal}
      fields={fields}
      control={control}
      disabled={!!status?.isFetching}
      autoFocus={autoFocus}
      reset={reset}
      blurToggle={blurToggle}
      errors={errors}
    />
  );

  const errorMessage = !!error && <Message message={error} />;

  return (
    <Form className={styles.form} onSubmit={onSubmitFinal}>
      {inline ? (
        <>
          <Form.Group inline>{fieldsComponent}</Form.Group>
          {errorMessage}
        </>
      ) : (
        <>
          {fieldsComponent}
          <div className={styles.message}>{errorMessage}</div>
          <FormReusableActions
            cancelButton={cancelButton}
            values={values}
            submitButton={submitButton}
            submitButtonText={submitButtonText}
            status={status}
            onCancel={onCancel}
            color={color}
            submitForm={onSubmitFinal}
            setValue={setValue}
          />
        </>
      )}
    </Form>
  );
};

ReusableForm.defaultProps = {
  defaultValues: {},
};

export default ReusableForm;
