/**
 * Labstep
 */

export default {
  '&multiLine': {
    // control: {
    //   minHeight: 63,
    // },
    backgroundColor: 'white',
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: '1px solid #eff2f7',
      borderRadius: '5px',
    },
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: ' #005bcc',
        color: 'white',
      },
    },
  },
};
