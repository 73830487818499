/**
 * Labstep
 *
 * @module components/DataTable/Action/Create
 * @desc Create a comment table
 */

import TableModal from 'labstep-web/components/Table/Modal';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import { withUi } from 'labstep-web/containers/Ui';
import ActionComponent from 'labstep-web/core/Action/Component';
import { Comment } from 'labstep-web/models/comment.model';
import { generateTableData } from 'labstep-web/services/table.service';
import React from 'react';
import { IProps, IState } from './types';

export class DataTableActionCreate extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.onCommentCreateSuccess =
      this.onCommentCreateSuccess.bind(this);
    this.resetCommentId = this.resetCommentId.bind(this);
    this.state = {
      commentId: null,
    };
  }

  onCommentCreateSuccess(e) {
    const commentId: number = e.response.result;
    this.setState({
      commentId,
    });
  }

  resetCommentId() {
    this.setState({
      commentId: null,
    });
  }

  render() {
    const { create, setActiveEntity } = this.props;
    const { commentId } = this.state;

    if (commentId === null) {
      return (
        <ActionComponent
          onClick={() => {
            const data = generateTableData();
            const table: any = {
              name: 'Untitled Table',
              data,
            };

            const comment: any = {
              body: 'DataTable',
              data_table: table,
            };
            create(comment, {
              onSuccess: this.onCommentCreateSuccess,
            });
          }}
          type="option"
          icon="table"
          text="Table"
        />
      );
    }

    return (
      <EntityReadEntityContainer
        entityName={Comment.entityName}
        id={commentId}
      >
        {({ entity: comment }) => (
          <TableModal
            viewComponent={null}
            initialModalState
            onClose={this.resetCommentId}
            table={comment.data_table}
            allowLocking
            onDone={() => {
              setActiveEntity(Comment.entityName, commentId);
              this.resetCommentId();
            }}
          />
        )}
      </EntityReadEntityContainer>
    );
  }
}

export default withUi(DataTableActionCreate);
