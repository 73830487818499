/**
 * Labstep
 *
 * @module services/chemical
 * @desc Chemical calculations based on chemical fields
 */

import * as math from 'mathjs';
import {
  Chemical,
  getDensity,
} from 'labstep-web/models/chemical.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { getBase } from 'labstep-web/constants/unit';
import {
  formatAmount,
  modifyAmount,
  scaleAmount,
} from './amount-unit.service';

/** Check if empty params in scope object */
const scopeHasEmptyParams = (
  scope: NonNullable<Parameters<typeof math.evaluate>[1]>,
): boolean => !!Object.values(scope).some((x) => !x);

/** Mimic backend behaviour of scaling at division */
const evalWithScale = (
  expr: math.MathExpression,
  scope?: Parameters<typeof math.evaluate>[1],
): math.BigNumber => scaleAmount(math.evaluate(expr, scope));

/**
 * Calculate theoretical mass in g
 * @param chemical Chemical
 * @returns Theoretical mass
 */
export const calcTheoreticalMass = (
  chemical: Chemical,
): string | null => {
  const scope = {
    mW: chemical.properties.MolecularWeight,
    purity: chemical.purity,
    molarAmount: chemical.molar_amount,
  };

  if (scopeHasEmptyParams(scope)) {
    return null;
  }

  const value = math.evaluate('mW * molarAmount / purity', scope);
  return formatAmount(value, true);
};

/**
 * Calculate yield
 * @param chemical Chemical
 * @returns Yield
 */
export const calcYield = (chemical: Chemical): string | null => {
  const scope: {
    actualMass: string | null;
    purity: number | null;
    theoMass: string | null;
    density?: string | null;
  } = {
    actualMass: chemical.protocol_value.amount,
    purity: chemical.purity,
    theoMass: calcTheoreticalMass(chemical),
  };

  if (scopeHasEmptyParams(scope)) {
    return null;
  }

  const unitBase = getBase(chemical.protocol_value.unit);

  if (unitBase === 'g') {
    return modifyAmount(
      evalWithScale('actualMass / theoMass', scope),
      chemical.protocol_value.unit,
      'g',
    );
  }

  scope.density = getDensity(chemical);
  if (unitBase === 'l' && scope.density) {
    return modifyAmount(
      evalWithScale('actualMass * density * 1000 / theoMass', scope),
      chemical.protocol_value.unit,
      'l',
    );
  }

  return null;
};

/**
 * Calculate amount of non-limiting chemical
 * @param chemical Chemical
 * @param protocolValue ProtocolValue
 * @param limitingChemical Chemical
 * @returns Amount
 */
export const calcAmountNonLimiting = (
  chemical: Chemical,
  protocolValue: ProtocolValue,
  limitingChemical: Chemical,
): string | null => {
  const scope: {
    molecularWeight: string | null;
    purity: number | null;
    equivalence: number | string | null;
    limitingChemicalMolarAmount: number | null;
    limitingChemicalEquivalence: number | string | null;
    density?: string | null;
  } = {
    molecularWeight: chemical.properties?.MolecularWeight,
    purity: chemical.purity,
    equivalence: chemical.equivalence,
    limitingChemicalMolarAmount: limitingChemical.molar_amount,
    limitingChemicalEquivalence: limitingChemical.equivalence,
  };

  if (scopeHasEmptyParams(scope)) {
    return null;
  }

  const unitBase = getBase(protocolValue.unit);

  if (unitBase === 'g') {
    return modifyAmount(
      evalWithScale(
        'molecularWeight * limitingChemicalMolarAmount * equivalence / ( limitingChemicalEquivalence * purity )',
        scope,
      ),
      unitBase,
      protocolValue.unit,
    );
  }

  scope.density = getDensity(chemical);
  if (unitBase === 'l' && scope.density) {
    return modifyAmount(
      evalWithScale(
        'molecularWeight * limitingChemicalMolarAmount * equivalence / ( limitingChemicalEquivalence * purity * density * 1000 )',
        scope,
      ),
      unitBase,
      protocolValue.unit,
    );
  }

  return null;
};

/**
 * Calculate amount of limiting chemical
 * @param chemical Chemical
 * @param protocolValue ProtocolValue
 * @returns Amount
 */
export const calcAmountLimiting = (
  chemical: Chemical,
  protocolValue: ProtocolValue,
): string | null => {
  const scope: {
    molecularWeight: string | null;
    purity: number | null;
    molarAmount: number | null;
    density?: string | null;
  } = {
    molecularWeight: chemical.properties?.MolecularWeight,
    purity: chemical.purity,
    molarAmount: chemical.molar_amount,
  };

  if (scopeHasEmptyParams(scope)) {
    return null;
  }

  const unitBase = getBase(protocolValue.unit);

  if (unitBase === 'g') {
    return modifyAmount(
      evalWithScale('molecularWeight * molarAmount / purity', scope),
      'g',
      protocolValue.unit,
    );
  }

  scope.density = getDensity(chemical);
  if (unitBase === 'l' && scope.density) {
    return modifyAmount(
      evalWithScale(
        'molecularWeight * molarAmount / ( purity * density * 1000 )',
        scope,
      ),
      'l',
      protocolValue.unit,
    );
  }

  return null;
};

/**
 * Calculate amount
 * @param chemical Chemical
 * @param protocolValue ProtocolValue
 * @param limitingChemical Chemical
 * @returns Amount
 */
export const calcAmount = (
  chemical: Chemical,
  protocolValue: ProtocolValue,
  limitingChemical: Chemical,
): string | null => {
  if (chemical.is_limiting) {
    return calcAmountLimiting(chemical, protocolValue);
  }

  return calcAmountNonLimiting(
    chemical,
    protocolValue,
    limitingChemical,
  );
};

/**
 * Calculate molar amount of limiting chemical
 * @param chemical Chemical
 * @param protocolValue ProtocolValue
 * @returns Molar amount
 */
export const calcMolarAmountLimiting = (
  chemical: Chemical,
  protocolValue: ProtocolValue,
): string | null => {
  const scope: {
    amount: string | null;
    purity: number | null;
    molecularWeight: string | null;
    density?: string | null;
  } = {
    amount: protocolValue.amount,
    purity: chemical.purity,
    molecularWeight: chemical.properties?.MolecularWeight,
  };

  if (scopeHasEmptyParams(scope)) {
    return null;
  }

  const unitBase = getBase(protocolValue.unit);

  if (unitBase === 'g') {
    return modifyAmount(
      evalWithScale('amount * purity / molecularWeight', scope),
      protocolValue.unit,
      'g',
    );
  }

  scope.density = getDensity(chemical);
  if (unitBase === 'l' && scope.density) {
    return modifyAmount(
      evalWithScale(
        'amount * density * 1000 * purity / molecularWeight',
        scope,
      ),
      protocolValue.unit,
      'l',
    );
  }

  return null;
};

/**
 * Calculate molar amount of non-limiting chemical
 * @param chemical Chemical
 * @param limitingChemical Chemical
 * @returns Molar amount
 */
export const calcMolarAmountNonLimiting = (
  chemical: Chemical,
  limitingChemical: Chemical,
): string | null => {
  const scope = {
    limitingChemicalMolarAmount: limitingChemical.molar_amount,
    equivalence: chemical.equivalence,
    limitingChemicalEquivalence: limitingChemical.equivalence,
  };

  if (scopeHasEmptyParams(scope)) {
    return null;
  }

  return formatAmount(
    evalWithScale(
      'limitingChemicalMolarAmount * equivalence / limitingChemicalEquivalence',
      scope,
    ),
  );
};

/**
 * Calculate molar amount
 * @param chemical Chemical
 * @param protocolValue ProtocolValue
 * @param limitingChemical Chemical
 * @returns Amount
 */
export const calcMolarAmount = (
  chemical: Chemical,
  protocolValue: ProtocolValue,
  limitingChemical: Chemical,
): string | null => {
  if (chemical.is_limiting) {
    return calcMolarAmountLimiting(chemical, protocolValue);
  }

  return calcMolarAmountNonLimiting(chemical, limitingChemical);
};
