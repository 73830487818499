/**
 * Labstep
 *
 * @module prosemirror/extensions/external-comm
 * @desc Plugin to allow for external communication of plugins, node views, commands to react props
 */

import { Plugin, PluginKey } from 'prosemirror-state';

export const reactPropsKey = new PluginKey('reactProps');

export const reactProps = (initialProps: any): Plugin => {
  return new Plugin({
    key: reactPropsKey,
    state: {
      init: () => initialProps,
      apply: (tr, prev) => tr.getMeta(reactPropsKey) || prev,
    },
  });
};
