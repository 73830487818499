/**
 * Labstep
 */

import { Resource } from 'labstep-web/models/resource.model';

export const getTitle = (resource: Resource) => {
  let title = 'No Item Template';
  if (resource.hasTemplateWithNonDeletedResourceItemTemplate) {
    title = `Using ${Resource.getHumanReadableEntityName(
      false,
      true,
      true,
    )} Template`;
  }
  return title;
};

export const getExplanation = (resource: Resource) => {
  let explanation =
    'Item templates allow each new item to be initialised with a default location, amount and additional metadata fields.';

  if (!resource.is_template) {
    explanation += ` Either use a custom template for this ${Resource.getHumanReadableEntityName(
      false,
      false,
    )} or add one to the ${Resource.getHumanReadableEntityName(
      false,
      false,
      true,
    )}.`;
  }

  if (resource.hasTemplateWithNonDeletedResourceItemTemplate) {
    explanation = `The item template of the ${Resource.getHumanReadableEntityName(
      false,
      false,
      true,
    )} will be used to initialise each new item with a default location, amount and metadata fields. To use a custom template for this resource instead click ‘Enable Custom Template’.`;
  }
  return explanation;
};
