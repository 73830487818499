/**
 * Labstep
 *
 * @module components/ProtocolTimer/Action/Delete
 * @desc Action to delete
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { IProtocolTimerDeleteActionProps } from './types';

export const ProtocolTimerDeleteAction: React.FC<
  IProtocolTimerDeleteActionProps
> = ({ protocolTimer, ...rest }) => (
  <EntityActionDelete {...rest} entity={protocolTimer} />
);

export default ProtocolTimerDeleteAction;
