/**
 * Labstep
 *
 * @module components/Tag/Card/EmptyState
 * @desc Empty state for tag
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import TagActionCreate from 'labstep-web/components/Tag/Action/Create';
import { ITagEmptyStateProps } from './types';

export const TagEmptyState: React.FC<ITagEmptyStateProps> = ({
  type,
}) => (
  <EmptyState
    src="/img/empty-state/files.svg"
    title="No Tags"
    explanation="Use tags to organise the content within your workspace. Tags can be used to differentiate projects, custom statuses, or research areas. Filter by tags when searching for content. "
    action={<TagActionCreate defaultType={type} />}
  />
);

export default TagEmptyState;
