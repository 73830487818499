/**
 * Labstep
 *
 * @module components/ExperimentWorkflowTemplate/Warning
 * @desc Display a warning for the user that they are viewing / editing a template.
 */

import React from 'react';
import Message from 'labstep-web/core/Message';
import { IExperimentWorkflowTemplateWarningProps } from './types';

const ExperimentWorkflowTemplateWarning: React.FC<
  IExperimentWorkflowTemplateWarningProps
> = ({ experimentWorkflow }) =>
  experimentWorkflow?.is_template ? (
    <Message color="yellow">This is a template.</Message>
  ) : null;

export default ExperimentWorkflowTemplateWarning;
