/**
 * Labstep
 *
 * @module components/Device/ListOrTable
 * @desc List or table of devices
 */

import React from 'react';
import DeviceList from 'labstep-web/components/Device/List';
import MasterIndexCenterListOrTable from 'labstep-web/screens/Master/Index/Center/ListOrTable';
import DeviceDataGrid from '../DataGrid/Container';
import { IDeviceListOrTableProps } from './types';
import { actions } from './constants';

export const DeviceListOrTable: React.FC<IDeviceListOrTableProps> = ({
  devices,
  ...rest
}) => (
  <MasterIndexCenterListOrTable
    tableComponent={<DeviceDataGrid devices={devices} {...rest} />}
    listComponent={<DeviceList devices={devices} actions={actions} />}
  />
);

export default DeviceListOrTable;
