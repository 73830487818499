/**
 * Labstep
 *
 * @module prosemirror/extensions/referencing/commands
 * @desc Commands for referencing plugin
 */

import { Entity } from 'labstep-web/models/entity.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { Resource } from 'labstep-web/models/resource.model';
import referencingPlugin, {
  referencingPluginKey,
} from 'labstep-web/prosemirror/extensions/referencing/plugin';
import { Category } from 'labstep-web/prosemirror/extensions/referencing/plugin/types';
import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import { ProsemirrorReferencingService } from 'labstep-web/services/prosemirror-referencing.service';
import { EditorState } from 'prosemirror-state';
import {
  SET_CATEGORIES,
  selectCategory,
  selectCreatableItem,
  selectItem,
  selectNext,
  selectPrevious,
  setCreatableItems,
  setExternalItems,
  setIsOpen,
  setItems,
  submitCategory,
  submitItem,
} from './actions';
import { getIsOpen, getSelectedCategory } from './selectors';

export type IReferencingExtensionProps = (
  state: EditorState,
  dispatch: NonNullable<IStateDispatchProps['dispatch']>,
) => boolean;

export const handleTrigger: IReferencingExtensionProps = (
  state,
  dispatch,
) => {
  if (!getIsOpen(state)) {
    handleSetIsOpen(state, dispatch, true);
  }
  return false;
};

export const handleArrowUp: IReferencingExtensionProps = (
  state,
  dispatch,
) => {
  if (getIsOpen(state)) {
    dispatch(state.tr.setMeta(referencingPlugin, selectPrevious()));
    return true;
  }
  return false;
};

export const handleArrowDown: IReferencingExtensionProps = (
  state,
  dispatch,
) => {
  if (getIsOpen(state)) {
    dispatch(state.tr.setMeta(referencingPlugin, selectNext()));
    return true;
  }
  return false;
};

export const handleEnter: IReferencingExtensionProps = (
  state,
  dispatch,
) => {
  if (getIsOpen(state)) {
    const { index, availableItems, availableCategories, token } =
      state.plugins
        .find((p) => p.spec.key === referencingPluginKey)!
        .getState(state);

    if (getSelectedCategory(state)) {
      const { tr } = state;
      tr.setMeta(referencingPlugin, submitItem());

      if (index < availableItems.length) {
        ProsemirrorReferencingService.replaceTokenWithReferenceNode(
          state,
          dispatch,
          index,
        );
      } else {
        ProsemirrorReferencingService.createAndReplaceTokenWithReferenceNode(
          state,
          dispatch,
          index,
        );
      }
    } else {
      if (availableCategories.length === 0) {
        return true;
      }
      const { tr } = state;

      tr.setMeta(referencingPlugin, submitCategory());
      dispatch(tr.deleteRange(token.from + 1, token.to));
    }

    return true;
  }
  return false;
};

export const handleSetIsOpen = (
  state: EditorState,
  dispatch: NonNullable<IStateDispatchProps['dispatch']>,
  isOpen: boolean,
) => {
  dispatch(state.tr.setMeta(referencingPluginKey, setIsOpen(isOpen)));
};

export const handleEscape: IReferencingExtensionProps = (
  state,
  dispatch,
) => {
  if (getIsOpen(state)) {
    handleSetIsOpen(state, dispatch, false);

    return true;
  }

  return false;
};

export const handleSelectItem = (
  state: EditorState,
  dispatch: NonNullable<IStateDispatchProps['dispatch']>,
  index: number,
) => {
  state.tr.setMeta(referencingPlugin, selectItem(index));

  ProsemirrorReferencingService.replaceTokenWithReferenceNode(
    state,
    dispatch,
    index,
  );

  return true;
};

export const handleCreateItem = (
  state: EditorState,
  dispatch: NonNullable<IStateDispatchProps['dispatch']>,
  index: number,
) => {
  state.tr.setMeta(referencingPlugin, selectCreatableItem(index));

  ProsemirrorReferencingService.createAndReplaceTokenWithReferenceNode(
    state,
    dispatch,
    index,
  );

  return true;
};

export const handleSelectCategory = (
  state: EditorState,
  dispatch: NonNullable<IStateDispatchProps['dispatch']>,
  index: number,
) => {
  const { token } = state.plugins
    .find((p) => p.spec.key === referencingPluginKey)!
    .getState(state);
  dispatch(
    state.tr
      .setMeta(referencingPlugin, selectCategory(index))
      .deleteRange(token.from + 1, token.to),
  );

  return true;
};

export const handleSetCategories = (
  state: EditorState,
  dispatch: NonNullable<IStateDispatchProps['dispatch']>,
  entity: Entity,
) => {
  let categories: Category[] = [
    {
      label: 'Inventory',
      value: 'protocol_values',
      creatableEntityName: Resource.entityName,
    },
    { label: 'Data', value: 'metadatas' },
    {
      label: 'Device',
      value: 'protocol_devices',
      creatableEntityName: 'device',
    },
  ];
  if (entity instanceof Experiment) {
    if (!entity.is_root) {
      categories = [
        ...categories,
        { label: 'Timer', value: 'protocol_timers' },
        { label: 'Step', value: 'protocol_steps' },
        { label: 'Table', value: 'protocol_tables' },
      ];
    } else {
      categories = [
        ...categories,
        {
          label: 'Protocol',
          value: 'experiments',
          creatableEntityName: ProtocolCollection.entityName,
        },
        { label: 'Table', value: 'protocol_tables' },
      ];
    }
  } else {
    categories = [
      ...categories,
      { label: 'Timer', value: 'protocol_timers' },
      { label: 'Step', value: 'protocol_steps' },
      { label: 'Table', value: 'protocol_tables' },
    ];
  }
  dispatch(
    state.tr.setMeta(referencingPluginKey, {
      type: SET_CATEGORIES,
      categories,
    }),
  );
};

export const handleSetItems = (
  state: EditorState,
  dispatch: NonNullable<IStateDispatchProps['dispatch']>,
  items: Entity[],
) => {
  if (getSelectedCategory(state)) {
    dispatch(state.tr.setMeta(referencingPluginKey, setItems(items)));
  }
};

export const handleSetExternalItems = (
  state: EditorState,
  dispatch: NonNullable<IStateDispatchProps['dispatch']>,
  items: Entity[],
) => {
  if (getSelectedCategory(state)) {
    dispatch(
      state.tr.setMeta(referencingPluginKey, setExternalItems(items)),
    );
  }
};

export const handleSetCreatableItems = (
  state: EditorState,
  dispatch: NonNullable<IStateDispatchProps['dispatch']>,
  items: Entity[],
) => {
  if (getSelectedCategory(state)) {
    dispatch(
      state.tr.setMeta(
        referencingPluginKey,
        setCreatableItems(items),
      ),
    );
  }
};
