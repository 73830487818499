/**
 * Labstep
 *
 * @module config/mime-types
 * @desc mimeTypes config file
 */

/**
 * List of supported image mime types
 *
 * @see https://support.imgix.com/hc/en-us/articles/204280985-Supported-image-formats
 */
export const allowedImageExtensions = [
  '.bmp',
  '.gif',
  '.jpeg',
  '.jpg',
  '.png',
  '.svg',
  '.tif',
  '.tiff',
  '.heic',
  '.heif',
  '.webp',
];

/**
 * List of supported SnapGene data mime types
 */
export const allowedSnapgeneExtensions = [
  '.dna',
  '.gb',
  '.gbk',
  '.fa',
  '.geneious',
];
