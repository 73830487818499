/**
 * Labstep
 *
 * @module state/selectors/jupyter
 * @desc Selectors for Jupyter OAuth
 */

import {
  createSelector,
  createSlice,
  type SliceCaseReducers,
} from '@reduxjs/toolkit';
import { JupyterAuthorizeState } from './types';

const initialState: JupyterAuthorizeState = null;

export const jupyterAuthorizeSlice = createSlice<
  JupyterAuthorizeState,
  SliceCaseReducers<JupyterAuthorizeState>
>({
  name: 'jupyterAuthorize',
  initialState,
  reducers: {
    authorize: {
      reducer(state) {
        return state;
      },
      prepare(payload) {
        return {
          type: `JUPYTER_OAUTH_AUTHORIZE_REQUEST`,
          payload: {},
          meta: {
            params: {
              response_type: payload.responseType,
              redirect_uri: payload.redirectURI,
              client_id: payload.clientId,
              state: payload.state,
            },
          },
        };
      },
    },
  },
});

export const selectJupyterAuthorizeStatus = createSelector(
  (state: any) => state.jupyter,
  (jupyter: any) => jupyter.authorize,
);

export const { authorize } = jupyterAuthorizeSlice.actions;

export default jupyterAuthorizeSlice.reducer;
