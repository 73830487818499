/**
 * Labstep
 *
 * @module prosemirror/nodes/doc
 * @desc Doc is the root node for ProseMirror
 */

import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';

const NodeDoc: LabstepNode = {
  key: 'doc',
  spec: {
    // End with paragraph otherwise
    // copy/paste metadata doesn't work
    // if doc ends with metadata block
    content:
      '(block|step)+ (paragraph|heading|step|ordered_list|bullet_list)',
  },
};

export default NodeDoc;
