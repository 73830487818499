/**
 * Labstep
 *
 * @module components/ProtocolValue/Form/ShowEdit/AmountUnit
 * @desc ProtocolValue Amount Unit
 */

import React from 'react';
import Flex from 'labstep-web/core/Flex';
import ProtocolValueShowEditAmount from '../Amount';
import ProtocolValueShowEditUnit from '../Unit';
import styles from './styles.module.scss';
import { IProtocolValueShowEditAmountUnitProps } from './types';

export const ProtocolValueShowEditAmountUnit: React.FC<
  IProtocolValueShowEditAmountUnitProps
> = ({ protocolValue, placeholder }) => (
  <Flex className={styles.container}>
    <ProtocolValueShowEditAmount
      protocolValue={protocolValue}
      placeholder={placeholder}
    />
    <ProtocolValueShowEditUnit
      protocolValue={protocolValue}
      placeholder={placeholder}
    />
  </Flex>
);

export default ProtocolValueShowEditAmountUnit;
