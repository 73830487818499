/**
 * Labstep
 *
 * @module components/Protocol/Action/CreateVariable
 * @desc Create variable action for protocol
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { useProtocolConditionModalContext } from 'labstep-web/components/ProtocolCondition/Modal/context';
import { getIdAttribute } from 'labstep-web/services/schema/helpers';
import React from 'react';
import { IProtocolActionCreateVariableProps } from './types';

export const ProtocolActionCreateVariable: React.FC<
  IProtocolActionCreateVariableProps
> = ({
  entityName,
  isInput,
  params,
  actionComponentProps,
  protocol,
}) => {
  const { setActiveVariable } = useProtocolConditionModalContext();
  return (
    <EntityActionCreate
      entityName={entityName}
      parentId={protocol.id}
      parentName={protocol.entityName}
      body={{
        is_variable: true,
        is_input: !!isInput,
        is_output: !isInput,
        ...params,
      }}
      actionComponentProps={actionComponentProps}
      onClick={(e): void => e.stopPropagation()}
      options={{
        onSuccess: ({ response }): void =>
          setActiveVariable({
            field_type: entityName,
            [getIdAttribute(entityName)]: response.result,
          }),
      }}
    />
  );
};

export default ProtocolActionCreateVariable;
