import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import { Filter } from 'labstep-web/components/Filter/Menu/types';

export const filters = [
  Filter.date_range,
  Filter.tag,
  Filter.deleted,
  Filter.purchase_order_status,
];

export const bulkActions = [BulkAction.delete];
