/**
 * Labstep
 *
 * @module components/File/Preview
 * @desc Component to preview files
 */

import FileLightbox from 'labstep-web/components/File/Lightbox';
import FilePreviewDocument from 'labstep-web/components/File/Preview/Document';
import {
  SnapgenePreview,
  canRenderSnapgene,
} from 'labstep-web/components/File/Snapgene';
import { ToggleContainer } from 'labstep-web/containers/Toggle';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { isMobile } from 'react-device-detect';
import styles from './styles.module.scss';
import {
  IFilePreviewContainerProps,
  IFilePreviewProps,
} from './types';

export const FilePreview: React.FC<IFilePreviewProps> = ({
  file,
  toggle,
  toggled,
  viewComponent,
  withDefault,
  text,
  noDiv,
}) => {
  let component;
  switch (true) {
    case file.isLinked:
      component = <span />;
      break;
    case file.isImage:
      component = <FileLightbox toggle={toggle} file={file} />;
      break;
    case file.is_snapgene:
      if (canRenderSnapgene(file)) {
        component = <SnapgenePreview toggle={toggle} file={file} />;
      }
      break;
    case file.isPdf && file.canDownload:
      component = <FilePreviewDocument toggle={toggle} file={file} />;
      break;
    case file.isOfficeFile && !isMobile:
      component = <FilePreviewDocument toggle={toggle} file={file} />;
      break;
    case file.isText:
      component = <FilePreviewDocument toggle={toggle} file={file} />;
      break;
    default:
      component = withDefault && (
        <FilePreviewDocument toggle={toggle} file={file} />
      );
      break;
  }

  if (!component) {
    return null;
  }

  let action = viewComponent ? (
    viewComponent({ toggle })
  ) : (
    <Icon link name="external" popup={{}} onClick={toggle} />
  );

  if (text) {
    action = (
      <span className={styles.span} onClick={toggle}>
        {text}
      </span>
    );
  }

  if (file.isLinked) {
    action = (
      <a
        className={styles.link}
        href={file.path}
        target="_blank"
        rel="noopener noreferrer"
      >
        {action}
      </a>
    );
  }

  return noDiv ? (
    <>
      {action}
      {toggled && component}
    </>
  ) : (
    <div>
      {action}
      {toggled && component}
    </div>
  );
};

export const FilePreviewContainer: React.FC<
  IFilePreviewContainerProps
> = (props) => (
  <ToggleContainer>
    {({ toggle, toggled }) => (
      <FilePreview {...props} toggle={toggle} toggled={toggled} />
    )}
  </ToggleContainer>
);

export default FilePreviewContainer;
