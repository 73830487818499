/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/LiveList
 * @desc A component to show live experiment workflows
 */

import ExperimentActionStart from 'labstep-web/components/Experiment/Action/Start';
import { ICONS } from 'labstep-web/constants/icons';
import Button from 'labstep-web/core/Button';
import Card from 'labstep-web/core/Card/Card';
import Checkbox from 'labstep-web/core/Checkbox';
import Icon from 'labstep-web/core/Icon';
import Link from 'labstep-web/core/Link';
import List from 'labstep-web/core/List';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import {
  IExperimentWorkflowLiveListProps,
  ILiveExperimentProps,
} from './types';

export const LiveExperiment: React.FC<ILiveExperimentProps> = ({
  experiment,
}) =>
  experiment.ended_at ? null : (
    <div className={styles.liveExperiment}>
      <div className={styles.container}>
        <div className={styles.protocolName}>{experiment.name}</div>
        {experiment.started_at && (
          <div
            className={styles.protocolDate}
          >{`Started on ${humanReadableDate(
            experiment.started_at,
          )}`}</div>
        )}
      </div>
      <Link
        className={styles.button}
        to="experiment_show"
        params={{ id: experiment.id }}
      >
        {experiment.started_at ? (
          <Button basic primary>
            Continue
          </Button>
        ) : (
          <ExperimentActionStart experiment={experiment} />
        )}
      </Link>
    </div>
  );

const ExperimentWorkflowLiveList: React.FC<
  IExperimentWorkflowLiveListProps
> = ({ experimentWorkflows, toggleSelection, isSelected }) => (
  <List>
    {experimentWorkflows.map((experimentWorkflow) => (
      <Card key={experimentWorkflow.id}>
        <div className={styles.headerContainer}>
          <Checkbox
            checked={isSelected(experimentWorkflow)}
            onChange={() => toggleSelection(experimentWorkflow)}
          />
          <Link
            to="experiment_workflow_show"
            params={{ id: experimentWorkflow.id }}
          >
            <b>{experimentWorkflow.displayName}</b>
          </Link>
          {experimentWorkflow.locked_at && (
            <Icon name={ICONS.experiment_workflow.actions.lock} />
          )}
        </div>
        {experimentWorkflow.experiments.map((experiment) => (
          <LiveExperiment
            key={experiment.id}
            experiment={experiment}
          />
        ))}
      </Card>
    ))}
  </List>
);

export default ExperimentWorkflowLiveList;
