/**
 * Labstep
 *
 * @module components/Group/Form/ShowEdit/Required
 * @desc Group field required
 */

import React from 'react';
import EntityActionUpdateCheckbox from 'labstep-web/components/Entity/Action/Update/Checkbox';
import { IGroupActionUpdateIsCompletionChecklistEnabledProps } from './types';

export const GroupActionUpdateIsCompletionChecklistEnabled: React.FC<
  IGroupActionUpdateIsCompletionChecklistEnabledProps
> = ({ group }) => (
  <EntityActionUpdateCheckbox
    entity={group}
    field={'is_completion_checklist_enabled' as const}
    optimistic
    toggle
  />
);

export default GroupActionUpdateIsCompletionChecklistEnabled;
