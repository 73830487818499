/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Link/Action/Edit
 * @desc Button to edit a link mark
 */

import React from 'react';
import { setMarkFromSelectedNode } from 'labstep-web/prosemirror/utils/selection';
import Modal from 'labstep-web/core/Modal/Default';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import Button from 'labstep-web/core/Button';
import Icon from 'labstep-web/core/Icon';
import { string } from 'yup';
import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { IMenuLinkActionEditProps } from './types';

export const fieldUrl: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'url',
  validation: string().url().required(),
  placeholder: 'Paste or type your link',
  fieldLabel: 'Web address',
};

const MenuLinkActionEdit: React.FC<IMenuLinkActionEditProps> = ({
  state,
  dispatch,
  attrs,
}) => (
  <Modal
    viewComponent={({ toggleModal }) => (
      <Button onMouseDown={toggleModal}>
        <Icon name="pencil" />
        <span>Edit</span>
      </Button>
    )}
    header="Edit link"
    content={({ toggleModal }) => (
      <ReusableForm
        fields={[fieldUrl]}
        onSubmit={({ url }) => {
          setMarkFromSelectedNode(state.schema.marks.link, {
            ...attrs,
            href: url,
          })(state, dispatch);
          toggleModal();
        }}
        submitButtonText="Update"
        defaultValues={{ url: attrs.href }}
      />
    )}
  />
);

export default MenuLinkActionEdit;
