/**
 * Labstep
 */

import Loader from 'labstep-web/core/Loader';
import React, { useEffect, useState } from 'react';

interface TextFilePreviewProps {
  url: string;
}

const TextFilePreview: React.FC<TextFilePreviewProps> = ({ url }) => {
  const [fileContent, setFileContent] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTextFile = async () => {
      try {
        const response = await fetch(url);
        const text = await response.text();
        setFileContent(text);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching the text file:', error);
        setLoading(false);
      }
    };

    fetchTextFile();
  }, [url]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <pre style={{ whiteSpace: 'pre-wrap' }}>{fileContent}</pre>
      )}
    </div>
  );
};

export default TextFilePreview;
