/**
 * Labstep
 *
 * @module state/selectors/file
 * @desc Selectors for File
 */

import { hasKey } from './helpers';

/**
 * Creates (parent) key for file to be created
 *
 * @function
 * @param  {string} entityType - entity type
 * @param  {number} entityId - entity id
 * @param  {string} orphanUuid - Orphan Uuid
 * @return {string} - key
 */
export const getCreateFileKey = (
  entityType: string,
  entityId: number,
  orphanUuid?: string,
) => orphanUuid || `${entityType}_id=${entityId}`;

/**
 * Select status of files that is being downloaded
 *
 * @function
 */
export const selectDownloadFileStatus = (state: any, id: number) => {
  const { downloadingIds } = state.entities.file;
  return hasKey(downloadingIds, id.toString())
    ? downloadingIds[id].status
    : false;
};

export const selectFilesInProgress = (state: any) => {
  const { creatingIds } = state.entities.file;

  return Object.keys(creatingIds)
    .map((key) => creatingIds[key].status)
    .filter(
      (creatingFile) =>
        creatingFile.progress >= 0 &&
        !creatingFile.error &&
        !creatingFile.id &&
        !creatingFile.hideProgress,
    );
};
