/**
 * Labstep
 *
 * @module core/Route
 * @desc Wrapper around react-router Route
 */

import { FullScreenLayout } from 'labstep-web/components/Layout/FullScreen';
import { withAuthenticatedUser } from 'labstep-web/containers/AuthenticatedUser';
import AuthenticationScreen from 'labstep-web/screens/Authentication';
import VerifyEmailError from 'labstep-web/screens/Error/VerifyEmail';
import React from 'react';
import { Route as ReactRoute } from 'react-router';
import { IRouteProps } from './types';

export const Route: React.FC<IRouteProps> = (props) => {
  const { authenticatedUser, publicPath, ...rest } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { component, render, ...routeProps } = rest;

  if (authenticatedUser.enabled === false && !publicPath) {
    return <FullScreenLayout right={<VerifyEmailError />} />;
  }

  if (!authenticatedUser.authenticated && !publicPath) {
    return (
      <ReactRoute {...routeProps} component={AuthenticationScreen} />
    );
  }

  return <ReactRoute {...rest} />;
};

Route.defaultProps = {
  publicPath: false,
};

export default withAuthenticatedUser(Route);
