/**
 * Labstep
 *
 * @module components/Molecule/Card/EmptyState
 * @desc Molecule Empty State card
 */

import React from 'react';
import styles from './styles.module.scss';
import { IMoleculeCardEmptyStateProps } from './types';

export const MoleculeCardEmptyState: React.FC<
  IMoleculeCardEmptyStateProps
> = ({ action }) => (
  <div className={styles.container}>
    <div>{action}</div>
  </div>
);

export default MoleculeCardEmptyState;
