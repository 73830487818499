/**
 * Labstep
 *
 * @module components/Group/Action/SetCustomIdentifier
 * @desc Action to set Custom Identifier
 */

import CustomIdentifierSetActionCreate from 'labstep-web/components/CustomIdentifierSet/Action/Create';
import CustomIdentifierSetPreview from 'labstep-web/components/CustomIdentifierSet/Preview';
import { useHasAccessCreate } from 'labstep-web/components/Entity/Can';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { CustomIdentifierSet } from 'labstep-web/models/custom-identifier-set.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import React from 'react';
import { IGroupFormShowEditExperimentWorkflowCustomIdentifierSetProps } from './types';

export const GroupFormShowEditExperimentWorkflowCustomIdentifierSet: React.FC<
  IGroupFormShowEditExperimentWorkflowCustomIdentifierSetProps
> = ({ group }) => {
  const canCreate = useHasAccessCreate(
    CustomIdentifierSet.entityName,
  ) as boolean;

  const customIdentifierSet =
    group.experimentWorkflowCustomIdentifierSet;
  if (customIdentifierSet) {
    return (
      <CustomIdentifierSetPreview
        group={group}
        customIdentifierSet={customIdentifierSet}
        canCreate={canCreate}
      />
    );
  }
  if (canCreate) {
    return (
      <CustomIdentifierSetActionCreate
        groupId={group.id}
        parentClass={ExperimentWorkflow.entityName}
      />
    );
  }
  return <TextPlaceholder>No Custom Identifier</TextPlaceholder>;
};

export default GroupFormShowEditExperimentWorkflowCustomIdentifierSet;
