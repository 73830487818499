/**
 * Labstep
 *
 * @module screens/OrderRequest/Index/LeftPane
 * @desc OrderRequest Index LeftPane
 */

import OrderRequestFilterPane from 'labstep-web/components/OrderRequest/Filter/Pane';
import LayoutLeftPane from 'labstep-web/components/Layout/LeftPane';
import React from 'react';
import { IOrderRequestIndexLeftPaneProps } from './types';

export const OrderRequestIndexLeftPane: React.FC<
  IOrderRequestIndexLeftPaneProps
> = ({ links }) => (
  <LayoutLeftPane links={links}>
    <OrderRequestFilterPane />
  </LayoutLeftPane>
);

export default OrderRequestIndexLeftPane;
