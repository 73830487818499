/**
 * Labstep
 *
 * @module screens/Authentication/SetPassword
 * @desc SetPasswordScreen
 */

import ResetPasswordForm from 'labstep-web/components/User/Form/ResetPassword';
import { UserResetPasswordContainer } from 'labstep-web/containers/User/ResetPassword';
import Button from 'labstep-web/core/Button';
import Card from 'labstep-web/core/Card/Card';
import DocumentHead from 'labstep-web/core/DocumentHead';
import { Header } from 'labstep-web/core/Header';
import Image from 'labstep-web/core/Image';
import Columns from 'labstep-web/screens/Authentication/Columns';
import React from 'react';
import Message from 'labstep-web/core/Message';
import Link from 'labstep-web/core/Link';
import styles from './styles.module.scss';
import {
  ISetPasswordComponentProps,
  ISetPasswordProps,
  ISetPasswordScreenContainerProps,
} from './types';

export const SetPasswordComponent: React.FC<
  ISetPasswordComponentProps
> = ({
  resetPassword,
  status,
  match: {
    params: { token },
  },
}) => {
  const successful =
    status && status.isFetching === false && status.error === null;

  if (successful) {
    return (
      <div>
        <p data-testid="success-message">
          <b>Your password has been succesfuly set!</b>
        </p>
        <Button route={{ to: 'login' }} primary fluid>
          Login
        </Button>
      </div>
    );
  }

  return (
    <ResetPasswordForm
      token={token}
      resetPassword={resetPassword}
      status={status}
    />
  );
};

const ErrorComponent = (
  <Message warning className={styles.info}>
    <p>
      {'This token has already been used. '}
      <Link to="forgot_password">Click here</Link>
      {' to request a new one.'}
    </p>
  </Message>
);

export const SetPasswordColumns = (props) => {
  let error = false;
  if (
    props.status &&
    props.status.error &&
    props.status.error.data &&
    props.status.error.data.error_code === 'token_already_taken'
  ) {
    error = true;
  }

  return (
    <>
      <DocumentHead title="Set Password" />
      <Columns
        left={
          <>
            <div>
              <Image src="/img/error/locked.svg" fluid />
            </div>
            <Header className={styles['left-title']} size="xsmall">
              More Science. Less Grind.
            </Header>
            <span>
              The modern R&D cloud platform designed to uncover the
              power of real-time data
            </span>
          </>
        }
        right={
          error ? ErrorComponent : <SetPasswordComponent {...props} />
        }
        header={error ? null : 'Set password'}
        explainer={
          !error && (
            <Card className={styles.explainer}>
              <span>
                Your password must contain 10 characters and at least
                one uppercase, one lowercase, one number and one
                special character, excluding _
              </span>
            </Card>
          )
        }
      />
    </>
  );
};

const SetPasswordScreenContainer: React.FC<
  ISetPasswordScreenContainerProps
> = (props) => (
  <UserResetPasswordContainer>
    {(containerProps: ISetPasswordProps) => (
      <SetPasswordColumns {...containerProps} {...props} />
    )}
  </UserResetPasswordContainer>
);

export default SetPasswordScreenContainer;
