/**
 * Labstep
 *
 * @module components/DeviceBooking/Manager
 * @desc Device Booking Manager
 */

import DeviceBookingModal from 'labstep-web/components/DeviceBooking/Modal';
import DeviceBookingTimeGrid from 'labstep-web/components/DeviceBooking/TimeGrid';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import { DeviceBooking } from 'labstep-web/models/device-booking.model';
import React from 'react';
import { IDeviceBookingManagerProps } from './types';

export const DeviceBookingManager: React.FC<
  IDeviceBookingManagerProps
> = ({ device, activeEditModal, setActiveEditModal }) => (
  <>
    <DeviceBookingTimeGrid device={device} />
    {
      // need to separate out the modal
      // in case time grid refreshes
      activeEditModal &&
        activeEditModal.entityName === DeviceBooking.entityName && (
          <DeviceBookingModal
            deviceBookingId={activeEditModal.id as number}
            onClose={() => setActiveEditModal(null)}
          />
        )
    }
  </>
);

export default withActiveEditModal(DeviceBookingManager);
