/**
 * Labstep
 *
 * @module components/EntityImport/Action/Revert
 * @desc Revert an EntityImport
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityImportStatus } from 'labstep-web/models/entity-import.model';
import React from 'react';
import { EntityImportActionRevertProps } from './types';

export const EntityImportActionRevert: React.FC<
  EntityImportActionRevertProps
> = ({ entityImport }) => {
  if (!entityImport.canBeReverted) {
    return null;
  }
  return (
    <EntityActionUpdate
      entityName={entityImport.entityName}
      id={entityImport.guid}
      body={{
        status: EntityImportStatus.revert_started,
      }}
      actionComponentProps={{
        type: 'option',
        text: 'Revert Import',
        icon: ICONS.entity_import.revert,
      }}
    />
  );
};

export default EntityImportActionRevert;
