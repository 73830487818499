/**
 * Labstep
 *
 * @module prosemirror/nodes/utils
 * @desc ProseMirror utils methods for nodes
 */

import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';
import { ParseRule } from 'prosemirror-model';

export const getAttrsForId =
  (key: LabstepNode['key']): ParseRule['getAttrs'] =>
  (node) => {
    if (node instanceof HTMLElement) {
      const attrs: { id?: number; guid?: string } = {};

      const id: string | null = node.getAttribute(`data-${key}-id`);
      const idNumber = id && Number(id);
      if (idNumber) {
        attrs.id = idNumber;
      }

      const guid: string | null = node.getAttribute(
        `data-${key}-guid`,
      );
      if (guid) {
        attrs.guid = guid;
      }

      return Object.keys(attrs).length ? attrs : false;
    }
    return false;
  };
