/**
 * Labstep
 *
 * @module prosemirror/extensions/slash
 * @desc Commands for commands plugin
 */

import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import MENU_COMMANDS_ELEMENTS from 'labstep-web/prosemirror/components/Menu/Commands/elements';
import commandsPlugin, {
  commandsPluginKey,
} from 'labstep-web/prosemirror/extensions/slash/plugin';
import { IProseMirrorCommandsElement } from 'labstep-web/prosemirror/extensions/slash/types';
import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import { getIsStepSelected } from 'labstep-web/prosemirror/utils/selection';
import { ProsemirrorSlashService } from 'labstep-web/services/prosemirror-slash.service';
import { EditorState } from 'prosemirror-state';
import { getState as getPremiumFeaturesState } from '../../premium-features/selectors';
import {
  SET_ELEMENTS,
  selectNext,
  selectPrevious,
  setIsOpen,
  submitElement,
} from './actions';
import {
  getAvailableElements,
  getIndex,
  getIsOpen,
} from './selectors';

const ELEMENTS_SHOULD_CREATE: IProseMirrorCommandsElement[] = [
  'protocol_table',
  'protocol_step',
  'protocol_value',
  'metadata',
  'jupyter_notebook',
  'molecule',
  'conditions',
];

export type ICommandsExtensionProps = (
  state: EditorState,
  dispatch: IStateDispatchProps['dispatch'],
) => boolean;

export const handleSubmitElement = (
  state: EditorState,
  dispatch: IStateDispatchProps['dispatch'],
  index: number,
) => {
  const element = getAvailableElements(state)[index];

  // elements that are created and passed to Redux
  if (ELEMENTS_SHOULD_CREATE.indexOf(element) > -1) {
    const commandElement = MENU_COMMANDS_ELEMENTS.find(
      (e) => e.id === element,
    );
    const premiumFeatures = getPremiumFeaturesState(state);
    const allowsCreation = commandElement?.premiumFeature
      ? premiumFeatures.indexOf(commandElement.premiumFeature) > -1
      : true;
    if (!allowsCreation) {
      dispatch?.(
        state.tr.setMeta(commandsPlugin, submitElement(element)),
      );
    } else {
      ProsemirrorSlashService.createElement(state, dispatch, element);
    }
  } else {
    dispatch?.(
      state.tr.setMeta(commandsPlugin, submitElement(element)),
    );
  }
  return true;
};

export const handleArrowUp: ICommandsExtensionProps = (
  state,
  dispatch,
) => {
  if (getIsOpen(state)) {
    dispatch?.(state.tr.setMeta(commandsPlugin, selectPrevious()));
    return true;
  }
  return false;
};

export const handleArrowDown: ICommandsExtensionProps = (
  state,
  dispatch,
) => {
  if (getIsOpen(state)) {
    dispatch?.(state.tr.setMeta(commandsPlugin, selectNext()));
    return true;
  }
  return false;
};

export const handleEnter: ICommandsExtensionProps = (
  state,
  dispatch,
) => {
  if (getIsOpen(state)) {
    return handleSubmitElement(state, dispatch, getIndex(state));
  }
  return false;
};

export const handleEscape: ICommandsExtensionProps = (
  state,
  dispatch,
) => {
  if (getIsOpen(state)) {
    handleSetIsOpen(state, dispatch, false);

    return true;
  }

  return false;
};

/**
 * Close the commands menu if there are no elements to select
 * @param state State
 * @param dispatch Dispatch
 * @returns False, so that the space key can be used to insert a space
 */
export const handleSpace: ICommandsExtensionProps = (
  state,
  dispatch,
) => {
  if (getIsOpen(state) && getAvailableElements(state).length === 0) {
    handleSetIsOpen(state, dispatch, false);
  }
  return false;
};

export const handleTrigger: ICommandsExtensionProps = (
  state,
  dispatch,
) => {
  if (!getIsOpen(state)) {
    handleSetIsOpen(state, dispatch, true);
  }
  return false;
};

export const handleSetIsOpen = (
  state: EditorState,
  dispatch: IStateDispatchProps['dispatch'],
  isOpen: boolean,
) => {
  dispatch?.(state.tr.setMeta(commandsPlugin, setIsOpen(isOpen)));
};

export const handleSetElements = (
  state: EditorState,
  dispatch: IStateDispatchProps['dispatch'],
  entity: Experiment | Protocol,
  experimentWorkflow?: ExperimentWorkflow,
): void => {
  const stepSelected = getIsStepSelected(state);
  let elements: IProseMirrorCommandsElement[] = [];
  if (entity instanceof Experiment) {
    if (!experimentWorkflow && !stepSelected) {
      elements = ['protocol_step'];
    } else if (experimentWorkflow) {
      elements = ['protocol_collection'];
    }
    elements = [
      ...elements,
      'protocol_table',
      'protocol_value',
      'metadata',
      'protocol_device',
    ];
    if (!experimentWorkflow) {
      elements = [...elements, 'protocol_timer'];
    }

    elements = [...elements, 'conditions'];
  } else {
    if (!stepSelected) {
      elements = ['protocol_step', ...elements];
    }
    elements = [
      ...elements,
      'protocol_table',
      'protocol_value',
      'metadata',
      'protocol_device',
      'protocol_timer',
    ];
    elements = [...elements, 'conditions'];
  }
  elements = [
    ...elements,
    'file',
    'image',
    'link',
    'html_table',
    'code',
    'molecule',
    'jupyter_notebook',
  ];
  if (experimentWorkflow && !experimentWorkflow.is_template) {
    elements.splice(-4, 0, 'experiment_workflow_link');
  }

  dispatch?.(
    state.tr.setMeta(commandsPluginKey, {
      type: SET_ELEMENTS,
      elements,
    }),
  );
};
