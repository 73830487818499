/**
 * Labstep
 *
 * @module state/epics/normalize
 * @desc Redux epic for normalizing action payload
 */

import values from 'lodash/values';
import merge from 'lodash/merge';
import { StateObservable } from 'redux-observable';
import { Observable, concat, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { Action } from 'labstep-web/models/action.model';
import { normalizeEntities } from 'labstep-web/services/normalize';
import { getPluralInSchema } from 'labstep-web/services/schema/helpers';
import { normalizeEntityWithTimestamp } from './utils';

/**
 * Normalize payload
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const normalizeActionEpic = (
  action$: Observable<Action>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state$: StateObservable<any>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type.includes('_RAW_OUTPUT')),
    map((action: Action) => {
      if (
        action &&
        action.meta &&
        action.meta.url &&
        action.meta.onFail &&
        !action?.meta?.url.includes('public-api') &&
        !state$.value.authenticatedUser?.byId?.authenticated
      ) {
        action.meta.onFail();
        return {
          type: 'SKIP_NORMALIZE_WITHOUT_API_KEY',
        };
      }

      if (
        action &&
        action.payload &&
        action.payload &&
        action.payload.updated_at &&
        action.meta &&
        action.meta.normalize &&
        action.meta.params &&
        action.meta.params.id
      ) {
        const entity =
          state$.value.entities[action.meta.normalize].byId[
            action.meta.params.id
          ];

        if (
          entity &&
          action.payload.updated_at &&
          entity.updated_at &&
          action.payload.updated_at < entity.updated_at
        ) {
          return {
            type: 'SKIP_UPDATE_ENTITY',
          };
        }
      }

      if (action.meta && action.meta.normalize && action.payload) {
        let payload = { ...action.payload };
        if (
          action.type.includes('READ_PAGE') ||
          action.type.includes('READ_CURSOR')
        ) {
          return {
            ...action,
            type: action.type.replace('_RAW_OUTPUT', ''),
            meta: {
              ...action.meta,
              denormalized_payload: payload,
            },
            payload: {
              page: payload.page,
              next_cursor: payload.next_cursor,
              count: payload.count,
              total: payload.total,
              items: normalizeEntities(
                payload.items,
                action.meta.normalize,
              ),
            },
          };
        }

        if (
          action.type.includes('ADD') ||
          action.type.includes('REMOVE')
        ) {
          // TODO: Refactor on backend to make sure remove returns always children with id and parent with id
          if (!payload.children) {
            return {
              ...action,
              meta: {
                ...action.meta,
                denormalized_payload: action.payload,
              },
              type: action.type.replace('_RAW_OUTPUT', ''),
            };
          }
          return {
            ...action,
            meta: {
              ...action.meta,
              denormalized_payload: payload,
            },
            type: action.type.replace('_RAW_OUTPUT', ''),
            payload: merge(
              normalizeEntities(
                payload.children,
                getPluralInSchema(action.meta.entityName),
              ),
              normalizeEntities(
                payload.parents,
                getPluralInSchema(action.meta.parentName),
              ),
            ),
          };
        }

        // This is for file upload action (where the payload is an object of ids i.e. normalized)
        // We turn it into array of objects instead
        if (
          action.type === 'SUCCESS_CREATE_FILE_RAW_OUTPUT' &&
          !action.payload.id
        ) {
          // https://github.com/Labstep/web/issues/7083
          // If upload fails it will return an empty array (but still return status 200)
          // In this case We should return a FAIL_CREATE_FILE action
          if (
            Array.isArray(action.payload) &&
            action.payload.length === 0
          ) {
            return {
              ...action,
              type: 'FAIL_CREATE_FILE',
            };
          }
          payload = values(payload);
        }

        // SUCCESS_TRANSFER_OWNERSHIP_RAW_OUTPUT when the payload is an array
        if (
          action.type === 'SUCCESS_TRANSFER_OWNERSHIP_RAW_OUTPUT' &&
          action.payload &&
          Array.isArray(action.payload)
        ) {
          return {
            ...action,
            meta: {
              ...action.meta,
              denormalized_payload: payload,
            },
            type: action.type.replace('_RAW_OUTPUT', ''),
            payload: normalizeEntities(
              payload,
              getPluralInSchema(action.meta.normalize),
            ),
          };
        }

        if (
          action.type.startsWith('SUCCESS_READ') &&
          action.meta &&
          action.meta.params &&
          action.meta.params.at
        ) {
          return {
            ...action,
            meta: {
              ...action.meta,
              denormalized_payload: payload,
            },
            type: action.type.replace('_RAW_OUTPUT', ''),
            payload: normalizeEntityWithTimestamp(action),
          };
        }

        return {
          ...action,
          meta: {
            ...action.meta,
            denormalized_payload: payload,
          },
          type: action.type.replace('_RAW_OUTPUT', ''),
          payload: normalizeEntities(payload, action.meta.normalize),
        };
      }

      return {
        ...action,
        meta: {
          ...action.meta,
          denormalized_payload: action.payload,
        },
        type: action.type.replace('_RAW_OUTPUT', ''),
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_NORMALIZE_NORMALIZE', payload: err }),
        source$,
      ),
    ),
  );
