/**
 * Labstep
 *
 * @module screens/EntityStateWorkflow/Show
 * @desc EntityStateWorkflow Show Screen
 */

import DocumentHead from 'labstep-web/core/DocumentHead';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { EntityStateWorkflow } from 'labstep-web/models/entity-state-workflow.model';
import MasterShow from 'labstep-web/screens/Master/Show';
import React from 'react';
import Center from './Center';
import Right from './Right';
import {
  IEntityStateWorkflowShowScreenContainerProps,
  IEntityStateWorkflowShowScreenProps,
} from './types';

export const EntityStateWorkflowShowScreen: React.FC<
  IEntityStateWorkflowShowScreenProps
> = ({ entityStateWorkflow }) => (
  <>
    <DocumentHead title={entityStateWorkflow.name} />
    <MasterShow
      entity={entityStateWorkflow}
      center={<Center entityStateWorkflow={entityStateWorkflow} />}
      right={<Right entityStateWorkflow={entityStateWorkflow} />}
    />
  </>
);

const EntityStateWorkflowShowScreenContainer: React.FC<
  IEntityStateWorkflowShowScreenContainerProps
> = ({
  match: {
    params: { id },
  },
}) => (
  <ReadOnMountHOC
    type="entities"
    entityName={EntityStateWorkflow.entityName}
    params={{
      get_single: 1,
      is_deleted: 'both',
      id,
    }}
    loading={{ loader: 'show', cached: true }}
    children={({ entity }) => (
      <EntityStateWorkflowShowScreen entityStateWorkflow={entity} />
    )}
  />
);

export default EntityStateWorkflowShowScreenContainer;
