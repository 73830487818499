/**
 * Labstep
 *
 * @module components/ProtocolCollection/Card/EmptyState
 * @desc Empty state for ProtocolCollection
 */

import { ProtocolCollectionActionCreate } from 'labstep-web/components/ProtocolCollection/Action/Create';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { Resource } from 'labstep-web/models/resource.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IProtocolCollectionEmptyStateProps } from './types';

const label = getHumanReadableEntityName(
  ProtocolCollection.entityName,
  true,
  true,
);

const explanation = `Create reusable templates to describe common procedures.  Specify the ${Resource.getHumanReadableEntityName(
  true,
)} needed, define steps to be completed and add interactive tables and timers. Recording what you done in the lab becomes as easy as clicking a button!`;

export const ProtocolCollectionEmptyState: React.FC<
  IProtocolCollectionEmptyStateProps
> = (props) => (
  <EmptyState
    title={`No ${label}`}
    explanation={explanation}
    src="/img/empty-state/protocols.svg"
    action={<ProtocolCollectionActionCreate />}
    {...props}
  />
);

export default ProtocolCollectionEmptyState;
