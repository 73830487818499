/**
 * Labstep
 */

import ChemicalActionDelete from 'labstep-web/components/Chemical/Action/Delete';
import ChemicalFormShowEditEquivalence from 'labstep-web/components/Chemical/Form/ShowEdit/Equivalence';
import ChemicalFormShowEditInventory from 'labstep-web/components/Chemical/Form/ShowEdit/Inventory';
import ChemicalFormShowEditIsLimiting from 'labstep-web/components/Chemical/Form/ShowEdit/IsLimiting';
import ChemicalFormShowEditMolarAmount from 'labstep-web/components/Chemical/Form/ShowEdit/MolarAmount';
import ChemicalFormShowEditPurity from 'labstep-web/components/Chemical/Form/ShowEdit/Purity';
import MoleculeSafety from 'labstep-web/components/Molecule/Safety';
import ProtocolValueFormShowEditAmountUnit from 'labstep-web/components/ProtocolValue/Form/ShowEdit/AmountUnit';
import PubChemMetadataFormShowEditCAS from 'labstep-web/components/PubChemMetadata/Form/ShowEdit/CAS';
import PubChemMetadataFormShowEditDensity from 'labstep-web/components/PubChemMetadata/Form/ShowEdit/Density';
import PubChemMetadataFormShowEditIUPACName from 'labstep-web/components/PubChemMetadata/Form/ShowEdit/IUPACName';
import PubChemMetadataFormShowEditMolecularFormula from 'labstep-web/components/PubChemMetadata/Form/ShowEdit/MolecularFormula';
import PubChemMetadataFormShowEditMolecularWeight from 'labstep-web/components/PubChemMetadata/Form/ShowEdit/MolecularWeight';
import {
  FetchResult,
  PubChemMetadata,
} from 'labstep-web/containers/PubChem/types';
import ActionMenu from 'labstep-web/core/Action/Menu';
import {
  Chemical,
  ChemicalSearchProperty,
  ChemicalType,
} from 'labstep-web/models/chemical.model';
import { Molecule } from 'labstep-web/models/molecule.model';
import { generateNewDateString } from 'labstep-web/services/date.service';
import { capitalize } from 'labstep-web/services/i18n.service';
import { ICreateAction } from 'labstep-web/typings';
import { groupBy } from 'lodash';
import React from 'react';

type ColumnType = {
  header: React.ReactNode;
  content: (chemical: Chemical) => React.ReactNode;
};

const getAvailableColumns: (
  isTemplate: boolean,
  showActionLinkToInventory: boolean,
) => ColumnType[] = (isTemplate, showActionLinkToInventory) => [
  {
    header: ' ',
    content: (chemical: Chemical): React.ReactElement => (
      <ActionMenu pointing="top left">
        <ChemicalActionDelete
          actionComponentProps={{
            type: 'option',
            icon: 'times',
            text: 'Remove',
          }}
          chemical={chemical}
        />
      </ActionMenu>
    ),
    cellProps: { style: { maxWidth: 50 } },
  },
  {
    header: 'IUPAC Name',
    content: (chemical: Chemical): React.ReactElement => (
      <PubChemMetadataFormShowEditIUPACName
        parent={chemical}
        pubChemMetadata={chemical.properties}
      />
    ),
    cellProps: { style: { maxWidth: 300 } },
  },
  {
    header: 'Formula',
    content: (chemical: Chemical): React.ReactElement => (
      <PubChemMetadataFormShowEditMolecularFormula
        parent={chemical}
        pubChemMetadata={chemical.properties}
      />
    ),
  },
  {
    header: 'CAS',
    content: (chemical: Chemical): React.ReactElement => (
      <PubChemMetadataFormShowEditCAS
        parent={chemical}
        pubChemMetadata={chemical.properties}
      />
    ),
  },
  {
    header: 'M.W.',
    content: (chemical: Chemical): React.ReactElement => (
      <PubChemMetadataFormShowEditMolecularWeight
        parent={chemical}
        pubChemMetadata={chemical.properties}
      />
    ),
  },
  {
    header: 'Density (g / mL)',
    content: (chemical: Chemical): React.ReactElement => (
      <PubChemMetadataFormShowEditDensity
        parent={chemical}
        pubChemMetadata={chemical.properties}
      />
    ),
  },
  {
    header: 'Theo. Mass',
    content: (chemical: Chemical): string | null =>
      chemical.theoreticalMass
        ? `${chemical.theoreticalMass} g`
        : null,
  },
  {
    header: 'Purity',
    content: (chemical: Chemical): React.ReactElement => (
      <ChemicalFormShowEditPurity chemical={chemical} />
    ),
  },
  {
    header: 'Equiv.',
    content: (chemical: Chemical): React.ReactElement => (
      <ChemicalFormShowEditEquivalence chemical={chemical} />
    ),
  },
  {
    header: 'Limiting?',
    content: (chemical: Chemical): React.ReactElement => (
      <ChemicalFormShowEditIsLimiting chemical={chemical} />
    ),
  },
  {
    header: 'moles',
    content: (chemical: Chemical): React.ReactElement => (
      <ChemicalFormShowEditMolarAmount chemical={chemical} />
    ),
  },
  {
    header: 'Amount',
    content: (chemical: Chemical): React.ReactElement => (
      <ProtocolValueFormShowEditAmountUnit
        protocolValue={chemical.protocol_value}
        placeholder="Enter"
      />
    ),
  },
  {
    header: 'Actual Mass',
    content: (chemical: Chemical): React.ReactElement => (
      <ProtocolValueFormShowEditAmountUnit
        protocolValue={chemical.protocol_value}
        placeholder="Enter"
      />
    ),
  },
  {
    header: 'Yield',
    content: (chemical: Chemical): string | null =>
      chemical.yieldAsPercentage,
  },
  {
    header: 'Safety',
    content: (chemical: Chemical): React.ReactElement => (
      <MoleculeSafety
        inline
        pubChemMetadata={chemical.properties}
        parent={chemical}
      />
    ),
  },
  {
    header: 'Linked Inventory',
    content: (chemical: Chemical): React.ReactElement => (
      <ChemicalFormShowEditInventory
        chemical={chemical}
        isTemplate={isTemplate}
        showActionLinkToInventory={showActionLinkToInventory}
      />
    ),
  },
];

export const columnHeaders = {
  reactant: [
    ' ',
    'IUPAC Name',
    'Formula',
    'CAS',
    'M.W.',
    'Density (g / mL)',
    'Purity',
    'Equiv.',
    'Limiting?',
    'moles',
    'Amount',
    'Safety',
    'Linked Inventory',
  ],
  product: [
    ' ',
    'IUPAC Name',
    'Formula',
    'CAS',
    'M.W.',
    'Purity',
    'Equiv.',
    'Theo. Mass',
    'Actual Mass',
    'Yield',
    'Safety',
    'Linked Inventory',
  ],
  solvent: [
    ' ',
    'IUPAC Name',
    'Formula',
    'CAS',
    'M.W.',
    'Amount',
    'Safety',
    'Linked Inventory',
  ],
};

export const getColumns = (
  type: ChemicalType,
  isTemplate: boolean,
  showActionLinkToInventory: boolean,
): ColumnType[] => {
  const columns = getAvailableColumns(
    isTemplate,
    showActionLinkToInventory,
  );
  return columnHeaders[type].map((columnHeader) =>
    columns.find((column) => column.header === columnHeader),
  );
};

export const generateBodyBasedOnType = (
  type: ChemicalType,
  properties?: PubChemMetadata,
): {
  name: string;
  is_output: boolean;
  is_input: boolean;
  unit: string;
} => ({
  name: properties?.IUPACName || capitalize(type),
  is_output: type === 'product',
  is_input: type !== 'product',
  unit: type === 'solvent' ? 'ml' : 'g',
});

/**
 * Check which chemicals to create and to delete
 * @param chemicals Existing chemicals
 * @param metadata  Pubchem metadata
 * @returns object  Chemicals to update
 */
export const getChemicalsToUpdate = (
  chemicals: Chemical[],
  metadata: FetchResult,
): {
  chemicalsToCreate: {
    reactant: PubChemMetadata[];
    product: PubChemMetadata[];
  };
  chemicalsToDelete: Chemical[];
} => {
  const metadataByType = {
    reactant: metadata.reactants,
    product: metadata.products,
  };
  const chemicalsByType = groupBy(chemicals, 'type');

  const chemicalsToKeep: Chemical[] = chemicals.filter(
    (c) => c.type === 'solvent',
  );
  const chemicalsToCreate: {
    reactant: PubChemMetadata[];
    product: PubChemMetadata[];
  } = {
    reactant: [],
    product: [],
  };

  ['reactant', 'product'].forEach((type) =>
    metadataByType[type].forEach((m: PubChemMetadata) => {
      const chemicalMatch = chemicalsByType[type]?.find(
        (chemical) =>
          chemical.properties[ChemicalSearchProperty] ===
          m[ChemicalSearchProperty],
      );
      if (chemicalMatch) {
        chemicalsToKeep.push(chemicalMatch);
      } else {
        chemicalsToCreate[type].push(m);
      }
    }),
  );

  const chemicalsToDelete = chemicals.filter(
    (chemical) => !chemicalsToKeep.includes(chemical),
  );

  return { chemicalsToCreate, chemicalsToDelete };
};

/**
 * Create chemicals by comparing with metadata
 * @param chemicals Existing chemicals
 * @param metadata Pubchem metadata
 * @param parent Molecule parent
 * @param molecule Molecule
 */
export const createChemicalsFromMetadata = (
  chemicals: Chemical[],
  metadata: FetchResult,
  moleculeGuid: Molecule['guid'],
  createProtocolValue: ICreateAction,
  createChemical: ICreateAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  read: VoidFunction,
): void => {
  const { chemicalsToCreate, chemicalsToDelete } =
    getChemicalsToUpdate(chemicals, metadata);

  const chemicalsToCreateArr = [
    ...chemicalsToCreate.reactant,
    ...chemicalsToCreate.product,
  ];

  const productIndex = chemicalsToCreate.reactant.length;

  const dataDeleted = chemicalsToDelete.map((chemical: Chemical) => ({
    guid: chemical.guid,
    deleted_at: generateNewDateString(),
  }));

  if (chemicalsToCreateArr.length) {
    createProtocolValue(
      chemicalsToCreateArr.map((m, i) =>
        generateBodyBasedOnType(
          i < productIndex ? 'reactant' : 'product',
          m,
        ),
      ),
      {
        onSuccess: ({ response }) => {
          const dataCreated = response.result.map(
            (protocolValueGuid: string, i: number) => ({
              molecule_guid: moleculeGuid,
              protocol_value_guid: protocolValueGuid,
              type: i < productIndex ? 'reactant' : 'product',
              properties: chemicalsToCreateArr[i],
            }),
          );
          const data = [...dataCreated, ...dataDeleted];
          createChemical(data, { onSuccess: () => read() });
        },
      },
    );
  } else if (chemicalsToDelete.length) {
    createChemical(dataDeleted, { onSuccess: () => read() });
  }
};
