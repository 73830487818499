import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import { Resource } from 'labstep-web/models/resource.model';
import { PostFilterResourceTemplate } from 'labstep-web/services/postFilter/filters/resource/template';
import { PostFilterTemplate } from 'labstep-web/services/postFilter/filters/template';
import { PostFilterType } from 'labstep-web/services/query-parameter.service';
import React from 'react';

export type searchParamsType = Record<string, unknown> & {
  template_id?: number;
  filter?: PostFilterType;
};

/**
 * Helper fn to get the id and guid of the active resource template
 * Based on legacy search parameters and post filter
 *
 * @param searchParams Search parameters
 * @returns Object containing the id and guid of the active resource template
 */
export const getActiveTemplateGuid = (
  searchParams: searchParamsType,
): string | undefined => {
  const node = PostFilterTemplate.getNodeInParams(searchParams);
  const guid = node
    ? PostFilterTemplate.getAttributeValues(node).guid.value
    : undefined;
  return guid;
};

/**
 * Helper fn to get the id and guid of the active resource template
 * Based on legacy search parameters and post filter
 *
 * @param searchParams Search parameters
 * @returns Object containing the id and guid of the active resource template
 */
export const getActiveResourceTemplateGuid = (
  searchParams: searchParamsType,
): string | undefined => {
  const node =
    PostFilterResourceTemplate.getNodeInParams(searchParams);
  const guid = node
    ? PostFilterResourceTemplate.getAttributeValues(node).guid.value
    : undefined;
  return guid;
};

/**
 * Helper fn to get the component with the active resource template based on search parameters
 * @param searchParams Search parameters
 * @param component Component to render
 * @returns Component with the active resource template passed in as a prop
 */
export const getComponentWithResourceTemplate = (
  searchParams: searchParamsType,
  component: (resourceTemplate?: Resource) => React.ReactElement,
): React.ReactElement => {
  const guid = getActiveResourceTemplateGuid(searchParams);
  if (!guid) {
    return component();
  }
  return (
    <EntityReadEntityContainer
      entityName={Resource.entityName}
      guid={guid}
    >
      {({ entity: resourceTemplate }): React.ReactElement =>
        component(resourceTemplate)
      }
    </EntityReadEntityContainer>
  );
};
