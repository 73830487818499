/**
 * Labstep
 *
 * @module components/ResourceLocation/Action/View
 * @desc Button linking to resource location show
 */

import React from 'react';
import EntityActionView from 'labstep-web/components/Entity/Action/View';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { IResourceLocationActionViewProps } from './types';

const ResourceLocationActionView: React.FC<
  IResourceLocationActionViewProps
> = ({ resourceLocation }) => (
  <EntityActionView
    entityName={ResourceLocation.entityName}
    params={{ id: resourceLocation.guid }}
  />
);

export default ResourceLocationActionView;
