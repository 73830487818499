/**
 * Labstep
 *
 * @module components/Entity/Action/AddSearchBulk
 * @desc Action to add. Shows modal to select entity to be added to.
 */

import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityToggleContainer } from 'labstep-web/containers/Entity/Toggle';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import ModalDefault from 'labstep-web/core/Modal/Default';
import SearchSelect from 'labstep-web/core/Select/Search';
import React from 'react';
import {
  IEntityActionAddSearchBulkProps,
  IEntityActionAddSearchComponentkProps,
} from './types';

export const EntityActionAddComponent: React.FC<
  IEntityActionAddSearchComponentkProps
> = ({
  entityName,
  parentId,
  activeGroup,
  options,
  parentName,
  header,
  viewComponent,
  searchSelectProps,
}) => (
  <ModalDefault
    header={header}
    content={({ toggleModal }) => (
      <EntityToggleContainer
        action="add"
        entityName={entityName}
        parentName={parentName}
        parentId={parentId}
      >
        {({ toggle }) => (
          <SearchSelect
            labelKey="name"
            autoFocus
            openMenuOnFocus
            creatable
            createBody={{
              type: parentName,
            }}
            params={{
              type: parentName,
              group_id: activeGroup?.id,
            }}
            entityName={entityName}
            value={null}
            onChange={(selectedOption) => {
              toggle(undefined, selectedOption.id, {
                ...options,
                toast: true,
              });
              toggleModal();
            }}
            {...searchSelectProps}
          />
        )}
      </EntityToggleContainer>
    )}
    viewComponent={viewComponent}
  />
);

export const EntityActionAddComponentWithGroup = withActiveGroup(
  EntityActionAddComponent,
);

export const EntityActionAddSearchBulk: React.FC<
  IEntityActionAddSearchBulkProps
> = ({
  parentEntities,
  parentEntitiesWithPermission,
  options,
  entityName,
  actionText,
  icon,
  searchSelectProps,
}) => {
  const parentName =
    parentEntitiesWithPermission[0] &&
    parentEntitiesWithPermission[0].entityName;

  return (
    <EntityActionAddComponentWithGroup
      options={options}
      entityName={entityName}
      parentName={parentName}
      header={actionText}
      parentId={parentEntitiesWithPermission.map((e) => e.id)}
      viewComponent={({ toggleModal }) => (
        <BulkActionComponent
          entitiesWithPermissionCount={
            parentEntitiesWithPermission.length
          }
          entitiesSelectedCount={parentEntities.length}
          onClick={toggleModal}
          content={actionText}
          icon={icon}
        />
      )}
      searchSelectProps={searchSelectProps}
    />
  );
};

export default EntityActionAddSearchBulk;
