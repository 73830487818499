/**
 * Labstep
 *
 * @module components/User/Basic
 * @desc Displays User basic information
 */

import ProfilePicture from 'labstep-web/components/Profile/Picture';
import UserFormShowEditName from 'labstep-web/components/User/Form/ShowEdit/Name';
import { withAuthenticatedUser } from 'labstep-web/containers/AuthenticatedUser';
import React from 'react';
import styles from './styles.module.scss';
import { IUserBasicProps } from './types';

export const UserBasic: React.FC<IUserBasicProps> = ({
  authenticatedUser,
  user,
}) => (
  <div className={styles.userBody}>
    <div className={styles.profilePhoto}>
      <ProfilePicture
        entity={user}
        uploadable={authenticatedUser.id === user.id}
      />
    </div>
    <div className={styles.userProfileForm}>
      <UserFormShowEditName user={user} />
      <div className={styles.username}>{user.username}</div>
    </div>
  </div>
);

export default withAuthenticatedUser(UserBasic);
