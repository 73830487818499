/**
 * Labstep
 *
 * @module components/Protocol/Form/ShowEdit/State/WarningModal
 * @desc Modal to block any additional action if protocol is locked
 */

import React from 'react';
import ModalDefault from 'labstep-web/core/Modal/Default';
import Button from 'labstep-web/core/Button';
import { ERRORS } from 'labstep-web/constants/error';
import { IWarningModalProps } from './types';

export const ERROR_MESSAGES = {
  [ERRORS.PROTOCOL_WITH_EXPERIMENT_CANNOT_UPDATE]:
    'This protocol version has been used in an experiment and is now locked for editing.',
  [ERRORS.PROSEMIRROR_STATE_IDS_DUPLICATE]:
    'Something went wrong and your changes could not be saved.',
  [ERRORS.PROSEMIRROR_STATE_IDS_MISMATCH]:
    'Something went wrong and your changes could not be saved.',
  [ERRORS.LOCK_PARENT_LOCKED]:
    'This document has been locked for editing.',
};

const WarningModal: React.FC<IWarningModalProps> = ({ status }) => {
  let message = null;
  if (status.error && ERROR_MESSAGES[status.error.data.error_code]) {
    message = ERROR_MESSAGES[status.error.data.error_code];
  }
  if (message) {
    return (
      <ModalDefault
        header="Warning"
        closeIcon={null}
        open
        viewComponent={null}
        content={() => (
          <div>
            <p>{message}</p>
            <Button primary onClick={() => window.location.reload()}>
              Reload Page
            </Button>
          </div>
        )}
      />
    );
  }
  return message;
};

export default WarningModal;
