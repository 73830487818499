import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import EntityStateWorkflowPreview from 'labstep-web/components/EntityStateWorkflow/Preview';
import EntityStateWorkflowSearchSelect from 'labstep-web/components/EntityStateWorkflow/SearchSelect';
import { premiumFeaturesInfo } from 'labstep-web/constants/premium-features';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import Button from 'labstep-web/core/Button';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Flex from 'labstep-web/core/Flex';
import { useToggle } from 'labstep-web/hooks/toggle';
import { EntityStateWorkflow } from 'labstep-web/models/entity-state-workflow.model';
import { EntityState } from 'labstep-web/models/entity-state.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { SignatureRequirement } from 'labstep-web/models/signature-requirement.model';
import React from 'react';
import { IExperimentWorkflowShowRightEntityStateWorkflowProps } from './types';

export const ExperimentWorkflowShowRightEntityStateWorkflow: React.FC<
  IExperimentWorkflowShowRightEntityStateWorkflowProps
> = ({ experimentWorkflow }) => {
  const [isToggled, toggle] = useToggle();
  const info = premiumFeaturesInfo.signature_workflow;

  const searchSelectAction = (
    <EntityCreateContainer
      entityName={EntityStateWorkflow.entityName}
    >
      {({ create }) => (
        <EntityUpdateContainer
          entityName={ExperimentWorkflow.entityName}
          id={experimentWorkflow.guid}
        >
          {({ update }) => (
            <div data-testid="search">
              <EntityStateWorkflowSearchSelect
                experimentWorkflow={experimentWorkflow}
                updateExperimentWorkflow={update}
                createEntityStateWorkflow={create}
                creatable
                isClearable
                defaultValue={
                  experimentWorkflow.entity_state_workflow
                }
              />
            </div>
          )}
        </EntityUpdateContainer>
      )}
    </EntityCreateContainer>
  );

  const action = isToggled ? (
    searchSelectAction
  ) : (
    <>
      <Can
        entityName={ExperimentWorkflow.entityName}
        action={Action.edit}
        id={experimentWorkflow.id}
      >
        <ActionComponent
          type="button"
          text="Select Workflow"
          onClick={toggle}
        />
      </Can>
      <Button
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        href={info.link}
        basic
        primary
        as="a"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn More
      </Button>
    </>
  );

  if (!experimentWorkflow.entity_state_workflow) {
    return (
      <EmptyState
        title={`No ${EntityStateWorkflow.getHumanReadableEntityName(
          false,
          true,
        )}`}
        action={action}
        src="/img/empty-state/state-workflow.svg"
        explanation={`Attach a ${EntityStateWorkflow.getHumanReadableEntityName()} to define the series of ${EntityState.getHumanReadableEntityName(
          true,
        )} ${ExperimentWorkflow.getHumanReadableEntityName(
          true,
        )} using this template must pass through, such as Planning, In Progress, In Review etc, including the ${SignatureRequirement.getHumanReadableEntityName(
          true,
        )} and permitted actions.`}
      />
    );
  }
  return (
    <Flex column hAlignContent="left">
      <Flex marginBottom={20}>{searchSelectAction}</Flex>
      <EntityStateWorkflowPreview
        entityStateWorkflow={experimentWorkflow.entity_state_workflow}
      />
    </Flex>
  );
};
