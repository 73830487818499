/**
 * Labstep
 *
 * @module core/MailTag
 * @desc Component to display a tag with mailto href
 */

import React from 'react';
import { IMailTagProps } from './types';

export const MailTag: React.FC<IMailTagProps> = ({ department }) => (
  <a
    href={`mailto:${department}@labstep.com`}
  >{`${department}@labstep.com`}</a>
);

export default MailTag;
