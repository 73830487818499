/**
 * Labstep
 *
 * @module screens/Resource/Show/Center
 * @desc Resource Show Center
 */

import EntityPrimaryInfoImage from 'labstep-web/components/Entity/PrimaryInfo/Image';
import EntityUserCard from 'labstep-web/components/EntityUser/Card';
import ResourceActionMenu from 'labstep-web/components/Resource/Action/Menu';
import { Action } from 'labstep-web/components/Resource/Action/Menu/types';
import ResourceBreadcrumb from 'labstep-web/components/Resource/Breadcrumb';
import ResourceFormShowEditName from 'labstep-web/components/Resource/Form/ShowEdit/Name';
import InformationCard from 'labstep-web/core/Card/Information';
import React from 'react';
import ResourceShowCenterSecondaryInfo from './SecondaryInfo';
import styles from './styles.module.scss';
import { IResourceShowCenterProps } from './types';

export const ResourceShowCenter: React.FC<
  IResourceShowCenterProps
> = ({ resource }) => (
  <div id="resource-show-screen" className={styles.container}>
    <InformationCard
      additionalActions={<EntityUserCard entity={resource} />}
      actionMenu={
        <ResourceActionMenu
          resource={resource}
          actions={[
            Action.set_resource_template,
            Action.tag,
            Action.create_order_request,
            Action.share,
            Action.duplicate,
            Action.delete,
            Action.transfer_ownership,
          ]}
        />
      }
      breadcrumb={<ResourceBreadcrumb resource={resource} />}
      primaryInfo={
        <EntityPrimaryInfoImage
          entity={resource}
          PrimaryInfo={
            <ResourceFormShowEditName resource={resource} />
          }
        />
      }
      secondaryInfo={
        <ResourceShowCenterSecondaryInfo resource={resource} />
      }
    />
  </div>
);

export default ResourceShowCenter;
