/**
 * Labstep
 *
 * @module prosemirror/marks/code
 * @desc Code mark
 */

import { LabstepMark } from 'labstep-web/prosemirror/marks/types';
import { toggleMark } from 'prosemirror-commands';

const MarkCode: LabstepMark = {
  key: 'code',
  spec: {
    // Excludes all marks in schema
    excludes: '_',
    parseDOM: [{ tag: 'code' }],
    toDOM() {
      return ['code', 0];
    },
  },
  keymap: {
    'Mod-`': (state, dispatch) =>
      toggleMark(state.schema.marks[MarkCode.key], {})(
        state,
        dispatch,
      ),
  },
};

export default MarkCode;
