/**
 * Labstep
 */

import { filterOutEntitiesFromItems } from 'labstep-web/state/reducers/helpers/remove';

export const updateResourceTemplateId = (state, action) => {
  return filterOutEntitiesFromItems(
    state,
    action,
    (keyState, params) =>
      (Number(params.template_id) !==
        Number(action.meta.body.template_id) &&
        params.template_id) ||
      params.has_template === 'false',
  );
};
