/**
 * Labstep
 *
 * @module containers/Toggle
 * @desc Toggle a component in edit state
 */

import * as React from 'react';
import {
  IToggleContainerProps,
  IToggleContainerState,
} from './types';

export class ToggleContainer extends React.Component<
  IToggleContainerProps,
  IToggleContainerState
> {
  public static defaultProps = {
    initialEditingState: false,
  };

  public constructor(props: IToggleContainerProps) {
    super(props);
    this.state = {
      editing: props.initialEditingState || false,
    };
    this.toggleEdit = this.toggleEdit.bind(this);
    this.close = this.close.bind(this);
  }

  public toggleEdit(): void {
    this.setState((prevState) => ({ editing: !prevState.editing }));
  }

  public close(): void {
    this.setState(() => ({ editing: false }));
  }

  public render(): React.ReactElement {
    const { children } = this.props;
    const { editing } = this.state;

    const Component = children({
      toggle: this.toggleEdit,
      close: this.close,
      toggled: editing,
    });

    return Component;
  }
}
