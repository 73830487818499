/**
 * Labstep
 *
 * @module components/Filter/Search
 * @desc Search Bar Filter
 */

import React from 'react';
import SearchBar from 'labstep-web/core/SearchBar';
import SearchBarToggle from 'labstep-web/core/SearchBar/Toggle';
import { useParamsContext } from 'labstep-web/hoc/Params/context';
import { ISearchFilterProps } from './types';

export const onSearchChange = (value, setParams, searchKey) => {
  const param = value === '' ? undefined : value;
  setParams({ [searchKey]: param });
};

export const SearchFilter: React.FC<ISearchFilterProps> = ({
  searchKey = 'search_query',
  searchBarProps,
}) => {
  const { setParams, searchParams, clearAll } = useParamsContext();
  const props = {
    defaultValue: searchParams[searchKey],
    onChange: (value) => onSearchChange(value, setParams, searchKey),
  };

  return 'viewComponent' in searchBarProps ? (
    <SearchBarToggle
      onClose={clearAll}
      {...props}
      {...searchBarProps}
    />
  ) : (
    <SearchBar {...props} {...searchBarProps} />
  );
};

export default SearchFilter;
