/**
 * Labstep
 */

import { getMetadataColDefs } from 'labstep-web/components/Entity/DataGrid/colDefs/metadatas';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';

/**
 * For all metadatas on resource item template, return a column
 * @param resourceTemplate Resource template
 * @returns Column definitions
 */
export const getResourceItemMetadataColDefs = (
  resourceTemplate?: Resource | null,
) =>
  resourceTemplate?.resource_item_template
    ? getMetadataColDefs(
        ResourceItem.entityName,
        resourceTemplate.resource_item_template,
        undefined,
        {
          entityName: ResourceItem.entityName,
        },
      )
    : [];
