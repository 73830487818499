/**
 * Labstep
 *
 * @module prosemirror/nodes/metadata
 * @desc Metadata node
 */

import { Node as ProseMirrorNode } from 'prosemirror-model';
import { getAttrsForId } from 'labstep-web/prosemirror/nodes/utils';
import { LabstepNode } from '../types';

const key = 'metadata';

const NodeMetadata: LabstepNode = {
  key,
  spec: {
    attrs: {
      id: {},
    },
    group: 'block',
    marks: '',
    parseDOM: [{ tag: 'div', getAttrs: getAttrsForId(key) }],
    toDOM(node: ProseMirrorNode) {
      const { id } = node.attrs;
      return ['div', { 'data-metadata-id': id }];
    },
  },
};

export default NodeMetadata;
