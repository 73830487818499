/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/Status/Select
 * @desc Select filter for status
 */

import { isActive } from 'labstep-web/components/Filter/List/utils';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import ActionComponent from 'labstep-web/core/Action/Component';
import Flex from 'labstep-web/core/Flex';
import Modal from 'labstep-web/core/Modal/Default';
import ReactSelect from 'labstep-web/core/Select';
import SearchSelect from 'labstep-web/core/Select/Search';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import { EntityState } from 'labstep-web/models/entity-state.model';
import { StatusTypes } from 'labstep-web/models/experiment-workflow.model';
import { STRINGS } from 'labstep-web/strings';
import React from 'react';
import { IExperimentWorkflowFilterStatusSelectProps } from './types';

const ExperimentWorkflowFilterStatusSelect: React.FC<
  IExperimentWorkflowFilterStatusSelectProps
> = ({ setParams, searchParams, activeGroup }) => {
  const activeStatusType = Object.values(StatusTypes).find((value) =>
    isActive(searchParams, value.params),
  );
  const hasSignatureFeatureEnabled = useHasFeatureFlagEnabled(
    'signature_workflow',
  );
  return (
    <Modal
      header={STRINGS.filter.header.status}
      content={({ toggleModal }) => (
        <Flex column spacing="gap">
          <ReactSelect
            value={
              activeStatusType
                ? {
                    label: activeStatusType.text,
                    value: activeStatusType.params,
                  }
                : null
            }
            placeholder={
              hasSignatureFeatureEnabled
                ? 'Select status type'
                : 'Select status'
            }
            options={Object.values(StatusTypes).map((value) => ({
              label: value.text,
              value: value.params,
            }))}
            isClearable
            onChange={(selectedOption) => {
              setParams(
                selectedOption
                  ? selectedOption.value
                  : Object.keys(StatusTypes.completed.params).reduce(
                      (result, key) => ({
                        ...result,
                        [key]: undefined,
                      }),
                      {},
                    ),
              );
              toggleModal();
            }}
          />
          {hasSignatureFeatureEnabled && (
            <>
              OR
              <SearchSelect
                isCursor
                entityName={EntityState.entityName}
                params={{
                  group_id: activeGroup?.id,
                  group_by_name: 1,
                }}
                onChange={(selectedOption) => {
                  setParams({
                    entity_state_name: selectedOption
                      ? selectedOption.name
                      : undefined,
                  });
                  toggleModal();
                }}
                value={null}
                placeholder="Select a specific status"
              />
            </>
          )}
        </Flex>
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon={StatusTypes.completed.icon}
          text={STRINGS.filter.header.status}
          onClick={toggleModal}
        />
      )}
    />
  );
};

export default withActiveGroup(ExperimentWorkflowFilterStatusSelect);
