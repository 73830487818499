/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/Symbol
 * @desc Button to insert a special character
 */

import React from 'react';
import ToolbarButton from 'labstep-web/prosemirror/components/Toolbar/Button';
import Modal from 'labstep-web/core/Modal/Default';
import CharMap from 'labstep-web/core/CharMap';
import { ISymbolToolbarProps } from './types';

const SymbolToolbar: React.FC<ISymbolToolbarProps> = ({
  state,
  dispatch,
}) => (
  <Modal
    viewComponent={({ toggleModal }) => (
      <ToolbarButton
        onMouseDown={toggleModal}
        icon="keyboard outline"
        popup="Symbol"
      />
    )}
    onClose={({ toggleModal }) => {
      toggleModal();
    }}
    header="Special characters"
    content={({ toggleModal }) => (
      <CharMap
        onClick={(symbol) => {
          dispatch?.(state.tr.insertText(symbol));
          toggleModal();
        }}
      />
    )}
  />
);

export default SymbolToolbar;
