/**
 * Labstep
 */

import { ICONS } from 'labstep-web/constants/icons';
import { IIconProps } from 'labstep-web/core/Icon/types';
import { OrderRequestStatusValues } from 'labstep-web/models/order-request.model';
import { PurchaseOrderStatusValues } from 'labstep-web/models/purchase-order.model';
import { ResourceItemStatusValues } from 'labstep-web/models/resource-item.model';

export const getNameAndIcon = (
  status: string,
): { name: string; icon: IIconProps['name'] } => ({
  name:
    ResourceItemStatusValues[status] ||
    OrderRequestStatusValues[status] ||
    PurchaseOrderStatusValues[status],

  icon:
    ICONS.resource_item.info[status] ||
    (OrderRequestStatusValues[status]
      ? ICONS.order_request.primary
      : ICONS.purchase_order.primary),
});
