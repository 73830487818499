/**
 * Labstep
 *
 * @module components/Comment/Action/Menu
 * @desc Comment options (Delete, Edit)
 */

import CommentActionComment from 'labstep-web/components/Comment/Action/Comment';
import CommentActionDelete from 'labstep-web/components/Comment/Action/Delete';
import CommentActionEdit from 'labstep-web/components/Comment/Action/Edit';
import CommentActionPin from 'labstep-web/components/Comment/Action/Pin';
import CommentThread from 'labstep-web/components/Comment/Thread';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { Action, ICommentActionMenuProps } from './types';

export const CommentActionMenu: React.FC<ICommentActionMenuProps> = ({
  toggle,
  comment,
  authenticatedUserEntity,
  actions = [Action.pin, Action.comment, Action.edit, Action.delete],
}) => (
  <CommentThread
    comment={comment}
    viewComponent={({ toggleModal }) => (
      <ActionMenu>
        {actions.includes(Action.pin) && (
          <CommentActionPin comment={comment} />
        )}
        {comment.thread && actions.includes(Action.comment) && (
          <CommentActionComment
            onClick={toggleModal}
            comment={comment}
          />
        )}
        {authenticatedUserEntity.id === comment.author.id && (
          <>
            {actions.includes(Action.edit) && (
              <CommentActionEdit comment={comment} toggle={toggle} />
            )}
            {actions.includes(Action.delete) && (
              <CommentActionDelete comment={comment} />
            )}
          </>
        )}
      </ActionMenu>
    )}
  />
);

export default withAuthenticatedUserEntity(CommentActionMenu);
