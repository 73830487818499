/**
 * Labstep
 *
 * @module containers/User/ChangePassword
 * @desc Change password container
 */

import { changePassword } from 'labstep-web/state/actions/user';
import { selectApiStatus } from 'labstep-web/state/selectors/ui';
import { connect } from 'react-redux';

const ChangePasswordContainer = ({ children, ...rest }: any) =>
  children(rest);

const mapStateToProps = (state: any) => ({
  status: selectApiStatus(state, 'change_password'),
});

const mapDispatchToProps = {
  changePassword,
};

export const UserChangePasswordContainer = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePasswordContainer);
