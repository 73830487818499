/**
 * Labstep
 */

import EntityActionUpdateTemplate from 'labstep-web/components/Entity/Action/UpdateTemplate';
import React from 'react';
import { IResourceActionUpdateResourceTemplateProps } from './types';

export const ResourceActionUpdateResourceTemplate: React.FC<
  IResourceActionUpdateResourceTemplateProps
> = ({ resource, actionType }) => (
  <EntityActionUpdateTemplate
    entity={resource}
    actionType={actionType}
  />
);

export default ResourceActionUpdateResourceTemplate;
