/**
 * Labstep
 *
 * @module core/Segment
 * @desc Wrapper around semantic-ui-react Segment
 */

import React from 'react';
import Segment, {
  SegmentProps,
} from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Icon from '../Icon';
import { IIconProps } from '../Icon/types';
import styles from './styles.module.scss';

export default ({
  children,
  onClose,
  ...rest
}: SegmentProps & {
  // eslint-disable-next-line react/require-default-props
  onClose?: IIconProps['onClick'];
}) => (
  <Segment {...rest}>
    <>
      {children}
      {onClose && (
        <Icon
          className={styles.closeIcon}
          name="times"
          onClick={onClose}
        />
      )}
    </>
  </Segment>
);
