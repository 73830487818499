/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Create/Wizard
 * @desc OrderRequest create wizard
 */

import EntityActionUpdateSearchSelect from 'labstep-web/components/Entity/Action/Update/SearchSelect';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import ResourcePrimaryInfo from 'labstep-web/components/Resource/PrimaryInfo';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import Identifier from 'labstep-web/core/Identifier';
import Segment from 'labstep-web/core/Segment';
import SearchSelect from 'labstep-web/core/Select/Search';
import TextWithIcon from 'labstep-web/core/Text/WithIcon';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import styles from './styles.module.scss';
import { IOrderRequestActionCreateWizardStepsSelectResourceProps } from './types';

export const OrderRequestActionCreateWizardStepsSelectResource: React.FC<
  IOrderRequestActionCreateWizardStepsSelectResourceProps
> = ({ resourceId, setResourceId }) =>
  resourceId ? (
    <EntityReadEntityContainer
      entityName={Resource.entityName}
      id={resourceId}
    >
      {({ entity: resource }) => {
        return (
          <Segment
            className={styles.segment}
            onClose={() => setResourceId(null)}
          >
            <TextWithIcon
              className={styles.name}
              icon={ICONS.resource_template.primary}
              iconProps={{ size: 'large' }}
              text={
                <EntityActionUpdateSearchSelect
                  entityName={Resource.entityName}
                  value={resource.template}
                  entity={resource}
                  params={{ is_template: true }}
                  getBody={(template) => ({
                    template_id: template?.guid || null,
                  })}
                  usePortal
                  creatable
                  createBody={{ is_template: true }}
                  createLabel="Create new category"
                />
              }
            />
            <ResourcePrimaryInfo
              resource={resource}
              icon={
                resource.is_template
                  ? ICONS.experiment_workflow.template
                  : undefined
              }
              name={resource.name || ''}
              prefix={
                resource.custom_identifier && (
                  <>
                    <Identifier>
                      {resource.custom_identifier}
                    </Identifier>
                  </>
                )
              }
              disabled
            />
            <MetadataManager entity={resource} />
          </Segment>
        );
      }}
    </EntityReadEntityContainer>
  ) : (
    <SearchSelect
      autoFocus
      entityName={Resource.entityName}
      creatable
      onChange={(value) => setResourceId(value?.idAttr || null)}
      usePortal
      labelKey="displayName"
    />
  );

export default OrderRequestActionCreateWizardStepsSelectResource;
