/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/UpdateStatus
 * @desc Action to update status for experimentWorkflow
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import EntityActionUpdateDropdownPill from 'labstep-web/components/Entity/Action/Update/DropdownPill';
import ExperimentCompletionChecklistModal from 'labstep-web/components/Experiment/CompletionChecklist/Modal';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import {
  ExperimentWorkflow,
  ExperimentWorkflowStatusKeys,
  ExperimentWorkflowStatusValues,
} from 'labstep-web/models/experiment-workflow.model';
import { generateNewDateString } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import { IExperimentWorkflowActionUpdateStatusProps } from './types';

export const updateStatus = (
  experimentWorkflow: ExperimentWorkflow,
  key: keyof typeof ExperimentWorkflowStatusValues,
) => {
  let body = {};
  const newDateString = generateNewDateString();
  switch (key) {
    case ExperimentWorkflowStatusKeys.unstarted:
      body = {
        started_at: null,
        ended_at: null,
      };
      break;
    case ExperimentWorkflowStatusKeys.started:
      body = {
        started_at: newDateString,
        ended_at: null,
      };
      break;
    case ExperimentWorkflowStatusKeys.completed:
      body = {
        ended_at: newDateString,
      };
      if (!experimentWorkflow.started_at) {
        body = { ...body, started_at: newDateString };
      }
      break;
    default:
      break;
  }
  return body;
};

export const ExperimentWorkflowActionUpdateStatus: React.FC<
  IExperimentWorkflowActionUpdateStatusProps
> = ({ experimentWorkflow, activeGroup }) => {
  if (experimentWorkflow.entity_state_workflow) {
    return null;
  }
  return (
    <ExperimentCompletionChecklistModal
      experiment={experimentWorkflow.root_experiment}
      experimentWorkflow={experimentWorkflow}
      action={({ toggleModal }) => (
        <EntityActionUpdate
          entityName={experimentWorkflow.entityName}
          id={experimentWorkflow.id}
          actionComponentProps={{
            type: 'button',
            text: 'Mark as completed',
            disabled:
              experimentWorkflow.root_experiment
                .metadataRequiredWithValueMissingCount > 0,
          }}
          body={updateStatus(experimentWorkflow, 'completed')}
          options={{ onSuccess: toggleModal }}
        />
      )}
      viewComponent={({ toggleModal }) => (
        <EntityActionUpdateDropdownPill
          entity={experimentWorkflow}
          triggerIcon={
            ICONS.experiment_workflow.info[
              experimentWorkflow.statusType
            ]
          }
          field={['started_at', 'ended_at']}
          triggerClassName={styles[experimentWorkflow.statusType]}
          currentValue={
            ExperimentWorkflowStatusValues[
              experimentWorkflow.statusType
            ]
          }
          items={Object.keys(ExperimentWorkflowStatusValues).map(
            (key) => ({
              label:
                ExperimentWorkflowStatusValues[
                  key as keyof typeof ExperimentWorkflowStatusValues
                ],
              body: updateStatus(
                experimentWorkflow,
                key as keyof typeof ExperimentWorkflowStatusValues,
              ),
              onClick:
                key === 'completed' &&
                activeGroup?.is_completion_checklist_enabled &&
                experimentWorkflow.statusType !== 'completed'
                  ? toggleModal
                  : undefined,
            }),
          )}
        />
      )}
    />
  );
};

export default withActiveGroup(ExperimentWorkflowActionUpdateStatus);
