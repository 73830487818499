/**
 * Labstep
 */

import {
  IOrganization,
  ORGANIZATIONS,
} from 'labstep-web/config/saml';

export class SAMLService {
  public static getOneBySamlIdentifier = (
    samlIdentifier: string,
  ): IOrganization | null => {
    return (
      ORGANIZATIONS.find(
        (organization) => organization.identifier === samlIdentifier,
      ) || null
    );
  };
}
