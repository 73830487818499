/**
 * Labstep
 *
 * @desc Protocol Condition DataGrid
 */

import React from 'react';
import Loadable from 'labstep-web/core/Loadable';
import { IDeviceDataGridContainerProps } from './types';

export const DeviceDataGridContainerComponent = React.lazy(
  () => import('./component'),
);

const DeviceDataGridContainer: React.FC<
  IDeviceDataGridContainerProps
> = (props) => (
  <Loadable>
    <DeviceDataGridContainerComponent {...props} />
  </Loadable>
);

export default DeviceDataGridContainer;
