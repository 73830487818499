/**
 * Labstep
 *
 * @module components/ResourceItem/Form/Location
 * @desc ShowEdit Form for location field
 */

import React from 'react';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import ResourceItemActionUpdateStatus from '..';
import { IResourceItemActionUpdateStatusBulkProps } from './types';

const ResourceItemActionUpdateStatusBulk: React.FC<
  IResourceItemActionUpdateStatusBulkProps
> = ({ entities, options }) => {
  const entityIdsAccessObject = useHasAccessMultiple(
    ResourceItem.entityName,
    entities ? entities.map((e) => e.id) : [],
    Action.edit,
  );

  const entitiesWithPermissions = entities
    ? entities.filter((e) => entityIdsAccessObject[e.id])
    : [];

  const trigger = (
    <BulkActionComponent
      content="Set Availability"
      onClick={null}
      icon="check circle outline"
      entitiesSelectedCount={entities.length}
      entitiesWithPermissionCount={entitiesWithPermissions.length}
    />
  );

  if (entitiesWithPermissions.length === 0) {
    return trigger;
  }

  return (
    <ResourceItemActionUpdateStatus
      disabled={entitiesWithPermissions.length === 0}
      trigger={trigger}
      resourceItemIds={entitiesWithPermissions.map((e) => e.id)}
      options={options}
    />
  );
};

export default ResourceItemActionUpdateStatusBulk;
