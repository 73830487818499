/**
 * Labstep
 *
 * @module components/ProtocolValue/Detail/Input
 * @desc Detail for Input Protocol Value
 */

import React from 'react';
import List from 'labstep-web/core/List';
import ProtocolValueActionUpdateAmountDeductedAt from 'labstep-web/components/ProtocolValue/Action/UpdateAmountDeductedAt';
import ProtocolValueActionQRScan from 'labstep-web/components/ProtocolValue/Action/QRScan';
import ProtocolValueFormShowEditResourceItemInput from 'labstep-web/components/ProtocolValue/Form/ShowEdit/ResourceItem/Input';
import Divider from 'labstep-web/core/Divider';
import styles from './styles.module.scss';
import { IProtocolValueDetailResourceItemInputProps } from './types';

export const ProtocolValueDetailResourceItemInput: React.FC<
  IProtocolValueDetailResourceItemInputProps
> = ({ protocolValue }) => (
  <List doubleSpaced>
    <ProtocolValueFormShowEditResourceItemInput
      protocolValue={protocolValue}
    />
    {!protocolValue.resource_item && (
      <>
        <Divider horizontal>OR</Divider>
        <ProtocolValueActionQRScan protocolValue={protocolValue} />
      </>
    )}
    <div className={styles.actionContainer}>
      <ProtocolValueActionUpdateAmountDeductedAt
        protocolValue={protocolValue}
      />
    </div>
  </List>
);

export default ProtocolValueDetailResourceItemInput;
