/**
 * Labstep
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { object, string } from 'yup';
import {
  PostFilterComparison,
  PostFilterComparisonHumanReadable,
  PostFilterComparisonHumanReadableChronologic,
} from 'labstep-web/services/query-parameter.service';
import {
  MetadataType,
  MetadataTypeValues,
} from 'labstep-web/models/metadata/types';
import rules from 'labstep-web/services/validation/rules';
import { FilterMetadataType } from './types';

export const getComparisonLabelDict = (type: FilterMetadataType) =>
  type === MetadataType.date
    ? PostFilterComparisonHumanReadableChronologic
    : PostFilterComparisonHumanReadable;

export const PostFilterMetadatasSupportedTypes = [
  MetadataType.default,
  MetadataType.numeric,
  MetadataType.date,
  MetadataType.options,
];

export const getFieldComparison = (
  options: PostFilterComparison[],
  labelByOption: Record<PostFilterComparison, string>,
): IFieldWithoutActionProps => ({
  fieldLabel: 'Comparison',
  name: 'comparison',
  fieldType: FieldType.ReactSelect,
  elementProps: {
    options: options.map((option) => ({
      value: option,
      label: labelByOption[option],
    })),
  },
  validation: rules.metadata.comparison,
});

export const fieldDate: IFieldWithoutActionProps = {
  fieldLabel: 'Date',
  name: 'value',
  fieldType: FieldType.DateTimePicker,
  validation: string().required(),
};

export const fieldValue: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'Value',
  name: 'value',
  placeholder: 'Enter value',
  validation: string().required(),
};

export const fieldOptions = {
  fieldLabel: 'Options',
  name: 'value',
  fieldType: FieldType.SelectOptions,
  validation: 'required',
};

export const fieldValueWithAutoFocus = {
  ...fieldValue,
  autoFocus: true,
};

export const fieldDateWithAutoFocus = {
  ...fieldDate,
  autoFocus: true,
};

export const fieldsPerMetadataType = (
  autoFocusValue?: boolean,
): Record<
  FilterMetadataType,
  { fields: IFieldWithoutActionProps[] }
> => {
  const fieldValueInForm = autoFocusValue
    ? fieldValueWithAutoFocus
    : fieldValue;
  const fieldDateInForm = autoFocusValue
    ? fieldDateWithAutoFocus
    : fieldDate;
  return {
    [MetadataType.default]: {
      fields: [fieldValueInForm],
    },
    [MetadataType.numeric]: {
      fields: [
        getFieldComparison(
          [
            PostFilterComparison.eq,
            PostFilterComparison.gt,
            PostFilterComparison.gte,
            PostFilterComparison.lt,
            PostFilterComparison.lte,
          ],
          getComparisonLabelDict(MetadataType.numeric),
        ),
        fieldValueInForm,
      ],
    },
    [MetadataType.date]: {
      fields: [
        getFieldComparison(
          [
            PostFilterComparison.eq,
            PostFilterComparison.gt,
            PostFilterComparison.lt,
          ],
          getComparisonLabelDict(MetadataType.date),
        ),
        fieldDateInForm,
      ],
    },
    [MetadataType.options]: {
      fields: [
        getFieldComparison(
          [
            PostFilterComparison.contains,
            PostFilterComparison.not_contains,
          ],
          getComparisonLabelDict(MetadataType.options),
        ),
        fieldValueInForm,
      ],
    },
  };
};

export const fieldLabel: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'Field Name',
  name: 'label',
  placeholder: 'Enter field name',
  validation: string().required(),
};

export const fieldType: IFieldWithoutActionProps = {
  fieldLabel: 'Type',
  name: 'type',
  placeholder: MetadataTypeValues.default,
  fieldType: FieldType.ReactSelect,
  elementProps: {
    options: PostFilterMetadatasSupportedTypes.map(
      (metadataType) => ({
        value: metadataType,
        label: MetadataTypeValues[metadataType],
      }),
    ),
  },
  validation: object(),
};

export const fieldsGeneric = [fieldLabel, fieldType];
