/**
 * Labstep
 *
 * @module components/Entity/Action/Revoke
 * @desc Action to remove an entity from a workspace you own
 */

import { useHasAccessGroup } from 'labstep-web/components/Entity/Can';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityDeleteContainer } from 'labstep-web/containers/Entity/Delete';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalConfirmation from 'labstep-web/core/Modal/Confirmation';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Group } from 'labstep-web/models/group.model';
import { Permission } from 'labstep-web/models/permission.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IEntityActionRevokeProps } from './types';

export const EntityActionRevoke: React.FC<
  IEntityActionRevokeProps
> = ({ entity, activeGroup }) =>
  activeGroup &&
  useHasAccessGroup('owner', activeGroup.id) &&
  entity.owner.id !== activeGroup.id ? (
    <ReadOnMountHOC
      type="entities"
      loading={false}
      entityName={Permission.entityName}
      params={{
        get_single: 1,
        [`${entity.entityName}_id`]: entity.id,
        group_id: activeGroup.id,
      }}
      children={({ entity: loadedPermission }) =>
        loadedPermission ? (
          <EntityDeleteContainer
            entityName={Permission.entityName}
            id={loadedPermission.guid}
          >
            {({ status, deleteEntity }) => (
              <ModalConfirmation
                status={status}
                header="Remove"
                onConfirm={({ toggleModal }) => {
                  deleteEntity({
                    toast: true,
                    onSuccess: toggleModal,
                    meta: {
                      entityName: Permission.entityName,
                      permissionParentEntityId: entity.id,
                      permissionParentEntityName: entity.entityName,
                    },
                  });
                }}
                message={`Are you sure you want to remove this ${getHumanReadableEntityName(
                  entity.entityName,
                )} from this ${Group.getHumanReadableEntityName()}?`}
              >
                {({ toggleModal }) => (
                  <ActionComponent
                    type="option"
                    text="Remove"
                    icon={ICONS.entity.actions.revoke}
                    onClick={toggleModal}
                  />
                )}
              </ModalConfirmation>
            )}
          </EntityDeleteContainer>
        ) : null
      }
    />
  ) : null;

export default withActiveGroup(EntityActionRevoke);
