/**
 * Labstep
 *
 * @module components/Resource/Breadcrumb
 * @desc Breadcrumb for Resource
 */

import React from 'react';
import EntityTemplateBreadcrumbShow from 'labstep-web/components/Entity/Template/Breadcrumb/Show';
import { IResourceBreadcrumbProps } from './types';

export const ResourceBreadcrumb: React.FC<
  IResourceBreadcrumbProps
> = ({ resource }) => (
  <EntityTemplateBreadcrumbShow entity={resource} />
);

export default ResourceBreadcrumb;
