/**
 * Labstep
 *
 * @module screens/Resource/Index/Breadcrumb
 * @desc Resource Index Breadcrumb
 */

import EntityTemplateBreadcrumb from 'labstep-web/components/Entity/Template/Breadcrumb/Index';
import EntityImportActionPending from 'labstep-web/components/EntityImport/Action/Pending';
import ResourceTemplatePreview from 'labstep-web/components/ResourceTemplate/Preview';
import { Resource } from 'labstep-web/models/resource.model';
import { PostFilterTemplate } from 'labstep-web/services/postFilter/filters/template';
import React from 'react';

export const ResourceIndexBreadcrumb: React.FC = () => (
  <>
    <EntityTemplateBreadcrumb
      entityName={Resource.entityName}
      entityPreview={({ entity, ...rest }): React.ReactElement => (
        <ResourceTemplatePreview
          resource={entity as Resource}
          {...rest}
        />
      )}
      postFilter={PostFilterTemplate}
    />
    <EntityImportActionPending
      targetEntityName={Resource.entityName}
    />
  </>
);

export default ResourceIndexBreadcrumb;
