/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Link/Action/Unlink
 * @desc Button to remove a link mark
 */

import React from 'react';
import { removeMarkFromSelectedNode } from 'labstep-web/prosemirror/utils/selection';
import Button from 'labstep-web/core/Button';
import Icon from 'labstep-web/core/Icon';
import { IMenuLinkActionUnlinkProps } from './types';

const MenuLinkActionUnlink: React.FC<IMenuLinkActionUnlinkProps> = ({
  state,
  dispatch,
}) => (
  <Button
    onMouseDown={() => {
      removeMarkFromSelectedNode(state.schema.marks.link)(
        state,
        dispatch,
      );
    }}
  >
    <Icon name="unlink" />
    <span>Unlink</span>
  </Button>
);

export default MenuLinkActionUnlink;
