/**
 * Labstep
 *
 * @module components/Entity/ActionView
 * @desc Core view action
 */

import React from 'react';
import { navigation } from 'labstep-web/services/navigation';
import ActionComponent from 'labstep-web/core/Action/Component';
import { withRouter } from 'react-router';
import { IViewProps } from './types';

export const View: React.FC<IViewProps> = ({
  entityName,
  params,
  history,
  actionComponentProps,
}) => {
  const onClick = () =>
    history.push(navigation.get(`${entityName}_show`, params));

  return (
    <ActionComponent
      type="option"
      text="View"
      icon="eye"
      onClick={onClick}
      {...actionComponentProps}
    />
  );
};

export default withRouter(View);
