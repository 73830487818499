/**
 * Labstep
 *
 * @module containers/ScrollToStep
 */

import React from 'react';
import qs from 'query-string';
import { isEqual } from 'lodash';
import { withRouter } from 'react-router';
import bugsnagService from 'labstep-web/services/bugsnag.service';
import { IScrollToStepProps } from './types';

export const getScrollableParent = (element: HTMLElement) => {
  if (element) {
    const style = window.getComputedStyle(element);
    return style.overflow === 'auto' || style.overflowY === 'auto'
      ? element
      : getScrollableParent(element.parentElement);
  }
  return null;
};

export class ScrollToStepContainer extends React.Component<IScrollToStepProps> {
  constructor(props) {
    super(props);
    this.scrollToStep = this.scrollToStep.bind(this);
  }

  componentDidMount() {
    setTimeout(this.scrollToStep, 100);
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.location.search, prevProps.location.search)
    ) {
      this.scrollToStep();
    }
  }

  scrollToStep() {
    const { location } = this.props;
    const params = qs.parse(location.search);

    if (params.step_id) {
      const step = document.getElementById(
        `protocol-step-${params.step_id}`,
      );

      const scrollableParent = document.getElementById(
        'screens-master-show-center',
      );

      if (step) {
        if (scrollableParent) {
          scrollableParent.scrollTop =
            step.offsetTop - scrollableParent.offsetTop - 10;
        }
      } else {
        bugsnagService.notify(new Error('Step id not found'));
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToStepContainer);
