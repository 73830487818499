/**
 * Labstep
 */

import ActionComponentLabel from 'labstep-web/core/Action/Component/Label';
import Flex from 'labstep-web/core/Flex';
import React from 'react';
import { IEntityStateWorkflowPreviewDetailsProps } from './types';

const EntityStateWorkflowPreviewDetails: React.FC<
  IEntityStateWorkflowPreviewDetailsProps
> = ({ previousEntityState, currentEntityState }) => (
  <Flex
    column
    spacing="gap"
    data-testid={`entity-state-workflow-preview-${currentEntityState.guid}`}
  >
    {previousEntityState.entity_user_role_requirements.map(
      (entityUserRoleRequirement) =>
        entityUserRoleRequirement.signature_requirement && (
          <div
            key={`signature-requirement-${entityUserRoleRequirement.signature_requirement.guid}`}
          >
            <ActionComponentLabel
              icon="edit"
              text={`${entityUserRoleRequirement.number_required} ${entityUserRoleRequirement.entity_user_role.name} Signatures`}
            />
          </div>
        ),
    )}
    {currentEntityState._entity_user_role_requirements.map(
      (entityUserRoleRequirement) => (
        <div
          key={`entity-user-role-requirement-${entityUserRoleRequirement.guid}`}
        >
          <ActionComponentLabel
            icon="user plus"
            text={`Assign ${entityUserRoleRequirement.number_required} ${entityUserRoleRequirement.entity_user_role.name}`}
          />
        </div>
      ),
    )}
  </Flex>
);

export default EntityStateWorkflowPreviewDetails;
