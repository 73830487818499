/**
 * Labstep
 *
 * @module core/Collapsible
 * @desc Collapsible component
 */

import React from 'react';
import { useToggle } from 'labstep-web/hooks/toggle';
import Icon from 'labstep-web/core/Icon';
import { ICollapsibleProps } from './types';
import styles from './styles.module.scss';

export const Collapsible: React.FC<ICollapsibleProps> = ({
  label,
  component,
  initialExpandedState = false,
  withChevron = true,
}) => {
  const [expanded, toggleExpanded] = useToggle(initialExpandedState);
  return (
    <div>
      <div className={styles.clickable} onClick={toggleExpanded}>
        {typeof label === 'function' ? label(expanded) : label}
        {withChevron && (
          <Icon name={expanded ? 'chevron up' : 'chevron down'} />
        )}
      </div>
      {expanded && component}
    </div>
  );
};

export default Collapsible;
