/**
 * Labstep
 *
 * @module components/Metadata/Detail
 * @desc Detail for Metadata Modal
 */

import React from 'react';
import { useLocation } from 'react-router-dom';
import Collapsible from 'labstep-web/core/Collapsible';
import Icon from 'labstep-web/core/Icon';
import Divider from 'labstep-web/core/Divider';
import Flex from 'labstep-web/core/Flex';
import { useProtocolConditionModalContext } from 'labstep-web/components/ProtocolCondition/Modal/context';
import MetadataVariableDetail from '../Variable/Detail';
import MetadataDetailTableSettings from './Table/Settings';
import MetadataDetailValue from './Value';
import { IMetadataDetailProps } from './types';

export const MetadataDetail: React.FC<IMetadataDetailProps> = ({
  metadata,
  entity,
}) => {
  const { state } = useLocation();
  const { isOpen } = useProtocolConditionModalContext();
  const settings = (
    <MetadataDetailTableSettings
      metadata={metadata}
      entity={entity}
    />
  );
  return (
    <Flex column grow>
      {isOpen ? (
        settings
      ) : (
        <Collapsible
          label={(expanded): React.ReactElement => (
            <span>
              <Icon name="cog" />
              {`${expanded ? 'Hide' : 'Show'} Settings`}
            </span>
          )}
          component={settings}
          // true when metadata first created
          initialExpandedState={(state as any)?.expanded}
          withChevron={false}
        />
      )}
      <Divider />
      {metadata.is_variable ? (
        <MetadataVariableDetail
          protocol={entity}
          metadata={metadata}
        />
      ) : (
        <MetadataDetailValue entity={entity} metadata={metadata} />
      )}
    </Flex>
  );
};

export default MetadataDetail;
