/**
 * Labstep
 *
 * @module screens/Notification/Index
 * @desc Notifications Index Screen
 */

import React from 'react';
import Notifications from 'labstep-web/components/Notification/List';
import DocumentHead from 'labstep-web/core/DocumentHead';
import LayoutMainContentCenter from 'labstep-web/components/Layout/MainContent/Center';

const title = 'Notifications';

const Index = () => (
  <>
    <DocumentHead title={title} />
    <LayoutMainContentCenter>
      <Notifications />
    </LayoutMainContentCenter>
  </>
);

export default Index;
