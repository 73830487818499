/**
 * Labstep
 *
 * @module components/Academic/SetupPayment
 * @desc Show modal with wizard to upgrade academic account
 */

import OrganizationPlanManagerPrice from 'labstep-web/components/OrganizationPlan/Manager/Price';
import { defaultCurrency } from 'labstep-web/constants/organization-plan-price';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import { OrganizationPlanPrice } from 'labstep-web/models/organization-plan-price.model';
import { CurrencyISO } from 'labstep-web/services/currency.service';
import React, { useState } from 'react';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { User } from 'labstep-web/models/user.model';
import { StripeContainer } from 'labstep-web/containers/Stripe';
import { intercomService } from 'labstep-web/services/intercom.service';
import { navigation } from 'labstep-web/services/navigation';
import { windowService } from 'labstep-web/services/window.service';
import { AcademicPlusSeats, MaxAcademicPlusSeats } from './Seats';
import {
  AcademicPlusUpgradeProps,
  AcademicPlusUpgradeContainerProps,
} from './types';

export const ROUTE_ID = 'academic-upgrade';

export const AcademicPlusUpgrade: React.FC<
  AcademicPlusUpgradeProps
> = ({
  actionComponentProps,
  userCount,
  createStripeCheckoutSession,
  ...rest
}) => {
  const [activePlanPrice, setActivePlanPrice] =
    useState<OrganizationPlanPrice | null>(null);
  const [priceCurrency, setPriceCurrency] =
    useState<CurrencyISO>(defaultCurrency);

  const [maxSeats, setMaxSeats] = useState(userCount);

  return (
    <ModalWizard
      mode="fullscreen"
      routeId={ROUTE_ID}
      title="Upgrade"
      trigger={({ toggleModal }) => (
        <ActionComponent
          type="button"
          dataTestId="academic-plus-upgrade"
          {...actionComponentProps}
          onClick={() => toggleModal()}
        />
      )}
      steps={['Select a plan', 'Select seats']}
      {...rest}
    >
      <ModalWizard.Step index={0}>
        <OrganizationPlanManagerPrice
          priceCurrency={priceCurrency}
          setPriceCurrency={setPriceCurrency}
          activePlanPrice={activePlanPrice}
          setActivePlanPrice={setActivePlanPrice}
          academic
        />
      </ModalWizard.Step>
      <ModalWizard.Step
        index={1}
        hideHeader
        continueLabel="Setup Payment"
        continueText="Continuing will take you off-site to Stripe’s payment gateway"
        disableContinueButton={maxSeats > MaxAcademicPlusSeats}
        onContinue={() => {
          if (!activePlanPrice) {
            return;
          }
          intercomService.trackEvent(
            'checkout-academic-plus-upgrade',
            {},
          );
          createStripeCheckoutSession(
            {
              price_id: activePlanPrice?.id,
              currency: priceCurrency,
              quantity: maxSeats,
              success_url: navigation.get('root', {}, true),
              cancel_url: window.location.href,
            },
            {
              onSuccess: ({ response }) => {
                windowService.setLocation(response.url);
              },
            },
          );
        }}
      >
        {activePlanPrice && (
          <AcademicPlusSeats
            userCount={userCount}
            priceCurrency={priceCurrency}
            activePlanPrice={activePlanPrice}
            maxSeats={maxSeats}
            setMaxSeats={setMaxSeats}
          />
        )}
      </ModalWizard.Step>
    </ModalWizard>
  );
};

const AcademicPlusUpgradeContainer: React.FC<
  AcademicPlusUpgradeContainerProps
> = ({ actionComponentProps }) => {
  return (
    <ReadOnMountHOC
      type="count"
      entityName={User.entityName}
      params={{
        count: 1,
        is_owner_group_member: 1,
      }}
      children={({ total }) => {
        if (total <= 0) {
          return null;
        }
        if (total < MaxAcademicPlusSeats) {
          return (
            <StripeContainer>
              {({ createStripeCheckoutSession }) => (
                <AcademicPlusUpgrade
                  actionComponentProps={actionComponentProps}
                  userCount={total}
                  createStripeCheckoutSession={
                    createStripeCheckoutSession
                  }
                />
              )}
            </StripeContainer>
          );
        }
        return (
          <div className="academic-upgrade">
            <ActionComponent
              type="button"
              dataTestId="academic-plus-intercom"
              {...actionComponentProps}
            />
          </div>
        );
      }}
    />
  );
};

export default AcademicPlusUpgradeContainer;
