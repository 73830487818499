/**
 * Labstep
 *
 * @module state/actions/ui
 * @desc Redux actions for ui
 */

import {
  CLEAR_ACTIVE_ENTITY,
  SET_ACTIVE_ENTITY,
  SET_CONNECTIVITY,
  TOGGLE_NEW_CONTENT,
} from 'labstep-web/state/constants/ui';
import {
  TypeClearActiveEntity,
  TypeSetActiveEntity,
} from './ui.types';

export const setActiveEntity: TypeSetActiveEntity = (
  entityName,
  id,
) => ({
  type: SET_ACTIVE_ENTITY,
  entityName,
  id,
});

export const clearActiveEntity: TypeClearActiveEntity = () => ({
  type: CLEAR_ACTIVE_ENTITY,
});

export const setConnected = (isConnected: boolean) => ({
  type: SET_CONNECTIVITY,
  isConnected,
});

export const toggleNewContent = () => ({
  type: TOGGLE_NEW_CONTENT,
});

export const printSuccessAction = () => ({
  type: 'PRINT_SUCCESS',
});

/**
 * Show toast
 *
 * @function
 * @param  {object} Toast
 */
export const showToast = (toast: any) => ({
  type: 'CUSTOM_TOAST',
  toast,
});
