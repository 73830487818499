/**
 * Labstep
 *
 * @module screens/Authentication/SAML/Authentication
 * @desc Signin Screen
 */

import { withUiPersistent } from 'labstep-web/containers/UiPersistent';
import Button from 'labstep-web/core/Button';
import AuthenticationSignin from 'labstep-web/screens/Authentication/Signin';
import { windowService } from 'labstep-web/services/window.service';
import React from 'react';
import {
  ISAMLSigninRedirectButtonProps,
  ISamlAuthenticationProps,
} from './types';

export const SAMLSigninRedirectButton: React.FC<
  ISAMLSigninRedirectButtonProps
> = ({ organization }) => (
  <Button color="yellow" fluid route={{ to: 'saml_get_login_url' }}>
    {`Sign in with ${organization.displayName}`}
  </Button>
);

export const SAMLAuthentication: React.FC<
  ISamlAuthenticationProps
> = ({ uiPersistent }) => {
  const organization = windowService.detectOrganization();
  if (!organization) {
    return null;
  }
  const activeShareLink = uiPersistent.active_share_link;
  return (
    <AuthenticationSignin
      right={
        <div>
          <SAMLSigninRedirectButton organization={organization} />
          <p>
            Not a Crick user?{' '}
            <a
              href={`https://app.labstep.com${
                activeShareLink
                  ? `/sharelink/${activeShareLink.token}`
                  : ''
              }`}
            >
              Click here to sign-in / sign-up
            </a>
          </p>
        </div>
      }
    />
  );
};

export default withUiPersistent(SAMLAuthentication);
