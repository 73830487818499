/**
 * Labstep
 *
 * @module core/Logo
 * @desc Logo component
 */

import React from 'react';
import Image from 'labstep-web/core/Image';
import { ILogoProps } from './types';

/**
 * Logo component
 * @return An image of Labstep logo
 */
const Logo: React.FC<ILogoProps> = ({
  simplified,
  size,
  blue,
  ...rest
}) => {
  let logo = 'logo';

  if (simplified) {
    logo = 'logo-simplified';
  }

  if (blue) {
    logo = 'logo-blue';
  }

  return (
    <Image size={size} src={`/img/logo/${logo}.svg`} {...rest} />
  );
};

export default Logo;
