/**
 * Labstep
 *
 * @module core/Redirect
 * @desc Wrapper around react-router Redirect
 */

import React from 'react';
import { navigation } from 'labstep-web/services/navigation';
import { Redirect as ReactRouterRedirect } from 'react-router';
import { IRedirectProps } from './types';

const Redirect: React.FC<IRedirectProps> = ({
  to,
  params,
  state,
}) => (
  <ReactRouterRedirect
    to={{ pathname: navigation.get(to, params), state }}
  />
);

export default Redirect;
