/**
 * Labstep
 *
 * @module core/Select
 * @desc Wrapper around react-select Select
 */

import classNames from 'classnames';
import React from 'react';
import ReactSelect, { SelectInstance } from 'react-select';
import styles from './styles.module.scss';
import './styles.scss';
import { ISelectProps } from './types';

export const Select: React.FC<ISelectProps> = ({
  label,
  onChange,
  keepMenuOpen,
  ...rest
}) => {
  const ref = React.useRef<SelectInstance<any> | null>(null);
  const handleChange = (option) => {
    if (onChange) {
      onChange(option);

      if (keepMenuOpen && ref.current) {
        const currentRef = ref.current;
        setTimeout(() => {
          currentRef.focus();
        }, 1500);
      }
    }
  };
  const component = (
    <ReactSelect
      ref={ref}
      data-testid="select"
      inputId={label ? label.text : undefined}
      className="Select-Search-Container"
      classNamePrefix="Select-Search"
      onChange={handleChange}
      {...rest}
    />
  );
  return label ? (
    <div
      className={classNames({
        [styles[label.position]]: true,
      })}
    >
      <label htmlFor={label.text}>{label.text}</label>
      {component}
    </div>
  ) : (
    component
  );
};

export default Select;
