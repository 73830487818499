/**
 * Labstep
 */

import { Resource } from 'labstep-web/models/resource.model';
import * as sfApi from 'labstep-web/services/sf-api.service';

export const resourceCustomIdentifierActionType =
  'resource_custom_identifier';

export const resourceCustomIdentifierAction = (id: string | number) =>
  sfApi.post({
    type: 'RESOURCE_CUSTOM_IDENTIFIER',
    route: {
      custom: 'app_api_resource_postassigncustomidentifier',
    },
    meta: {
      action_type: resourceCustomIdentifierActionType,
      customReducer: 1,
      normalize: Resource.entityName,
    },
    body: {
      resource_id: id,
    },
  });
