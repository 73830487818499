/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Bar
 * @desc Action Bar for Experiment
 */

import React from 'react';
import Divider from 'labstep-web/core/Divider';
import ExperimentActionLock from 'labstep-web/components/Experiment/Action/Lock';
import ExperimentActionStartEnd from 'labstep-web/components/Experiment/Action/StartEnd';
import EntityActionPrint from 'labstep-web/components/Entity/Action/Print';
import Flex from 'labstep-web/core/Flex';
import styles from './styles.module.scss';
import { IExperimentActionBarProps } from './types';

export const ExperimentActionBar: React.FC<
  IExperimentActionBarProps
> = ({ experiment, editAction, actions }) => (
  <div className={styles.container}>
    <Flex className={styles.actionContainer}>
      <ExperimentActionStartEnd
        experiment={experiment}
        showReverse={false}
      />
      {editAction}
      <EntityActionPrint
        entity={experiment}
        actionComponentProps={{ type: 'text' }}
      />
      <ExperimentActionLock
        experiment={experiment}
        actionComponentProps={{ type: 'text' }}
      />
      {actions}
    </Flex>
    <Divider className={styles.divider} />
  </div>
);

export default ExperimentActionBar;
