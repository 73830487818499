/**
 * Labstep
 */

import {
  PermissionRoleSections,
  PermissionRoleSettingsPostParameters,
} from 'labstep-web/constants/roles';
import { usePremiumFeatureStatus } from 'labstep-web/hooks/premium-feature';
import { PermissionRoleSetting } from 'labstep-web/models/permission-role-setting.model';
import { PermissionRole } from 'labstep-web/models/permission-role.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { ICreateAction } from 'labstep-web/typings';

/**
 * Human readable labels for each permission type
 */
export const LABELS_FOR_PERMISSIONS = {
  all: 'Always',
  none: 'Never',
  author: 'Author',
  assigned: 'If Assigned',
  custom: 'If Assigned',
};

/**
 * @description Sections for SimpleTableCollapsible
 */
export const getSections = () => {
  const sections = Object.keys(PermissionRoleSections).map(
    (entityName, index) => ({
      isInitiallyUncollapsed: index === 0,
      collapsible: true,
      name: getHumanReadableEntityName(entityName, true, true),
      entityName,
      rows: Object.keys(PermissionRoleSections[entityName]).map(
        (permission) => ({
          name: permission,
          entityName,
          permission,
        }),
      ),
    }),
  );

  const premiumFilteredSections = usePremiumFeatureStatus(
    sections,
    'entityName',
    'categories',
  );

  return premiumFilteredSections;
};

/**
 * @description Function to get items for EntityActionUpdateDropdownPill
 */
export const getItems = (
  permissionRole: PermissionRole,
  entityName: string,
  permission: string,
  create: ICreateAction,
) => {
  const items = [
    {
      label: LABELS_FOR_PERMISSIONS.all,
      onClick: () => {
        create(
          {
            type: 'all',
            ...PermissionRoleSettingsPostParameters[entityName][
              permission
            ],
          },
          {},
          permissionRole.entityName,
          permissionRole.guid,
        );
      },
    },
    {
      label: LABELS_FOR_PERMISSIONS.none,
      onClick: () => {
        create(
          {
            type: 'none',
            ...PermissionRoleSettingsPostParameters[entityName][
              permission
            ],
          },
          {},
          permissionRole.entityName,
          permissionRole.guid,
        );
      },
    },
  ];
  if (
    permission !== 'create' &&
    entityName !== 'tag' &&
    entityName !== 'folder' &&
    entityName !== 'group' &&
    entityName !== 'entity_state_workflow'
  ) {
    const label = 'If Assigned';
    items.splice(1, 0, {
      label,
      onClick: () => {
        create(
          {
            type: 'custom',
            ...PermissionRoleSettingsPostParameters[entityName][
              permission
            ],
          },
          {},
          permissionRole.entityName,
          permissionRole.guid,
        );
      },
    });
  }
  return items;
};

export const getExistingPermissionRoleSettingType = (
  permissionRole: PermissionRole,
  entityName: string,
  permission: string,
) => {
  const entityNameAction =
    PermissionRoleSettingsPostParameters[entityName][permission];

  const existingPermissionRoleSetting:
    | PermissionRoleSetting
    | undefined = permissionRole.permission_role_settings.find(
    (permissionRoleSetting: PermissionRoleSetting) =>
      permissionRoleSetting &&
      permissionRoleSetting.entity_name ===
        entityNameAction.entity_name &&
      permissionRoleSetting.action === entityNameAction.action,
  );

  if (existingPermissionRoleSetting) {
    return LABELS_FOR_PERMISSIONS[existingPermissionRoleSetting.type];
  }
  return LABELS_FOR_PERMISSIONS.none;
};
