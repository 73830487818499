/**
 * Labstep
 *
 * @module components/ProtocolValue/Check
 * @desc Experiment Value item
 */

import React from 'react';
import ProtocolValueAmountDeductedStatus from 'labstep-web/components/ProtocolValue/AmountDeductedStatus';
import ProtocolValueCheckResourceItemOutput from './ResourceItemOutput';
import { IProtocolValueCheckProps } from './types';

export const ProtocolValueCheck: React.FC<
  IProtocolValueCheckProps
> = ({ protocolValue }) =>
  protocolValue.is_input ? (
    <ProtocolValueAmountDeductedStatus
      protocolValue={protocolValue}
      showNotSpecifiedIfApplicable
    />
  ) : (
    <ProtocolValueCheckResourceItemOutput
      protocolValue={protocolValue}
    />
  );

export default ProtocolValueCheck;
