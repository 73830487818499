/**
 * Labstep
 *
 * @module models/automation
 * @desc Typescript export class for EntityState
 */

import { Type } from 'class-transformer';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { Entity } from './entity.model';
import { Group } from './group.model';
import { User } from './user.model';

// eslint-disable-next-line no-shadow
export enum AutomationTriggerType {
  log = 'log',
  schedule = 'schedule',
  fixedDate = 'fixed_date',
}

// eslint-disable-next-line no-shadow
export enum AutomationTriggerInterval {
  fiveMinutes = 'five_minutes',
  halfHour = 'half_hour',
  hour = 'hour',
  day = 'day',
  week = 'week',
}

export const AUTOMATION_TRIGGER_INTERVAL_LABELS: Record<
  string,
  string
> = {
  [AutomationTriggerInterval.fiveMinutes]: 'Run every 5 minutes',
  [AutomationTriggerInterval.halfHour]: 'Run every 30 minutes',
  [AutomationTriggerInterval.hour]: 'Run every hour',
  [AutomationTriggerInterval.day]: 'Run every day',
  [AutomationTriggerInterval.week]: 'Run every week',
};

export class Automation extends Entity {
  public static readonly entityName = 'automation';

  public static readonly entityClass = 'automation' as const;

  public get entityName(): string {
    return Automation.entityName;
  }

  public constructor(data: Partial<Automation> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public trigger_at!: string;

  public triggered_at!: string;

  public name!: string;

  public type!: string;

  public trigger_type!: string;

  public trigger_log_type!: string;

  public trigger_interval!: string;

  public filter!: Record<string, unknown>;

  public action!: string;

  public payload!: Record<string, unknown>;

  @Type(() => Group)
  public owner!: Group;

  @Type(() => User)
  public author!: User;

  get key(): string {
    return `${this.type}:${this.action}`;
  }

  get display(): string {
    if (this.trigger_type === AutomationTriggerType.schedule) {
      return AUTOMATION_TRIGGER_INTERVAL_LABELS[
        this.trigger_interval
      ];
    }
    if (this.trigger_type === AutomationTriggerType.fixedDate) {
      return 'Fixed date';
    }

    return 'Custom';
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
