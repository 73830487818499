/**
 * Labstep
 *
 * @module components/Entity/Action/Share
 * @desc Share an entity using a ShareLink
 */

import React from 'react';
import Modal from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import CreateShareLinkContainer from 'labstep-web/components/ShareLink/Action/Create';
import ShareLinkInfo from 'labstep-web/components/ShareLink/Info';
import PermissionManager from 'labstep-web/components/Permission/Manager';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { Limits, LimitTypes } from 'labstep-web/constants/limits';
import { useHasExceededLimit } from 'labstep-web/hooks/limits/limits';
import AcademicActionUpgrade from 'labstep-web/components/Academic/Action/Upgrade';
import Desktop from 'labstep-web/core/MediaQuery/Desktop';
import { Group } from 'labstep-web/models/group.model';
import { User } from 'labstep-web/models/user.model';
import { ICONS } from 'labstep-web/constants/icons';
import { IShareEntityActionProps } from './types';

const ShareEntityAction: React.FC<IShareEntityActionProps> = ({
  entity,
  actionComponentProps,
}) => {
  const showUserLimitWarning = useHasExceededLimit(
    entity instanceof Group ? entity.user_count : -1,
    LimitTypes.MAX_USERS_PER_GROUP,
  );

  const entityDisplayName = getHumanReadableEntityName(
    entity instanceof Group ? User.entityName : '',
    false,
    true,
  );

  return (
    <Can
      entityName={entity.entityName}
      id={entity.idAttr}
      action={Action.share}
    >
      <Modal
        size="small"
        header={
          showUserLimitWarning
            ? `${entityDisplayName} Limit Reached`
            : `Share ${getHumanReadableEntityName(
                entity.entityName,
                false,
                true,
              )}`
        }
        headerColor={showUserLimitWarning ? 'red' : undefined}
        viewComponent={({ toggleModal }) => (
          <ActionComponent
            type={showUserLimitWarning ? 'button' : 'option'}
            onClick={toggleModal}
            icon="share alternate"
            text="Share"
            {...actionComponentProps}
          />
        )}
        content={
          showUserLimitWarning ? (
            <div>
              <p>
                You cannot invite more users as there is a limit of{' '}
                {Limits[LimitTypes.MAX_USERS_PER_GROUP]} users per
                workspace on the Free Tier.
              </p>
              <p>
                Contact{' '}
                <a href="mailto:info@labstep.com">info@labstep.com</a>{' '}
                to upgrade to Academic Plus for up to 50 users or
                Academic Institute for unlimited users.
              </p>
            </div>
          ) : (
            <CreateShareLinkContainer parentEntity={entity as any}>
              {({ shareLink }) => (
                <>
                  <ShareLinkInfo
                    shareLink={shareLink}
                    parentEntity={entity as any}
                  />
                  {entity.entityName !== 'group' && (
                    <PermissionManager parentEntity={entity as any} />
                  )}
                </>
              )}
            </CreateShareLinkContainer>
          )
        }
        actions={[]}
      />
    </Can>
  );
};

export default ShareEntityAction;
