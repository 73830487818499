/**
 * Labstep
 *
 * @module components/Filter/AssignedTo
 * @desc Filter Assigned To
 */

import React from 'react';
import FilterUser from 'labstep-web/components/Filter/User';
import { STRINGS } from 'labstep-web/strings';
import { IFilterAssignedToProps } from './types';

export const FilterAssignedTo: React.FC<IFilterAssignedToProps> = (
  props,
) => (
  <FilterUser
    {...props}
    type="assignee"
    header={STRINGS.filter.header.assigned_to}
    icon="user plus"
  />
);

export default FilterAssignedTo;
