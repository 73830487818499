/**
 * Labstep
 *
 * @module screens/Organization/Show/Center
 * @desc Organization Show Center
 */

import EntityExportManager from 'labstep-web/components/EntityExport/Manager';
import LayoutLinksVertical from 'labstep-web/components/Layout/Links/Vertical';
import LayoutLinksVerticalMobile from 'labstep-web/components/Layout/Links/Vertical/Mobile';
import { ILayoutLinksVerticalGroupsProps } from 'labstep-web/components/Layout/Links/Vertical/types';
import { IEntityCardLink } from 'labstep-web/components/Layout/Links/types';
import OrganizationActivity from 'labstep-web/components/Organization/Activity';
import OrganizationGroupManager from 'labstep-web/components/Organization/GroupManager';
import OrganizationHeader from 'labstep-web/components/Organization/Header';
import OrganizationSettings from 'labstep-web/components/Organization/Settings';
import OrganizationPlanShow from 'labstep-web/components/OrganizationPlan/Show';
import PermissionRoleManager from 'labstep-web/components/PermissionRole/Manager';
import SecurityLogManager from 'labstep-web/components/SecurityLog/Manager';
import UserOrganizationManager from 'labstep-web/components/UserOrganization/Manager';
import { ICONS } from 'labstep-web/constants/icons';
import Mobile from 'labstep-web/core/MediaQuery/Mobile';
import React from 'react';
import styles from './styles.module.scss';
import { IScreensOrganizationShowCenterProps } from './types';

export const ScreensOrganizationShowCenter: React.FC<
  IScreensOrganizationShowCenterProps
> = ({ organization }) => {
  let links: IEntityCardLink[] = [
    {
      children: 'Workspaces',
      icon: ICONS.group.primary,
      route: {
        to: 'organization_show',
        params: { identifier: organization.identifier },
      },
      render: (): React.ReactNode => (
        <OrganizationGroupManager organization={organization} />
      ),
    },
  ];

  if (organization.logged_user_user_organization.type !== 'guest') {
    links = [
      ...links,
      {
        children: 'Users',
        icon: ICONS.user.secondary,
        route: {
          to: 'organization_show_users',
          params: { identifier: organization.identifier },
        },
        render: (): React.ReactNode => (
          <UserOrganizationManager organization={organization} />
        ),
      },
    ];

    links = [
      ...links,
      {
        children: 'Roles & Permissions',
        icon: 'user circle',
        route: {
          to: 'organization_show_permissions',
          params: { identifier: organization.identifier },
        },
        render: (): React.ReactNode => (
          <div className="organization-permissions">
            <PermissionRoleManager organization={organization} />
          </div>
        ),
      },
    ];
  }

  let adminLinks: IEntityCardLink[] = [];
  let groups: ILayoutLinksVerticalGroupsProps[] = [];

  if (organization.logged_user_user_organization.type === 'admin') {
    if (
      organization.is_stripe_subscription_set ||
      organization.is_trialling
    ) {
      adminLinks = [
        ...adminLinks,
        {
          children: 'Plans & Billing',
          icon: 'credit card',
          route: {
            to: 'organization_show_billing',
            params: { identifier: organization.identifier },
          },
          render: (): React.ReactNode => (
            <div className="organization-billing">
              <OrganizationPlanShow organization={organization} />
            </div>
          ),
        },
      ];
    }
    if (organization.metabase_url) {
      adminLinks = [
        {
          children: 'Activity Dashboards',
          icon: 'chart bar',
          route: {
            to: 'organization_show_activity',
            params: { identifier: organization.identifier },
          },
          render: (): React.ReactNode => (
            <OrganizationActivity organization={organization} />
          ),
        },
        ...adminLinks,
      ];
    }

    if (organization.hasFeatureEnabled('security_log')) {
      adminLinks = [
        ...adminLinks,
        {
          children: 'Security Logs',
          icon: 'lock',
          route: {
            to: 'organization_show_security',
            params: { identifier: organization.identifier },
          },
          render: (): React.ReactNode => (
            <SecurityLogManager organization={organization} />
          ),
        },
        {
          children: 'Exports Logs',
          icon: 'download',
          route: {
            to: 'organization_show_export',
            params: { identifier: organization.identifier },
          },
          render: (): React.ReactNode => (
            <EntityExportManager organization={organization} />
          ),
        },
      ];
    }

    adminLinks = [
      ...adminLinks,
      {
        children: 'Settings',
        icon: 'cogs',
        route: {
          to: 'organization_show_settings',
          params: { identifier: organization.identifier },
        },
        render: (): React.ReactNode => (
          <OrganizationSettings organization={organization} />
        ),
      },
    ];

    groups = [
      {
        label: 'Admin',
        links: adminLinks,
      },
    ];
  }

  return (
    <div className={styles.container}>
      <OrganizationHeader organization={organization} size="tiny" />
      <Mobile>
        {({ match }) =>
          match ? (
            <LayoutLinksVerticalMobile
              groups={groups}
              links={links}
            />
          ) : (
            <LayoutLinksVertical links={links} groups={groups} />
          )
        }
      </Mobile>
    </div>
  );
};

export default ScreensOrganizationShowCenter;
