/**
 * Labstep
 *
 * @module screens/Resource/Show/Right/OrderRequests
 * @desc Resource Show Right
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import OrderRequestActionCreate from 'labstep-web/components/OrderRequest/Action/Create';
import OrderRequestActionUpdateStatus from 'labstep-web/components/OrderRequest/Action/UpdateStatus';
import ResourceActionEditOrderRequestTemplate from 'labstep-web/components/Resource/Action/EditOrderRequestTemplate';
import { ICONS } from 'labstep-web/constants/icons';
import ElementCard from 'labstep-web/core/Card/Element';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Flex from 'labstep-web/core/Flex';
import List from 'labstep-web/core/List';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { STRINGS } from 'labstep-web/strings';
import React from 'react';
import PremiumFeatureScreenGuard from 'labstep-web/core/PremiumFeature/ScreenGuard';
import { IScreensResourceShowRightOrderRequestsProps } from './types';

const ScreensResourceShowRightOrderRequests: React.FC<
  IScreensResourceShowRightOrderRequestsProps
> = ({ resource }) => (
  <PremiumFeatureScreenGuard
    premiumFeature="order_management"
    unstyled
  >
    <ReadOnMountHOC
      type="cursor"
      entityName={OrderRequest.entityName}
      params={{
        count: 10,
        resource_id: resource.id,
        is_template: false,
      }}
      children={({ entities, total }) =>
        total > 0 ? (
          <>
            <Flex>
              <OrderRequestActionCreate
                actionComponentProps={{
                  icon: ICONS.order_request.primary,
                  type: 'text',
                }}
                resource={resource}
              />
              <ResourceActionEditOrderRequestTemplate
                actionComponentProps={{
                  type: 'text',
                }}
                resource={resource}
              />
            </Flex>
            <List>
              {entities.map((orderRequest) => (
                <ElementCard
                  key={orderRequest.id}
                  name={
                    <EntityLink
                      entity={orderRequest}
                      showIcon
                      displayName={`#${orderRequest.id}`}
                    />
                  }
                  detail={
                    <Flex
                      grow
                      hAlignContent="right"
                      vAlignContent="center"
                      style={{ justifyContent: 'space-around' }}
                    >
                      {orderRequest.printQuantity}
                      <OrderRequestActionUpdateStatus
                        orderRequest={orderRequest}
                        withAdditionalInfo
                      />
                    </Flex>
                  }
                  right={orderRequest.printAmount}
                />
              ))}
            </List>
          </>
        ) : (
          <EmptyState
            src="/img/empty-state/orderRequests.svg"
            explanation={STRINGS.order_request.empty_state}
            action={
              <OrderRequestActionCreate
                actionComponentProps={{
                  type: 'button',
                  text: 'New Request',
                }}
                resource={resource}
              />
            }
            secondaryAction={
              <ResourceActionEditOrderRequestTemplate
                actionComponentProps={{
                  type: 'button',
                  elementProps: { basic: true },
                }}
                resource={resource}
              />
            }
          />
        )
      }
    />
  </PremiumFeatureScreenGuard>
);

export default ScreensResourceShowRightOrderRequests;
