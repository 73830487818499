import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import { DefaultSort } from 'labstep-web/components/Entity/Table/SortOptions/types';
import { Filter } from 'labstep-web/components/Filter/Menu/types';

export const filters = [
  Filter.requested_by,
  Filter.assigned_to,
  Filter.date_range,
  Filter.tag,
  Filter.deleted,
  Filter.order_request_status,
];

export const bulkActions = [
  BulkAction.delete,
  BulkAction.tag,
  BulkAction.order_request_update_urgent,
  BulkAction.order_request_update_status,
  BulkAction.order_request_set_purchase_order,
  BulkAction.transfer_ownership,
  BulkAction.share,
];

export const sortOptions = {
  name: {
    label: 'Resource',
    default_sort: DefaultSort.asc,
  },
  received_at: {
    label: 'Date Received',
    default_sort: DefaultSort.desc,
  },
};
