/**
 * Labstep
 *
 * @module core/DropboxPicker
 * @desc DropboxPicker component
 */

import Picker from 'labstep-web/components/File/Action/Create/Picker';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import React from 'react';
import { IDropboxPickerProps } from './types';

export class DropboxPicker extends React.Component<IDropboxPickerProps> {
  static defaultProps = {
    multiple: false,
  };

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { create, options, onSelect, multiple } = this.props;

    const onSuccess = (body) => {
      create(body, options);
    };

    window.Dropbox.choose({
      multiselect: multiple,
      success: (files) => {
        const filesToUpload = files.map((file) => ({
          path: file.link,
          size: file.bytes,
          name: file.name,
          mime_type: file.name.split('.').pop(),
          link_source: 'dropbox',
        }));

        if (onSelect) {
          onSelect(filesToUpload);
        }

        filesToUpload.forEach((file) => {
          onSuccess(file);
        });
      },
    });
  }

  render() {
    return (
      <Picker onClick={this.onClick} icon="dropbox" text="Dropbox" />
    );
  }
}

const DropboxPickerFileContainer = ({
  parentName,
  parentId,
  ...rest
}) => (
  <EntityCreateContainer
    entityName="file"
    parentName={parentName}
    parentId={parentId}
  >
    {({ create }) => <DropboxPicker create={create} {...rest} />}
  </EntityCreateContainer>
);

export default DropboxPickerFileContainer;
