/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/Break
 * @desc Line break to separate sections on toolbar
 */

import React from 'react';
import styles from './styles.module.scss';

const Break = () => (
  <div className={styles.breakContainer}>
    <div className={styles.break} />
  </div>
);

export default Break;
