/**
 * Labstep
 *
 * @module components/Layout/FullPageMessage
 * @desc Generic Component for displaying page errors
 */

import React from 'react';
import Image from 'labstep-web/core/Image';
import Header from 'labstep-web/core/Header';
import styles from './styles.module.scss';
import { ILayoutFullPageMessageProps } from './types';

export const LayoutFullPageMessage: React.FC<
  ILayoutFullPageMessageProps
> = ({ src, title, explanation, action, additional }) => (
  <div className={styles.container}>
    <Image className={styles.image} centered src={src} />
    <Header size="large">{title}</Header>
    <Header size="small">{explanation}</Header>
    {action}
    <br />
    {additional}
  </div>
);

export default LayoutFullPageMessage;
