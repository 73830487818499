/**
 * Labstep
 *
 * @module components/UserOrganization/Action/Menu
 */

import UserActionToggleSaml from 'labstep-web/components/User/Action/ToggleSaml';
import UserOrganizationActionDelete from 'labstep-web/components/UserOrganization/Action/Delete';
import UserOrganizationActionManageWorkspaces from 'labstep-web/components/UserOrganization/Action/ManageWorkspaces';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { IUserOrganizationActionMenuProps } from './types';

export const UserOrganizationActionMenu: React.FC<
  IUserOrganizationActionMenuProps
> = ({ userOrganization, authenticatedUserEntity }) => {
  return (
    <ActionMenu type="icon">
      <UserOrganizationActionDelete
        userOrganization={userOrganization}
      />
      <UserOrganizationActionManageWorkspaces
        userOrganization={userOrganization}
      />
      {authenticatedUserEntity.isOrganizationAdmin && (
        <UserActionToggleSaml
          user={userOrganization.user}
          organization={userOrganization.organization}
        />
      )}
    </ActionMenu>
  );
};

export default withAuthenticatedUserEntity(
  UserOrganizationActionMenu,
);
