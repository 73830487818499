/**
 * Labstep
 *
 * @module components/PurchaseOrder/Details
 * @desc Shows actions + order request table
 */

import BulkActions from 'labstep-web/components/Entity/Search//BulkActions';
import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import Container from 'labstep-web/components/Layout/Container';
import { OrderRequestEmptyState } from 'labstep-web/components/OrderRequest/Card/EmptyState';
import OrderRequestTable from 'labstep-web/components/OrderRequest/Table';
import PurchaseOrderActionAddOrderRequest from 'labstep-web/components/PurchaseOrder/Action/AddOrderRequest';
import PurchaseOrderActionUpdateStatus from 'labstep-web/components/PurchaseOrder/Action/UpdateStatus';
import PurchaseOrderFormShowEditDiscount from 'labstep-web/components/PurchaseOrder/Form/ShowEdit/Discount';
import PurchaseOrderFormShowEditHandling from 'labstep-web/components/PurchaseOrder/Form/ShowEdit/Handling';
import PurchaseOrderFormShowEditTaxRate from 'labstep-web/components/PurchaseOrder/Form/ShowEdit/TaxRate';
import { BulkSelectContainer } from 'labstep-web/containers/BulkSelect';
import Flex from 'labstep-web/core/Flex';
import Table from 'labstep-web/core/Table';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import React from 'react';
import styles from './styles.module.scss';
import { IPurchaseOrderDetailsProps } from './types';

export const PurchaseOrderDetails: React.FC<
  IPurchaseOrderDetailsProps
> = ({ purchaseOrder }) => {
  const orderRequests = purchaseOrder.nonAttachedOrderRequests;

  return (
    <>
      {orderRequests.length === 0 ? (
        <OrderRequestEmptyState
          explanation="Add new or existing order requests to this order."
          action={
            <PurchaseOrderActionAddOrderRequest
              purchaseOrder={purchaseOrder}
            />
          }
        />
      ) : (
        <>
          <Container>
            <Flex>
              <PurchaseOrderActionUpdateStatus
                purchaseOrder={purchaseOrder}
              />
              <PurchaseOrderActionAddOrderRequest
                purchaseOrder={purchaseOrder}
              />
            </Flex>
          </Container>

          <BulkSelectContainer
            total={orderRequests.length}
            visibleEntities={orderRequests}
          >
            <>
              <BulkActions
                entityName={OrderRequest.entityName}
                bulkActions={
                  purchaseOrder.status === 'open'
                    ? [
                        BulkAction.order_request_remove_purchase_order,
                        BulkAction.order_request_update_status,
                      ]
                    : [BulkAction.order_request_update_status]
                }
              />
              <OrderRequestTable
                withAdditionalInfo={false}
                totalPages={1}
                emptyState={null}
                actions={[]}
                orderRequests={orderRequests}
                withPrice
                withCheckbox={purchaseOrder.status !== 'completed'}
                footer={
                  <Table.Row>
                    <Table.Cell
                      className={styles.footerCell}
                      textAlign="right"
                      style={{
                        paddingRight: 40,
                      }}
                    >
                      <div>
                        <div>
                          <b>Sub Total (Excl. VAT): </b>
                          <span style={{ padding: '5px' }}>
                            {purchaseOrder.printSubTotal}
                          </span>
                        </div>
                        <br />
                        <div>
                          <div>Handling: </div>
                          <PurchaseOrderFormShowEditHandling
                            purchaseOrder={purchaseOrder}
                          />
                        </div>

                        <br />

                        <div>
                          <div>Discount: </div>
                          <PurchaseOrderFormShowEditDiscount
                            purchaseOrder={purchaseOrder}
                          />
                        </div>

                        <br />

                        <div>
                          <b>Total (Excl. VAT): </b>
                          <span style={{ padding: '5px' }}>
                            {purchaseOrder.printTotal}
                          </span>
                        </div>

                        <br />

                        <div>
                          <div>VAT: </div>
                          <PurchaseOrderFormShowEditTaxRate
                            purchaseOrder={purchaseOrder}
                          />
                        </div>

                        <br />

                        <div>
                          <b>Total (Incl. VAT): </b>
                          <span style={{ padding: '5px' }}>
                            {purchaseOrder.printTotalWithVat}
                          </span>
                        </div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                }
              />
            </>
          </BulkSelectContainer>
        </>
      )}
    </>
  );
};

export default PurchaseOrderDetails;
