/**
 * Labstep
 *
 * @module core/Form/ShowEdit
 * @desc A component to toggle between Show and Edit mode
 * Renders children (and header if passed) and a pencil
 * icon next to them which on click toggles to edit mode
 */

import classnames from 'classnames';
import { ToggleContainer } from 'labstep-web/containers/Toggle';
import ActionComponent from 'labstep-web/core/Action/Component';
import Edit from 'labstep-web/core/Form/Edit';
import Icon from 'labstep-web/core/Icon';
import ModalDefault from 'labstep-web/core/Modal/Default';
import Popup from 'labstep-web/core/Popup';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import omit from 'lodash/omit';
import replace from 'lodash/replace';
import React from 'react';
import styles from './styles.module.scss';
import {
  IShowEditComponentProps,
  IShowEditProps,
  IShowEditToggleComponentProps,
} from './types';

export const ShowEditToggleComponent: React.FC<
  IShowEditToggleComponentProps
> = ({
  canEdit,
  placeholder,
  customPlaceholder,
  label,
  explainer,
  children,
  customShow,
  hasValue,
  toggledComponent,
  name,
  className,
  initialEditingState,
  secret,
}) => (
  <ToggleContainer initialEditingState={initialEditingState}>
    {({ toggle, toggled, close }): React.ReactElement => {
      const labelComponent = label && (
        <div className={styles.label} data-public>
          {label}
          {explainer && (
            <Popup
              key={1}
              inverted
              content={explainer}
              trigger={<Icon name="question circle outline" />}
            />
          )}
          :
        </div>
      );
      let render;
      let viewComponent;
      if (!canEdit) {
        render = (
          <div className={styles.paddedContainer}>
            {hasValue ? (
              children
            ) : (
              <TextPlaceholder>{`No ${name}`}</TextPlaceholder>
            )}
          </div>
        );
      } else {
        const edit = toggledComponent({ toggle, close });

        const show = customShow ? (
          customShow({ toggle })
        ) : (
          <div
            data-testid="show-edit-toggle"
            className={styles.toggleContainer}
            onClick={toggle}
          >
            {secret ? '********' : children}
          </div>
        );

        const placeholderComponent = customPlaceholder ? (
          customPlaceholder({ toggle })
        ) : (
          <ActionComponent
            dataTestId="show-edit-toggle"
            type="text"
            text={placeholder || `Specify ${name}`}
            onClick={toggle}
            elementProps={{ showEdit: true }}
          />
        );

        viewComponent = hasValue ? show : placeholderComponent;

        render = toggled ? edit : viewComponent;
      }

      return (
        <div
          data-private
          className={classnames(
            'show-edit',
            styles.container,
            className,
            { toggled },
          )}
        >
          {labelComponent}
          <div className={styles.innerContainer}>{render}</div>
        </div>
      );
    }}
  </ToggleContainer>
);

export const ShowEditComponent: React.FC<IShowEditComponentProps> = ({
  formProps: { options = {}, ...formRestProps },
  inModal,
  loadDefaultValues,
  preSubmit,
  ...rest
}) => (
  <ShowEditToggleComponent
    {...rest}
    toggledComponent={({ toggle }): React.ReactElement => {
      const editForm = (
        <Edit
          loadDefaultValues={loadDefaultValues}
          preSubmit={preSubmit}
          autoFocus
          {...formRestProps}
          options={enhanceOptions({
            options,
            onSuccess: toggle,
          })}
          toggleEdit={toggle}
        />
      );
      if (inModal) {
        return (
          <ModalDefault
            header={`Editing ${rest.name}`}
            viewComponent={null}
            content={editForm}
            initialModalState
            onClose={toggle}
          />
        );
      }
      return editForm;
    }}
  />
);

export const ShowEdit: React.FC<IShowEditProps> = (props) => {
  const {
    canEdit,
    children,
    field,
    placeholder,
    customShow,
    customPlaceholder,
    value,
    label,
    ...rest
  } = props;

  const name = field.fieldLabel || replace(field.name, /_/g, ' ');

  const hasValue =
    (value !== undefined ? value : rest.entity[field.name]) !== null;

  const formProps = {
    fields: [
      { ...omit(field, ['fieldLabel']) },
    ] as IShowEditComponentProps['formProps']['fields'],
    ...rest,
  };

  return (
    <ShowEditComponent
      name={name}
      formProps={formProps}
      canEdit={canEdit}
      placeholder={placeholder}
      children={children}
      customShow={customShow}
      hasValue={hasValue}
      customPlaceholder={customPlaceholder}
      label={label}
      explainer={field.fieldExplainer}
    />
  );
};

export default ShowEdit;
