/**
 * Labstep
 *
 * @module components/Tag/SearchSelect
 * @desc Search tags
 */

import React from 'react';
import SearchSelect from 'labstep-web/core/Select/Search';
import { Tag } from 'labstep-web/models/tag.model';
import { ITagSearchSelectProps } from './types';

const TagSearchSelect: React.FC<ITagSearchSelectProps> = ({
  onChange,
  ...rest
}) => (
  <SearchSelect
    entityName={Tag.entityName}
    creatable
    onChange={(tag) => onChange(tag)}
    value={null}
    {...rest}
  />
);

export default TagSearchSelect;
