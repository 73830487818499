/**
 * Labstep
 *
 * @desc Component to determine if user has permissions
 * for certain action
 */

import React from 'react';
import {
  useHasAccess,
  useHasAccessCreate,
  useHasAccessGroup,
} from './hooks';
import { ICanCreateProps, ICanGroupProps, ICanProps } from './types';

export { useHasAccess, useHasAccessCreate, useHasAccessGroup };

export const CanCreate: React.FC<ICanCreateProps> = ({
  parentName,
  parentId,
  entityName,
  children,
}) =>
  useHasAccessCreate(entityName, parentName, parentId) ? (
    <>{children}</>
  ) : null;

export const CanGroup: React.FC<ICanGroupProps> = ({
  action,
  groupId,
  children,
}) => (useHasAccessGroup(action, groupId) ? <>{children}</> : null);

const Can: React.FC<ICanProps> = ({
  entityName,
  id,
  action,
  children,
  field,
}) =>
  useHasAccess(entityName, id, action, field) ? (
    <>{children}</>
  ) : null;

export default Can;
