/**
 * Labstep
 *
 * @module components/Resource/Form/ResourceTemplate
 * @desc ShowEdit Form for resource template field
 */

import React from 'react';
import EntityFormShowEditTemplate from 'labstep-web/components/Entity/Form/ShowEdit/Template';
import { Resource } from 'labstep-web/models/resource.model';
import { IResourceFormResourceTemplateProps } from './types';

export const ResourceFormResourceTemplate: React.FC<
  IResourceFormResourceTemplateProps
> = ({ resource, ...rest }) => (
  <EntityFormShowEditTemplate
    entity={resource}
    childEntityName={Resource.entityName}
    {...rest}
  />
);

export default ResourceFormResourceTemplate;
