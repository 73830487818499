/**
 * Labstep
 *
 * @module services/react
 * @desc Helper methods for react
 */

export const objectOrFunction: any = (component, props) => {
  if (typeof component === 'function') {
    return component(props);
  }
  return component;
};

/** Call a function if defined */
export const callIf = (f: any, ...args) => {
  if (f && typeof f === 'function') {
    return f(...args);
  }
  return null;
};
