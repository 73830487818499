/**
 * Labstep
 *
 * @module state/selectors/authenticatedUser
 * @desc Selectors for logged User
 */

import get from 'lodash/get';
import { createSelector } from 'reselect';
import { User } from 'labstep-web/models/user.model';
import { hasKey, isEmpty } from './helpers';
import { selectEntity } from './entity';

/**
 * @constant Selector for authenticated user by id
 */
export const selectAuthenticatedUser = createSelector(
  (state: any): any => state.authenticatedUser,
  (authenticatedUser: any): any =>
    isEmpty(authenticatedUser.byId) ? false : authenticatedUser.byId,
);

/**
 * @constant Selector for authenticated user by username
 */
export const selectAuthenticatedUsername = createSelector(
  selectAuthenticatedUser,
  (authenticatedUser: any): any =>
    authenticatedUser ? authenticatedUser.username : null,
);

/**
 * @constant Selector for authenticated user by username
 */
export const selectAuthenticatedUserId = createSelector(
  selectAuthenticatedUser,
  (authenticatedUser: any): any =>
    authenticatedUser ? authenticatedUser.id : null,
);

/**
 * @constant Selector for authenticated user by users
 *
 * Please note that this returns a normalized version of the user entity
 * except for the profile
 */
export const selectAuthenticatedUserEntity = createSelector(
  (state: any): any => {
    const id: string = selectAuthenticatedUserId(state);
    const user: any = state.entities.user.byId[id];
    return user ? selectEntity(state, 'user', id) : false;
  },
  (entity: User) => entity,
);

/**
 * @constant Selector for login status
 */
export const selectLoginStatus = createSelector(
  (state: any): any => state.authenticatedUser.logging,
  (logging: any): any => get(logging, 'authenticated_user.status'),
);

/**
 * @constant Selector for authenticated user load status
 */
export const selectAuthenticatedUserLoadStatus = createSelector(
  (state: any): any => state.entities.user.readingIds,
  (state: any): any => selectAuthenticatedUserId(state),
  (readingIds: any, id: string): any =>
    hasKey(readingIds, id) ? readingIds[id].status : false,
);

/**
 * @constant Selector for token_saml_login
 */
export const selectTokenSamlLoginUuid = createSelector(
  (state: any): any => state.authenticatedUser,
  (authenticatedUser: any): string | null =>
    authenticatedUser?.token_saml_login,
);
