/**
 * Labstep
 *
 * @module components/Experiment/List
 * @desc Experiment List
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import ResourceItemActionMenu from 'labstep-web/components/ResourceItem/Action/Menu';
import { ICONS } from 'labstep-web/constants/icons';
import ElementCard from 'labstep-web/core/Card/Element';
import List from 'labstep-web/core/List';
import React from 'react';
import styles from './styles.module.scss';
import { IResourceItemListProps } from './types';

const ResourceItemList: React.FC<IResourceItemListProps> = ({
  resourceItems,
  actions,
}) => (
  <List className={styles.feed} id="resource-item-list">
    {resourceItems.map((resourceItem) => (
      <ElementCard
        key={resourceItem.id}
        icon={ICONS.resource_item.primary}
        name={<EntityLink entity={resourceItem} />}
        actions={
          <ResourceItemActionMenu
            resourceItem={resourceItem}
            actions={actions}
          />
        }
      />
    ))}
  </List>
);

export default ResourceItemList;
