/**
 * Labstep
 */

import FullPageError from 'labstep-web/components/Layout/FullPageError';
import Button from 'labstep-web/core/Button';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import {
  defaultFetchingStatus,
  defaultNotFetchingStatus,
} from 'labstep-web/state/selectors/helpers';
import { IStatus } from 'labstep-web/typings';
import React from 'react';
import styles from './styles.module.scss';

export const genericError =
  'Oops something went wrong. Please try refreshing the page.';

export const getStatus = (
  status: IStatus,
  defaultFetching?: boolean,
) =>
  defaultFetching
    ? defaultFetchingStatus(status)
    : defaultNotFetchingStatus(status);

export const getError = (
  error: IStatus['error'],
  fullpage?: boolean,
) => {
  const { status } = error;
  if (status) {
    if (status === 404) {
      return (
        <div className={styles.fullCard}>
          <EmptyState
            src="/img/error/404.svg"
            title="Oops!"
            explanation="Looks like the page you’re looking for doesn’t exist"
            action={
              <Button primary route={{ to: 'app_homepage' }}>
                Back to home
              </Button>
            }
          />
        </div>
      );
    }
    if (status === 403) {
      return (
        <div className={styles.fullCard}>
          <EmptyState
            src="/img/error/locked.svg"
            title="You don't have the right permissions to view this content"
            explanation=""
          />
        </div>
      );
    }
    if (status === 401) {
      return (
        <div className={styles.fullCard}>
          <EmptyState
            src="/img/error/locked.svg"
            title="You need to signup to view this page"
            explanation=""
            action={<Button route={{ to: 'signup' }}>Sign Up</Button>}
          />
        </div>
      );
    }
    return fullpage ? <FullPageError /> : genericError;
  }
  return genericError;
};
