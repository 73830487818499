/**
 * Labstep
 *
 * @module components/ResourceItem/Action/Create/Template
 * @desc Action to create a ResourceItem template
 */

import React from 'react';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { IResourceItemActionCreateTemplateProps } from './types';

export const ResourceItemActionCreateTemplate: React.FC<
  IResourceItemActionCreateTemplateProps
> = ({ resource }) => (
  <EntityActionCreate
    entityName={ResourceItem.entityName}
    parentName={resource.entityName}
    parentId={resource.id}
    actionComponentProps={{
      type: 'button',
      text: resource.is_template
        ? 'Create Template'
        : 'Enable Custom Template',
    }}
    body={{
      resource_id: resource.id,
      is_template: true,
    }}
  />
);

export default ResourceItemActionCreateTemplate;
