/**
 * Labstep
 *
 * @module components/Layout/Links/Vertical/Mobile
 */

import LayoutMainContentCenter from 'labstep-web/components/Layout/MainContent/Center';
import { ICONS } from 'labstep-web/constants/icons';
import Card from 'labstep-web/core/Card/Card';
import Icon from 'labstep-web/core/Icon';
import Select from 'labstep-web/core/Select';
import Switch from 'labstep-web/core/Switch';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router-dom';
import styles from './styles.module.scss';
import { IOption, LayoutLinksVerticalMobileProps } from './types';

export const LayoutLinksVerticalMobile: React.FC<
  LayoutLinksVerticalMobileProps
> = ({ label, links, groups, history }) => {
  const allLinks = links.concat(
    groups.map((group) => group.links).flat(),
  );
  const options = allLinks.flatMap((link) => ({
    label: link.children as string,
    value: {
      to: link.route.to,
      params: link.route.params,
    },
  }));

  const handleChange = (selectedOption: IOption) => {
    if (selectedOption) {
      const { to, params } = selectedOption.value;
      history.push(navigation.get(to, params));
    }
  };

  return (
    <LayoutMainContentCenter>
      <Card className={styles.card}>
        {label && (
          <div className={styles.primaryInfo}>
            <div>
              <Icon
                className={styles.icon}
                name={ICONS.navigation.settings}
              />
              {label}
            </div>
          </div>
        )}
        <div className={styles.select} data-public>
          <Select
            options={options}
            onChange={handleChange}
            defaultValue={options[0]}
            getOptionLabel={(option: IOption) => option.label}
            getOptionValue={(option: IOption) => option.value.to}
            isSearchable={false}
          />
        </div>
        <Switch
          links={allLinks.flatMap((link) =>
            link.routes
              ? link.routes.map((route) => ({
                  render: link.render,
                  route: { to: route },
                }))
              : {
                  render: link.render,
                  route: { to: link.route.to },
                },
          )}
        />
      </Card>
    </LayoutMainContentCenter>
  );
};

export default withRouter(LayoutLinksVerticalMobile);
