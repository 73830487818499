/**
 * Labstep
 */

import { CustomIdentifierSet } from 'labstep-web/models/custom-identifier-set.model';
import { fieldPrefix, fieldZeroPadding } from './fields';

/** Control character length for generate/display preview */
const PREFIX_MAX_CHAR = 30;
const ZERO_PADDING_MAX_CHAR = 10;

export const getPreview = (values): string => {
  const { [fieldPrefix.name]: prefix } = values;

  const zeroPadding =
    values[fieldZeroPadding.name] ||
    CustomIdentifierSet.defaultZeroPadding;

  const firstCustomIdentifier =
    prefix &&
    prefix.length <= PREFIX_MAX_CHAR &&
    zeroPadding >= 1 &&
    zeroPadding <= ZERO_PADDING_MAX_CHAR
      ? CustomIdentifierSet.generateCustomIdentifier(
          prefix,
          zeroPadding,
        )
      : '';

  return `Preview: ${firstCustomIdentifier}`;
};

export const preSubmit = (settings, parentClass) => ({
  settings: {
    ...settings,
    zero_padding:
      settings.zero_padding || CustomIdentifierSet.defaultZeroPadding,
  },
  parent_class: parentClass,
});
