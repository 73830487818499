/**
 * Labstep
 *
 * @module components/Entity/Sortable/Action/Container
 * @desc Container for Entity Sortable Action
 */

import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import React, { useState } from 'react';
import { IEntitySortableActionContainerProps } from './types';

export const EntitySortableActionContainer: React.FC<
  IEntitySortableActionContainerProps
> = ({ children, icon }) => {
  const [disabled, setDisabled] = useState(true);
  const [activeId, setActiveId] = useState<string | number | null>(
    null,
  );

  const getSortAction = (id: number | string) => (
    <div
      onMouseEnter={() => {
        setDisabled(false);
        if (activeId === null) {
          setActiveId(id);
        }
      }}
      onMouseLeave={() => {
        if (activeId === id) {
          setDisabled(true);
          setActiveId(null);
        }
      }}
    >
      <ActionComponent
        type="icon"
        icon={icon || ICONS.entity.actions.sort}
        elementProps={{
          popup: { content: 'Reorder' },
        }}
      />
    </div>
  );
  return children({ disabled, getSortAction });
};

export default EntitySortableActionContainer;
