/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Link/Action/Open
 * @desc Button to open a link
 */

import { ICONS } from 'labstep-web/constants/icons';
import Button from 'labstep-web/core/Button';
import Icon from 'labstep-web/core/Icon';
import { windowService } from 'labstep-web/services/window.service';
import React from 'react';
import { IMenuLinkActionOpenProps } from './types';

const MenuLinkActionOpen: React.FC<IMenuLinkActionOpenProps> = ({
  attrs,
}) => (
  <Button
    onMouseDown={() => {
      const { href } = attrs;
      if (href) {
        windowService.open(href);
      }
    }}
  >
    <Icon name={ICONS.file.actions.preview} />
    <span>Open</span>
  </Button>
);

export default MenuLinkActionOpen;
