/**
 * Labstep
 *
 * @module prosemirror/extensions/referencing
 * @desc Referencing extension
 */

import { LabstepExtension } from '../types';
import {
  handleArrowDown,
  handleArrowUp,
  handleEnter,
  handleEscape,
  handleTrigger,
} from './commands';
import referencingPlugin, { TRIGGER } from './plugin';

const ExtensionReferencing: LabstepExtension = {
  keymap: {
    ArrowUp: (state, dispatch) => handleArrowUp(state, dispatch),
    ArrowDown: (state, dispatch) => handleArrowDown(state, dispatch),
    Enter: (state, dispatch) => handleEnter(state, dispatch),
    Escape: (state, dispatch) => handleEscape(state, dispatch),
    [TRIGGER]: (state, dispatch) => handleTrigger(state, dispatch),
  },
  plugins: [referencingPlugin],
};

export default ExtensionReferencing;
