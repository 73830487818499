/**
 * Labstep
 *
 * @module components/File/Thumbnail
 * @desc Component to load securely a thumbnail (serverless image resize)
 */

import React, { useEffect, useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { INewThumbnailProps } from './types';

export const DEFAULT_PLACEHOLDER = '/img/placeholder/image.png';

export const NewThumbnail: React.FC<INewThumbnailProps> = ({
  status,
  src,
  fetchUrl,
  children,
  onError,
  customFetching,
  placeholder = DEFAULT_PLACEHOLDER,
}) => {
  const myRef = useRef<HTMLDivElement>(null);
  const hasBeenInViewport = useRef(false);
  const { inViewport } = useInViewport(
    myRef,
    {},
    { disconnectOnLeave: true },
  );

  // if no status, this image has not been fetched yet
  // we fetch it once it's in the viewport
  useEffect(() => {
    if (!status && !customFetching && inViewport) {
      fetchUrl();
    }
  }, [status, fetchUrl, customFetching, inViewport]);

  // retry fetch on first time in viewport if fetched but did not load
  useEffect(() => {
    if (
      status === 'failed' &&
      inViewport &&
      !hasBeenInViewport.current &&
      !customFetching
    ) {
      hasBeenInViewport.current = true;
      fetchUrl();
    }
  }, [status, fetchUrl, customFetching, inViewport]);

  if (customFetching) {
    return children({ src, onError, status, fetchUrl });
  }

  return (
    <section ref={myRef}>
      {children({
        src: status !== 'loaded' ? placeholder : src,
        onError,
        status,
        fetchUrl,
      })}
    </section>
  );
};
