/**
 * Labstep
 *
 * @module state/reducers/params
 * @desc Reducer to hold url get params that could be user later in the app (e.g. referral code)
 */

import { AnyAction } from 'redux';

const urlParamsReducer = (state: any = {}, action: AnyAction) => {
  switch (action.type) {
    case 'SET_PARAMS':
      return action.params;
    case 'CLEAR_PARAMS':
      return {};
    default:
      return state;
  }
};

export default urlParamsReducer;
