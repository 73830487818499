/**
 * Labstep
 *
 * @module components/ExperimentWorkflowLink/Action/Delete
 * @desc Action to delete ExperimentWorkflowLink
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { IExperimentWorkflowLinkActionDeleteProps } from './types';

export const ExperimentWorkflowLinkActionDelete: React.FC<
  IExperimentWorkflowLinkActionDeleteProps
> = ({ experimentWorkflowLink }) => (
  <EntityActionDelete
    entity={experimentWorkflowLink}
    confirmMessage="Are you sure want to delete the link to this experiment?"
  />
);

export default ExperimentWorkflowLinkActionDelete;
