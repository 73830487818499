/**
 * Labstep
 *
 * @module core/Form/ReactForm/Text
 * @desc Editable text as a form field
 */

import React from 'react';
import Input from 'labstep-web/core/Input';
import CustomFieldWrapper from '../CustomFieldWrapper';

export const Text: React.FC<any> = (props) => (
  <CustomFieldWrapper
    fieldElement={Input}
    defaultValue=""
    {...props}
  />
);

export default Text;
