/**
 * Labstep
 *
 * @module components/Experiment/CompletionChecklist/Message
 * @desc Message inside completion checklist (showed in different colors)
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import Link from 'labstep-web/core/Link';
import { IMessageProps } from './types';
import styles from './styles.module.scss';

const Message: React.FC<IMessageProps> = ({
  level = 'yellow',
  children,
  route,
  onClick,
}) => (
  <Link unstyled {...route} onClick={onClick}>
    <p>
      <Icon
        name={
          level === 'green' ? 'check circle' : 'exclamation triangle'
        }
        color={level}
      />
      {children}
    </p>
  </Link>
);

export default Message;
