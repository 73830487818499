/**
 * Labstep
 *
 * @module components/EntityImport/Action/Create/Steps/ImportMode
 * @desc EntityImport Create Step 1: ImportMode
 */

import { ICONS } from 'labstep-web/constants/icons';
import Header from 'labstep-web/core/Header';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import { Card, Image } from 'semantic-ui-react';
import EntityImportActionCreateStepsImportModeCard from './Card';
import { IEntityImportActionCreateStepsImportModeProps } from './types';

export const EntityImportActionCreateStepsImportMode: React.FC<
  IEntityImportActionCreateStepsImportModeProps
> = ({ entityName, index, onContinue }) => {
  const [selectedCard, setSelectedCard] =
    React.useState<typeof entityName>(entityName);
  return (
    <ModalWizard.Step
      index={index}
      onContinue={({ goForward }) => {
        onContinue(selectedCard);
        goForward();
      }}
      description="First, you must decide how you want to structure your import."
      help={
        <div>
          <Header size="tiny">Resources and Items</Header>
          <p>
            Items represent different batches or aliquots of a
            particular Resource:
          </p>
          <Image src="/img/inventory/tree.svg" />
        </div>
      }
    >
      <Card.Group itemsPerRow={1}>
        <EntityImportActionCreateStepsImportModeCard
          title="One Row per Item"
          icon={ICONS.resource_item.primary}
          description={
            <>
              Use this option if you want to import item specific
              metadata such as{' '}
              <i>location, amount remaining, lot numbers, barcodes</i>{' '}
              or <i>expiry dates</i>.
            </>
          }
          img="/img/inventory/import-items.svg"
          isSelected={selectedCard === ResourceItem.entityName}
          setIsSelected={() =>
            setSelectedCard(ResourceItem.entityName)
          }
        />
        <EntityImportActionCreateStepsImportModeCard
          title="One Row per Resource"
          icon={ICONS.resource.primary}
          description={
            <>
              Use this option if you just want to import Resources
              with no Items in stock or if you just want to specify{' '}
              <i>N</i> identical items in stock for each Resource.
            </>
          }
          img="/img/inventory/import-resources.svg"
          isSelected={selectedCard === Resource.entityName}
          setIsSelected={() => setSelectedCard(Resource.entityName)}
        />
      </Card.Group>
    </ModalWizard.Step>
  );
};

export default EntityImportActionCreateStepsImportMode;
