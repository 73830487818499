/**
 * Labstep
 *
 * @module core/Form/ShowEdit/Link
 * @desc A component to toggle between Show and Edit mode
 * for text fields. The intention is to toggle onBlur and do
 * an optimistic update. If the field is left empty no api
 * request will be send and the last saved value will be restored.
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import Icon from 'labstep-web/core/Icon';
import { omit, replace } from 'lodash';
import React from 'react';
import { ShowEditToggleComponent } from '..';
import { getDefaultValues } from '../../Edit';
import ReusableForm from '../../Reusable';
import styles from './styles.module.scss';
import { IFormShowEditLinkProps } from './types';

const FormShowEditLink: React.FC<IFormShowEditLinkProps> = (
  props,
) => {
  const {
    field,
    value,
    entityName,
    id,
    entity,
    options = {},
    link,
  } = props;
  const finalId: string | number = id || entity.idAttr;

  const canEdit = useHasAccess(entityName, finalId, Action.edit);
  const name = field.fieldLabel || replace(field.name, /_/g, ' ');

  const hasValue =
    (value !== undefined ? value : entity[field.name]) !== null;

  const fieldWithoutLabel: any = {
    ...omit(field, ['fieldLabel']),
  };

  const defaultValues = getDefaultValues([field], entity);

  return (
    <EntityUpdateContainer
      entityName={entityName}
      id={finalId}
      optimistic
    >
      {({ update }) => (
        <ShowEditToggleComponent
          canEdit
          children={link}
          name={name}
          hasValue={hasValue}
          customShow={({ toggle }) => (
            <div className={styles.customShow}>
              {link}
              {canEdit && (
                <div className={styles.icon}>
                  <Icon onClick={toggle} name="pencil" link />
                </div>
              )}
            </div>
          )}
          toggledComponent={({ toggle }) => (
            <ReusableForm
              fields={[fieldWithoutLabel]}
              defaultValues={defaultValues}
              submitButton={false}
              blurToggle={toggle}
              autoFocus
              onSubmit={(values) => {
                update(values, options);
                toggle();
              }}
            />
          )}
        />
      )}
    </EntityUpdateContainer>
  );
};

export default FormShowEditLink;
