/**
 * Labstep
 *
 * @module core/Select/Search/Modal
 * @desc An search select component in modal
 */

import React from 'react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { FormShowEditSearchSelectToggleViewComponent } from 'labstep-web/core/Form/ShowEdit/SearchSelect/Toggle';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ISearchSelectModalViewComponentProps } from './types';

export const SearchSelectModalViewComponent: React.FC<
  ISearchSelectModalViewComponentProps
> = ({
  actionComponentProps: customActionComponentProps,
  entityName,
  canEdit,
  status,
  children,
  hasValue,
  onChange,
  toggleModal,
}) => {
  if (hasValue) {
    if (canEdit) {
      return (
        <FormShowEditSearchSelectToggleViewComponent
          status={status}
          onExpand={(): void => onChange(null)}
          children={children}
        />
      );
    }
    return <>{children}</>;
  }
  return (
    <ActionComponent
      dataTestId="select-search-modal-action"
      disabled={!canEdit}
      status={status}
      onClick={toggleModal}
      {...(customActionComponentProps || {
        type: 'text',
        text: `${
          canEdit ? 'Specify' : 'No'
        } ${getHumanReadableEntityName(entityName)}`,
        elementProps: {
          showEdit: true,
        },
      })}
    />
  );
};

export default SearchSelectModalViewComponent;
