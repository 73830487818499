/**
 * Labstep
 */

import colDefName from 'labstep-web/components/Entity/DataGrid/colDefs/name';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import { PrecursorCellEditor } from '../../cellEditor/precursorCellEditor';

export const colDefResourceName = {
  ...colDefName(Resource.entityName, {
    getEntityPreviewProps: (resource) => ({
      secondaryInfo: <MetadataManager entity={resource} />,
    }),
  }),
  cellEditor: PrecursorCellEditor,
};
