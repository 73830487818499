/**
 * Labstep
 *
 * @module components/Layout/Menu/TopBar/Breadcrumb
 * @desc Breadcrumb
 */

import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { withSidebar } from 'labstep-web/containers/Ui/Sidebar';
import Icon from 'labstep-web/core/Icon';
import Logo from 'labstep-web/core/Logo';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import React from 'react';
import GroupContext from './Context/Group';
import ModuleContext from './Context/Module';
import OrganizationContext from './Context/Organization';
import Title from './Title';
import {
  IBreadcrumbRootProps,
  ILayoutMenuTopBarBreadcrumbProps,
} from './types';

export const BreadcrumbRoot: React.FC<IBreadcrumbRootProps> = ({
  setSidebar,
}) => (
  <div
    onClick={(): void => {
      setSidebar(true, 'left');
    }}
  >
    <Logo centered simplified />
  </div>
);

const BreadcrumbRootWrapper = withSidebar(BreadcrumbRoot);

export const LayoutMenuTopBarBreadcrumb: React.FC<
  ILayoutMenuTopBarBreadcrumbProps
> = ({ breadcrumb = {}, authenticatedUserEntity }) => {
  const { organization, group, module, title } = breadcrumb;
  const { activeGroup } = useActiveGroup();

  const breadcrumbElements: React.ReactNode[] = [
    <BreadcrumbRootWrapper key={0} />,
  ];

  if (organization && authenticatedUserEntity.userOrganization) {
    breadcrumbElements.push(
      <OrganizationContext
        organization={
          authenticatedUserEntity.userOrganization.organization
        }
      />,
    );
  }

  if (group && activeGroup) {
    breadcrumbElements.push(
      <GroupContext activeGroup={activeGroup} />,
    );
  }

  if (group && module && activeGroup) {
    breadcrumbElements.push(
      <ModuleContext
        moduleContext={module}
        activeGroup={activeGroup}
      />,
    );
  }

  if (title) {
    breadcrumbElements.push(<Title title={title} />);
  }

  return (
    <>
      {breadcrumbElements.map((element, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={i}>
          {element}
          {i + 1 < breadcrumbElements.length && (
            <Icon name="chevron right" />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default withAuthenticatedUserEntity(
  LayoutMenuTopBarBreadcrumb,
);
