/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Delete
 * @desc Action to delete
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { IOrderRequestDeleteActionProps } from './types';

export const OrderRequestDeleteAction: React.FC<
  IOrderRequestDeleteActionProps
> = ({ orderRequest }) => (
  <EntityActionDelete entity={orderRequest} />
);

export default OrderRequestDeleteAction;
