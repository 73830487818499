/**
 * Labstep
 */

import { IProseMirrorCursorPosition } from 'labstep-web/prosemirror/cursor';

/** Number of pixels to display dropdown below/above the selection */
export const LINE_HEIGHT_BELOW = 30;
export const LINE_HEIGHT_ABOVE = 10;

/** Height that menu will shrink to before displaying above selection */
export const MIN_MENU_HEIGHT = 200;

/** Margin to accept between window and menu */
export const MENU_MARGIN_BELOW = 40;
export const MENU_MARGIN_ABOVE = 60;

/**
 * Compute menu size/position based on its position relative to the window.
 * Menu is placed either:
 * 1) below cursor in full height
 * 2) below cursor with a maximum height
 * 3) above cursor
 *
 * @function
 * @param  {object} menuEl - Menu element
 * @param  {object} cursorPosition - Cursor position
 */
export const getMenuPlacement = (
  menuEl: HTMLElement,
  cursorPosition: IProseMirrorCursorPosition,
) => {
  const { height: menuHeight, top: menuTop } =
    menuEl.getBoundingClientRect();
  const viewHeight = window.innerHeight;
  const viewSpaceBelow = viewHeight - menuTop;

  // menu fits below
  let top = cursorPosition.top + LINE_HEIGHT_BELOW;
  let bottom = null;
  let maxHeight = null;

  // menu fits below if shrunk
  if (viewSpaceBelow >= MIN_MENU_HEIGHT + MENU_MARGIN_BELOW) {
    maxHeight = viewSpaceBelow - MENU_MARGIN_BELOW;
  }

  // menu does not fit below
  else if (viewSpaceBelow < menuHeight) {
    const viewSpaceAbove = cursorPosition.top - LINE_HEIGHT_ABOVE;
    top = null;
    bottom = viewHeight - viewSpaceAbove;

    // menu does not fit above
    if (viewSpaceAbove - MENU_MARGIN_ABOVE < menuHeight) {
      maxHeight = viewSpaceAbove - MENU_MARGIN_ABOVE;
    }
  }

  return {
    top: top ? `${top}px` : null,
    bottom: bottom ? `${bottom}px` : null,
    left: `${cursorPosition.left}px`,
    maxHeight,
  };
};
