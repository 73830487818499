/**
 * Labstep
 *
 * @module core/ProtocolCondition/Modal/Container
 * @desc Protocol Condition Modal Container
 */

import React from 'react';
import { Route } from 'react-router-dom';
import { ProtocolConditionModal } from '..';
import { useProtocolConditionModalContext } from '../context';
import { IProtocolConditionModalContainerProps } from './types';

export const ProtocolConditionModalContainer: React.FC<
  IProtocolConditionModalContainerProps
> = ({ protocol }) => {
  const { path } = useProtocolConditionModalContext();
  if (!path) {
    return null;
  }
  return (
    <Route
      path={path}
      render={(): React.ReactElement => (
        <ProtocolConditionModal protocol={protocol} />
      )}
    />
  );
};

export default ProtocolConditionModalContainer;
