/**
 * Labstep
 *
 * @module components/Metadata/Action/Copy
 * @desc Action to copy metadata value
 */

import ActionCopy from 'labstep-web/core/Action/Copy';
import React from 'react';
import { IMetadataActionCopyProps } from './types';

export const MetadataActionCopy: React.FC<
  IMetadataActionCopyProps
> = ({ metadata }) => {
  if (metadata.printValue === null) {
    return null;
  }
  return (
    <ActionCopy
      text={metadata.printValue}
      actionComponentProps={{ type: 'icon', icon: 'copy' }}
      hoverContent="Copy"
    />
  );
};

export default MetadataActionCopy;
