// @ts-strict-ignore
/**
 * Labstep
 *
 * @module core/ProtocolCondition/Modal/Content/Right/VariableManager
 * @desc Protocol Condition Modal Content Right Variable Manager
 */

import Container from 'labstep-web/components/Layout/Container';
import RightPaneSubPage from 'labstep-web/components/Layout/RightPane/SubPage';
import ProtocolActionAddVariableMetadata from 'labstep-web/components/Protocol/Action/AddVariable/Metadata';
import ProtocolActionAddVariableProtocolValue from 'labstep-web/components/Protocol/Action/AddVariable/ProtocolValue';
import { useProtocolConditionModalContext } from 'labstep-web/components/ProtocolCondition/Modal/context';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { Metadata } from 'labstep-web/models/metadata';
import { usePremiumFeatureStatus } from 'labstep-web/hooks/premium-feature';
import { ICONS } from 'labstep-web/constants/icons';
import { HorizontalDivider } from 'labstep-web/core/Divider';
import { capitalize } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IProtocolConditionModalContentRightVariableManagerProps } from './types';

export const ProtocolConditionModalContentRightVariableManager: React.FC<
  IProtocolConditionModalContentRightVariableManagerProps
> = ({ protocol }) => {
  const { variableManager, setVariableManager } =
    useProtocolConditionModalContext();

  const components = [
    {
      name: 'protocol_value',
      component: (
        <ProtocolActionAddVariableProtocolValue
          protocol={protocol}
          isInput={variableManager === 'inputs'}
        />
      ),
    },
    {
      name: 'metadata',
      component: (
        <ProtocolActionAddVariableMetadata
          protocol={protocol}
          isInput={variableManager === 'inputs'}
        />
      ),
    },
  ];

  const premiumOptions = usePremiumFeatureStatus(
    components,
    'name',
    'conditions_table',
  );

  return (
    <RightPaneSubPage
      icon={ICONS.protocol_condition.info[variableManager]}
      header={capitalize(variableManager)}
      children={
        <div>
          {premiumOptions.map((option, index) => {
            const component =
              option.premiumFeature && !option.premiumFeatureEnabled
                ? React.cloneElement(option.component, {
                    premiumFeature: option.premiumFeature,
                  })
                : option.component;

            if (index === premiumOptions.length - 1) {
              return component;
            }
            return (
              <>
                <Container>{component}</Container>
                <Container>
                  <HorizontalDivider />
                </Container>
              </>
            );
          })}
        </div>
      }
      onClose={(): void => setVariableManager(null)}
    />
  );
};

export default ProtocolConditionModalContentRightVariableManager;
