/**
 * Labstep
 *
 * @module components/ResourceLocation/Label
 * @desc Resource Location Label
 */

import classnames from 'classnames';
import { ICONS } from 'labstep-web/constants/icons';
import Icon from 'labstep-web/core/Icon';
import Label from 'labstep-web/core/Label';
import Popup from 'labstep-web/core/Popup';
import React from 'react';
import styles from './styles.module.scss';
import { IResourceLocationLabelBasicProps } from './types';

export const ResourceLocationLabelBasic: React.FC<
  IResourceLocationLabelBasicProps
> = ({
  resourceLocation,
  customName,
  withIcon,
  withPopup,
  className,
  onClick,
}) => {
  const label = (
    <Label
      data-testid="resource-location-label"
      size="large"
      circular
      onClick={onClick}
      className={classnames(styles.label, className)}
      style={onClick && { cursor: 'pointer' }}
    >
      {withIcon && <Icon name={ICONS.resource_location.primary} />}
      <span>{customName || resourceLocation.name}</span>
    </Label>
  );

  return withPopup && resourceLocation.location_path ? (
    <Popup content={resourceLocation.location_path} trigger={label} />
  ) : (
    label
  );
};

export default ResourceLocationLabelBasic;
