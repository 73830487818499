/**
 * Labstep
 *
 * @module components/Entity/ActionCreate
 * @desc Core create action. Creates entity on click.
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import EntityCanCreateContainer from 'labstep-web/containers/Entity/Create/Can';
import { withRouter } from 'react-router';
import { callIf } from 'labstep-web/services/react.service';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import { IOptions } from 'labstep-web/typings';
import {
  capitalize,
  getHumanReadableEntityName,
} from 'labstep-web/services/i18n.service';
import { navigation } from 'labstep-web/services/navigation';
import {
  IEntityActionCreateContainerProps,
  IEntityActionCreateProps,
} from './types';

export const EntityActionCreate: React.FC<
  IEntityActionCreateProps
> = (props) => {
  const {
    status,
    create,
    body = {},
    redirectRoute,
    entityName,
    customActionName,
    history,
    options = {},
    actionComponentProps,
    onClick,
  } = props;

  let onSuccess: IOptions['onSuccess'];
  if (redirectRoute) {
    onSuccess = ({ response }): void => {
      const { to, params, query } = redirectRoute({ response });
      history.push(navigation.get(to, params, false, query));
    };
  }

  return (
    <ActionComponent
      type="option"
      onClick={(e): void => {
        create(body, enhanceOptions({ options, onSuccess }));
        callIf(onClick, e);
      }}
      status={status}
      text={
        customActionName ||
        `New ${capitalize(
          getHumanReadableEntityName(
            entityName,
            false,
            false,
            body.is_template as boolean,
          ),
        )}`
      }
      icon="plus"
      {...actionComponentProps}
    />
  );
};

export const EntityActionCreateContainer: React.FC<
  IEntityActionCreateContainerProps
> = ({
  entityName,
  parentName,
  parentId,
  overwriteCanCreateProps = {},
  skipPermissionCheck,
  redirectRoute,
  ...rest
}) => (
  <EntityCanCreateContainer
    entityName={entityName}
    parentName={parentName}
    parentId={parentId}
    isTemplate={!!rest.body?.is_template}
    overwriteCanCreateProps={{
      entityName: rest.body?.is_template
        ? `${entityName}_template`
        : entityName,
      ...overwriteCanCreateProps,
    }}
    skipPermissionCheck={skipPermissionCheck}
    showLoaderWhileCreating={redirectRoute !== undefined}
  >
    {({ status, create }) => (
      <EntityActionCreate
        {...rest}
        status={status}
        create={create}
        entityName={entityName}
        redirectRoute={redirectRoute}
      />
    )}
  </EntityCanCreateContainer>
);

export default withRouter(EntityActionCreateContainer);
