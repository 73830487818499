/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Sign
 * @desc Action to sign experimentWorkflow
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import SignatureFormCreate from 'labstep-web/components/Signature/Form/Create';
import UserActionEnableMfa from 'labstep-web/components/User/Action/EnableMfa';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { Signature } from 'labstep-web/models/signature.model';
import React from 'react';
import SignatureSignRejectFormCreate from '../../Form/CreateSignReject';
import { SignatureTypeAction } from '../../SignAndRejectButtons/types';
import { ISignatureActionCreateProps } from './types';

export const SignatureActionCreate: React.FC<
  ISignatureActionCreateProps
> = ({
  experimentWorkflow,
  authenticatedUserEntity,
  actionComponentProps,
  body,
}) => {
  const form = (toggleModal: any) =>
    experimentWorkflow.entity_state_workflow ? (
      <SignatureSignRejectFormCreate
        experimentWorkflow={experimentWorkflow}
        toggleModal={toggleModal}
        signatureTypeAction={SignatureTypeAction.sign}
      />
    ) : (
      <SignatureFormCreate
        experimentWorkflow={experimentWorkflow}
        options={{ onSuccess: toggleModal }}
        body={body}
      />
    );
  return (
    <CanCreate
      parentId={experimentWorkflow.id}
      parentName={experimentWorkflow.entityName}
      entityName="signature"
    >
      <ModalDefault
        header={`Electronic ${Signature.getHumanReadableEntityName(
          false,
          true,
        )}`}
        content={({ toggleModal }) =>
          experimentWorkflow.owner.is_signature_two_factor_required &&
          !authenticatedUserEntity.is_two_factor_enabled ? (
            <div>
              <p>
                You need to enable two-factor authentication before
                you can sign{' '}
              </p>
              <UserActionEnableMfa />
            </div>
          ) : (
            form(toggleModal)
          )
        }
        viewComponent={({ toggleModal }) => (
          <ActionComponent
            type="button"
            text={`Add ${Signature.getHumanReadableEntityName(
              false,
              true,
            )}`}
            {...actionComponentProps}
            onClick={toggleModal}
          />
        )}
      />
    </CanCreate>
  );
};

export default withAuthenticatedUserEntity(SignatureActionCreate);
