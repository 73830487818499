/**
 * Labstep
 *
 * @module components/ResourceLocation/StockCheck
 * @desc Resource Location StockCheck
 */

import React from 'react';
import { useToggle } from 'labstep-web/hooks/toggle';
import ResourceItemSearchStockCheck from 'labstep-web/components/ResourceItem/Search/StockCheck';
import PermaLinkActionQRScan from 'labstep-web/components/PermaLink/Action/QRScan';
import ResourceItemPermaLinkReader from 'labstep-web/components/ResourceItem/PermaLinkReader';
import ActionComponent from 'labstep-web/core/Action/Component';
import styles from './styles.module.scss';
import { IResourceLocationStockCheckProps } from './types';

export const ResourceLocationStockCheck: React.FC<
  IResourceLocationStockCheckProps
> = ({ resourceLocation, onDone }) => {
  const [reader, toggleReader] = useToggle();

  if (reader) {
    return (
      <div>
        <ResourceItemPermaLinkReader
          resourceLocation={resourceLocation}
        />
        <ActionComponent
          type="button"
          text="Done"
          elementProps={{ fluid: true }}
          onClick={toggleReader}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <PermaLinkActionQRScan
        actionComponentProps={{ onClick: toggleReader }}
      />
      <ResourceItemSearchStockCheck
        resourceLocation={resourceLocation}
      />
      <ActionComponent
        type="button"
        text="Done"
        elementProps={{ fluid: true }}
        onClick={onDone}
      />
    </div>
  );
};

export default ResourceLocationStockCheck;
