/**
 * Labstep
 *
 * @module core/Entity/DataGrid/ColumnOptions
 * @desc Entity DataGrid dropdown to set column options
 */

import { Column } from 'ag-grid-community';
import { useDataGridContext } from 'labstep-web/core/DataGrid/context';
import { toggleColumnVisible } from 'labstep-web/core/DataGrid/utils';
import React, { useCallback, useMemo } from 'react';
import Icon from 'labstep-web/core/Icon';
import EntityDataGridToolPanelColumnsSection from './Section';
import { SectionType } from './Section/types';
import styles from './styles.module.scss';
import { IEntityDataGridToolPanelColumnsProps } from './types';
import {
  filterHiddenColumnDefs,
  filterPinnedLeftColumnDefs,
  filterPinnedRightColumnDefs,
  filterShownAndNotPinnedPositionColumnDefs,
  getColIdArr,
} from './utils';

export const EntityDataGridToolPanelColumns: React.FC<
  IEntityDataGridToolPanelColumnsProps
> = ({ extraColumnOptions }) => {
  const { agGrid, columns, columnState } = useDataGridContext();
  if (!columns) {
    return null;
  }
  /**
   * Columns by type (pinned left, pinned right, shown, hidden)
   */
  const columnsByType: Record<
    'pinnedLeft' | 'pinnedRight' | 'shown' | 'hidden',
    Column[]
  > = useMemo(
    () => ({
      pinnedLeft: filterPinnedLeftColumnDefs(columns, columnState),
      pinnedRight: filterPinnedRightColumnDefs(columns, columnState),
      shown: filterShownAndNotPinnedPositionColumnDefs(
        columns,
        columnState,
      ),
      hidden: filterHiddenColumnDefs(columns, columnState),
    }),
    [columns, columnState],
  );

  const onSortEnd = useCallback(
    (columnsSorted: Column[]) => {
      if (agGrid) {
        const allColumns = [
          ...columnsByType.pinnedLeft,
          ...columnsSorted,
          ...columnsByType.pinnedRight,
          ...columnsByType.hidden,
        ];
        const allColumnsColIdArr = getColIdArr(allColumns);
        agGrid.columnApi.applyColumnState({
          state: allColumnsColIdArr,
          applyOrder: true,
        });
      }
    },
    [agGrid],
  );

  const toggleColumnVisibleByColId = useCallback(
    (colId) => {
      if (agGrid) {
        toggleColumnVisible(agGrid, colId);
      }
    },
    [agGrid],
  );

  return (
    <div className={styles.container}>
      <Icon
        className={styles.close}
        name="times"
        onClick={() => agGrid?.api?.setSideBarVisible(false)}
      />
      <EntityDataGridToolPanelColumnsSection
        section={SectionType.visible}
        columns={columnsByType.shown}
        onToggleColumnVisible={toggleColumnVisibleByColId}
        onSortColumns={onSortEnd}
      />
      <EntityDataGridToolPanelColumnsSection
        section={SectionType.hidden}
        columns={columnsByType.hidden}
        onToggleColumnVisible={toggleColumnVisibleByColId}
        onSortColumns={onSortEnd}
      />
      {extraColumnOptions}
    </div>
  );
};

export default EntityDataGridToolPanelColumns;
