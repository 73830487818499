/**
 * Labstep
 *
 * @module prosemirror/nodes/experiment-table
 * @desc ExperimentTable node
 */

import { Node as ProseMirrorNode } from 'prosemirror-model';
import { getAttrsForId } from 'labstep-web/prosemirror/nodes/utils';
import { LabstepNode } from '../types';

const key = 'experiment_table';

const NodeExperimentTable: LabstepNode = {
  key,
  spec: {
    attrs: {
      id: { default: null },
      guid: { default: null },
    },
    group: 'block',
    marks: '',
    parseDOM: [{ tag: 'div', getAttrs: getAttrsForId(key) }],
    toDOM(node: ProseMirrorNode) {
      const { id, guid } = node.attrs;
      return [
        'div',
        {
          [`data-${key}-id`]: id,
          [`data-${key}-guid`]: guid,
        },
      ];
    },
  },
};

export default NodeExperimentTable;
