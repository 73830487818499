/**
 * Labstep
 *
 * @module components/ResourceLocation/Form/Edit/MapDimensions
 * @desc Form to edit resource location's map dimensions
 */

import {
  fieldResourceLocationMapColumns,
  fieldResourceLocationMapRows,
} from 'labstep-web/components/ResourceLocation/Form/fields';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import FormReusable from 'labstep-web/core/Form/Reusable';
import React, { useState } from 'react';
import { IResourceLocationFormEditMapDimensionsProps } from './types';

export const checkViolatesDimensions = (
  layout,
  rowCount,
  columnCount,
) => {
  let maxW = 0;
  let maxH = 0;
  Object.keys(layout).forEach((key) => {
    const { item } = layout[key];
    const w = item.x + item.w;
    const h = item.y + item.h;
    if (w > maxW) {
      maxW = w;
    }
    if (h > maxH) {
      maxH = h;
    }
  });
  if (rowCount < maxH || columnCount < maxW) {
    return true;
  }
  return false;
};

export const ResourceLocationEditMapDimensionsForm: React.FC<
  IResourceLocationFormEditMapDimensionsProps
> = ({ resourceLocation, options }) => {
  const [error, setError] = useState(null);
  return (
    <EntityUpdateContainer
      entityName={resourceLocation.entityName}
      id={resourceLocation.guid}
    >
      {({ update, status }) => (
        <FormReusable
          autoFocus
          submitButtonText="Update"
          defaultValues={{
            [fieldResourceLocationMapRows.name]:
              resourceLocation.map_data.rowCount,
            [fieldResourceLocationMapColumns.name]:
              resourceLocation.map_data.columnCount,
          }}
          status={status}
          fields={[
            fieldResourceLocationMapRows,
            fieldResourceLocationMapColumns,
          ]}
          error={error}
          onSubmit={({ rows, columns }) => {
            if (
              checkViolatesDimensions(
                resourceLocation.initialLayout,
                rows,
                columns,
              )
            ) {
              setError(
                'The new dimensions do not fit all items present in the map',
              );
            } else {
              setError(null);
              update(
                {
                  map_data: {
                    ...resourceLocation.map_data,
                    rowCount: rows,
                    columnCount: columns,
                  },
                },
                options,
              );
            }
          }}
        />
      )}
    </EntityUpdateContainer>
  );
};

export default ResourceLocationEditMapDimensionsForm;
