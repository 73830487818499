/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Action/Delete
 * @desc Action to delete experiment workflow
 */

import React from 'react';
import DeleteAction from 'labstep-web/components/Entity/Action/Delete';
import { IEntityStateWorkflowDeleteActionProps } from './types';

export const EntityStateWorkflowDeleteAction: React.FC<
  IEntityStateWorkflowDeleteActionProps
> = ({ entityStateWorkflow }) => (
  <DeleteAction entity={entityStateWorkflow} />
);

export default EntityStateWorkflowDeleteAction;
