/**
 * Labstep
 *
 * @module components/PurchaseOrder/Action/Create
 * @desc Create Action for purchaseOrder
 */

import React from 'react';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';

export const PurchaseOrderActionCreate: React.FC = () => (
  <EntityActionCreate
    entityName={PurchaseOrder.entityName}
    redirectRoute={({ response }) => ({
      to: 'purchase_order_show',
      params: { id: response.result },
    })}
    actionComponentProps={{ type: 'button' }}
  />
);

export default PurchaseOrderActionCreate;
