/**
 * Labstep
 *
 * @module prosemirror/nodes/commands
 * @desc ProseMirror commands for nodes
 */

import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import { Node as ProsemirrorNode } from 'prosemirror-model';
import { TextSelection } from 'prosemirror-state';

export const replaceWithNode = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
  from: number,
  to: number,
  key: string,
  attrs: { [key: string]: any } = {},
  content?: ProsemirrorNode,
) => {
  const { tr, schema } = state;
  const $from = tr.doc.resolve(from);
  const $to = tr.doc.resolve(to);
  const node = schema.nodes[key].create(attrs, content);

  tr.setStoredMarks([]);

  const isEmptyListItem =
    $from.parent.type === schema.nodes.paragraph &&
    $to.parent.type === schema.nodes.list_item;
  if (isEmptyListItem && !content) {
    const fragment = schema.nodes.paragraph.createAndFill();
    if (fragment) {
      tr.insert(to, fragment);
    }
  }

  tr.replaceRangeWith(from, to, node);

  if (content) {
    let pos = from + 1;
    // step placed under paragraph
    if ($from.parentOffset > 0) {
      pos += 2;
    }
    tr.setSelection(new TextSelection(tr.doc.resolve(pos)));
  }
  dispatch?.(tr);
};

export const insertNode = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
  key: string,
  pos?: number,
  attrs?: { [key: string]: any },
) => {
  const { tr } = state;
  const node = state.schema.nodes[key].create(attrs);
  tr.setStoredMarks([]);
  tr.insert(pos || state.selection.$from.pos, node);
  dispatch?.(tr);
};
