/**
 * Labstep
 *
 * @module components/OrderRequest/ListOrTable
 * @desc List or table of orderRequests
 */

import React from 'react';
import OrderRequestList from 'labstep-web/components/OrderRequest/List';
import MasterIndexCenterListOrTable from 'labstep-web/screens/Master/Index/Center/ListOrTable';
import OrderRequestDataGrid from '../DataGrid';
import { IOrderRequestListOrTableProps } from './types';
import { actions } from './constants';

export const OrderRequestListOrTable: React.FC<
  IOrderRequestListOrTableProps
> = ({ orderRequests, ...rest }) => (
  <MasterIndexCenterListOrTable
    tableComponent={
      <OrderRequestDataGrid orderRequests={orderRequests} {...rest} />
    }
    listComponent={
      <OrderRequestList
        orderRequests={orderRequests}
        actions={actions}
      />
    }
  />
);

export default OrderRequestListOrTable;
