/**
 * Labstep
 */

import OrderRequestActionDelete from 'labstep-web/components/OrderRequest/Action/Delete';
import OrderRequestActionShare from 'labstep-web/components/OrderRequest/Action/Share';
import OrderRequestActionDuplicate from 'labstep-web/components/OrderRequest/Action/Duplicate';
import OrderRequestActionSetPurchaseOrder from 'labstep-web/components/OrderRequest/Action/SetPurchaseOrder';
import EntityActionTransferOwnership from 'labstep-web/components/Entity/Action/TransferOwnership';
import EntityActionTag from 'labstep-web/components/Entity/Action/Tag';
import EntityActionRevoke from 'labstep-web/components/Entity/Action/Revoke';
import EntityUserActionManage from 'labstep-web/components/EntityUser/Action/Manage';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import colDefActionMenu from 'labstep-web/components/Entity/DataGrid/colDefs/actionMenu';
import React from 'react';

export const colDefOrderRequestActionMenu =
  colDefActionMenu<OrderRequest>((params) => (
    <>
      <OrderRequestActionSetPurchaseOrder
        orderRequest={params.data}
        currency={params.data.currency}
      />
      <OrderRequestActionShare orderRequest={params.data} />
      <OrderRequestActionDuplicate orderRequest={params.data} />
      <EntityUserActionManage entity={params.data} />
      <EntityActionTag parentEntity={params.data} />
      <EntityActionRevoke entity={params.data} />
      <EntityActionTransferOwnership entity={params.data} />
      <OrderRequestActionDelete orderRequest={params.data} />
    </>
  ));
