/**
 * Labstep
 *
 * @module epics/thumbnail
 * @desc Redux epic for thumbnail
 */

import { APP_VERSION } from 'labstep-web/constants/version';
import { Action } from 'labstep-web/models/action.model';
import { authenticationService } from 'labstep-web/services/authentication.service';
import { configService } from 'labstep-web/services/config.service';
import { HttpClientService } from 'labstep-web/services/http-client.service';
import {
  REFRESH_THUMBNAIL_STATUSES_ACTION_TYPE,
  thumbnailFetchUrlAction,
} from 'labstep-web/state/actions/thumbnail';
import qs from 'query-string';
import { StateObservable } from 'redux-observable';
import { Observable, concat, from, of } from 'rxjs';
import {
  catchError,
  distinct,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { LabstepReduxState } from '../types';

/**
 * Fetch thumbnail url
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onThumbnailFetchSrcValid = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'SERVERLESS_REQUEST_THUMBNAIL',
    ),
    distinct((action: Action) => action),
    mergeMap((action: Action) => {
      return authenticationService
        .getAuthentication(action, state$)
        .pipe(
          mergeMap((actionOrAuthentication) => {
            if (
              actionOrAuthentication.type.endsWith('REFRESH_TOKEN')
            ) {
              return of(actionOrAuthentication);
            }

            const queryParameters = qs.stringify({
              ...action.meta.params,
            });
            const url = `${configService.imageResizeUrl}/${
              action.meta.fileGuid
            }${queryParameters ? `?${queryParameters}` : ''}`;
            const headers = {
              'labstep-web-app-version': APP_VERSION,
              Authorization: `Bearer ${actionOrAuthentication.meta?.authentication?.jwt}`,
            };

            return from(
              HttpClientService.send('get', url, headers),
            ).pipe(
              map((payload) => {
                return {
                  ...action,
                  type: `${action.type.replace(
                    'REQUEST',
                    'SUCCESS',
                  )}`,
                  payload,
                };
              }),
              catchError((error) =>
                of({
                  ...action,
                  type: action.type.replace('REQUEST', 'FAIL'),
                  error,
                }),
              ),
            );
          }),
        );
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_THUMBNAIL_FETCH_URL',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * When loading a thumbnail fails, call serverless endpoint again.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onThumbnailCheckUrlValid = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type === 'THUMBNAIL_ERROR'),
    map((action: Action) =>
      thumbnailFetchUrlAction(
        action.meta.fileGuid,
        action.meta.params,
        {},
      ),
    ),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_THUMBNAIL_CHECK_URL',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Call onSuccess
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onSuccessActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'SERVERLESS_SUCCESS_THUMBNAIL',
    ),
    tap((action: Action) => {
      if (
        action &&
        action.onSuccess &&
        action.payload &&
        action.payload.url
      ) {
        action.onSuccess({ response: action.payload });
      }
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_SF_API_SUCCESS', payload: err }),
        source$,
      ),
    ),
  );

export const refreshStatuses = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => {
      return action.type === 'persist/REHYDRATE';
    }),
    map(() => {
      return { type: REFRESH_THUMBNAIL_STATUSES_ACTION_TYPE };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: `EPIC_FAIL_${REFRESH_THUMBNAIL_STATUSES_ACTION_TYPE}`,
          payload: err,
        }),
        source$,
      ),
    ),
  );
