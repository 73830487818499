/**
 * Labstep
 */

import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Group } from 'labstep-web/models/group.model';
import { SignatureRequirement } from 'labstep-web/models/signature-requirement.model';
import { SignatureTypeAction } from '../../SignAndRejectButtons/types';

export const getDefaultStatement = (
  signatureTypeAction: SignatureTypeAction,
  signatureRequirement?: SignatureRequirement | null,
  activeGroup?: Group | null,
) => {
  if (signatureTypeAction === SignatureTypeAction.reject) {
    return undefined;
  }
  if (
    signatureRequirement?.statement ??
    activeGroup?.default_signature_statement
  ) {
    return (
      signatureRequirement?.statement ??
      activeGroup?.default_signature_statement
    );
  }
  return `I hereby confirm that I have read and approve the ${ExperimentWorkflow.getHumanReadableEntityName()}.`;
};
