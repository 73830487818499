/**
 * Labstep
 *
 * @module components/Entity/Card/Overview
 * @desc Overview Card for protocol collection
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import LayoutLoadingContent from 'labstep-web/components/Layout/LoadingContent';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import Card from 'labstep-web/core/Card/Card';
import Icon from 'labstep-web/core/Icon';
import { Size } from 'labstep-web/core/Initials/types';
import Link from 'labstep-web/core/Link';
import PaginationCount from 'labstep-web/core/Pagination/Count';
import Table from 'labstep-web/core/Table';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import styles from './styles.module.scss';
import { IEntityCardOverviewProps } from './types';

export const EntityCardOverviewContainer: React.FC<
  IEntityCardOverviewProps
> = ({
  params,
  entityName,
  actionMenu,
  emptyState,
  icon,
  action,
  title,
}) => (
  <ReadOnMountHOC
    type="cursor"
    loading={false}
    entityName={entityName}
    params={{
      ...params,
      count: 4,
    }}
    children={({ entities, status, total }) => (
      <Card
        header={
          <Link to={`${entityName}_index`} unstyled>
            <Icon name={icon} />
            {title ||
              getHumanReadableEntityName(entityName, true, true)}
          </Link>
        }
        size="tiny"
        className={styles.card}
      >
        <LayoutLoadingContent status={status} loader="placeholder">
          {total === 0 ? (
            emptyState
          ) : (
            <>
              <div className={styles.viewAllContainer}>
                <PaginationCount
                  from={1}
                  to={total < 4 ? total : 4}
                  total={total}
                />
                <Link
                  // 24/07/2019 - TODO: To be refactor with new react-router
                  to={
                    window.location.href.includes('workspace')
                      ? `group_${entityName}s`
                      : `${entityName}_index`
                  }
                  params={
                    window.location.href.includes('workspace')
                      ? { id: params.group_id }
                      : {}
                  }
                  underline
                >
                  {`View all ${getHumanReadableEntityName(
                    entityName,
                    true,
                    true,
                  )}`}
                </Link>
              </div>
              <Table unstackable>
                <Table.Body>
                  {(entities as any[])
                    .filter((e) => !e.deleted_at)
                    .map((entity) => (
                      <Table.Row key={entity.id}>
                        <Table.Cell width={1}>
                          <ProfilePicture
                            size={Size.mini}
                            entity={entity.author}
                          />
                        </Table.Cell>
                        <Table.Cell style={{ flexGrow: 1 }}>
                          <EntityLink entity={entity} secondary />
                        </Table.Cell>
                        <Table.Cell
                          textAlign="right"
                          width={1}
                          style={{ flexShrink: 1 }}
                        >
                          {actionMenu({ entity })}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
              <div className={styles.action}>{action}</div>
            </>
          )}
        </LayoutLoadingContent>
      </Card>
    )}
  />
);

export default EntityCardOverviewContainer;
