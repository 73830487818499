import { getIdTimestamp } from 'labstep-web/state/epics/normalize/utils';
import { NodeSpec } from 'prosemirror-model';

export const getStepsOrder = (
  state: {
    content: Record<string, NodeSpec>[];
  },
  timestamp?: string,
): (string | number)[] =>
  state.content
    .filter((n) => n.type.includes('step'))
    .map((n) => getIdTimestamp(n.attrs.guid, timestamp));
