/**
 * Labstep
 */

import EntityActionCreateModal from 'labstep-web/components/Entity/Action/Create/Modal';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import ExperimentWorkflowActionUpdateEntityState from 'labstep-web/components/ExperimentWorkflow/Action/UpdateEntityState';
import { checkUserAssignToSignature } from 'labstep-web/components/Signature/SignAndRejectButtons/utils';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import Button from 'labstep-web/core/Button';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import { Signature } from 'labstep-web/models/signature.model';
import React, { useState } from 'react';
import SignatureModal from '../Modal';
import {
  ISignatureSignAndRejectButtons,
  ISignatureSignAndRejectButtonsContainerProps,
  SignatureTypeAction,
} from './types';

export const SignatureSignAndRejectButtons: React.FC<
  ISignatureSignAndRejectButtons
> = ({
  experimentWorkflow,
  authenticatedUserEntity,
  entityUsers,
}) => {
  const [choice, setChoice] = useState<SignatureTypeAction>(
    SignatureTypeAction.sign,
  );

  const canEditEntityState = useHasAccess(
    'experiment_workflow',
    experimentWorkflow.id,
    Action.experiment_workflow_edit_entity_state,
  );
  const isAssigned = checkUserAssignToSignature(
    experimentWorkflow,
    authenticatedUserEntity.id,
    entityUsers,
  );

  const entityStateIsLocked = experimentWorkflow.locked_at;

  if (!(isAssigned && entityStateIsLocked)) {
    return null;
  }

  return (
    <Flex
      spacing="gap"
      data-testid="sign-and-reject-buttons"
      hAlignContent="right"
      marginBottom={20}
    >
      <EntityActionCreateModal
        dataTestId="signature-action-modal"
        entityName={Signature.entityName}
        parent={experimentWorkflow}
        header="Sign or Reject"
        actionComponentProps={{
          icon: 'edit',
          text: 'Sign',
          type: 'text',
        }}
        viewComponent={({ toggleModal }) => (
          <Flex spacing="gap">
            <ActionComponent
              type="children"
              onClick={() => {
                setChoice(SignatureTypeAction.reject);
                toggleModal();
              }}
              children={({ onClick, status }) => (
                <Button onClick={onClick} status={status} basic>
                  <Icon name="close" color="red" />
                  <span>Reject</span>
                </Button>
              )}
            />
            {experimentWorkflow.getNumberOfSignaturesRequiredLeft(
              experimentWorkflow.entity_state,
            ) === 1 &&
            canEditEntityState &&
            experimentWorkflow.hasNextEntityState ? (
              <ExperimentWorkflowActionUpdateEntityState
                experimentWorkflow={experimentWorkflow}
                iconColor="green"
              />
            ) : (
              <ActionComponent
                type="children"
                onClick={() => {
                  setChoice(SignatureTypeAction.sign);
                  toggleModal();
                }}
                children={({ onClick, status }) => (
                  <Button onClick={onClick} status={status} basic>
                    <Icon name="signup" color="green" />
                    <span>Sign</span>
                  </Button>
                )}
              />
            )}
          </Flex>
        )}
        content={({ toggleModal }) => (
          <SignatureModal
            experimentWorkflow={experimentWorkflow}
            signatureTypeAction={choice}
            toggleModal={toggleModal}
          />
        )}
      />
    </Flex>
  );
};

const SignatureSignAndRejectButtonsContainer: React.FC<
  ISignatureSignAndRejectButtonsContainerProps
> = (props) => (
  <ReadOnMountHOC
    type="cursor"
    pagination={{ loader: false }}
    entityName={EntityUser.entityName}
    params={{
      experiment_workflow_id: props.experimentWorkflow.id,
      count: 100,
    }}
  >
    {({ entities }) => (
      <SignatureSignAndRejectButtons
        {...props}
        entityUsers={entities.filter(
          (e: EntityUser) => e.is_contributor || e.is_assigned,
        )}
      />
    )}
  </ReadOnMountHOC>
);

export default withAuthenticatedUserEntity(
  SignatureSignAndRejectButtonsContainer,
);
