/**
 * Labstep
 *
 * @module core/DataGrid/ColumnHeader/EntityImport/DateFormat
 * @desc Dropdown to switch metadata type
 */

import React from 'react';
import Dropdown from 'labstep-web/core/Dropdown/Portal';
import ActionComponent from 'labstep-web/core/Action/Component';
import { useAgGridEntityImportServiceFromContext } from 'labstep-web/core/DataGrid/context';
import { getOptions } from './utils';
import { DataGridColumnHeaderEntityImportDateFormatProps } from './types';

export const DataGridColumnHeaderEntityImportDateFormat: React.FC<
  DataGridColumnHeaderEntityImportDateFormatProps
> = ({ colDef }) => {
  const agGridEntityImportService =
    useAgGridEntityImportServiceFromContext();
  if (!agGridEntityImportService) {
    return null;
  }

  return (
    <Dropdown
      trigger={<div>{colDef.entity_import?.date_format}</div>}
      closeOnChange
    >
      {getOptions(colDef).map((option) => (
        <ActionComponent
          key={option}
          type="option"
          text={option}
          onClick={(): void =>
            agGridEntityImportService.setColDefEntityImportParameter(
              colDef.colId,
              {
                date_format: option,
              },
            )
          }
        />
      ))}
    </Dropdown>
  );
};

export default DataGridColumnHeaderEntityImportDateFormat;
