/**
 * Labstep
 *
 * @module components/Resource/Form/fields
 * @desc Resource fields
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { Resource } from 'labstep-web/models/resource.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import rules from 'labstep-web/services/validation/rules';

export const fieldResourceLocation: IFieldWithoutActionProps = {
  elementProps: {
    entityName: ResourceLocation.entityName,
    params: {},
    creatable: true,
  },
  name: 'resource_location',
  fieldType: FieldType.SearchSelect,
  fieldLabel: getHumanReadableEntityName(
    ResourceLocation.entityName,
    false,
    true,
  ),

  validation: rules.resource.resource_location,
};

export const fieldResourceDefaultAmount: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'default_amount',
  fieldLabel: 'Default Amount',
  validation: rules.resource.default_amount,
};

export const fieldResourceDefaultUnit: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'default_unit',
  fieldLabel: 'Default Unit',
  validation: rules.resource.default_unit,
  elementProps: {
    withSymbols: true,
  },
};

export const fieldResourceName: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'Name',
  name: 'name',
  validation: rules.resource.name,
  placeholder: 'Give your resource a name',
  header: true,
};

export const fieldResourceAvailableResourceItemCountAlertThreshold: IFieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    fieldLabel: 'Alert Threshold',
    name: 'available_resource_item_count_alert_threshold',
    validation:
      rules.resource.available_resource_item_count_alert_threshold,
    placeholder: 'Set alert threshold',
  };

export const fieldResourceTemplate: IFieldWithoutActionProps = {
  elementProps: {
    entityName: Resource.entityName,
    openMenuOnFocus: true,
    creatable: true,
    createBody: {
      is_template: 1,
    },
    extraParams: { is_template: 1 },
  },
  name: 'template',
  fieldType: FieldType.SearchSelect,
  fieldLabel: getHumanReadableEntityName(
    Resource.entityName,
    false,
    true,
    true,
  ),
  validation: rules.resource.resource_template,
};
