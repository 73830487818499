/* eslint-disable react/require-default-props */
/**
 * Labstep
 *
 * @module containers/File/Upload
 * @desc Container for orphan files. Appends children with helpful
 * actions and variables to achieve orphan files upload
 * ( orphanFiles, orphanFilesIds, removeOrphanFile, refreshUuid, orphanUuid )
 */

import { UniqueIdContainer } from 'labstep-web/containers/UniqueId';
import {
  IUploadFileParams,
  uploadFile,
} from 'labstep-web/state/actions/file';
import {
  selectCreateEntitiesStatuses,
  selectCreatedEntities,
} from 'labstep-web/state/selectors/entity';
import values from 'lodash/values';
import * as React from 'react';
import { connect } from 'react-redux';

interface IChildrenProps {
  orphanUuid: string;
  entityType: string;
  entityId: number;
}

export type FileUploadContainerChildrenProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

const Children = ({
  children,
  ...rest
}: FileUploadContainerChildrenProps & {
  children: (
    childrenProps: Omit<FileUploadContainerChildrenProps, 'children'>,
  ) => JSX.Element | null;
}) => children(rest);

const mapStateToProps = (state: any, ownProps: IChildrenProps) => {
  const files = selectCreatedEntities(
    state,
    'file',
    ownProps.entityType,
    ownProps.entityId,
    ownProps.orphanUuid,
  );

  const createFilesStatuses = selectCreateEntitiesStatuses(
    state,
    'file',
    ownProps.entityType,
    ownProps.entityId,
    ownProps.orphanUuid,
  );

  return {
    createFilesStatuses,
    files,
    uploading: values(files).reduce(
      (result, file) => result || (file && file.isFetching),
      false,
    ),
  };
};

const mapDispatchToProps = (
  dispatch: (...args: any[]) => void,
  ownProps: any,
) => ({
  uploadFile: (config: IUploadFileParams, options: any) => {
    dispatch(
      uploadFile(config, {
        ...options,
        hideProgress: ownProps.hideProgress,
      }),
    );
  },
});

const ConnectedChildren = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Children);

export const FileUploadContainer: React.FC<{
  children: (
    childrenProps: FileUploadContainerChildrenProps & {
      orphanUuid: string;
      refreshUuid: () => void;
    },
  ) => JSX.Element | null;
  entityType?: string;
  entityId?: number;
  orphan?: boolean;
  hideProgress?: boolean;
}> = (props) => {
  const { entityType, entityId, orphan, children, hideProgress } =
    props;
  return (
    <UniqueIdContainer>
      {({ uuid, refreshUuid }) => (
        <ConnectedChildren
          entityType={entityType}
          entityId={entityId}
          children={children}
          refreshUuid={refreshUuid}
          orphanUuid={orphan && uuid}
          hideProgress={hideProgress}
        />
      )}
    </UniqueIdContainer>
  );
};
