/**
 * Labstep
 *
 * @module components/Molecule/Card/EmptyState/Svg
 * @desc Empty state for molecule svg
 */

import MoleculeActionEdit from 'labstep-web/components/Molecule/Action/Edit';
import MoleculeCardEmptyState from 'labstep-web/components/Molecule/Card/EmptyState';
import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import React from 'react';
import { IMoleculeCardEmptyStateSvgProps } from './types';

export const MoleculeCardEmptyStateSvg: React.FC<
  IMoleculeCardEmptyStateSvgProps
> = ({ molecule, entity, canEdit }) => (
  <MoleculeCardEmptyState
    action={
      <MoleculeActionEdit
        molecule={molecule}
        parent={entity}
        canEdit={canEdit}
        viewComponent={({ toggleModal }) =>
          canEdit ? (
            <ActionComponent
              type="text"
              icon={ICONS.entity.actions.edit}
              text="Draw reaction"
              onClick={toggleModal}
            />
          ) : (
            <span>No reaction</span>
          )
        }
      />
    }
  />
);

export default MoleculeCardEmptyStateSvg;
