/**
 * Labstep
 *
 * @module prosemirror/extensions/loader/plugin
 * @desc Plugin for loader
 */

import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

export const loaderKey = 'loader';

export const loaderPlugin = new Plugin({
  key: new PluginKey(loaderKey),
  state: {
    init() {
      return DecorationSet.empty;
    },
    apply(tr, set) {
      let mappedSet = set.map(tr.mapping, tr.doc);
      const action = tr.getMeta(loaderKey);
      if (action && action.add) {
        const widget = document.createElement('div');
        widget.style.cssText = 'margin-left:5px';
        widget.className = 'ui tiny active inline loader';
        const deco = Decoration.widget(action.add.pos, widget, {
          id: action.add.id,
        });
        mappedSet = mappedSet.add(tr.doc, [deco]);
      } else if (action && action.remove) {
        mappedSet = mappedSet.remove(
          mappedSet.find(
            undefined,
            undefined,
            (spec) => spec.id === action.remove.id,
          ),
        );
      }
      return mappedSet;
    },
  },
  props: {
    decorations(state) {
      return this.getState(state);
    },
  },
});

export default loaderPlugin;
