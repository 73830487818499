/**
 * Labstep
 */

import SignatureListEntityState from 'labstep-web/components/Signature/List/EntityState';
import React from 'react';
import { IStepSignaturesProps } from './types';

const StepSignatures: React.FC<IStepSignaturesProps> = ({
  experimentWorkflow,
  entityState,
}) => {
  return (
    <SignatureListEntityState
      entityState={entityState}
      experimentWorkflow={experimentWorkflow}
      round={experimentWorkflow.entity_state_round}
      showState={false}
    />
  );
};

export default StepSignatures;
