/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/CreateMenu
 * @desc Create Action Menu for experiment workflow
 */

import React from 'react';
import ExperimentWorkflowActionCreate from 'labstep-web/components/ExperimentWorkflow/Action/Create';
import ExperimentWorkflowActionCreateFromTemplate from 'labstep-web/components/ExperimentWorkflow/Action/CreateFromTemplate';
import ButtonWithDropdown from 'labstep-web/core/Button/WithDropdown';
import { CanCreate } from 'labstep-web/components/Entity/Can';
import { IExperimentWorkflowActionCreateMenuProps } from './types';

export const ExperimentWorkflowActionCreateMenu: React.FC<
  IExperimentWorkflowActionCreateMenuProps
> = (props) => (
  <CanCreate entityName="experiment_workflow">
    <ButtonWithDropdown
      primaryAction={<ExperimentWorkflowActionCreate {...props} />}
      extraOptions={
        <ExperimentWorkflowActionCreateFromTemplate {...props} />
      }
    />
  </CanCreate>
);

export default ExperimentWorkflowActionCreateMenu;
