/**
 * Labstep
 */

import type { HeaderClassParams } from 'ag-grid-community';
import { objectOrFunction } from 'labstep-web/services/react.service';

export const isHideable = (column: HeaderClassParams['column']) => {
  if (!column) {
    return false;
  }
  return (
    !column.isPinned() &&
    !column.isLockPinned() &&
    !column.getColDef().lockVisible
  );
};

/**
 * Check if a column has a context menu
 * True if the column has sort, filter, column options or can be hidden
 * @param params Params passed to the header component
 * @param context Entity view context
 * @returns True if the column has a context menu
 */
export const hasContextMenu = (params: HeaderClassParams) => {
  if (!params.column) {
    return false;
  }
  const headerComponentParams = objectOrFunction(
    params.column.getColDef().headerComponentParams,
    params,
  );
  if (!headerComponentParams) {
    return false;
  }
  const { sort, filter, columnOptions } = headerComponentParams;
  return sort || filter || columnOptions || isHideable(params.column);
};
