/**
 * Labstep
 *
 * @module core/Select/Search/Modal/Content
 * @desc Modal content for search select
 */

import React, { useMemo } from 'react';
import EntitySearch from 'labstep-web/components/Entity/Search';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import Loadable from 'labstep-web/core/Loadable';
import SearchSelectModalEmptyStateSearch from '../EmptyState/Search';
import SearchSelectModalEmptyState from '../EmptyState';
import { ISearchSelectModalContentProps } from './types';
import { getBody, getCreateOptions } from './utils';

export const SearchSelectModalDataGrid = React.lazy(
  () => import('../DataGrid'),
);

export const SearchSelectModalContent: React.FC<
  ISearchSelectModalContentProps
> = ({
  entityName,
  creatable,
  createBody,
  createAction,
  searchProps,
  columnDefs,
  toggleModal,
  rowSelection = 'single',
  onChange,
  onSelectionChanged,
}) => {
  const options = useMemo(() => {
    const onChangeToggle = (option): void => {
      onChange(option);
      toggleModal();
    };
    return getCreateOptions(entityName, onChangeToggle);
  }, [entityName, onChange, toggleModal]);

  return (
    <EntitySearch
      searchBarProps={{
        autoFocus: true,
      }}
      action={
        creatable
          ? createAction ||
            (({ searchParams }) => (
              <EntityActionCreate
                entityName={entityName}
                body={getBody(createBody, searchParams)}
                options={options}
                actionComponentProps={{
                  type: 'button',
                }}
              />
            ))
          : undefined
      }
      entityName={entityName}
      noResultsMessageOnSearch={({ searchParams }) => (
        <SearchSelectModalEmptyStateSearch
          entityName={entityName}
          body={getBody(createBody, searchParams)}
          options={options}
          name={searchParams.search_query}
          creatable={creatable}
        />
      )}
      noResultsMessage={
        <SearchSelectModalEmptyState
          entityName={entityName}
          body={getBody(createBody)}
          options={options}
          creatable={creatable}
          action={createAction}
        />
      }
      useAllPages
      {...searchProps}
    >
      {(props) => (
        <Loadable>
          <SearchSelectModalDataGrid
            rowSelection={rowSelection}
            columnDefs={columnDefs}
            onSelectionChanged={onSelectionChanged}
            {...props}
          />
        </Loadable>
      )}
    </EntitySearch>
  );
};

export default SearchSelectModalContent;
