/**
 * Labstep
 *
 * @module state/actions/websocket
 * @desc Redux actions for websocket
 */

export const websocketCheckConnectedAction = () => ({
  type: 'WEBSOCKET_CHECK_CONNECTED',
});
