/**
 * Labstep
 *
 * @module components/Device/Action/UpdateTemplate
 * @desc Update device template
 */

import EntityActionUpdateTemplate from 'labstep-web/components/Entity/Action/UpdateTemplate';
import React from 'react';
import { IDeviceActionUpdateTemplateProps } from './types';

export const DeviceActionUpdateTemplate: React.FC<
  IDeviceActionUpdateTemplateProps
> = ({ device, actionType }) => (
  <EntityActionUpdateTemplate
    entity={device}
    actionType={actionType}
  />
);

export default DeviceActionUpdateTemplate;
