/**
 * Labstep
 *
 * @module components/Protocol/Action/AddVariable/ProtocolValue
 * @desc Add protocolValue variable action for protocol
 */

import { ICONS } from 'labstep-web/constants/icons';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React from 'react';
import ProtocolActionCreateVariable from '../../CreateVariable';
import ProtocolActionAddVariableGroup from '../Group';
import { IProtocolActionAddVariableProtocolValueProps } from './types';

export const ProtocolActionAddVariableProtocolValue: React.FC<
  IProtocolActionAddVariableProtocolValueProps
> = ({ protocol, isInput, premiumFeature }) => {
  const name = ProtocolValue.getHumanReadableEntityName(false, true);
  const params = { [`${protocol.entityName}_id`]: protocol.id };
  return (
    <ProtocolActionAddVariableGroup
      entityName={ProtocolValue.entityName}
      protocol={protocol}
      name={name}
      params={params}
      icon={ICONS.protocol_value.primary}
      isInput={isInput}
      action={
        <ProtocolActionCreateVariable
          protocol={protocol}
          entityName={ProtocolValue.entityName}
          isInput={isInput}
          actionComponentProps={{
            type: 'text',
            icon: 'plus',
            text: `Add ${name}`,
            premiumFeature,
          }}
        />
      }
    />
  );
};

export default ProtocolActionAddVariableProtocolValue;
