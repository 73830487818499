/**
 * Labstep
 *
 * @module components/Entity/Sortable/Table
 * @desc Entity Sortable Table
 */

import React from 'react';
import SortableTable from 'labstep-web/core/Sortable/Table';
import EntitySortable from '..';
import { IEntitySortableTableProps } from './types';

const EntitySortableTable: React.FC<IEntitySortableTableProps> = ({
  entityName,
  disabled,
  ...rest
}) => (
  <EntitySortable
    entityName={entityName}
    disabled={disabled}
    render={(props) => <SortableTable {...props} {...rest} />}
  />
);

export default EntitySortableTable;
