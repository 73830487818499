/**
 * Labstep
 *
 * @desc Service to parse OADate format from excel sheets
 * @see https://github.com/markitondemand/moment-msdate
 */

import moment from 'moment';
import { defaultFormat } from './date.service';

const DAY_MILLISECONDS = 86400000;
const MS_DAY_OFFSET = 25569;

const oaDateToTicks = (oaDate: number): number => {
  let ticks = (oaDate - MS_DAY_OFFSET) * DAY_MILLISECONDS;
  if (oaDate < 0) {
    const frac = (oaDate - Math.trunc(oaDate)) * DAY_MILLISECONDS;
    if (frac !== 0) {
      ticks -= frac * 2;
    }
  }
  return ticks;
};

/**
 * @description Takes an oaDate that is in utc and converts it to a utc moment.
 *
 * @param {string} oaDate
 * @returns string
 */
export const fromOADate = (oaDate: string): string => {
  const oaDateNumber = parseFloat(
    oaDate.replace('/OADate(', '').replace(')', ''),
  );

  const ticks = oaDateToTicks(oaDateNumber);
  return moment(ticks).utc().format(defaultFormat);
};

/**
 * @description Returns whether given variable is an OADate string or not.
 *
 * @param {string} oaDate
 * @returns boolean
 */
export const isOADate = (oaDate: string): boolean =>
  typeof oaDate === 'string' && /\/OADate\([0-9.]+\)\//.test(oaDate);
