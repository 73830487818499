/**
 * Labstep
 *
 * @module components/Layout/Menu/TopBar/Context
 * @desc Context
 */

import React from 'react';
import UserGroupListPreview from 'labstep-web/components/UserGroup/List/Preview';
import DropdownPortalHover from 'labstep-web/core/Dropdown/Portal/Hover';
import GroupItem from 'labstep-web/components/Group/Item';
import Link from 'labstep-web/core/Link';
import { IContextProps } from './types';

export const Context: React.FC<IContextProps> = ({ activeGroup }) => {
  if (!activeGroup) {
    return null;
  }
  return (
    <DropdownPortalHover
      trigger={
        <Link to="group_show" params={{ id: activeGroup.id }}>
          <GroupItem group={activeGroup} />
        </Link>
      }
      content={<UserGroupListPreview />}
    />
  );
};

export default Context;
