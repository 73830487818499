/**
 * Labstep
 *
 * @module components/Resource/ListOrTable
 * @desc List or table of resources
 */

import React from 'react';
import ResourceList from 'labstep-web/components/Resource/List';
import MasterIndexCenterListOrTable from 'labstep-web/screens/Master/Index/Center/ListOrTable';
import ResourceDataGrid from '../DataGrid/Container';
import { IResourceListOrTableProps } from './types';
import { actions } from './constants';

export const ResourceListOrTable: React.FC<
  IResourceListOrTableProps
> = ({ resources, ...rest }) => (
  <MasterIndexCenterListOrTable
    tableComponent={
      <ResourceDataGrid resources={resources} {...rest} />
    }
    listComponent={
      <ResourceList resources={resources} actions={actions} />
    }
  />
);

export default ResourceListOrTable;
