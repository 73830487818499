/**
 * Labstep
 *
 * @module components/PermaLink/Card/EmptyState
 * @desc Empty state for perma link
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import PermaLinkActionQRScan from 'labstep-web/components/PermaLink/Action/QRScan';
import { IPermaLinkReaderEmptyStateProps } from './types';

export const PermaLinkReaderEmptyState: React.FC<
  IPermaLinkReaderEmptyStateProps
> = ({ onClick, explanation }) => (
  <EmptyState
    src="/img/error/404.svg"
    title="Oops!"
    explanation={
      explanation ||
      'Looks like there are no items associated with this QR Code'
    }
    action={
      <PermaLinkActionQRScan
        actionComponentProps={{
          text: 'Scan Again',
          onClick,
        }}
      />
    }
  />
);

export default PermaLinkReaderEmptyState;
