/**
 * Labstep
 *
 * @module components/ResourceLocation/Form/fields
 * @desc ResourceLocation fields
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const fieldResourceLocationName: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'name',
  validation: rules.resource_location.name,
  placeholder: 'Enter name e.g. `Box 55`',
  header: true,
};

export const DEFAULT_DIMENSION_VALUE = 10;

export const fieldResourceLocationMapRows: IFieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    name: 'rows',
    fieldLabel: 'Rows',
    validation: rules.resource_location.map_rows,
    header: true,
  };

export const fieldResourceLocationMapColumns: IFieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    name: 'columns',
    fieldLabel: 'Columns',
    validation: rules.resource_location.map_columns,
    header: true,
  };
