/**
 * Labstep
 *
 * @module components/ProtocolValue/Detail
 * @desc Detail for Experiment Value
 */

import Divider from 'labstep-web/core/Divider';
import Flex from 'labstep-web/core/Flex';
import React from 'react';
import ProtocolValueVariableDetail from '../Variable/Detail';
import ProtocolValueDetailAmount from './Amount';
import ProtocolValueDetailResourceItem from './ResourceItem';
import ProtocolValueDetailSettings from './Settings';
import { IProtocolValueDetailProps } from './types';

export const ProtocolValueDetail: React.FC<
  IProtocolValueDetailProps
> = ({ protocolValue }) => (
  <Flex column grow>
    <ProtocolValueDetailSettings protocolValue={protocolValue} />
    <Divider />
    {protocolValue.is_variable ? (
      <ProtocolValueVariableDetail
        protocol={protocolValue.parent}
        protocolValue={protocolValue}
      />
    ) : (
      <>
        <ProtocolValueDetailAmount protocolValue={protocolValue} />
        <Divider />
        <ProtocolValueDetailResourceItem
          protocolValue={protocolValue}
        />
      </>
    )}
  </Flex>
);

export default ProtocolValueDetail;
