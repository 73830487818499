/**
 * Labstep
 *
 * @module screens/Jupyter/Authorize
 * @desc Jupyter Authorize OAuth
 */

import React from 'react';
import DocumentHead from 'labstep-web/core/DocumentHead';
import Center from './Center';
import { JupyterAuthorizeScreenProps } from './types';

export const JupyterAuthorizeScreen: React.FC<
  JupyterAuthorizeScreenProps
> = ({ location }) => (
  <>
    <DocumentHead title="Jupyter Authorize" />
    <Center location={location} />
  </>
);

export default JupyterAuthorizeScreen;
