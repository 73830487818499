/**
 * Labstep
 *
 * @module core/Pagination/CountPerPage
 * @desc Select count per page
 */

import React from 'react';
import SelectNoIndicator from 'labstep-web/core/Select/NoIndicator';
import { SelectOptionBase } from 'labstep-web/core/Select/types';
import styles from './styles.module.scss';
import { IPaginationCountPerPageProps } from './types';

export const AVAILABLE_COUNTS = [10, 20, 50];
export const AVAILABLE_COUNTS_FILTER = [10];

export const optionFromCount = (count: number): SelectOptionBase => ({
  label: count,
  value: count,
});

export const PaginationCountPerPage: React.FC<
  IPaginationCountPerPageProps
> = ({
  countPerPage,
  setCountPerPage,
  availableCounts = AVAILABLE_COUNTS,
}) => (
  <div className={styles.container}>
    <span>Results per page:</span>
    <SelectNoIndicator
      className={styles.selectContainer}
      value={optionFromCount(countPerPage)}
      options={availableCounts.map((count) => optionFromCount(count))}
      onChange={(option: SelectOptionBase): void =>
        setCountPerPage(option.value)
      }
      menuPlacement="top"
      isSearchable={false}
    />
  </div>
);

export default PaginationCountPerPage;
