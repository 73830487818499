/**
 * Labstep
 *
 * @module screens/Group/Show/Center/Discussion
 * @desc Discussion for a group
 */

import React from 'react';
import ThreadForm from 'labstep-web/components/Thread/Form';
import LayoutMainContentCenter from 'labstep-web/components/Layout/MainContent/Center';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Card from 'labstep-web/core/Card/Card';
import { IScreensThreadProps } from './types';
import styles from './styles.module.scss';

const ScreensThread: React.FC<IScreensThreadProps> = ({ thread }) => (
  <div className={styles.container}>
    <LayoutMainContentCenter>
      <Card id="information-card" className={styles.threadContainer}>
        <ThreadForm
          entityNameLabel="comments"
          readThreadIds={thread.id}
          createThreadId={thread.id}
          noCommentsMessage={
            <EmptyState
              src="/img/empty-state/comments.svg"
              title="No comments"
              explanation="Start a discussion with workspace members."
            />
          }
        />
      </Card>
    </LayoutMainContentCenter>
  </div>
);

export default ScreensThread;
