/**
 * Labstep
 *
 * @module screens/ResourceItem/Show/Center
 * @desc ResourceItem Show Center
 */

import EntityPrimaryInfoImage from 'labstep-web/components/Entity/PrimaryInfo/Image';
import EntityUserCard from 'labstep-web/components/EntityUser/Card';
import ResourceItemActionMenu from 'labstep-web/components/ResourceItem/Action/Menu';
import { Action } from 'labstep-web/components/ResourceItem/Action/Menu/types';
import ResourceItemBreadcrumb from 'labstep-web/components/ResourceItem/Breadcrumb';
import InformationCard from 'labstep-web/core/Card/Information';
import React from 'react';
import ResourceItemShowCenterContentSecondaryInfo from './SecondaryInfo';
import styles from './styles.module.scss';
import { ICenterProps } from './types';

const Center: React.FC<ICenterProps> = ({ resourceItem }) => (
  <div id="resource-item-show-screen" className={styles.container}>
    <InformationCard
      additionalActions={<EntityUserCard entity={resourceItem} />}
      actionMenu={
        <ResourceItemActionMenu
          resourceItem={resourceItem}
          actions={[Action.delete, Action.duplicate]}
        />
      }
      breadcrumb={
        <ResourceItemBreadcrumb resourceItem={resourceItem} />
      }
      primaryInfo={<EntityPrimaryInfoImage entity={resourceItem} />}
      secondaryInfo={
        <ResourceItemShowCenterContentSecondaryInfo
          resourceItem={resourceItem}
        />
      }
    />
  </div>
);

export default Center;
