/**
 * Labstep
 *
 * @module prosemirror/marks/underline
 * @desc Underline mark
 */

import { toggleMark } from 'prosemirror-commands';
import { LabstepMark } from 'labstep-web/prosemirror/marks/types';

const MarkUnderline: LabstepMark = {
  key: 'underline',
  spec: {
    toDOM() {
      return ['u', 0];
    },
    parseDOM: [
      {
        tag: 'u',
      },
      {
        style: 'text-decoration',
        getAttrs: (value) => value === 'underline' && null,
      },
      {
        style: 'text-decoration-line',
        getAttrs: (value) => value === 'underline' && null,
      },
    ],
  },
  keymap: {
    'Mod-u': (state, dispatch) =>
      toggleMark(state.schema.marks[MarkUnderline.key], {})(
        state,
        dispatch,
      ),
  },
  toolbarIcon: 'underline',
};

export default MarkUnderline;
