/**
 * Labstep
 *
 * @module state/reducers/entity/deletingIds;
 * @desc Reducer for deleted entities
 */

import handleAction from 'labstep-web/state/reducers/entity/utils';

/**
 * Returns reducer for deleted entities
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} action - Redux action
 */
const deletingIds =
  (entityName: string, entityNameUppercase: string) =>
  (state: any = {}, action: any): any =>
    handleAction(
      state,
      action,
      'DELETE',
      action.meta && action.meta.identifier,
      entityName,
      entityNameUppercase,
    );

export default deletingIds;
