/**
 * Labstep
 *
 * @module components/EntityUsers/List
 * @desc List of tags
 */

import React from 'react';
import Flex from 'labstep-web/core/Flex';
import EntityUserAvatar from 'labstep-web/components/EntityUser/Avatar';
import { PlusCount } from 'labstep-web/core/List/Cap';
import { IEntityUserListProps } from './types';
import styles from './styles.module.scss';

export const EntityUserList: React.FC<IEntityUserListProps> = ({
  entity,
  onPlusCountClick,
  hidePopup,
}) => (
  <Flex className={styles.container}>
    {entity.entity_users_preview
      .filter((entityUser) => entityUser?.user?.name)
      .map((entityUser) => (
        <EntityUserAvatar
          popup={!hidePopup}
          content={`${entityUser.user.name} - ${entityUser.getRole(
            entity.author,
          )}`}
          key={entityUser.id}
          entityUser={entityUser}
        />
      ))}
    <PlusCount
      onClick={onPlusCountClick}
      count={
        entity.entity_users_count - entity.entity_users_preview.length
      }
      circular
    />
  </Flex>
);

export default EntityUserList;
