/**
 * Labstep
 *
 * @module components/Experiment/Action/Start
 * @desc Action to start experiment
 */

import React from 'react';
import { generateNewDateString } from 'labstep-web/services/date.service';
import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { IExperimentActionStartProps } from './types';

export const ExperimentActionStart: React.FC<
  IExperimentActionStartProps
> = ({ experiment, options = {} }) =>
  experiment.canStart ? (
    <EntityActionUpdate
      entityName={experiment.entityName}
      id={experiment.id}
      body={{ started_at: generateNewDateString() }}
      options={options}
      actionComponentProps={{
        type: 'button',
        text: 'Start',
        elementProps: {
          color: 'green',
        },
      }}
    />
  ) : null;

export default ExperimentActionStart;
