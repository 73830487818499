/**
 * Labstep
 */

import Button from 'labstep-web/core/Button';
import Modal from 'labstep-web/core/Modal';
import TextWithIcon from 'labstep-web/core/Text/WithIcon';
import React from 'react';
import { Ref } from 'semantic-ui-react';
import { useModalWizardContext } from '../context';
import styles from './styles.module.scss';
import { IModalWizardActionsProps } from './types';

export const ModalWizardActions: React.FC<
  IModalWizardActionsProps
> = ({
  onContinue,
  onBack,
  hideBackButton,
  hideContinueButton,
  disableContinueButton,
  isContinueButtonDisabled,
  continueLabel,
  continueText,
  actions,
}) => {
  const {
    activeStepIndex,
    startIndex,
    goBack,
    goForward,
    isLastStep,
    status,
    errorMessages,
  } = useModalWizardContext();

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const additionalButtonProps: any = {};
  if (isContinueButtonDisabled) {
    additionalButtonProps.disabled = true;
  }

  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === 'Enter') {
        e.preventDefault();
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [goForward]);

  let buttonLabel = continueLabel;
  if (!buttonLabel) {
    buttonLabel = isLastStep ? 'Finish' : 'Continue';
  }

  return (
    <Modal.Actions>
      {!hideBackButton && !(activeStepIndex - 1 < startIndex) ? (
        <Button
          data-testid="modal-wizard-back-button"
          className={styles.actionLeft}
          primary
          basic
          onClick={() => {
            if (onBack) {
              onBack({ goBack });
            } else {
              goBack();
            }
          }}
        >
          Back
        </Button>
      ) : null}
      {errorMessages.map((message) => (
        <Button key={message} className={styles.error}>
          <TextWithIcon
            key={message}
            text={message}
            icon="warning sign"
            iconProps={{ color: 'red' }}
          />
        </Button>
      ))}
      {actions}
      {continueText && (
        <span className={styles.continueText}>{continueText}</span>
      )}
      {!hideContinueButton && (
        <Ref innerRef={buttonRef}>
          <Button
            data-testid="modal-wizard-continue-button"
            {...additionalButtonProps}
            primary
            disabled={status?.isFetching || disableContinueButton}
            onClick={() => goForward(onContinue)}
          >
            {buttonLabel}
          </Button>
        </Ref>
      )}
    </Modal.Actions>
  );
};

export default ModalWizardActions;
