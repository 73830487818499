/**
 * Labstep
 *
 * @module screens/User/Show/PersonalNotes
 * @desc User Personal Notes
 */

import React from 'react';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import ModalDefault from 'labstep-web/core/Modal/Default';
import ThreadModal from 'labstep-web/components/Thread/Modal';
import { IUserShowPersonalNotesProps } from './types';

const UserShowPersonalNotes: React.FC<
  IUserShowPersonalNotesProps
> = ({ user, ...rest }) => (
  <Can action={Action.edit} entityName={user.entityName} id={user.id}>
    <ModalDefault
      header="Personal Notes"
      size="large"
      closeOnDimmerClick
      content={
        <ThreadModal
          noCommentsMessage="No personal notes"
          threadId={user.thread.id}
        />
      }
      {...rest}
    />
  </Can>
);

export default UserShowPersonalNotes;
