/**
 * Labstep
 *
 * @module components/Entity/Action/Update/Checkbox
 * @desc Entity Update action with checkbox
 */

import React from 'react';
import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import {
  EntityType,
  IEntityActionUpdateCheckboxProps,
} from './types';

export const EntityActionUpdateCheckbox = <
  TEntity extends EntityType,
>({
  entity,
  field,
  disabled = false,
  toggle,
  radio,
  optimistic,
  isChecked,
}: IEntityActionUpdateCheckboxProps<TEntity>) => {
  const checked =
    typeof isChecked === 'boolean' ? isChecked : !!entity[field];
  return (
    <EntityActionUpdate
      entityName={entity.entityName}
      id={entity.idAttr}
      body={{ [field]: !checked }}
      optimistic={optimistic}
      actionComponentProps={({ canEdit }) => ({
        type: 'checkbox',
        elementProps: {
          checked,
          toggle,
          radio,
        },
        disabled: !canEdit || disabled,
      })}
    />
  );
};

export default EntityActionUpdateCheckbox;
