/**
 * Labstep
 *
 * @module models/protocol-timer
 * @desc Typescript export class for ProtocolTimer
 */

import { Type } from 'class-transformer';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { ProtocolStep } from 'labstep-web/models/protocol-step.model';
import { Entity } from 'labstep-web/models/entity.model';

export class ProtocolTimer extends Entity {
  public static readonly idAttr = 'guid' as const;

  static readonly entityName = 'protocol_timer';

  get entityName(): string {
    return ProtocolTimer.entityName;
  }

  constructor(data: Partial<ProtocolTimer> = {}) {
    super();
    Object.assign(this, data);
  }

  name: string;

  hours: number;

  minutes: number;

  seconds: number;

  ended_at: string;

  paused_at: string;

  is_experiment_child: boolean;

  @Type(() => ProtocolStep)
  protocol_step: ProtocolStep;

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
