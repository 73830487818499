/**
 * Labstep
 *
 * @module components/Resource/Filter/Active/AvailableResourceItemCount
 * @desc Active filter component for molecule_inchi
 */

import { ICONS } from 'labstep-web/constants/icons';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import React from 'react';
import MoleculeFilter from '..';
import { MoleculeFilterActiveProps } from './types';

const MoleculeFilterActive: React.FC<MoleculeFilterActiveProps> = ({
  clearParams,
  searchParams,
  setParams,
  addPostFilter,
}) =>
  'molecule_inchi' in searchParams ? (
    <>
      <MoleculeFilter
        searchParams={searchParams}
        setParams={setParams}
        addPostFilter={addPostFilter}
        viewComponent={({ toggleModal }) => (
          <RemoveLabel
            icon={ICONS.molecule.primary}
            name="Chemical Structure"
            onNameClick={toggleModal}
            onRemoveClick={() => clearParams(['molecule_inchi'])}
          />
        )}
      />
    </>
  ) : null;

export default MoleculeFilterActive;
