/**
 * Labstep
 *
 * @module services/utils
 * @desc Helper methods
 */

import { pluralize } from 'labstep-web/services/i18n.service';

interface IEnhancedOptions {
  toast?: boolean;
  onSuccess?: (...props: any) => void;
  onFail?: (...props: any) => void;
  options: any;
}

export const enhanceOptions = ({
  onSuccess,
  onFail,
  toast,
  options = {},
}: IEnhancedOptions) => ({
  toast,
  ...options,
  onSuccess: (props) => {
    if (options.onSuccess) {
      options.onSuccess(props);
    }
    if (onSuccess) {
      onSuccess(props);
    }
  },
  onFail: (props) => {
    if (options.onFail) {
      options.onFail(props);
    }
    if (onFail) {
      onFail(props);
    }
  },
});

/**
 * Joins the names in array and returns a human readable
 * message
 * @param  {array} names - Array of names
 * @return {string}      - Human readable message
 */
export const joinNames = (names: string[]): string => {
  if (names.length === 0) {
    return 'None';
  }
  if (names.length <= 3) {
    return names.join(', ');
  }
  return `${names.slice(0, 3).join(', ')} and ${
    names.length - 3
  } others.`;
};

/**
 * Pluralizes if count is not 1
 * @param  {string} name  - Name
 * @param  {integer} count - Count of items
 * @return {string}    - Sanitized name
 */
export const pluralizeWithCheck = (
  name: string,
  count: number,
): string => {
  if (count === 1) {
    return name;
  }
  return pluralize(name);
};

export const flattenObject = (object) => {
  if (!(object && typeof object === 'object')) {
    return object;
  }
  return Object.keys(object).reduce((result, key) => {
    if (object[key] && typeof object[key] === 'object') {
      return result;
    }
    return { ...result, [key]: object[key] };
  }, {});
};

export const isGuid = (value: string | number): boolean =>
  typeof value === 'string' && value.indexOf('-') !== -1;
