/**
 * Labstep
 *
 * @module components/Entity/ActionMenu
 * @desc Core action menu
 */

import React from 'react';
import Dropdown from 'labstep-web/core/Dropdown';
import { IIconProps } from 'labstep-web/core/Icon/types';
import ActionComponent from '../Component';
import { IActionMenuProps } from './types';
import styles from './styles.module.scss';

const ActionMenu: React.FC<IActionMenuProps> = ({
  children,
  type,
  color,
  orientation,
  upward,
  size,
  ...rest
}) => {
  // temp fix
  if (!React.Children.toArray(children).length) {
    return null;
  }

  const props: any = {
    pointing: 'top right',
    icon: null,
    ...rest,
  };

  const iconName = `ellipsis ${orientation}` as IIconProps['name'];

  switch (type) {
    default: {
      props.upward = upward;
      props.trigger = (
        <ActionComponent
          type="icon"
          icon={iconName}
          elementProps={{ color, size }}
        />
      );
      break;
    }
  }

  return (
    <Dropdown {...props}>
      {children}
      <Dropdown.Item className={styles.empty} disabled>
        No Actions
      </Dropdown.Item>
    </Dropdown>
  );
};

ActionMenu.defaultProps = {
  orientation: 'vertical',
};

export default ActionMenu;
