/**
 * Labstep
 *
 * @module components/ResourceLocation/Action/Create
 * @desc Create Action for experiment workflow
 */

import React from 'react';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { IResourceLocationActionCreateProps } from './types';

export const ResourceLocationActionCreate: React.FC<
  IResourceLocationActionCreateProps
> = ({ body = {}, redirect = true }) => (
  <EntityActionCreate
    entityName={ResourceLocation.entityName}
    body={body}
    redirectRoute={
      redirect
        ? ({ response }) => ({
            to: 'resource_location_show',
            params: { id: response.result },
          })
        : undefined
    }
    actionComponentProps={{
      type: 'button',
    }}
  />
);

export default ResourceLocationActionCreate;
