/**
 * Labstep
 *
 * @module containers/User/Login
 * @desc Login container
 */

import {
  clearLoginError,
  internalLoginAction,
  internalLoginCheckAction,
} from 'labstep-web/state/actions/user';
import { selectLoginStatus } from 'labstep-web/state/selectors/authenticated-user';
import { connect } from 'react-redux';
import { IUserLoginContainerProps } from './types';

const LoginContainer = ({ children, ...rest }: any) => children(rest);

const mapStateToProps = (state: any) => ({
  status: selectLoginStatus(state),
});

const mapDispatchToProps = (dispatch: (...args: any[]) => void) => ({
  loginInternal: (userData: any, options = {}) => {
    dispatch(internalLoginAction({ userData, options }));
  },
  clearLoginError: () => {
    dispatch(clearLoginError());
  },
  loginInternalCheck: (userData: any, options = {}) => {
    dispatch(internalLoginCheckAction({ userData, options }));
  },
});

export const UserLoginContainer: React.FC<IUserLoginContainerProps> =
  connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
