/**
 * Labstep
 *
 * @module components/Resource/Filter/Active/AvailableResourceItemCount
 * @desc Active filter component for molecule_inchi
 */

import { ICONS } from 'labstep-web/constants/icons';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { PostFilterMolecule } from 'labstep-web/services/postFilter/filters/molecule';
import React from 'react';

const MoleculeFilterActive = ({ onRemoveClick, node }) => {
  const { inchis } = PostFilterMolecule.getAttributeValues(node);
  if (!inchis) {
    return null;
  }
  return (
    <RemoveLabel
      icon={ICONS.molecule.primary}
      name="Chemical Structure"
      onRemoveClick={onRemoveClick}
    />
  );
};

export default MoleculeFilterActive;
