/**
 * Labstep
 *
 * @module components/Filter/Action/ToggleCollapse
 * @desc An action to toggle filter collapsed state
 */

import { withUiPersistent } from 'labstep-web/containers/UiPersistent';
import ToggleCollapse from 'labstep-web/core/ToggleCollapse';
import React from 'react';
import { IFilterActionCollapseProps } from './types';

export const FilterActionCollapse: React.FC<
  IFilterActionCollapseProps
> = ({ toggleUiFilter, uiPersistent, filter }) => (
  <ToggleCollapse
    onClick={() => toggleUiFilter(filter.entityName, filter.name)}
    isCollapsed={
      uiPersistent.filters[filter.entityName][filter.name]!.state ===
      'collapsed'
    }
  />
);

export default withUiPersistent(FilterActionCollapse);
