/**
 * Labstep
 *
 * @module components/Layout/FullPageError
 * @desc Generic Component showing full page error
 */

import React from 'react';
import Image from 'labstep-web/core/Image';
import UserActionSignout from 'labstep-web/components/User/Action/Signout/';
import styles from './styles.module.scss';
import { IFullPageErrorProps } from './types';

export const labstepMaintenanceError = (
  <Image centered src="/img/error/maintenance.svg" />
);

export const FullPageError: React.FC<IFullPageErrorProps> = ({
  message,
  showSignout,
}) => (
  <div className={styles.container}>
    {labstepMaintenanceError}
    <p>{message}</p>
    {showSignout && (
      <div>
        <UserActionSignout skipApi />
      </div>
    )}
  </div>
);

FullPageError.defaultProps = {
  message:
    'Labstep is going through some features enhancement. Please refresh your page.',
};

export default FullPageError;
