/**
 * Labstep
 */

import type { DropAnimation } from '@dnd-kit/core';
import {
  DragOverlay,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import React from 'react';

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
};

export const SortableOverlay: React.FC = ({ children }) => (
  <DragOverlay dropAnimation={dropAnimationConfig}>
    {children}
  </DragOverlay>
);
