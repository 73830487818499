/**
 * Labstep
 *
 * @module prosemirror/extensions/placeholder/plugin
 * @desc Placeholder plugin
 */

import { isMobile } from 'react-device-detect';
import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { editablePluginKey } from 'labstep-web/prosemirror/extensions/editable/plugin';
import {
  getPluginState,
  isCursorAtBlankLine,
  isCursorInTable,
} from 'labstep-web/prosemirror/utils';
import styles from './styles.module.scss';

export const PLACEHOLDER_MESSAGE =
  'Type / to insert new elements or @ to reference';

export const placeholderKey = 'placeholder';

export const createPlaceholder = () => {
  const placeholder = document.createElement('span');
  placeholder.classList.add(styles.placeholder);
  placeholder.textContent = PLACEHOLDER_MESSAGE;
  return placeholder;
};

export const placeholderPlugin = new Plugin({
  key: new PluginKey(placeholderKey),
  props: {
    decorations(state) {
      if (
        !isCursorAtBlankLine(state) ||
        !getPluginState(state, editablePluginKey) ||
        isCursorInTable(state) ||
        isMobile
      ) {
        return null;
      }
      return DecorationSet.create(state.doc, [
        Decoration.widget(state.selection.from, createPlaceholder()),
      ]);
    },
  },
});
export default placeholderPlugin;
