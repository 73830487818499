/**
 * Labstep
 */

import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';

export const replaceWithLink = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
  from: number,
  to: number,
  url: string,
  text: string,
) => {
  const { tr, schema } = state;
  const link = schema.marks.link.create({
    href: url,
  });
  const node = schema.text(text || url).mark([link]);
  tr.replaceRangeWith(from, to, node);
  dispatch?.(tr);
};
