/**
 * Labstep
 *
 * @module core/ToggleCollapse
 * @desc A toggle action component for collapsed state
 */

import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import React from 'react';
import { IToggleCollapseProps } from './types';

export const ToggleCollapse: React.FC<IToggleCollapseProps> = ({
  onClick,
  isCollapsed,
}) => (
  <ActionComponent
    type="icon"
    onClick={onClick}
    icon={isCollapsed ? ICONS.info.collapsed : ICONS.info.expanded}
  />
);

export default ToggleCollapse;
