/**
 * Labstep
 *
 * @author     Onoufrios Malikkides <onoufrios@labstep.com>
 *
 * @desc Empty State to show in for premium features
 */

import { premiumFeaturesInfo } from 'labstep-web/constants/premium-features';
import Button from 'labstep-web/core/Button';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import { intercomService } from 'labstep-web/services/intercom.service';
import React, { useEffect } from 'react';
import { Image } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { IPremiumFeatureEmptyStateProps } from './types';

export const PremiumFeatureEmptyState: React.FC<
  IPremiumFeatureEmptyStateProps
> = ({ premiumFeature }) => {
  const info = premiumFeaturesInfo[premiumFeature];
  useEffect(() => {
    intercomService.trackEvent('hit-premium-feature-block', {
      feature: premiumFeature,
    });
  });

  const upgradeButton = (
    <Button
      primary
      className={styles.shinyButton}
      id="upgrade-now"
      onClick={() => {
        intercomService.trackEvent('click-premium-feature-upgrade', {
          feature: premiumFeature,
        });
      }}
    >
      <Icon name="arrow alternate circle up" />
      Upgrade Now{' '}
    </Button>
  );

  const learnMoreButton = info.link && (
    <Button
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      href={info.link}
      basic
      primary
      as="a"
      target="_blank"
      rel="noopener noreferrer"
    >
      Learn More
    </Button>
  );

  if (!info.image) {
    return (
      <EmptyState
        src="/img/error/locked.svg"
        title="Premium Feature"
        explanation={`${
          info.name
        } is a premium feature. If you are interested in activating this feature click below to contact us${
          info.link ? ' or learn more' : ''
        }.`}
        action={upgradeButton}
        secondaryAction={learnMoreButton}
      />
    );
  }

  return (
    <Flex
      spacing="gap"
      style={{
        flexWrap: 'wrap-reverse',
        padding: '10px',
        gap: '20px',
      }}
    >
      <Flex spacing="gap" column>
        <Header>{info.title || info.name}</Header>
        <div>
          {info.selling_points &&
            info.selling_points.map((point) => (
              <Flex key={point}>
                <Icon size="large" name="check" color="green" />
                <Header size="mini">{point}</Header>
              </Flex>
            ))}
        </div>

        <div>
          {upgradeButton}
          {learnMoreButton}
        </div>
      </Flex>
      {info.image && (
        <div className={styles.imageContainer}>
          <Image src={info.image} alt={info.name} fluid />
        </div>
      )}
    </Flex>
  );
};

export default PremiumFeatureEmptyState;
