/**
 * Labstep
 */

import AccessKeyManager from 'labstep-web/components/AccessKey/Manager';
import Container from 'labstep-web/components/Layout/Container';
import Email from 'labstep-web/components/User/Settings/Email';
import LoginSecurity from 'labstep-web/components/User/Settings/LoginSecurity';
import UserSettingsMFA from 'labstep-web/components/User/Settings/Mfa';
import { User } from 'labstep-web/models/user.model';
import React from 'react';

export const getLinks = (user: User) => {
  const links = [
    {
      key: 'General',
      render: () => (
        <div>
          <Container>
            <Email user={user} />
          </Container>
          {!user.is_external && (
            <>
              <Container>
                <LoginSecurity />
              </Container>
              <Container>
                <UserSettingsMFA user={user} />
              </Container>
            </>
          )}
        </div>
      ),
    },
    {
      key: 'API',
      render: () => <AccessKeyManager />,
    },
  ];

  return links;
};
