/**
 * Labstep
 */

import { EntityStateWorkflow } from 'labstep-web/models/entity-state-workflow.model';
import { EntityStateType } from 'labstep-web/models/entity-state.model';

export const getHelperTextByType = (
  entityStateType: EntityStateType,
) => {
  const helperTexts = {
    [EntityStateType.unstarted]:
      'Map the planning workflow i.e. any review / approval steps needed before the experiment begins.',
    [EntityStateType.started]:
      "The 'Started Date' for the experiment will be automatically set once you enter the first started state.",
    [EntityStateType.completed]:
      "The 'Completed Date' for the experiment will be automatically set once you enter the first completed state.",
  };

  return helperTexts[entityStateType];
};

export const getMaxPositionByType = (
  entityStateWorkflow: EntityStateWorkflow,
  type: EntityStateType,
) => {
  const entityStates = entityStateWorkflow.entity_states.filter(
    (e) => e.type === type.toLowerCase(),
  );
  if (entityStates.length === 0) {
    return getEntityStateBasePostionByType(type);
  }
  const lastEntityState = entityStates[entityStates.length - 1];

  return lastEntityState.position + 1;
};

export const getRandomColor = () => {
  const DEFAULT_STATE_COLORS = [
    '#ffc214',
    '#d38cff',
    '#1162a1',
    '#ff8514',
    '#5ac361',
    '#0057dd',
  ];

  return DEFAULT_STATE_COLORS[
    Math.floor(Math.random() * DEFAULT_STATE_COLORS.length)
  ];
};

export const getDefaultIcon = (entityStateType: EntityStateType) => {
  const DEFAULT_STATUS_ICONS = ['clock', 'adjust', 'check circle'];

  return DEFAULT_STATUS_ICONS[
    Object.values(EntityStateType).findIndex(
      (stateType) => stateType === entityStateType,
    )!
  ];
};

export const getEntityStateBasePostionByType = (
  entityStateType: EntityStateType,
) => {
  const BASE_POSITIONS = {
    [EntityStateType.unstarted]: 10,
    [EntityStateType.started]: 10000,
    [EntityStateType.completed]: 10000000,
  };
  return BASE_POSITIONS[entityStateType];
};
