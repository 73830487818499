/**
 * Labstep
 *
 * @module containers/Entity/Read/Entity
 * @desc Container for reading one entity
 */

import { readEntity } from 'labstep-web/state/actions/entity';
import {
  selectEntity,
  selectReadEntityStatus,
} from 'labstep-web/state/selectors/entity';
import { LabstepReduxState } from 'labstep-web/state/types';
import { IOptions } from 'labstep-web/typings';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import {
  IEntityReadEntityContainerChildrenProps,
  IEntityReadEntityContainerProps,
} from './types';

export type EntityReadEntityContainerChildrenProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

const Container = ({
  children,
  ...rest
}: EntityReadEntityContainerChildrenProps & {
  children: React.FC<
    Omit<EntityReadEntityContainerChildrenProps, 'children'>
  >;
}) => children(rest);

const mapStateToProps = (
  state: LabstepReduxState,
  ownProps: IEntityReadEntityContainerProps,
): Pick<
  IEntityReadEntityContainerChildrenProps,
  'entity' | 'status'
> => {
  let id;
  if ('guid' in ownProps) {
    const entities = state.entities
      ? Object.values(
          state.entities[
            ownProps.entityName as keyof typeof state.entities
          ].byId,
        )
      : ([] as { id: number; guid: string }[]);
    id = entities.find((entity) => entity.guid === ownProps.guid)?.id;
  } else {
    id = ownProps.id;
  }
  return {
    entity: id ? selectEntity(state, ownProps.entityName, id) : null,
    status: id
      ? selectReadEntityStatus(state, ownProps.entityName, id)
      : null,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: IEntityReadEntityContainerProps,
) => ({
  read(options?: IOptions) {
    const id = 'guid' in ownProps ? ownProps.guid : ownProps.id;
    dispatch(readEntity(ownProps.entityName, id, options));
  },
});

export const EntityReadEntityContainer: React.FC<IEntityReadEntityContainerProps> =
  connect(mapStateToProps, mapDispatchToProps)(Container);
