/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Create
 * @desc Create Action for order request workflow
 */

import { currencies } from 'labstep-web/constants/currencies';
import {
  FieldType,
  IFieldTypeSearchSelectProps,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { Resource } from 'labstep-web/models/resource.model';
import rules from 'labstep-web/services/validation/rules';

export const DEFAULT_QUANTITY_VALUE = 1;

export const fieldQuantity: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'quantity',
  fieldLabel: 'Number of Items',
  placeholder: 'Enter number of items requested',
  validation: rules.order_request.quantity,
};

export const fieldOrderRequestAmount: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'amount',
  fieldLabel: 'Price',
  placeholder: 'Enter Price',
  validation: rules.order_request.amount,
};

export const fieldResource: IFieldTypeSearchSelectProps = {
  fieldType: FieldType.SearchSelect,
  name: 'resource',
  elementProps: {
    entityName: 'resource',
    creatable: true,
  },
  fieldLabel: Resource.getHumanReadableEntityName(false, true),
  validation: rules.order_request.resource,
};

export const fieldUrgent: IFieldWithoutActionProps = {
  name: 'is_urgent',
  fieldLabel: 'Urgent',
  fieldType: FieldType.Checkbox,
  validation: rules.order_request.is_urgent,
};

export const fieldOrderRequestCurrency: IFieldWithoutActionProps = {
  fieldType: FieldType.ReactSelect,
  name: 'currency',
  fieldLabel: 'Currency',
  placeholder: 'Enter Currency',
  elementProps: {
    openMenuOnFocus: true,
    options: Object.values(currencies).map((currency) => ({
      label: `${currency.code} - ${currency.name}`,
      value: currency.code,
    })),
  },

  validation: rules.order_request.currency,
};
