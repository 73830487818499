/**
 * Labstep
 *
 * @module components/Experiment/Action/EndWithCheck
 * @desc Action to finish experiment
 */

import ExperimentCompletionChecklistModal from 'labstep-web/components/Experiment/CompletionChecklist/Modal';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import React from 'react';
import ExperimentActionEnd from '..';
import { IExperimentActionEndWithCheckProps } from './types';

export const ExperimentActionEndWithCheck: React.FC<
  IExperimentActionEndWithCheckProps
> = ({ experiment, options = {}, actionType, activeGroup }) => {
  if (activeGroup.is_completion_checklist_enabled) {
    return (
      <ExperimentCompletionChecklistModal
        experiment={experiment}
        actionType={actionType}
      />
    );
  }
  return (
    <ExperimentActionEnd
      experiment={experiment}
      actionType={actionType}
      options={options}
    />
  );
};

export default withActiveGroup(ExperimentActionEndWithCheck);
