/**
 * Labstep
 *
 * @module components/Metadata/Action/ClearValue
 * @desc Table listing all metadatas
 */

import React from 'react';
import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { IActionComponentProps } from 'labstep-web/core/Action/Component/types';
import { IMetadataActionClearValueProps } from './types';
import { getClearMetadataBody } from './utils';

const actionComponentProps = {
  type: 'icon',
  icon: 'close',
  elementProps: {
    popup: {
      content: 'Clear',
      position: 'right center',
    },
  },
} as IActionComponentProps;

const MetadataActionClearValue: React.FC<
  IMetadataActionClearValueProps
> = ({ metadata, entity }) => {
  if (!metadata.hasValue || metadata.type === MetadataType.file) {
    return null;
  }

  const { entityName, entityId, body } =
    getClearMetadataBody(metadata);

  if (metadata.getIsServingAsDefaultTemplate(entity)) {
    return null;
  }

  return (
    <EntityActionUpdate
      entityName={entityName}
      id={entityId}
      body={body}
      optimistic
      actionComponentProps={actionComponentProps}
    />
  );
};

export default MetadataActionClearValue;
