/**
 * Labstep
 *
 * @module components/Signature/List
 * @desc Signature list
 */

import DropdownPill from 'labstep-web/core/Dropdown/Pill';
import Flex from 'labstep-web/core/Flex';
import React, { useState } from 'react';
import SignatureCard from '../../../Card';
import SignatureHeader from '../../../Header';
import SignatureListEntityStateCardsToBeAssigned from '../ToBeAssigned';
import { ISignatureListEntityStateProps } from '../types';

export const SignatureListEntityState: React.FC<
  ISignatureListEntityStateProps
> = ({
  entityState,
  experimentWorkflow,
  round,
  showState = true,
  entityUsers,
}) => {
  const [stateCurrentRounds, setStateCurrentRounds] = useState<{
    [key: string]: number;
  }>(
    experimentWorkflow!.entity_state_workflow!.getStateCurrentRounds(
      experimentWorkflow.entity_state_round,
    ),
  );

  return (
    <div key={entityState.name} data-testid={entityState.name}>
      {showState && (
        <Flex
          spacing="between"
          vAlignContent="center"
          marginTop={10}
          marginBottom={10}
        >
          {!round && (
            <SignatureHeader
              name={entityState.name}
              icon={entityState.icon}
            />
          )}
          {experimentWorkflow.entity_state_round > 1 && !round && (
            <DropdownPill
              activeLabel={`Round ${
                stateCurrentRounds[entityState.name]
              } of ${experimentWorkflow.entity_state_round}`}
              items={Array.from(
                { length: experimentWorkflow.entity_state_round },
                (_, index) => ({
                  label: `Round ${index + 1} of ${
                    experimentWorkflow.entity_state_round
                  }`,
                  onClick: () => {
                    setStateCurrentRounds({
                      ...stateCurrentRounds,
                      [entityState.name]: index + 1,
                    });
                  },
                }),
              )}
            />
          )}
        </Flex>
      )}
      <Flex spacing="gap" column>
        {entityState.entity_user_role_requirements.flatMap(
          (entityUserRoleRequirement) => {
            return (
              <>
                {experimentWorkflow.signatures
                  .filter((s) => !s.revoked_at)
                  .filter(
                    (s) =>
                      s.entity_user_role_requirement_id ===
                      entityUserRoleRequirement.id,
                  )
                  .filter(
                    (signature) =>
                      signature.entity_state_round ===
                      stateCurrentRounds[entityState.name],
                  )
                  .map((signature) => (
                    <div
                      data-testid="signature-item"
                      key={signature.id}
                    >
                      <SignatureCard
                        signature={signature}
                        entityUserRole={
                          entityUserRoleRequirement.entity_user_role
                        }
                        experimentWorkflow={experimentWorkflow}
                      />
                    </div>
                  ))}
              </>
            );
          },
        )}

        <SignatureListEntityStateCardsToBeAssigned
          entityState={entityState}
          experimentWorkflow={experimentWorkflow}
          entityUsers={entityUsers}
          round={round ?? stateCurrentRounds[entityState.name]}
        />
      </Flex>
    </div>
  );
};

export default SignatureListEntityState;
