/**
 * Labstep
 *
 * @module components/Metadata/Card/Preview/WithEntity
 * @desc Metadata Card With Experiment Entity Link
 */

import EntityCardData from 'labstep-web/components/Entity/Card/Data';
import MetadataThread from 'labstep-web/components/Metadata/Thread';
import MetadataValue from 'labstep-web/components/Metadata/Value';
import { ICONS } from 'labstep-web/constants/icons';
import Icon from 'labstep-web/core/Icon';
import Link from 'labstep-web/core/Link';
import React from 'react';
import { IMetadataCardPreviewWithEntityProps } from './types';

export const MetadataCardPreviewWithEntity: React.FC<
  IMetadataCardPreviewWithEntityProps
> = ({ metadata }) => (
  <EntityCardData
    icon={ICONS.metadata.default}
    date={metadata.updated_at}
    label={metadata.label}
    origin={
      <Link
        to="experiment_show"
        params={{ id: metadata.experiment_id }}
      >
        <Icon name={ICONS.experiment.primary} />
        {metadata.experiment_name}
      </Link>
    }
    value={<MetadataValue metadata={metadata} canEdit={false} />}
    actions={<MetadataThread metadata={metadata} />}
  />
);

export default MetadataCardPreviewWithEntity;
