/**
 * Labstep
 *
 * @module components/Group/Settings/Export
 * @desc Export Group Card
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { STRINGS } from 'labstep-web/strings';
import ActionComponent from 'labstep-web/core/Action/Component';

const GroupSettingsExport: React.FC = () => {
  return (
    <EmptyState
      src="/img/empty-state/export-client-graphic.svg"
      explanation={STRINGS.export_client.message}
      action={
        <a
          href={STRINGS.export_client.urlWindows}
          style={{ color: 'white' }}
          aria-label="Download for Windows"
        >
          <ActionComponent
            type="button"
            elementProps={{ primary: true }}
            text="Download for Windows"
          />
        </a>
      }
      secondaryAction={
        <a
          href={STRINGS.export_client.urlMac}
          style={{ color: 'white' }}
          aria-label="Download for Mac"
        >
          <ActionComponent
            type="button"
            elementProps={{ primary: true, basic: true }}
            text="Download for Mac"
          />
        </a>
      }
    />
  );
};

export default GroupSettingsExport;
