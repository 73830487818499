/**
 * Labstep
 *
 * @module prosemirror/marks/sup
 * @desc Sup mark
 */

import { toggleMark } from 'prosemirror-commands';
import { LabstepMark } from 'labstep-web/prosemirror/marks/types';

const MarkSup: LabstepMark = {
  key: 'sup',
  spec: {
    excludes: 'sub',
    toDOM() {
      return ['sup', 0];
    },
    parseDOM: [
      { tag: 'sup' },
      {
        style: 'vertical-align',
        getAttrs: (value) => {
          return value === 'super' && null;
        },
      },
    ],
  },
  keymap: {
    'Mod-.': (state, dispatch) =>
      toggleMark(state.schema.marks[MarkSup.key], {})(
        state,
        dispatch,
      ),
  },
  toolbarIcon: 'superscript',
};

export default MarkSup;
