/**
 * Labstep
 *
 * @module hoc/ReadOnMount
 * @desc Reads entity/entities on mount
 * Passes read and status to children
 */

import LoadingContent from 'labstep-web/components/Layout/LoadingContent';
import { EntityReadCountContainer } from 'labstep-web/containers/Entity/Read/Count';
import { EntityReadCursorContainer } from 'labstep-web/containers/Entity/Read/Cursor';
import { EntityReadEntitiesContainer } from 'labstep-web/containers/Entity/Read/Entities';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import { EntityReadPageContainer } from 'labstep-web/containers/Entity/Read/Page';
import { ErrorBoundaryContainer } from 'labstep-web/containers/ErrorBoundary';
import Paginator from 'labstep-web/core/Paginator';
import React from 'react';
import {
  IReadOnMountContainerProps,
  IReadOnMountErrorProps,
  IReadOnMountProps,
  IReadOnMountState,
} from './types';
import { inequalFunc as inequalFuncDefault } from './utils';

export class ReadOnMount extends React.Component<
  IReadOnMountProps,
  IReadOnMountState
> {
  public static defaultProps = {
    loading: { loader: 'spinner' },
  };

  /**
   * Read is set as false by default. If read is false
   * we return an empty div. This is to prevent the component
   * from mounting its children before calling read() as read() is called when
   * component has already mounted so for the first cycle it will render the children
   * rather than showing the spinner. (e.g. If we read a
   * protocol collection with id 1 then move to another page and come back to
   * read the protocol collection with id 1 again the status will already be
   * set to { isFetching: false, error: null } and the dated protocol collection
   * data will be passed to the component for the first cycle. This becomes a problem
   * for ProtocolCollectionRedirect.
   */
  public constructor(props: IReadOnMountProps) {
    super(props);
    this.state = {
      read: false,
    };
  }

  public componentDidMount(): void {
    const { read, readOnMount } = this.props;
    if (readOnMount) {
      readOnMount();
    } else {
      read();
    }
    this.setState({ read: true });
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: IReadOnMountProps,
  ): void {
    if (this.inequalFunc(this.props, nextProps)) {
      this.setState({ read: false });
    }
  }

  public componentDidUpdate(prevProps: IReadOnMountProps): void {
    const { read } = this.props;
    if (this.inequalFunc(this.props, prevProps)) {
      read();
      this.setState({ read: true });
    }
  }

  public inequalFunc(
    prevProps: IReadOnMountProps,
    nextProps: IReadOnMountProps,
  ): boolean {
    const inequalFunc = this.props.inequalFunc || inequalFuncDefault;
    return inequalFunc(prevProps, nextProps);
  }

  public isCached(): boolean {
    const { loading, status } = this.props;
    return Boolean(
      loading && loading.cached && status && status.cached,
    );
  }

  public render(): React.ReactNode {
    const { children, loading, status, loadingContainer } =
      this.props;
    const { read } = this.state;

    if (!loading || this.isCached()) {
      return children();
    }

    if (!read) {
      return <div />;
    }

    return loadingContainer ? (
      loadingContainer(children())
    ) : (
      <LoadingContent status={status} {...loading} defaultFetching>
        {() => children()}
      </LoadingContent>
    );
  }
}

export const ReadOnMountContainer: React.FC<
  IReadOnMountContainerProps
> = (props) => {
  const sharedProps = {
    loading: props.loading,
    inequalFunc: props.inequalFunc,
  };
  if (props.type === 'page') {
    const { type, children, options, ...rest } = props;
    return (
      <EntityReadPageContainer {...rest}>
        {(containerProps) => (
          <ReadOnMount
            children={() => children(containerProps)}
            status={containerProps.status}
            read={() => containerProps.read(options)}
            params={rest.params}
            {...sharedProps}
          />
        )}
      </EntityReadPageContainer>
    );
  }
  if (props.type === 'cursor') {
    const { type, children, options, pagination, ...rest } = props;
    return (
      <EntityReadCursorContainer {...rest}>
        {(containerProps) => (
          <ReadOnMount
            children={() => children(containerProps)}
            status={containerProps.status}
            read={() => containerProps.read(options)}
            readOnMount={() =>
              containerProps.read(options, {
                refresh: true,
                mount: true,
              })
            }
            loadingContainer={(element) => (
              <Paginator {...containerProps} {...pagination}>
                {element}
              </Paginator>
            )}
            params={rest.params}
            {...sharedProps}
          />
        )}
      </EntityReadCursorContainer>
    );
  }
  if (props.type === 'count') {
    const { type, children, options, ...rest } = props;
    return (
      <EntityReadCountContainer {...rest}>
        {(containerProps) => (
          <ReadOnMount
            children={() => children(containerProps)}
            status={containerProps.status}
            read={() => containerProps.read(options)}
            params={rest.params}
            {...sharedProps}
          />
        )}
      </EntityReadCountContainer>
    );
  }
  if (props.type === 'entity') {
    const { type, children, options, ...rest } = props;
    return (
      <EntityReadEntityContainer {...rest}>
        {(containerProps) => (
          <ReadOnMount
            children={() => children(containerProps)}
            status={containerProps.status}
            read={() => containerProps.read(options)}
            id={'guid' in rest ? rest.guid : rest.id}
            {...sharedProps}
          />
        )}
      </EntityReadEntityContainer>
    );
  }
  const { type, children, options, ...rest } = props;
  return (
    <EntityReadEntitiesContainer {...rest}>
      {(containerProps) => (
        <ReadOnMount
          children={() => children(containerProps)}
          status={containerProps.status}
          read={() => containerProps.read(options)}
          params={rest.params}
          {...sharedProps}
        />
      )}
    </EntityReadEntitiesContainer>
  );
};

export const ReadOnMountHOC: React.FC<IReadOnMountErrorProps> = ({
  FallbackComponent,
  ...props
}) => (
  <ErrorBoundaryContainer FallbackComponent={FallbackComponent}>
    <ReadOnMountContainer {...props} />
  </ErrorBoundaryContainer>
);
