/**
 * Labstep
 *
 * @module core/Sortable
 * @desc Component for sortable items
 */

import React, { useMemo, useState } from 'react';
import {
  Active,
  closestCenter,
  DndContext,
  MouseSensor,
  useSensor,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext } from '@dnd-kit/sortable';
import { SortableOverlay } from './Overlay';
import { getOldAndNewIndex } from './utils';
import { ISortableItemProps, ISortableProps } from './types';

export const Sortable = <T extends ISortableItemProps>({
  items,
  renderItem,
  onSortEnd,
  hideOverlay,
}: ISortableProps<T>): React.ReactElement => {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items],
  );

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 1 pixel before activating
    // To support click events on sortable items
    activationConstraint: {
      distance: 1,
    },
  });

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      sensors={[mouseSensor]}
      onDragStart={({ active: onDragStartActive }): void => {
        setActive(onDragStartActive);
      }}
      onDragEnd={({ active: onDragEndActive, over }): void => {
        if (over && onDragEndActive.id !== over?.id) {
          const { oldIndex, newIndex } = getOldAndNewIndex(
            items,
            onDragEndActive,
            over,
          );
          onSortEnd({ oldIndex, newIndex });
        }
        setActive(null);
      }}
      onDragCancel={(): void => {
        setActive(null);
      }}
    >
      <SortableContext items={items}>
        {items.map((item) => renderItem(item))}
      </SortableContext>
      {!hideOverlay && (
        <SortableOverlay>
          {activeItem ? renderItem(activeItem) : null}
        </SortableOverlay>
      )}
    </DndContext>
  );
};

export default Sortable;
