/**
 * Labstep
 */

import { Folder } from 'labstep-web/models/folder.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { Resource } from 'labstep-web/models/resource.model';
import {
  capitalize,
  getHumanReadableEntityName,
  pluralize,
} from 'labstep-web/services/i18n.service';
import { IToastProps } from './types';

export const DEFAULT_MESSAGE =
  'Something went wrong! Please try again or refresh the page.';
export const PROCESSED_MESSAGE =
  "We'll email you when your request has been processed";

export const permissionDuplicateErrorToast = () => ({
  type: 'success',
  message: `Workspace already added.`,
});

export const restoreToast = (toast: IToastProps['toast']) => {
  const { name } = toast.meta.denormalized_payload;
  const message = name
    ? `You restored "${name}"`
    : 'Restoring successful';
  return {
    type: 'success',
    message,
    options: {
      header: `${capitalize(
        getHumanReadableEntityName(toast.meta.entityName),
      )} restored`,
    },
  };
};

export const removeToast = (toast: IToastProps['toast']) => ({
  type: 'success',
  message:
    toast.message ||
    `Removed from your ${getHumanReadableEntityName(
      toast.meta.entityName,
    )}s`,
  options: {
    header: '',
  },
});

export const sharedToast = {
  type: 'success',
  message: `Shared`,
};

export const transferOwnershipToast = (
  toast: IToastProps['toast'],
) => ({
  type: 'success',
  message: `Ownership Transferred to ${toast.meta.denormalized_payload.owner.name}`,
});

export const addTagToast = (toast: IToastProps['toast']) => ({
  type: 'success',
  message: `${getHumanReadableEntityName(
    toast.meta.parentName,
    true,
    true,
  )} tagged.`,
  options: {
    header: 'Tagged',
  },
});

export const marketingConsentToast = {
  type: 'success',
  message: 'You updated your marketing preferences',
  options: {
    header: 'Marketing Consent',
  },
};

export const createSignatureRequestToast = {
  type: 'success',
  message: `Signature request(s) sent`,
  options: {
    header: 'Request sent',
    timeout: 4000,
  },
};

export const createOrderRequestToast = {
  type: 'success',
  message: `${getHumanReadableEntityName(
    Resource.entityName,
    true,
    true,
  )} have been requested.`,
  options: {
    header: `${getHumanReadableEntityName(
      OrderRequest.entityName,
      true,
      true,
    )} created.`,
  },
};

export const addFolderToast = {
  type: 'success',
  message: `Added to ${Folder.getHumanReadableEntityName()}.`,
};

export const disableNotificationsToast = {
  type: 'success',
  message: `Added to ${Folder.getHumanReadableEntityName()}.`,
};

export const createExportToast = {
  type: 'success',
  message: `An email will be send to you shortly with your export data.`,
  options: {
    header: 'Export successful',
    timeout: 8000,
  },
};

export const sendInvitationsToast = {
  type: 'success',
  message: `Invitations sent.`,
  options: {
    header: 'Success',
    timeout: 4000,
  },
};

export const addToProtocolLibraryToast = {
  type: 'success',
  message: 'This protocol was added to your Protocol Library.',
  options: {
    header: 'New Protocol',
  },
};

export const createNewProtocolFromExperimentWithCollectionToast = (
  toast,
) => ({
  type: 'success',
  message: `New version of ${toast.meta.denormalized_payload.collection.name} created.`,
  options: {
    header: 'New Version',
  },
});

export const createProtocolFromExperimentToast = (
  toast: IToastProps['toast'],
) => ({
  type: 'success',
  message: `New Protocol ${toast.meta.denormalized_payload.collection.name} created.`,
  options: {
    header: 'New Protocol',
  },
});

export const createProtocolToast = {
  type: 'success',
  message:
    "We've created a new version for you as this protocol was used in some experiments.",
  options: {
    header: 'New Version',
    timeout: 4000,
  },
};

export const experimentCompletedToast = (
  toast: IToastProps['toast'],
) => ({
  type: 'success',
  message: `You completed "${toast.meta.denormalized_payload.protocol}"`,
  options: {
    header: 'Experiment completed',
  },
});

export const createProtocolCollectionToast = (toast, context) => ({
  type: 'success',
  message: `${toast.meta.denormalized_payload} has been added to ${
    context ? context.name : 'your protocols'
  }`,
  options: {
    header: 'Protocol created',
  },
});

export const addResourceToResourceListToast = (
  toast: IToastProps['toast'],
) => ({
  type: 'success',
  message: `"${
    toast.meta.denormalized_payload
  }" has been added to ${Resource.getHumanReadableEntityName(
    true,
    false,
  )}.`,
  options: {
    header: `${Resource.getHumanReadableEntityName(
      false,
      true,
    )} created`,
  },
});

export const createResourceToast = (toast: IToastProps['toast']) => ({
  type: 'success',
  message: `"${
    toast.meta.denormalized_payload.name ||
    toast.meta.denormalized_payload.custom_identifier
  }" has been created and added to ${Resource.getHumanReadableEntityName(
    true,
    false,
  )}.`,
  options: {
    header: `${Resource.getHumanReadableEntityName(
      false,
      true,
    )} created`,
  },
});

export const metadataLabelExistsToast = {
  type: 'error',
  message:
    'Metadata field name already exists. Please choose another name.',
};

export const askExpertToast = {
  type: 'success',
  message:
    'Keep an eye on your notifications to know when the community has responded to your question.',
};

export const successEmailSharelinkToast = {
  type: 'success',
  message: 'Invitation sent!',
};

export const failEmailSharelinkToast = {
  type: 'error',
  message:
    'There was a problem sending your invitations. Please ensure the email addresses are valid.',
};

export const failSharelinkAcceptToast = (
  toast: IToastProps['toast'],
) => ({
  type: 'error',
  message:
    (toast.error && toast.error.data && toast.error.data.message) ||
    'Something went wrong..',
});

export const failCreateFileToast = (toast: IToastProps['toast']) => ({
  type: 'error',
  message:
    (toast.error && toast.error.data && toast.error.data.error) ||
    'Your file could not be uploaded at this time',
  options: {
    header: 'Error',
  },
});

export const failUpdateUserGroupToast = (
  toast: IToastProps['toast'],
) => ({
  type: 'error',
  message: toast.error.data && toast.error.data.message,
});

export const addToOrderToast = {
  type: 'success',
  message: `Successfully added to order`,
  options: {
    header: '',
  },
};

export const whitelistIpErrorToast = {
  type: 'error',
  message:
    'Make sure at least one of the provided ip addresses is the one currently in use.',
  options: {
    header: 'Error',
    timeout: 4000,
  },
};

export const failUpdateProtocolToast = {
  type: 'error',
  message:
    'Failed to save the protocol. Note down your changes and try refreshing the page.',
  options: {
    header: 'Looks like something went wrong!',
  },
};

export const failCreateReviewToast = {
  type: 'error',
  message: 'You are only allowed to leave one review.',
  options: {
    header: 'Error',
  },
};

export const failCreateTagToast = {
  type: 'error',
  message: `Tag with this name and type already exists in this workspace`,
  options: {
    header: 'Error',
  },
};

export const failCreateResourceLocationToast = (
  toast: IToastProps['toast'],
) => ({
  type: 'error',
  message: `'${toast.meta.body.name}' has already been taken.`,
  options: {
    header: 'Error',
  },
});

export const failUpdateProtocolTableToast = (
  toast: IToastProps['toast'],
) => {
  const isFailUpdateProtocolTableWithError =
    toast.error &&
    toast.error.data &&
    toast.error.data.message &&
    toast.error.data.message[0];
  const error = isFailUpdateProtocolTableWithError
    ? toast.error.data.message[0]
    : {};

  return {
    type: 'error',
    message: error.message || DEFAULT_MESSAGE,
    options: {
      header: 'Looks like something went wrong!',
    },
  };
};

export const failCreateCustomIdentifierSetToast = {
  type: 'error',
  message: 'This prefix already exists within the organization.',
  options: {
    header: 'Error',
  },
};

export const confirmTokenToast = {
  type: 'success',
  message: 'You have succesfully verified your email address',
  options: {
    header: 'Confirmed',
  },
};

export const groupAutoSharingPropagating = {
  type: 'error',
  message:
    'Cannot delete rule while changes are propagating. Retry later.',
  options: {
    header: 'Error',
  },
};

export const organizationAtLeastOneAdmin = {
  type: 'error',
  message: 'Your organization must have at least one admin.',
  options: {
    header: 'Error',
  },
};

export const failToast = {
  type: 'error',
  message: DEFAULT_MESSAGE,
  options: {
    header: 'Error',
  },
};

export const endExperimentToast = (toast: IToastProps['toast']) => ({
  type: 'success',
  message: `You have marked ${
    Array.isArray(toast.meta.identifier)
      ? pluralize('experiment')
      : 'experiment'
  } as finished`,
  options: {},
});

export const exportDataToast = (toast: IToastProps['toast']) => ({
  type: 'success',
  message: toast.message || PROCESSED_MESSAGE,
  options: {
    header: 'Request queued',
  },
});
