/**
 * Labstep
 *
 * @module models/security-log
 * @desc Typescript export class for SecurityLog
 */

import { Entity } from 'labstep-web/models/entity.model';
import { Type } from 'class-transformer';
import { UAParser } from 'labstep-web/services/parser/user-agent';
import { humanReadableDate } from 'labstep-web/services/date.service';
import { User } from './user.model';

// eslint-disable-next-line no-shadow
export enum SecurityLogType {
  login = 'login',
  login_failed = 'login_failed',
  logout = 'logout',
  activity = 'activity',
}

export class SecurityLog extends Entity {
  static readonly entityName = 'security_log';

  get entityName(): string {
    return SecurityLog.entityName;
  }

  constructor(data: Partial<SecurityLog> = {}) {
    super();
    Object.assign(this, data);
  }

  id: number;

  started_at: string;

  ended_at: string;

  type: SecurityLogType;

  ip: string;

  user_agent: string;

  @Type(() => User)
  user: User;

  get userAgentObject(): UAParser.IResult {
    const parser = new UAParser();

    parser.setUA(this.user_agent);

    return parser.getResult();
  }

  get browser(): UAParser.IBrowser {
    return this.userAgentObject.browser;
  }

  get device(): UAParser.IDevice {
    return this.userAgentObject.device;
  }

  get os(): UAParser.IOS {
    return this.userAgentObject.os;
  }

  get printDeviceType(): string {
    const { device } = this.userAgentObject;
    return device.type || 'desktop';
  }

  get printTimeStamp(): string {
    const startedTimeStamp = humanReadableDate(this.started_at);
    if (this.started_at === this.ended_at) {
      return startedTimeStamp;
    }
    return `${startedTimeStamp}- ${humanReadableDate(this.ended_at)}`;
  }
}
