/**
 * Labstep
 */

import Image from 'labstep-web/core/Image';
import Desktop from 'labstep-web/core/MediaQuery/Desktop';
import React from 'react';
import styles from './styles.module.scss';
import { IModalWizardStepHelpProps } from './types';

export const ModalWizardStepHelp: React.FC<
  IModalWizardStepHelpProps
> = ({ help }) => (
  <Desktop>
    {({ match }) =>
      match ? (
        <div className={styles.help}>
          <div className={styles.helpContent}>
            {help}
            <Image
              className={styles.image}
              src="/img/help/steppy.svg"
            />
          </div>
        </div>
      ) : null
    }
  </Desktop>
);

export default ModalWizardStepHelp;
