/**
 * Labstep
 *
 * @desc Component to do global search
 */

import EntitySearchCursor from 'labstep-web/components/Entity/Search/Cursor';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import TableSimple from 'labstep-web/core/Table/Simple';
import React from 'react';
import { ILayoutGlobalSearchSearchResultsProps } from './types';
import { getColumns, getParams } from './utils';

export const SearchResults: React.FC<
  ILayoutGlobalSearchSearchResultsProps
> = ({ searchParams }) => (
  <EntitySearchCursor
    entityName={searchParams.entityName}
    params={{
      ...getParams(searchParams),
      workspace_is_deleted: false,
    }}
    filters={[Filter.created_by, Filter.date_range, Filter.deleted]}
    noResultsMessage={
      <EmptyState explanation="Add a search query to search for experiments, protocols, resources, items and much more across different workspaces" />
    }
    key={searchParams.entityName}
  >
    {({ entities }) => (
      <TableSimple
        hideHeader
        rows={entities}
        columns={getColumns(searchParams.entityName)}
      />
    )}
  </EntitySearchCursor>
);

export default SearchResults;
