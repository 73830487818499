/**
 * Labstep
 *
 * @module components/Metadata/Thread
 * @desc Side menu. Shows thread on open
 */

import React from 'react';
import ProtocolChildRouter from 'labstep-web/containers/Router/ProtocolChild';
import ThreadCountIcon from 'labstep-web/components/Thread/CountIcon';
import Modal from 'labstep-web/core/Modal/Default';
import MetadataThreadDetail from './Detail';
import {
  IMetadataThreadProps,
  IMetadataThreadContainerProps,
} from './types';

export const MetadataThread: React.FC<IMetadataThreadProps> = ({
  metadata,
  navigate,
}) => {
  const viewComponent = ({ toggleModal }) => (
    <ThreadCountIcon
      onClick={toggleModal}
      count={metadata.thread.comment_count}
    />
  );

  return navigate ? (
    viewComponent({
      toggleModal: navigate,
    })
  ) : (
    <Modal
      header={`Notes on ${metadata.label}`}
      viewComponent={viewComponent}
      content={<MetadataThreadDetail metadata={metadata} />}
    />
  );
};

export const MetadataThreadContainer: React.FC<
  IMetadataThreadContainerProps
> = (props) => (
  <ProtocolChildRouter
    route="metadata_show_thread"
    id={props.metadata.id}
  >
    {({ navigate }) => (
      <MetadataThread {...props} navigate={navigate} />
    )}
  </ProtocolChildRouter>
);

export default MetadataThreadContainer;
