/**
 * Labstep
 *
 * @module components/ShareLink/Accept/Entity
 * @desc Components to accept sharelink for entities
 */

import React from 'react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import Button from 'labstep-web/core/Button';
import { ShareLinkHeader } from 'labstep-web/screens/Authentication/Signin/ShareLinkHeader';
import FormReusable from 'labstep-web/core/Form/Reusable';
import { Group } from 'labstep-web/models/group.model';
import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { object } from 'yup';
import { IShareLinkNotGroupOrganizationProps } from './types';

export const fieldWorkspace: IFieldWithoutActionProps = {
  fieldType: FieldType.SearchSelect,
  name: 'workspace',
  validation: object().required(),
  elementProps: {
    entityName: Group.entityName,
    creatable: true,
  },
};

export const ShareLinkNotGroupOrganization: React.FC<
  IShareLinkNotGroupOrganizationProps
> = ({ shareLink, onChange, status }) => {
  return (
    <div>
      <ShareLinkHeader sign={null} shareLink={shareLink} />
      <p>
        {`Please select / create a workspace to save this
        ${getHumanReadableEntityName(
          shareLink.entity.entityName,
        )} to...`}
      </p>
      <FormReusable
        fields={[fieldWorkspace]}
        status={status}
        submitButtonText="Accept"
        onSubmit={(values) => {
          onChange({ id: values.workspace_id as number });
        }}
        cancelButton={
          <Button
            status={status}
            primary
            basic
            route={{ to: 'app_homepage' }}
          >
            Reject
          </Button>
        }
      />
    </div>
  );
};

export default ShareLinkNotGroupOrganization;
