/**
 * Labstep
 *
 * @module core/TextArea
 * @desc Wrapper around react-textarea-autosize
 */

import TextareaAutosize from 'react-textarea-autosize';

export default TextareaAutosize;
