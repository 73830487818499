/**
 * Labstep
 *
 * @module components/Protocol/Action/Duplicate
 * @desc Form to duplicate a Protocol into a Group
 */

import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import { fieldGroup, fieldProtocolName } from '../fields';
import {
  IProtocolFormDuplicateCreateContainerProps,
  IProtocolFormDuplicateProps,
} from './types';

export const ProtocolFormDuplicateWithGroup: React.FC<
  IProtocolFormDuplicateProps
> = ({ activeGroup, parent, create, status, options }) => (
  <ReusableForm
    fields={[fieldProtocolName, fieldGroup]}
    status={status}
    submitButtonText="Duplicate"
    defaultValues={{
      group: activeGroup,
    }}
    onSubmit={({ name, group_id }) => {
      create(
        {
          name,
          group_id,
          parent_protocol_id: parent.id,
        },
        options,
      );
    }}
    autoFocus
  />
);

export const ProtocolFormDuplicate = withActiveGroup(
  ProtocolFormDuplicateWithGroup,
);

export const ProtocolFormDuplicateCreateContainer: React.FC<
  IProtocolFormDuplicateCreateContainerProps
> = (props) => (
  <EntityCreateContainer
    entityName={Protocol.entityName}
    showLoaderWhileCreating
  >
    {(containerProps) => (
      <ProtocolFormDuplicate {...containerProps} {...props} />
    )}
  </EntityCreateContainer>
);

export default ProtocolFormDuplicateCreateContainer;
