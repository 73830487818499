/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Menu
 * @desc Action Menu for group
 */

import React from 'react';
import ActionMenu from 'labstep-web/core/Action/Menu';
import ExperimentActionLock from 'labstep-web/components/Experiment/Action/Lock';
import ExperimentActionDelete from 'labstep-web/components/Experiment/Action/Delete';
import EntityActionPrint from 'labstep-web/components/Entity/Action/Print';
import ExperimentActionSetIsUseLatestProtocol from 'labstep-web/components/Experiment/Action/SetIsUseLatestProtocol';
import { IExperimentActionMenuProps } from './types';

export const ExperimentActionMenu: React.FC<
  IExperimentActionMenuProps
> = ({ experiment, type, actions = [] }) => (
  <ActionMenu type={type}>
    {actions.includes('lock') && (
      <ExperimentActionLock experiment={experiment} />
    )}
    {actions.includes('print') && (
      <EntityActionPrint entity={experiment} />
    )}
    {actions.includes('delete') && (
      <ExperimentActionDelete experiment={experiment} />
    )}
    {actions.includes('is_use_latest_protocol') &&
      experiment.canSetIsUseLatestProtocol && (
        <ExperimentActionSetIsUseLatestProtocol
          experiment={experiment}
        />
      )}
  </ActionMenu>
);

ExperimentActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default ExperimentActionMenu;
