/**
 * Labstep
 *
 * @module components/AccessKey/Form/Create
 * @desc Form to create metadata
 */

import React from 'react';
import FormCreate from 'labstep-web/core/Form/Create';
import { AccessKey } from 'labstep-web/models/access-key.model';
import {
  accessKeyFieldExpiresAt,
  accessKeyFieldName,
} from 'labstep-web/components/AccessKey/Form/fields';
import { IAccessKeyFormCreateProps } from './types';

const AccessKeyFormCreate: React.FC<IAccessKeyFormCreateProps> = ({
  options,
}) => (
  <FormCreate
    entityName={AccessKey.entityName}
    fields={[accessKeyFieldName, accessKeyFieldExpiresAt]}
    options={options}
    autoFocus
  />
);

export default AccessKeyFormCreate;
