/**
 * Labstep
 *
 * @module components/Resource/Action/EditOrderRequestTemplate
 * @desc Action for editing item template
 */

import React from 'react';
import Modal from 'labstep-web/core/Modal/Default';
import ResourceOrderRequestTemplateEdit from 'labstep-web/components/Resource/OrderRequestTemplate';
import ActionComponent from 'labstep-web/core/Action/Component';
import { IResourceActionEditOrderRequestTemplateProps } from './types';

export const ResourceActionEditOrderRequestTemplate: React.FC<
  IResourceActionEditOrderRequestTemplateProps
> = ({ resource, actionComponentProps }) =>
  resource.is_template ? null : (
    <Modal
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="text"
          icon="copy"
          text="Edit Request Template"
          onClick={toggleModal}
          {...actionComponentProps}
        />
      )}
      content={
        <ResourceOrderRequestTemplateEdit resource={resource} />
      }
      size="large"
      closeOnDimmerClick
    />
  );

export default ResourceActionEditOrderRequestTemplate;
