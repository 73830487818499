/**
 * Labstep
 *
 * @module prosemirror/nodes/image
 * @desc Image node
 */

import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';
import { Node as ProseMirrorNode } from 'prosemirror-model';

const NodeImage: LabstepNode = {
  key: 'image',
  spec: {
    inline: true,
    attrs: {
      src: {},
      alt: { default: null },
      title: { default: null },
    },
    group: 'inline',
    draggable: true,
    // parseDOM: [
    //   {
    //     tag: 'img[src]',
    //     getAttrs(dom): Image['attrs'] | null {
    //       if (dom instanceof HTMLElement) {
    //         return {
    //           src: dom.getAttribute('src'),
    //           title: dom.getAttribute('title'),
    //           alt: dom.getAttribute('alt'),
    //         };
    //       }
    //       return null;
    //     },
    //   },
    // ],
    toDOM(node: ProseMirrorNode) {
      const { src, alt, title } = node.attrs;
      return ['img', { src, alt, title }];
    },
  },
};

export default NodeImage;
