/**
 * Labstep
 *
 * @module constants/sensitive
 * @desc List of sensitive fields in redux actions and network request
 */

export const SENSITIVE_FIELDS = [
  'new_password',
  'new_password_confirm',
  'current_password',
  'username',
  'password',
  'email',
  'first_name',
  'last_name',
  'name',
  'properties',
  'body',
  'data',
  'body',
  'description',
  'unit',
  'quantity_unit',
  'state',
  'message',
  'data_json',
  'label',
  'value',
  'options',
  'svg',
  'table_data',
  'pubchem',
  'inchis',
  'units',
  'statement',
  'experiment_name',
  'admin_email',
  'parent_name',
];
