/**
 * Labstep
 *
 * @module components/ResourceItem/Preview
 * @desc Resource Item Preview
 */

import EntityPreview from 'labstep-web/components/Entity/Preview';
import MetadataPreview from 'labstep-web/components/Metadata/Preview';
import { ICONS } from 'labstep-web/constants/icons';
import Flex from 'labstep-web/core/Flex';
import ListLabelValue from 'labstep-web/core/List/LabelValue';
import ResourceItemShowCenterContentSecondaryInfo from 'labstep-web/screens/ResourceItem/Show/Center/SecondaryInfo';
import React from 'react';
import styles from './styles.module.scss';
import { IResourceItemPreviewProps } from './types';
import { getItemInfo, getResourceInfo } from './utils';

const ResourceItemPreview: React.FC<IResourceItemPreviewProps> = ({
  resourceItem,
  action,
  showIcon,
  showResource,
  basic,
  ...rest
}) => {
  const preview = (
    <EntityPreview
      entity={resourceItem}
      secondaryInfo={
        <ResourceItemShowCenterContentSecondaryInfo
          resourceItem={resourceItem}
        />
      }
      showIcon={showIcon}
      {...rest}
    />
  );

  return basic ? (
    preview
  ) : (
    <Flex>
      <div className={styles.info}>
        {preview}
        <ListLabelValue array={getItemInfo(resourceItem)} />
        {showResource && (
          <>
            <ListLabelValue array={getResourceInfo(resourceItem)} />
            <div className={styles.metadata}>
              <MetadataPreview
                entity={resourceItem.resource}
                withIcon
                cornerIcon={ICONS.resource.primary}
              />
            </div>
          </>
        )}
        <div className={styles.metadata}>
          <MetadataPreview entity={resourceItem} withIcon />
        </div>
        <div>{action}</div>
      </div>
    </Flex>
  );
};

export default ResourceItemPreview;
