/**
 * Labstep
 *
 * @desc Breadcrumb for Template Entity on Index page
 */

import EntityFormShowEditName from 'labstep-web/components/Entity/Form/ShowEdit/Name';
import { IEntityPreviewProps } from 'labstep-web/components/Entity/Preview/types';
import EntityTemplatePaneManager from 'labstep-web/components/Entity/Template/Pane/Manager';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import ActionComponent from 'labstep-web/core/Action/Component';
import Desktop from 'labstep-web/core/MediaQuery/Desktop';
import ScreensIndexBreadcrumb from 'labstep-web/screens/Breadcrumb';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { PostFilterTemplateNull } from 'labstep-web/services/postFilter/filters/template';
import { isActive as isFilterActive } from 'labstep-web/components/Filter/List/utils';
import styles from './styles.module.scss';
import { ITemplateBreadcrumbIndexProps } from './types';

export const TemplateBreadcrumbShowIndex: React.FC<
  ITemplateBreadcrumbIndexProps
> = ({
  entityName,
  activeEditModal,
  setActiveEditModal,
  showUncategorised = true,
  entityPreview,
  text,
  postFilter,
}) => (
  <>
    <ScreensIndexBreadcrumb
      text={
        text ||
        `All ${getHumanReadableEntityName(entityName, true, true)}`
      }
      dropdownContent={({ header }): React.ReactElement => (
        <div className={styles.contentContainer}>
          {header}
          <EntityTemplatePaneManager
            entityPreview={entityPreview}
            entityName={entityName}
            showUncategorised={showUncategorised}
            searchBarProps={{
              // Prevent mobile breadcrumb closing
              onClick: (e): void => {
                e.preventDefault();
                e.stopPropagation();
              },
            }}
            postFilter={postFilter}
          />
        </div>
      )}
      icon={ICONS[entityName].primary}
      rightMobileIcon={ICONS.resource_template.primary}
      right={({ searchParams }): React.ReactElement | null => {
        let params;
        if (postFilter) {
          const node = postFilter.getNodeInParams(searchParams);
          if (node) {
            params = {
              guid: postFilter.getAttributeValues(node).guid?.value,
            };
          }
        } else if (searchParams.template_id) {
          params = {
            id: searchParams.template_id,
          };
        }
        if (params) {
          return (
            <EntityReadEntityContainer
              entityName={entityName}
              {...params}
            >
              {({ entity }): React.ReactElement | null =>
                entity ? (
                  <div className={styles.container}>
                    <EntityFormShowEditName entity={entity} />
                    <ActionComponent
                      type="icon"
                      icon={ICONS.navigation.settings}
                      onClick={(e): void => {
                        e.preventDefault();
                        e.stopPropagation();
                        setActiveEditModal({
                          entityName,
                          id: entity.id,
                        });
                      }}
                      elementProps={{
                        popup: { content: 'Settings' },
                      }}
                    />
                  </div>
                ) : null
              }
            </EntityReadEntityContainer>
          );
        }
        if (
          searchParams.has_template === 'false' ||
          isFilterActive(searchParams, params, [
            PostFilterTemplateNull,
            [],
          ])
        ) {
          return (
            <Desktop>
              {({ match }): React.ReactElement =>
                match ? (
                  <div className={styles.desktopContainer}>
                    <span>Uncategorised</span>
                  </div>
                ) : (
                  <span>Uncategorised</span>
                )
              }
            </Desktop>
          );
        }
        return null;
      }}
    />
    {activeEditModal && activeEditModal.entityName === entityName && (
      <EntityReadEntityContainer
        entityName={entityName}
        id={activeEditModal.id}
      >
        {({
          entity,
        }: {
          entity: IEntityPreviewProps['entity'];
        }): React.ReactElement =>
          entityPreview({
            entity,
            modalProps: {
              open: true,
              viewComponent: null,
              onClose: (): void => {
                setActiveEditModal(null);
              },
            },
          }) as React.ReactElement
        }
      </EntityReadEntityContainer>
    )}
  </>
);

export default withActiveEditModal(TemplateBreadcrumbShowIndex);
