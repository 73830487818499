/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Card/EmptyState
 * @desc Empty state for group
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import ExperimentWorkflowActionAddProtocol from 'labstep-web/components/ExperimentWorkflow/Action/AddProtocol';
import { IExperimentEmptyStateProps } from './types';

export const ExperimentEmptyState: React.FC<
  IExperimentEmptyStateProps
> = ({ experimentWorkflow, ...rest }) => (
  <EmptyState
    title="No protocols"
    explanation="Add protocols to track the methods used in your experiment. Follow your protocols step-by-step and link the inventory items used in each protocol."
    src="/img/empty-state/protocols.svg"
    action={
      <ExperimentWorkflowActionAddProtocol
        experimentWorkflow={experimentWorkflow}
      />
    }
    {...rest}
  />
);

export default ExperimentEmptyState;
