/**
 * Labstep
 *
 * @module components/Metadata/Card/Preview/WithEntity
 * @desc Metadata Card With Experiment Entity Link
 */

import EntityCardData from 'labstep-web/components/Entity/Card/Data';
import FileViewer from 'labstep-web/components/File/Viewer';
import { ICONS } from 'labstep-web/constants/icons';
import React from 'react';
import { IDeviceDataPreviewProps } from './types';

export const DeviceDataPreview: React.FC<IDeviceDataPreviewProps> = ({
  deviceData,
}) => (
  <EntityCardData
    icon={ICONS.device.primary}
    date={deviceData.updated_at}
    label={deviceData.name}
    value={
      deviceData &&
      deviceData.files &&
      deviceData.files.length > 0 ? (
        <>
          {deviceData.files
            .filter((file) => file.deleted_at === null)
            .map((file) => (
              <FileViewer key={file.guid} file={file} />
            ))}
        </>
      ) : (
        deviceData.printValue
      )
    }
  />
);

export default DeviceDataPreview;
