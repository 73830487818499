/**
 * Labstep
 */

import { fieldSignatureRequirementStatement } from 'labstep-web/components/SignatureRequirement/Form/fields';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import Pre from 'labstep-web/core/Pre';
import React from 'react';
import { ISignatureRequirementFormShowEditStatementProps } from './types';

export const SignatureRequirementFormShowEditStatement: React.FC<
  ISignatureRequirementFormShowEditStatementProps
> = ({ signatureRequirement }) => (
  <ShowEditText
    entityName={signatureRequirement.entityName}
    entity={signatureRequirement}
    field={fieldSignatureRequirementStatement}
    placeholder="Add default statement"
  >
    <Pre>{signatureRequirement.statement}</Pre>
  </ShowEditText>
);

export default SignatureRequirementFormShowEditStatement;
