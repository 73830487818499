/**
 * Labstep
 *
 * @module components/Tag/Manager
 * @desc Tag Manager component
 */

import EntityToggleList from 'labstep-web/components/Entity/List/Toggle';
import TagList from 'labstep-web/components/Tag/List';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import Button from 'labstep-web/core/Button';
import Modal from 'labstep-web/core/Modal/Default';
import Popup from 'labstep-web/core/Popup';
import { Tag } from 'labstep-web/models/tag.model';
import React from 'react';
import { ITagManagerProps } from './types';

export const TagManager: React.FC<ITagManagerProps> = ({
  parentEntity,
  activeGroup,
  viewComponent,
}) => (
  <Modal
    header="Manage Tags"
    viewComponent={
      viewComponent ||
      (({ toggleModal }) => (
        <Popup
          trigger={
            <Button
              onClick={toggleModal}
              icon={ICONS.tag.primary}
              circular
            />
          }
          content="Manage Tags"
          size="mini"
        />
      ))
    }
    content={
      <EntityToggleList
        parent={parentEntity}
        entityName={Tag.entityName}
        labelKey="name"
        caseSensitive={false}
        extraParams={{
          group_id: activeGroup?.id,
        }}
        creatable
        createBody={{ type: parentEntity.entityName }}
        // One off until we come up with a more generic one
        placeholder="Search tags in this workspace or create a new one..."
        actionMessageIcon="tags"
        list={({ entities }) => (
          <TagList tags={entities} parentEntity={parentEntity} />
        )}
      />
    }
  />
);

export default withActiveGroup(TagManager);
