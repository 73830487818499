/**
 * Labstep
 *
 * @module components/Permission/Form/ShowEdit/Type
 * @desc Displays permission type and allow user to edit it
 */

import PermissionIcon from 'labstep-web/components/Permission/Icon';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ActionComponent from 'labstep-web/core/Action/Component';
import { VerticalDivider } from 'labstep-web/core/Divider';
import Dropdown from 'labstep-web/core/Dropdown';
import Icon from 'labstep-web/core/Icon';
import Loader from 'labstep-web/core/Loader';
import {
  Permission,
  PermissionType,
} from 'labstep-web/models/permission.model';
import React from 'react';
import styles from './styles.module.scss';
import { PermissionFormShowEditTypeProps } from './types';

export const PermissionFormShowEditType: React.FC<
  PermissionFormShowEditTypeProps
> = ({ permission }) => (
  <EntityUpdateContainer
    entityName={Permission.entityName}
    id={permission.guid}
  >
    {({ update, status }) => (
      <>
        {status && status.isFetching ? (
          <Loader size="mini" inline active />
        ) : (
          <Dropdown
            direction="left"
            icon={null}
            trigger={
              <div className={styles.container}>
                <PermissionIcon type={permission.type} />
                <VerticalDivider />
                <Icon name="chevron down" />
              </div>
            }
          >
            <ActionComponent
              icon={
                permission.type === PermissionType.view
                  ? 'check'
                  : null
              }
              text={Permission.permissionsText[PermissionType.view]}
              onClick={() => {
                if (permission.type === PermissionType.edit) {
                  update({
                    type: PermissionType.view,
                  });
                }
              }}
              type="option"
            />
            <ActionComponent
              icon={
                permission.type === PermissionType.edit
                  ? 'check'
                  : null
              }
              text={Permission.permissionsText[PermissionType.edit]}
              onClick={() => {
                if (permission.type === PermissionType.view) {
                  update({
                    type: PermissionType.edit,
                  });
                }
              }}
              type="option"
            />
          </Dropdown>
        )}
      </>
    )}
  </EntityUpdateContainer>
);

export default PermissionFormShowEditType;
