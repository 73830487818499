/**
 * Labstep
 *
 * @module core/Link
 * @desc Wrapper around react-router-dom RouterLink
 */

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { navigation } from 'labstep-web/services/navigation';
import cx from 'classnames';
import styles from './styles.module.scss';
import { ILinkProps } from './types';

const Link: React.FC<ILinkProps> = (props) => {
  const {
    children,
    to,
    params,
    unstyled,
    underline,
    className,
    query,
    disabled,
    ...rest
  } = props;

  const cxArgs: cx.ArgumentArray = [
    { [styles.underline]: underline },
    { [styles.unstyled]: unstyled },
    { [styles.disabled]: disabled },
  ];
  if (className) {
    cxArgs.push({ [className]: className });
  }
  const classes = cx(...cxArgs);

  return (
    <RouterLink
      className={classes}
      to={disabled ? null : navigation.get(to, params, false, query)}
      {...rest}
    >
      {children}
    </RouterLink>
  );
};

Link.defaultProps = {
  unstyled: false,
};

export default Link;
