/**
 * Labstep
 *
 * @desc Edit a permissionRole table
 */

import React from 'react';
import ModalDefault from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import PermissionRoleFormEdit from 'labstep-web/components/PermissionRole/Form/Edit';
import { IPermissionRoleActionEditProps } from './types';

export const PermissionRoleActionEdit: React.FC<
  IPermissionRoleActionEditProps
> = ({ permissionRole }) => (
  <ModalDefault
    header="Edit Role"
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        onClick={toggleModal}
        type="option"
        icon="pencil"
        text="Edit"
      />
    )}
    content={({ toggleModal }) => (
      <PermissionRoleFormEdit
        permissionRole={permissionRole}
        options={{ onSuccess: toggleModal }}
      />
    )}
  />
);

export default PermissionRoleActionEdit;
