/**
 * Labstep
 *
 * @module core/Label
 * @desc Core Label Item - Wrapper around Semantic Label
 */

import Label from 'semantic-ui-react/dist/commonjs/elements/Label';

export default Label;
