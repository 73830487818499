/**
 * Labstep
 *
 * @module core/Card/Element
 * @desc Generic Card for Interactive Elements in Protocols / Experiments for use in the Overview side panel.
 */

import classnames from 'classnames';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import Card from '../Card';
import styles from './styles.module.scss';
import { IElementCardProps } from './types';

const ElementCard: React.FC<IElementCardProps> = ({
  name,
  icon,
  right,
  detail,
  actions,
  customIcon,
  onClick,
  isLink,
  basic = false,
}) =>
  basic ? (
    <div className={styles.elementCard} style={{ padding: 0 }}>
      <span className={styles.dragHandle} />
      <div>
        <div>{customIcon || (icon && <Icon name={icon} />)}</div>
        <Flex
          wrap
          grow
          vAlignContent="center"
          className={styles.elementCardDetail}
        >
          <div
            data-private
            className={classnames(styles.elementCardName, {
              [styles.clickable]: onClick,
              [styles.link]: isLink,
            })}
            onClick={onClick}
          >
            {name}
          </div>
          <>{detail}</>
        </Flex>
      </div>
      <div className={styles.elementCardRight}>
        {right}
        {actions}
      </div>
    </div>
  ) : (
    <Card className={styles.elementCard}>
      <span className={styles.dragHandle} />
      <div>
        <div>{customIcon || (icon && <Icon name={icon} />)}</div>
        <Flex
          wrap
          grow
          vAlignContent="center"
          className={styles.elementCardDetail}
        >
          <div
            data-private
            className={classnames(styles.elementCardName, {
              [styles.clickable]: onClick,
              [styles.link]: isLink,
            })}
            onClick={onClick}
          >
            {name}
          </div>
          <>{detail}</>
        </Flex>
      </div>
      <div className={styles.elementCardRight}>
        {right}
        {actions}
      </div>
    </Card>
  );

export default ElementCard;
