/**
 * Labstep
 *
 * @module screens/User/Deleted
 * @desc Screen showing deleted user message
 */

import LayoutFullScreenPage from 'labstep-web/components/Layout/FullScreenPage';
import { withAuthenticatedUser } from 'labstep-web/containers/AuthenticatedUser';
import ActionComponent from 'labstep-web/core/Action/Component';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { logoutHardAction } from 'labstep-web/state/actions/user';
import store from 'labstep-web/state/store';
import React from 'react';
import { IScreensUserDeletedProps } from './types';

export const ScreensUserDeleted: React.FC<
  IScreensUserDeletedProps
> = ({ authenticatedUser }) => {
  if (!authenticatedUser?.user_organizations[0]) {
    return (
      <LayoutFullScreenPage>
        <EmptyState
          title="Account Locked"
          explanation={
            <>
              Your account has been locked. Please contact your
              administrator or{' '}
              <a href="mailto:billing@labbstep.com">
                info@labstep.com
              </a>{' '}
              for assistance
            </>
          }
          src="img/error/locked.svg"
          action={
            <ActionComponent
              onClick={() => store.dispatch(logoutHardAction())}
              text="Sign Out"
              type="button"
              elementProps={{ color: 'red' }}
            />
          }
        />
      </LayoutFullScreenPage>
    );
  }
  return (
    <LayoutFullScreenPage>
      <EmptyState
        title="Account Disabled"
        explanation={
          <>
            This account has been disabled by the organization admin.
            Please contact your admin to reactivate your account.
          </>
        }
        src="img/error/locked.svg"
        action={
          <ActionComponent
            onClick={() => store.dispatch(logoutHardAction())}
            text="Sign Out"
            type="button"
            elementProps={{ color: 'red' }}
          />
        }
      />
    </LayoutFullScreenPage>
  );
};

export default withAuthenticatedUser(ScreensUserDeleted);
