/**
 * Labstep
 *
 * @module components/Layout/Alert
 * @desc Components for alerting user
 */

import React from 'react';
import OfflineContent from './OfflineContent';
import StagingContent from './StagingContent';
import LegacyDomain from './LegacyDomain';

const LayoutAlert: React.FC = () => (
  <div>
    <OfflineContent />
    <StagingContent />
    <LegacyDomain />
  </div>
);

export default LayoutAlert;
