/**
 * Labstep
 *
 * @module containers/Print
 * @desc Container to manage print progress
 */

import { selectPrintProgress } from 'labstep-web/state/new/print';
import { LabstepReduxState } from 'labstep-web/state/types';
import { connect } from 'react-redux';
import { WithPrintMapDispatchToProps } from './types';

const mapStateToProps = (
  state: LabstepReduxState,
): WithPrintMapDispatchToProps => ({
  progress: selectPrintProgress(state),
});

export const withPrint = <P extends WithPrintMapDispatchToProps, S>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<P, Exclude<keyof P, keyof WithPrintMapDispatchToProps>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> => connect(mapStateToProps)(Component as any);
