/**
 * Labstep
 *
 * @module components/Protocol/Form/ShowEdit/State/ToolbarRight
 * @desc ToolbarRight
 */

import ExperimentActionSaveAs from 'labstep-web/components/Experiment/Action/SaveAs';
import ProtocolActionSave from 'labstep-web/components/Protocol/Action/Save';
import { withProseMirror } from 'labstep-web/containers/ProseMirror';
import { ProseMirrorStatus } from 'labstep-web/containers/ProseMirror/types';
import ActionComponent from 'labstep-web/core/Action/Component';
import AutosaveSpinnerProseMirror from 'labstep-web/core/AutoSave/Spinner/ProseMirror';
import { Experiment } from 'labstep-web/models/experiment.model';
import React from 'react';
import { IProtocolFormShowEditStateToolbarRightProps } from './types';

export const ProtocolFormShowEditStateToolbarRight: React.FC<
  IProtocolFormShowEditStateToolbarRightProps
> = ({
  protocol,
  protocolCollection,
  experimentWorkflow,
  proseMirrorStatus,
  setViewMode,
}) => (
  <>
    <AutosaveSpinnerProseMirror />
    {protocolCollection && (
      <ProtocolActionSave
        protocolCollection={protocolCollection}
        actionComponentProps={{
          type: 'text',
          disabled:
            proseMirrorStatus === ProseMirrorStatus.debouncing,
          icon: 'save',
        }}
      />
    )}
    {!experimentWorkflow && protocol instanceof Experiment && (
      <ExperimentActionSaveAs
        experiment={protocol}
        disabled={proseMirrorStatus === ProseMirrorStatus.debouncing}
      />
    )}
    <ActionComponent
      type="text"
      text="Done"
      icon="check"
      disabled={proseMirrorStatus === ProseMirrorStatus.debouncing}
      onClick={setViewMode}
    />
  </>
);

export default withProseMirror(ProtocolFormShowEditStateToolbarRight);
