// eslint-disable-next-line max-classes-per-file
import { EditorState, Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

export const CommentActionTypeNew = 'newComment';
export const CommentActionTypeDelete = 'deleteComment';

export const commentPluginKey = new PluginKey('COMMENT_DECORATION');

export class Comment {
  id: any;

  text: string;

  constructor(text, id) {
    this.id = id;
    this.text = text;
  }
}

export const deco = (from, to, comment) => {
  return Decoration.inline(
    from,
    to,
    { class: 'inline-comment' },
    { comment },
  );
};

class CommentState {
  decos: DecorationSet;

  constructor(decos) {
    this.decos = decos;
  }

  findComment(id) {
    const current = this.decos.find();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < current.length; i++) {
      if (current[i].spec.comment.id === id) {
        return current[i];
      }
    }
    return false;
  }

  commentsAt(pos) {
    return this.decos.find(pos, pos);
  }

  apply(tr: EditorState['tr']) {
    const action = tr.getMeta(commentPluginKey);
    const actionType = action && action.type;
    if (!action && !tr.docChanged) {
      return this;
    }
    let { decos } = this;
    decos = decos.map(tr.mapping, tr.doc);
    if (actionType === CommentActionTypeNew) {
      decos = decos.add(tr.doc, [
        deco(action.from, action.to, action.comment),
      ]);
    } else if (actionType === CommentActionTypeDelete) {
      const comment = this.findComment(action.comment.id);
      if (comment) {
        decos = decos.remove([comment]);
      }
    }
    return new CommentState(decos);
  }

  static init(_, { doc }, initialDecorations) {
    const decos = initialDecorations.map((c) =>
      deco(c.from, c.to, new Comment(c.text, c.id)),
    );
    return new CommentState(DecorationSet.create(doc, decos));
  }
}

export const createCommentPlugin = (initialDecorations) =>
  new Plugin({
    key: commentPluginKey,
    state: {
      init: (config, instance) =>
        CommentState.init(config, instance, initialDecorations),
      apply(tr, value) {
        const newValue = value.apply(tr);
        return newValue;
      },
    },
    props: {
      decorations(state) {
        return this.getState(state).decos;
      },
    },
  });
