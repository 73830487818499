/**
 * Labstep
 *
 * @module components/PurchaseOrder/Action/AddOrderRequest
 * @desc Action to add order request for purchaseOrder
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import OrderRequestActionCreate from 'labstep-web/components/OrderRequest/Action/Create';
import OrderRequestSearchSelect from 'labstep-web/components/OrderRequest/SearchSelect';
import ActionComponent from 'labstep-web/core/Action/Component';
import Button from 'labstep-web/core/Button';
import Dropdown from 'labstep-web/core/Dropdown';
import Icon from 'labstep-web/core/Icon';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { dateOnly } from 'labstep-web/services/date.service';
import { updateEntity } from 'labstep-web/state/actions/entity';
import store from 'labstep-web/state/store';
import React from 'react';
import { IPurchaseOrderActionAddOrderRequestProps } from './types';

export const PurchaseOrderActionAddOrderRequest: React.FC<
  IPurchaseOrderActionAddOrderRequestProps
> = ({ purchaseOrder }) =>
  purchaseOrder.canAddOrRemoveOrderRequest ? (
    <Can
      entityName={purchaseOrder.entityName}
      id={purchaseOrder.id}
      action={Action.edit}
    >
      <Dropdown
        trigger={
          <Button icon primary basic>
            Add to Order | <Icon color="blue" name="chevron down" />
          </Button>
        }
        icon={null}
      >
        <OrderRequestActionCreate
          purchaseOrder={purchaseOrder}
          actionComponentProps={{
            type: 'option',
            text: 'New Order Request',
          }}
        />

        <ModalDefault
          header="Add Order Request"
          content={({ toggleModal }) => (
            <OrderRequestSearchSelect
              params={{
                has_purchase_order: false,
                is_template: false,
              }}
              getOptionLabel={(option: OrderRequest) =>
                `${option.resource.name} - ${
                  option.quantity
                } unit(s) - ${dateOnly(option.created_at)}`
              }
              onChange={(option) => {
                store.dispatch(
                  updateEntity(OrderRequest.entityName, option.id, {
                    purchase_order_id: purchaseOrder.id,
                  }),
                );
                toggleModal();
              }}
            />
          )}
          viewComponent={({ toggleModal }) => (
            <ActionComponent
              type="option"
              onClick={toggleModal}
              text="From Existing Order Requests"
            />
          )}
        />
      </Dropdown>
    </Can>
  ) : null;

export default PurchaseOrderActionAddOrderRequest;
