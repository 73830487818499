/**
 * Labstep
 *
 * @module screens/ProtocolValue/Show
 * @desc ProtocolValue show
 */

import ProtocolValueDetail from 'labstep-web/components/ProtocolValue/Detail';
import ProtocolValueFormShowEditName from 'labstep-web/components/ProtocolValue/Form/ShowEdit/Name';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import Flex from 'labstep-web/core/Flex';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React from 'react';
import {
  IProtocolValueShowNameProps,
  IProtocolValueShowProps,
} from './types';

export const ProtocolValueShowName: React.FC<
  IProtocolValueShowNameProps
> = ({ id }) => (
  <EntityReadEntityContainer
    entityName={ProtocolValue.entityName}
    id={id}
  >
    {({ entity }) =>
      entity ? (
        <ProtocolValueFormShowEditName protocolValue={entity} />
      ) : null
    }
  </EntityReadEntityContainer>
);

const ProtocolValueShowContainer: React.FC<
  IProtocolValueShowProps
> = ({ id }) => (
  <Flex
    id="protocol-value-show-screen"
    grow
    style={{ justifyContent: 'space-between', height: '100%' }}
    column
  >
    <ReadOnMountHOC
      type="entities"
      entityName={ProtocolValue.entityName}
      params={{
        get_single: 1,
        id,
      }}
      loading={{ loader: 'placeholder' }}
      children={({ entity: protocolValue }): React.ReactElement => (
        <ProtocolValueDetail protocolValue={protocolValue} />
      )}
    />
  </Flex>
);

export default ProtocolValueShowContainer;
