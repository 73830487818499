/**
 * Labstep
 */

import type { Column, ColumnState } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { AGGridService } from 'labstep-web/services/agGrid/ag-grid.service';
import { AGGridEntityImportService } from 'labstep-web/services/agGrid/ag-grid-entity-import.service';
import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useEffect,
} from 'react';

export interface DataGridContextType {
  agGrid: AgGridReact | undefined;
  setColumnState: React.Dispatch<React.SetStateAction<ColumnState[]>>;
  setColumns: React.Dispatch<React.SetStateAction<Column[] | null>>;
  columns: Column[] | null;
  columnState: ColumnState[];
  ref: React.MutableRefObject<AgGridReact | undefined>;
}

export const DataGridContext = createContext<DataGridContextType>({
  agGrid: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setColumnState: () => {
    // Do nothing
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setColumns: () => {
    // Do nothing
  },
  columns: [],
  columnState: [],
  ref: { current: undefined },
});

export const useDataGridContext = (): DataGridContextType => {
  const context = useContext(DataGridContext);
  if (!context) {
    throw new Error(
      'useDataGridContext must be used within a DataGridContextProvider',
    );
  }
  return context;
};

export const useAgGridServiceFromContext = ():
  | AGGridService
  | undefined => {
  const dataGridContext = useDataGridContext();

  const [agGridService, setAgGridService] = useState<AGGridService>();

  useEffect(() => {
    if (
      dataGridContext?.agGrid?.api &&
      dataGridContext?.agGrid?.columnApi
    ) {
      setAgGridService(new AGGridService(dataGridContext.agGrid));
    }
  }, [
    dataGridContext?.agGrid?.api,
    dataGridContext?.agGrid?.columnApi,
  ]);

  return agGridService;
};

export const useAgGridEntityImportServiceFromContext = ():
  | AGGridEntityImportService
  | undefined => {
  const dataGridContext = useDataGridContext();

  const [agGridService, setAgGridService] =
    useState<AGGridEntityImportService>();

  useEffect(() => {
    if (
      dataGridContext?.agGrid?.api &&
      dataGridContext?.agGrid?.columnApi
    ) {
      setAgGridService(
        new AGGridEntityImportService(dataGridContext.agGrid),
      );
    }
  }, [
    dataGridContext?.agGrid?.api,
    dataGridContext?.agGrid?.columnApi,
  ]);

  return agGridService;
};

export const DataGridContextProvider: React.FC = ({ children }) => {
  const ref = useRef<AgGridReact | undefined>();
  const [columns, setColumns] = React.useState<Column[] | null>([]);
  const [columnState, setColumnState] = React.useState<ColumnState[]>(
    [],
  );
  const value = React.useMemo(
    () => ({
      ref,
      agGrid: ref?.current,
      columns,
      setColumns,
      columnState,
      setColumnState,
    }),
    [ref, columns, setColumns, columnState, setColumnState],
  );
  return (
    <DataGridContext.Provider value={value} children={children} />
  );
};
