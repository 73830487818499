/**
 * Labstep
 *
 * @module hoc/Modal
 * @desc Modal container
 */

import React from 'react';
import { objectOrFunction } from 'labstep-web/services/react.service';
import { IProps, IState } from './types';

class ModalContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.renderViewComponent = this.renderViewComponent.bind(this);
    this.state = {
      show: props.initialModalState,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      show: !prevState.show,
    }));
  }

  /**
   * If viewComponent is an object then onClick would trigger the modal.
   * If it's a function then the toggleModal prop will
   * be passed and the component would have to manually trigger the modal
   */
  renderViewComponent(viewComponent, props) {
    if (typeof viewComponent === 'function') {
      return viewComponent(props);
    }

    return <div onClick={this.toggle}>{viewComponent}</div>;
  }

  render() {
    const { modalComponent, viewComponent } = this.props;
    const { show: showingModal } = this.state;
    const modalActionProps = {
      open: showingModal,
      toggleModal: this.toggle,
    };

    return (
      <>
        {this.renderViewComponent(viewComponent, modalActionProps)}
        {objectOrFunction(modalComponent, modalActionProps)}
      </>
    );
  }
}

export const ModalHOC = ModalContainer;
