/**
 * Labstep
 */

// In new file to avoid circular dependencies.
export const params = {
  count: 5,
  sort: '-is_home',
  is_group_deleted: 0,
};
