/**
 * Labstep
 */

import FilterAvailableResourceItemCountActivePost from 'labstep-web/components/Filter/AvailableResourceItemCount/Active/Post';
import FilterDateRangeActivePost from 'labstep-web/components/Filter/DateRange/Active/Post';
import FilterDeletedActivePost from 'labstep-web/components/Filter/Deleted/Active/Post';
import FilterMetadataActivePost from 'labstep-web/components/Filter/Metadata/Active/Post';
import FilterResourceItemStatusActivePost from 'labstep-web/components/Filter/ResourceItemStatus/Active/Post';
import TagFilterActivePost from 'labstep-web/components/Filter/Tag/Active/Post';
import FilterUserActivePost from 'labstep-web/components/Filter/User/Active/Post';
import FilterMoleculeActivePost from 'labstep-web/components/Molecule/Filter/Active/Post';
import FilterResourceLocationActivePost from 'labstep-web/components/ResourceLocation/Filter/Active/Post';
import React from 'react';
import { IFilterPostActiveLabelProps } from './types';

export const FilterPostActiveLabel: React.FC<
  IFilterPostActiveLabelProps
> = (props): React.ReactElement | null => (
  <>
    <FilterResourceLocationActivePost {...props} />
    <FilterUserActivePost {...props} />
    <FilterMetadataActivePost {...props} />
    <FilterResourceItemStatusActivePost {...props} />
    <FilterDeletedActivePost {...props} />
    <FilterMoleculeActivePost {...props} />
    <FilterAvailableResourceItemCountActivePost {...props} />
    <FilterDateRangeActivePost {...props} />
    <TagFilterActivePost {...props} />
  </>
);
