/**
 * Labstep
 */

export type Interval = 'day' | 'week' | 'month' | 'year';

export class PricingService {
  static readonly intervalDiscount: {
    [key in Interval]?: number;
  } = { year: 0.1 };

  static getPriceBeforeDiscount = (
    price: number,
    interval: Interval,
  ): number | null => {
    const savings = PricingService.intervalDiscount[interval];
    if (!savings) {
      return null;
    }
    return price / (1 - savings);
  };
}
