/**
 * Labstep
 *
 * @module prosemirror/components/Menu/ActionList
 * @desc List of actions when selecting a single text node
 */

import React from 'react';
import MenuTextActionLink from 'labstep-web/prosemirror/components/Menu/Text/Action/Link';
import MenuTextActionProtocolValue from 'labstep-web/prosemirror/components/Menu/Text/Action/ProtocolValue';
import MenuTextActionComment from 'labstep-web/prosemirror/components/Menu/Text/Action/Comment';
import { IMenuTextActionListProps } from './types';

export const MenuTextActionList: React.FC<
  IMenuTextActionListProps
> = ({ view, entity }) => (
  <>
    {view.editable && <MenuTextActionLink view={view} />}
    <MenuTextActionComment view={view} entity={entity} />
    {view.editable && (
      <MenuTextActionProtocolValue protocol={entity} view={view} />
    )}
  </>
);

export default MenuTextActionList;
