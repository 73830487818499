/**
 * Labstep
 *
 * @module components/GroupAutoSharing/Table
 * @desc Display a list of GroupAutoSharings as table
 */

import React from 'react';
import Table from 'labstep-web/core/Table';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import GroupAutoSharingActionDelete from 'labstep-web/components/GroupAutoSharing/Action/Delete';
import styles from './styles.module.scss';
import { GroupAutoSharingTableProps } from './types';

const GroupAutoSharingTable: React.FC<GroupAutoSharingTableProps> = ({
  title,
  groupAutoSharings,
  group,
}) => (
  <div>
    <br />
    <span>{title}</span>
    <div className={styles.tableContainer}>
      <Table striped unstackable>
        <Table.Body>
          <Table.Row key="header">
            <Table.Cell key="1" width={2}>
              Workspace
            </Table.Cell>
            <Table.Cell key="2" width={2}>
              Entity
            </Table.Cell>
            <Table.Cell key="3" width={2}>
              Permission
            </Table.Cell>
            <Table.Cell key="4" width={2} />
          </Table.Row>
          {groupAutoSharings.map((groupAutoSharing) => (
            <Table.Row key={groupAutoSharing.guid}>
              <Table.Cell key="1" width={2}>
                {group.guid === groupAutoSharing.source_group.guid
                  ? groupAutoSharing.target_group.name
                  : groupAutoSharing.source_group.name}
              </Table.Cell>
              <Table.Cell key="2" width={2}>
                {getHumanReadableEntityName(
                  groupAutoSharing.entity_type,
                  true,
                  true,
                )}
              </Table.Cell>
              <Table.Cell key="3" width={2} textAlign="left">
                {groupAutoSharing.permission_type === 'view'
                  ? 'Read Only'
                  : 'View & Edit'}
              </Table.Cell>
              <Table.Cell key="4" width={2} textAlign="left">
                {group.logged_user_user_group.type === 'owner' && (
                  <GroupAutoSharingActionDelete
                    groupAutoSharing={groupAutoSharing}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  </div>
);

export default GroupAutoSharingTable;
