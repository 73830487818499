/**
 * Labstep
 *
 * @module components/Organization/EmptyState/NoSeatsLeft
 * @desc Empty state component for when an organization has no seats left
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { OrganizationEmptyStateNoSeatsLeftProps } from './types';

export const OrganizationEmptyStateNoSeatsLeft: React.FC<
  OrganizationEmptyStateNoSeatsLeftProps
> = ({ organization }) => (
  <EmptyState
    src="/img/error/locked.svg"
    title="User Limit Reached"
    warning
    explanation={
      <>
        You are unable to join this Organization because it has
        reached its User Limit. Please contact{' '}
        <a href={`mailto:${organization.admin_email}`}>
          {organization.admin_email}
        </a>{' '}
        and ask them to increase the user limit.
      </>
    }
  />
);

export default OrganizationEmptyStateNoSeatsLeft;
