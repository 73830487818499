/**
 * Labstep
 *
 * @module services/http-client
 * @desc Wrapper around axios to perform HTTP requests
 */

import axios, {
  AxiosError,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from 'axios';
import bugsnagService from 'labstep-web/services/bugsnag.service';

const DEFAULT_TIMEOUT = 290000;

export const ECONNABORTED = 'ECONNABORTED';

export const RESPONSE_ERROR = 'response_error';

export const TIMEOUT_ERROR = 'timeout_error';

export const NETWORK_ERROR = 'network_error';

export interface IError extends Error {
  data: Record<string, unknown>;
  status: number;
}

export interface IErrorResponse extends IError {
  headers: Record<string, string>;
}

export interface IAxiosError extends IError {
  axiosError: string | object;
}

export const REQUEST_ERROR_MESSAGE =
  'The request was made but no response was received';

export class HttpClientService {
  /**
   * Handle success
   *
   * @function
   * @param  {AxiosResponse['data']} response - response
   */
  static handleSuccess = (
    response: AxiosResponse,
  ): AxiosResponse['data'] => response.data;

  /**
   * Handle error
   *
   * @function
   * @param  {response} response - response
   *
   * @see https://github.com/axios/axios#handling-errors
   */
  static handleError = (error: AxiosError): void => {
    if (error.response) {
      const { response } = error;
      const errorObject: IErrorResponse = {
        name: RESPONSE_ERROR,
        message: RESPONSE_ERROR,
        data: response.data,
        status: response.status,
        headers: response.headers,
      };
      throw errorObject;
    }

    bugsnagService.notify(error, null, 'info');

    if (error && error.code && error.code === ECONNABORTED) {
      const errorObject: IAxiosError = {
        name: TIMEOUT_ERROR,
        message: TIMEOUT_ERROR,
        data: {
          message: error.message,
        },
        status: 500,
        axiosError: error.toJSON
          ? error.toJSON()
          : JSON.stringify(error),
      };
      throw errorObject;
    }

    const errorObject: IAxiosError = {
      name: NETWORK_ERROR,
      message: NETWORK_ERROR,
      data: {
        message: REQUEST_ERROR_MESSAGE,
      },
      status: 500,
      axiosError: error.toJSON
        ? error.toJSON()
        : JSON.stringify(error),
    };
    throw errorObject;
  };

  /**
   * Send a HTTP request with axios
   *
   * @function
   * @param  {Method}   method
   * @param  {string}   url
   * @param  {Record<string, string>}   headers
   * @param  {Record<string, unknown>}   data
   * @param  {Function} onUploadProgress
   */
  static send = (
    method: Method,
    url: string,
    headers?: Record<string, string>,
    data?: Record<string, unknown>,
    onUploadProgress?: (...args: Record<string, unknown>[]) => void,
  ): AxiosPromise => {
    const config: AxiosRequestConfig = {
      method,
      url,
      headers,
      onUploadProgress,
      timeout: onUploadProgress ? 0 : DEFAULT_TIMEOUT,
    };
    if (method.toLowerCase() !== 'get') {
      config.data = data;
    }

    return axios(config)
      .then(HttpClientService.handleSuccess)
      .catch(HttpClientService.handleError);
  };
}

export default HttpClientService;
