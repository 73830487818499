/**
 * Labstep
 *
 * @module prosemirror/marks/sub
 * @desc Sub mark
 */

import { toggleMark } from 'prosemirror-commands';
import { LabstepMark } from 'labstep-web/prosemirror/marks/types';

const MarkSub: LabstepMark = {
  key: 'sub',
  spec: {
    excludes: 'sup',
    toDOM() {
      return ['sub', 0];
    },
    parseDOM: [
      { tag: 'sub' },
      {
        style: 'vertical-align',
        getAttrs: (value) => {
          return value === 'sub' && null;
        },
      },
    ],
  },
  keymap: {
    'Mod-,': (state, dispatch) =>
      toggleMark(state.schema.marks[MarkSub.key], {})(
        state,
        dispatch,
      ),
  },
  toolbarIcon: 'subscript',
};

export default MarkSub;
