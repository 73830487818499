/**
 * Labstep
 *
 * @module components/Organization/Logo
 * @desc Organization Logo component
 */

import React from 'react';
import Image from 'labstep-web/core/Image';
import {
  IOrganizationLogoDefaultProps,
  IOrganizationLogoProps,
} from './types';

export const OrganizationLogoDefault: React.FC<
  IOrganizationLogoDefaultProps
> = ({ size = 'medium' }) => (
  <Image src="/img/organization/default-logo.png" size={size} />
);

const OrganizationLogo: React.FC<IOrganizationLogoProps> = ({
  organization,
  size = 'medium',
}) => (
  <Image
    src={
      organization.logo_url || '/img/organization/default-logo.png'
    }
    size={size}
  />
);

export default OrganizationLogo;
