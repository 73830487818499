/**
 * Labstep
 */

import colDefEntityUser from 'labstep-web/components/Entity/DataGrid/colDefs/entityUser';
import { colDefResourceItemAmount } from 'labstep-web/components/ResourceItem/DataGrid/colDefs/amount';
import { getResourceItemMetadataColDefs } from 'labstep-web/components/ResourceItem/DataGrid/colDefs/metadata';
import { getResourceItemResourceMetadataColDefs } from 'labstep-web/components/ResourceItem/DataGrid/colDefs/metadata/resource';
import { colDefResourceItemNameNoLink } from 'labstep-web/components/ResourceItem/DataGrid/colDefs/name/noLink';
import { colDefResourceItemResourceNoLink } from 'labstep-web/components/ResourceItem/DataGrid/colDefs/resource/noLink';
import colDefResourceLocation from 'labstep-web/components/ResourceItem/DataGrid/colDefs/resourceLocation';
import colDefResourceItemStatus from 'labstep-web/components/ResourceItem/DataGrid/colDefs/status';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';

export default (
  resource?: Resource | null,
  resourceTemplate?: Resource | null,
): (ColDef<ResourceItem> | ColDef<Resource>)[] => {
  let itemMetadataColDefs: (
    | ColDef<ResourceItem>
    | ColDef<Resource>
  )[] = [];

  let resourceMetadataColDefs: (
    | ColDef<ResourceItem>
    | ColDef<Resource>
  )[] = [];

  if (resource?.resource_item_template) {
    itemMetadataColDefs = getResourceItemMetadataColDefs(resource);
  } else if (resourceTemplate?.resource_item_template) {
    itemMetadataColDefs =
      getResourceItemMetadataColDefs(resourceTemplate);
  }

  if (resourceTemplate && !resource) {
    resourceMetadataColDefs =
      getResourceItemResourceMetadataColDefs(resourceTemplate);
  }

  const colDefs = [
    colDefResourceItemNameNoLink,
    colDefResourceLocation,
    colDefResourceItemAmount,
    ...(resource ? [] : [colDefResourceItemResourceNoLink]),
    ...itemMetadataColDefs,
    ...resourceMetadataColDefs,
    colDefEntityUser,
    colDefResourceItemStatus,
  ];

  return colDefs;
};
