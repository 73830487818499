/**
 * Labstep
 *
 * @module screens/PurchaseOrder/Index/LeftPane
 * @desc PurchaseOrder Index LeftPane
 */

import PurchaseOrderFilterPane from 'labstep-web/components/PurchaseOrder/Filter/Pane';
import LayoutLeftPane from 'labstep-web/components/Layout/LeftPane';
import React from 'react';
import { IPurchaseOrderIndexLeftPaneProps } from './types';

export const PurchaseOrderIndexLeftPane: React.FC<
  IPurchaseOrderIndexLeftPaneProps
> = ({ links }) => (
  <LayoutLeftPane links={links}>
    <PurchaseOrderFilterPane />
  </LayoutLeftPane>
);

export default PurchaseOrderIndexLeftPane;
