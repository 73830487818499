/**
 * Labstep
 *
 * @module components/Folder/Action/Menu
 * @desc Action Menu for folder
 */

import React from 'react';
import ActionMenu from 'labstep-web/core/Action/Menu';
import FolderActionDelete from 'labstep-web/components/Folder/Action/Delete';
import FolderActionEdit from 'labstep-web/components/Folder/Action/Edit';
import FolderActionCreateSub from 'labstep-web/components/Folder/Action/CreateSub';
import FolderActionUpdateOuter from 'labstep-web/components/Folder/Action/UpdateOuter';
import { Action, IFolderActionMenuProps } from './types';

export const FolderActionMenu: React.FC<IFolderActionMenuProps> = ({
  folder,
  folderType,
  type,
  actions = [],
  options,
}) => (
  <ActionMenu type={type}>
    {actions.includes(Action.edit) && (
      <FolderActionEdit
        actionComponentProps={{ type: 'option' }}
        folder={folder}
      />
    )}
    {actions.includes(Action.delete) && (
      <FolderActionDelete folder={folder} options={options} />
    )}
    {actions.includes(Action.add_subfolders) && (
      <FolderActionCreateSub
        folder={folder}
        folderType={folderType}
      />
    )}
    {actions.includes(Action.update_outer) && (
      <FolderActionUpdateOuter
        folder={folder}
        folderType={folderType}
      />
    )}
  </ActionMenu>
);

FolderActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default FolderActionMenu;
