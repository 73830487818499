/**
 * Labstep
 *
 * @module components/Signature/List
 * @desc Signature list
 */

import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import NumberCircle from 'labstep-web/core/Number/Circle';
import React from 'react';
import { SemanticICONS } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { ISignatureHeaderProps } from './types';

const SignatureHeader: React.FC<ISignatureHeaderProps> = ({
  name,
  icon,
}) => {
  return (
    <Flex
      spacing="gap"
      vAlignContent="center"
      hAlignContent="left"
      data-testid="signature-header"
    >
      <Icon
        name={icon as SemanticICONS}
        size="large"
        className={styles.icon}
      />
      <span>{name}</span>
    </Flex>
  );
};

export default SignatureHeader;
