/**
 * Labstep
 *
 * @module components/Chemical/Form/ShowEdit/IsLimiting
 * @desc ShowEditIsLimiting for Chemical
 */

import React from 'react';
import EntityActionUpdateCheckbox from 'labstep-web/components/Entity/Action/Update/Checkbox';
import { IChemicalFormShowEditIsLimitingProps } from './types';

export const ChemicalFormShowEditIsLimiting: React.FC<
  IChemicalFormShowEditIsLimitingProps
> = ({ chemical }) => (
  <EntityActionUpdateCheckbox
    entity={chemical.molecule}
    isChecked={chemical.is_limiting}
    field="limiting_chemical_guid"
    fieldValue={chemical.is_limiting ? undefined : chemical.guid}
    optimistic
    radio
  />
);

export default ChemicalFormShowEditIsLimiting;
