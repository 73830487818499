/**
 * Labstep
 *
 * @module
 * @desc Header for sharelink for Signin Screen
 */

import Header from 'labstep-web/core/Header';
import SubHeader from 'labstep-web/core/SubHeader';
import React from 'react';
import { IShareLinkHeaderProps } from './types';

export const ShareLinkHeader: React.FC<IShareLinkHeaderProps> = ({
  shareLink,
  sign,
}) => {
  return (
    <div>
      <SubHeader>{`You have been invited to ${
        shareLink.organization || shareLink.group ? 'join' : 'view'
      }...`}</SubHeader>
      <Header>{shareLink.entity.displayName}</Header>
      <SubHeader>
        {`by ${shareLink.entity.author.name}`}
        {sign && `, sign ${sign} to view this content.`}
      </SubHeader>
      <br />
    </div>
  );
};

export default ShareLinkHeader;
