/**
 * Labstep
 *
 * @module components/ResourceItem/Search/StockCheck
 * @desc Resource Item Search for stock check
 */

import React from 'react';
import EntitySearch from 'labstep-web/components/Entity/Search';
import ResourceLocationListStockCheck from 'labstep-web/components/ResourceLocation/List/StockCheck';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { IResourceItemSearchStockCheckProps } from './types';

export const ResourceItemSearchStockCheck: React.FC<
  IResourceItemSearchStockCheckProps
> = ({ resourceLocation }) => {
  return (
    <EntitySearch
      entityName={ResourceItem.entityName}
      params={{ resource_location_guid: resourceLocation.guid }}
      additionalFilters={[
        (resourceItem: ResourceItem) =>
          resourceItem.stockCheckDue &&
          resourceItem.status === 'available',
      ]}
      noResultsMessage={
        <EmptyState
          src="/img/empty-state/inventory.svg"
          title="All done!"
          explanation="You have checked all the items listed in this location. If there are more items here, keep scanning to update their location."
        />
      }
    >
      {({ entities }) => (
        <ResourceLocationListStockCheck resourceItems={entities} />
      )}
    </EntitySearch>
  );
};

export default ResourceItemSearchStockCheck;
