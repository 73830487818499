/**
 * Labstep
 *
 * @module components/Experiment/Form/ShowEdit/Dates
 * @desc Showing started_at and ended_at show edits
 */

import React from 'react';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import ExperimentFormShowEditStartedAt from 'labstep-web/components/Experiment/Form/ShowEdit/StartedAt';
import ExperimentFormShowEditEndedAt from 'labstep-web/components/Experiment/Form/ShowEdit/EndedAt';
import { IExperimentFormShowEditDatesProps } from './types';

const ExperimentFormShowEditDates: React.FC<
  IExperimentFormShowEditDatesProps
> = ({ experiment }) => (
  <Flex style={{ alignItems: 'baseline' }}>
    <Icon name="calendar alternate outline" />
    <ExperimentFormShowEditStartedAt experiment={experiment} />
    <span>-</span>
    <ExperimentFormShowEditEndedAt experiment={experiment} />
  </Flex>
);

export default ExperimentFormShowEditDates;
