/**
 * Labstep
 *
 * @module components/ShareLinkInvitation/Modal
 * @desc A modal showing ShareLinkInvitations
 */

import React from 'react';
import ShareLinkInvitationList from 'labstep-web/components/ShareLinkInvitation/List';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { IShareLinkInvitationModalProps } from './types';

const ShareLinkInvitationModal: React.FC<
  IShareLinkInvitationModalProps
> = ({ entity }) => (
  <ModalDefault
    header="Pending invites"
    content={() => <ShareLinkInvitationList entity={entity} />}
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        type="text"
        text="View pending invites"
        onClick={toggleModal}
      />
    )}
    showDone
  />
);

export default ShareLinkInvitationModal;
