/**
 * Labstep
 */

import { IFilterPostActiveLabelProps } from 'labstep-web/components/Filter/Post/Active/Label/types';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { PostFilterStatus } from 'labstep-web/services/postFilter/filters/status';
import React from 'react';
import { getNameAndIcon } from '../utils';

export const FilterResourceItemStatusActivePost: React.FC<
  IFilterPostActiveLabelProps
> = ({ onRemoveClick, node }) => {
  const { status: statusAttribute } =
    PostFilterStatus.getAttributeValues(node);
  const status = statusAttribute?.value;
  if (!status) {
    return null;
  }
  return (
    <RemoveLabel
      {...getNameAndIcon(status)}
      onRemoveClick={onRemoveClick}
    />
  );
};

export default FilterResourceItemStatusActivePost;
