/**
 * Labstep
 *
 * @desc A service to deal with amount and units
 */

import { getBase, UNITS } from 'labstep-web/constants/unit';
import * as math from 'mathjs';

const SCALE = 5;

const FORMAT_OPTIONS = {
  notation: 'fixed',
} as math.FormatOptions;

const SCALING_OPTIONS = {
  ...FORMAT_OPTIONS,
  precision: SCALE,
} as math.FormatOptions;

interface IAmountUnit {
  amount: string;
  unit: string | null;
}

/**
 * Get unit modifier if exists
 */
export const getUnitModifier = (unit: string | null) =>
  UNITS[unit.toLowerCase()]?.modifier || 0;

/**
 * Compute amount relative to base
 *
 * @param string amount
 * @param string unit
 *
 * @return number
 */
export const amountRelBase = (
  amount: string,
  unit: string | null,
): number => {
  const modifier = getUnitModifier(unit);
  return math.evaluate(`${amount}*(10^${modifier})`);
};

export const canDeductAmount = (
  toBeDeducted: IAmountUnit,
  from: IAmountUnit,
): boolean => {
  if (toBeDeducted.unit === null) {
    return (
      Number(math.compare(toBeDeducted.amount, from.amount)) <= 0
    );
  }
  return (
    Number(
      math.compare(
        amountRelBase(toBeDeducted.amount, toBeDeducted.unit),
        amountRelBase(from.amount, from.unit),
      ),
    ) <= 0
  );
};

export const checkUnitBase = (
  protocolValueUnit: string,
  resourceItemUnit: string,
) => {
  const protocolValueBase = getBase(protocolValueUnit);
  const resourceItemBase = getBase(resourceItemUnit);

  return protocolValueBase === resourceItemBase;
};

/**
 * Round to scale (mimics brick math)
 */
export const scaleAmount = (amount: any) => {
  if (!amount) {
    return amount;
  }

  return math.bignumber(math.format(amount, SCALING_OPTIONS));
};

/**
 * Format amount to string
 * @param amount Amount to be converted
 * @param withScale If true, use scale for rounding
 * @returns Formatted amount string
 */
export const formatAmount = (amount: any, withScale?: boolean) => {
  if (!amount) {
    return null;
  }

  let stringValue = math.format(
    amount,
    withScale ? SCALING_OPTIONS : FORMAT_OPTIONS,
  );
  if (stringValue.includes('.')) {
    stringValue = stringValue.replace(/0+$/, '');
    if (stringValue.slice(-1) === '.') {
      stringValue = stringValue.slice(0, -1);
    }
  }
  return stringValue;
};

/**
 * Modify amount to adjust to units.
 */
export const modifyAmount = (
  amount: string | number | math.BigNumber,
  from: string,
  to: string,
): string | null => {
  const power = getUnitModifier(from) - getUnitModifier(to);
  const scope = {
    amount,
    power,
  };
  return formatAmount(math.evaluate(`amount*(10^power)`, scope));
};

/** Combine amount and unit into string */
export const getAmountUnitText = (
  amount: string | null | undefined,
  unit: string | null | undefined,
  noUnitOnly?: boolean,
): string | null => {
  if (!(amount || unit)) {
    return null;
  }
  if (amount && unit) {
    return `${amount} ${unit}`;
  }
  if (amount) {
    return amount;
  }
  if (unit && !noUnitOnly) {
    return unit;
  }
  return null;
};

/** Convert amount with unit to amount
 * If not valid amount, return false
 */
export const convertAmountUnitToAmount = (
  amountUnit: string | number,
): string | false => {
  const result = String(amountUnit)
    .replace(/[^0-9.]/g, '')
    .trim();
  return result.length ? result : false;
};

export const convertAmountUnitToUnit = (
  amountUnit: string | number,
): string | false => {
  const result = String(amountUnit)
    .replace(/[0-9.,]/g, '')
    .trim();
  return result.length ? result : false;
};
