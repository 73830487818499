/**
 * Labstep
 *
 * @module containers/Ui/Print
 * @desc A container to store print data from websocket
 */

import { selectPrintLink } from 'labstep-web/state/selectors/ui';
import { LabstepReduxState } from 'labstep-web/state/types';
import { connect } from 'react-redux';

export type ChildrenProps = {
  link: string;
};

const Container = ({
  link,
  children,
}: {
  link: string;
  children: (props: ChildrenProps) => React.ReactElement | null;
}) => {
  return children({ link });
};

const mapStateToProps = (
  state: LabstepReduxState,
  ownProps: {
    id: number;
  },
) => ({
  link: selectPrintLink(state, ownProps.id),
});

export const PrintContainer = connect(mapStateToProps)(Container);
