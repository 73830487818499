/**
 * Labstep
 *
 * @module core/Loader
 * @desc Wrapper around semantic-ui-react Loader
 */

import React from 'react';
import SemanticLoader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import { StrictLoaderProps } from './types';

const Loader: React.FC<StrictLoaderProps> = (props) => (
  <SemanticLoader {...props} />
);

export default Loader;
