/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Finish/Bulk
 * @desc Action to update is_urgent field
 */

import React from 'react';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { generateNewDateString } from 'labstep-web/services/date.service';
import { IExperimentWorkflowActionFinishBulkProps } from './types';

const ExperimentWorkflowActionFinishBulk: React.FC<
  IExperimentWorkflowActionFinishBulkProps
> = ({ entities, options, icon = 'check circle' }) => {
  const entityIdsAccessObject = useHasAccessMultiple(
    ExperimentWorkflow.entityName,
    entities ? entities.map((e) => e.id) : [],
    Action.edit,
  );

  const entitiesWithPermission = entities
    ? entities.filter((e) => entityIdsAccessObject[e.id])
    : [];

  if (
    !entities ||
    entities.length === 0 ||
    entitiesWithPermission.length === 0
  ) {
    return null;
  }

  return (
    <EntityActionUpdate
      id={entitiesWithPermission.map((e) => e.id)}
      entityName={ExperimentWorkflow.entityName}
      body={{ ended_at: generateNewDateString() }}
      options={options}
      actionComponentProps={{
        type: 'children',
        children: ({ onClick, status }) => (
          <BulkActionComponent
            disabled={status && status.isFetching}
            entitiesSelectedCount={entities.length}
            entitiesWithPermissionCount={
              entitiesWithPermission.length
            }
            onClick={onClick}
            content="Mark as complete"
            icon={icon}
          />
        ),
      }}
    />
  );
};

export default ExperimentWorkflowActionFinishBulk;
