/**
 * Labstep
 *
 * @module containers/ErrorBoundary
 * @desc Container for catching error
 * Provides an error boundary wrapper for components by checking the new
 * lifecyle method componentDidCatch()
 */

import React, { ErrorInfo } from 'react';
import { intercomService } from 'labstep-web/services/intercom.service';
import { objectOrFunction } from 'labstep-web/services/react.service';
import bugsnagService from 'labstep-web/services/bugsnag.service';
import {
  ICustomErrorBoundaryProps,
  IErrorBoundaryContainerProps,
} from './types';

intercomService.init();

class CustomErrorBoundary extends React.Component<
  ICustomErrorBoundaryProps,
  { hasError: boolean }
> {
  public constructor(props: ICustomErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(): {
    hasError: boolean;
  } {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, info: ErrorInfo): this {
    // eslint-disable-next-line no-console
    console.log('error=', error);
    // eslint-disable-next-line no-console
    console.log('info=', info);
    bugsnagService.notify(error, { error, info });
    return this;
  }

  public render(): React.ReactNode {
    const { children, FallbackComponent } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return objectOrFunction(FallbackComponent, {});
    }
    return children;
  }
}

const bugsnagReactPlugin = bugsnagService.getReactPlugin();
const errorBoundary = (props: ICustomErrorBoundaryProps) => (
  <CustomErrorBoundary {...props} />
);
const BugsnagErrorBoundary =
  bugsnagReactPlugin?.createErrorBoundary(React) || errorBoundary;

// FIXME Use Alert component
const DefaultFallbackComponent = (
  <div>Something went wrong. Please refresh your page...</div>
);

export const ErrorBoundaryContainer: React.FC<
  IErrorBoundaryContainerProps
> = ({ children, FallbackComponent }) => (
  <BugsnagErrorBoundary
    FallbackComponent={(): React.ReactElement =>
      FallbackComponent || DefaultFallbackComponent
    }
  >
    {children}
  </BugsnagErrorBoundary>
);
