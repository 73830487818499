/**
 * Labstep
 *
 * @module components/OrderRequest/Filter/Search/Active
 * @desc Active filter component for search query
 */

import React from 'react';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { IFilterSearchActiveProps } from './types';

const FilterSearchActive: React.FC<IFilterSearchActiveProps> = ({
  clearParams,
  searchParams,
}) => {
  return (
    'search_query' in searchParams && (
      <RemoveLabel
        name={searchParams.search_query}
        onRemoveClick={
          clearParams && (() => clearParams(['search_query']))
        }
      />
    )
  );
};

export default FilterSearchActive;
