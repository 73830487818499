/**
 * Labstep
 *
 * @module state/new/print
 * @desc Selectors/Reducers for Print
 */

import { combineReducers } from 'redux';
import { Action } from 'labstep-web/models/action.model';
import { createSelector } from 'reselect';
import { LabstepReduxState } from '../types';

export const selectPrintProgress = createSelector(
  (state: LabstepReduxState) => state.print.progress,
  (progress: number) => progress,
);

/**
 * Print progress (every 30 seconds elasped since user asked for a print).
 */
export const progress = (state = 0, action: Action): number => {
  if (
    action.type === 'WEBSOCKET_MESSAGE' &&
    action.payload &&
    action.payload.type === 'print'
  ) {
    return -1;
  }
  if (action.type === 'SUCCESS_CREATE_ENTITY_EXPORT_RAW_OUTPUT') {
    return 0;
  }
  if (
    action.type === 'UPDATE_PRINT_PROGRESS' &&
    action.meta &&
    action.meta.progress
  ) {
    return action.meta.progress;
  }

  return state;
};

export const printReducers = combineReducers({
  progress,
});
