/**
 * Labstep
 *
 * @module constants/email
 * @desc Constants for email
 */

const supportEmail = 'mailto:hello@labstep.com';

export { supportEmail };
