/**
 * Labstep
 *
 * @module components/ShareLinkInvitation/Action/Create/Organization/Disabled
 * @desc A modal to show when an organization has SAML enabled or no seats left
 */

import React from 'react';
import ModalConfirmation from 'labstep-web/core/Modal/Confirmation';
import { navigation } from 'labstep-web/services/navigation';
import { SharelinkInvitationActionCreateOrganizationViewComponent } from '..';
import { ISharelinkInvitationActionCreateOrganizationDisabledProps } from './types';

export const SharelinkInvitationActionCreateOrganizationDisabled: React.FC<
  ISharelinkInvitationActionCreateOrganizationDisabledProps
> = ({ organization }) => (
  <ModalConfirmation
    header={
      organization.noSeatsLeft
        ? 'User Limit Reached'
        : 'SSO Login Enabled'
    }
    children={
      SharelinkInvitationActionCreateOrganizationViewComponent
    }
    message={
      organization.noSeatsLeft ? (
        <p>
          You cannot invite more users because you will exceed the
          user limit for your plan. Please contact{' '}
          <a href="mailto:info@labstep.com">info@labstep.com</a> to
          add more.
        </p>
      ) : (
        <p>
          You have enabled SSO login. Users of your organization can
          sign up{' '}
          <a
            href={navigation.get(
              'login_saml',
              {
                organizationIdentifier: organization.identifier,
              },
              true,
            )}
          >
            here
          </a>
        </p>
      )
    }
    positive={!organization.noSeatsLeft}
    cancelButtonContent="Okay"
    confirmButtonContent={false}
  />
);

export default SharelinkInvitationActionCreateOrganizationDisabled;
