/**
 * Labstep
 *
 * @module components/Experiment/Form/ShowEdit/EndedAt
 * @desc Showing experiment.ended_at + allow for update
 */

import React from 'react';
import FormShowEditDate from 'labstep-web/core/Form/ShowEdit/Date';
import { IExperimentFormShowEditEndedAtProps } from './types';

const ExperimentFormShowEditEndedAt: React.FC<
  IExperimentFormShowEditEndedAtProps
> = ({ experiment }) => {
  return (
    <FormShowEditDate
      entity={experiment}
      field="ended_at"
      label="completion"
      dateTimePickerOptions={{ minDate: experiment.started_at }}
    />
  );
};

export default ExperimentFormShowEditEndedAt;
