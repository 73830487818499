/**
 * Labstep
 *
 * @desc ShowEdit Form for entity template field
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import Button from 'labstep-web/core/Button';
import FormShowEditSearchSelect from 'labstep-web/core/Form/ShowEdit/SearchSelect';
import { ParentEntityType } from 'labstep-web/core/Form/ShowEdit/SearchSelect/types';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import styles from './styles.module.scss';
import { IEntityFormTemplateProps } from './types';

export const EntityFormTemplate = <
  TParentEntity extends ParentEntityType,
>({
  entity,
  activeGroup,
  ...rest
}: IEntityFormTemplateProps<TParentEntity>) => (
  <div>
    <div className={styles.container}>
      <FormShowEditSearchSelect
        customPlaceholder={({ toggle }): React.ReactElement => (
          <Button primary basic onClick={toggle}>
            {`Select ${getHumanReadableEntityName(
              entity.entityName,
              false,
              true,
              true,
            )}`}
          </Button>
        )}
        children={
          entity.template && <EntityLink entity={entity.template} />
        }
        childEntityName={entity.entityName}
        {...rest}
        childKeyName="template"
        parentEntity={entity}
        searchSelectProps={{
          params: { group_id: activeGroup?.id, is_template: true },
          creatable: true,
          createBody: { is_template: true },
        }}
        optimistic={false} // entity_template metadatas can be null
      />
    </div>
  </div>
);

export default withActiveGroup(EntityFormTemplate);
