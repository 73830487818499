/**
 * Labstep
 *
 * @module screens/ResourceTemplate/LayoutLinks
 * @desc ResourceTemplate Layout Links
 */

import GroupFormShowEditResourceCustomIdentifierSet from 'labstep-web/components/Group/Form/ShowEdit/CustomShowIdentifierSet/ResourceTemplate';
import Container from 'labstep-web/components/Layout/Container';
import LayoutLinks from 'labstep-web/components/Layout/Links';
import LayoutLinksState from 'labstep-web/components/Layout/Links/State';
import { Tab } from 'labstep-web/components/Layout/Links/State/types';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import ResourceFormShowEditAlertThreshold from 'labstep-web/components/Resource/Form/ShowEdit/AlertThreshold';
import ResourceItemManagerTemplate from 'labstep-web/components/ResourceItem/Manager/Template';
import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import { Metadata } from 'labstep-web/models/metadata';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import { IResourceTemplateLayoutLinksProps } from './types';

const ResourceTemplateLayoutLinks: React.FC<
  IResourceTemplateLayoutLinksProps
> = ({ resourceTemplate, withRoute = true }) => {
  const hasCustomIdentifierFeatureEnabled = useHasFeatureFlagEnabled(
    'custom_identifier',
  );
  const rows = [
    {
      header: 'Alert Threshold:',
      content: () => (
        <ResourceFormShowEditAlertThreshold
          resource={resourceTemplate}
          label=""
        />
      ),
    },
  ];

  if (hasCustomIdentifierFeatureEnabled) {
    rows.unshift({
      header: 'Custom Identifier:',
      content: () => (
        <GroupFormShowEditResourceCustomIdentifierSet
          group={resourceTemplate.owner}
          resource={resourceTemplate}
        />
      ),
    });
  }

  const links = [
    {
      children: `Resource Template`,
      route: {
        to: 'resource_template_show',
        params: { id: resourceTemplate.id },
      } as const,
      render: () => (
        <div className="resource-template-metadata">
          <Container>
            <HorizontalTable
              borderless
              headerProps={{ width: 3 }}
              rows={rows}
              columns={[resourceTemplate]}
            />
          </Container>
          <MetadataManager
            isTemplate
            entity={resourceTemplate}
            emptyStateMessage={`${Resource.getHumanReadableEntityName(
              true,
              true,
              true,
            )} are customisable ${Metadata.getHumanReadableEntityName()} templates to apply to ${Resource.getHumanReadableEntityName(
              true,
            )}. Build a set of custom fields to easily upload and manage batches of inventory. Set defaults for fields with common values.`}
          />
        </div>
      ),
    },
    {
      children: 'Item Template',
      route: {
        to: 'resource_template_show_resource_item_template',
        params: { id: resourceTemplate.id },
      } as const,
      render: () => (
        <ResourceItemManagerTemplate resource={resourceTemplate} />
      ),
    },
  ];

  if (!withRoute) {
    const tabs: Tab[] = links.map((link) => ({
      key: link.children,
      render: link.render,
    }));
    return <LayoutLinksState tabs={tabs} inverted />;
  }

  return <LayoutLinks links={links} inverted />;
};

export default ResourceTemplateLayoutLinks;
