/**
 * Labstep
 *
 * @module components/AccessKey/Manager
 * @desc AccessKey manager
 */

import React from 'react';
import Container from 'labstep-web/components/Layout/Container';
import AccessKeyActionCreate from 'labstep-web/components/AccessKey/Action/Create';
import AccessKeyTable from 'labstep-web/components/AccessKey/Table';

const AcessKeyManager: React.FC = () => (
  <div id="access-key-card">
    <Container>
      <AccessKeyActionCreate />
    </Container>
    <Container>
      <AccessKeyTable />
    </Container>
  </div>
);

export default AcessKeyManager;
