/**
 * Labstep
 *
 * @module components/User/Action/AddGroup
 * @desc Action to add user to a group (join a group)
 */

import GroupInitials from 'labstep-web/components/Group/Initials';
import UserGroupActionCreate from 'labstep-web/components/UserGroup/Action/Create';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import Button from 'labstep-web/core/Button';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import { IUserActionAddGroupProps } from './types';

export const UserActionAddGroup: React.FC<
  IUserActionAddGroupProps
> = ({ authenticatedUserEntity, group, viewComponent }) => {
  const groupPreview = (
    <Flex shrink={0} column>
      <Flex>
        <div style={{ marginRight: 10 }}>
          <GroupInitials group={group} />
        </div>
        <Header>{group.name}</Header>
      </Flex>
      <br />
      <div>
        <p>{group.description}</p>
      </div>
    </Flex>
  );
  return !authenticatedUserEntity.isOrganizationAdmin &&
    group.is_invite_only ? (
    <ModalDefault
      header="Workspace Info"
      viewComponent={viewComponent}
      content={
        <div>
          {groupPreview}
          <br />
          <div>
            This is an invite only workspace. Contact the owner to
            join.
          </div>
        </div>
      }
    />
  ) : (
    <UserGroupActionCreate
      group={group}
      redirectRoute={() => ({
        to: 'group_show',
        params: { id: group.id },
      })}
      actionComponentProps={{
        type: 'children',
        children: ({ onClick: create, status }) => (
          <ModalDefault
            header="Workspace Info"
            viewComponent={viewComponent}
            content={({ toggleModal }) => (
              <div>
                {groupPreview}
                <br />
                <Flex hAlignContent="right">
                  <Button
                    primary
                    onClick={() => create({ onSuccess: toggleModal })}
                    status={status}
                  >
                    Join Workspace
                  </Button>
                </Flex>
              </div>
            )}
          />
        ),
      }}
    />
  );
};

export default withAuthenticatedUserEntity(UserActionAddGroup);
