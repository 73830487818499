import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import { DefaultSort } from 'labstep-web/components/Entity/Table/SortOptions/types';
import { Filter } from 'labstep-web/components/Filter/Menu/types';

export const filters = [
  Filter.created_by,
  Filter.assigned_to,
  Filter.date_range,
  Filter.deleted,
  Filter.resource_item_status,
  Filter.resource_location,
  Filter.metadata,
  Filter.resource_metadata,
];

export const bulkActions = [
  BulkAction.delete,
  BulkAction.resource_item_update_status,
  BulkAction.resource_item_update_resource_location,
  BulkAction.resource_item_update_amount,
];

export const sortOptions = {
  name: { label: 'Name', default_sort: DefaultSort.asc },
};
