/**
 * Labstep
 *
 * @module components/Resource/Filter/Pane
 * @desc Filter pane for resource
 */

import FilterAssignedToList from 'labstep-web/components/Filter/AssignedTo/List';
import FilterCreatedByList from 'labstep-web/components/Filter/CreatedBy/List';
import { FilterList } from 'labstep-web/components/Filter/List';
import FilterPane from 'labstep-web/components/Filter/Pane';
import FilterTagList from 'labstep-web/components/Filter/Tag/List';
import { IResourceItemExtraFiltersProps } from 'labstep-web/components/ResourceItem/Filter/Pane/types';
import { ICONS } from 'labstep-web/constants/icons';
import { Resource } from 'labstep-web/models/resource.model';
import { PostFilterAvailableItems } from 'labstep-web/services/postFilter/filters/availableItems';
import { STRINGS } from 'labstep-web/strings';
import React from 'react';

export const ResourceExtraFilters: React.FC<
  IResourceItemExtraFiltersProps
> = ({ usePostFilter }) => (
  <FilterList
    filters={[
      {
        icon: ICONS.resource.info.available,
        text: STRINGS.resource.info.available,
        params: { available_resource_item_count: 1 },
        postFilterValues: usePostFilter
          ? [PostFilterAvailableItems, ['1']]
          : undefined,
      },
      {
        icon: ICONS.resource.info.unavailable,
        text: STRINGS.resource.info.unavailable,
        params: { available_resource_item_count: 0 },
        postFilterValues: usePostFilter
          ? [PostFilterAvailableItems, ['0', '0']]
          : undefined,
      },
    ]}
  />
);

const ResourceFilterPane: React.FC = () => (
  <FilterPane
    entityName={Resource.entityName}
    extraFilters={
      <>
        <ResourceExtraFilters usePostFilter />
        <FilterCreatedByList
          entityName={Resource.entityName}
          usePostFilter
        />
        <FilterAssignedToList
          entityName={Resource.entityName}
          usePostFilter
        />
        <FilterTagList
          entityName={Resource.entityName}
          usePostFilter
        />
      </>
    }
    filters={[]}
    icon={ICONS.resource.primary}
    text={STRINGS.resource.plural.capitalized}
  />
);

export default ResourceFilterPane;
