/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Create/Wizard/Trigger
 * @desc OrderRequest create wizard trigger
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { useModalRoute } from 'labstep-web/hooks/modalRoute';
import React from 'react';
import { UPDATE_ENTITY_STATE_MODAL_ROUTE_ID } from '..';
import { IExperimentWorkflowActionUpdateEntityStateModalWizardTriggerProps } from './types';

export const ExperimentWorkflowActionUpdateEntityStateModalWizardTrigger: React.FC<
  IExperimentWorkflowActionUpdateEntityStateModalWizardTriggerProps
> = ({ experimentWorkflow, actionComponentProps }) => {
  const { toggleModal } = useModalRoute(
    UPDATE_ENTITY_STATE_MODAL_ROUTE_ID,
  );

  const canEdit = useHasAccess(
    'experiment_workflow',
    experimentWorkflow.id,
    Action.experiment_workflow_edit_entity_state,
  );

  if (!canEdit) {
    return null;
  }

  return (
    <ActionComponent
      onClick={() => toggleModal({ experimentWorkflow })}
      {...actionComponentProps}
    />
  );
};

export default ExperimentWorkflowActionUpdateEntityStateModalWizardTrigger;
