/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Create
 * @desc Create Action for order request workflow
 */

import React from 'react';
import { IOrderRequestActionCreateProps } from './types';
import OrderRequestActionCreateWizardTrigger from './Wizard/Trigger';

export const OrderRequestActionCreate: React.FC<
  IOrderRequestActionCreateProps
> = ({ resource, purchaseOrder, actionComponentProps }) => {
  return (
    <OrderRequestActionCreateWizardTrigger
      actionComponentProps={actionComponentProps}
      resource={resource}
      purchaseOrder={purchaseOrder}
    />
  );
};

export default OrderRequestActionCreate;
