/**
 * Labstep
 *
 * @module core/Placeholder
 * @desc Placeholder for loading content. Wrapper around semantic ui placeholder.
 */

import React from 'react';
import Placeholder from 'semantic-ui-react/dist/commonjs/elements/Placeholder';
import {
  ICorePlaceholderProps,
  ICorePlaceholderState,
} from './types';

export const PlaceholderDefault: React.FC = () => (
  <Placeholder fluid>
    <Placeholder.Line length="full" />
    <Placeholder.Line length="very long" />
    <Placeholder.Line length="long" />
    <Placeholder.Line length="medium" />
    <Placeholder.Line length="short" />
    <Placeholder.Line length="very short" />
  </Placeholder>
);

const DELAY = 500;

class CorePlaceholder extends React.Component<
  ICorePlaceholderProps,
  ICorePlaceholderState
> {
  static Placeholder = Placeholder;

  static Header = Placeholder.Header;

  static Image = Placeholder.Image;

  static Line = Placeholder.Line;

  static Paragraph = Placeholder.Paragraph;

  constructor(props: ICorePlaceholderProps) {
    super(props);
    this.state = { showing: false };
  }

  componentDidMount() {
    const { delay = DELAY } = this.props;
    setTimeout(() => {
      this.setState({ showing: true });
    }, delay);
  }

  render() {
    const { showing } = this.state;
    if (!showing) {
      return null;
    }
    return <PlaceholderDefault />;
  }
}

export default CorePlaceholder;
