/**
 * Labstep
 *
 * @module prosemirror/nodes/code-block
 * @desc Code block node
 */

import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';
import { setBlockType } from 'prosemirror-commands';
import { textblockTypeInputRule } from 'prosemirror-inputrules';
import { NodeType } from 'prosemirror-model';

// : (NodeType) → InputRule
// Given a code block node type, returns an input rule that turns a
// textblock starting with three backticks into a code block.
export function codeBlockRule(nodeType: NodeType) {
  return textblockTypeInputRule(/^```$/, nodeType);
}

const NodeCodeBlock: LabstepNode = {
  key: 'code_block',
  spec: {
    content: 'text*',
    marks: '',
    group: 'block',
    code: true,
    defining: true,
    parseDOM: [{ tag: 'pre', preserveWhitespace: 'full' }],
    toDOM() {
      return ['pre', ['code', 0]];
    },
  },
  keymap: {
    'Shift-Ctrl-\\': (state, dispatch) =>
      setBlockType(state.schema.nodes[NodeCodeBlock.key])(
        state,
        dispatch,
      ),
  },
  inputRules({ schema }) {
    return [codeBlockRule(schema.nodes[NodeCodeBlock.key])];
  },
};

export default NodeCodeBlock;
