/**
 * Labstep
 *
 * @module components/Entity/Action/Update/SelectMulti
 * @desc Entity Update action with Select Multi
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import SelectMulti from 'labstep-web/core/Select/Multi';
import React from 'react';
import { IEntityActionUpdateSelectMultiProps } from './types';

export const EntityActionUpdateSelectMulti: React.FC<
  IEntityActionUpdateSelectMultiProps
> = ({ entity, createBody, options, optimistic, ...rest }) => (
  <EntityUpdateContainer
    entityName={entity.entityName}
    id={entity.id}
    optimistic={optimistic}
  >
    {({ update }) => (
      <SelectMulti
        onChange={(values) => update(createBody(values), options)}
        {...rest}
      />
    )}
  </EntityUpdateContainer>
);

export default EntityActionUpdateSelectMulti;
