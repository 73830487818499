/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/Heading
 * @desc Buttons to set heading
 */

import React, { useState, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { setBlockType } from 'prosemirror-commands';
import { Popup } from 'semantic-ui-react';
import { hasBlockType } from 'labstep-web/prosemirror/marks/utils';
import ToolbarButton from 'labstep-web/prosemirror/components/Toolbar/Button';
import Flex from 'labstep-web/core/Flex';
import { IHeadingProps, IHeadingToolbarProps } from './types';

/** Heading levels */
export const HEADING_LEVELS = [1, 2, 3, 4, 5, 6];

export const Heading: React.FC<IHeadingProps> = ({
  state,
  dispatch,
  level,
  handleClose,
}) => (
  <ToolbarButton
    text={`H${level}`}
    textStyle={{ fontSize: `${18 - level}px` }}
    popup={`Heading ${level} (Ctrl+⇧+${level})`}
    onClick={() => {
      setBlockType(state.schema.nodes.heading, { level })(
        state,
        dispatch,
      );
      handleClose();
    }}
    isActive={hasBlockType(state, state.schema.nodes.heading, {
      level,
    })}
  />
);

const HeadingToolbar: React.FC<IHeadingToolbarProps> = ({
  state,
  dispatch,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <Popup
        wide
        style={{
          padding: '5px',
          top: isMobile ? '40px' : 0,
          left: isMobile ? 0 : '-28px',
        }}
        basic
        trigger={
          <ToolbarButton
            key="heading"
            icon="heading"
            popup="Heading"
            isActive={
              HEADING_LEVELS.filter((level: number) =>
                hasBlockType(state, state.schema.nodes.heading, {
                  level,
                }),
              ).length !== 0
            }
          />
        }
        position="bottom center"
        open={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        <Popup.Content>
          <Flex>
            {HEADING_LEVELS.map((level: number) => (
              <Heading
                key={level}
                state={state}
                dispatch={dispatch}
                level={level}
                handleClose={handleClose}
              />
            ))}
            <ToolbarButton
              text="P"
              popup="Paragraph (Ctrl+⇧+7)"
              onClick={() => {
                setBlockType(state.schema.nodes.paragraph, {
                  level: 1,
                })(state, dispatch);
                handleClose();
              }}
              isActive={hasBlockType(
                state,
                state.schema.nodes.paragraph,
                {
                  level: 1,
                },
              )}
            />
          </Flex>
        </Popup.Content>
      </Popup>
    </div>
  );
};

export default HeadingToolbar;
