/**
 * Labstep
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';

export const Links: React.FC = () => (
  <div>
    <a
      href="http://help.labstep.com/en/articles/5144891-using-the-labstep-device-client-to-send-files-from-your-device"
      target="_blank"
      rel="noreferrer"
      aria-label="Download Device Client"
    >
      <ActionComponent
        type="text"
        icon="download"
        text="Download Device Client"
      />
    </a>
    <a
      href="https://labsteppy.readthedocs.io/en/latest/source/device_integration.html"
      target="_blank"
      rel="noreferrer"
      aria-label="Setup via API"
    >
      <ActionComponent
        type="text"
        icon="help circle"
        text="Setup via API"
      />
    </a>
  </div>
);

export default Links;
