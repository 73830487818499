/**
 * Labstep
 */

// eslint-disable-next-line no-shadow
export enum Theme {
  theme1 = 'theme1',
  theme2 = 'theme2',
  theme3 = 'theme3',
  theme4 = 'theme4',
  theme5 = 'theme5',
  theme6 = 'theme6',
  theme7 = 'theme7',
  theme8 = 'theme8',
}

// eslint-disable-next-line no-shadow
export enum Size {
  mini = 'mini',
  tiny = 'tiny',
  small = 'small',
  medium = 'medium',
}

export interface IInitialsProps {
  name: string;
  capitalize?: boolean;
  size?: Size;
  themeId?: number;
  centered?: boolean;
  circular?: boolean;
}
