/**
 * Labstep
 *
 * @module models/permission
 * @desc Typescript export class for Permission
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { Group } from 'labstep-web/models/group.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

// eslint-disable-next-line no-shadow
export enum PermissionType {
  view = 'view',
  edit = 'edit',
  owner = 'owner',
}

export class Permission extends Entity {
  public static readonly idAttr = 'guid' as const;

  static readonly entityName = 'permission';

  static readonly permissionsText = {
    [PermissionType.view]: 'Can view only',
    [PermissionType.edit]: 'Can view & edit',
    [PermissionType.owner]: 'Owner',
  };

  id: number;

  get entityName(): string {
    return Permission.entityName;
  }

  get displayEntityName(): string {
    return this.entityName;
  }

  type: PermissionType;

  @Type(() => Group)
  group: Group;

  constructor(data: Partial<Permission> = {}) {
    super();
    Object.assign(this, data);
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
