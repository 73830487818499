/**
 * Labstep
 */

import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { Layout } from 'react-grid-layout';

/* eslint-disable no-plusplus */
export const generateLayout = (rows: number, columns: number) => {
  let layout = {};
  for (let i = 0; i < columns; i++) {
    for (let j = 0; j < rows; ) {
      const key = (j * columns + i + 1).toString();
      layout = {
        ...layout,
        [key]: {
          name: key,
          item: {
            x: i,
            y: j,
            w: 1,
            h: 1,
            i: key,
            static: false,
          },
        },
      };
      j += 2;
    }
  }

  return layout;
};

export const getAlphabetLetter = (i: number) =>
  'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i];

export const getFilledCells = (layout: Layout[]) =>
  layout.reduce((result, item) => {
    const extraCells = {};
    for (let i = 0; i < item.w; i++) {
      for (let j = 0; j < item.h; j++) {
        extraCells[`${i + item.x},${j + item.y}`] = true;
      }
    }
    return { ...result, ...extraCells };
  }, {});

export const transformLayoutToReadOnly = (
  initialLayout: ResourceLocation['map_data']['data'],
): ResourceLocation['map_data']['data'] =>
  Object.keys(initialLayout).reduce(
    (result, key) => ({
      ...result,
      [key]: {
        ...initialLayout[key],
        item: {
          ...initialLayout[key].item,
          isDraggable: false,
        },
      },
    }),
    {},
  );
