/**
 * Labstep
 *
 * @module components/UserGroup/Table
 * @desc Table listing user groups
 */

import React from 'react';
import UserGroupActionMenu from 'labstep-web/components/UserGroup/Action/Menu';
import UserGroupActionUpdate from 'labstep-web/components/UserGroup/Action/Update';
import { Action } from 'labstep-web/components/UserGroup/Action/Menu/types';
import GroupInitials from 'labstep-web/components/Group/Initials';
import EntityTable from 'labstep-web/components/Entity/Table';
import { UserGroup } from 'labstep-web/models/user-group.model';
import { Size } from 'labstep-web/core/Initials/types';
import LinkButton from 'labstep-web/core/Link/Button';
import Flex from 'labstep-web/core/Flex';
import { IUserGroupTableProps } from './types';
import styles from './styles.module.scss';

export const UserGroupTable: React.FC<IUserGroupTableProps> = ({
  userGroups,
}) => {
  const primaryColumn = {
    header: 'Workspace',
    content: (userGroup: UserGroup) => (
      <Flex spacing="gap" vAlignContent="center">
        <div>
          <GroupInitials size={Size.mini} group={userGroup.group} />
        </div>
        <LinkButton
          className={styles.linkButton}
          route={{
            to: 'group_show',
            params: { id: userGroup.group.id },
          }}
        >
          <b>{userGroup.group.name}</b>
        </LinkButton>
      </Flex>
    ),
    cellProps: { style: { flex: 4, alignItems: 'center' } },
  };

  const columns = [
    {
      header: 'Workspace Role',
      content: (userGroup: UserGroup) => (
        <UserGroupActionUpdate userGroup={userGroup} />
      ),
      cellProps: { style: { flex: 3, alignItems: 'center' } },
    },
  ];

  return (
    <EntityTable
      id="user-group-table"
      entities={userGroups}
      extraColumns={columns}
      primaryColumn={primaryColumn}
      actionMenu={({ entity }) => (
        <UserGroupActionMenu
          userGroup={entity}
          actions={[Action.delete_user_group]}
        />
      )}
    />
  );
};

export default UserGroupTable;
