/**
 * Labstep
 *
 * @module components/Entity/Search
 * @desc Generic component for rendering a list of entities with search bar + filters
 */

import { EntityViewContextProvider } from 'labstep-web/components/EntityView/context';
import Filter from 'labstep-web/components/Filter';
import { BulkSelectContainer } from 'labstep-web/containers/BulkSelect';
import { withUiPersistent } from 'labstep-web/containers/UiPersistent';
import { DataGridContextProvider } from 'labstep-web/core/DataGrid/context';
import Pagination from 'labstep-web/core/Pagination';
import { AVAILABLE_COUNTS_FILTER } from 'labstep-web/core/Pagination/CountPerPage';
import { SearchHOC } from 'labstep-web/hoc/Search';
import { objectOrFunction } from 'labstep-web/services/react.service';
import isEqual from 'lodash/isEqual';
import React, { useMemo } from 'react';
import ActionBar from './ActionBar';
import EntitySearchChildren from './Children';
import EntitySearchContext from './context';
import styles from './styles.module.scss';
import {
  IEntitySearchContainerProps,
  IEntitySearchContainerWithUiPersistentProps,
  IEntitySearchProps,
} from './types';
import { getCountPerPage, getFilteredEntities } from './utils';

export class EntitySearch extends React.Component<IEntitySearchProps> {
  private async: NodeJS.Timeout | undefined = undefined;

  public componentDidUpdate(prevProps: IEntitySearchProps) {
    const {
      searchParams,
      entities,
      total,
      setParams,
      clearParams,
      read,
      status,
      countPerPage,
      clearAll,
    } = this.props;
    const currentPage = searchParams.page || 1;

    // If entities ids has changed (e.g. Archive, Created new entity) then reread page
    if (
      isEqual(prevProps.searchParams, searchParams) &&
      !isEqual(
        entities.map((e) => e.id),
        prevProps.entities.map((e) => e.id),
      ) &&
      prevProps.status.cached &&
      // Not after a reread
      !(prevProps.status.isFetching && !this.props.status.isFetching)
    ) {
      // We set a timeout because ElasticSearch takes some time to reload
      if (total !== prevProps.total) {
        this.async = setTimeout(() => read(), 500);
      }
    }

    // // If cache has been invalidated read
    if (
      status &&
      !status.cached &&
      prevProps.status &&
      prevProps.status.cached
    ) {
      read();
    }

    // Return to last page of results if current page is bigger than last page
    if (
      status &&
      status.cached &&
      !isEqual(prevProps.searchParams, searchParams)
    ) {
      const lastPage = Math.ceil(total / countPerPage);

      if (currentPage > 1 && currentPage > lastPage) {
        setParams({ page: lastPage });
      }
    }

    // Return to first page if countPerPage changes
    if (!isEqual(prevProps.countPerPage, countPerPage)) {
      clearParams(['page']);
    }

    // Clear searchParams if invalid parameter
    if (
      searchParams &&
      status &&
      status.error &&
      status.error.status === 400
    ) {
      clearAll();
    }
  }

  public componentWillUnmount() {
    if (this.async) {
      clearTimeout(this.async);
    }
  }

  public render() {
    const {
      status,
      entityName,
      total,
      action,
      searchKey,
      searchParams,
      filters,
      bulkActions,
      countPerPage,
      hideFilters,
      setCountPerPage,
      searchBarProps,
      usePostFilter,
      isTemplate,
      sortOptions,
      entityView,
      hidePagination,
      useAllPages,
      defaultSortOptions,
      entities,
      ...rest
    } = this.props;

    const children = (
      <div className={styles.container}>
        {!hideFilters && (
          <Filter
            action={objectOrFunction(action, {
              searchParams,
            })}
            filters={filters}
            searchKey={searchKey}
            entityName={entityName}
            searchBarProps={searchBarProps}
            isTemplate={isTemplate}
            sortOptions={sortOptions}
            defaultSortOptions={defaultSortOptions}
          />
        )}
        <ActionBar
          entityName={entityName}
          bulkActions={bulkActions}
        />
        <div className={styles.children}>
          <EntitySearchChildren
            status={status}
            entityName={entityName}
            useAllPages={useAllPages}
            entities={entities}
            {...rest}
          />
        </div>
        {!hidePagination && !useAllPages && !!entities.length && (
          <Pagination
            count={total}
            countPerPage={countPerPage}
            setCountPerPage={setCountPerPage}
            availableCounts={
              usePostFilter ? AVAILABLE_COUNTS_FILTER : undefined
            }
          />
        )}
      </div>
    );
    return entityView ? (
      <EntityViewContextProvider
        {...objectOrFunction(entityView, searchParams)}
      >
        {children}
      </EntityViewContextProvider>
    ) : (
      children
    );
  }
}

export const EntitySearchContainer: React.FC<
  IEntitySearchContainerProps
> = ({
  params,
  entityName,
  historyAction,
  additionalFilters,
  initialSearchParams,
  countPerPage,
  usePostFilter,
  useAllPages,
  sortOptions,
  ...rest
}) => {
  const value = useMemo(
    () => ({
      sortOptions,
      usePostFilter,
    }),
    [sortOptions, usePostFilter],
  );
  return (
    <SearchHOC
      entityName={entityName}
      params={{ count: countPerPage, ...params }}
      historyAction={historyAction}
      initialParams={initialSearchParams}
      usePostFilter={usePostFilter}
    >
      {({
        entities,
        entitiesAllPages,
        total,
        ...restSearchHocParams
      }) => {
        const entitiesAll = useAllPages
          ? entitiesAllPages!
          : entities;
        const filteredEntities = getFilteredEntities(
          entitiesAll,
          restSearchHocParams.searchParams,
          rest.filters,
          additionalFilters,
        );
        const totalExcludingFilteredEntities =
          total - (entitiesAll.length - filteredEntities.length);
        return (
          <BulkSelectContainer
            total={total}
            visibleEntities={filteredEntities}
          >
            <EntitySearchContext.Provider value={value}>
              <DataGridContextProvider>
                <EntitySearch
                  entityName={entityName}
                  countPerPage={countPerPage}
                  entities={filteredEntities}
                  total={totalExcludingFilteredEntities}
                  initialSearchParams={initialSearchParams}
                  isTemplate={!!params?.is_template}
                  usePostFilter={usePostFilter}
                  useAllPages={useAllPages}
                  sortOptions={sortOptions}
                  {...rest}
                  {...restSearchHocParams}
                />
              </DataGridContextProvider>
            </EntitySearchContext.Provider>
          </BulkSelectContainer>
        );
      }}
    </SearchHOC>
  );
};

export const EntitySearchContainerWithUiPersistent: React.FC<
  IEntitySearchContainerWithUiPersistentProps
> = ({ uiPersistent, setUiCountPerPage, ...rest }) => (
  <EntitySearchContainer
    countPerPage={getCountPerPage(uiPersistent)}
    setCountPerPage={setUiCountPerPage}
    {...rest}
  />
);

export default withUiPersistent(
  EntitySearchContainerWithUiPersistent,
);
