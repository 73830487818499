/**
 * Labstep
 *
 * @module components/Sequence/Action/Edit
 */

import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import SequenceActionUpdate from 'labstep-web/components/Sequence/Action/Update';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import { Sequence } from 'labstep-web/models/sequence.model';
import React, { useEffect, useState } from 'react';
import { ISequenceActionEditProps } from './types';

export const SequenceActionEdit: React.FC<
  ISequenceActionEditProps
> = ({
  sequence,
  actionComponentProps = {},
  activeEditModal,
  setActiveEditModal,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      activeEditModal &&
      activeEditModal.entityName === Sequence.entityName &&
      activeEditModal.id === sequence.id
    ) {
      setOpen(true);
    }
  }, [activeEditModal, sequence]);

  return (
    <>
      <EntityActionEdit
        entity={sequence}
        actionComponentProps={{
          type: 'option',
          onClick: () => setOpen(true),
          ...actionComponentProps,
        }}
      />
      {open && (
        <SequenceActionUpdate
          onClose={() => {
            setOpen(false);
            setActiveEditModal(null);
          }}
          sequenceId={sequence.id}
        />
      )}
    </>
  );
};

export default withActiveEditModal(SequenceActionEdit);
