/**
 * Labstep
 *
 * @module components/Entity/Detail/Table
 * @desc Table for entity detail
 */

import React from 'react';
import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import { IEntityDetailTableProps } from './types';

export const EntityDetailTable: React.FC<IEntityDetailTableProps> = ({
  rows,
  columns,
}) => (
  <HorizontalTable
    padded
    borderless
    headerProps={{ width: 6 }}
    rows={rows}
    columns={columns}
  />
);

export default EntityDetailTable;
