/**
 * Labstep
 */

import { Action } from 'labstep-web/models/action.model';
import { Chemical } from 'labstep-web/models/chemical.model';
import { Entity } from 'labstep-web/models/entity.model';
import { Metadata } from 'labstep-web/models/metadata';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { isEmpty } from 'labstep-web/state/selectors/helpers';
import { LabstepReduxState } from 'labstep-web/state/types';
import clone from 'lodash/clone';
import union from 'lodash/union';

/** Sorting to use if not default */
const SORT_FIELDS = {
  [Chemical.entityName]: { createdAt: 'asc' },
  [ProtocolCondition.entityName]: { createdAt: 'asc' },
};

export const isPaginated = (keyState, params) => {
  if (typeof params !== 'object') {
    return false;
  }
  if ('get_count' in params) {
    return false;
  }
  if ('folder_group_not_id' in params) {
    return false;
  }
  if ('is_deleted' in params && params.is_deleted !== 'both') {
    return false;
  }
  if ('get_single' in params) {
    return false;
  }
  if (!(keyState.items || 'totalPages' in keyState)) {
    return false;
  }
  return true;
};

export const jsonParse = (key) => {
  if (!key.startsWith('{')) {
    return key;
  }
  return JSON.parse(key);
};

export const arrayify = (input: any) =>
  Array.isArray(input) ? input : [input];

/**
 * For single entity the normalised result is not an array
 * We use arrayfy to turn it in to array to be able to use union function
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {object|array} result
 * @return {array}
 */
export const mergeWithArray = (state: any, result: any) =>
  union(state, arrayify(result));

export const removeUndefinedKeys = (object) =>
  Object.keys(object).reduce((result, key) => {
    if (object[key] === undefined) {
      return result;
    }
    return { ...result, [key]: object[key] };
  }, {});

/**
 * Adds variable_template if variable_template_guid defined
 * TODO: Move this into middleware after metadata guid migration
 */
export const addVariableTemplate = (entity, state, entityName) => {
  const variableTemplateGuid = entity.variable_template_guid;
  if (!variableTemplateGuid) {
    return entity;
  }
  let variableTemplateIdAttr: string | number = variableTemplateGuid;

  // Can remove this once metadata idAttribute is guid
  if (entityName === Metadata.entityName) {
    const variableTemplateId = Object.values(
      state as LabstepReduxState['entities']['metadata']['byId'],
    ).find((value) => value.guid === variableTemplateGuid)?.id;
    variableTemplateIdAttr = variableTemplateId;
  }

  return {
    ...entity,
    variable_template: variableTemplateIdAttr,
  };
};

/**
 * Merge entities by ids
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {array} entities - Array of entities
 * @return {object}
 */
export const mergeByIds = (
  state: any,
  entities: Entity[],
  entityName: string,
): any => {
  // When nested entity is empty in normalizr it does not appear under entities therefore
  // we need to check for undefined. Also if entities is null or empty just return state
  if (
    typeof entities === 'undefined' ||
    entities == null ||
    isEmpty(entities)
  ) {
    return state;
  }

  // Merge entities and state common keys as they might have different fields
  // FIXME Bad code detected by ESLint
  const mergedEntities: any[] = Object.keys(entities).reduce(
    (result: any, current: any) => {
      const item: any = clone(result);
      const entitiesCurrent = addVariableTemplate(
        entities[current],
        state,
        entityName,
      );
      item[current] =
        current in state
          ? {
              ...state[current],
              ...removeUndefinedKeys(entitiesCurrent),
            }
          : entitiesCurrent;
      return item;
    },
    {},
  );

  return { ...state, ...mergedEntities };
};

export const isSubset = (complete, sub) => {
  return Object.keys(sub).reduce(
    (condition, key) => complete[key] === sub[key] || condition,
    false,
  );
};

const getUniqueValues = (
  arrayOfValuesToBePushed: any[],
  array: any[],
) =>
  arrayOfValuesToBePushed.filter(
    (value) => array.indexOf(value) === -1,
  );

export const prependUniqueValuesToArray = (
  arrayOfValuesToBePushed: any[],
  array: any[],
) => [...getUniqueValues(arrayOfValuesToBePushed, array), ...array];

export const appendUniqueValuesToArray = (
  arrayOfValuesToBePushed: any[],
  array: any[],
) => [...array, ...getUniqueValues(arrayOfValuesToBePushed, array)];

/** Filter method for items not in the action identifier(s) */
export const filterById = (action: Action, id: number) => {
  return Array.isArray(action.meta.identifier)
    ? !action.meta.identifier.includes(id)
    : id !== action.meta.identifier;
};

export const checkFieldNotInParams = (
  action: Action,
  params: Record<string, unknown>,
  field: string,
): boolean =>
  field in action.meta.body &&
  !(String(params[field]) === String(action.meta.body[field]));

/** Check if should append to array rather than prepend */
export const shouldAppend = (entityName: string): boolean => {
  const sortFields = SORT_FIELDS[entityName];
  if (sortFields?.createdAt === 'asc' || sortFields?.id === 'asc') {
    return true;
  }
  return false;
};
