/**
 * Labstep
 *
 * @module containers/Resource/CustomIdentifier
 * @desc Resource CustomIdentifier container
 */

import {
  resourceCustomIdentifierAction,
  resourceCustomIdentifierActionType,
} from 'labstep-web/state/actions/resource-custom-identifier';
import { selectApiStatus } from 'labstep-web/state/selectors/ui';
import { LabstepReduxState } from 'labstep-web/state/types';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

type ResourceCustomIdentifierContainerChildrenProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

export const Container = ({
  children,
  ...rest
}: ResourceCustomIdentifierContainerChildrenProps & {
  children: (
    childrenProps: Omit<
      ResourceCustomIdentifierContainerChildrenProps,
      'children'
    >,
  ) => JSX.Element;
}) => children(rest);

const mapStateToProps = (state: LabstepReduxState) => ({
  status: selectApiStatus(state, resourceCustomIdentifierActionType),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createResourceCustomIdentifier(id: string | number) {
    dispatch(resourceCustomIdentifierAction(id));
  },
});

export const ResourceCustomIdentifierContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
