/**
 * Labstep
 *
 * @module components/Metadata/Search
 * @desc Metadata Search
 */

import EntitySearch from 'labstep-web/components/Entity/Search/Cursor';
import MetadataCardPreviewWithEntity from 'labstep-web/components/Metadata/Card/Preview/WithEntity';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import List from 'labstep-web/core/List';
import { Metadata } from 'labstep-web/models/metadata';
import { STRINGS } from 'labstep-web/strings';
import uniqBy from 'lodash/uniqBy';
import React, { useMemo } from 'react';
import EmptyState from './EmptyState';
import { IMetadataSearchProps } from './types';
import { getParams } from './utils';

export const MetadataSearch: React.FC<IMetadataSearchProps> = ({
  entity,
}) => {
  const entitySearchProps = useMemo(
    () => ({
      entityName: Metadata.entityName,
      hideAction: true,
      hideEmptyState: true,
      hideFilters: true,
    }),
    [],
  );
  return (
    <EntitySearch
      {...entitySearchProps}
      params={getParams(entity, 'input')}
      searchBarProps={{ placeholder: 'Search data' }}
    >
      {({
        entities: metadatasInput,
        loadMore: loadMoreInput,
        hasMore: hasMoreInput,
        status: statusInput,
        searchParams,
      }): React.ReactElement => (
        <EntitySearch
          {...entitySearchProps}
          params={getParams(entity, 'output', searchParams)}
        >
          {({
            entities: metadatasOutput,
            loadMore: loadMoreOutput,
            hasMore: hasMoreOutput,
            status: statusOutput,
          }): React.ReactElement => {
            const metadatas = uniqBy(
              [...metadatasInput, ...metadatasOutput],
              'guid',
            );
            if (!metadatas.length) {
              return <EmptyState />;
            }
            return (
              <>
                <List>
                  {metadatas.map((metadata: Metadata) => (
                    <MetadataCardPreviewWithEntity
                      key={metadata.id}
                      metadata={metadata}
                    />
                  ))}
                </List>
                {(hasMoreInput || hasMoreOutput) && (
                  <ActionComponent
                    status={
                      statusInput?.isFetching
                        ? statusInput
                        : statusOutput
                    }
                    onClick={() => {
                      loadMoreInput();
                      loadMoreOutput();
                    }}
                    type="text"
                    text={STRINGS.actions.show_more}
                  />
                )}
              </>
            );
          }}
        </EntitySearch>
      )}
    </EntitySearch>
  );
};

export default MetadataSearch;
