/**
 * Labstep
 *
 * @module models/notification
 * @desc Typescript export class for Notification
 */

import { Type } from 'class-transformer';
import { User } from 'labstep-web/models/user.model';
import { Entity } from 'labstep-web/models/entity.model';

// eslint-disable-next-line no-shadow
export enum NotificationType {
  announcement = 'announcement',
  comment_created_device = 'comment_created_device',
  comment_created_experiment_workflow = 'comment_created_experiment_workflow',
  comment_created_group = 'comment_created_group',
  comment_created_order_request = 'comment_created_order_request',
  comment_created_protocol_collection = 'comment_created_protocol_collection',
  comment_created_purchase_order = 'comment_created_purchase_order',
  comment_created_resource = 'comment_created_resource',
  comment_created_resource_item = 'comment_created_resource_item',
  comment_created_resource_location = 'comment_created_resource_location',
  device_assigned = 'device_assigned',
  device_created = 'device_created',
  device_updated = 'device_updated',
  device_booking_created = 'device_booking_created',
  device_booking_updated = 'device_booking_updated',
  device_data_created = 'device_data_created',
  device_data_updated = 'device_data_updated',
  experiment_workflow_assigned = 'experiment_workflow_assigned',
  experiment_workflow_created = 'experiment_workflow_created',
  jupyter_instance_run_status_updated = 'jupyter_instance_run_status_updated',
  group_security_policy_updated = 'group_security_policy_updated',
  invitation_accepted = 'invitation_accepted',
  invitation_created = 'invitation_created',
  notification_alert_triggered = 'notification_alert_triggered',
  order_request_assigned = 'order_request_assigned',
  order_request_created = 'order_request_created',
  order_request_updated = 'order_request_updated',
  protocol_collection_assigned = 'protocol_collection_assigned',
  protocol_collection_created = 'protocol_collection_created',
  purchase_order_assigned = 'purchase_order_assigned',
  purchase_order_created = 'purchase_order_created',
  purchase_order_updated_order_request = 'purchase_order_updated_order_request',
  purchase_order_updated_status = 'purchase_order_updated_status',
  resource_available_resource_item_count_alert = 'resource_available_resource_item_count_alert',
  resource_assigned = 'resource_assigned',
  resource_created = 'resource_created',
  resource_import_success = 'resource_import_success',
  resource_import_error = 'resource_import_error',
  resource_item_assigned = 'resource_item_assigned',
  resource_item_amount_alert = 'resource_item_amount_alert',
  resource_location_assigned = 'resource_location_assigned',
  share_link = 'share_link',
  share_link_invitation_created = 'share_link_invitation_created',
  signature_created = 'signature_created',
  signature_request_created = 'signature_request_created',
  signature_revoked = 'signature_revoked',
  user_group_deleted = 'user_group_deleted',
  user_group_demoted = 'user_group_demoted',
  user_group_promoted = 'user_group_promoted',
  user_group_security_policy_set = 'user_group_security_policy_set',
  user_group_security_policy_unset = 'user_group_security_policy_unset',
  user_mention_created = 'user_mention_created',
  experiment_workflow_entity_state_updated = 'experiment_workflow_entity_state_updated',
  entity_state_workflow_created = 'entity_state_workflow_created',
  entity_state_workflow_assigned = 'entity_state_workflow_assigned',
}

export class Notification extends Entity {
  public static readonly entityName = 'notification';

  public get entityName(): string {
    return Notification.entityName;
  }

  public constructor(data: Partial<Notification> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public metadata!: {
    [key: string]: unknown;
    parent_thread_id?: number;
  } | null;

  public message!: string;

  public type!: NotificationType;

  public read_at!: string;

  public entity_name!: string;

  public entity_type!: string;

  @Type(() => User)
  public author!: User;
}
