import { Entity } from 'labstep-web/models/entity.model';

export const SELECT_PREVIOUS = 'SELECT_PREVIOUS';

export const selectPrevious = () => ({
  type: SELECT_PREVIOUS,
});

export const SELECT_NEXT = 'SELECT_NEXT';

export const selectNext = () => ({
  type: SELECT_NEXT,
});

export const SUBMIT_ITEM = 'SUBMIT_ITEM';

export const submitItem = () => ({
  type: SUBMIT_ITEM,
});

export const SELECT_ITEM = 'SELECT_ITEM';

export const selectItem = (index: number) => ({
  type: SELECT_ITEM,
  index,
});

export const SELECT_CREATABLE_ITEM = 'SELECT_CREATABLE_ITEM';

export const selectCreatableItem = (index: number) => ({
  type: SELECT_CREATABLE_ITEM,
  index,
});

export const SUBMIT_CATEGORY = 'SUBMIT_CATEGORY';

export const submitCategory = () => ({
  type: SUBMIT_CATEGORY,
});

export const SELECT_CATEGORY = 'SELECT_CATEGORY';

export const selectCategory = (index: number) => ({
  type: SELECT_CATEGORY,
  index,
});

export const SET_ITEMS = 'SET_ITEMS';

export const setItems = (items: Entity[]) => ({
  type: SET_ITEMS,
  items,
});

export const SET_EXTERNAL_ITEMS = 'SET_EXTERNAL_ITEMS';

export const setExternalItems = (items: Entity[]) => ({
  type: SET_EXTERNAL_ITEMS,
  items,
});

export const SET_CREATABLE_ITEMS = 'SET_CREATABLE_ITEMS';

export const setCreatableItems = (items: Entity[]) => ({
  type: SET_CREATABLE_ITEMS,
  items,
});

export const SET_CATEGORIES = 'SET_CATEGORIES';

interface ICategory {
  label: string;
  value: string;
}

export const setCategories = (categories: ICategory[]) => ({
  type: SET_CATEGORIES,
  categories,
});

export const SET_IS_OPEN = 'SET_IS_OPEN';

export const setIsOpen = (isOpen: boolean) => ({
  type: SET_IS_OPEN,
  isOpen,
});
