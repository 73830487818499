/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/Signed/Select
 * @desc Select filter for extensions
 */

import React from 'react';
import Modal from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import ReactSelect from 'labstep-web/core/Select';
import { isActive } from 'labstep-web/components/Filter/List/utils';
import { SignedTypes } from 'labstep-web/models/experiment-workflow.model';
import { STRINGS } from 'labstep-web/strings';
import { IExperimentWorkflowFilterSignedSelectProps } from './types';
import { placeholder } from './strings';

const ExperimentWorkflowFilterSignedSelect: React.FC<
  IExperimentWorkflowFilterSignedSelectProps
> = ({ setParams, searchParams }) => {
  const activeSignedType = Object.values(SignedTypes).find((value) =>
    isActive(searchParams, value.params),
  );

  return (
    <Modal
      header={STRINGS.filter.header.signed_type}
      content={({ toggleModal }) => (
        <ReactSelect
          value={
            activeSignedType
              ? {
                  label: activeSignedType.text,
                  value: activeSignedType.params,
                }
              : null
          }
          options={Object.values(SignedTypes).map((value) => ({
            label: value.text,
            value: value.params,
          }))}
          isClearable
          onChange={(selectedOption) => {
            setParams(
              selectedOption
                ? selectedOption.value
                : Object.keys(
                    SignedTypes.request_pending.params,
                  ).reduce(
                    (result, key) => ({
                      ...result,
                      [key]: undefined,
                    }),
                    {},
                  ),
            );
            toggleModal();
          }}
          placeholder={placeholder}
        />
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon={SignedTypes.signed.icon}
          text={STRINGS.filter.header.signed_type}
          onClick={toggleModal}
        />
      )}
    />
  );
};

export default ExperimentWorkflowFilterSignedSelect;
