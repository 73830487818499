/**
 * Labstep
 */

import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';

export const getSignaturesChronologically = (
  experimentWorkflow: ExperimentWorkflow,
) => {
  if (experimentWorkflow.entityUserRoleRequirements.length === 0) {
    return [];
  }
  const sortedSignatures = experimentWorkflow.signatures.sort(
    (a, b) => {
      const updatesAtA = new Date(a.updated_at).getTime();
      const updatesAtB = new Date(b.updated_at).getTime();
      return updatesAtB - updatesAtA;
    },
  );

  return sortedSignatures;
};
