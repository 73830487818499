/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Share
 * @desc Share a experiment workflow with all users on the platform
 */

import React from 'react';
import EntityActionShare from 'labstep-web/components/Entity/Action/Share';
import { IExperimentWorkflowActionShareProps } from './types';

export const ExperimentWorkflowActionShare: React.FC<
  IExperimentWorkflowActionShareProps
> = ({ experimentWorkflow }) => (
  <EntityActionShare entity={experimentWorkflow} />
);

export default ExperimentWorkflowActionShare;
