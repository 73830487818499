/**
 * Labstep
 *
 * @module components/EntityUser/Action/Assign
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ICONS } from 'labstep-web/constants/icons';
import React from 'react';
import { IEntityUserActionToggleAssignProps } from './types';

interface IAdditional {
  entity_user_role_id?: string | null;
  is_assigned?: boolean;
}

export const EntityUserActionToggleAssign: React.FC<
  IEntityUserActionToggleAssignProps
> = ({ entityUser, entity, options, actionComponentProps = {} }) => {
  let additional: IAdditional = {};
  let isAssigned = entityUser.is_assigned;
  if (entityUser.entity_user_role) {
    isAssigned = true;
    additional = {
      entity_user_role_id: null,
      is_assigned: false,
    };
  }
  return (
    <Can
      entityName={entity.entityName}
      id={entity.idAttr}
      action={Action.assign}
    >
      <EntityActionCreate
        overwriteCanCreateProps={{
          parentName: entity.entityName,
          parentId: entity.idAttr,
        }}
        entityName={entityUser.entityName}
        actionComponentProps={{
          type: 'text',
          text: isAssigned ? 'Unassign' : 'Assign',
          icon: isAssigned
            ? ICONS.entity.actions.unassign
            : ICONS.entity.actions.assign,
          ...actionComponentProps,
        }}
        body={{
          // Important to keep this top for epics/normalize to work
          [`${entity.entityName}_id`]: entity.id,
          is_assigned: !isAssigned,
          user_id: entityUser.user.id,
          ...additional,
        }}
        options={options}
      />
    </Can>
  );
};

export default EntityUserActionToggleAssign;
