/**
 * Labstep
 *
 * @module prosemirror/extensions/cursor
 * @desc Cursor management
 */

import { dropCursor } from 'prosemirror-dropcursor';
import { gapCursor } from 'prosemirror-gapcursor';
import { LabstepExtension } from '../types';

const ExtensionCursor: LabstepExtension = {
  plugins: [dropCursor(), gapCursor()],
};

export default ExtensionCursor;
