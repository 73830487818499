/**
 * Labstep
 *
 * @module containers/Entity/Update/Can
 * @desc Entity update container with Can permission check
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import React from 'react';
import { IEntityCanUpdateContainerProps } from './types';

export const EntityCanUpdateContainer: React.FC<
  IEntityCanUpdateContainerProps
> = ({
  entityName,
  id,
  optimistic,
  renderIfNoEditPermissions,
  children,
  field,
}) => {
  const canEdit = Array.isArray(id)
    ? useHasAccessMultiple(entityName, id, Action.edit, field)
    : useHasAccess(entityName, id, Action.edit, field);

  const component = (
    <EntityUpdateContainer
      entityName={entityName}
      id={id}
      optimistic={optimistic}
    >
      {({ status, update }): React.ReactElement =>
        children({
          status,
          update,
          canEdit,
        })
      }
    </EntityUpdateContainer>
  );
  if (!Array.isArray(id) && !renderIfNoEditPermissions) {
    return canEdit ? component : null;
  }
  return component;
};

export default EntityCanUpdateContainer;
