/**
 * Labstep
 *
 * @module core/DatePreview
 * @desc A component showing a date
 */

import React from 'react';
import {
  dateOnly,
  humanReadableDate,
  timeOnly,
} from 'labstep-web/services/date.service';
import TextSmall from 'labstep-web/core/Text/Small';
import { IDatePreviewProps } from './types';

export const DateSmall: React.FC<IDatePreviewProps> = ({
  dateString,
}) => <TextSmall>{humanReadableDate(dateString)}</TextSmall>;

export const DatePreview: React.FC<IDatePreviewProps> = ({
  dateString,
}) => (
  <div>
    <span>{dateOnly(dateString)}</span> <span>at</span>{' '}
    <span>{timeOnly(dateString)}</span>
  </div>
);

export default DatePreview;
