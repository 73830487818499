/**
 * Labstep
 */

import ProtocolConditionActionToggleIsVariable from 'labstep-web/components/ProtocolCondition/Action/ToggleIsVariable';
import ProtocolValueShowEditInputOutput from 'labstep-web/components/ProtocolValue/Form/ShowEdit/InputOutput';
import ProtocolValueShowEditLineageTracking from 'labstep-web/components/ProtocolValue/Form/ShowEdit/LineageTracking';
import ProtocolValueFormShowEditResource from 'labstep-web/components/ProtocolValue/Form/ShowEdit/Resource';
import ProtocolValueShowEditResourceTemplate from 'labstep-web/components/ProtocolValue/Form/ShowEdit/ResourceTemplate';
import ProtocolValueShowEditUnit from 'labstep-web/components/ProtocolValue/Form/ShowEdit/Unit';
import { ICONS } from 'labstep-web/constants/icons';
import TextWithHelp from 'labstep-web/core/Text/WithHelp';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React from 'react';
import { RowsType } from '../types';

export const getSettingsRows = (entity: ProtocolValue): RowsType => {
  const rows: RowsType = [
    {
      header: (
        <TextWithHelp
          text="Input / Output"
          icon="exchange"
          header
          helperText="Input inventory fields are those used in the protocol, Output inventory fields are those produced in the protocol."
        />
      ),
      content: (protocolValue): React.ReactElement => (
        <ProtocolValueShowEditInputOutput
          protocolValue={protocolValue}
        />
      ),
      dataTestId: 'material-table-input-output',
    },
    {
      header: (
        <TextWithHelp
          text="Lineage Tracking"
          icon="sitemap"
          helperText="If enabled, the items used / created in this field will appear in the lineage of the other items with lineage tracking enabled."
          header
        />
      ),
      content: (protocolValue): React.ReactElement => (
        <ProtocolValueShowEditLineageTracking
          protocolValue={protocolValue}
        />
      ),
      dataTestId: 'material-table-lineage-tracking',
    },
    {
      header: (
        <TextWithHelp
          text="Category"
          icon={ICONS.resource_template.primary}
          header
        />
      ),
      content: (protocolValue): React.ReactElement => (
        <ProtocolValueShowEditResourceTemplate
          protocolValue={protocolValue}
        />
      ),
      dataTestId: 'material-table-resource-category',
    },
    {
      header: (
        <TextWithHelp
          text="Resource"
          icon={ICONS.resource.primary}
          header
        />
      ),
      content: (protocolValue): React.ReactElement => (
        <ProtocolValueFormShowEditResource
          protocolValue={protocolValue}
        />
      ),
      dataTestId: 'material-table-resource',
    },
  ];

  rows.splice(1, 0, {
    header: (
      <TextWithHelp
        text="Variable"
        icon={ICONS.protocol_condition.info.variable}
        helperText="Enable to assign this field different values across different conditions."
        header
      />
    ),
    content: (protocolValue): React.ReactElement => (
      <ProtocolConditionActionToggleIsVariable
        entity={protocolValue}
        protocol={protocolValue.parent}
      />
    ),
    dataTestId: 'material-table-is-variable',
  });

  if (entity.is_variable) {
    rows.push({
      header: (
        <TextWithHelp
          text="Amount Unit"
          icon={ICONS.protocol_value.info.unit}
          header
        />
      ),
      content: (protocolValue): React.ReactElement => (
        <ProtocolValueShowEditUnit protocolValue={protocolValue} />
      ),
      dataTestId: 'material-table-unit',
    });
  }
  return rows;
};
