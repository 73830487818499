/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/**
 * Labstep
 */

import React from 'react';
import classnames from 'classnames';
import { getAlphabetLetter } from 'labstep-web/core/GridLayout/Default/utils';
import { SIZE_PX } from '..';
import styles from './styles.module.scss';
import { ITableGridProps } from './types';

export const TableGrid: React.FC<ITableGridProps> = ({
  filledCells,
  rows,
  columns,
  onClick,
}) => {
  const rowsRepeater = new Array(rows).fill(0);
  const columnsRepeater = new Array(columns).fill(0);
  return (
    <table
      style={{
        borderSpacing: 0,
        borderCollapse: 'collapse',
      }}
      className={styles.table}
    >
      <tbody>
        <tr>
          <td
            style={{
              width: 40,
              height: 40,
            }}
          />
          {columnsRepeater.map((r2, j) => {
            const width = SIZE_PX;
            return (
              <td
                key={j}
                style={{
                  boxSizing: 'border-box',
                  width,
                  height: 40,
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {j + 1}
              </td>
            );
          })}
        </tr>
        {rowsRepeater.map((r, i) => {
          const height = SIZE_PX;
          return (
            <tr key={i}>
              <td
                style={{
                  width: 40,
                  height,
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {getAlphabetLetter(i)}
              </td>
              {columnsRepeater.map((r2, j) => {
                const width = SIZE_PX;
                const isOccupied = filledCells[`${j},${i}`];
                return (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <td
                    // eslint-disable-next-line no-alert
                    onClick={() => {
                      if (!isOccupied && onClick) {
                        onClick(j, i);
                      }
                    }}
                    key={j}
                    className={classnames({
                      [styles.selectionMode]:
                        !!onClick && !isOccupied,
                    })}
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 4,
                      borderStyle: 'solid',
                      boxSizing: 'border-box',
                      borderColor:
                        !!onClick && isOccupied
                          ? 'transparent'
                          : '#EFF2F7',
                      width,
                      height,
                    }}
                  />
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableGrid;
