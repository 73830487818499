/**
 * Labstep
 *
 * @module components/EntityImport/Action/Menu
 * @desc Action Menu for group
 */

import React from 'react';
import ActionMenu from 'labstep-web/core/Action/Menu';
import EntityImportActionRevert from 'labstep-web/components/EntityImport/Action/Revert';
import EntityImportActionDelete from 'labstep-web/components/EntityImport/Action/Delete';
import { Action, IEntityImportActionMenuProps } from './types';

const EntityImportActionMenu: React.FC<
  IEntityImportActionMenuProps
> = ({ entityImport, type, actions = [] }) => {
  return (
    <ActionMenu type={type}>
      {actions.includes(Action.delete) && (
        <EntityImportActionDelete entity={entityImport} />
      )}
      {actions.includes(Action.revert) && (
        <EntityImportActionRevert entityImport={entityImport} />
      )}
    </ActionMenu>
  );
};

EntityImportActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default EntityImportActionMenu;
