/**
 * Labstep
 *
 * @module models/ProtocolCondition
 * @desc Typescript export class for ProtocolCondition
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Metadata } from 'labstep-web/models/metadata';
import {
  MetadataEntityNameType,
  MetadataType,
} from 'labstep-web/models/metadata/types';
import {
  ProtocolValue,
  ProtocolValueEntityNameType,
} from 'labstep-web/models/protocol-value.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

export type ProtocolConditionEntityNameType = 'protocol_condition';

export type ProtocolConditionVariableType = {
  id?: number;
  guid: string;
  name: string | null;
  field_type: ProtocolValueEntityNameType | MetadataEntityNameType;
  is_input: boolean;
  type?: MetadataType;
  device_id?: number;
};

export class ProtocolCondition extends Entity {
  public static readonly idAttr = 'guid' as const;

  public static readonly entityName: ProtocolConditionEntityNameType =
    'protocol_condition';

  public get entityName(): ProtocolConditionEntityNameType {
    return ProtocolCondition.entityName;
  }

  public constructor(data: Partial<ProtocolCondition> = {}) {
    super();
    Object.assign(this, data);
  }

  public guid!: string;

  @Type(() => ProtocolValue)
  public protocol_values!: ProtocolValue[];

  @Type(() => ProtocolValue)
  public protocol_values_variable!: ProtocolValue[];

  @Type(() => ProtocolValue)
  public protocol_values_constant!: ProtocolValue[];

  @Type(() => Metadata)
  public metadatas!: Metadata[];

  @Type(() => Metadata)
  public metadatas_variable!: Metadata[];

  @Type(() => Metadata)
  public metadatas_constant!: Metadata[];

  @Type(() => Experiment)
  public experiment!: Experiment;

  @Type(() => Protocol)
  public protocol!: Protocol;

  public get nVariables(): number {
    return this.protocol_values.length + this.metadatas.length;
  }

  public static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
