/**
 * Labstep
 *
 * @module components/Layout/Menu/TopBar
 * @desc Default top bar
 */

import classnames from 'classnames';
import AcademicActionUpgrade from 'labstep-web/components/Academic/Action/Upgrade';
import EntityActionNew from 'labstep-web/components/Entity/Action/New';
import ExperimentLive from 'labstep-web/components/ExperimentWorkflow/Live';
import LayoutGlobalSearch from 'labstep-web/components/Layout/GlobalSearch';
import UserNotificationSidebar from 'labstep-web/components/Layout/UserNotificationSidebar';
import NotificationSideMenu from 'labstep-web/components/Notification/SideMenu';
import PermaLinkActionRedirect from 'labstep-web/components/PermaLink/Action/Redirect';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { VerticalDivider } from 'labstep-web/core/Divider';
import Help from 'labstep-web/core/Help';
import MediaQuery from 'labstep-web/core/MediaQuery';
import Desktop from 'labstep-web/core/MediaQuery/Desktop';
import { Size } from 'labstep-web/core/MediaQuery/types';
import { User } from 'labstep-web/models/user.model';
import React from 'react';
import { PlanTier } from 'labstep-web/constants/plans';
import Breadcrumb from './Breadcrumb';
import BreadcrumbMobile from './Breadcrumb/Mobile';
import LayoutMenuTopBarProfile from './Profile';
import styles from './styles.module.scss';
import { ILayoutMenuTopBarProps, IRightProps } from './types';

export const showAcademicUpgrade = (
  authenticatedUserEntity: User,
): boolean => {
  const organization =
    authenticatedUserEntity.userOrganizationOrganization;
  if (organization) {
    return organization.tier === PlanTier.AcademicPlus;
  }

  return !authenticatedUserEntity.is_organization_setup_completed;
};

export const Right: React.FC<IRightProps> = ({
  authenticatedUserEntity,
}) =>
  authenticatedUserEntity ? (
    <div className={styles.rightOptions}>
      <Desktop>
        <EntityActionNew />
        <VerticalDivider />
      </Desktop>
      <LayoutGlobalSearch />
      <MediaQuery size={Size.tablet_desktop}>
        <ExperimentLive />
      </MediaQuery>
      <MediaQuery size={Size.mobile_tablet}>
        <PermaLinkActionRedirect />
      </MediaQuery>
      <Desktop>
        <Help />
      </Desktop>
      <NotificationSideMenu
        viewComponent={<UserNotificationSidebar />}
      />
      {showAcademicUpgrade(authenticatedUserEntity) && (
        <Desktop>
          <AcademicActionUpgrade
            actionComponentProps={{
              type: 'icon',
              icon: 'arrow alternate circle up',
              elementProps: {
                color: 'green',
                size: authenticatedUserEntity.stripe_subscription_id
                  ? 'mini'
                  : 'large',
                popup: {
                  content:
                    authenticatedUserEntity.stripe_subscription_id
                      ? 'Complete Setup'
                      : 'Upgrade',
                },
              },
            }}
          />
        </Desktop>
      )}
      <LayoutMenuTopBarProfile />
    </div>
  ) : (
    <div />
  );

const RightWrapper = withAuthenticatedUserEntity(Right);

export const LayoutMenuTopBar: React.FC<ILayoutMenuTopBarProps> = ({
  breadcrumb,
}) => (
  <div className={styles.container} data-testid="menu-top-bar">
    <div className={classnames(styles.topBar)}>
      <div className={styles.left}>
        <Desktop>
          {({ match }) =>
            match ? (
              <Breadcrumb breadcrumb={breadcrumb} />
            ) : (
              <BreadcrumbMobile breadcrumb={breadcrumb} />
            )
          }
        </Desktop>
      </div>
      <RightWrapper />
    </div>
  </div>
);

export default LayoutMenuTopBar;
