/**
 * Labstep
 *
 * @module components/Organization/Form/Create
 * @desc Organization Form Create
 */

import React from 'react';
import FormCreate from 'labstep-web/core/Form/Create';
import { Organization } from 'labstep-web/models/organization.model';
import { fieldOrganizationName } from 'labstep-web/components/Organization/Form/fields';
import { IOrganizationFormCreateProps } from './types';

export const OrganizationFormCreate: React.FC<
  IOrganizationFormCreateProps
> = (props) => (
  <FormCreate
    autoFocus
    entityName={Organization.entityName}
    fields={[fieldOrganizationName]}
    {...props}
  />
);

export default OrganizationFormCreate;
