/**
 * Labstep
 *
 * @module components/Filter/CreatedBy/List
 * @desc Filter Created By List
 */

import React from 'react';
import FilterUserList from 'labstep-web/components/Filter/User/List';
import { STRINGS } from 'labstep-web/strings';
import { IFilterCreatedByListProps } from './types';

export const FilterCreatedByList: React.FC<
  IFilterCreatedByListProps
> = ({ header, entityName, usePostFilter }) => (
  <FilterUserList
    header={header || STRINGS.filter.header.created_by}
    name="created_by"
    field="author_id"
    entityName={entityName}
    usePostFilter={usePostFilter}
  />
);

export default FilterCreatedByList;
