/**
 * Labstep
 *
 * @module components/ResourceItemExport/Action/Create
 * @desc ResourceItem Export Action
 */

import EntityExportActionCreate from 'labstep-web/components/EntityExport/Action/Create';
import ActiveFilter from 'labstep-web/components/Filter/Active';
import ResourceItemExportTable from 'labstep-web/components/ResourceItemExport/Table';
import fields from 'labstep-web/components/ResourceItemExport/Table/fields';
import { Field } from 'labstep-web/components/ResourceItemExport/Table/types';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import ActionComponent from 'labstep-web/core/Action/Component';
import DefaultModal from 'labstep-web/core/Modal/Default';
import { filterParams, ParamsHOC } from 'labstep-web/hoc/Params';
import { ParamsContext } from 'labstep-web/hoc/Params/context';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import pick from 'lodash/pick';
import React from 'react';
import styles from './styles.module.scss';
import {
  ResourceItemExportActionCreateProps,
  ResourceItemExportActionCreateState,
} from './types';
import { formatFields } from './utils';

export const initialState: ResourceItemExportActionCreateState = {
  open: false,
  activeFields: fields.filter((field) => field.default),
};

export class ResourceItemExportActionCreate extends React.Component<
  ResourceItemExportActionCreateProps,
  ResourceItemExportActionCreateState
> {
  constructor(props: ResourceItemExportActionCreateProps) {
    super(props);
    this.state = initialState;
  }

  reset = () => {
    this.setState(initialState);
  };

  open = () => {
    this.setState({
      ...initialState,
      open: true,
    });
  };

  setActiveFields = (activeFields: Field[]) => {
    this.setState({
      activeFields,
    });
  };

  render() {
    const { activeGroup, actionComponentProps } = this.props;
    const { open, activeFields } = this.state;
    const additionalFields = fields.filter(
      (field) => !activeFields.includes(field),
    );
    return (
      <DefaultModal
        open={open}
        onClose={this.reset}
        size="fullscreen"
        header={`Export ${ResourceItem.getHumanReadableEntityName(
          true,
          true,
        )}`}
        viewComponent={() => (
          <ActionComponent
            type="option"
            icon="file archive"
            text="Export"
            onClick={() => this.open()}
            {...actionComponentProps}
          />
        )}
        content={() => {
          return (
            <div>
              <ParamsHOC
                historyAction="replace"
                skipIgnoreParamsOnMount
              >
                <ParamsContext.Consumer>
                  {({
                    searchParams,
                    removePostFilter,
                    setPostFilterType,
                  }) => {
                    return (
                      <div>
                        <div className={styles.filterContainer}>
                          <ActiveFilter
                            searchParams={searchParams}
                            showSearchQuery
                            setPostFilterType={setPostFilterType}
                            removePostFilter={removePostFilter}
                          />
                        </div>
                        <ReadOnMountHOC
                          type="page"
                          loading={{ loader: 'placeholder' }}
                          page={1}
                          entityName={ResourceItem.entityName}
                          params={{
                            group_id: activeGroup?.id,
                            ...pick(searchParams, filterParams),
                          }}
                          children={({ entities, total }) => (
                            <div>
                              <span>{`Previewing items 1-${entities.length} of ${total}`}</span>
                              <ResourceItemExportTable
                                resourceItems={entities}
                                activeFields={activeFields}
                                setActiveFields={this.setActiveFields}
                                additionalFields={additionalFields}
                              />
                            </div>
                          )}
                        />
                        <div className={styles.footer}>
                          <EntityExportActionCreate
                            type="csv"
                            entityName={ResourceItem.entityName}
                            params={searchParams}
                            fields={formatFields(activeFields)}
                            options={{
                              onSuccess: this.reset,
                            }}
                            actionComponentProps={{
                              type: 'button',
                            }}
                          />
                        </div>
                      </div>
                    );
                  }}
                </ParamsContext.Consumer>
              </ParamsHOC>
            </div>
          );
        }}
      />
    );
  }
}

export default withActiveGroup(ResourceItemExportActionCreate);
