/**
 * Labstep
 *
 * @module screens/Experiment/Show/TopBar
 * @desc TopBar of ExperimentShow Screen
 */

import ExperimentFormShowEditName from 'labstep-web/components/Experiment/Form/ShowEdit/Name';
import { ICONS } from 'labstep-web/constants/icons';
import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { IScreensExperimentShowTopBarProps } from './types';

export const DEFAULT_BACK_ROUTE = 'experiment_workflow_show';

export const ScreensExperimentShowTopBar: React.FC<
  IScreensExperimentShowTopBarProps
> = ({ experiment, to }) => (
  <div className={styles.topBar}>
    <Header size="xsmall" noMargin className={styles.header}>
      {experiment?.experiment_workflow && (
        <>
          <Link
            className={styles.link}
            to={
              to ||
              navigation.get(DEFAULT_BACK_ROUTE, {
                id: experiment.experiment_workflow.id,
              })
            }
          >
            <Icon name="angle left" size="large" />
          </Link>
          <Icon name={ICONS.experiment.primary} />
          <ExperimentFormShowEditName experiment={experiment} />
        </>
      )}
    </Header>
  </div>
);

export default ScreensExperimentShowTopBar;
