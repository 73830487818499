/**
 * Labstep
 *
 * @module components/ResourceTemplate/Action/Menu
 * @desc Action Menu for resourceTemplate
 */

import React from 'react';
import ActionMenu from 'labstep-web/core/Action/Menu';
import ResourceTemplateActionDelete from 'labstep-web/components/ResourceTemplate/Action/Delete';
import ResourceTemplateActionShare from 'labstep-web/components/ResourceTemplate/Action/Share';
import ResourceTemplateActionDuplicate from 'labstep-web/components/ResourceTemplate/Action/Duplicate';
import EntityActionTransferOwnership from 'labstep-web/components/Entity/Action/TransferOwnership';
import { Action, IResourceTemplateActionMenuProps } from './types';

export const ResourceTemplateActionMenu: React.FC<
  IResourceTemplateActionMenuProps
> = ({ resourceTemplate, type, actions = [], options }) => (
  <ActionMenu type={type}>
    {actions.includes(Action.share) && (
      <ResourceTemplateActionShare
        resourceTemplate={resourceTemplate}
      />
    )}
    {actions.includes(Action.duplicate) && (
      <ResourceTemplateActionDuplicate
        resourceTemplate={resourceTemplate}
      />
    )}
    {actions.includes(Action.transfer_ownership) && (
      <EntityActionTransferOwnership entity={resourceTemplate} />
    )}
    {actions.includes(Action.delete) && (
      <ResourceTemplateActionDelete
        resourceTemplate={resourceTemplate}
        options={options}
      />
    )}
  </ActionMenu>
);

ResourceTemplateActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default ResourceTemplateActionMenu;
