/**
 * Labstep
 *
 * @module core/DataGrid/CellEditor/SearchSelect
 * @desc CellEditor Search Select
 */

import { stopEditingAndFocus } from 'labstep-web/core/DataGrid/utils';
import FormShowEditSearchSelectModal from 'labstep-web/core/Form/ShowEdit/SearchSelect/Modal';
import { ParentEntityType } from 'labstep-web/core/Form/ShowEdit/SearchSelect/types';
import { callIf } from 'labstep-web/services/react.service';
import React from 'react';
import { ICellEditorSearchSelectModalProps } from './types';

export const DataGridCellEditorSearchSelectModal: React.FC<
  ICellEditorSearchSelectModalProps<ParentEntityType>
> = React.memo((params) => (
  <FormShowEditSearchSelectModal
    {...params.props}
    modalProps={{
      onClose: (): void => stopEditingAndFocus(params),
      open: true,
      viewComponent: null,
    }}
    searchProps={{
      ...params.props.searchProps,
      initialSearchParams: {
        ...params.props.searchProps?.initialSearchParams,
        search_query: params.charPress,
      },
    }}
    onChange={(value): void => {
      callIf(params.props.onChange, value);
    }}
    onDone={() => {
      stopEditingAndFocus(params);
    }}
  />
));

export default DataGridCellEditorSearchSelectModal;
