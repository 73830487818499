/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/Read
 * @desc Component to do the read of entity and fallback to placeholder
 */

import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Entity } from 'labstep-web/models/entity.model';
import ViewInline from 'labstep-web/prosemirror/components/Inline/View';
import React from 'react';
import { getReadProps } from '../utils';
import { IReadProps } from './types';

const Read: React.FC<IReadProps> = ({
  entityName,
  id,
  timestamp,
  placeholder,
  children,
}) => {
  const childrenWithMount = (
    <ReadOnMountHOC
      {...getReadProps(entityName, id, timestamp)}
      children={({
        entity,
      }: {
        entity: Entity | null;
      }): React.ReactElement =>
        entity ? children({ entity }) : placeholder || <ViewInline />
      }
    />
  );

  return timestamp ? (
    childrenWithMount
  ) : (
    <EntityReadEntityContainer entityName={entityName} id={id}>
      {({ entity }): React.ReactElement => {
        if (!entity) {
          return childrenWithMount;
        }
        if (entity.deleted_at) {
          return <ViewInline entity={entity} />;
        }
        return children({ entity });
      }}
    </EntityReadEntityContainer>
  );
};

export default Read;
