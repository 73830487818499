/**
 * Labstep
 *
 * @module prosemirror/nodes/experiment
 * @desc Experiment node
 */

import { Node as ProseMirrorNode } from 'prosemirror-model';
import { getAttrsForId } from 'labstep-web/prosemirror/nodes/utils';
import { LabstepNode } from '../types';

const key = 'experiment';

const NodeExperiment: LabstepNode = {
  key,
  spec: {
    attrs: {
      id: {},
    },
    inline: true,
    group: 'inline',
    marks: '',
    draggable: true,
    parseDOM: [{ tag: 'span', getAttrs: getAttrsForId(key) }],
    toDOM(node: ProseMirrorNode) {
      const { id } = node.attrs;
      return ['span', { [`data-${NodeExperiment.key}-id`]: id }];
    },
  },
};

export default NodeExperiment;
