/**
 * Labstep
 *
 * @module state/reducers/entity/helpers
 * @desc Helper functions for entity reducers
 */

import { Action } from 'labstep-web/models/action.model';
import { Comment as CommentModel } from 'labstep-web/models/comment.model';
import {
  addAddedEntityToItemsArray,
  addCreatedEntityToCursorItemsArray,
  addToTree,
  failCount,
  failCursor,
  failPage,
  failStatus,
  filterOutEntitiesFromItems,
  readRemoveManyToManyParent,
  readRemoveOneToManyParent,
  receiveCount,
  receiveCursor,
  receivePage,
  receiveStatus,
  removeEntityFromFolderItems,
  requestCount,
  requestCursor,
  requestPage,
  requestStatus,
  resetReadPageCache,
  updateCommentLists,
  updatePinCommentLists,
  updateResourceTemplateId,
  updateTree,
} from 'labstep-web/state/reducers/helpers';
import { LabstepReduxState } from 'labstep-web/state/types';

/**
 * Handles an action (READ, UPDATE, DELETE)
 * Defaults to add/remove entities from readingIds when approriate
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} action - Redux action
 * @param  {string} actionType - Action type (READ, CREATE, UPDATE or DELETE)
 * @param  {string} identifier - Identifier (key in the store)
 * @param  {object} args - Extra arguments
 */
const handleAction = (
  state: any,
  action: Action,
  actionType: string,
  identifier: string,
  entityName: string,
  entityNameUppercase: string,
  args: Record<string, unknown> = {},
): LabstepReduxState => {
  if (
    !action.meta ||
    !action.type ||
    action.type.includes('_RAW_OUTPUT')
  ) {
    return state;
  }

  switch (action.type) {
    case `REQUEST_${actionType}_${entityNameUppercase}`: {
      return requestStatus(state, action, identifier, args);
    }
    case `SUCCESS_${actionType}_${entityNameUppercase}`: {
      return receiveStatus(state, action, identifier, args);
    }
    case `FAIL_${actionType}_${entityNameUppercase}`: {
      return failStatus(state, action, identifier);
    }
    case `REQUEST_${actionType}_PAGE_${entityNameUppercase}`: {
      return requestPage(state, action, identifier);
    }
    case `SUCCESS_${actionType}_PAGE_${entityNameUppercase}`: {
      return receivePage(state, action, identifier);
    }
    case `FAIL_${actionType}_PAGE_${entityNameUppercase}`: {
      return failPage(state, action, identifier);
    }
    case `REQUEST_${actionType}_CURSOR_${entityNameUppercase}`: {
      return requestCursor(state, action, identifier);
    }
    case `SUCCESS_${actionType}_CURSOR_${entityNameUppercase}`: {
      return receiveCursor(state, action, identifier);
    }
    case `FAIL_${actionType}_CURSOR_${entityNameUppercase}`: {
      return failCursor(state, action, identifier);
    }
    case `REQUEST_${actionType}_COUNT_${entityNameUppercase}`: {
      return requestCount(state, action, identifier);
    }
    case `SUCCESS_${actionType}_COUNT_${entityNameUppercase}`: {
      return receiveCount(state, action, identifier);
    }
    case `FAIL_${actionType}_COUNT_${entityNameUppercase}`: {
      return failCount(state, action, identifier);
    }
    default: {
      if (actionType === 'READ') {
        // Add/Move/Delete Resource Location
        if (
          action.type === 'SUCCESS_UPDATE_RESOURCE_LOCATION' &&
          (action.meta.body.outer_location_guid ||
            action.meta.body.outer_location_guid === null ||
            action.meta.body.deleted_at)
        ) {
          return updateTree(state, action, 'outer_location');
        }
        // Create Resource Location
        if (action.type === 'SUCCESS_CREATE_RESOURCE_LOCATION') {
          return addToTree(state, action, 'outer_location');
        }
        // Add/Move/Delete Folder
        if (
          action.type === 'SUCCESS_UPDATE_FOLDER' &&
          (action.meta.body.outer_folder_id ||
            action.meta.body.outer_folder_id === null ||
            action.meta.body.deleted_at)
        ) {
          return updateTree(state, action, 'outer_folder');
        }
        // Create Folder
        if (action.type === 'SUCCESS_CREATE_FOLDER') {
          return addToTree(state, action, 'outer_folder');
        }
        // Pin/Unpin Comment > Add/Remove to Pinned Comment List
        if (
          entityName === CommentModel.entityName &&
          action.type === 'SUCCESS_UPDATE_COMMENT'
        ) {
          return updatePinCommentLists(state, action);
        }
        if (
          action.type === 'SUCCESS_CREATE_COMMENT' &&
          entityName === 'comment'
        ) {
          return updateCommentLists(state, action);
        }
        // Remove on delete
        if (
          action.type.startsWith('SUCCESS_UPDATE_') &&
          action.meta.entityName === entityName &&
          action.meta.body.deleted_at
        ) {
          return filterOutEntitiesFromItems(state, action);
        }
        // Remove resources from template
        if (
          action.type === 'SUCCESS_UPDATE_RESOURCE' &&
          action.meta.body.template_id
        ) {
          return updateResourceTemplateId(state, action);
        }
        if (
          action.type.startsWith('SUCCESS_ADD') &&
          action.meta.entityName === entityName
        ) {
          return addAddedEntityToItemsArray(state, action);
        }
        // Custom code to remove the entity from unsorted folder list
        if (
          action.type.startsWith('SUCCESS_ADD_FOLDER_') &&
          action.meta.parentName === entityName
        ) {
          return removeEntityFromFolderItems(state, action);
        }
        if (
          action.type.startsWith('SUCCESS_REMOVE') &&
          action.meta.parentName === entityName
        ) {
          return readRemoveManyToManyParent(state, action);
        }
        if (
          action.type.startsWith('SUCCESS_REMOVE') &&
          action.meta.entityName === entityName
        ) {
          return readRemoveOneToManyParent(state, action);
        }
        if (
          action.type === 'SUCCESS_CREATE_USER' &&
          entityName === 'user_organization' &&
          action.meta.url.includes('batch')
        ) {
          return resetReadPageCache(state);
        }
        if (
          action.type.startsWith('SUCCESS_CREATE') &&
          action.meta.entityName === entityName
        ) {
          if (['protocol'].includes(entityName)) {
            return state;
          }

          return addCreatedEntityToCursorItemsArray(state, action);
        }
        if (
          action.type.startsWith('SUCCESS_DELETE') &&
          action.meta.entityName === entityName
        ) {
          return filterOutEntitiesFromItems(state, action);
        }
        if (
          action.type.startsWith('SUCCESS_DELETE_PERMISSION') &&
          action.meta.permissionParentEntityName === entityName
        ) {
          return readRemoveOneToManyParent(state, action);
        }
      }
      return state;
    }
  }
};

export default handleAction;
