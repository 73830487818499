/**
 * Labstep
 *
 * @module components/ResourceItem/Edit/Template
 * @desc Edit a resource item template
 */

import Container from 'labstep-web/components/Layout/Container';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import ResourceItemActionDeleteTemplate from 'labstep-web/components/ResourceItem/Action/Delete/Template';
import ResourceItemFormShowEditAlertThreshold from 'labstep-web/components/ResourceItem/Form/ShowEdit/AlertThreshold';
import ResourceItemFormShowEditAmount from 'labstep-web/components/ResourceItem/Form/ShowEdit/Amount';
import ResourceItemResourceLocationModal from 'labstep-web/components/ResourceItem/ResourceLocationModal';
import { ICONS } from 'labstep-web/constants/icons';
import Flex from 'labstep-web/core/Flex';
import TextWithCount from 'labstep-web/core/Text/WithCount';
import { Metadata } from 'labstep-web/models/metadata';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React from 'react';
import { IResourceItemPreviewTemplateProps } from './types';

export const rows = [
  {
    header: (
      <TextWithCount
        header
        text="Initial Amount"
        icon="thermometer full"
      />
    ),
    content: (resourceItem: ResourceItem) => (
      <ResourceItemFormShowEditAmount resourceItem={resourceItem} />
    ),
  },
  {
    header: (
      <TextWithCount
        header
        text="Alert Threshold"
        icon="bell outline"
      />
    ),
    content: (resourceItem: ResourceItem) => (
      <ResourceItemFormShowEditAlertThreshold
        resourceItem={resourceItem}
      />
    ),
  },
  {
    header: (
      <TextWithCount
        header
        text="Default Location"
        icon={ICONS.resource_location.primary}
      />
    ),
    content: (resourceItem: ResourceItem) => (
      <ResourceItemResourceLocationModal
        resourceItem={resourceItem}
        hideMap
      />
    ),
  },
];

const ResourceItemPreviewTemplate: React.FC<
  IResourceItemPreviewTemplateProps
> = ({ resourceItem }) => (
  <Flex column>
    <Container>
      <div className="resource-item-metadata">
        <MetadataManager
          entity={resourceItem}
          emptyStateMessage={`Add ${Metadata.getHumanReadableEntityName()} to track properties specfic to each aliquot, batch or container. Track numeric quantities and dates, create dropdowns to select from a list of options or use file fields to store analytical reports and other important documents.`}
          isTemplate
          extraRows={rows}
        />
      </div>
    </Container>
    <ResourceItemActionDeleteTemplate resourceItem={resourceItem} />
  </Flex>
);

export default ResourceItemPreviewTemplate;
