/**
 * Labstep
 *
 * @desc Component to generate and display svg based on smiles
 */

import React from 'react';
import Lightbox from 'labstep-web/core/Lightbox';
import Image from 'labstep-web/core/Image';
import { Count } from 'labstep-web/core/Text/WithCount';
import { IChemDrawNewReactionSvgProps } from './types';
import styles from './styles.module.scss';

const ChemDrawNewReactionSvg: React.FC<
  IChemDrawNewReactionSvgProps
> = ({ svgs, size = 50 }) => {
  const src = svgs.map((svg) =>
    URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' })),
  );
  // useEffect(() => {
  //   import(
  //     'labstep-web/components/Molecule/Action/FetchMetadata/utils'
  //   ).then((module) => {
  //     Promise.all(
  //       smiles.map((smile) => module.generateImage(smile, 'png')),
  //     ).then((blobs) => {
  //       setSrc(blobs.map(URL.createObjectURL));
  //     });
  //   });
  // }, [smiles]);
  if (src.length === 0) {
    return null;
  }
  return (
    <Lightbox
      images={src.map((s) => ({
        src: s,
        // caption: svgs[index],
      }))}
      wrapperClassName={styles.wrapperClassName}
    >
      {({ toggle }) => (
        <div
          onClick={toggle}
          style={{
            position: 'relative',
            height: size,
            width: size,
            cursor: 'pointer',
          }}
        >
          <Image src={src[0]} style={{ height: size, width: size }} />
          {svgs.length > 1 && (
            <div
              style={{ position: 'absolute', bottom: 0, right: 0 }}
            >
              <Count count={svgs.length} />
            </div>
          )}
        </div>
      )}
    </Lightbox>
  );
};

export default ChemDrawNewReactionSvg;
