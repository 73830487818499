/**
 * Labstep
 *
 * @module components/ProtocolValue/Action/AmountDeductedStatus
 * @desc Shows if amount has been deducted
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import { IProtocolValueActionAmountDeductedStatusProps } from './types';

const ProtocolValueActionAmountDeductedStatus: React.FC<
  IProtocolValueActionAmountDeductedStatusProps
> = ({ protocolValue, showNotSpecifiedIfApplicable }) => {
  if (protocolValue.is_output || protocolValue.is_variable) {
    return null;
  }
  if (protocolValue.amount_deducted_at) {
    return (
      <Icon
        name="check circle"
        color="green"
        popup={{ content: 'Amount deducted from inventory' }}
      />
    );
  }
  if (!protocolValue.resourceItem) {
    if (showNotSpecifiedIfApplicable) {
      return (
        <Icon
          name="warning sign"
          color="yellow"
          popup={{ content: 'Item not specified' }}
        />
      );
    }
    return null;
  }
  return (
    <Icon
      name="warning sign"
      color="yellow"
      popup={{ content: 'Amount not deducted from inventory' }}
    />
  );
};

export default ProtocolValueActionAmountDeductedStatus;
