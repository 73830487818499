/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/Code
 * @desc Renders a CodeMirror node
 */

import React from 'react';
import CoreCodeMirror from 'labstep-web/core/CodeMirror';
import { useReactNodeView } from 'labstep-web/prosemirror/components/ReactNodeView';
import { CodeNodeProps } from './types';

export class CodeNode extends React.Component<CodeNodeProps> {
  shouldComponentUpdate() {
    return Boolean(!this);
  }

  render() {
    const { mode, value } = this.props;
    return (
      <div contentEditable={false}>
        <CoreCodeMirror readOnly mode={mode} value={value} />
      </div>
    );
  }
}

const CodeNodeView = () => {
  const context = useReactNodeView();
  if (!context.node) {
    return null;
  }
  const { mode, value } = context.node.attrs;

  return <CodeNode mode={mode} value={value} />;
};

export default CodeNodeView;
