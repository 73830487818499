/**
 * Labstep
 *
 * @module components/EntityImport/Action/Create/Steps/FieldDefinitions
 * @desc EntityImport Create Step 3: FieldDefinitions
 */

import { ICONS } from 'labstep-web/constants/icons';
import Header from 'labstep-web/core/Header';
import Label from 'labstep-web/core/Label';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import SearchSelect from 'labstep-web/core/Select/Search';
import Table from 'labstep-web/core/Table';
import TextWithIcon from 'labstep-web/core/Text/WithIcon';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import { Device } from 'labstep-web/models/device.model';
import { EntityImportColDef } from 'labstep-web/models/entity-import.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import { AGGridEntityImportExcelService } from 'labstep-web/services/agGrid/ag-grid-entity-import-excel.service';
import { AGGridEntityImportService } from 'labstep-web/services/agGrid/ag-grid-entity-import.service';
import { selectEntity } from 'labstep-web/state/selectors/entity';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EntityImportActionCreateStepsFieldDefinitionsFields from './Fields';
import styles from './styles.module.scss';
import {
  IEntityImportActionCreateStepsFieldDefinitionsContainerProps,
  IEntityImportActionCreateStepsFieldDefinitionsProps,
} from './types';

export const EntityImportActionCreateStepsFieldDefinitions: React.FC<
  IEntityImportActionCreateStepsFieldDefinitionsProps
> = ({
  entityName,
  index,
  onContinue,
  importData,
  nameColumn,
  headerRowIndex,
  template,
}) => {
  const { activeGroup } = useActiveGroup();
  const [columnDefs, setColumnDefs] = useState<EntityImportColDef[]>(
    [],
  );
  const [headerMap, setHeaderMap] = useState<{
    [key: string]: string | null;
  }>({});
  const [selectedTemplate, setSelectedTemplate] = React.useState<
    Resource | Device | null
  >(template || null);

  const selectedTemplateEntity = useSelector((state) =>
    selectedTemplate
      ? selectEntity(
          state,
          selectedTemplate.entityName,
          selectedTemplate.id,
        )
      : null,
  );

  const [nFieldsAdded, setNFieldsAdded] = useState(0);

  useEffect(() => {
    if (!selectedTemplate) {
      setNFieldsAdded(0);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedTemplate) {
      const columnDefsTemplate =
        AGGridEntityImportService.getColumnDefsTemplate(
          entityName,
          selectedTemplate,
        );
      if (importData) {
        setHeaderMap(
          AGGridEntityImportExcelService.matchColumnNames(
            importData,
            columnDefsTemplate,
            nameColumn,
            headerRowIndex,
            entityName === ResourceItem.entityName
              ? 'resource_item_resource'
              : 'name',
          ),
        );
      }
      setColumnDefs(columnDefsTemplate);
    }
  }, [
    selectedTemplate,
    nameColumn,
    headerRowIndex,
    entityName,
    importData,
  ]);

  useEffect(() => {
    if (selectedTemplateEntity) {
      const columnDefsTemplate =
        AGGridEntityImportService.getColumnDefsTemplate(
          entityName,
          selectedTemplateEntity,
        );
      setColumnDefs(columnDefsTemplate);
    }
  }, [selectedTemplateEntity]);

  return (
    <ModalWizard.Step
      index={index}
      onContinue={({ goForward, setErrorMessages }) => {
        if (!selectedTemplateEntity) {
          setErrorMessages(['Please select a template to continue']);
          return;
        }
        if (
          Object.values(headerMap).some((value) => value === null)
        ) {
          setErrorMessages(['Please link all columns to continue']);
          return;
        }
        onContinue(selectedTemplateEntity, headerMap);
        goForward();
      }}
      description="Define the structure of your fields and specify the type of information they will hold. This will generate a template of fields that will be associated with the Category."
      help={
        <div className={styles.help}>
          {entityName === Device.entityName ? (
            <div>
              <Header size="tiny">What is a Device Template?</Header>
              <p>
                What is a Device Template? A device template defines
                the metadata fields you want to track for the devices
                in this category. They can either be static or
                variable.
              </p>
              <p>
                e.g. <b>Manufacturer</b>, <b>Model Number</b>,{' '}
                <b>Serial Number</b>, <b>User Manual</b> will be
                always remain the same.
              </p>
              <p>
                e.g. <b>Last Calibration Date</b>,{' '}
                <b>Next Service Date</b> will be updated over time.
              </p>
            </div>
          ) : (
            <>
              <div>
                <Header size="tiny">
                  What is a Resource Template?
                </Header>
                <p>
                  Resource templates define fields shared by multiple
                  functionally identical items.
                </p>
                <p>
                  e.g. <b>Vendor</b>, <b>Catalog No</b> will be shared
                  by all items of the same product.
                </p>
                <p>
                  e.g. <b>Sequence</b>, <b>Tm</b> will be shared by
                  all batches of the same primer.
                </p>
              </div>
              <div>
                <Header size="tiny">What is an Item Template?</Header>
                <p>
                  Item templates define fields that vary depending on
                  the specific batch or aliquot you have in the lab.
                </p>
                <p>
                  e.g. <b>Expiry Date</b> or <b>Lot No</b> may be
                  different for each item of the same product.
                </p>
                <p>
                  e.g. <b>Concentration</b> may be different for each
                  batch of the same primer.
                </p>
              </div>
            </>
          )}
        </div>
      }
    >
      <div className={styles.content}>
        <Table basic="very" singleLine>
          <Table.Body>
            <Table.Row>
              <Table.Cell width="2">
                <TextWithIcon
                  icon={ICONS.resource_template.primary}
                  text="Category"
                />
              </Table.Cell>
              <Table.Cell width="3">
                <SearchSelect
                  creatable
                  value={selectedTemplate}
                  entityName={
                    entityName === ResourceItem.entityName
                      ? Resource.entityName
                      : entityName
                  }
                  onChange={(newValue: Resource) =>
                    setSelectedTemplate(newValue)
                  }
                  createBody={{
                    is_template: 1,
                  }}
                  params={{
                    is_template: 1,
                    group_id: activeGroup?.id,
                  }}
                />
              </Table.Cell>
              <Table.Cell width="7" textAlign="right">
                {nFieldsAdded > 0 ? (
                  <Label basic color="blue">
                    <TextWithIcon
                      icon={ICONS.metadata.secondary}
                      text={`${nFieldsAdded} new ${
                        nFieldsAdded > 1 ? 'fields have' : 'field has'
                      } been added`}
                    />
                  </Label>
                ) : null}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        {selectedTemplate && (
          <EntityImportActionCreateStepsFieldDefinitionsFields
            selectedTemplate={selectedTemplateEntity}
            importData={importData}
            entityName={entityName}
            headerRowIndex={headerRowIndex}
            headerMap={headerMap}
            setHeaderMap={setHeaderMap}
            columnDefs={columnDefs}
            setNFieldsAdded={setNFieldsAdded}
          />
        )}
      </div>
    </ModalWizard.Step>
  );
};

export const EntityImportActionCreateStepsFieldDefinitionsContainer: React.FC<
  IEntityImportActionCreateStepsFieldDefinitionsContainerProps
> = ({ templateId, ...rest }) => {
  if (!templateId) {
    return (
      <EntityImportActionCreateStepsFieldDefinitions {...rest} />
    );
  }
  return (
    <ReadOnMountHOC
      type="entities"
      entityName={
        rest.entityName === ResourceItem.entityName
          ? Resource.entityName
          : rest.entityName
      }
      params={{
        id: templateId,
        get_single: 1,
      }}
      loading={{ loader: false }}
      children={({ entity: template }) => (
        <EntityImportActionCreateStepsFieldDefinitions
          {...rest}
          template={template}
        />
      )}
    />
  );
};

export default EntityImportActionCreateStepsFieldDefinitionsContainer;
