/**
 * Labstep
 *
 * @module components/UserGroup/Action/LeaveWorkspace
 * @desc Action to leave a workspace
 */

import UserGroupActionDelete from 'labstep-web/components/UserGroup/Action/Delete';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { UserGroup } from 'labstep-web/models/user-group.model';
import React from 'react';
import { UserGroupActionLeaveWorkspaceProps } from './types';

export const UserGroupActionLeaveWorkspace: React.FC<
  UserGroupActionLeaveWorkspaceProps
> = ({ group, authenticatedUserEntity }) => (
  <ReadOnMountHOC
    type="entities"
    loading={false}
    entityName={UserGroup.entityName}
    params={{
      get_single: 1,
      user_id: authenticatedUserEntity.id,
      group_id: group.id,
    }}
    children={({ entity: userGroup }) => {
      if (!userGroup) {
        return null;
      }
      if (userGroup.type !== 'owner') {
        return (
          <UserGroupActionDelete
            userGroup={userGroup}
            type="button"
          />
        );
      }
      return null;
    }}
  />
);

export default withAuthenticatedUserEntity(
  UserGroupActionLeaveWorkspace,
);
