/**
 * Labstep
 *
 * @desc Message show edit for notification alert
 */

import React from 'react';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import { fieldNotificationAlertMinutesBefore } from 'labstep-web/components/NotificationAlert/Form/fields';
import { INotificationAlertFormShowEditMinutesBeforeProps } from './types';

export const NotificationAlertFormShowEditMinutesBefore: React.FC<
  INotificationAlertFormShowEditMinutesBeforeProps
> = ({ notificationAlert, ...rest }) => (
  <ShowEditText
    entityName={notificationAlert.entityName}
    entity={notificationAlert}
    field={fieldNotificationAlertMinutesBefore}
    {...rest}
  >
    {notificationAlert.minutes_before}
  </ShowEditText>
);

export default NotificationAlertFormShowEditMinutesBefore;
