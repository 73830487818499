/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Code/Action/Delete
 * @desc Button to delete a code mark
 */

import React from 'react';
import Button from 'labstep-web/core/Button';
import Icon from 'labstep-web/core/Icon';
import { IMenuCodeActionDeleteProps } from './types';

const MenuCodeActionDelete: React.FC<IMenuCodeActionDeleteProps> = ({
  state,
  dispatch,
}) => (
  <Button
    onMouseDown={(e) => {
      e.preventDefault();
      dispatch?.(state.tr.deleteSelection());
    }}
  >
    <Icon name="trash" />
    <span>Delete</span>
  </Button>
);

export default MenuCodeActionDelete;
