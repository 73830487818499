/**
 * Labstep
 *
 * @module components/ProtocolValue/Variable/Action/SetItems/Container
 * @desc ProtocolValue Variable Action Set Items Container
 */

import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React from 'react';
import { IProtocolValueVariableActionSetItemsContainerProps } from './types';

export const ProtocolValueVariableActionSetItemsContainer: React.FC<
  IProtocolValueVariableActionSetItemsContainerProps
> = ({ children }) => (
  <EntityCreateContainer
    batch
    entityName={ProtocolCondition.entityName}
  >
    {({
      create: createProtocolCondition,
      status: createProtocolConditionStatus,
    }): React.ReactElement => (
      <EntityCreateContainer
        batch
        entityName={ProtocolValue.entityName}
      >
        {({
          create: createProtocolValue,
          status: createProtocolValueStatus,
        }) => {
          const disabled = [
            createProtocolConditionStatus,
            createProtocolValueStatus,
          ].some((s) => s?.isFetching);
          return children({
            createProtocolValue,
            createProtocolCondition,
            disabled,
          });
        }}
      </EntityCreateContainer>
    )}
  </EntityCreateContainer>
);

export default ProtocolValueVariableActionSetItemsContainer;
