/**
 * Labstep
 *
 * @module components/EntityImport/Action/ImportExcel
 * @desc User can select an excel file from its file system and import it into AG Grid
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import Dropzone from 'labstep-web/core/Dropzone';
import { AGGridEntityImportExcelService } from 'labstep-web/services/agGrid/ag-grid-entity-import-excel.service';
import { IEntityImportActionImportExcelProps } from './types';

export const EntityImportActionImportExcel: React.FC<
  IEntityImportActionImportExcelProps
> = ({ onImport, targetEntityName, ...rest }) => {
  const agGridEntityImportExcelService =
    new AGGridEntityImportExcelService(onImport);

  return (
    <Dropzone
      accept={['.xlsx', '.csv']}
      onDropAccepted={(files: File[]): void =>
        agGridEntityImportExcelService?.importFile(files[0])
      }
      children={
        <ActionComponent
          type="button"
          text="Upload XLSX / CSV"
          elementProps={{
            basic: true,
          }}
        />
      }
      {...rest}
    />
  );
};

export default EntityImportActionImportExcel;
