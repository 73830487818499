/**
 * Labstep
 *
 * @module components/Filter/List/Header
 * @desc Header for list of filter items
 */

import FilterActionToggleCollapse from 'labstep-web/components/Filter/Action/ToggleCollapse';
import { ICONS } from 'labstep-web/constants/icons';
import { withUiPersistent } from 'labstep-web/containers/UiPersistent';
import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import Link from 'labstep-web/core/Link';
import React from 'react';
import styles from './styles.module.scss';
import { IFilterListHeaderProps } from './types';

export const FilterListHeader: React.FC<IFilterListHeaderProps> = ({
  filter,
  header,
  route,
  toggleUiFilter,
}) => (
  <div className={styles.headerContainer}>
    <Header
      size="tiny"
      onClick={() => toggleUiFilter(filter.entityName, filter.name)}
    >
      {header}
    </Header>
    <div>
      {route && (
        <Link unstyled {...route}>
          <Icon name={ICONS.navigation.settings} />
        </Link>
      )}
      <FilterActionToggleCollapse filter={filter} />
    </div>
  </div>
);

export default withUiPersistent(FilterListHeader);
