/**
 * Labstep
 *
 * @module screens/User/Blocked
 * @desc Screen showing deleted user message
 */

import React from 'react';
import UserActionSignout from 'labstep-web/components/User/Action/Signout';
import LayoutFullScreenPage from 'labstep-web/components/Layout/FullScreenPage';

export const ScreensUserBlocked: React.FC = () => (
  <LayoutFullScreenPage>
    <p>
      You are blocked from accessing this account using your current
      IP address. Talk to your organization admin to find out which IP
      adressess are allowed.
    </p>

    <br />

    <UserActionSignout />
  </LayoutFullScreenPage>
);

export default ScreensUserBlocked;
