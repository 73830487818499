/**
 * Labstep
 *
 * @module components/Folder/Action/Create
 * @desc Create Action for folder
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import FolderFormCreate from 'labstep-web/components/Folder/Form/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { ParamsHOC } from 'labstep-web/hoc/Params';
import { ParamsContext } from 'labstep-web/hoc/Params/context';
import { Folder } from 'labstep-web/models/folder.model';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import React from 'react';
import { IFolderActionCreateProps } from './types';

export const FolderActionCreate: React.FC<
  IFolderActionCreateProps
> = ({ folderType, options }) => (
  <CanCreate entityName="folder">
    <ModalDefault
      header={`Create new ${Folder.getHumanReadableEntityName()}`}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          onClick={toggleModal}
          icon="plus"
          text={`New ${Folder.getHumanReadableEntityName(
            false,
            true,
          )}`}
          type="text"
        />
      )}
      content={({ toggleModal }) => (
        <FolderFormCreate
          type={folderType}
          options={enhanceOptions({
            options,
            onSuccess: toggleModal,
          })}
        />
      )}
    />
  </CanCreate>
);

const FolderActionCreateContainer: React.FC<
  IFolderActionCreateProps
> = (props) => (
  <ParamsHOC historyAction="replace">
    <ParamsContext.Consumer>
      {({ setParams }) => (
        <FolderActionCreate
          {...props}
          options={{
            onSuccess: ({ response }) =>
              setParams({ folder_id: response.result }),
          }}
        />
      )}
    </ParamsContext.Consumer>
  </ParamsHOC>
);

export default FolderActionCreateContainer;
