/**
 * Labstep
 *
 * @desc Protocol Condition DataGrid
 */

import React from 'react';
import Loadable from 'labstep-web/core/Loadable';
import { IResourceDataGridContainerProps } from './types';

export const ResourceDataGridContainerComponent = React.lazy(
  () => import('./component'),
);

const ResourceDataGridContainer: React.FC<
  IResourceDataGridContainerProps
> = (props) => (
  <Loadable>
    <ResourceDataGridContainerComponent {...props} />
  </Loadable>
);

export default ResourceDataGridContainer;
