/**
 * Labstep
 *
 * @module components/Layout/Links
 * @desc Navlinks + Switch
 */

import React from 'react';
import classnames from 'classnames';
import Switch from 'labstep-web/core/Switch';
import NavLink from 'labstep-web/core/NavLink';
import PremiumFeatureLockIcon from 'labstep-web/core/PremiumFeature/LockIcon';
import styles from './styles.module.scss';
import { ILayoutLinksProps } from './types';

export const LayoutLinks: React.FC<ILayoutLinksProps> = ({
  links,
  inverted,
  fluid,
  stretch,
  noBottomMargin,
}) => (
  <div className={styles.container}>
    <div
      className={classnames(styles.linksContainer, {
        [styles.fluid]: fluid,
        [styles.noBottomMargin]: noBottomMargin,
      })}
    >
      {links.map((link, index) => (
        <NavLink
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          exact
          className={classnames(styles.link, {
            [styles.inverted]: inverted,
            [styles.stretch]: stretch,
          })}
          to={link.route.to}
          params={link.route.params}
          query={link.route.query}
        >
          {link.children}
          {link.premiumFeature && (
            <PremiumFeatureLockIcon
              premiumFeature={link.premiumFeature}
            />
          )}
        </NavLink>
      ))}
      {inverted && !stretch && <div className={styles.barrier} />}
    </div>
    <Switch
      links={links.map((link) => ({
        render: link.render,
        route: { to: link.route.to },
      }))}
    />
  </div>
);

export default LayoutLinks;
