/**
 * Labstep
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const fieldNotificationAlertMessage: IFieldWithoutActionProps =
  {
    fieldLabel: 'Message',
    name: 'message',
    fieldType: FieldType.TextArea,
    validation: rules.notification_alert.message,
    placeholder: 'Enter Message',
  };

export const fieldNotificationAlertMinutesBefore: IFieldWithoutActionProps =
  {
    name: 'minutes_before',
    fieldType: FieldType.Text,
    validation: rules.notification_alert.minutes_before,
    placeholder: 'Enter minutes',
  };
