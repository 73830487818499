/**
 * Labstep
 */

import colDefStatus from 'labstep-web/components/Entity/DataGrid/colDefs/status';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import { ResourceItem } from 'labstep-web/models/resource-item.model';

const colDefResourceItemStatus: ColDef<ResourceItem> = colDefStatus(
  ResourceItem.entityName,
);

export default colDefResourceItemStatus;
