/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/Primary
 * @desc Primary filters for experiment workflow
 */

import { FilterList } from 'labstep-web/components/Filter/List';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import Icon from 'labstep-web/core/Icon';
import { SignedTypes } from 'labstep-web/models/experiment-workflow.model';
import React from 'react';
import { IExperimentWorkflowFilterPrimaryListProps } from './types';

export const ExperimentWorkflowFilterPrimaryList: React.FC<
  IExperimentWorkflowFilterPrimaryListProps
> = ({ authenticatedUserEntity }) => (
  <div>
    <FilterList
      filters={[
        {
          component: <Icon name="user" />,
          text: 'Created by me',
          params: {
            author_id: String(authenticatedUserEntity.id),
          },
        },
        {
          component: <Icon name="user plus" />,
          text: 'Assigned to me',
          params: {
            assignee_id: String(authenticatedUserEntity.id),
          },
        },
        {
          component: <Icon name={SignedTypes.unsigned.icon} />,
          text: 'For me to sign',
          params: {
            has_signature: undefined,
            has_signature_request: undefined,
            signature_request_user_id: String(
              authenticatedUserEntity.id,
            ),
          },
        },
      ]}
    />
  </div>
);

export default withAuthenticatedUserEntity(
  ExperimentWorkflowFilterPrimaryList,
);
