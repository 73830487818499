/**
 * Labstep
 *
 * @module screens/ResourceTemplate/Index
 * @desc ResourceTemplate index
 */

import EntitySearch from 'labstep-web/components/Entity/Search';
import ResourceTemplateActionCreate from 'labstep-web/components/ResourceTemplate/Action/Create';
import ResourceTemplateEmptyState from 'labstep-web/components/ResourceTemplate/Card/EmptyState';
import ResourceTemplateTable from 'labstep-web/components/ResourceTemplate/Table';
import { Resource } from 'labstep-web/models/resource.model';
import React, { useMemo } from 'react';
import { bulkActions, filters } from './constants';
import { IResourceTemplateIndexProps } from './types';

export const ResourceTemplateIndex: React.FC<
  IResourceTemplateIndexProps
> = ({ group }) => {
  const params = useMemo(
    () => ({ group_id: group.id, is_template: 1 }),
    [group.id],
  );
  const action = useMemo(() => <ResourceTemplateActionCreate />, []);
  return (
    <EntitySearch
      params={{
        ...params,
        is_template: 1,
      }}
      noResultsMessage={<ResourceTemplateEmptyState />}
      action={action}
      entityName={Resource.entityName}
      bulkActions={bulkActions}
      filters={filters}
    >
      {({ entities, ...rest }): React.ReactElement => (
        <ResourceTemplateTable
          resourceTemplates={entities}
          {...rest}
        />
      )}
    </EntitySearch>
  );
};

export default ResourceTemplateIndex;
