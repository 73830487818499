/**
 * Labstep
 *
 * @module components/Resource/Action/ShareLink
 * @desc Share a resource with all users on the platform
 */

import React from 'react';
import EntityActionShare from 'labstep-web/components/Entity/Action/Share';
import { IResourceActionShareProps } from './types';

export const ResourceActionShare: React.FC<
  IResourceActionShareProps
> = ({ resource }) => <EntityActionShare entity={resource} />;

export default ResourceActionShare;
