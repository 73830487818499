/**
 * Labstep
 *
 * @module components/ResourceItem/Preview/StockCheck
 * @desc Stock check Preview for a Resource Item
 */

import ResourceItemPreview from 'labstep-web/components/ResourceItem/Preview';
import { ICONS } from 'labstep-web/constants/icons';
import Card from 'labstep-web/core/Card/Card';
import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { IResourceItemPreviewStockCheckProps } from './types';

export const ResourceItemPreviewStockCheck: React.FC<
  IResourceItemPreviewStockCheckProps
> = ({ resourceItem, additionalActions, header, error }) => (
  <div className={styles.actionContainer}>
    {header && (
      <div className={styles.header}>
        <Header size="xsmall" color={error ? 'red' : undefined}>
          {header}
        </Header>
      </div>
    )}
    <Card>
      <ResourceItemPreview
        resourceItem={resourceItem}
        showIcon
        showResource
        action={
          <Icon
            name={ICONS.resource_item.stock_check}
            color={error ? 'yellow' : 'green'}
            size="big"
          />
        }
      />
    </Card>
    <div>{additionalActions}</div>
  </div>
);

export default ResourceItemPreviewStockCheck;
