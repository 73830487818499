/**
 * Labstep
 *
 * @module models/sequence
 * @desc Typescript export class for Sequence
 */

import { Entity } from 'labstep-web/models/entity.model';
import { pick } from 'lodash';

export type SequenceCreateBodyType = { name: string; data: string };

export class Sequence extends Entity {
  public static readonly entityName = 'sequence';

  public get entityName(): string {
    return Sequence.entityName;
  }

  public constructor(data: Partial<Sequence> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public data!: string;

  public name!: string;

  public static get createBodyDefault(): SequenceCreateBodyType {
    return {
      name: 'Untitled Sequence',
      data: JSON.stringify({
        circular: true,
        sequence: null,
      }),
    };
  }

  public get createBody(): SequenceCreateBodyType {
    return pick(
      this,
      Object.keys(Sequence.createBodyDefault),
    ) as SequenceCreateBodyType;
  }

  // Transforming data for sequence editor
  public get sequenceData(): Record<string, unknown> {
    return {
      ...JSON.parse(this.data),
      name: this.name,
    };
  }
}
