/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/Plan
 * @desc Organization plan card for plan
 */

import OrganizationPlanCard from 'labstep-web/components/OrganizationPlan/Card';
import {
  ADDONS,
  premiumFeaturesInfo,
} from 'labstep-web/constants/premium-features';
import React from 'react';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { PlanTier } from 'labstep-web/constants/plans';
import styles from './styles.module.scss';
import { OrganizationPlanAddonsProps } from './types';

export const OrganizationPlanAddons: React.FC<
  OrganizationPlanAddonsProps
> = ({ organization }) => {
  const addons = ADDONS.filter(
    (addon) => organization.premium_features?.includes(addon),
  ).map((addon) => {
    return (
      <div
        key={addon}
        data-testid="addon-label"
        className={styles.label}
      >
        <div>{premiumFeaturesInfo[addon].name}</div>
      </div>
    );
  });

  const placeholderText =
    organization.tier !== PlanTier.Pro &&
    organization.tier !== PlanTier.Ultimate
      ? ' Add-ons are only available in Professional and Ultimate plans'
      : 'No Add-ons';

  return (
    <OrganizationPlanCard
      icon="puzzle"
      header="Modules & Add-ons"
      secondary={
        <div className={styles.container}>
          {addons.length ? (
            addons
          ) : (
            <TextPlaceholder>
              <i>{placeholderText}</i>
            </TextPlaceholder>
          )}
        </div>
      }
    />
  );
};

export default OrganizationPlanAddons;
