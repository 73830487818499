/**
 * Labstep
 *
 * @module components/PurchaseOrder/Form/ShowEdit/Discount
 * @desc ShowEdit form for order request currency and handling
 */

import React from 'react';
import ShowEditAmount from 'labstep-web/core/Form/ShowEdit/Amount';
import { fieldPurchaseOrderDiscountAmount } from 'labstep-web/components/PurchaseOrder/Form/fields';
import { IPurchaseOrderFormShowEditDiscountProps } from './types';

export const PurchaseOrderFormShowEditDiscount: React.FC<
  IPurchaseOrderFormShowEditDiscountProps
> = ({ purchaseOrder }) => (
  <ShowEditAmount
    entity={purchaseOrder}
    entityName={purchaseOrder.entityName}
    field={fieldPurchaseOrderDiscountAmount}
    currency={purchaseOrder.currency}
  >
    {purchaseOrder.printDiscount}
  </ShowEditAmount>
);

export default PurchaseOrderFormShowEditDiscount;
