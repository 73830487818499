/**
 * Labstep
 *
 * @module components/Organization/Settings/Mfa
 * @desc Updated mfa organization settings
 */

import React from 'react';
import Checkbox from 'labstep-web/core/Checkbox';
import PremiumFeatureToggle from 'labstep-web/components/Organization/PremiumFeatureToggle';
import { IOrganizationSettingsMfaProps } from './types';

export const OrganizationSettingsMfa: React.FC<
  IOrganizationSettingsMfaProps
> = ({ organization }) => {
  if (!organization.hasFeatureEnabled('2fa')) {
    return <PremiumFeatureToggle premiumFeature="2fa" />;
  }
  if (organization.is_saml_enabled) {
    return (
      <p>
        {organization.name} has enabled Saml SSO. Please use your SAML
        provider for two factor authentication.
      </p>
    );
  }
  return <Checkbox toggle checked disabled />;
};

export default OrganizationSettingsMfa;
