/**
 * Labstep
 */

import React, { createContext, useContext } from 'react';
import { IParamsHOCChildrenProps } from './types';

export interface ParamsContextType {
  searchParams: IParamsHOCChildrenProps['searchParams'];
  setParams: IParamsHOCChildrenProps['setParams'];
  addPostFilter: IParamsHOCChildrenProps['addPostFilter'];
  clearAll: IParamsHOCChildrenProps['clearAll'];
  clearParams: IParamsHOCChildrenProps['clearParams'];
  setPostFilterType: IParamsHOCChildrenProps['setPostFilterType'];
  removePostFilter: IParamsHOCChildrenProps['removePostFilter'];
}

export const defaultParamsContext: ParamsContextType = {
  searchParams: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setParams: (): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addPostFilter: (): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearAll: (): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearParams: (): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPostFilterType: (): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removePostFilter: (): void => {},
};

/**
 * Context for params to access searchParams, setParams and addPostFilter in children
 * without passing props down multiple levels.
 * TODO: Remove props passing completely and use context instead.
 */
export const ParamsContext = createContext<ParamsContextType>(
  defaultParamsContext,
);

export const useParamsContext = (): ParamsContextType => {
  const context = useContext(ParamsContext);
  return context;
};

export const ParamsContextProvider: React.FC<{
  value: ParamsContextType;
}> = ({ value, children }) => {
  return <ParamsContext.Provider value={value} children={children} />;
};

export interface ParamsStateContextType {
  persistEntity: string | undefined;
}

/**
 * Helper context to define if entity parameters should be persisted in Redux
 * This is because we can't store POST filter objects in history state
 */
export const ParamsStateContext =
  createContext<ParamsStateContextType>({
    persistEntity: undefined,
  });

export const useParamsStateContext = ():
  | ParamsStateContextType
  | undefined => {
  const context = useContext(ParamsStateContext);
  return context;
};

export const ParamsStateContextProvider: React.FC<{
  value: ParamsStateContextType;
}> = ({ value, children }) => {
  return (
    <ParamsStateContext.Provider value={value} children={children} />
  );
};
