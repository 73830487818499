/**
 * Labstep
 *
 * @module components/ResourceItemExport/Table/Columns/Add
 * @desc Action to add new field to resource item export table
 */

import React from 'react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import Dropdown from 'labstep-web/core/Dropdown';
import Icon from 'labstep-web/core/Icon';
import Button from 'labstep-web/core/Button';
import ActionComponent from 'labstep-web/core/Action/Component';
import { IResourceItemExportActionAddColumnProps } from './types';

export const ResourceItemExportActionAddColumn: React.FC<
  IResourceItemExportActionAddColumnProps
> = ({ fields, entityNames, onClick }) => {
  return (
    <Dropdown
      pointing="top right"
      icon={null}
      trigger={
        <Button icon>
          <Icon name="plus" />
        </Button>
      }
    >
      {entityNames.map((entityName) => {
        const fieldsByEntityName = fields.filter(
          (field) => field.entityName === entityName,
        );
        if (fieldsByEntityName.length > 0) {
          const dropdownHeader = (
            <Dropdown.Header
              key={0}
              content={`${getHumanReadableEntityName(
                entityName,
                false,
                true,
              )} Fields`}
            />
          );
          const dropdownItems = fieldsByEntityName.map(
            (field, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Dropdown.Item key={index + 1}>
                <ActionComponent
                  type="option"
                  icon={field.icon}
                  text={field.name}
                  onClick={() => onClick(field)}
                />
              </Dropdown.Item>
            ),
          );
          return [dropdownHeader, ...dropdownItems];
        }
        return null;
      })}
    </Dropdown>
  );
};

export default ResourceItemExportActionAddColumn;
