/**
 * Labstep
 */

import { routing } from 'labstep-web/services/navigation';
import { IRouting } from 'labstep-web/services/navigation.types';
import { matchPath } from 'react-router';

type IRoute = keyof IRouting;

/**
 * Route tuples
 */
const ROUTES_TO_TRACK: IRoute[][] = [
  ['experiment_show', 'experiment_workflow_show'],
];

/**
 *
 * @description Turns pathname into {entityName}_{id} string
 */
export const getKeyFromPathname = (pathname: string): string => {
  const parts = pathname.split('/');
  const key = `${parts[1]}_${parts[2]}`;
  return key;
};

/**
 *
 * @description computes matchPath with non strict options
 */
export const getIsMatchPath = (
  pathname: string,
  route: IRoute,
): boolean =>
  !!matchPath(pathname, {
    path: routing[route]({} as any),
    strict: false,
    exact: false,
  });

/**
 *
 * @description computes if user navigated between the two pathnames provided
 */
export const getHasRouteSwitchedBetweenDesiredRoutes = (
  prevPathname: string,
  nextPathname: string,
): boolean =>
  !!ROUTES_TO_TRACK.reduce((result, routePair) => {
    if (result) {
      return result;
    }

    return (
      (getIsMatchPath(prevPathname, routePair[0]) &&
        getIsMatchPath(nextPathname, routePair[1])) ||
      (getIsMatchPath(prevPathname, routePair[1]) &&
        getIsMatchPath(nextPathname, routePair[0]))
    );
  }, false);

/**
 *
 * @description computes if pathname is in ROUTES_TO_TRACK array
 */
export const getIsPathnameInDesiredRoutes = (
  pathname: string,
): boolean =>
  !!ROUTES_TO_TRACK.find((routePair) => {
    return routePair.find((route) => getIsMatchPath(pathname, route));
  });
