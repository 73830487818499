/**
 * Labstep
 *
 * @module core/Select/Search/Modal
 * @desc An search select component in modal
 */

import ModalDefault from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import Flex from 'labstep-web/core/Flex';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import Mobile from 'labstep-web/core/MediaQuery/Mobile';
import React, { useCallback, useState } from 'react';
import styles from './styles.module.scss';
import SearchSelectModalContent from './Content';
import SearchSelectModalViewComponent from './ViewComponent';
import {
  ISearchSelectModalContainerProps,
  ISearchSelectModalProps,
} from './types';

export const SearchSelectModal: React.FC<ISearchSelectModalProps> = ({
  creatable,
  createBody,
  createAction,
  searchProps,
  hasValue,
  children,
  canEdit,
  status,
  actionComponentProps,
  modalProps,
  columnDefs,
  onChange,
  rowSelection,
  requiredCount,
  entityName,
  onDone: onDoneProp,
  isMobile,
  ...rest
}) => {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const onDone = useCallback(() => {
    onDoneProp?.();
    if (!selectedRows.length) {
      return;
    }
    if (rowSelection === 'multiple') {
      onChange(selectedRows);
    } else {
      onChange(selectedRows[0]);
    }
  }, [onChange, rowSelection, selectedRows, onDoneProp]);

  const onSelectionChanged = useCallback((event) => {
    setSelectedRows(event.api.getSelectedRows());
  }, []);

  return (
    <ModalDefault
      dataTestId="modal-search-select"
      size={isMobile ? 'fullscreen' : 'large'}
      closeIcon={false}
      footer={({ toggleModal }) => (
        <Flex grow hAlignContent="right" vAlignContent="center">
          {rowSelection === 'multiple' && (
            <div className={styles.count}>
              {requiredCount
                ? `${selectedRows.length} / ${requiredCount}`
                : selectedRows.length}{' '}
              {getHumanReadableEntityName(entityName, true)} selected
            </div>
          )}
          <ActionComponent
            type="button"
            text="Done"
            status={status}
            disabled={
              requiredCount
                ? selectedRows.length !== requiredCount
                : undefined
            }
            onClick={() => {
              onDone();
              toggleModal();
            }}
          />
        </Flex>
      )}
      content={({ toggleModal }) => (
        <SearchSelectModalContent
          onSelectionChanged={onSelectionChanged}
          toggleModal={toggleModal}
          creatable={creatable}
          createBody={createBody}
          createAction={createAction}
          searchProps={searchProps}
          columnDefs={columnDefs}
          onChange={onChange}
          rowSelection={rowSelection}
          entityName={entityName}
          {...rest}
        />
      )}
      viewComponent={({ toggleModal }) => (
        <SearchSelectModalViewComponent
          canEdit={canEdit}
          status={status}
          children={children}
          hasValue={hasValue}
          toggleModal={toggleModal}
          actionComponentProps={actionComponentProps}
          onChange={onChange}
          entityName={entityName}
        />
      )}
      {...modalProps}
    />
  );
};

export const SearchSelectModalContainer: React.FC<
  ISearchSelectModalContainerProps
> = (props) => (
  <Mobile>
    {({ match }): React.ReactElement => (
      <SearchSelectModal {...props} isMobile={match} />
    )}
  </Mobile>
);

export default SearchSelectModalContainer;
