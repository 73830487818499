/**
 * Labstep
 *
 * @module components/ProtocolValue/List/Lineage/Children
 * @desc Lineage Children List
 */

import ProtocolValueItemLineage from 'labstep-web/components/ProtocolValue/Item/Lineage';
import List from 'labstep-web/core/List';
import TextWithHelp from 'labstep-web/core/Text/WithHelp';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React from 'react';
import {
  ProtocolValueListLineageChildrenContainerProps,
  ProtocolValueListLineageChildrenProps,
} from './types';

export const ProtocolValueListLineageChildren: React.FC<
  ProtocolValueListLineageChildrenProps
> = ({ protocolValues }) => (
  <List id="protocol-value-list">
    {protocolValues.map((protocolValue: ProtocolValue) => (
      <ProtocolValueItemLineage
        key={protocolValue.guid}
        protocolValue={protocolValue}
      />
    ))}
  </List>
);

export const ProtocolValueListLineageChildrenContainer: React.FC<
  ProtocolValueListLineageChildrenContainerProps
> = ({ resourceItem }) => {
  if (!resourceItem.input_experiment_ids?.length) {
    return null;
  }

  const params: Record<string, unknown> = {
    experiment_id: resourceItem.input_experiment_ids,
    is_output: 1,
    is_lineage: 1,
    is_variable: 0,
    has_resource_item_output: true,
  };

  if (resourceItem.input_protocol_condition_guids?.length) {
    params.protocol_condition_guid_or_null =
      resourceItem.input_protocol_condition_guids;
  }

  return (
    <>
      <TextWithHelp
        text="Children"
        helperText="These items were output inventory in protocols where this item was input inventory"
        header
      />
      <ReadOnMountHOC
        type="cursor"
        entityName={ProtocolValue.entityName}
        params={params}
        children={({ entities: protocolValues }) => (
          <ProtocolValueListLineageChildren
            protocolValues={protocolValues}
          />
        )}
      />
    </>
  );
};

export default ProtocolValueListLineageChildrenContainer;
