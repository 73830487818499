/**
 * Labstep
 *
 * @module components/Experiment/Form/ShowEdit/State/ExperimentActionEdit
 * @desc Button to switch to edit mode
 */

import React from 'react';
import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import { ExperimentActionEditProps } from './types';

export const ExperimentActionEdit: React.FC<
  ExperimentActionEditProps
> = ({ experiment, onClick, actionComponentProps = {} }) => (
  <EntityActionEdit
    entity={experiment}
    actionComponentProps={{
      type: 'text',
      ...actionComponentProps,
      onClick,
    }}
  />
);

export default ExperimentActionEdit;
