/**
 * Labstep
 */

import { setProseMirrorReferencingSearchType } from './prosemirror-referencing-search.types';

export const setProseMirrorReferencingSearchTypeName =
  'SET_PROSEMIRROR_REFERENCE_SEARCH';

export const setProseMirrorReferencingSearchAction: setProseMirrorReferencingSearchType =
  (proseMirrorReferencingSearch) => ({
    type: setProseMirrorReferencingSearchTypeName,
    payload: proseMirrorReferencingSearch,
  });
