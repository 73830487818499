/**
 * Labstep
 *
 * @module components/Signature/Form/fields
 * @desc Signature fields
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const fieldSignatureStatement: IFieldWithoutActionProps = {
  name: 'statement',
  fieldLabel: 'Statement',
  fieldType: FieldType.TextArea,
  validation: rules.signature.statement,
};

export const getFieldSignatureRequirementEntityStateOnReject = (
  options: any,
): IFieldWithoutActionProps => {
  return {
    name: 'reject_entity_state',
    fieldLabel: 'Change Experiment Status To',
    fieldType: FieldType.ReactSelect,
    elementProps: {
      options,
    },
    validation: rules.signature_requirement.reject_entity_state,
  };
};
