/**
 * Labstep
 */

import { IActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { UserGroup } from 'labstep-web/models/user-group.model';

// eslint-disable-next-line no-shadow
export enum Action {
  leave = 'leave',
  view = 'view',
  share = 'share',
  set_home = 'set_home',
  delete_group = 'delete_group',
  delete_user_group = 'delete_user_group',
}

export interface IUserGroupActionMenuProps
  extends Pick<
    IActionMenuProps,
    Exclude<keyof IActionMenuProps, 'children'>
  > {
  userGroup: UserGroup;
  actions: Action[];
}
