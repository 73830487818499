/**
 * Labstep
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const fieldPurityName = 'purity';

export const fieldPurity: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'Purity',
  name: fieldPurityName,
  validation: rules.chemical.purity,
  // This is so that it doesn't turn into
  // a number but statys as string
  skipTransform: true,
};

export const fieldEquivalenceName = 'equivalence';

export const fieldEquivalence: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'Equivalence',
  name: fieldEquivalenceName,
  validation: rules.chemical.equivalence,
};

export const fieldMolarAmountName = 'molar_amount';

export const fieldMolarAmount: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'Molar Amount',
  name: fieldMolarAmountName,
  validation: rules.chemical.molar_amount,
};
