/**
 * Labstep
 *
 * @module models/order-request
 * @desc Typescript export class for OrderRequest
 */

import { Type } from 'class-transformer';
import { User } from 'labstep-web/models/user.model';
import { Entity } from 'labstep-web/models/entity.model';
import { ShareLink } from 'labstep-web/models/share-link.model';
import { Thread } from 'labstep-web/models/thread.model';
import { Log } from 'labstep-web/models/log.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { Resource } from 'labstep-web/models/resource.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import { Group } from 'labstep-web/models/group.model';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import { PermissionActions } from 'labstep-web/typings';
import CurrencyService, {
  Currency,
} from 'labstep-web/services/currency.service';
import { pluralizeWithCheck } from 'labstep-web/services/utils.service';
import { PermaLink } from './perma-link.model';

// eslint-disable-next-line no-shadow
export enum OrderRequestStatus {
  new = 'new',
  approved = 'approved',
  ordered = 'ordered',
  back_ordered = 'back_ordered',
  received = 'received',
  partially_received = 'partially_received',
  cancelled = 'cancelled',
}

export const OrderRequestStatusValues = {
  new: 'Requested',
  approved: 'Approved',
  ordered: 'Ordered',
  back_ordered: 'Back Ordered',
  partially_received: 'Partially Received',
  received: 'Received',
  cancelled: 'Cancelled',
};

export class OrderRequest extends Entity {
  static readonly entityName = 'order_request';

  get entityName(): typeof OrderRequest.entityName {
    return OrderRequest.entityName;
  }

  constructor(data: Partial<OrderRequest> = {}) {
    super();
    Object.assign(this, data);
  }

  static get defaultMetadatas(): Metadata[] {
    return [
      new Metadata({
        label: 'Expected Arrival Date',
        type: MetadataType.date,
        is_required: false,
      }),
    ];
  }

  id!: number;

  is_urgent!: boolean;

  is_template!: boolean;

  entity_users_count!: number;

  resource_item_count!: number;

  currency!: Currency;

  allowed_actions!: PermissionActions[];

  @Type(() => Group)
  owner!: Group;

  @Type(() => User)
  author!: User;

  @Type(() => Thread)
  thread!: Thread;

  @Type(() => OrderRequest)
  template!: OrderRequest | null;

  @Type(() => Resource)
  resource!: Resource;

  @Type(() => PurchaseOrder)
  purchase_order!: PurchaseOrder;

  @Type(() => ShareLink)
  share_link!: ShareLink;

  @Type(() => PermaLink)
  perma_link!: PermaLink;

  @Type(() => MetadataThread)
  metadata_thread!: MetadataThread;

  @Type(() => EntityUser)
  entity_users_preview!: EntityUser[];

  name!: string;

  resource_count!: number;

  status!: OrderRequestStatus;

  quantity!: number;

  amount!: number;

  locked_at!: string | null;

  @Type(() => Log)
  locked_log!: Log | null;

  @Type(() => Log)
  ordered_log!: Log | null;

  @Type(() => Log)
  approved_log!: Log | null;

  @Type(() => Log)
  back_ordered_log!: Log | null;

  @Type(() => Log)
  partially_received_log!: Log | null;

  @Type(() => Log)
  received_log!: Log | null;

  @Type(() => Log)
  cancelled_log!: Log | null;

  get printAmount(): string {
    return CurrencyService.print(this.amount, this.currency);
  }

  get printQuantity(): string {
    return `${this.quantity} ${pluralizeWithCheck(
      'item',
      this.quantity,
    )}`;
  }

  get printStatus(): string {
    return OrderRequestStatusValues[this.status];
  }

  get canSetPurchaseOrder(): boolean {
    return !(
      this.purchase_order &&
      !this.purchase_order.canAddOrRemoveOrderRequest
    );
  }

  get lastDateAndUser(): { date: string; user: User } | null {
    if (this.status === OrderRequestStatus.new) {
      return { date: this.created_at, user: this.author };
    }
    const log: Log | null = this[`${this.status}_log`];
    if (log) {
      return { date: log.created_at, user: log.author };
    }
    return null;
  }

  public getStatusLog(status: OrderRequestStatus) {
    if (status === OrderRequestStatus.new) {
      return { created_at: this.created_at, author: this.author };
    }
    return this[`${status}_log`];
  }

  get remainingItemsCount(): number {
    return this.quantity - this.resource_item_count;
  }

  get hasItemsRemaining(): boolean {
    return this.resource_item_count < this.quantity;
  }

  public get nonDeletedTemplate(): OrderRequest | null {
    if (this.template && !this.template.deleted_at) {
      return this.template;
    }
    return null;
  }

  /**
   * Checks if the resource has any metadatas that are required
   * but do not have a value.
   */
  public get requiredMetadatasWithoutValue(): Metadata[] {
    const requiredMetadatas = this.metadata_thread.metadatas.filter(
      (metadata) =>
        metadata.is_required &&
        !metadata.template_id &&
        !metadata.hasValue,
    );

    if (this.nonDeletedTemplate) {
      requiredMetadatas.push(
        ...this.nonDeletedTemplate.metadata_thread.metadatas.filter(
          (metadata) =>
            metadata.is_required &&
            !this.metadata_thread.metadatas.find(
              (m) => m.template_id === metadata.id,
            )?.hasValue,
        ),
      );
    }
    return requiredMetadatas;
  }

  public shouldImportResourceItems(
    status: OrderRequestStatus,
  ): boolean {
    return (
      status !== this.status &&
      [
        OrderRequestStatus.partially_received,
        OrderRequestStatus.received,
      ].includes(status) &&
      this.hasItemsRemaining
    );
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
