/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Form/ShowEdit/Name
 * @desc Name showedit field
 */

import EntityPrimaryInfo from 'labstep-web/components/Entity/PrimaryInfo';
import { ICONS } from 'labstep-web/constants/icons';
import React from 'react';
import { IEntityStateWorkflowFormShowEditNameProps } from './types';

const EntityStateWorkflowFormShowEditName: React.FC<
  IEntityStateWorkflowFormShowEditNameProps
> = ({ entityStateWorkflow }) => (
  <EntityPrimaryInfo
    entity={entityStateWorkflow}
    icon={ICONS.entity_state_workflow.primary}
    name={entityStateWorkflow.name}
  />
);

export default EntityStateWorkflowFormShowEditName;
