/**
 * Labstep
 */

import Mobile from 'labstep-web/core/MediaQuery/Mobile';
import Modal from 'labstep-web/core/Modal';
import React from 'react';
import styles from './styles.module.scss';
import { IModalWizardHeaderProps } from './types';
import ModalWizardHeaderSteps from './Steps';

export const ModalWizardHeader: React.FC<IModalWizardHeaderProps> = ({
  steps,
  title,
  activeStepIndex,
}) => (
  <Mobile>
    {({ match }) => (
      <Modal.Header className={styles.header}>
        <div className={styles.title}>{title}</div>
        {match ? null : (
          <ModalWizardHeaderSteps
            steps={steps}
            activeStepIndex={activeStepIndex}
          />
        )}
        {<div /> /** Empty div for styling */}
      </Modal.Header>
    )}
  </Mobile>
);

export default ModalWizardHeader;
