/**
 * Labstep
 *
 * @module models/signature-requirement
 * @desc Typescript export class for SignatureRequirement
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { EntityState } from './entity-state.model';
import { SignatureRequest } from './signature-request.model';

export class SignatureRequirement extends Entity {
  static readonly entityName = 'signature_requirement';

  get entityName(): string {
    return SignatureRequirement.entityName;
  }

  constructor(data: Partial<SignatureRequirement> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: never;

  statement!: string;

  reject_entity_state_id!: number;

  entity_role_requirement_id!: number;

  days_to_sign!: number;

  @Type(() => EntityState)
  reject_entity_state?: EntityState;

  @Type(() => SignatureRequest)
  signature_requests!: SignatureRequest[];

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
