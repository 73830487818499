/**
 * Labstep
 *
 * @module core/Form/ShowEdit/Date/ToggleComponent
 * @desc The toggle component for Form ShowEdit Date
 */

import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { DateTimePicker } from 'labstep-web/core/DateTimePicker';
import Popup from 'labstep-web/core/Popup';
import styles from './styles.module.scss';
import { IFormShowEditDateToggleComponentProps } from './types';

export class FormShowEditDateToggleComponent extends React.Component<IFormShowEditDateToggleComponentProps> {
  public dateTimePicker: Flatpickr | null = null;

  public constructor(props: IFormShowEditDateToggleComponentProps) {
    super(props);
    this.setRef = this.setRef.bind(this);
    this.open = this.open.bind(this);
  }

  public componentDidMount(): void {
    if (this.props.dateTimePickerOnly) {
      this.open();
    }
  }

  public setRef(tableRef: Flatpickr): void {
    this.dateTimePicker = tableRef;
  }

  public open(): void {
    if (
      this.dateTimePicker &&
      this.dateTimePicker.flatpickr &&
      this.dateTimePicker.flatpickr.open
    ) {
      setTimeout(this.dateTimePicker.flatpickr.open, 10);
    }
  }

  public render(): React.ReactElement {
    const {
      canEdit,
      dateTimePickerOptions = {},
      dateOnly,
      popup,
      defaultDate,
      viewComponent,
      onClose,
      disabled,
      dateTimePickerOnly,
      ...rest
    } = this.props;

    const component = (
      <div
        className={classnames(styles.dateTimeContainer, {
          [styles.dateTimePickerOnly]: dateTimePickerOnly,
        })}
      >
        {canEdit && (
          <>
            <DateTimePicker
              // Making sure it re-mounts once defaultDate has changed
              key={defaultDate}
              setRef={this.setRef}
              type="hidden"
              options={{
                // no support for open method with native datetime input
                // see https://flatpickr.js.org/mobile-support/
                disableMobile: true,
                defaultDate: new Date(defaultDate || Date.now()),
                type: 'hidden',
                enableTime: !dateOnly,
                dateFormat: 'Y-m-d H:i',
                time_24hr: true,
                onClose,
                minDate: dateTimePickerOptions.minDate
                  ? moment(dateTimePickerOptions.minDate).format(
                      'YYYY-MM-DD',
                    )
                  : null,
                maxDate: dateTimePickerOptions.maxDate
                  ? moment(dateTimePickerOptions.maxDate).format(
                      'YYYY-MM-DD',
                    )
                  : null,
              }}
              {...rest}
            />
          </>
        )}
        <div
          data-testid="show-edit-toggle"
          className={classnames({
            [styles.toggleContainer]: canEdit,
            toggle: canEdit,
          })}
          onClick={disabled ? undefined : this.open}
        >
          {viewComponent}
        </div>
      </div>
    );

    return popup ? (
      <Popup trigger={component} content={popup} size="small" />
    ) : (
      component
    );
  }
}

export default FormShowEditDateToggleComponent;
