/**
 * Labstep
 *
 * @module core/DataGrid/CellEditor/SearchSelect
 * @desc CellEditor Search Select
 */

import EntityActionUpdateSearchSelect from 'labstep-web/components/Entity/Action/Update/SearchSelect';
import { Entity } from 'labstep-web/models/entity.model';
import React from 'react';
import { stopEditingAndFocus } from '../../utils';
import styles from './styles.module.scss';
import { ICellEditorSearchSelectProps } from './types';

export const DataGridCellEditorSearchSelect: React.FC<
  ICellEditorSearchSelectProps<Entity>
> = React.memo((params) => (
  <div
    className={styles.container}
    style={{
      width: params.column.getActualWidth(),
      height: params.node.rowHeight,
    }}
  >
    <EntityActionUpdateSearchSelect
      autoFocus
      backspaceRemovesValue
      onBlur={(): void => stopEditingAndFocus(params)}
      onUpdate={(): void => stopEditingAndFocus(params)}
      optimistic
      {...params.props}
    />
  </div>
));

export default DataGridCellEditorSearchSelect;
