/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/LockSettings
 * @desc Action to change an experiment workflow lock settings
 */

import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import FormEdit from 'labstep-web/core/Form/Edit';
import { IEditField } from 'labstep-web/core/Form/Edit/types';
import { FieldType } from 'labstep-web/core/Form/Reusable/types';
import Modal from 'labstep-web/core/Modal/Default';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { generateNewDateString } from 'labstep-web/services/date.service';
import React from 'react';
import { string } from 'yup';
import { ExperimentWorkflowActionLockSettingsProps } from './types';

const defaultAllowedActionsLock = [
  'unlock',
  'folder:add_remove',
  'share_link:add_remove',
  'tag:add_remove',
  'entity_export:*',
  'entity_user:*',
  'permission:*',
  'signature:*',
  'experiment:unlock',
  'experiment:edit:decorations',
];

export const ExperimentWorkflowActionLockSettings: React.FC<
  ExperimentWorkflowActionLockSettingsProps
> = ({ authenticatedUserEntity, experimentWorkflow }) => {
  if (
    !authenticatedUserEntity.userOrganization?.organization.hasFeatureEnabled(
      'lock_settings',
    ) ||
    experimentWorkflow.locked_at
  ) {
    return null;
  }
  return (
    <Modal
      header="Lock settings"
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="button"
          text="Lock settings"
          onClick={toggleModal}
        />
      )}
      content={({ toggleModal }) => {
        return (
          <div>
            <b>Default ones: </b>
            {defaultAllowedActionsLock.map((a) => (
              <div key={a}>{a}</div>
            ))}
            <b>Custom ones:</b>
            <FormEdit
              entityName={experimentWorkflow.entityName}
              entity={
                new ExperimentWorkflow({
                  ...experimentWorkflow,
                  allowed_actions_lock:
                    experimentWorkflow.allowed_actions_lock.filter(
                      (f) => !defaultAllowedActionsLock.includes(f),
                    ),
                })
              }
              fields={[
                {
                  name: 'allowed_actions_lock',
                  fieldType: FieldType.TextArea,
                  validation: string().min(1).required(),
                } as IEditField,
              ]}
              autoFocus
              preSubmit={(values) => ({
                allowed_actions_lock: [
                  'allowed_actions_lock',
                  ...(values.allowed_actions_lock ?? '').split(','),
                ],
                locked_at: generateNewDateString(),
              })}
              options={{ onSuccess: toggleModal }}
              onCancel={toggleModal}
            />
          </div>
        );
      }}
    />
  );
};

export default withAuthenticatedUserEntity(
  ExperimentWorkflowActionLockSettings,
);
