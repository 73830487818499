/**
 * Labstep
 *
 * @module prosemirror/marks/strike
 * @desc Strike mark
 */

import { LabstepMark } from 'labstep-web/prosemirror/marks/types';
import { toggleMark } from 'prosemirror-commands';

const MarkStrike: LabstepMark = {
  key: 'strike',
  spec: {
    toDOM() {
      return ['s', 0];
    },
    parseDOM: [
      {
        tag: 's',
      },
      {
        tag: 'del',
      },
      {
        tag: 'strike',
      },
      {
        style: 'text-decoration',
        getAttrs: (value) => value === 'line-through' && null,
      },
    ],
  },
  keymap: {
    'Mod-d': (state, dispatch) =>
      toggleMark(state.schema.marks[MarkStrike.key], {})(
        state,
        dispatch,
      ),
  },
  toolbarIcon: 'strikethrough',
};

export default MarkStrike;
