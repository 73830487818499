/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Info/DateAt
 * @desc Showing experiment.date_at + allow for update
 */

import React from 'react';
import FormShowEditDate from 'labstep-web/core/Form/ShowEdit/Date';
import { IExperimentWorkflowInfoDateAtProps } from './types';

const ExperimentWorkflowInfoDateAt: React.FC<
  IExperimentWorkflowInfoDateAtProps
> = ({
  experimentWorkflow,
  label,
  field,
  dateTimePickerOptions,
  popup,
}) => {
  return (
    <FormShowEditDate
      entity={experimentWorkflow}
      field={field}
      label={label}
      dateTimePickerOptions={dateTimePickerOptions}
      popup={popup}
    />
  );
};

export default ExperimentWorkflowInfoDateAt;
