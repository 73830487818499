/**
 * Labstep
 *
 * @desc Create Action for resource with form
 */

import React from 'react';
import EntityActionCreateTemplatable from 'labstep-web/components/Entity/Action/Create/Templatable';
import { IResourceActionCreateFormProps } from './types';

export const ResourceActionCreateForm: React.FC<
  IResourceActionCreateFormProps
> = ({ resourceTemplate, actionComponentProps }) => (
  <EntityActionCreateTemplatable
    entityName="resource"
    template={resourceTemplate}
    actionComponentProps={actionComponentProps}
  />
);

export default ResourceActionCreateForm;
