/**
 * Labstep
 *
 * @module core/Loader/Logo
 * @desc Loader with Labstep logo
 */

import React from 'react';
import Logo from 'labstep-web/core/Logo';
import Loader from 'labstep-web/core/Loader';
import styles from './styles.module.scss';

export const LoaderLogo: React.FC = () => (
  <div className={styles.compoundSpinner}>
    <div>
      <Loader inline size="massive" active />
    </div>
    <div>
      <Logo simplified />
    </div>
  </div>
);

export default LoaderLogo;
