/**
 * Labstep
 *
 * @module components/ProtocolValue/DetailResourceItem/Output
 * @desc DetailResourceItem for Output Protocol Value
 */

import React from 'react';
import TextHelper from 'labstep-web/core/Text/Helper';
import List from 'labstep-web/core/List';
import ActionComponent from 'labstep-web/core/Action/Component';
import ProtocolValueFormShowEditResourceItemOutput from 'labstep-web/components/ProtocolValue/Form/ShowEdit/ResourceItem/Output';
import ResourceItemActionCreateProtocolValue from 'labstep-web/components/ResourceItem/Action/CreateProtocolValue';
import { IProtocolValueDetailResourceItemOutputProps } from './types';

export const ProtocolValueDetailResourceItemOutput: React.FC<
  IProtocolValueDetailResourceItemOutputProps
> = ({ protocolValue }) => (
  <List separation>
    {protocolValue.resourceItem ? (
      <ProtocolValueFormShowEditResourceItemOutput
        protocolValue={protocolValue}
      />
    ) : (
      <>
        <ResourceItemActionCreateProtocolValue
          protocolValue={protocolValue}
        />
        <ProtocolValueFormShowEditResourceItemOutput
          protocolValue={protocolValue}
          modalProps={{
            viewComponent: ({ toggleModal }): React.ReactElement => (
              <TextHelper>
                Looking to link to an exisiting item? Click{' '}
                <ActionComponent
                  type="text"
                  text="here"
                  onClick={toggleModal}
                />
              </TextHelper>
            ),
          }}
        />
      </>
    )}
  </List>
);

export default ProtocolValueDetailResourceItemOutput;
