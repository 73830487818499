/**
 * Labstep
 */

import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import React from 'react';
import { SignatureListTimelineExistingSignatures } from './ExistingSignatures';
import { SignatureListTimelineRequiredSignatures } from './RequiredSignatures';
import styles from './styles.module.scss';
import { SignatureListTimelineProps } from './types';

export const SignatureListTimeline: React.FC<
  SignatureListTimelineProps
> = ({ experimentWorkflow }) => {
  return (
    <ReadOnMountHOC
      type="cursor"
      pagination={{ loader: 'placeholder' }}
      entityName={EntityUser.entityName}
      params={{
        experiment_workflow_id: experimentWorkflow.id,
        count: 100,
      }}
    >
      {({ entities }) => (
        <>
          <div className={styles.container}>
            <SignatureListTimelineRequiredSignatures
              experimentWorkflow={experimentWorkflow}
              entityUsers={entities}
            />
            <SignatureListTimelineExistingSignatures
              experimentWorkflow={experimentWorkflow}
            />
          </div>
        </>
      )}
    </ReadOnMountHOC>
  );
};
