/**
 * Labstep
 *
 * @module components/EntityImport/Action/Create/Steps/ImportMode/Card
 * @desc Card for EntityImport Create Step 1: ImportMode
 */

import React from 'react';
import { Image } from 'semantic-ui-react';
import Header from 'labstep-web/core/Header';
import CardSelect from 'labstep-web/core/Card/Select';
import TextWithIcon from 'labstep-web/core/Text/WithIcon';
import styles from './styles.module.scss';
import { IEntityImportActionCreateStepsImportModeCardProps } from './types';

export const EntityImportActionCreateStepsImportModeCard: React.FC<
  IEntityImportActionCreateStepsImportModeCardProps
> = ({ icon, title, description, img, ...rest }) => (
  <CardSelect
    content={
      <div>
        <div className={styles.top}>
          <Header className={styles.header} size="xsmall">
            <TextWithIcon icon={icon} text={title} />
          </Header>
          <p>{description}</p>
        </div>
        <Image src={img} />
      </div>
    }
    {...rest}
  />
);

export default EntityImportActionCreateStepsImportModeCard;
