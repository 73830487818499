/**
 * Labstep
 *
 * @module components/JupyterInstance/List
 * @desc JupyterInstance List
 */

import Table from 'labstep-web/core/Table';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { JupyterInstance } from 'labstep-web/models/jupyter-instance.model';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import { JupyterInstanceListProps } from './types';

export const JupyterInstanceList: React.FC<
  JupyterInstanceListProps
> = ({ jupyterNotebook }) => (
  <ReadOnMountHOC
    type="cursor"
    entityName={JupyterInstance.entityName}
    params={{
      is_ended: true,
      jupyter_notebook_id: jupyterNotebook.id,
    }}
    loading={{ loader: 'main_content', cached: true }}
    children={({ entities }) => (
      <Table striped unstackable>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={2}>User</Table.Cell>
            <Table.Cell width={1}>Created</Table.Cell>
            <Table.Cell width={1}>Started</Table.Cell>
            <Table.Cell width={1}>Ended</Table.Cell>
            <Table.Cell width={1}>Status</Table.Cell>
            <Table.Cell width={6}>Error message</Table.Cell>
          </Table.Row>
          {entities.map((jupyterInstance: JupyterInstance) => (
            <Table.Row key={jupyterInstance.id}>
              <Table.Cell width={2}>
                {jupyterInstance.author?.first_name}{' '}
                {jupyterInstance.author?.last_name}
              </Table.Cell>
              <Table.Cell width={1}>
                {jupyterInstance.created_at
                  ? humanReadableDate(jupyterInstance.created_at)
                  : 'N/A'}
              </Table.Cell>
              <Table.Cell width={1}>
                {jupyterInstance.started_at
                  ? humanReadableDate(jupyterInstance.started_at)
                  : 'N/A'}
              </Table.Cell>
              <Table.Cell width={1}>
                {jupyterInstance.ended_at
                  ? humanReadableDate(jupyterInstance.ended_at)
                  : 'N/A'}
              </Table.Cell>
              <Table.Cell width={1}>
                {jupyterInstance.status}
              </Table.Cell>
              <Table.Cell width={6}>
                {jupyterInstance.error_message}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    )}
  />
);

export default JupyterInstanceList;
