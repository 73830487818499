/**
 * Labstep
 */

import isEqual from 'lodash/isEqual';
import { Action } from 'labstep-web/models/action.model';

/**
 * Returns the state when requesting a page
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @param  {string} identifier - Identifier
 * @return {object}
 */
export const requestPage = (
  state: any,
  action: Action,
  identifier: string,
) => {
  const stateIdentifier = state[identifier] || {};
  const stateIdentifierPages = stateIdentifier.pages || {};
  const statePage = stateIdentifierPages[action.meta.page] || {};
  const statePageStatus = statePage.status || {};

  return {
    ...state,
    [identifier]: {
      ...stateIdentifier,
      pages: {
        ...stateIdentifierPages,
        [action.meta.page]: {
          ...statePage,
          status: {
            ...statePageStatus,
            isFetching: true,
            error: null,
          },
          items: statePage.items || [],
        },
      },
    },
  };
};

const invalidatePageCache = (state) => {
  if ('pages' in state) {
    return {
      ...state,
      pages: Object.keys(state.pages).reduce(
        (result, page) => ({
          ...result,
          [page]: {
            ...state.pages[page],
            status: {
              ...state.pages[page].status,
              cached: null,
            },
          },
        }),
        {},
      ),
    };
  }

  return state;
};

export const resetReadPageCache = (state) =>
  Object.keys(state).reduce(
    (result, key) => ({
      ...result,
      [key]: invalidatePageCache(state[key]),
    }),
    {},
  );

/**
 * Returns the state when receiving a page
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @param  {string} identifier - Identifier
 * @return {object}
 */
export const receivePage = (
  state: any,
  action: Action,
  identifier: string,
) => {
  const nextPageItems = action.payload.items.result;
  const currentStatePage =
    state[identifier] &&
    state[identifier].pages &&
    state[identifier].pages[action.meta.page];

  let newState =
    currentStatePage &&
    currentStatePage.status &&
    currentStatePage.status.cached &&
    !isEqual(currentStatePage.items, nextPageItems)
      ? resetReadPageCache(state)
      : state;

  const stateIdentifier = newState[identifier] || {};
  const stateIdentifierPages = stateIdentifier.pages || {};
  const statePage = stateIdentifierPages[action.meta.page] || {};

  newState = {
    ...state,
    [identifier]: {
      ...stateIdentifier,
      total: action.payload.total,
      totalPages: Math.ceil(
        action.payload.total / action.payload.count,
      ),
      pages: {
        ...stateIdentifierPages,
        [action.meta.page]: {
          ...statePage,
          status: {
            isFetching: false,
            error: null,
            cached: Date.now(),
          },
          items: action.payload.items.result,
        },
      },
    },
  };

  return newState;
};

/**
 * Returns the state when requesting a page failed
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @param  {string} identifier - Identifier
 * @return {object}
 */
export const failPage = (
  state: any,
  action: Action,
  identifier: string,
) => {
  const stateIdentifier = state[identifier] || {};
  const stateIdentifierPages = stateIdentifier.pages || {};
  const statePage = stateIdentifierPages[action.meta.page] || {};

  return {
    ...state,
    [identifier]: {
      ...stateIdentifier,
      pages: {
        ...stateIdentifierPages,
        [action.meta.page]: {
          ...statePage,
          status: {
            isFetching: false,
            error: action.error,
            cached: null,
          },
        },
      },
    },
  };
};
