/**
 * Labstep
 *
 * @module state/selectors/jupyter
 * @desc Selectors for Jupyter OAuth
 */

import {
  createSelector,
  createSlice,
  type SliceCaseReducers,
} from '@reduxjs/toolkit';
import { JupyterInstanceState } from './types';

const initialState: JupyterInstanceState = {
  run: {},
  edit: {},
};

export const jupyterInstanceSlice = createSlice<
  JupyterInstanceState,
  SliceCaseReducers<JupyterInstanceState>
>({
  name: 'jupyterInstance',
  initialState,
  reducers: {
    getJupyterInstance: {
      reducer(state) {
        return state;
      },
      prepare(payload) {
        return {
          type: `GET_JUPYTER_INSTANCE_REQUEST`,
          payload: {},
          meta: payload,
        };
      },
    },
    postJupyterInstance: {
      reducer(state) {
        return state;
      },
      prepare(payload) {
        return {
          type: `POST_JUPYTER_INSTANCE_REQUEST`,
          payload: {},
          meta: payload,
        };
      },
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type === 'jupyterInstance/postJupyterInstance',
      (state, action) => {
        if (action.meta.type === 'edit') {
          // eslint-disable-next-line no-param-reassign
          state.edit[action.meta.jupyter_notebook_id] = null;
        }
        if (action.meta.type === 'run') {
          // eslint-disable-next-line no-param-reassign
          state.run[action.meta.jupyter_notebook_id] = null;
        }
      },
    );
    builder.addMatcher(
      (action) =>
        action.type === 'GET_JUPYTER_INSTANCE_SUCCESS' ||
        action.type === 'POST_JUPYTER_INSTANCE_SUCCESS',
      (state, action) => {
        if (action.payload === null) {
          return;
        }
        const { type } = action.payload;
        if (type === 'edit') {
          // eslint-disable-next-line no-param-reassign
          state.edit[action.payload.jupyter_notebook.id] =
            action.payload;
        }
        if (type === 'run') {
          // eslint-disable-next-line no-param-reassign
          state.run[action.payload.jupyter_notebook.id] =
            action.payload;
        }
      },
    );
  },
});

export const selectJupyterInstance = createSelector(
  (state: any) => state.jupyter,
  (jupyter: any) => jupyter.jupyter_instance,
);

export const { getJupyterInstance, postJupyterInstance } =
  jupyterInstanceSlice.actions;

export default jupyterInstanceSlice.reducer;
