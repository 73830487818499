/**
 * Labstep
 *
 * @module core/Form/Reusable/Label
 * @desc Field label for reusable form
 */

import React from 'react';
import Popup from 'labstep-web/core/Popup';
import Icon from 'labstep-web/core/Icon';
import styles from './styles.module.scss';
import { IFormReusableLabelProps } from './types';

export const FormReusableLabel: React.FC<IFormReusableLabelProps> = ({
  fieldLabel,
  fieldExplainer,
}) => (
  <div className={styles.labelContainer}>
    <label>{fieldLabel}</label>
    {fieldExplainer && (
      <Popup
        inverted
        content={fieldExplainer}
        trigger={<Icon name="question circle outline" />}
      />
    )}
  </div>
);

export default FormReusableLabel;
