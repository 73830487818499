/**
 * Labstep
 *
 * @module components/DeviceBooking/Action/Delete
 * @desc Action to delete
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { IDeviceBookingDeleteActionProps } from './types';

export const DeviceBookingDeleteAction: React.FC<
  IDeviceBookingDeleteActionProps
> = ({ deviceBooking, ...rest }) => (
  <EntityActionDelete {...rest} entity={deviceBooking} />
);

export default DeviceBookingDeleteAction;
