/**
 * Labstep
 *
 * @module components/Folder/Form/fields
 * @desc Fields for folder
 */

import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';
import { mixed } from 'yup';

export const fieldName: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'Name',
  name: 'name',
  validation: rules.folder.name,
  placeholder: 'Enter name',
};

export const fieldNameMultiple: IFieldWithoutActionProps = {
  fieldLabel: 'Names',
  name: 'names',
  validation: mixed().required(),
  placeholder: 'Enter name',
  fieldType: FieldType.SelectMulti,
};
