/**
 * Labstep
 *
 * @module screens/ResourceItem/Index/LeftPane
 * @desc ResourceItem Index LeftPane
 */

import ResourceItemFilterPane from 'labstep-web/components/ResourceItem/Filter/Pane';
import LayoutLeftPane from 'labstep-web/components/Layout/LeftPane';
import EntityTemplatePane from 'labstep-web/components/Entity/Template/Pane';
import ResourceTemplatePreview from 'labstep-web/components/ResourceTemplate/Preview';
import { Resource } from 'labstep-web/models/resource.model';
import ResourceLocationPane from 'labstep-web/components/ResourceLocation/Pane';
import { PostFilterResourceTemplate } from 'labstep-web/services/postFilter/filters/resource/template';
import React from 'react';
import { IResourceItemIndexLeftPaneProps } from './types';

export const ResourceItemIndexLeftPane: React.FC<
  IResourceItemIndexLeftPaneProps
> = ({ links }) => (
  <LayoutLeftPane links={links}>
    <EntityTemplatePane
      entityPreview={({ entity, ...rest }): React.ReactElement => (
        <ResourceTemplatePreview
          resource={entity as Resource}
          {...rest}
        />
      )}
      entityName={Resource.entityName}
      showUncategorised={false}
      postFilter={PostFilterResourceTemplate}
    />
    <ResourceLocationPane usePostFilter />
    <ResourceItemFilterPane usePostFilter />
  </LayoutLeftPane>
);

export default ResourceItemIndexLeftPane;
