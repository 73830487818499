/**
 * Labstep
 *
 * @module screens/Protocol/Show
 * @desc Protocol show
 */

import ProtocolConditionModal from 'labstep-web/components/ProtocolCondition/Modal/Container';
import { ProtocolConditionModalProvider } from 'labstep-web/components/ProtocolCondition/Modal/context';
import { withAuthenticatedUser } from 'labstep-web/containers/AuthenticatedUser';
import DocumentHead from 'labstep-web/core/DocumentHead';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import MasterShow from 'labstep-web/screens/Master/Show';
import React from 'react';
import Center from './Center';
import Right from './Right';
import {
  IScreensProtocolCollectionShowProps,
  IShowProps,
} from './types';

export const Show: React.FC<IShowProps> = ({
  protocolCollection,
}) => (
  <>
    <DocumentHead title={protocolCollection.name} />
    <ProtocolConditionModalProvider
      entityName={ProtocolCollection.entityName}
    >
      <ProtocolConditionModal
        protocol={protocolCollection.last_version}
      />
      <MasterShow
        entity={protocolCollection}
        center={<Center protocolCollection={protocolCollection} />}
        right={<Right protocolCollection={protocolCollection} />}
      />
    </ProtocolConditionModalProvider>
  </>
);

const ScreensProtocolCollectionShow: React.FC<
  IScreensProtocolCollectionShowProps
> = (props) => {
  return (
    <ReadOnMountHOC
      type="entities"
      loading={{ loader: 'show' }}
      entityName="protocol_collection"
      params={{
        get_single: 1,
        is_deleted: 'both',
        id: props.match.params.id,
      }}
      children={({ entity: protocolCollection }) => (
        <Show protocolCollection={protocolCollection} />
      )}
    />
  );
};

export default withAuthenticatedUser(ScreensProtocolCollectionShow);
