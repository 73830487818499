/* eslint-disable no-param-reassign */
/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
export const loadScript = (appId: string) => {
  const w = window as any;
  w.intercomSettings = {
    app_id: appId,
  };
  const ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    const i = function () {
      i.c(arguments);
    };
    i.q = [] as any[];
    i.c = function (args: any) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function () {
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/byt67nbf';
      const x = d.getElementsByTagName('script')[0];
      x.parentNode?.insertBefore(s, x);
    };
    if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
};
