/**
 * Labstep
 */

import Table from 'labstep-web/core/Table';
import React from 'react';
import SortableItemRef from '../Ref';
import { ISortableItemProps } from './types';

export const SortableItemTable: React.FC<ISortableItemProps> = ({
  children,
  id,
  disabled,
  additionalRows,
}) => (
  <SortableItemRef id={id} disabled={disabled}>
    {({ style }): JSX.Element =>
      additionalRows ? (
        <Table.Body style={style}>
          <Table.Row>{children}</Table.Row>
          {additionalRows}
        </Table.Body>
      ) : (
        <Table.Row key={id} style={style}>
          {children}
        </Table.Row>
      )
    }
  </SortableItemRef>
);

export default SortableItemTable;
