/**
 * Labstep
 *
 * @module
 * @desc Modal showing empy state for premium features
 */

import LayoutMainContentCenter from 'labstep-web/components/Layout/MainContent/Center';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import Card from 'labstep-web/core/Card/Card';
import React from 'react';
import PremiumFeatureEmptyState from '../EmptyState';
import styles from './styles.module.scss';
import { IPremiumFeatureScreenGuardProps } from './types';

export const UncontainedPremiumFeatureScreenGuard: React.FC<
  IPremiumFeatureScreenGuardProps
> = ({
  premiumFeature,
  children,
  authenticatedUserEntity,
  unstyled,
}) => {
  if (authenticatedUserEntity.hasFeatureEnabled(premiumFeature)) {
    return <>{children}</>;
  }

  return (
    <LayoutMainContentCenter>
      {unstyled ? (
        <PremiumFeatureEmptyState premiumFeature={premiumFeature} />
      ) : (
        <Card className={styles.card}>
          <PremiumFeatureEmptyState premiumFeature={premiumFeature} />
        </Card>
      )}
    </LayoutMainContentCenter>
  );
};

export const PremiumFeatureScreenGuard = withAuthenticatedUserEntity(
  UncontainedPremiumFeatureScreenGuard,
);

export default PremiumFeatureScreenGuard;
