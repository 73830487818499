/**
 * Labstep
 */

import { selectActiveGroup } from 'labstep-web/state/slices/activeGroup';
import { useSelector } from 'react-redux';
import { useActiveGroupType } from './types';

export const useActiveGroup = (): useActiveGroupType => {
  return {
    activeGroup: useSelector((state) => selectActiveGroup(state)),
  };
};
