/**
 * Labstep
 *
 * @module components/Device/Action/Duplicate
 * @desc Duplicate action for device
 */

import React from 'react';
import EntityActionDuplicate from 'labstep-web/components/Entity/Action/Duplicate';
import { IDeviceActionDuplicateProps } from './types';

export const DeviceActionDuplicate: React.FC<
  IDeviceActionDuplicateProps
> = ({ device }) => <EntityActionDuplicate entity={device} />;

export default DeviceActionDuplicate;
