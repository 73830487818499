/**
 * Labstep
 */

import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

export const getPlaceholder = (entityName: string): string => {
  return `Search or select a ${getHumanReadableEntityName(
    entityName,
  ).toLowerCase()}...`;
};
