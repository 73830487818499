/**
 * Labstep
 *
 * @module screens/ProtocolCondition/Show
 * @desc ProtocolCondition Show
 */

import { IEntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import { ProtocolConditionEmptyState } from 'labstep-web/components/ProtocolCondition/DataGrid/EmptyState';
import ProtocolConditionDataGridReporting from 'labstep-web/components/ProtocolCondition/DataGridReporting';
import { ParamsStateContextProvider } from 'labstep-web/hoc/Params/context';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import MasterIndex from 'labstep-web/screens/Master/Index';
import {
  PostFilterComparison,
  PostFilterOperator,
} from 'labstep-web/services/query-parameter.service';
import React, { useCallback, useMemo } from 'react';
import ProtocolConditionShowBreadcrumb from './Breadcrumb';
import {
  IProtocolConditionShowContainerProps,
  IProtocolConditionShowListOrTableProps,
  IProtocolConditionShowProps,
} from './types';

export const ProtocolConditionShowListOrTable: React.FC<
  IProtocolConditionShowListOrTableProps
> = ({ protocol, protocolConditions, loadMore, status }) => {
  return (
    <ProtocolConditionDataGridReporting
      protocolConditions={protocolConditions}
      protocol={protocol}
      loadMore={loadMore}
      status={status}
    />
  );
};

export const ProtocolConditionShow: React.FC<
  IProtocolConditionShowProps
> = ({ protocolCollection, protocol, groupId }) => {
  const breadcrumb = useMemo(
    () => (
      <ProtocolConditionShowBreadcrumb
        protocol={protocol}
        groupId={groupId}
      />
    ),
    [protocol.id],
  );
  const noResultsMessage = <ProtocolConditionEmptyState />;

  const initialSearchParams = {
    filter: [
      {
        path: 'experiment.protocol.collection',
        type: PostFilterOperator.and,
        predicates: [
          {
            attribute: 'guid',
            comparison: PostFilterComparison.eq,
            value: protocolCollection.guid,
          },
        ],
      },
    ],
    serializerGroups: 'reporting',
    sort: '-created_at',
  };

  const entityView = useCallback(() => {
    return {
      context: 'protocol_condition_index',
      entityName: ProtocolCondition.entityName,
      parameters: undefined,
    };
  }, []);

  const paramsStateContext = useMemo(
    () => ({ persistEntity: ProtocolCondition.entityName }),
    [],
  );

  return (
    <ParamsStateContextProvider value={paramsStateContext}>
      <MasterIndex
        breadcrumb={breadcrumb}
        entityName={ProtocolCondition.entityName}
        params={initialSearchParams}
        // filters={filters}
        // sortOptions={sortOptions}
        noResultsMessage={noResultsMessage}
        entityView={entityView}
        tableFormat={false}
        hideFilters
        usePostFilter
        useAllPages
      >
        {({
          entities,
          readNextPage,
          status,
        }: IEntitySearchContainerChildrenProps) => (
          <ProtocolConditionShowListOrTable
            protocol={protocol}
            protocolConditions={entities}
            loadMore={readNextPage}
            status={status}
          />
        )}
      </MasterIndex>
    </ParamsStateContextProvider>
  );
};

const ProtocolConditionShowContainer: React.FC<
  IProtocolConditionShowContainerProps
> = ({ match }) => (
  <ReadOnMountHOC
    type="entities"
    entityName={ProtocolCollection.entityName}
    params={{
      get_single: 1,
      id: match.params.protocolCollectionId,
      serializerGroups: 'protocol_collection_reporting',
    }}
    loading={{ loader: 'show', cached: true }}
    children={({ entity }: { entity: ProtocolCollection }) => (
      <ProtocolConditionShow
        protocolCollection={entity}
        protocol={entity.last_version}
        groupId={match.params.id}
      />
    )}
  />
);

export default ProtocolConditionShowContainer;
