/**
 * Labstep
 *
 * @desc Breadcrumb for Device Show page
 */

import React from 'react';
import EntityTemplateBreadcrumbShow from 'labstep-web/components/Entity/Template/Breadcrumb/Show';
import { IDeviceBreadcrumbProps } from './types';

export const DeviceBreadcrumb: React.FC<IDeviceBreadcrumbProps> = ({
  device,
}) => <EntityTemplateBreadcrumbShow entity={device} />;

export default DeviceBreadcrumb;
