/**
 * Labstep
 *
 * @module prosemirror/extensions/commands
 * @desc Commands extension
 */

import { LabstepExtension } from '../types';
import {
  handleArrowDown,
  handleArrowUp,
  handleEnter,
  handleEscape,
  handleSpace,
  handleTrigger,
} from './commands';
import commandsPlugin, { TRIGGER } from './plugin';

const ExtensionCommands: LabstepExtension = {
  keymap: {
    ArrowUp: (state, dispatch) => handleArrowUp(state, dispatch),
    ArrowDown: (state, dispatch) => handleArrowDown(state, dispatch),
    Enter: (state, dispatch) => handleEnter(state, dispatch),
    Escape: (state, dispatch) => handleEscape(state, dispatch),
    Space: (state, dispatch) => handleSpace(state, dispatch),
    [TRIGGER]: (state, dispatch) => handleTrigger(state, dispatch),
  },
  plugins: [commandsPlugin],
};

export default ExtensionCommands;
