import { Experiment } from 'labstep-web/models/experiment.model';
import { navigation } from 'labstep-web/services/navigation';
import { IRouting } from 'labstep-web/services/navigation.types';

export const getExperimentPath = (experiment: Experiment): string => {
  let name: keyof IRouting = 'experiment_show';
  let params = { id: experiment.id };
  if (experiment.useLatestProtocol) {
    name = 'protocol_collection_show';
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    params = { id: experiment.protocol_collection_id! };
  }
  return navigation.get(name, params);
};
