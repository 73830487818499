/**
 * Labstep
 *
 * @module components/Metadata/Action/Create
 * @desc Create action for metadata
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import MetadataActionCreateDropdown from 'labstep-web/components/Metadata/Action/Create/Dropdown';
import { addMoleculeToBody } from 'labstep-web/components/Metadata/Form/utils';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import { Metadata } from 'labstep-web/models/metadata';
import { Resource } from 'labstep-web/models/resource.model';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import React from 'react';
import { IMetadataActionCreateProps } from './types';

export const MetadataActionCreate: React.FC<
  IMetadataActionCreateProps
> = ({
  options,
  entity,
  handleOnClickBulkOption,
  body,
  actionComponentProps,
}) => (
  <CanCreate
    parentName={entity.metadata_thread.entityName}
    parentId={entity.metadata_thread.idAttr}
    entityName="metadata"
  >
    <EntityCreateContainer
      entityName={Metadata.entityName}
      parentName={entity.metadata_thread.entityName}
      parentId={entity.metadata_thread.idAttr}
    >
      {({ create, status }) => (
        <MetadataActionCreateDropdown
          trigger={
            <ActionComponent
              dataTestId="metadata-action-create-dropdown"
              type="button"
              text={`Add ${Metadata.getHumanReadableEntityName(
                false,
                true,
                entity,
              )}`}
              status={status}
              {...actionComponentProps}
            />
          }
          handleOnClickMetadataOption={(metadataType) => {
            let createBody: any = {
              ...body,
              type: metadataType.value,
            };
            createBody = addMoleculeToBody(
              createBody,
              metadataType.value,
            );
            create(createBody, enhanceOptions({ options }));
          }}
          handleOnClickBulkOption={handleOnClickBulkOption}
          showChemistry={entity instanceof Resource}
        />
      )}
    </EntityCreateContainer>
  </CanCreate>
);

export default MetadataActionCreate;
