/**
 * Labstep
 *
 * @module components/Metadata/DataGrid/ColumnOptions
 * @desc Metadata Data Grid Column Options for metadata create
 */

import MetadataActionCreateModal from 'labstep-web/components/Metadata/Action/Create/Modal';
import { ICONS } from 'labstep-web/constants/icons';
import Header from 'labstep-web/core/Header';
import { Metadata } from 'labstep-web/models/metadata';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IMetadataDataGridColumnOptionsProps } from './types';

export const MetadataDataGridColumnOptions: React.FC<
  IMetadataDataGridColumnOptionsProps
> = ({ entity, extraFields }) => (
  <div>
    <Header size="mini">Add Field...</Header>
    {extraFields}
    {entity.is_template && (
      <MetadataActionCreateModal
        entity={entity}
        entityName={entity.entityName}
        isTemplate
        actionComponentProps={{
          type: 'text',
          icon: ICONS.metadata.secondary,
          cornerIcon: ICONS[entity.entityName].primary,
          text: `${getHumanReadableEntityName(
            entity.entityName,
            false,
            true,
          )} ${Metadata.getHumanReadableEntityName(false, true)}`,
        }}
      />
    )}
  </div>
);

export default MetadataDataGridColumnOptions;
