/**
 * Labstep
 *
 * @module components/OrderRequest/Action/ShareLink
 * @desc Share a orderRequest with all users on the platform
 */

import React from 'react';
import EntityActionShare from 'labstep-web/components/Entity/Action/Share';
import { IOrderRequestActionShareProps } from './types';

export const OrderRequestActionShare: React.FC<
  IOrderRequestActionShareProps
> = ({ orderRequest }) => <EntityActionShare entity={orderRequest} />;

export default OrderRequestActionShare;
