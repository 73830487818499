/**
 * Labstep
 *
 * @module components/Entity/Table/BulkSelect
 * @desc Table of nested content items
 */

import React from 'react';
import BulkSelectContext from 'labstep-web/containers/BulkSelect/context';
import SimpleTable from 'labstep-web/core/Table/Simple';
import ActionComponent from 'labstep-web/core/Action/Component';
import styles from './styles.module.scss';
import { IEntityTableBulkSelectProps } from './types';
import { bulkSelectColumn } from './utils';

export const EntityTableBulkSelect: React.FC<
  IEntityTableBulkSelectProps
> = ({
  columns,
  entities,
  withCheckbox,
  singleSelect,
  lastHeader,
  ...rest
}) => {
  let columnsWithBulk = columns;
  if (singleSelect) {
    columnsWithBulk = [
      ...columns,
      {
        header: null,
        content: (
          entity: (typeof entities)[0],
        ): React.ReactElement => (
          <BulkSelectContext.Consumer>
            {({ toggleSelection }): React.ReactElement => (
              <ActionComponent
                type="button"
                text="Select"
                onClick={(): void => toggleSelection(entity)}
              />
            )}
          </BulkSelectContext.Consumer>
        ),
      },
    ];
  } else if (withCheckbox) {
    columnsWithBulk = [bulkSelectColumn, ...columns];
  }

  const lastColumn = columnsWithBulk[columnsWithBulk.length - 1];
  lastColumn.header = lastHeader;
  lastColumn.cellProps = {
    ...lastColumn.cellProps,
    className: styles.lastColumn,
  };

  return (
    <SimpleTable
      columns={columnsWithBulk}
      rows={entities}
      {...rest}
    />
  );
};

export default EntityTableBulkSelect;
