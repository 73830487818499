/**
 * Labstep
 *
 * @module components/PurchaseOrder/Status
 * @desc PurchaseOrder Request status + option to change
 */

import React from 'react';
import styles from './styles.module.scss';
import { IPurchaseOrderStatusActionProps } from './types';

const PurchaseOrderActionStatus: React.FC<
  IPurchaseOrderStatusActionProps
> = ({ purchaseOrder }) => (
  <span className={styles[purchaseOrder.status]}>
    {purchaseOrder.printStatus}
  </span>
);

export default PurchaseOrderActionStatus;
