/**
 * Labstep
 *
 * @module core/Collapsible/Item
 * @desc Component to toggle an item with content
 */

import React from 'react';
import classNames from 'classnames';
import { useToggle } from 'labstep-web/hooks/toggle';
import Icon from 'labstep-web/core/Icon';
import styles from './styles.module.scss';
import {
  ICollapsibleCardItemContainerProps,
  ICollapsibleCardItemProps,
} from './types';

export const CollapsibleCardItem: React.FC<
  ICollapsibleCardItemProps
> = ({
  icon,
  name,
  actions,
  content,
  expanded,
  toggleExpanded,
  disabled,
  scrollable,
  className,
}) => {
  const toggleActive = disabled ? undefined : toggleExpanded;
  return (
    <div className={className}>
      <div className={styles.info}>
        <div className={styles.infoContainer}>
          <span className={styles.name} onClick={toggleActive}>
            {icon && <Icon name={icon} />}
            {name}
          </span>
        </div>
        <div className={styles.actionContainer}>
          {typeof actions === 'function'
            ? actions({ expanded })
            : actions}{' '}
          {toggleActive && (
            <Icon
              name={expanded ? 'angle up' : 'angle down'}
              size="large"
              onClick={toggleActive}
            />
          )}
        </div>
      </div>
      {expanded && (
        <div
          className={classNames(styles.contentContainer, {
            [styles.scrollable]: scrollable,
          })}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export const CollapsibleCardItemContainer: React.FC<
  ICollapsibleCardItemContainerProps
> = ({ initialExpandedState = true, ...rest }) => {
  const [expanded, toggleExpanded] = useToggle(initialExpandedState);
  return (
    <CollapsibleCardItem
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      {...rest}
    />
  );
};

export default CollapsibleCardItemContainer;
