/**
 * Labstep
 */

import { IFilter } from './types';

export const isActive = (
  searchParams: Record<string, string>,
  params?: Record<string, string>,
  postFilterValues?: IFilter['postFilterValues'],
): boolean => {
  if (postFilterValues) {
    const [postFilter, values] = postFilterValues;
    return !!postFilter.getNodeInParams(searchParams, values);
  }
  if (params) {
    if (params.tag_id) {
      const activeTags = searchParams.tag_id
        ? searchParams.tag_id.split(',')
        : [];
      return activeTags.includes(params.tag_id);
    }
    return Object.keys(params).reduce((result, key) => {
      return result && searchParams[key] === params[key];
    }, true);
  }
  return false;
};
