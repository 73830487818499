/**
 * Labstep
 *
 * @module screens/ProtocolCondition/Index/Breadcrumb
 * @desc ProtocolCondition Index Breadcrumb
 */

import ProtocolCollectionSearchSelect from 'labstep-web/components/ProtocolCollection/SearchSelect';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import { ProtocolConditionShowBreadcrumbProps } from './types';

export const ProtocolConditionShowBreadcrumb: React.FC<
  ProtocolConditionShowBreadcrumbProps
> = ({ protocol, groupId, history }) => (
  <Flex vAlignContent="center" spacing="gap">
    <Header size="mini" noMargin>
      Showing data for runs of:{' '}
    </Header>
    <ProtocolCollectionSearchSelect
      params={{
        group_id: groupId,
      }}
      returnProtocolCollection
      value={{ id: protocol.id, label: protocol.name }}
      onChange={(option: ProtocolCollection) => {
        history.push(
          navigation.get('protocol_condition_show', {
            id: groupId,
            protocolCollectionId: option.guid,
          }),
        );
      }}
    />
  </Flex>
);

export default withRouter(ProtocolConditionShowBreadcrumb);
