/**
 * Labstep
 *
 * @module components/Chemical/Form/ShowEdit/MolarAmount
 * @desc ShowEditMolarAmount for Chemical
 */

import React from 'react';
import FormShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import { Chemical } from 'labstep-web/models/chemical.model';
import { fieldMolarAmount } from 'labstep-web/components/Chemical/Form/fields';
import { IChemicalFormShowEditMolarAmountProps } from './types';

export const ChemicalFormShowEditMolarAmount: React.FC<
  IChemicalFormShowEditMolarAmountProps
> = ({ chemical }) => (
  <FormShowEditText
    entityName={Chemical.entityName}
    entity={chemical}
    field={fieldMolarAmount}
    placeholder="Enter"
    disabled={!chemical.canChangeAmount}
  >
    {chemical.molar_amount}
  </FormShowEditText>
);

export default ChemicalFormShowEditMolarAmount;
