/**
 * Labstep
 *
 * @module components/ResourceLocation/Filter/Active
 * @desc Active filter component for resource location
 */

import { ICONS } from 'labstep-web/constants/icons';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import React from 'react';
import { IResourceLocationFilterActiveProps } from './types';

const ResourceLocationFilterActive: React.FC<
  IResourceLocationFilterActiveProps
> = ({ clearParams, searchParams }) =>
  'resource_location_guid' in searchParams ? (
    <EntityReadEntityContainer
      entityName={ResourceLocation.entityName}
      id={searchParams.resource_location_guid}
    >
      {({ entity }) =>
        entity ? (
          <RemoveLabel
            icon={ICONS.resource_location.primary}
            name={entity.name}
            onRemoveClick={
              clearParams &&
              (() => clearParams(['resource_location_guid']))
            }
          />
        ) : null
      }
    </EntityReadEntityContainer>
  ) : null;

export default ResourceLocationFilterActive;
