/**
 * Labstep
 *
 * @module services/i18n
 * @desc Helper methods for internalization
 */

import replace from 'lodash/replace';

/**
 * Capitalizes string
 * @param  {string} string
 * @return {string} - Capitalized string
 */
export const capitalize = (val: string): string =>
  val.replace(/(?:^|\s)\S/g, (a: string) => a.toUpperCase());

const replacements: Record<string, string> = {
  protocol_collection: 'protocol',
  group: 'workspace',
  user_group: 'workspace',
  experiment_workflow: 'experiment',
  experiment_workflow_template: 'experiment template',
  rich_text: 'long text',
  molecule: 'molecule',
  metadata: 'metadata',
  resource_location: 'location',
  protocol_value: 'inventory field',
  resource: 'resource',
  protocol_step: 'step',
  protocol_timer: 'timer',
  protocol_device: 'device',
  protocol_condition: 'condition',
  order_request: 'order request',
  resource_item: 'item',
  access_key: 'API key',
  user: 'member',
  paper: 'publication',
  purchase_order: 'order',
  comment: 'note',
  folder: 'collection',
  entity_user: 'collaborator',
  user_organization: 'user',
  code: 'code snippet',
  protocol_table: 'table',
  jupyter_notebook: 'jupyter notebook',
  device_booking: 'device booking',
  experiment_workflow_link: 'link',
  experiment: 'protocol',
  resource_template: 'resource category',
  device_template: 'device category',
  device_data: 'device data',
  entity_view: 'view',
  entity_state: 'status',
  entity_state_workflow: 'status workflow',
  entity_user_role: 'user role',
  entity_user_role_requirement: 'role requirement',
  signature_requirement: 'signature requirement',
};

const pluralReplacements: Record<string, string> = {
  category: 'categories',
  data: 'data',
  policy: 'policies',
  status: 'statuses',
};

/**
 * Pluralizes entityName
 *
 * @function
 * @param  {string} entityName - Entity name
 * @return {string}
 */
export const pluralize = (entityName: string): string => {
  const pluralReplacement = Object.keys(pluralReplacements).find(
    (subStr) => entityName.endsWith(subStr),
  );

  if (pluralReplacement) {
    return entityName.replace(
      pluralReplacement,
      pluralReplacements[pluralReplacement],
    );
  }
  return `${entityName}s`;
};

/**
 *
 * @description Get human readable version of entity name
 */
export const getHumanReadableEntityName = (
  entityName: string,
  plural = false,
  capitalization = false,
  isTemplate = false,
): string => {
  let name = entityName;

  // temp fix until we deprecate resource template model
  if (isTemplate && !name.endsWith('_template')) {
    name += '_template';
  }

  if (name in replacements) {
    name = replacements[name];
  }

  const singular = replace(name, /_/g, ' ');

  if (plural) {
    name = pluralize(singular);
  } else {
    name = singular;
  }

  if (capitalization) {
    return capitalize(name);
  }
  return name;
};
