/**
 * Labstep
 *
 * @module components/Metadata/Detail/Table
 * @desc Table for (meta)Data Detail
 */

import React from 'react';
import MetadataDetailTableValue from 'labstep-web/components/Metadata/Detail/Table/Value';
import MetadataFormShowEditValue from 'labstep-web/components/Metadata/Form/ShowEdit/Value';
import { IMetadataDetailValueProps } from './types';

export const MetadataDetailValue: React.FC<
  IMetadataDetailValueProps
> = ({ metadata, entity }) =>
  metadata.type === 'file' ? (
    <MetadataFormShowEditValue
      entity={entity}
      metadata={metadata}
      isDropzone
    />
  ) : (
    <MetadataDetailTableValue entity={entity} metadata={metadata} />
  );

export default MetadataDetailValue;
