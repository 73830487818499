/**
 * Labstep
 *
 * @module components/Molecule/Hazards
 * @desc Showing molecule hazards
 */

import classnames from 'classnames';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import ActionComponent from 'labstep-web/core/Action/Component';
import Header from 'labstep-web/core/Header';
import Hoverable from 'labstep-web/core/Hoverable';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import ActionAdd from './ActionAdd';
import ActionRemove from './ActionRemove';
import Hazards from './Hazards';
import Pictograms from './Pictograms';
import Precautionary from './Precautionary';
import styles from './styles.module.scss';
import {
  IMoleculeSafetyCodeListProps,
  IMoleculeSafetyProps,
} from './types';

export const MoleculeSafetyCodeList: React.FC<
  IMoleculeSafetyCodeListProps
> = ({ label, codes, parent, type, subtype, pubChemMetadata }) => (
  <>
    <div>
      <Header children={label} size="mini" />
      <ul className={styles.codeList}>
        {codes.map((code) => (
          <li key={code.code}>
            <Hoverable
              hoverable={
                <div>
                  <u>{code.code}</u>
                  {code.info && ` - ${code.info}`}
                </div>
              }
              component={
                parent && (
                  <ActionRemove
                    parent={parent}
                    type={type}
                    code={code.code}
                    subtype={subtype}
                    pubChemMetadata={pubChemMetadata}
                  />
                )
              }
            />
          </li>
        ))}
      </ul>
      {parent && (
        <ActionAdd
          pubChemMetadata={pubChemMetadata}
          parent={parent}
          type={type}
          subtype={subtype}
        />
      )}
    </div>
    <br />
  </>
);

export const MoleculeSafety: React.FC<IMoleculeSafetyProps> = ({
  pubChemMetadata,
  parent,
  inline,
  modalProps,
}) => {
  if (!pubChemMetadata.Safety) {
    return null;
  }
  return (
    <ModalDefault
      header="Safety Information"
      content={(): React.ReactElement => (
        <div>
          <Pictograms
            parent={parent}
            pubChemMetadata={pubChemMetadata}
          />
          <Hazards
            parent={parent}
            pubChemMetadata={pubChemMetadata}
          />
          <Precautionary
            parent={parent}
            pubChemMetadata={pubChemMetadata}
          />
        </div>
      )}
      viewComponent={({ toggleModal }): React.ReactElement => (
        <div
          className={classnames(styles.container, {
            [styles.inline]: inline,
          })}
        >
          <Pictograms
            pubChemMetadata={pubChemMetadata}
            isInline
            size={inline ? 'mini' : undefined}
          />
          <ActionComponent
            onClick={toggleModal}
            type="text"
            elementProps={{
              underline: true,
            }}
            text="show all codes"
          />
        </div>
      )}
      {...modalProps}
    />
  );
};

export default withAuthenticatedUserEntity(MoleculeSafety);
