/**
 * Labstep
 *
 * @module core/TextWithMetadata
 * @desc Text with Metadata
 */

import React from 'react';
import styles from './styles.module.scss';
import { ITextWithMetadataProps } from './types';

export const TextWithMetadata: React.FC<ITextWithMetadataProps> = ({
  text,
  metadata,
}) => (
  <>
    {text}
    {metadata && (
      <div className={styles.metadata}>
        <small>{metadata}</small>
      </div>
    )}
  </>
);

export default TextWithMetadata;
