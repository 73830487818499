/**
 * Labstep
 */

import {
  IMETADATA_FIELDS,
  IMETADATA_TYPES,
  MetadataType,
  MetadataTypeValues,
} from './types';

export const METADATA_DEFAULT_LABEL_DATA = 'Untitled Data Field';

/* Group metadata types with values */
export const METADATA_TYPES: IMETADATA_TYPES[] = [
  {
    label: MetadataTypeValues.default,
    value: MetadataType.default,
  },
  {
    label: MetadataTypeValues.date,
    value: MetadataType.date,
  },
  {
    label: MetadataTypeValues.datetime,
    value: MetadataType.datetime,
  },
  {
    label: MetadataTypeValues.numeric,
    value: MetadataType.numeric,
  },
  {
    label: MetadataTypeValues.options,
    value: MetadataType.options,
  },
  {
    label: MetadataTypeValues.file,
    value: MetadataType.file,
  },
  {
    label: MetadataTypeValues.molecule,
    value: MetadataType.molecule,
  },
  {
    label: MetadataTypeValues.sequence,
    value: MetadataType.sequence,
  },
];

/**
 * Metadata types for experiment/protocol data
 */
export const METADATA_TYPES_DATA = METADATA_TYPES.filter(
  (metadataType) => metadataType.value !== MetadataType.molecule,
);

export const METADATA_TYPES_NOT_LINKED = METADATA_TYPES.filter(
  (metadataType) =>
    metadataType.value !== MetadataType.molecule &&
    metadataType.value !== MetadataType.sequence &&
    metadataType.value !== MetadataType.file,
);

/* List of fields for all metadata types */
export const METADATA_FIELDS: IMETADATA_FIELDS = {
  [MetadataType.default]: {
    fields: ['value'],
    fieldsToReset: ['value'],
  },
  [MetadataType.date]: {
    fields: ['date'],
    fieldsToReset: ['date'],
  },
  [MetadataType.datetime]: {
    fields: ['date'],
    fieldsToReset: ['date'],
  },
  [MetadataType.numeric]: {
    fields: ['number', 'unit'],
    fieldsToReset: ['number', 'unit'],
  },
  [MetadataType.options]: {
    fields: ['options'],
    fieldsToReset: ['options'],
  },
  [MetadataType.file]: {
    fields: ['file_id'],
    fieldsToDelete: ['file', 'file_id'],
  },
  [MetadataType.molecule]: {
    fields: ['molecule'],
    fieldsToDelete: ['molecule'],
  },
  [MetadataType.sequence]: {
    fields: ['sequence'],
    fieldsToDelete: ['sequence'],
  },
};
