/**
 * Labstep
 *
 * @module components/Filter/Menu
 * @desc Filters menu list
 */

import React from 'react';
import { usePremiumFeatureStatus } from 'labstep-web/hooks/premium-feature';
import ActionComponent from 'labstep-web/core/Action/Component';
import { IFilterMenuListProps } from './types';

export const FilterMenuList: React.FC<IFilterMenuListProps> = ({
  filtersContent,
  filters,
}) => {
  const premiumFilterOptions = usePremiumFeatureStatus(
    filtersContent,
    'name',
    'filters',
  );

  return (
    <>
      {premiumFilterOptions.map(
        ({
          name,
          component,
          actionComponentProps,
          premiumFeature,
          premiumFeatureEnabled,
        }) => {
          if (filters.includes(name) && component) {
            if (!premiumFeature || premiumFeatureEnabled) {
              return (
                <React.Fragment key={name}>
                  {component}
                </React.Fragment>
              );
            }
            if (!premiumFeatureEnabled) {
              return (
                <React.Fragment key={name}>
                  <ActionComponent
                    type="option"
                    premiumFeature={premiumFeature}
                    {...actionComponentProps}
                  />
                </React.Fragment>
              );
            }
          }
          return null;
        },
      )}
    </>
  );
};
