/**
 * Labstep
 *
 * @module components/Metadata/ShowEdit/Value/WithClear
 * @desc Rendering ShowEditValue + Clear action for metadata
 */

import MetadataActionClearValue from 'labstep-web/components/Metadata/Action/ClearValue';
import MetadataActionCopy from 'labstep-web/components/Metadata/Action/Copy';
import MetadataFormShowEditValue from 'labstep-web/components/Metadata/Form/ShowEdit/Value';
import Flex from 'labstep-web/core/Flex';
import Hoverable from 'labstep-web/core/Hoverable';
import React from 'react';
import styles from './styles.module.scss';
import { IMetadataFormShowEditValueWithClearProps } from './types';

const MetadataFormShowEditValueWithClear: React.FC<
  IMetadataFormShowEditValueWithClearProps
> = ({ metadata, entity, componentProps }) => (
  <Hoverable
    className={styles.fieldName}
    hoverable={
      <MetadataFormShowEditValue
        key={metadata.type}
        entity={entity}
        metadata={metadata}
        componentProps={componentProps}
      />
    }
    component={
      <Flex>
        <MetadataActionCopy metadata={metadata} />
        <MetadataActionClearValue
          metadata={metadata}
          entity={entity}
        />
      </Flex>
    }
  />
);

export default MetadataFormShowEditValueWithClear;
