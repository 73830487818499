/**
 * Labstep
 *
 * @module components/Experiment/Action/SaveAs
 * @desc A dropdown menu to save as a new protocol or a new protocol collection
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import ProtocolActionCreate from 'labstep-web/components/Protocol/Action/Create';
import ProtocolFormCreate from 'labstep-web/components/Protocol/Form/Create';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import Dropdown from 'labstep-web/core/Dropdown';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import {
  IExperimentActionSaveAsProps,
  IExperimentActionSaveAsWithProtocolProps,
  IExperimentActionSaveAsWithoutProtocolProps,
} from './types';

export const ExperimentActionSaveAsWithoutProtocol: React.FC<
  IExperimentActionSaveAsWithoutProtocolProps
> = ({ experiment, activeGroup }) => (
  <CanCreate entityName="protocol_collection">
    <EntityCreateContainer
      entityName={Protocol.entityName}
      showLoaderWhileCreating
    >
      {({ status, create }) => (
        <ActionComponent
          type="text"
          icon="save"
          text="Save to Protocol Library"
          disabled={status && status.isFetching}
          onClick={() =>
            create(
              {
                experiment_id: experiment.id,
                group_id: activeGroup?.id,
              },
              {
                toast: true,
                additionalMeta: {
                  addToProtocolLibrary: true,
                },
              },
            )
          }
        />
      )}
    </EntityCreateContainer>
  </CanCreate>
);

export const ExperimentActionSaveAsWithProtocol: React.FC<
  IExperimentActionSaveAsWithProtocolProps
> = ({ experiment, disabled, activeGroup }) => (
  <Dropdown
    icon={null}
    disabled={disabled}
    trigger={
      <ActionComponent type="text" icon="save" text="Save As" />
    }
  >
    <ProtocolActionCreate
      parentName={experiment.protocol.collection.entityName}
      parentId={experiment.protocol.collection.id}
      actionComponentProps={{
        type: 'option',
        text: 'New Version',
        icon: undefined,
      }}
      body={{
        experiment_id: experiment.id,
      }}
      options={{ toast: true }}
    />
    <CanCreate entityName="protocol_collection">
      <ModalDefault
        header="New Protocol"
        viewComponent={({ toggleModal }) => (
          <ActionComponent
            type="option"
            text="New Protocol"
            onClick={toggleModal}
          />
        )}
        content={({ toggleModal }) => (
          <ProtocolFormCreate
            body={{
              experiment_id: experiment.id,
              group_id: activeGroup?.id,
            }}
            options={{
              onSuccess: toggleModal,
              toast: true,
            }}
          />
        )}
      />
    </CanCreate>
  </Dropdown>
);

export const ExperimentActionSaveAs: React.FC<
  IExperimentActionSaveAsProps
> = ({ experiment, disabled, ...rest }) => {
  if (experiment.protocol) {
    return (
      <ExperimentActionSaveAsWithProtocol
        experiment={experiment}
        disabled={disabled}
        {...rest}
      />
    );
  }

  return (
    <ExperimentActionSaveAsWithoutProtocol
      experiment={experiment}
      {...rest}
    />
  );
};

export default withActiveGroup(ExperimentActionSaveAs);
