/**
 * Labstep
 *
 * @module components/Notification/Action/Menu
 * @desc Action Menu for notification
 */

import NotificationActionDisable from 'labstep-web/components/Notification/Action/Disable';
import { NotificationTypeValues } from 'labstep-web/constants/user-group-notification';
import Dropdown from 'labstep-web/core/Dropdown';
import Icon from 'labstep-web/core/Icon';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import React, { useState } from 'react';
import { INotificationActionMenuProps } from './types';

const NotificationActionMenu: React.FC<
  INotificationActionMenuProps
> = ({ notification, actions, type }) => {
  const [open, setOpen] = useState(false);
  const { activeGroup } = useActiveGroup();
  if (!activeGroup) {
    return null;
  }
  const typesWithSettings = Object.keys(
    NotificationTypeValues,
  ).reduce(
    (result: string[], key) => [
      ...result,
      ...Object.keys(NotificationTypeValues[key]),
    ],
    [],
  );
  if (typesWithSettings.indexOf(notification.type) === -1) {
    return null;
  }
  return (
    <Dropdown.Dropdown
      type={type}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      pointing="top right"
      icon={null}
      trigger={<Icon name="ellipsis vertical" />}
    >
      <Dropdown.Menu>
        {!open && <Dropdown.Item>Placeholder</Dropdown.Item>}
        {actions.includes('disable') && open && (
          <NotificationActionDisable notification={notification} />
        )}
      </Dropdown.Menu>
    </Dropdown.Dropdown>
  );
};

NotificationActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default NotificationActionMenu;
