/**
 * Labstep
 */

import { Filter } from 'labstep-web/components/Filter/Menu/types';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { PermissionRoleSectionKeys, PremiumFeatures } from './types';

export const PremiumFeatureConfig: PremiumFeatures = {
  chemistry: {
    premiumKeys: [Filter.molecule],
    show: ['metadata', 'filters', 'slash_commands'],
    hide: ['bulk_metadata'],
  },
  molecular_biology: {
    premiumKeys: [MetadataType.sequence],
    show: ['metadata'],
    hide: ['bulk_metadata'],
  },
  inventory: {
    premiumKeys: [
      Filter.resource,
      Filter.resource_location,
      Filter.resource_item_status,
      Filter.resource_metadata,
      PermissionRoleSectionKeys.resource,
      PermissionRoleSectionKeys.resource_location,
      PermissionRoleSectionKeys.resource_item,
      PermissionRoleSectionKeys.resource_template,
      'resource_item',
      'protocol_value',
      'inventory',
    ],
    show: ['slash_commands'],
    hide: [
      'filters',
      'referencing_menu_categories',
      'conditions_table',
      'categories',
      'global_search',
    ],
  },
  reporting: {
    premiumKeys: ['protocol_conditions'],
    show: ['sub_links'],
    hide: [],
  },
  signature_workflow: {
    premiumKeys: [
      Filter.experiment_workflow_status,
      Filter.experiment_workflow_status_type,
      PermissionRoleSectionKeys.entity_state_workflow,
    ],
    show: [],
    hide: ['categories'],
  },
  devices: {
    premiumKeys: ['device', 'device_template', 'protocol_device'],
    show: ['slash_commands'],
    hide: [
      'metadata_detail_table',
      'referencing_menu_categories',
      'global_search',
      'categories',
    ],
  },
  order_management: {
    premiumKeys: ['purchase_order', 'order_request'],
    show: [],
    hide: ['global_search', 'categories', 'order_request_action'],
  },
  jupyter: {
    premiumKeys: ['jupyter_notebook'],
    show: ['slash_commands'],
    hide: [],
  },
  multiplexing: {
    premiumKeys: ['conditions'],
    show: ['slash_commands'],
    hide: [],
  },
};
