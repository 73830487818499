/**
 * Labstep
 *
 * @module components/ResourceItem/Action/Duplicate
 * @desc Button to add resource from resource list
 */

import EntityActionDuplicate from 'labstep-web/components/Entity/Action/Duplicate';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import { IResourceItemDuplicateActionProps } from './types';

export const ResourceItemDuplicateAction: React.FC<
  IResourceItemDuplicateActionProps
> = ({ resourceItem, noRedirect }) => (
  <EntityActionDuplicate
    entity={resourceItem}
    body={{
      resource_id: resourceItem.resource.id,
    }}
    noRedirect={noRedirect}
    parentName={Resource.entityName}
    parentId={resourceItem.resource.id}
  />
);

export default ResourceItemDuplicateAction;
