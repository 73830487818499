/**
 * Labstep
 */

import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ModalRouteStateType } from './types';

export const useModalRoute = (routeId: string) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const mParams = params.getAll('m');

    if (mParams.includes(routeId)) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [location.search, routeId]);

  const toggleModal = useCallback(
    (state?: ModalRouteStateType) => {
      if (isModalOpen) {
        const params = new URLSearchParams(location.search);
        const mParams = params.getAll('m');
        params.delete('m');
        mParams.forEach((param) => {
          if (param !== routeId) {
            params.append('m', param);
          }
        });
        history.push({
          search: params.toString(),
          state: {},
        });
      } else {
        const params = new URLSearchParams(location.search);
        params.append('m', routeId);
        history.push({
          search: params.toString(),
          state,
        });
      }
    },
    [history, isModalOpen, location.search, routeId],
  );

  return { open: isModalOpen, toggleModal };
};
