/**
 * Labstep
 *
 * @module prosemirror/extensions/alignment/commands
 * @desc Commands for alignment
 */

import { ProseMirrorCommand } from 'labstep-web/prosemirror/marks/types';
import bugsnagService from 'labstep-web/services/bugsnag.service';
import { CellSelection } from 'prosemirror-tables';

type TypeSetTextAlign = (alignment?: string) => ProseMirrorCommand;

export const setTextAlign: TypeSetTextAlign =
  (alignment) => (state, dispatch) => {
    try {
      const { selection } = state;
      // TODO: Propose Transaction to receive S (schema)
      let tr = state.tr.setSelection(selection);

      const { doc } = tr;
      if (!selection || !doc) {
        return false;
      }
      if (selection instanceof CellSelection) {
        selection.forEachCell((cell, cellPos) => {
          cell.descendants((descendant) => {
            if (descendant.type.name === 'paragraph') {
              tr = tr.setNodeMarkup(
                cellPos + 1,
                descendant.type,
                {
                  ...descendant.attrs,
                  align: alignment,
                },
                descendant.marks,
              );
            }
          });
        });
      } else {
        doc.nodesBetween(
          selection.from,
          selection.to,
          (node, pos) => {
            const nodeType = node.type;

            if (nodeType.name === 'paragraph') {
              tr = tr.setNodeMarkup(
                pos,
                nodeType,
                {
                  ...node.attrs,
                  align: alignment,
                },
                node.marks,
              );
            }
            return true;
          },
        );
      }
      if (tr.docChanged) {
        if (dispatch) {
          dispatch(tr);
        }
        return true;
      }
      return false;
    } catch (e) {
      bugsnagService.notify(e);
      return false;
    }
  };
