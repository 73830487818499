/**
 * Labstep
 *
 * @module components/Resource/OrderRequestTemplate/EmptyState
 * @desc Empty State for OrderRequestTemplate
 */

import React from 'react';
import OrderRequestTemplateActionCreate from 'labstep-web/components/OrderRequestTemplate/Action/Create';
import OrderRequestTemplateActionDelete from 'labstep-web/components/OrderRequestTemplate/Action/Delete';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { ResourceOrderRequestTemplateEmptyStateProps } from './types';

const ResourceOrderRequestTemplateEmptyState: React.FC<
  ResourceOrderRequestTemplateEmptyStateProps
> = ({ resource }) => {
  const title = 'No Order Request Template';

  const explanation =
    'Order request templates allow each request to be initialised with a default price.';

  return (
    <EmptyState
      title={title}
      explanation={explanation}
      src="/img/empty-state/orderRequests.svg"
      action={
        resource.order_request_template ? (
          <OrderRequestTemplateActionDelete
            orderRequestTemplate={resource.order_request_template}
          />
        ) : (
          <OrderRequestTemplateActionCreate resource={resource} />
        )
      }
    />
  );
};

export default ResourceOrderRequestTemplateEmptyState;
