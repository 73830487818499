/**
 * Labstep
 *
 * @module components/ResourceItem/Action/Export/Menu
 * @desc Action Menu for resource item exports
 */

import React from 'react';
import ActionMenu from 'labstep-web/core/Action/Menu';
import ResourceItemExportActionCreate from 'labstep-web/components/ResourceItemExport/Action/Create';

export const ResourceItemActionExportMenu: React.FC = () => (
  <ActionMenu>
    <ResourceItemExportActionCreate />
  </ActionMenu>
);

export default ResourceItemActionExportMenu;
