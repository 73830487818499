/**
 * Labstep
 *
 * @module state/reducers/websocket
 * @desc Reducers for Websocket
 */

import { combineReducers } from 'redux';

/**
 * Websocket connected
 */
export const connected = (state = false, action) => {
  if (action.type === 'WEBSOCKET_CONNECT') {
    return true;
  }
  if (action.type === 'WEBSOCKET_DISCONNECT') {
    return false;
  }
  if (action.type === 'WEBSOCKET_ERROR') {
    return false;
  }

  return state;
};

/**
 * Websocket room
 */
export const room = (state = null, action) => {
  if (action.type === 'JOIN_ROOM' && action.meta) {
    return action.meta.room;
  }

  return state;
};

/**
 * Websocket list of connected users
 */
export const users = (state = [], action) => {
  if (
    action.type === 'WEBSOCKET_MESSAGE' &&
    action.payload &&
    action.payload.type === 'connected_users'
  ) {
    return action.payload.users;
  }

  return state;
};

const reducers: any = combineReducers({
  connected,
  room,
  users,
});

export default reducers;
