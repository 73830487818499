/**
 * Labstep
 *
 * @module services/authentication
 * @desc Authentication with api key or JWT
 */

import { Action } from 'labstep-web/models/action.model';
import { jwtService } from 'labstep-web/services/jwt.service';
import { selectAuthenticatedUser } from 'labstep-web/state/selectors/authenticated-user';
import { LabstepReduxState } from 'labstep-web/state/types';
import { StateObservable } from 'redux-observable';
import { Observable, concat, from, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export interface Authentication {
  jwt?: string;
  apikey?: string;
}

export class AuthenticationService {
  private jwtObs: Record<string, Observable<Action>> = {};

  public static getApiKeyOrJWT = (
    state$: StateObservable<LabstepReduxState>,
  ): Authentication => {
    const authenticatedUser = selectAuthenticatedUser(state$.value);

    if (!authenticatedUser) {
      return {};
    }

    return {
      jwt: authenticatedUser.token,
    };
  };

  public getJwtObs(
    action: Action,
    state$: StateObservable<LabstepReduxState>,
    jwt: string,
  ): Observable<Action> {
    return from(jwtService.isJwtExpired(jwt)).pipe(
      mergeMap((isExpired: boolean): Observable<Action> => {
        if (isExpired) {
          const { token, refresh_token } = selectAuthenticatedUser(
            state$.value,
          );

          if (!this.jwtObs[token]) {
            this.jwtObs[token] = jwtService.refreshToken(
              token,
              refresh_token,
            );
          }

          return this.jwtObs[token].pipe(
            mergeMap((refreshTokenAction) => {
              if (
                refreshTokenAction.type === 'SUCCESS_REFRESH_TOKEN'
              ) {
                return concat(
                  of(refreshTokenAction),
                  of({
                    ...action,
                    meta: {
                      ...action.meta,
                      authentication: {
                        jwt: refreshTokenAction.payload.token,
                      },
                    },
                  }),
                );
              }

              return of(refreshTokenAction);
            }),
          );
        }

        return of({
          ...action,
          meta: {
            ...action.meta,
            authentication: {
              jwt,
            },
          },
        });
      }),
    );
  }

  public getAuthentication(
    action: Action,
    state$: StateObservable<LabstepReduxState>,
  ): Observable<Action> {
    const authentication =
      AuthenticationService.getApiKeyOrJWT(state$);

    if (authentication.apikey) {
      return of({
        type: 'AUTHENTICATION',
        meta: {
          authentication,
        },
      });
    }

    if (authentication.jwt) {
      return this.getJwtObs(action, state$, authentication.jwt);
    }

    return of(action);
  }
}

export const authenticationService = new AuthenticationService();
