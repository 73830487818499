/**
 * Labstep
 *
 * @module containers/File/Progress
 * @desc Container to pass all files currently under progress
 */

import { connect } from 'react-redux';
import { selectFilesInProgress } from 'labstep-web/state/selectors/file';

export type FileProgressContainerChildrenProps = ReturnType<
  typeof mapStateToProps
>;

const Container = ({
  children,
  ...rest
}: FileProgressContainerChildrenProps & {
  children: (
    childrenProps: Omit<
      FileProgressContainerChildrenProps,
      'children'
    >,
  ) => JSX.Element | null;
}) => children(rest);

const mapStateToProps = (state: any) => ({
  files: selectFilesInProgress(state),
});

const mapDispatchToProps = () => ({});

/**
 * Delete Cotainer connecting a component with state
 * Returns orginal component with
 * delete function and status of action
 */
export const FileProgressContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
