/**
 * Labstep
 *
 * @module components/ProtocolValue/Form/ShowEdit/InputOutput
 * @desc ProtocolValue field type input/output
 */

import React from 'react';
import EntityFormShowEditInputOutput from 'labstep-web/components/Entity/Form/ShowEdit/InputOutput';
import { IProtocolValueShowEditInputOutputProps } from './types';

export const ProtocolValueShowEditInputOutput: React.FC<
  IProtocolValueShowEditInputOutputProps
> = ({ protocolValue }) => (
  <EntityFormShowEditInputOutput entity={protocolValue} />
);

export default ProtocolValueShowEditInputOutput;
