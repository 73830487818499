/**
 * Labstep
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { OrganizationSAML } from 'labstep-web/models/organization-saml.model';
import React from 'react';
import { IOrganizationSamlActionCreateProps } from './types';

const OrganizationSamlActionCreate: React.FC<
  IOrganizationSamlActionCreateProps
> = ({ organization, actionComponentProps }) =>
  !organization.organization_saml ? (
    <EntityActionCreate
      parentId={organization.id}
      parentName={organization.entityName}
      entityName={OrganizationSAML.entityName}
      actionComponentProps={actionComponentProps}
    />
  ) : null;

export default OrganizationSamlActionCreate;
