/**
 * Labstep
 *
 * @module containers/Params
 * @desc Container for params
 */

import {
  clearParams,
  setParams,
} from 'labstep-web/state/actions/params';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  params: state.params,
});

const mapDispatchToProps = {
  setParams,
  clearParams,
};

export const withParams = (Component) =>
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps,
  )(Component);
