/**
 * Labstep
 *
 * @module components/Comment/Form/Edit
 * @desc Comment Form Edit
 */

import ActionContainer from 'labstep-web/components/Layout/Container/ActionButtons';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import Button from 'labstep-web/core/Button';
import Mentions from 'labstep-web/core/Mentions';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import {
  ICommentFormEditContainerProps,
  ICommentFormEditProps,
} from './types';

export const CommentFormEdit: React.FC<ICommentFormEditProps> = ({
  comment,
  update,
  status,
  toggle,
}) => {
  const [value, setValue] = useState(comment.body);
  return (
    <div className={styles.container}>
      <Mentions
        value={value}
        onChange={setValue}
        placeholder="Type any text"
        autoFocus
      />
      <ActionContainer>
        <Button basic primary status={status} onClick={toggle}>
          Cancel
        </Button>
        <Button
          onClick={() =>
            update({ body: value }, { onSuccess: toggle })
          }
          loader
          type="submit"
          status={status}
          primary
        >
          Submit
        </Button>
      </ActionContainer>
    </div>
  );
};

export const CommentFormEditContainer: React.FC<
  ICommentFormEditContainerProps
> = (props) => (
  <EntityUpdateContainer
    entityName={props.comment.entityName}
    id={props.comment.id}
  >
    {(containerProps) => (
      <CommentFormEdit {...containerProps} {...props} />
    )}
  </EntityUpdateContainer>
);

export default CommentFormEditContainer;
