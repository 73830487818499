/**
 * Labstep
 *
 * @module prosemirror/nodes/hard-break/commands
 * @desc Commands for Hard break node
 */

import { chainCommands, exitCode } from 'prosemirror-commands';

export const getHardBreakCmd = (type) => {
  const br = type;
  const cmd = chainCommands(exitCode, (state, dispatch) => {
    dispatch(
      state.tr.replaceSelectionWith(br.create()).scrollIntoView(),
    );
    return true;
  });
  return cmd;
};
