/**
 * Labstep
 */

import React, { useContext } from 'react';
import Icon from 'labstep-web/core/Icon';
import { SortableItemContext } from '../context';
import styles from './styles.module.scss';
import { ISortableItemDragHandleProps } from './types';

export const SortableItemDragHandle: React.FC<
  ISortableItemDragHandleProps
> = ({ disabled, children }) => {
  const { attributes, listeners, ref } = useContext(
    SortableItemContext,
  );

  return (
    <div
      className={styles.dragHandle}
      {...attributes}
      {...listeners}
      ref={ref}
    >
      {children || (
        <Icon
          className={styles.icon}
          name="bars"
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default SortableItemDragHandle;
