/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/Workspaces
 * @desc Organization plan card for workspaces
 */

import OrganizationPlanCard from 'labstep-web/components/OrganizationPlan/Card';
import { ICONS } from 'labstep-web/constants/icons';
import Button from 'labstep-web/core/Button';
import React from 'react';
import { OrganizationPlanCardWorkspacesProps } from './types';

export const OrganizationPlanCardWorkspaces: React.FC<
  OrganizationPlanCardWorkspacesProps
> = ({ organization }) => (
  <OrganizationPlanCard
    icon={ICONS.group.primary}
    header="Workspaces"
    primary={<p>{organization.group_count}</p>}
    action={
      <Button
        route={{
          to: 'organization_show',
          params: { identifier: organization.identifier },
        }}
        primary
        basic
      >
        View workspaces
      </Button>
    }
  />
);

export default OrganizationPlanCardWorkspaces;
