/**
 * Labstep
 *
 * @module components/Group/Settings/Name
 * @desc Name update
 */

import React from 'react';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import { fieldName } from 'labstep-web/components/Group/Form/fields';
import { IGroupFormShowEditNameProps } from './types';

export const GroupFormShowEditName: React.FC<
  IGroupFormShowEditNameProps
> = ({ group }) => (
  <ShowEditText
    entityName={group.entityName}
    entity={group}
    field={fieldName}
  >
    {group.name}
  </ShowEditText>
);

export default GroupFormShowEditName;
