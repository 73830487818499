/**
 * Labstep
 *
 * @module components/Resource/OrderRequestTemplate/Edit
 * @desc Edit a resource resource item template
 */

import React from 'react';
import Container from 'labstep-web/components/Layout/Container';
import OrderRequestTemplateActionDelete from 'labstep-web/components/OrderRequestTemplate/Action/Delete';
import OrderRequestPrice from 'labstep-web/components/OrderRequest/Price';
import ListLabelValue from 'labstep-web/core/List/LabelValue';
import EntityLink from 'labstep-web/components/Entity/Link';
import { ResourceOrderRequestTemplateEditProps } from './types';

const ResourceOrderRequestTemplateEdit: React.FC<
  ResourceOrderRequestTemplateEditProps
> = ({ resource }) => (
  <div>
    <Container>
      <ListLabelValue
        fluid
        array={[
          {
            label: 'Resource',
            value: <EntityLink entity={resource} />,
          },
          {
            dataTestId: 'edit-order-request-template-action',
            label: 'Default Price',
            value: (
              <OrderRequestPrice
                orderRequest={resource.order_request_template}
              />
            ),
          },
        ]}
      />
    </Container>
    <OrderRequestTemplateActionDelete
      orderRequestTemplate={resource.order_request_template}
    />
  </div>
);

export default ResourceOrderRequestTemplateEdit;
